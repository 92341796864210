import { useEffect, useState } from "react";

interface IPaginationProps {
  data: any[];
  current?: number;
  onChange: (data: number) => void;
}

const Pagination = ({ data, current = 1, onChange }: IPaginationProps) => {
  const [activeItem, setActiveItem] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const MAX_PAGE_PER_ROW = 5;
  const indexOfLastItem = currentPage * MAX_PAGE_PER_ROW;
  const indexOfFirstItem = indexOfLastItem - MAX_PAGE_PER_ROW + 1;
  const pages = [];

  useEffect(() => {
    if (activeItem > indexOfLastItem) {
      setCurrentPage((prev) => prev + 1);
    } else if (activeItem < indexOfFirstItem) {
      setCurrentPage((prev) => prev - 1);
    }
  }, [activeItem, indexOfLastItem, indexOfFirstItem]);

  useEffect(() => {
    setActiveItem(current);
  }, []);

  useEffect(() => {
    onChange(currentPage);
  }, [currentPage]);

  const handleNavigateForward = () => {
    if (activeItem <= indexOfLastItem && activeItem < data.length) {
      setActiveItem((prev) => prev + 1);
      return;
    }
  };

  for (
    let i = indexOfFirstItem;
    i <= indexOfLastItem && i <= data.length;
    i++
  ) {
    pages.push(
      <li
        key={`index-${i}`}
        className={`page-item ${activeItem === i && "active"}`}
        onClick={() => {
          setActiveItem(i);
        }}
      >
        <a className="page-link" href="#link">
          {i}
        </a>
      </li>
    );
  }

  const handleNavigateBackward = () => {
    if (activeItem > 1) {
      setActiveItem((prev) => prev - 1);
    }
  };

  const handleNavigateToFirstPage = () => {
    setActiveItem(1);
  };

  const handleNavigateToLastPage = () => {
    setActiveItem(data?.length || MAX_PAGE_PER_ROW);
  };

  return (
    <ul className="pagination pagination-info">
      <li className="page-item" onClick={handleNavigateToFirstPage}>
        <a className="page-link" href="#link" aria-label="Previous">
          <span aria-hidden="true">
            <i className="fa fa-angle-double-left" aria-hidden="true"></i>
          </span>
        </a>
      </li>
      <li className="page-item" onClick={handleNavigateBackward}>
        <a className="page-link" href="#link" aria-label="Previous">
          <span aria-hidden="true">
            <i className="ni ni-bold-left" aria-hidden="true"></i>
          </span>
        </a>
      </li>

      {pages}

      <li className="page-item" onClick={handleNavigateForward}>
        <a className="page-link" href="#link" aria-label="Next">
          <span aria-hidden="true">
            <i className="ni ni-bold-right" aria-hidden="true"></i>
          </span>
        </a>
      </li>
      <li className="page-item" onClick={handleNavigateToLastPage}>
        <a className="page-link" href="#link" aria-label="Next">
          <span aria-hidden="true">
            <i className="fa fa-angle-double-right" aria-hidden="true"></i>
          </span>
        </a>
      </li>
    </ul>
  );
};

export default Pagination;
