import { useEffect, useRef, useState } from "react";

import { useDebounce } from "../../hooks/useDebounce";
import { useApiCaller } from "../../apis/config";
import { getSpotlightsApi } from "../../apis/paths/dashboard";
import Typography from "../ui-typography/component";
import InputSearch from "../search-input/component";
import ModalGeneral from "../ui-modal-general/component";
import TableDataPanel from "../../components/panel-table-data";

import { accountSchema } from "./schema/accountSchema";
import { pickingSchema } from "./schema/pickingSchema";
import { purchaseOrderSchema } from "./schema/purchaseOrderSchema";
import { saleOrderSchema } from "./schema/saleOrderSchema";
import { productSchema } from "./schema/productSchema";
import styles from "./styles.module.scss";

interface ISpotlightSearchInput {
  isShowModal: boolean;
  onCancel: any;
  setIsShowModal: any;
  trigerSearchCount: number;
}

const FieldContainer = ({
  children,
  header,
}: {
  children: any;
  header: string;
}) => (
  <div>
    <Typography
      customFontsize={15}
      fontWeight="fw-bold"
      className="text-center my-2"
    >
      {header}
    </Typography>
    {children}
    <hr className="horizontal dark mt-0" />
  </div>
);

const SpotlightSearchInput = ({
  isShowModal = false,
  onCancel,
  setIsShowModal,
  trigerSearchCount,
}: ISpotlightSearchInput) => {
  const [value, setValue] = useState("");
  const [hasSetFocused, setHasSetFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const debouncedValue = useDebounce(value, 300);

  useEffect(() => {
    if (inputRef.current && !hasSetFocused) {
      inputRef.current.focus();
      setHasSetFocused(true);
    }

    return () => {
      setHasSetFocused(false);
      setValue("");
    };
  }, [inputRef.current, trigerSearchCount]);

  const [spotlightParams, setSpotlightParams] = useState({
    keyword: "",
  });

  useEffect(() => {
    setSpotlightParams({ keyword: debouncedValue });
  }, [debouncedValue, setSpotlightParams]);

  const { result: spotlights } = useApiCaller({
    api: getSpotlightsApi,
    params: spotlightParams,
  });

  const formatSpotlightType = (objectSpotlight: {
    type: string;
    spotlight: any[];
  }) => {
    const { type, spotlight } = objectSpotlight || {};
    const shouldRender = Array.isArray(spotlight) && spotlight.length > 0;

    if (!shouldRender) return null;

    switch (type) {
      case "accounts":
        return (
          <FieldContainer header="Accounts">
            <TableDataPanel data={spotlight} schema={accountSchema} />
          </FieldContainer>
        );

      case "pickLists":
        return (
          <FieldContainer header="Pickings">
            <TableDataPanel data={spotlight} schema={pickingSchema} />
          </FieldContainer>
        );

      case "purchaseOrders":
        return (
          <FieldContainer header="Purchase Orders">
            <TableDataPanel data={spotlight} schema={purchaseOrderSchema} />
          </FieldContainer>
        );

      case "saleOrders":
        return (
          <FieldContainer header="Sale Orders">
            <TableDataPanel data={spotlight} schema={saleOrderSchema} />
          </FieldContainer>
        );

      case "products":
        return (
          <FieldContainer header="Products">
            <TableDataPanel data={spotlight} schema={productSchema} />
          </FieldContainer>
        );
    }
  };

  const { accounts, pick_lists, products, purchase_orders, sale_orders } =
    spotlights?.data || {};

  const sortingSpotlights = [
    {
      type: "purchaseOrders",
      spotlight: purchase_orders,
    },
    {
      type: "saleOrders",
      spotlight: sale_orders,
    },
    {
      type: "pickLists",
      spotlight: pick_lists,
    },
    {
      type: "products",
      spotlight: products,
    },
    {
      type: "accounts",
      spotlight: accounts,
    },
  ];

  return (
    <ModalGeneral
      isShowModal={isShowModal}
      onCancel={onCancel}
      className={{ body: "p-0 overflow-hidden", dialog: styles.customDialog }}
      isShowFooter={false}
      size="xl"
    >
      <InputSearch
        ref={inputRef}
        value={value}
        isDisplayCloseButton={false}
        setValue={setValue}
        placeholder={"Input your search keywords"}
        className={{ searchInput: styles.searchInput }}
      />
      <div
        onClick={() => {
          setIsShowModal(false);
        }}
      >
        {sortingSpotlights?.map((sortingSpotlights) => (
          <div key={sortingSpotlights?.type}>
            {formatSpotlightType(sortingSpotlights)}
          </div>
        ))}
      </div>
    </ModalGeneral>
  );
};

export default SpotlightSearchInput;
