import AuthCoverLayout from "../components/layout-auth-cover";
import AuthFormLayout from "../components/layout-auth-form";

interface PropsType {
  cover?: {
    title?: string;
    description?: string;
  };
  form?: {
    title?: string;
    description?: string;
  };
  children?: any;
}

const Component = ({ cover, form, children }: PropsType) => (
  <>
    <AuthCoverLayout title={cover?.title} description={cover?.description} />
    <AuthFormLayout title={form?.title} description={form?.description}>
      {children}
    </AuthFormLayout>
  </>
);

export default Component;
