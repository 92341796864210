import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { apiCaller, useApiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import {
  IUpdateWorkspace,
  getSettingsConstantApi,
  updateConstantsWorkspaceApi,
  uploadLogoApi,
} from "../../apis/paths/settings";
import UiInput from "../../components/ui-input";
import alert from "../../utils/alert";
import UiForm from "../../components/ui-form";
import UiButton from "../../components/ui-button";
import NotFoundPanel from "../../components/panel-not-found";
import ImageUploader from "../../components/ui-image-uploader/component";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";

import { formSchema } from "./formSchema";
import { IWorkspaceConstants } from "./types";

const CompanyInfo = () => {
  const navigate = useNavigate()

  const [params] = useState<{
    page: number;
    per: number;
  }>({
    page: 1,
    per: 50,
  });

  const { result } = useApiCaller({
    api: getSettingsConstantApi,
    params,
  });

  const workspaceInfo = result?.data?.workspace;

  const {
    setValue,
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const logo = watch("logo");

  useEffect(() => {
    if (!workspaceInfo) return;

    const keys = Object.keys(formSchema?.fields);
    keys.forEach((key: any) => {
      if (key !== "logo") {
        setValue(key, workspaceInfo[key as keyof IWorkspaceConstants]);
      }
    });
  }, [result, workspaceInfo]);

  const onUploadImage = async () => {
    const formData = new FormData();
    formData.append("logo", (logo as any)?.[0]?.file);

    const waitUploadLogoImage = await apiCaller({
      api: uploadLogoApi,
      params: {
        logo: formData,
      },
    });

    if (waitUploadLogoImage.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update logo successful!");
      return;
    }

    alert.error("Upload Logo was not successful!. Please try again!");
  };

  const onSubmit = async (formData: any, event: any) => {
    event.preventDefault();

    !!(logo as any)?.[0]?.file && onUploadImage();

    const { name, email, phone, website, address } = formData || {};
    const waitUpdateConstantsWorkspace = await apiCaller({
      api: updateConstantsWorkspaceApi,
      params: {
        name,
        email,
        phone,
        website,
        address,
      } as IUpdateWorkspace,
    });

    if (waitUpdateConstantsWorkspace.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update successful!");
      setTimeout(() => {
        navigate(0);
      }, 1000);
      return;
    }

    alert.error("Update was not successful!. Please try again!");
  };

  if (workspaceInfo?.status === "ERROR") return <NotFoundPanel />;

  return (
    <UiForm onSubmit={handleSubmit(onSubmit)}>
      <div className="card card-profile-bottom">
        <div className="card position-sticky top-1">
          <div className="card-header pb-0">
            <div className="d-flex justify-content-between">
              <div>
                <h5>Company Info</h5>
                <p className="text-sm">
                  Here you can setup and manage your company info.
                </p>
              </div>
              <div className="d-flex align-items-center ">
                <UiButton
                  className="me-2"
                  title={"Update"}
                  onClick={() => {}}
                />
              </div>
            </div>
            <hr className="horizontal dark mt-2" />
          </div>
          <div className="card-body pt-0 mx-5">
            <>
              <TwoColumnGridLayout
                leftCol={
                  <UiInput
                    className="dataTable-input"
                    name={"name"}
                    title={"Name"}
                    required
                    isDisplayLabel={true}
                    register={register}
                    errorMessage={errors?.name?.message}
                  />
                }
                rightCol={
                  <UiInput
                    className="dataTable-input"
                    name={"website"}
                    title={"Website"}
                    isDisplayLabel={true}
                    register={register}
                    errorMessage={errors?.website?.message}
                  />
                }
              />
              <TwoColumnGridLayout
                leftCol={
                  <UiInput
                    className="dataTable-input"
                    name={"email"}
                    title={"Email"}
                    isDisplayLabel={true}
                    register={register}
                    errorMessage={errors?.email?.message}
                  />
                }
                rightCol={
                  <UiInput
                    className="dataTable-input"
                    name={"phone"}
                    title={"Phone"}
                    isDisplayLabel={true}
                    register={register}
                    errorMessage={errors?.phone?.message}
                  />
                }
              />
              <UiInput
                className="dataTable-input"
                name={"address"}
                title={"Address"}
                isDisplayLabel={true}
                register={register}
                errorMessage={errors?.address?.message}
              />
              <ImageUploader
                isDisplayLabel
                title="Logo"
                isDisplayRemoveAll={false}
                name="logo"
                trigger={trigger}
                setValue={setValue}
                errorMessage={errors?.logo?.message}
              />
            </>
          </div>
        </div>
      </div>
    </UiForm>
  );
};

export default CompanyInfo;
