import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";

import * as R from "../../routings/path";
import { useMySession } from "../../hooks/useMySession";
import { userStateType } from "../../states/user.flow";
import { useKeyPress } from "../../hooks/useTypingLetterSelection";
import Breadcrumbs from "../../components/ui-breadcrumb/component";
import PanelHelper from "../panel-helper/component";
import PanelStatusMode from "../ui-panel-status-mode/component";
import SpotlightSearchInput from "../ui-spotlight-search/component";
import useHandleSidebarStatus from "../../hooks/useSidebarStatus";
import PanelSettingController from "../panel-setting-controller/component";

import styles from "./styles.module.scss";
import ViewCustomSearchInput from "./view-custom-search-input";
import ViewAccountInfo from "./view-account-info";

const Component = ({ children }: any) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowSpotlightModal, setIsShowSpotlightModal] = useState(false);
  const [trigerSearchCount, setTrigerSearchCount] = useState(0);

  const searchRef = useRef<HTMLInputElement | null>(null);

  const isGroupKeyPressed1 = useKeyPress(["Meta", "k"]);
  const isGroupKeyPressed2 = useKeyPress(["Meta", "K"]);
  const isGroupKeyPressed3 = useKeyPress(["Control", "k"]);
  const isGroupKeyPressed4 = useKeyPress(["Control", "K"]);
  const isGroupKeyPressed5 = useKeyPress(["Shift", "?"]);
  const isOpenSpotlightSearch =
    isGroupKeyPressed1 ||
    isGroupKeyPressed2 ||
    isGroupKeyPressed3 ||
    isGroupKeyPressed4 ||
    isGroupKeyPressed5;

  useEffect(() => {
    if (isOpenSpotlightSearch) {
      setIsShowSpotlightModal(true);
    }
  }, [isOpenSpotlightSearch]);

  const account = useSelector(
    (state: { user: userStateType }) => state.user.loginInfo.account
  );

  const { fetchMySession, session } = useMySession();
  const { mySession } = session || {};

  useEffect(() => {
    fetchMySession();
  }, [fetchMySession]);

  const { sidebarStatus, dispatchSidebarStatus } = useHandleSidebarStatus();
  const { isOpened } = sidebarStatus || {};

  return (
    <>
      <SpotlightSearchInput
        isShowModal={isShowSpotlightModal}
        setIsShowModal={setIsShowSpotlightModal}
        onCancel={() => {
          setIsShowSpotlightModal(false);
        }}
        trigerSearchCount={trigerSearchCount}
      />
      <main
        className={classNames(
          "main-content position-relative border-radius-lg",
          !isOpened && styles.sidebarCollapsed
        )}
      >
        <div
          className={classNames(
            "position-fixed cursor-pointer ",
            styles.toggleSidebar,
            isOpened && "d-none"
          )}
          onClick={() => {
            dispatchSidebarStatus({ isOpened: true });
          }}
        >
          <i className="fa-solid fa-chevron-right opacity-4 fa-sm"></i>
        </div>
        <nav
          className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl z-index-sticky"
          id="navbarBlur"
          data-scroll="false"
        >
          <div className="container-fluid py-1 px-3">
            <Breadcrumbs />
            <div
              className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
              id="navbar"
            >
              <div className="ms-md-auto pe-md-3 d-flex align-items-center" />
              <ul className="navbar-nav justify-content-end">
                <li className="nav-item d-flex align-items-center me-3">
                  <ViewCustomSearchInput
                    onClickSearch={() => {
                      setIsShowSpotlightModal(true);
                      searchRef?.current?.blur();
                      setTrigerSearchCount((prev) => prev + 1);
                    }}
                    ref={searchRef}
                  />
                </li>

                <li className="nav-item d-flex align-items-center">
                  <ViewAccountInfo
                    account={account}
                    mySession={mySession}
                    setIsShowModal={setIsShowModal}
                  />
                </li>

                <li
                  className={`nav-item dropdown px-3 d-flex align-items-center mb-3 ${styles.notiItem}`}
                >
                  <NavLink
                    to={R.NOTIFICATION_LIST}
                    className="nav-link text-white p-0"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i
                      className="fa fa-bell cursor-pointer"
                      aria-hidden="true"
                    ></i>
                    <span className={styles.number}>{"Notification"}</span>
                  </NavLink>
                </li>

                <li className="nav-item d-flex align-items-center">
                  <div className="mb-3">
                    <PanelSettingController />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="container-fluid py-4">{children}</div>
        <PanelStatusMode
          isShowModal={isShowModal}
          handleCloseModal={() => {
            setIsShowModal(false);
          }}
        />
      </main>
      <div className="position-fixed " style={{ bottom: 40, right: 30 }}>
        <PanelHelper />
      </div>
    </>
  );
};

export default Component;
