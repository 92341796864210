import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { apiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { useMySession } from "../../hooks/useMySession";
import { GENERAL_UI_COLOR } from "../../constants/color";
import {
  IsessionsWorkModeApi,
  sessionsWorkModeApi,
} from "../../apis/paths/sessions";
import { formSchema } from "./formSchema";
import {
  calculateRemainingTime,
  convertYYYYMMDDToUnixTime,
  getFutureUnixTime,
} from "../../utils/date-time";
import UiSelectDropdown, {
  IOptionItem,
} from "../../components/ui-select-dropdown/component";
import UiForm from "../../components/ui-form";
import alert from "../../utils/alert";
import Badge from "../ui-badge/component";
import DatePicker from "../ui-date-picker/component";
import Typography from "../ui-typography/component";
import PopupConfirmation from "../popup-confirmation/component";

import styles from "./styles.module.scss";

interface IWorkMode {
  value: string;
  content: string;
}

const WORK_MODES = [
  {
    value: "available",
    content: "Available",
    icon: (
      <span className="material-icons opacity-6 text-success">
        notifications
      </span>
    ),
  },
  {
    value: "outside",
    content: "Outside",
    icon: (
      <span className="material-icons opacity-6 text-warning">
        notifications_off
      </span>
    ),
  },
  {
    value: "custom",
    content: "Custom",
    icon: (
      <span className="material-icons opacity-6 text-info-emphasis">timer</span>
    ),
  },
];

const OUTSIDE_PERIODS = [
  { content: "30 minutes", value: 0.5, key: 1 },
  { content: "1 hour", value: 1, key: 2 },
  { content: "2 hour", value: 2, key: 3 },
  { content: "3 hour", value: 3, key: 4 },
  { content: "4 hour", value: 4, key: 5 },
];

interface IConfirmationPopupProps {
  isShowModal: boolean;
  handleCloseModal?: () => void;
  confirmationButton?: string;
  isNotable?: boolean;
}

const PanelStatusMode = ({
  isShowModal,
  handleCloseModal,
  confirmationButton = "Save",
}: IConfirmationPopupProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedWorkMode, setSelectedWorkMode] = useState<any | null>(null);
  const {
    setValue,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const { fetchMySession, session } = useMySession();
  const { mySession } = session || {};

  useEffect(() => {
    if (mySession?.outside_until) {
      setValue("outside_until", mySession?.outside_until);
    }
  }, [mySession]);

  useEffect(() => {
    return () => {
      setSelectedWorkMode(null);
      setCurrentStep(0);
      reset({});
    };
  }, []);

  const onSubmit = async ({
    work_mode,
    outside_until,
  }: IsessionsWorkModeApi) => {
    const waitUpdateRole = await apiCaller({
      api: sessionsWorkModeApi,
      params: {
        work_mode,
        outside_until:
          typeof outside_until === "string"
            ? convertYYYYMMDDToUnixTime(outside_until as any)
            : outsideUntil,
      },
    });

    if (waitUpdateRole.status === API_RESPONSE_STATUS.SUCCESS) {
      setTimeout(() => {
        alert.success("Update your working status successful!");
      }, 1000);
      handleCloseModal && handleCloseModal();
      setSelectedWorkMode(null);
      setCurrentStep(0);
      reset({});
      fetchMySession();
      return;
    }

    handleCloseModal && handleCloseModal();
    alert.error("Update was not successful!. Please try again!");
  };

  const handleClickWorkMode = (workMode: IWorkMode) => {
    setValue("work_mode", workMode?.value);
  };

  const outsideUntil = watch("outside_until");

  return (
    <PopupConfirmation
      isShowModal={isShowModal}
      onCloseModal={() => {
        setSelectedWorkMode(null);
        setCurrentStep(0);
        reset({});
        handleCloseModal && handleCloseModal();
      }}
      closeButton
      header="Set a status"
    >
      <UiForm onSubmit={handleSubmit(onSubmit)}>
        {currentStep === 0 ? (
          <div className="d-flex flex-column">
            {WORK_MODES?.map((workMode) => (
              <div
                key={workMode?.value}
                className={classNames(
                  "d-flex gap-1 px-3 py-2 mb-1 align-items-center",
                  styles.workMode,
                  workMode?.value === mySession?.work_mode &&
                    styles.activeWorkmode
                )}
                onClick={() => {
                  handleClickWorkMode(workMode);
                  setSelectedWorkMode(workMode);
                  setCurrentStep(1);
                }}
              >
                {workMode?.icon}
                <Typography fontWeight="fw-normal">
                  {workMode?.content}
                </Typography>
              </div>
            ))}
          </div>
        ) : (
          <div>
            <label>Your selected workmode:</label>
            <div
              className={classNames(
                "d-flex gap-1 px-3 py-2 align-items-center",
                styles.active
              )}
              onClick={() => {}}
            >
              {selectedWorkMode?.icon}
              <Typography fontWeight="fw-normal">
                {selectedWorkMode?.content}
              </Typography>
              <i
                className={classNames(
                  "fa-solid fa-circle-xmark ms-auto opacity-6 cursor-pointer",
                  styles.closeButton
                )}
                onClick={() => {
                  setCurrentStep(0);
                  setSelectedWorkMode(null);
                }}
              ></i>
            </div>
            {selectedWorkMode?.value === "outside" && (
              <div className="d-flex mt-3 flex-column justify-content-start">
                <UiSelectDropdown
                  name="outside_until"
                  title="Select opitons"
                  isDisplayLabel
                  onChange={(value) => {
                    setValue("outside_until", getFutureUnixTime(Number(value)));
                  }}
                  optionList={OUTSIDE_PERIODS as IOptionItem[]}
                />
                <Typography fontWeight="fw-bold" className="my-2 text-center">
                  Or
                </Typography>
                <div className="d-flex gap-2 w-100 flex-grow-1 justify-content-between">
                  <div className="col-6">
                    <DatePicker
                      className="dataTable-input"
                      name={"outside_until"}
                      title={"Select Date-Time"}
                      type="datetime-local"
                      isDisplayLabel={true}
                      required
                      control={control}
                      errorMessage={errors?.outside_until?.message as string}
                      dirtyFields={dirtyFields}
                    />
                  </div>
                  <div className="text-start pe-2 col-6">
                    <div>
                      <label>Until: </label>
                      <div className=" mt-2">
                        <Badge
                          content={
                            typeof outsideUntil === "string"
                              ? calculateRemainingTime(
                                  convertYYYYMMDDToUnixTime(
                                    outsideUntil
                                  ) as number
                                )
                              : calculateRemainingTime(outsideUntil)
                          }
                          type={GENERAL_UI_COLOR.WARNING}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        <div>
          <hr className="horizontal dark mt-3" />
          <div className="d-flex mt-3 justify-content-end">
            <Button
              className="mb-2 me-3"
              variant=""
              size="sm"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              className="mb-2 bg-gradient-primary"
              type="submit"
              disabled={currentStep === 0 || !selectedWorkMode}
            >
              {confirmationButton}
            </Button>
          </div>
        </div>
      </UiForm>
    </PopupConfirmation>
  );
};

export default PanelStatusMode;
