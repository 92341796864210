import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { GENERAL_UI_SIZE } from "../../constants/size";
import {
  IPrintPackageApi,
  getPurchaseOrderDetailApi,
  printPackageApi,
} from "../../apis/paths/purchase-order";
import { apiCaller, useApiCaller } from "../../apis/config";
import { usePrinting } from "../../hooks/usePrinting";
import UiInput from "../../components/ui-input";
import UiButton from "../../components/ui-button";
import NoDataPanel from "../../components/no-data-panel/component";
import Typography from "../../components/ui-typography/component";
import GeneralPanel from "../../components/panel-general";
import NotFoundPanel from "../../components/panel-not-found/component";
import UiLoadingPanel from "../../components/ui-loading-panel";
import HeaderInfoPanel from "../../components/panel-header-info";
import TableCellStatus from "../../components/table-cell-status";
import GridLayoutTwoColumn from "../../components/grid-layout-two-column/component";

import GeneralHeading from "./view-general-heading";
import RowTextContent from "./view-row-text-content";
import PackageHeading from "./view-package-heading";
import styles from "./styles.module.scss";

const Component = () => {
  const [purchaseOrderInfo, setPurchaseOrderInfo] = useState<any>({});
  const [isSelectPrintingAll, setIsSelectPrintingAll] = useState(false);
  const [printingPurchaseOrder, setPrintingPurchaseOrder] = useState<string[]>(
    []
  );

  const { purchaseOrderId: uuid } = useParams();
  const [params] = useState({ uuid });

  const { printUrl } = usePrinting();

  const { result, loading } = useApiCaller({
    api: getPurchaseOrderDetailApi,
    params: params,
  });


  useEffect(() => {
    if (result?.data) setPurchaseOrderInfo(result?.data);
  }, [result]);

  const handleSelectPrintingAllPackage = () =>
    purchaseOrderInfo?.purchase_order?.packages?.map(
      (packageItem: any) => packageItem?.uuid
    );

  const handlePrintPackage = async () => {
    if (printingPurchaseOrder.length === 0) return;

    const waitPrintPackage = await apiCaller({
      api: printPackageApi,
      params: {
        uuid,
        packageUuids: printingPurchaseOrder,
      } as IPrintPackageApi,
    });

    if (waitPrintPackage?.status === "SUCCESS") {
      waitPrintPackage?.data?.list?.forEach(async (link: any) => {
        await printUrl(link?.url, "label");
      });
    }
  };

  if (loading) return <UiLoadingPanel />;

  if (result?.status === "ERROR") return <NotFoundPanel />;

  return (
    <>
      <div className="mb-4">
        <HeaderInfoPanel
          isDisplayInfoArea={true}
          title={`#${purchaseOrderInfo?.purchase_order?.number}`}
          infoArea={() => (
            <TableCellStatus
              status={purchaseOrderInfo?.purchase_order?.status}
              size={GENERAL_UI_SIZE.SMALL}
            />
          )}
          actionArea={() => <></>}
        />
      </div>
      <GeneralPanel className="mt-3">
        <GeneralHeading>
          Packages Info
          {Array.isArray(purchaseOrderInfo?.purchase_order?.packages) &&
            purchaseOrderInfo?.purchase_order?.packages?.length > 0 && (
              <div className="d-flex justify-content-end gap-4 align-items-center">
                <div className="d-flex justify-content-between align-items-center">
                  <UiInput
                    type="checkbox"
                    name={"checkbox"}
                    containerClassName="mb-0 mt-3"
                    className="mb-0"
                    isDisplayLabel={false}
                    onClick={() => {
                      setPrintingPurchaseOrder(
                        !isSelectPrintingAll
                          ? handleSelectPrintingAllPackage()
                          : []
                      );
                      setIsSelectPrintingAll((prev) => !prev);
                    }}
                  />
                  <Typography customFontsize={13} fontWeight="fw-bold">
                    Select All
                  </Typography>
                </div>
                <div className="d-flex justify-content-center align-items-center h-100">
                  <UiButton
                    className="ms-auto"
                    size={GENERAL_UI_SIZE.SMALL}
                    title={"Print"}
                    onClick={handlePrintPackage}
                  />
                </div>
              </div>
            )}
        </GeneralHeading>
        <div className={styles.packageList}>
          {purchaseOrderInfo?.purchase_order?.packages?.length > 0 ? (
            purchaseOrderInfo?.purchase_order?.packages?.map(
              (packageItem: any) => (
                <GridLayoutTwoColumn
                  widthDivision={[11, 1]}
                  leftCol={
                    <div className={styles.packageItem}>
                      <div className={styles.info}>
                        <PackageHeading
                          packageItemSource={packageItem}
                          purchaseOrderUuid={uuid}
                        />
                        <div className={styles.content}>
                          <div className={styles.tableContent}>
                            <RowTextContent
                              sub={"Number of items"}
                              title={packageItem?.number_of_items}
                            />
                            <RowTextContent
                              sub={"Width x Height x Length"}
                              title={
                                !packageItem?.width ||
                                !packageItem?.height ||
                                !packageItem?.length
                                  ? "Unknown"
                                  : `${packageItem?.width} x ${packageItem?.height} x ${packageItem?.length}`
                              }
                            />
                            <RowTextContent
                              sub={"Weight"}
                              title={
                                !packageItem?.weight
                                  ? "Unknown"
                                  : packageItem?.weight
                              }
                            />
                          </div>

                          <div className={styles.products}>
                            {packageItem?.package_products?.map(
                              (productItem: any) => (
                                <div className={styles.productItem}>
                                  <img src={productItem?.product?.image_url} />
                                  <div className={styles.productName}>
                                    {productItem?.product?.name}
                                  </div>
                                  <div className={styles.quantity}>
                                    {productItem.quantity}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  rightCol={
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <UiInput
                        type="checkbox"
                        name={"checkbox"}
                        isDisplayLabel={false}
                        checked={printingPurchaseOrder.includes(
                          packageItem?.uuid
                        )}
                        onClick={() => {
                          setPrintingPurchaseOrder((prev: string[]) =>
                            prev.includes(packageItem?.uuid)
                              ? prev.filter(
                                  (packageUuid: string) =>
                                    packageUuid !== packageItem?.uuid
                                )
                              : [...prev, packageItem?.uuid]
                          );
                        }}
                      />
                    </div>
                  }
                />
              )
            )
          ) : (
            <div>
              <NoDataPanel description="No package to display!" />
            </div>
          )}
        </div>
      </GeneralPanel>
    </>
  );
};

export default Component;
