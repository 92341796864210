/**
 * generateUrlParams
 *
 * @params object; ex: {page: 1, per: 2}
 * @return string; ex: ?page=1&per=2
 */

export const generateUrlParams = (params: any) => {
  const keys = Object.keys(params);
  const generatedParams = new URLSearchParams();

  keys.forEach((_key: string) => {
    if (
      !!params[_key] &&
      Array.isArray(params[_key]) &&
      params[_key].length > 0
    ) {
      params[_key]?.forEach((insideObject: any) => {
        const { key, value } = formatKeyValueParams(_key, insideObject);
        !!key && value !== null && generatedParams.append(key, String(value));
      });
    } else {
      const { key, value } = formatKeyValueParams(_key, params[_key]);
      !!key && value !== null && generatedParams.set(key, String(value));
    }
  });

  return `?${generatedParams.toString()}`;
};

const formatKeyValueParams = (key: string, value: any) => {
  if ("sort" === key) {
    return {
      key: "sort[]",
      value: !!value ? JSON.stringify(value) : null,
    };
  }

  if ("filter" === key) {
    let formattedValue: any = "";
    if (!value) {
      formattedValue = null;
    } else if (Array.isArray(value)) {
      formattedValue = value
        .map((v: any) => {
          return JSON.stringify(v);
        })
        .join(",");
    } else {
      formattedValue = JSON.stringify(value);
    }

    return {
      key: "filter[]",
      value: formattedValue || null,
    };
  }

  if ("product_filter_uuids" === key) {
    return {
      key: "product_filter[uuids][]",
      value: !!value ? value.join(",") : null,
    };
  }

  if ("product_filter_operator" === key) {
    return {
      key: "product_filter[operator]",
      value: value || null,
    };
  }

  return { key, value };
};

export const generateGoogleMapLink = ({
  latitude,
  longitude,
}: {
  latitude: string;
  longitude: string;
}) => `https://www.google.com/maps/@${latitude},${longitude},14z?entry=ttu`;
