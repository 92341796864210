/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useApiCaller, apiCaller } from "../../apis/config";
import {
  getSaleOrderDetailApi,
  IUpdateSaleOrderShippingService,
  updateSaleOrderShippingService,
} from "../../apis/paths/sale-order";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { SHIPPING_SERVICES_STATUS } from "../../constants/status";
import alert from "../../utils/alert";
import GeneralPanel from "../../components/panel-general/component";
import NotFoundPanel from "../../components/panel-not-found/component";
import UiLoadingPanel from "../../components/ui-loading-panel";
import HeaderInfoPanel from "../../components/panel-header-info";
import TableCellStatus from "../../components/table-cell-status";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import useHandleSidebarStatus from "../../hooks/useSidebarStatus";

import { schema } from "./schema";
import UiInput from "../../components/ui-input/component";
import UiButton from "../../components/ui-button/component";
import RadioComboBox from "../../components/ui-radio-combo-box/component";

const LIXHOUSE_CODE = "LixHouse";

const Component = () => {
  const [saleOrderInfo, setSaleOrderInfo] = useState<any>({});
  const [isSelectLixhouse, setIsSelectLixhouse] = useState(false);

  const { saleOrderId: uuid } = useParams();
  const [params] = useState({ uuid });

  const {
    register,
    control,
    watch,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const selectedShippingService = watch("shipping_service");

  useEffect(() => {
    if (!selectedShippingService) return;

    if (selectedShippingService === LIXHOUSE_CODE) {
      setIsSelectLixhouse(true);
      return;
    }

    setValue("tracking_code", "");
    setValue("shipping_service_name", "");

    setIsSelectLixhouse(false);
  }, [selectedShippingService]);

  const { dispatchSidebarStatus } = useHandleSidebarStatus();

  useEffect(() => {
    dispatchSidebarStatus({ isOpened: false });
    return () => {
      dispatchSidebarStatus({ isOpened: true });
    };
  }, [selectedShippingService]);

  const { result, loading } = useApiCaller({
    api: getSaleOrderDetailApi,
    params: params,
  });

  useEffect(() => {
    if (result?.data) {
      let saleOrderResult = result?.data;
      setSaleOrderInfo(saleOrderResult);
      setValue("shipping_service", saleOrderResult?.shipping_service);

      if (saleOrderResult?.shipping_service === LIXHOUSE_CODE) {
        setValue(
          "shipping_service_name",
          saleOrderResult?.shipping_service_name || ""
        );
        setValue("tracking_code", saleOrderResult?.tracking_code || "");
      }
    }
  }, [result]);

  const onSubmit = async ({
    shipping_service,
    shipping_service_name,
    tracking_code,
  }: any) => {
    if (shipping_service === LIXHOUSE_CODE) {
      if (!shipping_service_name || !tracking_code) {
        if (!shipping_service_name) {
          setError("shipping_service_name", {
            type: "required",
            message: "This field is required",
          });
        }
        if (!tracking_code) {
          setError("tracking_code", {
            type: "required",
            message: "This field is required",
          });
        }

        return;
      }
    }

    const waitedUpdate = await apiCaller({
      api: updateSaleOrderShippingService,
      params: {
        uuid,
        shipping_service,
        shipping_service_name,
        tracking_code,
      } as IUpdateSaleOrderShippingService,
    });

    if (waitedUpdate?.status === "SUCCESS") {
      alert.success("Update successful!");
      setTimeout(() => {
        window.location.reload();
      }, 500);
      return;
    }
    alert.error("Update Shipping Service unsuccessful!");
  };

  if (loading) return <UiLoadingPanel />;

  if (result?.status === "ERROR") return <NotFoundPanel />;

  return (
    <>
      <div className="mb-4">
        <HeaderInfoPanel
          isDisplayInfoArea={true}
          title={`#${saleOrderInfo?.number}`}
          infoArea={() => (
            <>
              <TableCellStatus
                status={saleOrderInfo?.status}
                size={GENERAL_UI_SIZE.SMALL}
              />
              <TableCellStatus
                className="ms-2"
                status={saleOrderInfo?.shipping_service}
                size={GENERAL_UI_SIZE.SMALL}
              />
            </>
          )}
          actionArea={() => (
            <>
              <UiButton
                onClick={handleSubmit(onSubmit)}
                className="me-2"
                title={"Update"}
              />
            </>
          )}
        />
      </div>
      <GeneralPanel className="mt-3">
        <RadioComboBox
          required
          control={control}
          options={SHIPPING_SERVICES_STATUS}
          title={"Shipping Service"}
          name="shipping_service"
        />

        {isSelectLixhouse && (
          <TwoColumnGridLayout
            className={{ container: "px-0 mt-4" }}
            leftCol={
              <>
                <UiInput
                  title={"Shipping Service Name"}
                  name={"shipping_service_name"}
                  type="text"
                  required
                  register={register}
                  hint="Please input Shipping Service Name and Tracking Code when you select LixHouse"
                  errorMessage={errors?.shipping_service_name?.message}
                />
              </>
            }
            rightCol={
              <UiInput
                title={"Tracking Code"}
                name={"tracking_code"}
                required
                type="string"
                register={register}
                errorMessage={errors?.tracking_code?.message}
              />
            }
          />
        )}
      </GeneralPanel>
    </>
  );
};

export default Component;
