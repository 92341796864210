import { NavLink } from "react-router-dom";
import classNames from "classnames";

import { GENERAL_UI_COLOR } from "../../constants/color";
import { htmlStripTag } from "../../utils/format";

import Avatar from "../ui-avatar/component";
import UiCopyIcon from "../ui-copy-icon/component";

import styles from "./styles.module.scss";

const Container: any = ({ children, linkTo, target }: any) =>
  !!linkTo && linkTo.length ? (
    <NavLink to={linkTo} target={target}>
      {children}
    </NavLink>
  ) : (
    <>{children}</>
  );

interface IComponent {
  image?: string;
  previewImage?: boolean;
  icon?: any;
  subIcon?: any;
  title?: string | number;
  description?: string;
  maxWidth?: number;
  minWidth?: number;
  className?: {
    container?: string;
    description?: string;
    title?: string;
  };
  extraField?: any;
  linkTo?: string;
  linkTarget?: string;
  descriptionLine?: number;
  userName?: any;
  allowDynamicAvatarBackgroundColor?: boolean;
  isForceDisplayAvatar?: boolean;
  imageSize?: number;
  align?: "start" | "center" | "end";
  displayStatusMode?: {
    workMode: string;
    outsideUntil?: number;
  };
  isOneLineTitle?: boolean;
  isCopyAble?: {
    title?: boolean;
    description?: boolean;
  };
}

const ProductTableCell = ({
  image,
  previewImage = true,
  title,
  description,
  maxWidth,
  minWidth,
  icon,
  subIcon,
  className = {
    container: "",
    description: "",
    title: "",
  },
  extraField,
  linkTo,
  linkTarget,
  descriptionLine = 1,
  userName,
  isForceDisplayAvatar = false,
  imageSize,
  align = "start",
  allowDynamicAvatarBackgroundColor = false,
  displayStatusMode,
  isOneLineTitle = true,
  isCopyAble = {
    title: false,
    description: false,
  },
}: IComponent) => {
  return (
    <Container linkTo={linkTo} target={linkTarget}>
      <div
        className={classNames(
          `d-flex ${styles.container} justify-content-${align}`,
          className?.container
        )}
      >
        {(image || isForceDisplayAvatar) && (
          <div className="me-2">
            {!!displayStatusMode ? (
              <Avatar
                imageSrc={image}
                name={userName}
                variant={GENERAL_UI_COLOR.SECONDARY}
                preview={previewImage}
                fixSize={imageSize}
                allowDynamicBackgroundColor={allowDynamicAvatarBackgroundColor}
                statusDot={{
                  workMode: displayStatusMode?.workMode,
                  outsideUntil: displayStatusMode?.outsideUntil,
                }}
              />
            ) : (
              <Avatar
                imageSrc={image}
                name={userName}
                variant={GENERAL_UI_COLOR.SECONDARY}
                preview={previewImage}
                fixSize={imageSize}
                allowDynamicBackgroundColor={allowDynamicAvatarBackgroundColor}
              />
            )}
          </div>
        )}
        {icon && (
          <div
            className=" d-flex justify-content-center align-items-center p-2"
            style={{ minWidth: 50 }}
          >
            {icon}
          </div>
        )}
        <div
          style={{ maxWidth: maxWidth, minWidth: minWidth }}
          className={`d-flex flex-column justify-content-center ${styles.content}`}
        >
          {title && (
            <h6
              className={classNames(
                "mb-0 text-sm text-truncate d-flex",
                isOneLineTitle ? styles.title : styles.preWrapTitle,
                className.title
              )}
            >
              {typeof title === "string" ? htmlStripTag(title) : title}
              {isCopyAble?.title && (
                <div className={styles.copyButton}>
                  <UiCopyIcon copyContent={title?.toString()} />
                </div>
              )}
            </h6>
          )}
          {description && (
            <p
              className={classNames(
                `text-xs font-weight-medium text-secondary mb-0 ${styles.description}`,
                className?.description
              )}
              style={{ WebkitLineClamp: descriptionLine }}
            >
              {subIcon && (
                <i className={`fa-solid ${subIcon} fa-sm opacity-4 me-1`}></i>
              )}
              {typeof description === "string"
                ? htmlStripTag(description)
                : description}

              {isCopyAble?.description && (
                <div className={styles.copyButton}>
                  <UiCopyIcon copyContent={description} />
                </div>
              )}
            </p>
          )}
          {extraField && (
            <p
              className={`text-xs font-weight-medium text-secondary mb-0 ${styles.description}`}
            >
              {extraField}
            </p>
          )}
        </div>
      </div>
    </Container>
  );
};

export default ProductTableCell;
