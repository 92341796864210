import { GENERAL_UI_COLOR } from "../../../constants/color";
import Avatar from "../../../components/ui-avatar/component";

import styles from "./styles.module.scss";
import SelectedItem from "./view-selected-item";

const ViewAssignee = ({ selectedAssignee }: { selectedAssignee: any }) => {
  return (
    <div className={styles.selectItem}>
      <SelectedItem
        label="Selected Assignee"
        noItemContent={"No Assignee Selected"}
        itemName={
          !!selectedAssignee && (
            <div className="d-flex justify-content-start align-items-center">
              <Avatar
                imageSrc={selectedAssignee?.avatar}
                name={selectedAssignee?.name}
                variant={GENERAL_UI_COLOR.SECONDARY}
                fixSize={36}
              />
              <div className="ms-2">{selectedAssignee?.name}</div>
            </div>
          )
        }
      />
    </div>
  );
};

export default ViewAssignee;
