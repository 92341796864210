import React from "react";
import { useNavigate } from "react-router-dom";

interface ICardProps {
  header?: React.ReactNode | string;
  imageSrc?: string;
  content?: React.ReactNode | string;
  handleClick?: (param?: any) => void;
}

const Card = ({ header, imageSrc, content, handleClick }: ICardProps) => {
  const navigate = useNavigate();

  return (
    <div
      className="card"
      onClick={() => {
        navigate("");
        handleClick && handleClick();
      }}
    >
      <div className="card-body">
        {header && (
          <div className="card-title h5 d-block text-darker">
            {header}
          </div>
        )}
        <div className="author align-items-center">
          <img src={imageSrc} alt="" className="avatar shadow" />
          <div className="name ps-3">{content}</div>
        </div>
      </div>
    </div>
  );
};

export default Card;
