import { useWindowResize } from "../../hooks/useWindowResize";
import Typography from "../../components/ui-typography/component";
import PanelGeneral from "../../components/panel-general/component";
import DoughnutChart from "../../components/ui-doughnut-chart/component";

import { LARGE_SCREEN_SIZE, MEDIUM_SCREEN_SIZE } from "../dashboard/component";

interface IDoughnutProps {
  productCountByStatusResult: Record<string, number>;
  onClick?: (elements: Array<Record<string, number>>) => void;
}

const Component = ({ productCountByStatusResult, onClick }: IDoughnutProps) => {
  const inboundDoughnutInput = {
    labels: ["Checked", "Checking", 'Rechecking', 'Unchecked'],
    datasets: [
      {
        label: "Quantity",
        data: [
          productCountByStatusResult?.checked,
          productCountByStatusResult?.checking,
          productCountByStatusResult?.rechecking,
          productCountByStatusResult?.unchecked,
        ],
        cutout: 85,
        backgroundColor: [
          "rgba(40, 167, 69, 0.2)",
          "rgba(23, 162, 184, 0.2)",
          "rgba(255, 193, 7, 0.2)",
          "rgba(128, 128, 128, 0.2)",
        ],
        hoverBackgroundColor: [
          "rgba(40, 167, 69, 0.6)",
          "rgba(23, 162, 184, 0.6)",
          "rgba(255, 193, 7, 0.6)",
          "rgba(128, 128, 128, 0.6)",
        ],
        borderColor: [
          "rgba(40, 167, 69, 1)",
          "rgba(23, 162, 184, 1)",
          "rgba(255, 193, 7, 1)",
          "rgba(128, 128, 128, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const { windowWidth } = useWindowResize();
  const isLargeScreen = windowWidth && windowWidth >= LARGE_SCREEN_SIZE;
  const isMediumScreen =
    (windowWidth && windowWidth <= MEDIUM_SCREEN_SIZE) || true;

  return (
    <div>
      <PanelGeneral className="position-relative w-100">
        <Typography
          className="text-center my-3"
          customFontsize={14}
          fontWeight="fw-bold"
        >
          Progress (Products)
        </Typography>
        <DoughnutChart
          data={inboundDoughnutInput}
          width={isMediumScreen ? 280 : 320}
          height={280}
          legend={{ position: !isLargeScreen ? "bottom" : "right" }}
          onClick={(elements: Array<Record<string, number>>) => {
            onClick && onClick(elements);
          }
          }
        />
      </PanelGeneral>
    </div>
  );
};

export default Component;
