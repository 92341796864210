import React, { memo } from "react";
import classNames from "classnames";

import { IKanbanItem } from "./component";
import Typography from "../../components/ui-typography/component";
import UiLoadingPanel from "../../components/ui-loading-panel";

import styles from "./styles.module.scss";
import ViewCardKanban from "./view-card-kanban";

export interface IFilterOption {
  page: number;
  per?: number;
  keyword?: string;
  filter?: object | null;
}

interface IViewKanbanItem {
  filterOption: IKanbanItem;
  saleOrders: any;
  onShowMore: () => void;
  onCollapse: () => void;
}

const ViewKanbanItem = ({
  filterOption,
  saleOrders,
  onShowMore,
  onCollapse,
}: IViewKanbanItem) => {
  const shouldDisplayShowmoreButton =
    saleOrders?.paging?.current_page < saleOrders?.paging?.total_pages;

  return (
    <div
      className={classNames(styles.kanbanListContainer)}
      style={{ height: `calc(100vh -120px)` }}
    >
      <div className={classNames(styles.kanbanHeader)}>
        <div className="d-flex align-items-center gap-2">
          <Typography
            fontSize="fs-5"
            fontWeight="fw-bold"
            textColor="black"
            className="py-3"
          >
            {filterOption?.title}
          </Typography>
          <Typography fontWeight="fw-bold">
            {`${saleOrders?.list?.length || 0} / ${
              saleOrders?.paging?.total_count || 0
            }`}
          </Typography>
        </div>
        <div
          className={classNames("cursor-pointer", styles.collapseIcon)}
          onClick={onCollapse}
        >
          <i className="fa-solid fa-minimize "></i>
        </div>
      </div>

      <div className={classNames(styles.kanbanList, "d-flex flex-column")}>
        {Array.isArray(saleOrders?.list) ? (
          saleOrders?.list?.map((saleOrder: any, index: number) => (
            <ViewCardKanban
              index={index}
              saleOrder={saleOrder}
              key={saleOrder?.uuid}
            />
          ))
        ) : (
          <UiLoadingPanel />
        )}

        {shouldDisplayShowmoreButton && (
          <data
            onClick={() => onShowMore()}
            className={styles.showMore}
            title="Show more"
          >
            <i className={`fas fa-angle-down text-sm ${styles.icon}`}></i>
          </data>
        )}
      </div>
    </div>
  );
};

export default memo(ViewKanbanItem);
