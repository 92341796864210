import { GENERAL_STATUS } from "../../constants/status";
import { GENERAL_UI_SIZE } from "../../constants/size";
import TableCellStatus from "../../components/table-cell-status";

import ViewInputProductItem from "./view-input-product-item";
import styles from "./styles.module.scss";

const Component = ({
  label,
  products,
  onDeleteItem,
  onUpdateItem,
  customPanelHeight,
  isDisplayStatusLabel = true,
  isDisplayRequireMark = true,
  latestSelectedProduct,
}: {
  label: string;
  products: any;
  onDeleteItem: any;
  onUpdateItem: any;
  customPanelHeight?: number;
  isDisplayStatusLabel?: boolean;
  isDisplayRequireMark?: boolean;
  latestSelectedProduct?: any;
}) => {
  return (
    <>
      <div className={styles.selectedProductHeading}>
        <label data-required={isDisplayRequireMark}>{label}</label>
        {isDisplayStatusLabel && (
          <TableCellStatus
            status={
              products && products.length
                ? GENERAL_STATUS.SELECTED
                : GENERAL_STATUS.MISSING
            }
            size={GENERAL_UI_SIZE.SMALL}
          />
        )}
      </div>
      <div
        className={styles.selectedProducts}
        style={{ maxHeight: customPanelHeight }}
      >
        {!products || !products.length ? (
          <span className={styles.empty}>No product selected</span>
        ) : (
          products?.map((product: any) => (
            <ViewInputProductItem
              onDeleteItem={onDeleteItem}
              onUpdateItem={onUpdateItem}
              product={product}
              key={product?.uuid}
              latestSelectedProduct={latestSelectedProduct}
            />
          ))
        )}
      </div>
    </>
  );
};

export default Component;
