import React from "react";
import GridLayoutTwoColumn from "../../components/grid-layout-two-column/component";
import ContentSidebar from "./view-content-sidebar";
import { Outlet } from "react-router-dom";

const RoutingNavigationLayoutWithParams = ({
  sidebarContent,
  paramIds,
  widthDivision = [3, 9],
}: {
  sidebarContent: any;
  paramIds: string[];
  widthDivision?: [number, number];
}) => {
  return (
    <GridLayoutTwoColumn
      widthDivision={widthDivision}
      leftCol={
        <ContentSidebar sidebarContent={sidebarContent} paramIds={paramIds} />
      }
      rightCol={<Outlet />}
    />
  );
};

export default RoutingNavigationLayoutWithParams;
