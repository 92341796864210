import { useEffect } from "react";
import classNames from "classnames";

import useHandleSidebarStatus from "../../hooks/useSidebarStatus";

import styles from "./styles.module.scss";
import ViewKanbanList from "./view-kanban-list";

const definedFilterParams: Record<string, IKanbanItem> = {
  backlog: {
    title: "Backlog",
    filterParams: {
      field: "status",
      values: ["pending", "confirmed"],
      operator: "in",
    },
  },
  todo: {
    title: "Todo",
    filterParams: {
      field: "status",
      values: ["ready"],
      operator: "in",
    },
  },
  picking: {
    title: "Picking",
    filterParams: { field: "status", values: ["picking"], operator: "in" },
  },
  picked: {
    title: "Picked",
    filterParams: { field: "status", values: ["picked"], operator: "in" },
  },
  packProcess: {
    title: "Pack Process",
    filterParams: {
      field: "status",
      values: ["packing", "packed"],
      operator: "in",
    },
  },
  handovering: {
    title: "Handovering",
    filterParams: {
      field: "status",
      values: ["handovering"],
      operator: "in",
    },
  },
};

export interface IKanbanItem {
  title: string;
  filterParams: {
    field: string;
    values: string[];
    operator: string;
  };
}

const Component = () => {
  const { dispatchSidebarStatus } = useHandleSidebarStatus();

  useEffect(() => {
    dispatchSidebarStatus({ isOpened: false });
    return () => {
      dispatchSidebarStatus({ isOpened: true });
    };
  }, []);

  return (
    <main
      className={classNames(
        "main-content position-relative border-radius-lg kanban-layout",
        styles.mainContainer
      )}
    >
      <div
        className="mt-3 kanban-container-custom"
        style={{ maxHeight: `calc(100vh - 100px)` }}
      >
        <div className="py-2 d-inline-flex">
          <div className={classNames(styles.subContainer, "d-flex gap-3")}>
            {Object.entries(definedFilterParams)?.map(([key, value]) => (
              <ViewKanbanList filterOption={value} key={key} />
            ))}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Component;
