import { useState } from "react";
import ReactPaginate from "react-paginate";

interface IComponent {
  initialPage?: number;
  pageCount: number;
  onPageChange?: (data: number) => void;
}

const Component = ({
  initialPage = 0,
  pageCount,
  onPageChange,
  ...rest
}: IComponent) => {
  const [currentPage, setCurrentPage] = useState(initialPage - 1);

  const hanldePageChange = ({ selected }: { selected: number }) => {
    if (currentPage !== selected) {
      setCurrentPage(selected);
      onPageChange && onPageChange(selected + 1);
    }
  };

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel="Next"
      marginPagesDisplayed={1}
      initialPage={currentPage}
      onPageChange={hanldePageChange}
      pageRangeDisplayed={5}
      pageCount={pageCount}
      previousLabel="Prev"
      renderOnZeroPageCount={null}
      {...rest}
    />
  );
};

export default Component;
