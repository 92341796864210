import classNames from "classnames";
import React from "react";
import styles from "../styles.module.scss";
import { useApiCaller } from "../../../apis/config";
import { DEFAULT_PAGINATION_PARAMS } from "../../../constants/pagination";
import { getNamesMapper } from "../../../apis/paths/taskLogging";
import Typography from "../../../components/ui-typography/component";
import { capitalize, splitAndCapitalize } from "../utils";

export interface ITaskType {
  id: number;
  name: string;
  icon: React.ReactNode;
  type: "inbound_putaway" | "inbound_qc" | "outbound_picking" | "outbound_qc";
}
export interface ITask {
  name: string;
  pickObject: Record<string, string>;
}

const groupCategoryNameMapper = (nameMapper: any) => {
  const groupedArray: any = [];

  for (const categoryKey in nameMapper) {
    if (nameMapper.hasOwnProperty(categoryKey)) {
      const category = categoryKey.replace(/_(\w+)/, "");

      const categoryData = {
        name: categoryKey,
        pickObject: nameMapper[categoryKey].pick_objects,
      };

      const existingCategory = groupedArray.find((item: any) => item[category]);
      if (existingCategory) {
        existingCategory[category].push(categoryData);
      } else {
        groupedArray.push({ [category]: [categoryData] });
      }
    }
  }

  return groupedArray;
};

const TaskTypeSelect = ({
  taskType,
  setTaskType,
  errorMessage,
}: {
  taskType: string;
  setTaskType: (params: number | string, objectType: ITask) => void;
  errorMessage?: any;
}) => {
  const { result } = useApiCaller({
    api: getNamesMapper,
    params: DEFAULT_PAGINATION_PARAMS,
  });

  const nameMapper = result?.data;

  const mappingNameMapper = groupCategoryNameMapper(nameMapper);

  type TNameMapper = Record<string, ITask[]>;

  return (
    <div>
      <div className="row text-center">
        <div className="col-10 mx-auto">
          <h5 className="font-weight-normal">Select your task type</h5>
          <p>Give us what type of task you want to create.</p>
        </div>
      </div>
      <div className=" ">
        {errorMessage && (
          <label className="mb-0" style={{ color: "#f5365c" }}>
            Please select task type!
          </label>
        )}
        <div className="row mt-4 justify-content-center gap-4">
          {mappingNameMapper?.map((task: TNameMapper, index: number) => {
            return (
              <div key={index}>
                {Object.entries(task)?.map(([key, types]) => {
                  return (
                    <div key={key}>
                      <Typography
                        fontWeight="fw-bolder"
                        className="text-start"
                        textColor="#344767"
                        customFontsize={15}
                      >
                        {capitalize(key)}
                      </Typography>
                      <div className="d-flex align-items-center gap-3 mt-3">
                        {types?.map((type: ITask) => {
                          return (
                            <div
                              className={classNames(
                                "col-sm-3 w-20 d-flex justify-content-center flex-column"
                              )}
                              key={type.name}
                              onClick={() => {
                                setTaskType(type.name, type as any);
                              }}
                            >
                              <div
                                className={classNames(
                                  "btn btn-lg border-2 px-6 py-5",
                                  styles.taskItem,
                                  taskType === type.name && styles.active
                                )}
                              >
                                <div
                                  className={classNames(
                                    styles.icon,
                                    taskType === type.name && styles.activeIcon
                                  )}
                                >
                                  {splitAndCapitalize(type.name)}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TaskTypeSelect;
