import { useParams } from "react-router-dom";
import { useState } from "react";

import styles from "./styles.module.scss";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { getSaleOrderDetailApi } from "../../apis/paths/sale-order";
import { useApiCaller } from "../../apis/config";

import HeaderInfoPanel from "../../components/panel-header-info";
import TableCellStatus from "../../components/table-cell-status";
import UiLoadingPanel from "../../components/ui-loading-panel";
import NotFoundPanel from "../../components/panel-not-found";
import PanelStateAuditActivities from "../../components/panel-state-audit-activities/component";

const Component = () => {
  const { saleOrderId: uuid } = useParams();

  const [params] = useState({ uuid });

  const { result, loading } = useApiCaller({
    api: getSaleOrderDetailApi,
    params,
  });

  const saleOrderDetail = result?.data;

  if (loading) return <UiLoadingPanel />;

  if (result?.status === "ERROR") return <NotFoundPanel />;

  return (
    <>
      <HeaderInfoPanel
        title={`#${saleOrderDetail?.number}`}
        infoArea={() => (
          <div className={styles.headerInfoArea}>
            <TableCellStatus
              className="me-2"
              status={saleOrderDetail?.status}
              size={GENERAL_UI_SIZE.SMALL}
            />
          </div>
        )}
      />

      <div className="mt-4">
        {uuid && (
          <PanelStateAuditActivities
            objectCode={uuid as string}
            objectType="sale_order"
          />
        )}
      </div>
    </>
  );
};

export default Component;
