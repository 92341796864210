const Component = ({ children }: { children: any }) => (
  <h5
    style={{ textTransform: "uppercase" }}
    className="text-sm mb-3 font-bold pb-3"
  >
    {children}
  </h5>
);

export default Component;
