import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

import {
  editLabelsApi,
  getLinkObjectLabelsApi,
  unassignLabelsApi,
} from "../../apis/paths/labels";
import { apiCaller, useApiCaller } from "../../apis/config";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { API_RESPONSE_STATUS } from "../../constants/api";
import Badge from "../../components/ui-badge-button/component";
import alert from "../../utils/alert";
import Typography from "../ui-typography/component";
import TableDataPanel from "../../components/panel-table-data";
import PanelAddLabels from "../../components/ui-panel-add-labels/component";

import { useLabelSchema } from "./schema";
import styles from "./styles.module.scss";
import LabelDetailPopup from "./view-label-detail";

interface IPanelLabelController {
  linkedObjectType: string;
  isEditable?: boolean;
  uniqueUuid: string;
  isDisplayPrintButton?: boolean;
  onPrint?: any;
  onReceiveResult?: (result: any) => void;
}

const PanelLabelController = ({
  linkedObjectType,
  isEditable = true,
  uniqueUuid,
  isDisplayPrintButton = false,
  onPrint,
  onReceiveResult,
}: IPanelLabelController) => {
  const [pageReloadVersion, setPageReloadVersion] = useState(1);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isShowPopUpModal, setIsShowPopUpModal] = useState(false);
  const [viewedLabel, setViewedLabel] = useState<any | null>(null);
  const [editingLabel, setEditingLabel] = useState<any | null>(null);
  const originalNoteRef = useRef<any | null>(null);

  const [labelParams] = useState({
    linked_object_type: linkedObjectType,
    linked_object_uuid: uniqueUuid,
  });

  const { result: labels } = useApiCaller({
    api: getLinkObjectLabelsApi,
    params: labelParams,
    pageReloadVersion,
  });

  const selectedLabels = labels?.data?.list?.map(
    (item: any) => item?.label?.uuid
  );

  useEffect(() => {
    if (Array.isArray(labels?.data?.list)) {
      onReceiveResult?.(labels?.data?.list);
    }
  }, [labels]);

  const handleUnassignLabels = async (uuid: string) => {
    const waitDelete = await apiCaller({
      api: unassignLabelsApi,
      params: { label_assignment_uuid: uuid },
    });

    if (waitDelete.status === API_RESPONSE_STATUS.SUCCESS) {
      setPageReloadVersion((prev) => prev + 1);
      return;
    }

    alert.error("Unassign label was unsuccessful!. Please try again");
  };

  const handleEditLabels = async (labelUuid: string) => {
    const waitAddLabel = await apiCaller({
      api: editLabelsApi,
      params: {
        label_assignment_uuid: labelUuid,
        note: editingLabel?.note,
      },
    });

    if (waitAddLabel.status === API_RESPONSE_STATUS.SUCCESS) {
      setPageReloadVersion((prev) => prev + 1);
      return;
    }

    alert.error("Assign label was unsuccessful!. Please try again");
  };

  return (
    <>
      <LabelDetailPopup
        content={
          <TableDataPanel
            data={[viewedLabel]}
            schema={useLabelSchema({
              editingLabel,
              setEditingLabel,
              onEditLabel: handleEditLabels,
              isEditMode,
              setViewedLabel,
              originalNoteRef,
              isDisplayPrintButton,
              onPrint,
            })}
          />
        }
        isShowModal={isShowPopUpModal}
        handleCloseModal={() => {
          setIsShowPopUpModal(false);
        }}
      />

      <div className="d-flex justify-content-between align-items-start w-100">
        <div className="d-flex gap-3 flex-column w-100">
          {Array.isArray(labels?.data?.list) &&
          labels?.data?.list?.length > 0 ? (
            labels?.data?.list?.map((item: any) => (
              <div
                className="d-flex gap-3 align-items-center w-100"
                key={item?.uuid}
              >
                <div className="d-flex align-items-center position-relative ">
                  <Badge
                    content={item?.label?.name}
                    type={item?.label?.color}
                    size={GENERAL_UI_SIZE.SMALL}
                    className="mb-0"
                    onClick={() => {
                      setViewedLabel(item);
                      setIsShowPopUpModal(true);
                    }}
                  />
                  {isEditMode && (
                    <div
                      className={classNames(
                        "d-flex mb-3 ms-1 badge badge-primary cursor-pointer",
                        styles.cancelBadge
                      )}
                      onClick={() => {
                        handleUnassignLabels(item?.uuid);
                      }}
                    >
                      <i className="fa-solid fa-x"></i>
                    </div>
                  )}
                </div>
                {!isEditMode && (
                  <div className="d-flex align-items-center justify-content-between flex-grow-1">
                    <Typography
                      fontWeight="fw-normal"
                      className={classNames("w-100", styles.elipse)}
                    >
                      {item?.note}
                    </Typography>
                    {isEditMode && (
                      <div
                        className={classNames(
                          "cursor-pointer",
                          styles.editAction
                        )}
                        onClick={() => {
                          setIsEditMode(true);
                          setEditingLabel(item);
                          originalNoteRef.current = item;
                        }}
                      >
                        <i className="fa-solid fa-pen-to-square fa-sm"></i>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))
          ) : (
            <Typography customFontsize={14}>No labels</Typography>
          )}
        </div>
        {isEditable && (
          <div
            className={classNames("cursor-pointer", styles.editAction)}
            onClick={() => {
              setIsEditMode((prev) => !prev);
            }}
          >
            {!isEditMode ? (
              <i className="fa-solid fa-pen-to-square fa-xl"></i>
            ) : (
              <i className="fa-solid fa-x fa-xl"></i>
            )}
          </div>
        )}
      </div>

      {isEditMode && (
        <PanelAddLabels
          linkedObjectType={linkedObjectType}
          linkedObjectUuid={uniqueUuid as string}
          selectedLabels={selectedLabels}
          onPageReload={setPageReloadVersion}
        />
      )}
    </>
  );
};

export default PanelLabelController;
