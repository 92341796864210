import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { apiCaller, useApiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { GENERAL_STATUS } from "../../constants/status";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { PRODUCTS_LIST } from "../../routings/path";
import { STRATEGY } from "../../constants/product";
import {
  getProductTypeListApi,
  createProductApi,
  ICreateProductApi,
} from "../../apis/paths/products";
import alert from "../../utils/alert";
import SmallCollumnCenterGridLayout from "../../components/grid-layout-small-collumn-center";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import HeaderInfoPanel from "../../components/panel-header-info";
import GeneralPanel from "../../components/panel-general";
import UiTextArea from "../../components/ui-text-area";
import ProductTypesSearchBox from "../product-edit/view-product-type-search-box";
import UiButton from "../../components/ui-button";
import UiInput from "../../components/ui-input";
import ImageUploader from "../../components/ui-image-uploader/component";
import UiSelectDropdown, {
  IOptionItem,
} from "../../components/ui-select-dropdown/component";
import SelectDropdown from "../../components/ui-select-dropdown/component";

import { schema } from "./schema";
import { CAPACITY_UNIT } from "./constants";

const Component = () => {
  const [selectedItems, setSelectedItems] = useState<any>([]);

  const { result: productTypes } = useApiCaller({
    api: getProductTypeListApi,
    params: DEFAULT_PAGINATION_PARAMS,
  });

  const formatedAllOptionProductTypes = productTypes?.data?.list?.map(
    (productType: any) => ({
      value: productType.id,
      content: productType.name,
    })
  );

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const uploadImage: any = watch("upload_image");

  useEffect(() => {
    setValue(
      "product_types",
      selectedItems?.map((item: any) => ({ id: item.value }))
    );
  }, [selectedItems]);

  const onSubmit = async ({
    name,
    short_name,
    description,
    reference_code,
    capacity_value,
    capacity_unit,
    display_width,
    display_length,
    display_height,
    packaging_weight,
    packaging_width,
    packaging_height,
    packaging_length,
    product_types,
    image_url,
    strategy,
  }: any) => {
    const data: ICreateProductApi = {
      name,
      short_name,
      description,
      reference_code,
      capacity_value,
      capacity_unit,
      display_width,
      display_length,
      display_height,
      packaging_weight,
      packaging_width,
      packaging_height,
      packaging_length,
      image_url,
      upload_image: uploadImage?.[0]?.file,
      status: GENERAL_STATUS.DEACTIVE,
      product_types,
      strategy,
    };

    const formData = new FormData();

    for (const key in data) {
      if (key === "product_types" && Array.isArray(data[key])) {
        data[key].forEach((product: any) => {
          if (product.hasOwnProperty("id")) {
            formData.append(`product_types[][id]`, product.id);
          }
        });
      } else {
        if (!!data[key as keyof ICreateProductApi]) {
          formData.append(key, data[key as keyof ICreateProductApi]);
        }
      }
    }

    const waitUpdateProduct = await apiCaller({
      api: createProductApi,
      params: formData,
    });

    if (waitUpdateProduct.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update successful!");
      setTimeout(() => {
        navigate(PRODUCTS_LIST);
      }, 500);

      return;
    }

    alert.error("Update was not successful!. Please try again!");
  };

  const formatOptionItems = (data: string[]) =>
    data.map((option) => ({
      value: option,
      content: option,
    }));

  return (
    <>
      <HeaderInfoPanel
        title={"Create new product"}
        description={"Input info below to create a new product"}
        actionArea={() => (
          <>
            <UiButton
              onClick={handleSubmit(onSubmit)}
              className="me-2"
              title={"Create"}
            />
          </>
        )}
      />
      <SmallCollumnCenterGridLayout>
        <GeneralPanel panel={{ subTitle: "Product Information" }}>
          <TwoColumnGridLayout
            className={{ container: "px-0" }}
            leftCol={
              <>
                <UiInput
                  title={"Name"}
                  name={"name"}
                  required
                  register={register}
                  errorMessage={errors?.name?.message}
                />
                <UiInput
                  title={"Short name"}
                  name={"short_name"}
                  register={register}
                  errorMessage={errors?.short_name?.message}
                />
                <UiInput
                  required
                  title={"Image URL"}
                  name={"image_url"}
                  register={register}
                  errorMessage={errors?.image_url?.message}
                />
                <UiTextArea
                  title={"Description"}
                  name={"description"}
                  register={register}
                  errorMessage={errors?.description?.message}
                  rows={6}
                />
              </>
            }
            rightCol={
              <>
                <UiInput
                  title={"Reference Code"}
                  required
                  name={"reference_code"}
                  register={register}
                  errorMessage={errors?.reference_code?.message}
                />
                <SelectDropdown
                  name="strategy"
                  className="w-100"
                  title="Strategy"
                  isDisplayLabel
                  register={register}
                  setValue={setValue}
                  optionList={STRATEGY}
                />
                {formatedAllOptionProductTypes &&
                  formatedAllOptionProductTypes.length > 0 && (
                    <ProductTypesSearchBox
                      optionList={formatedAllOptionProductTypes}
                      setSelectedItems={setSelectedItems}
                      formItem={{}}
                      errorMessage={errors?.product_types?.message}
                    />
                  )}
                <ImageUploader
                  isDisplayLabel
                  title="Image Upload File"
                  isDisplayRemoveAll={false}
                  name="upload_image"
                  setValue={setValue}
                  errorMessage={errors?.upload_image?.message}
                />
              </>
            }
          />
        </GeneralPanel>

        <GeneralPanel panel={{ subTitle: "Packaging Information" }}>
          <TwoColumnGridLayout
            className={{ container: "px-0" }}
            leftCol={
              <>
                <UiInput
                  title={"Capacity Value"}
                  name={"capacity_value"}
                  type="number"
                  register={register}
                  errorMessage={errors?.capacity_value?.message}
                />
                <UiSelectDropdown
                  name="capacity_unit"
                  title="Capacity Unit"
                  isDisplayLabel
                  register={register}
                  optionList={formatOptionItems(CAPACITY_UNIT) as IOptionItem[]}
                  errorMessage={errors?.capacity_unit?.message}
                />
                <UiInput
                  title={"Display Width"}
                  name={"display_width"}
                  register={register}
                  type="number"
                  errorMessage={errors?.display_width?.message}
                />
                <UiInput
                  title={"Display Length"}
                  name={"display_length"}
                  register={register}
                  type="number"
                  errorMessage={errors?.display_length?.message}
                />
                <UiInput
                  title={"Display Height"}
                  name={"display_height"}
                  register={register}
                  type="number"
                  errorMessage={errors?.display_height?.message}
                />
              </>
            }
            rightCol={
              <>
                <UiInput
                  title={"Packaging Weight"}
                  name={"packaging_weight"}
                  register={register}
                  type="number"
                  errorMessage={errors?.packaging_weight?.message}
                />
                <UiInput
                  title={"Packaging Width"}
                  name={"packaging_width"}
                  register={register}
                  type="number"
                  errorMessage={errors?.packaging_width?.message}
                />
                <UiInput
                  title={"Packaging Height"}
                  name={"packaging_height"}
                  register={register}
                  type="number"
                  errorMessage={errors?.packaging_height?.message}
                />
                <UiInput
                  title={"Packaging Length"}
                  name={"packaging_length"}
                  register={register}
                  type="number"
                  errorMessage={errors?.packaging_length?.message}
                />
              </>
            }
          />
        </GeneralPanel>
      </SmallCollumnCenterGridLayout>
    </>
  );
};

export default Component;
