import { useEffect, useState } from "react";
import classNames from "classnames";

import { useDebounce } from "../../hooks/useDebounce";
import { getGeoWardApi } from "../../apis/paths/warehouse";
import { useApiCaller } from "../../apis/config";
import ModalGeneral from "../ui-modal-general/component";
import Typography from "../ui-typography/component";

import InputSearch from "./view-input-search";
import styles from "./styles.module.scss";

interface IGeoSearchSelector {
  isShowModal: boolean;
  onCancel: any;
  setIsShowModal: any;
  setWardValue: any;
}

export interface IGeo {
  key: number;
  value: string;
}

const GeoSearchSelector = ({
  isShowModal = false,
  onCancel,
  setIsShowModal,
  setWardValue,
}: IGeoSearchSelector) => {
  const [value, setValue] = useState("");

  const debouncedValue = useDebounce(value, 500);

  const [geoParams, setGeoParams] = useState({
    keyword: " ",
    limit: 15,
  });

  useEffect(() => {
    setGeoParams((prev: any) => ({
      ...prev,
      keyword: debouncedValue,
    }));
  }, [debouncedValue, setGeoParams]);

  const { result: geoLocations } = useApiCaller({
    api: getGeoWardApi,
    params: geoParams,
  });

  const mappingGeos = Object.entries(geoLocations?.data || [])?.map(
    ([key, value]) => ({
      key: Number(key),
      value,
    })
  );

  return (
    <ModalGeneral
      isShowModal={isShowModal}
      onCancel={onCancel}
      className={{ body: "p-0 overflow-hidden" }}
      header={<Typography fontWeight="fw-bold">Search the location</Typography>}
      isShowFooter={false}
    >
      <InputSearch
        value={value}
        setValue={setValue}
        placeholder={"Ex: An Khanh, Thu Duc, Ho Chi Minh"}
      />
      {(mappingGeos as IGeo[])?.map((geo) => (
        <div
          onClick={() => {
            setWardValue(geo);
            setIsShowModal(false);
          }}
          className={classNames(
            "d-flex justify-content-between p-2 px-3 cursor-pointer ",
            styles.selectItem
          )}
        >
          {geo?.value}
        </div>
      ))}
    </ModalGeneral>
  );
};

export default GeoSearchSelector;
