import classNames from "classnames";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  IParameterInfo,
  dynamicFormSchema,
} from "../../utils/dynamicFormSchema";
import {
  getDynamicDocsApi,
  getSettingsConstantApi,
  updateAdvancedSettingsApi,
} from "../../apis/paths/settings";
import { apiCaller, useApiCaller } from "../../apis/config";
import { splitAndCapitalize } from "../task-logging-create/utils";
import { API_RESPONSE_STATUS } from "../../constants/api";
import UiInput from "../../components/ui-input";
import alert from "../../utils/alert";
import UiForm from "../../components/ui-form";
import UiButton from "../../components/ui-button";
import Typography from "../../components/ui-typography/component";
import SelectDropdown from "../../components/ui-select-dropdown/component";

import { splitFieldByBrackets } from "./utils";
import styles from "./style.module.scss";
import DynamicInput from "./view-dynamic-array-input";

const ADVANCED_CONSTANT = "/wms/constants/advanced";

const CompanyInfo = () => {
  const [pageReloadVersion, setPageReloadVersion] = useState(1);

  const [params] = useState<{
    page: number;
    per: number;
  }>({
    page: 1,
    per: 50,
  });

  const { result } = useApiCaller({
    api: getSettingsConstantApi,
    params,
    pageReloadVersion,
  });

  const { result: docs } = useApiCaller({
    api: getDynamicDocsApi,
    params,
  });

  const advancedSettingParameters =
    docs?.data?.paths?.[ADVANCED_CONSTANT]?.put?.parameters;
  const advancedSettingInfo = result?.data?.advanced;
  const schema = dynamicFormSchema?.(advancedSettingParameters);

  const {
    setValue,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  useEffect(() => {
    if (!advancedSettingInfo) return;

    const keys = Object.keys(schema?.fields);

    keys.forEach((key: any) => {
      const groupKey = key.split("-");

      if (groupKey[1] === "undefined") {
        setValue(key, advancedSettingInfo?.[groupKey[0]] as any);
        return;
      }

      setValue(
        key,
        (advancedSettingInfo?.[groupKey[0]] as any)?.[groupKey[1] as any]
      );
    });
  }, [result, docs]);

  const onSubmit = async (formData: Record<string, any>, event: any) => {
    event.preventDefault();
    const transformData: Record<string, Record<string, number>> = {};

    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        const parts = key.split("-");
        const groupName = parts[0];
        const subKey = parts.slice(1).join("-");

        if (subKey === "undefined") {
          transformData[groupName] = formData[key];
          continue;
        }

        if (!transformData[groupName]) {
          transformData[groupName] = {};
        }

        transformData[groupName][subKey] = formData[key];
      }
    }

    const waitUpdateAdvancedSetting = await apiCaller({
      api: updateAdvancedSettingsApi,
      params: { data: transformData },
    });

    if (waitUpdateAdvancedSetting?.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update successful!");
      setPageReloadVersion((prev) => prev + 1);
      return;
    }

    alert.error("Update was not successful!. Please try again!");
  };

  const groupedData: Record<string, any> = {};

  advancedSettingParameters?.forEach((setting: Record<string, any>) => {
    const groupName =
      setting?.additionalProperties?.group ||
      setting?.items?.additionalProperties?.group;

    if (!groupedData[groupName]) {
      groupedData[groupName] = [];
    }
    groupedData[groupName].push(setting);
  });

  const generateInputItems = (settingParameter: IParameterInfo) => {
    const { parentText, childText } = splitFieldByBrackets(
      settingParameter?.name as string
    );

    if (settingParameter?.enum && settingParameter?.type === "string") {
      const mappedStrategy = settingParameter?.enum?.map(
        (enumValue: string) => ({
          value: enumValue,
          content: splitAndCapitalize(enumValue),
        })
      );

      return (
        <SelectDropdown
          name={`${parentText}-${childText}`}
          className="w-100"
          title="Stategy"
          isDisplayLabel
          register={register}
          setValue={setValue}
          optionList={mappedStrategy || []}
        />
      );
    } else if (settingParameter?.type === "string") {
      return (
        <UiInput
          className="dataTable-input"
          name={`${parentText}-${childText}`}
          title={settingParameter?.description}
          required={settingParameter?.required}
          isDisplayLabel={true}
          inline={true}
          type="text"
          register={register}
          errorMessage={errors[`${parentText}-${childText}`]?.message as string}
        />
      );
    } else if (settingParameter?.type === "number") {
      return (
        <UiInput
          className="dataTable-input"
          name={`${parentText}-${childText}`}
          title={settingParameter?.description}
          required={settingParameter?.required}
          isDisplayLabel={true}
          inline={true}
          type="number"
          register={register}
          errorMessage={errors[`${parentText}-${childText}`]?.message as string}
        />
      );
    } else if (settingParameter?.type === "boolean") {
      return (
        <UiInput
          name={`${parentText}-${childText}`}
          title={settingParameter?.description}
          isDisplayLabel={true}
          inline={true}
          type="checkbox"
          register={register}
          errorMessage={errors[`${parentText}-${childText}`]?.message as string}
        />
      );
    } else if (settingParameter?.type === "array") {
      return (
        <DynamicInput
          required={settingParameter?.required as boolean}
          title={settingParameter?.description as string}
          formItems={watch(`${parentText}-${childText}`)}
          name={`${parentText}-${childText}`}
          setValue={setValue}
          errorMessage={errors[`${parentText}-${childText}`]?.message as string}
        />
      );
    }
  };

  const handleCheckKeyDown = (e: any) => {
    if (e.key === "Enter") e.preventDefault();
  };

  return (
    <UiForm onSubmit={handleSubmit(onSubmit)} onKeyDown={handleCheckKeyDown}>
      <div className="card card-profile-bottom">
        <div className="card position-sticky top-1">
          <div className="card-header pb-0">
            <div className="d-flex justify-content-between">
              <div>
                <h5>Advanced Setting</h5>
                <p className="text-sm">
                  Here you can edit more advanced factor of system.
                </p>
              </div>
              <div className="d-flex align-items-center ">
                <UiButton
                  className="me-2"
                  title={"Update"}
                  onClick={() => {}}
                />
              </div>
            </div>
            <hr className="horizontal dark mt-2" />
          </div>

          <div className="card-body pt-0">
            <div>
              {Object.entries(groupedData)?.map(
                ([parentField, childArray], index) => {
                  return (
                    <div
                      key={index}
                      className={classNames(
                        styles.groupItems,
                        "mt-4 p-3 border-radius-xl mx-5"
                      )}
                    >
                      <Typography
                        fontWeight="fw-bolder"
                        className="text-start mb-4"
                        textColor="#344767"
                        customFontsize={15}
                      >
                        {parentField}
                      </Typography>

                      {childArray?.map((settingParameter: IParameterInfo) =>
                        generateInputItems(settingParameter)
                      )}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
    </UiForm>
  );
};

export default CompanyInfo;
