import { nanoid } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";

import { ILinkForm } from "../../types/tpl";
import { GENERAL_UI_COLOR } from "../../constants/color";
import PanelGeneral from "../../components/panel-general/component";
import UiButton from "../../components/ui-button";

import EditTplFormItem from "./view-edit-tpl-form-item";

const EditTplFormRouting = ({
  register,
  setValue,
  errors,
  data,
}: {
  register: any;
  errors: any;
  data: any;
  setValue?: any;
}) => {
  const uniqueId = nanoid(10);

  const [isAppended, setIsAppended] = useState(false);
  const [formItemComponent, setFormItemComponent] = useState<any[]>([]);

  const renderedDefaultTplFormItems = (links: ILinkForm[]) => {
    return links?.map((formItem: any) => {
      const subUniqueId = nanoid(10);
      return (
        <EditTplFormItem
          keyIndex={subUniqueId}
          key={subUniqueId}
          data={data}
          formItem={formItem}
          register={register}
          errors={errors}
          setValue={setValue}
          setFormItemComponent={setFormItemComponent}
        />
      );
    });
  };

  useEffect(() => {
    if (
      data?.links &&
      data?.products &&
      data?.warehouses &&
      data?.provinces &&
      data?.districts &&
      !isAppended
    ) {
      setIsAppended(true);
      setFormItemComponent(renderedDefaultTplFormItems(data?.links));
    }
  }, [data, setFormItemComponent]);

  return (
    <PanelGeneral
      styles={{ minHeight: 400 }}
      panel={{
        title: "Routing",
        actionArea: () => (
          <>
            <UiButton
              customType="button"
              className="me-2"
              type={GENERAL_UI_COLOR.LIGHT}
              onClick={() => {
                setFormItemComponent((prevComponent) => [
                  <EditTplFormItem
                    keyIndex={uniqueId}
                    key={uniqueId}
                    data={data}
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    setFormItemComponent={setFormItemComponent}
                  />,
                  ...prevComponent,
                ]);
              }}
              title={"+"}
            />
          </>
        ),
      }}
    >
      {formItemComponent}
    </PanelGeneral>
  );
};

export default EditTplFormRouting;
