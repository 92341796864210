import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  getTaskLoggingAssignees,
  getTaskLoggings,
  getUnassignedTaskLoggings,
} from "../../apis/paths/taskLogging";
import * as R from "../../routings/path";
import { ACTIONABLE_TYPE } from "../../constants/actionable";

import UiTab from "../../components/ui-tab";
import TablePanel from "../../components/panel-table";
import { IFilterField } from "../../components/ui-filter/component";

import { schema } from "./schema";

export const TASK_FILTER_OPTIONS : IFilterField[] = [
  {
    field: "status",
    operator: "in",
    options: [
      { value: "to_do", content: "To do" },
      { value: "in_progress", content: "In Progress" },
      { value: "completed", content: "Completed" },
      { value: "canceled", content: "Cancelled" },
    ],
  },
  {
    field: "assignee_uuid",
    template: "account",
    operator: "in",
    type: "avatar",
    options: [],
    assigneeApiPath: getTaskLoggingAssignees,
  },
];

const Component = () => {
  const navigate = useNavigate();
  const [pageReloadVersion, setPageReloadVersion] = useState(1);
  const [currentActiveTab, setCurrentActiveTab] = useState(0);

  const handleToggleUiTab = (newActiveTab: number) => {
    setCurrentActiveTab(newActiveTab);
    setPageReloadVersion((prev) => prev + 1);
  };

  return currentActiveTab === 0 ? (
    <TablePanel
      apiPath={getTaskLoggings}
      pageReloadVersion={pageReloadVersion}
      filter={{
        optionList: TASK_FILTER_OPTIONS,
      }}
      panel={{
        title: "Tasks",
        description: "List of assigned tasks",
        actionableButtons: [
          {
            actionableType: ACTIONABLE_TYPE.NEW,
            title: "New Task",
            onClick: () => navigate(R.TASK_LOGGING_CREATE),
          },
        ],
        actionArea: () => (
          <>
            <UiTab
              tabs={["Assigned", "Unassigned"]}
              selected={currentActiveTab}
              onChange={handleToggleUiTab}
            />
          </>
        ),
      }}
      tableSchema={schema()}
    />
  ) : (
    <TablePanel
      apiPath={getUnassignedTaskLoggings}
      pageReloadVersion={pageReloadVersion}
      panel={{
        title: "Tasks",
        description: "List of unassigned tasks",

        actionArea: () => (
          <>
            <UiTab
              tabs={["Assigned", "Unassigned"]}
              selected={currentActiveTab}
              onChange={handleToggleUiTab}
            />
          </>
        ),
      }}
      tableSchema={schema()}
    />
  );
};

export default Component;
