import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { schema } from "./schema";

import { useApiCaller } from "../../apis/config";
import { IUpdateShopApi, getShopDetailApi } from "../../apis/paths/shop";
import { SHOP_LIST } from "../../routings/path";

import SmallCollumnCenterGridLayout from "../../components/grid-layout-small-collumn-center";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import HeaderInfoPanel from "../../components/panel-header-info";
import GeneralPanel from "../../components/panel-general";
import UiButton from "../../components/ui-button";
import UiInput from "../../components/ui-input";
import LoadingPanel from "../../components/ui-loading-panel/component";

const Component = () => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { shopId: uuid } = useParams();

  const [params] = useState({ uuid });

  const { result, loading } = useApiCaller({
    api: getShopDetailApi,
    params: params,
  });

  const shopDetail = result?.data;

  useEffect(() => {
    if (!shopDetail) return;

    const keys = Object.keys(schema?.fields);
    keys.forEach((key: any) => {
      setValue(key, shopDetail[key as keyof IUpdateShopApi]);
    });
  }, [result, shopDetail]);

  const navigate = useNavigate();

  if (loading) return <LoadingPanel />;

  return (
    <>
      <HeaderInfoPanel
        title={shopDetail?.name}
        image={shopDetail?.logo}
        description="Input info below to edit shop"
        actionArea={() => (
          <>
            <UiButton
              onClick={() => {
                navigate(`${SHOP_LIST}/${shopDetail.uuid}/edit`);
              }}
              customType="button"
              className="me-2"
              title={"Edit"}
            />
          </>
        )}
      />
      <SmallCollumnCenterGridLayout>
        <GeneralPanel panel={{ subTitle: "Shop Information" }}>
          <TwoColumnGridLayout
            className={{ container: "px-0" }}
            leftCol={
              <>
                <UiInput
                  title={"Name"}
                  name={"name"}
                  required
                  viewMode
                  register={register}
                  errorMessage={errors?.name?.message}
                />
                <UiInput
                  title={"Code"}
                  name={"code"}
                  required
                  viewMode
                  register={register}
                  errorMessage={errors?.code?.message}
                />
                <UiInput
                  title={"Full Address"}
                  name={"full_address"}
                  required
                  viewMode
                  register={register}
                  errorMessage={errors?.full_address?.message}
                />
              </>
            }
            rightCol={
              <>
                <UiInput
                  title={"Phone"}
                  name={"phone"}
                  required
                  viewMode
                  register={register}
                  errorMessage={errors?.phone?.message}
                />
                <UiInput
                  title={"Tax Code"}
                  name={"tax_code"}
                  viewMode
                  register={register}
                  errorMessage={errors?.tax_code?.message}
                />
              </>
            }
          />
        </GeneralPanel>
      </SmallCollumnCenterGridLayout>
    </>
  );
};

export default Component;
