import * as yup from "yup";
import { validationMessage } from "../../utils/validation";
import { FieldTitle } from "./constants";

type FieldSchema = keyof typeof FieldTitle;

const MAX_IMAGE_SIZE = 3145728;

export const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(FieldTitle[path as FieldSchema])
      ),
    code: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(FieldTitle[path as FieldSchema])
      ),
    size: yup
      .string()
      .oneOf(["small", "medium", "large", "extra_large", "extra_extra_large"]),
    box_type: yup
      .string()
      .oneOf(["normal", "gift", "special"])
      .required(({ path }) =>
        validationMessage.required(FieldTitle[path as FieldSchema])
      ),
    height: yup
      .number()
      .required(({ path }) =>
        validationMessage.required(FieldTitle[path as FieldSchema])
      ),
    length: yup
      .number()
      .integer(
        "Please enter the correct integer type and avoid using decimals."
      )
      .required(({ path }) =>
        validationMessage.required(FieldTitle[path as FieldSchema])
      ),
    width: yup
      .number()
      .integer(
        "Please enter the correct integer type and avoid using decimals."
      )
      .required(({ path }) =>
        validationMessage.required(FieldTitle[path as FieldSchema])
      ),
    upload_image: yup
      .mixed()
      .test(
        "fileSize",
        "File size is too large, should be smaller than 3Mbs",
        (value: any) => {
          if (!value) return true;
          return value?.[0]?.file?.size <= MAX_IMAGE_SIZE;
        }
      )
      .test("fileType", "Unsupported file type", (value: any) => {
        if (!value) return true;
        return ["image/jpeg", "image/png", "image/webp"].includes(
          value?.[0]?.file?.type
        );
      }),
  })
  .required();
