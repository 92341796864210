import { useEffect, useState } from "react";
import { API_RESPONSE_STATUS } from "../constants/api";
import { store } from "../states/store";
import { toast } from "react-toastify";
import {
  addErrorApi,
  clearErrorApi,
} from "../states/expiredSessionDetection.flow";
import alert from "../utils/alert";

const ALLOW_EXPIRED_SESSION_API_COUNT = 3;
export const API_FQDN = process.env.REACT_APP_API_ENDPOINT;
export const API_METHOD = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

interface XHRType {
  path: string;
  data?: any;
  method?: string;
  byPassErrorAlert?: boolean;
  isFormData?: boolean;
}

interface IRequestOption {
  method?: string;
  body?: any;
  headers?: {
    "content-type"?: string;
    "access-token": any;
  };
}

const apiCreator = ({
  path,
  data,
  method,
  byPassErrorAlert,
  isFormData = false,
}: XHRType) => {
  return new Promise((resolve, reject) => {
    const accessToken = store.getState()?.user?.loginInfo?.access_token || "";

    let requestOption: IRequestOption = {
      method,
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json; charset=UTF-8",
        "access-token": accessToken,
      },
    };

    if (isFormData) {
      requestOption = {
        method,
        body: data,
        headers: {
          "access-token": accessToken,
        },
      };
    }

    return fetch(`${API_FQDN}${path}`, requestOption)
      .then((response) => ({
        data: response.json(),
        status: response.status,
      }))
      .then(async ({ data, status }) => {
        const resolvedData = await data;

        if (status >= 400 && !byPassErrorAlert) {
          handleErrorToastMessageAlert(
            resolvedData.message || resolvedData.messages
          );
          if (status === 401) {
            const apiPath = `${API_FQDN}${path}`;
            handleSessionExpired(apiPath);
          }
        }

        return resolve(formatResolvedData(resolvedData));
      })
      .catch((error) => {
        alert.error(error);
        return reject(error);
      });
  });
};

interface IAlertMapping {
  count: number;
  id: number;
}

const errorStrorage = new Map<string, IAlertMapping>();

const handleSessionExpired = async (apiPath: string) => {
  store.dispatch(addErrorApi({ api: apiPath }));
  const errorState = store.getState().expiredSessionDetection.errorState;
  const currentApi401ErroredQty = errorState?.length;

  if (currentApi401ErroredQty > ALLOW_EXPIRED_SESSION_API_COUNT) {
    const currentSession = store.getState().mySession.mySession;
    if (!currentSession) {
      store.dispatch(clearErrorApi());
      handleErrorToastMessageAlert(
        "Your session has been expired. Please sign-in again!"
      );
    }
  }
};

export const handleErrorToastMessageAlert = (err: string | string[]) => {
  let errorMessage = "";

  if (typeof err === "string") {
    errorMessage = err;
  } else if (Array.isArray(err) && err.length > 0) {
    errorMessage = err[0];
  }

  if (errorMessage) {
    if (errorStrorage.has(errorMessage)) {
      const repeatedTaskId = errorStrorage.get(errorMessage)?.id;
      const messageCounter =
        (errorStrorage.get(errorMessage)?.count as number) + 1;

      toast.update(repeatedTaskId as number, {
        render: `${errorMessage} (x${messageCounter})`,
        type: "error",
      });

      if (repeatedTaskId && messageCounter) {
        errorStrorage.set(errorMessage, {
          id: repeatedTaskId as number,
          count: messageCounter,
        });
      }
    } else {
      const toastId = toast.error(errorMessage);
      errorStrorage.set(errorMessage, {
        id: toastId as number,
        count: 1,
      });

      setTimeout(() => {
        errorStrorage.delete(errorMessage);
      }, 3000);
    }
  } else {
    toast.error("Something went wrong, please try again");
  }
};

export const handleClearErrorMessageStore = () => {
  errorStrorage.clear();
};

const formatResolvedData = (resolvedData: any) => {
  if (
    resolvedData.error ||
    resolvedData.errors ||
    resolvedData.error === null
  ) {
    return {
      status: API_RESPONSE_STATUS.ERROR,
      error: resolvedData.error,
      messages: resolvedData.messages,
    };
  }

  return {
    status: API_RESPONSE_STATUS.SUCCESS,
    data: resolvedData,
  };
};

export const get = (
  { path, isFormData }: XHRType,
  byPassErrorAlert?: boolean
) => apiCreator({ path, method: API_METHOD.GET, byPassErrorAlert, isFormData });

export const post = (
  { path, data, isFormData }: XHRType,
  byPassErrorAlert?: boolean
) =>
  apiCreator({
    path,
    data,
    method: API_METHOD.POST,
    byPassErrorAlert,
    isFormData,
  });

export const patch = (
  { path, data, isFormData }: XHRType,
  byPassErrorAlert?: boolean
) =>
  apiCreator({
    path,
    data,
    method: API_METHOD.PATCH,
    byPassErrorAlert,
    isFormData,
  });

export const put = (
  { path, data, isFormData }: XHRType,
  byPassErrorAlert?: boolean
) =>
  apiCreator({
    path,
    data,
    method: API_METHOD.PUT,
    byPassErrorAlert,
    isFormData,
  });

export const del = (
  { path, data, isFormData }: XHRType,
  byPassErrorAlert?: boolean
) =>
  apiCreator({
    path,
    data,
    method: API_METHOD.DELETE,
    byPassErrorAlert,
    isFormData,
  });

interface apiCallerItem {
  api: Function;
  params?: any;
  pageReloadVersion?: number;
}

export const apiCaller = ({ api, params }: apiCallerItem) =>
  api(params, params.byPassErrorAlert);

export const useApiCaller = ({
  api,
  params,
  pageReloadVersion = 1,
}: apiCallerItem) => {
  const [result, setResult]: any = useState(null);
  const [loading, setLoading]: any = useState(false);

  useEffect(() => {
    setLoading(true);

    (async () => {
      const responseData = await api(params, params?.byPassErrorAlert);
      setResult(responseData);
      setLoading(false);
    })();
  }, [params, pageReloadVersion]);

  return { result, loading };
};
