import { NavLink, useLocation } from "react-router-dom";
import styles from "./styles.module.scss";

interface IComponent {
  value: string;
  routing?: string;
}

const Component = ({ value, routing }: IComponent) => {
  const { pathname } = useLocation();

  return (
    <NavLink
      className={styles.container}
      to={`${routing || pathname}?keyword=${value}`}
    >
      <span className={styles.value}>{value}</span>
    </NavLink>
  );
};

export default Component;
