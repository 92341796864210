import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import * as R from "../../routings/path";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { formatDateTime } from "../../utils/date-time";
import { useApiCaller } from "../../apis/config";
import { getRelocationShipmentDetailApi } from "../../apis/paths/relocationShipment";
import { extractDynamicAddressFields } from "../../utils/address";

import styles from "./styles.module.scss";

import PanelLabelController from "../../components/ui-panel-label-controller/component";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import HeaderInfoPanel from "../../components/panel-header-info";
import TableCellStatus from "../../components/table-cell-status";
import UiLoadingPanel from "../../components/ui-loading-panel";
import GeneralHeading from "../../components/ui-general-heading/component";
import TableDataPanel from "../../components/panel-table-data";
import NotFoundPanel from "../../components/panel-not-found";
import UiContentRow from "../../components/ui-content-row/component";
import GeneralPanel from "../../components/panel-general";
import UiTimeline from "../../components/ui-timeline";
import AddressRow from "../../components/ui-address-row/component";
import UiButton from "../../components/ui-button";
import PanelNote from "../../components/panel-note/component";

import UiContentHeading from "./view-section-heading";
import { productSchema } from "./schema";

const Component = () => {
  const navigate = useNavigate();

  const { relocationShipmentId: uuid } = useParams();
  const [params] = useState({ uuid });

  const { result, loading } = useApiCaller({
    api: getRelocationShipmentDetailApi,
    params,
  });

  const relocationShipmentDetail = result?.data;
  const { address: fromWarehouseAddress } =
    relocationShipmentDetail?.from_warehouse || {};
  const { address: toWarehouseAddress } =
    relocationShipmentDetail?.to_warehouse || {};

  if (loading) return <UiLoadingPanel />;

  if (result?.status === "ERROR") return <NotFoundPanel />;

  const {
    detail: fromWhDetail,
    street: fromWhStreet,
    ward: fromWhWard,
  } = extractDynamicAddressFields(fromWarehouseAddress?.full_address);

  const {
    detail: toWhDetail,
    street: toWhStreet,
    ward: toWhWard,
  } = extractDynamicAddressFields(toWarehouseAddress?.full_address);

  return (
    <>
      <HeaderInfoPanel
        title={`Relocation shipment ${relocationShipmentDetail?.from_warehouse?.name} - ${relocationShipmentDetail?.to_warehouse?.name}`}
        infoArea={() => (
          <div className={styles.headerInfoArea}>
            <TableCellStatus
              className="me-2"
              status={relocationShipmentDetail?.status}
              size={GENERAL_UI_SIZE.SMALL}
            />
          </div>
        )}
        actionArea={() => (
          <>
            <UiButton
              onClick={() =>
                navigate(`${R.RELOCATION_SHIPMENTS_LIST}/${uuid}/edit`)
              }
              title={"Edit"}
            />
          </>
        )}
      />
      <GeneralPanel className="pt-4" containerClassName="mt-4">
        <TwoColumnGridLayout
          widthDivision={[5, 7]}
          isDisplayPartition
          gutter={5}
          leftCol={
            <>
              <UiContentHeading>Timeline</UiContentHeading>
              <UiTimeline
                displayLimit={3}
                list={relocationShipmentDetail?.state_activities?.map(
                  (activity: any) => ({
                    icon: "gear",
                    title: activity?.state_value,
                    subTitle: activity?.account?.name
                      ? `by ${activity?.account?.name}`
                      : `by ${activity?.comment}`,
                    description: formatDateTime(
                      activity?.changed_at,
                      "HH:mm:ss DD-MM-YYYY"
                    ),
                  })
                )}
              />
              {uuid && (
                <PanelNote
                  isDisplayHeading
                  className={{
                    heading: "mb-0 pb-0 mt-4",
                    card: styles.customPanel,
                    cardBody: styles.customCardBody,
                    container1: styles.customContainer,
                    editArea: "mt-0",
                  }}
                  notable_type="RelocationShipment"
                  notable_uuid={uuid as string}
                  setNoteQuantity={() => {}}
                />
              )}
            </>
          }
          rightCol={
            <>
              <UiContentHeading>Relocation Info</UiContentHeading>
              <div className="mb-4">
                <UiContentRow label={"Created at:"}>
                  {formatDateTime(relocationShipmentDetail?.created_at)}
                </UiContentRow>

                <UiContentRow label={"Type:"}>
                  {relocationShipmentDetail?.type == "FastRelocationShipment"
                    ? "Fast"
                    : "Normal"}
                </UiContentRow>

                <UiContentRow label={"Product Status:"}>
                  {relocationShipmentDetail?.product_status}
                </UiContentRow>

                <UiContentRow label={"Shipping Service:"}>
                  {relocationShipmentDetail?.shipping_service}
                </UiContentRow>

                <UiContentRow label={"Note:"}>
                  {relocationShipmentDetail?.note}
                </UiContentRow>
              </div>

              <UiContentHeading>From Warehouse</UiContentHeading>
              <div className="mb-4">
                <UiContentRow label={"Warehouse name:"}>
                  {relocationShipmentDetail?.from_warehouse?.name}
                </UiContentRow>
                <UiContentRow label={"Contact Name:"}>
                  {relocationShipmentDetail?.from_warehouse?.contact_name}
                </UiContentRow>
                <UiContentRow label={"Phone Number:"}>
                  {relocationShipmentDetail?.from_warehouse?.contact_phone}
                </UiContentRow>
                <UiContentRow label={"Address:"}>
                  <AddressRow
                    mainAddress={` ${fromWhWard}`}
                    detailAddress={`${fromWhDetail}, ${fromWhStreet}`}
                  />
                </UiContentRow>
              </div>

              <UiContentHeading>To Warehouse</UiContentHeading>
              <div className="mb-4">
                <UiContentRow label={"Warehouse name:"}>
                  {relocationShipmentDetail?.to_warehouse?.name}
                </UiContentRow>
                <UiContentRow label={"Contact Name:"}>
                  {relocationShipmentDetail?.to_warehouse?.contact_name}
                </UiContentRow>
                <UiContentRow label={"Phone Number:"}>
                  {relocationShipmentDetail?.to_warehouse?.contact_phone}
                </UiContentRow>
                <UiContentRow label={"Address:"}>
                  <AddressRow
                    mainAddress={` ${toWhWard}`}
                    detailAddress={`${toWhDetail}, ${toWhStreet}`}
                  />
                </UiContentRow>
              </div>
              <div>
                <GeneralHeading className="mb-2 ">Labels</GeneralHeading>
                <PanelLabelController
                  isEditable={false}
                  linkedObjectType="RelocationShipment"
                  uniqueUuid={uuid as string}
                />
              </div>
            </>
          }
        />
      </GeneralPanel>
      <div className="pt-4">
        <TableDataPanel
          data={relocationShipmentDetail?.relocation_shipment_products}
          schema={productSchema}
          displayLimit={10}
        />
      </div>
    </>
  );
};

export default Component;
