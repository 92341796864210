import Fuse from "fuse.js";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";
import { memo, useEffect, useRef, useState } from "react";

import { useApiCaller } from "../../apis/config";
import { ACTIONABLE_TYPE } from "../../constants/actionable";
import { defaultDataTransformation } from "../../utils/data-transformation";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { UiButtonPropsType } from "../../components/ui-button/component";
import { areObjectsEqual } from "../../utils/compare";
import { useDebounce } from "../../hooks/useDebounce";
import UiLoadingPanel from "../../components/ui-loading-panel";
import UiTable from "../../components/ui-table";
import UiPaginate from "../../components/ui-paginate";
import NoDataPanel from "../../components/no-data-panel/component";
import Filter, { IFilterField } from "../../components/ui-filter/component";

import { DEFAULT_PAGING } from "./init";
import styles from "./styles.module.scss";
import Header from "./view.header";
import Search from "./view.search";
import PageStat from "./view.page-stat";
import PerPageSelection from "./view.per-page-selection";
import DanymicFilter from "./view-dynamic-filter";

export interface IActionableButtonItem extends UiButtonPropsType {
  actionableType: ACTIONABLE_TYPE;
  rest?: any;
}

interface IComponent {
  apiPath: any;
  isDisplayControlPanel?: boolean;
  panel?: {
    title?: string;
    description?: string;
    actionArea?: any;
    actionableButtons?: Array<IActionableButtonItem>;
    required?: boolean;
    className?: string;
  };
  modules?: {
    header?: boolean;
    search?: boolean;
    pagination?: boolean;
  };
  tableSchema?: Array<any>;
  dataTransformation?: Function;
  isDisplayedDataTopTable?: boolean;
  showPerPageSelection?: boolean;
  pageReloadVersion?: number;
  isSearchable?: boolean;
  panelClassName?: string;
  type?: "minimal" | "normal";
  apiParams?: any;
  isLocalSearchable?: {
    searchKeys: string[];
  };
  filter?: {
    optionList: IFilterField[];
    showOverlay?: boolean;
    pageCode?: string;
  };
  dynamicFilter?: any;
  noDataDescription?: any;
  tableClassName?: {
    container1?: string;
    container2?: string;
    container3?: string;
  };
  minHeight?: {
    tableContainer?: number;
  };
  onReceivedData?: any;
  displayHeadingAction?: Array<number>;
}

export interface IFilter {
  field: string;
  operator: string;
  values: string[];
}

const Component = ({
  apiPath,
  panel: panelInfo,
  modules = {
    header: true,
    search: true,
    pagination: true,
  },
  tableSchema,
  dataTransformation,
  isDisplayedDataTopTable = true,
  showPerPageSelection = true,
  pageReloadVersion = 1,
  isSearchable = true,
  panelClassName = "card",
  tableClassName = {
    container1: "",
    container2: "",
    container3: "",
  },
  type = "normal",
  apiParams,
  isLocalSearchable,
  filter,
  dynamicFilter,
  noDataDescription,
  minHeight = {
    tableContainer: 400,
  },
  onReceivedData,
  displayHeadingAction,
}: IComponent) => {
  const [searchParams] = useSearchParams();
  const searchKeywordParams = searchParams.get("keyword") || "";

  const [localSearchValue, setLocalSearchValue] = useState("");
  const [apiSearchValue, setApiSearchValue] = useState(
    searchKeywordParams || ""
  );
  const [hasSearched, setHasSearched] = useState(false);

  const debouncedValue = useDebounce(apiSearchValue, 300);

  useEffect(() => {
    setHasSearched(false);
  }, [apiPath]);

  useEffect(() => {
    setApiSearchValue(searchKeywordParams);
    setHasSearched(true);
  }, [searchKeywordParams]);

  const prevApiParams = useRef();
  const initPaginationParams = Object.assign(
    {},
    DEFAULT_PAGINATION_PARAMS,
    type === "minimal" && { per: 7 },
    apiParams
  );

  const [pagingParams, setPaging] = useState<{
    page: number;
    per: number;
    keyword: string;
    sort: {
      field: string;
      direction: string;
    } | null;
    filter: IFilter[];
  }>(initPaginationParams);

  useEffect(() => {
    setPaging((prev: any) => ({
      ...prev,
      keyword: debouncedValue,
    }));
  }, [debouncedValue, setPaging]);

  useEffect(() => {
    const shouldSetpaging = !areObjectsEqual(
      apiParams,
      prevApiParams.current as any
    );
    if (apiParams && shouldSetpaging) {
      setPaging({ ...initPaginationParams, ...apiParams });
    }
    prevApiParams.current = apiParams;
  }, [apiParams]);

  const { result, loading } = useApiCaller({
    api: apiPath,
    params: pagingParams,
    pageReloadVersion,
  });

  useEffect(() => {
    onReceivedData?.(result?.data);
  }, [result]);

  const transformationFunction = dataTransformation
    ? dataTransformation
    : defaultDataTransformation;
  const transformedResult = result && transformationFunction(result);

  let data = transformedResult?.data || [];
  const actionableActions = transformedResult?.actionable?.actions;

  const handleSearch = (value: string, fields: any) => {
    if (value === "") return fields;
    const fuse = new Fuse(fields, {
      keys: isLocalSearchable?.searchKeys,
      threshold: 0.5,
    });

    const searchTerm = value.toLowerCase();
    const results = fuse.search(searchTerm);

    const matchedObjects = results?.map((result) => result.item);
    return matchedObjects;
  };

  if (isLocalSearchable) {
    data = handleSearch(localSearchValue, data);
  }

  const paging = transformedResult?.paging || DEFAULT_PAGING;

  const handleCheckKeyDown = (e: any) => {
    if (e.key === "Enter") e.preventDefault();
  };

  return (
    <div className="row" onKeyDown={handleCheckKeyDown}>
      <div className="col-12">
        <div
          className={`${
            type === "minimal" ? styles.panelCardBorder : "card"
          } ${panelClassName}`}
        >
          <Header {...panelInfo} actions={actionableActions} />
          <div
            className={classNames(
              "table-responsive listing-table",
              tableClassName.container1
            )}
          >
            <div
              className={classNames(
                "dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns",
                tableClassName.container2
              )}
            >
              {(hasSearched || isDisplayedDataTopTable) && (
                <div
                  className={classNames(
                    `dataTable-top pt-0 pb-0`,
                    styles.dataTableTop,
                    tableClassName.container3
                  )}
                >
                  {isSearchable && (
                    <Search
                      initKeyword={apiSearchValue}
                      onSearch={(keyword: string) => {
                        setApiSearchValue(keyword);
                        setHasSearched(true);
                      }}
                    />
                  )}
                  {isLocalSearchable && (
                    <Search
                      initKeyword={apiSearchValue}
                      onSearch={(keyword: string) => {
                        setLocalSearchValue(keyword);
                      }}
                    />
                  )}
                  {filter && filter.optionList.length > 0 && (
                    <Filter
                      optionList={filter?.optionList}
                      onFilter={(filterOption: any[]) => {
                        return setPaging({
                          ...pagingParams,
                          page: DEFAULT_PAGINATION_PARAMS.page,
                          per: DEFAULT_PAGINATION_PARAMS.per,
                          filter: filterOption,
                        });
                      }}
                      showOverlay={filter?.showOverlay}
                      pageCode={filter?.pageCode}
                    />
                  )}
                </div>
              )}
              {dynamicFilter?.length && (
                <DanymicFilter
                  list={dynamicFilter}
                  onApply={(results: any) => {
                    setPaging({
                      ...pagingParams,
                      filter: [...pagingParams.filter, ...results],
                    });
                  }}
                />
              )}
              <div
                className="dataTable-container"
                style={{ minHeight: minHeight?.tableContainer }}
              >
                {!!data && !!data.length ? (
                  <UiTable
                    data={data}
                    isVisible={!loading}
                    showHeader={modules?.header}
                    schema={tableSchema}
                    displayHeadingAction={displayHeadingAction}
                    onSort={(sortTypes: { key: string; value: string }) => {
                      if (!sortTypes)
                        return setPaging({ ...pagingParams, sort: null });

                      const { key, value } = sortTypes;
                      setPaging({
                        ...pagingParams,
                        sort: { field: key, direction: value },
                      });
                    }}
                  />
                ) : loading ? (
                  <UiLoadingPanel type={type} />
                ) : (
                  <NoDataPanel description={noDataDescription} />
                )}
              </div>
              {!isLocalSearchable && (
                <div
                  className="dataTable-bottom"
                  style={{ display: loading ? "none" : "" }}
                >
                  {showPerPageSelection && !!data && !!data.length && (
                    <PerPageSelection
                      type={type}
                      onChange={(perPageChange: number) =>
                        setPaging({ ...pagingParams, per: perPageChange })
                      }
                    />
                  )}
                  {modules.pagination && (
                    <PageStat
                      perPage={paging.perPage}
                      currentPage={paging.currentPage}
                      totalItem={paging?.totalCount}
                    />
                  )}
                  {modules.pagination && paging.totalPages > 1 && (
                    <nav className="dataTable-pagination">
                      <UiPaginate
                        initialPage={paging?.currentPage}
                        onPageChange={(newPage: number) =>
                          setPaging({ ...pagingParams, page: newPage })
                        }
                        pageCount={paging?.totalPages}
                      />
                    </nav>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Component);
