import { useState } from "react";

import SmallCollumnCenterGridLayout from "../../components/grid-layout-small-collumn-center";
import UiContentHeading from "../../components/ui-content-heading";
import GeneralPanel from "../../components/panel-general";
import UiTimeline from "../../components/ui-timeline";
import UiButton from "../../components/ui-button";
import { notificationTransformation } from "../../utils/notification";

import { useNotifications } from "./hook";
import styles from "./styles.module.scss";

const Component = () => {
  const [page, setPage] = useState(1);
  const { notifications, totalPage, loading } = useNotifications({ page });

  const isDisplayViewMore = totalPage !== -1 && page < totalPage;

  const handleViewMore = () => setPage(page + 1);

  return (
    <SmallCollumnCenterGridLayout className={styles.container}>
      <GeneralPanel className="pt-4">
        <UiContentHeading className="mb-4">Notifications</UiContentHeading>
        <UiTimeline
          displayLimit={page * 25}
          list={notifications?.map(notificationTransformation)}
        />
        {isDisplayViewMore && (
          <UiButton
            isLoading={loading}
            disabled={loading}
            onClick={handleViewMore}
            title="View more"
          />
        )}
      </GeneralPanel>
    </SmallCollumnCenterGridLayout>
  );
};

export default Component;
