import classNames from "classnames";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import styles from "./styles.module.scss";

interface IPrintingProvider {
  children: any;
  buttonTitle?: string | React.ReactNode;
}
const PrintingProvider = ({
  children,
  buttonTitle = "Print",
}: IPrintingProvider) => {
  const componentRef = useRef(null);

  return (
    <div>
      <ReactToPrint
        trigger={() => <div>{buttonTitle}</div>}
        content={() => componentRef.current}
      />
      <div ref={componentRef} className={classNames(styles.printing)}>
        {children}
      </div>
    </div>
  );
};

export default PrintingProvider;
