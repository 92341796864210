import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IItemsState {
  isPackingAutoPrintable: boolean;
  isPackingMouseSelectable: boolean;
  isPackingProductListPrintable: boolean;
}

type TBooleanValue =
  | "isPackingAutoPrintable"
  | "isPackingMouseSelectable"
  | "isPackingProductListPrintable";

export interface IBooleanPayload {
  key: TBooleanValue;
  value: boolean;
}

const initialState: IItemsState = {
  isPackingAutoPrintable: false,
  isPackingMouseSelectable: false,
  isPackingProductListPrintable: false,
};

export const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {
    updateBooleanValueAction: (
      state,
      action: PayloadAction<IBooleanPayload>
    ) => {
      const { key, value } = action.payload;
      if (
        key === "isPackingAutoPrintable" ||
        key === "isPackingMouseSelectable" ||
        key === "isPackingProductListPrintable"
      ) {
        state[key] = value;
      }
    },
  },
});

export const { updateBooleanValueAction } = configurationSlice.actions;
export default configurationSlice.reducer;
