import { useEffect, useState } from "react";
import classNames from "classnames";

import { GENERAL_UI_SIZE } from "../../constants/size";
import { ACTIONABLE_TYPE } from "../../constants/actionable";
import { TABLE_ACTION } from "../../constants/table-action";
import Badge from "../ui-badge-button/component";
import UiTextArea from "../../components/ui-text-area";
import Typography from "../ui-typography/component";
import ActionTableCell from "../../components/table-cell-action";

import styles from "./styles.module.scss";

interface ISchemaProps {
  editingLabel: any;
  setEditingLabel: any;
  onEditLabel: any;
  isEditMode: boolean;
  setViewedLabel: any;
  originalNoteRef: any;
  isDisplayPrintButton?: boolean;
  onPrint?: any;
}

export const useLabelSchema = ({
  editingLabel,
  setEditingLabel,
  onEditLabel,
  isEditMode,
  setViewedLabel,
  originalNoteRef,
  isDisplayPrintButton = false,
  onPrint,
}: ISchemaProps) => {
  const [isNoteEditMode, setIsNoteEditMode] = useState(false);

  useEffect(() => {
    return () => {
      setIsNoteEditMode(false);
    };
  }, []);

  return [
    {
      name: "Name",
      width: 150,
      cell: (data: any) => (
        <div className="pe-none">
          <Badge
            content={data?.label?.name}
            className="my-1"
            type={data?.label?.color}
            size={GENERAL_UI_SIZE.SMALL}
          />
        </div>
      ),
    },
    {
      name: "Description",
      align: "left",
      width: 200,
      cell: (data: any) => (
        <div
          style={{ width: 200, whiteSpace: "break-spaces", textAlign: "left" }}
        >
          {data?.label?.description}
        </div>
      ),
    },
    {
      name: "Note",
      align: "left",
      width: 200,
      cell: (data: any) => (
        <>
          {isNoteEditMode ? (
            <div className="d-flex gap-3 align-items-center justify-content-between ">
              <div className="mt-2 flex-grow-1 flex-shrink-1">
                <UiTextArea
                  isDisplayLabel={false}
                  value={editingLabel?.note}
                  onChange={(e) => {
                    setEditingLabel((prev: any) => ({
                      ...prev,
                      note: e.target.value,
                    }));
                    setViewedLabel((prev: any) => ({
                      ...prev,
                      note: e.target.value,
                    }));
                  }}
                  name={"commentEdit"}
                  className="flex-grow-1 flex-shrink-1"
                  placeholder="Write your comment"
                  rows={3}
                />
              </div>
              <div className="d-flex flex-column align-items-center h-100 gap-4 justify-content-between">
                <i
                  className="fa-regular fa-circle-check cursor-pointer"
                  onClick={() => {
                    onEditLabel(data?.uuid);
                    setIsNoteEditMode(false);
                  }}
                ></i>
                <i
                  className="fa-solid fa-xmark cursor-pointer"
                  onClick={() => {
                    setIsNoteEditMode(false);
                    setEditingLabel(data);
                    setViewedLabel(originalNoteRef.current);
                  }}
                ></i>
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-center justify-content-between">
              <Typography
                fontWeight="fw-bold"
                className={classNames("text-start", styles.elipse)}
              >
                {data?.note}
              </Typography>
              {isEditMode && (
                <div
                  className={classNames("cursor-pointer", styles.editAction)}
                  onClick={() => {
                    setIsNoteEditMode(true);
                    setEditingLabel(data);
                    originalNoteRef.current = data;
                  }}
                >
                  <i className="fa-solid fa-pen-to-square fa-sm"></i>
                </div>
              )}
            </div>
          )}
        </>
      ),
    },
    ...(isDisplayPrintButton
      ? [
          {
            name: "Action",
            align: "right",
            cell: (data: any) => (
              <ActionTableCell
                actions={{
                  print: { actionable: data?.label?.printable, message: [] },
                }}
                customActionableType={ACTIONABLE_TYPE.PRINT}
                customTitle={"Print"}
                onCustomClick={() => onPrint?.(data?.uuid)}
                types={[TABLE_ACTION.CUSTOM]}
              />
            ),
          },
        ]
      : []),
  ];
};
