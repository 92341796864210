import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  deleteMaterialApi,
  getDeletedMaterialListApi,
  getMaterialListApi,
  restoreMaterialApi,
} from "../../apis/paths/material";
import * as R from "../../routings/path";
import { ACTIONABLE_TYPE } from "../../constants/actionable";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { apiCaller } from "../../apis/config";
import alert from "../../utils/alert";

import TablePanel from "../../components/panel-table";
import UiTab from "../../components/ui-tab";

import { schema } from "./schema";
import { deletedMaterialSchema } from "./deletedMaterialSchema";
import ViewPrintingPopup from "./view-printing-pop-up";

const INITIAL_PRINTING_ITEM = 1;

const Component = () => {
  const navigate = useNavigate();
  const [pageReloadVersion, setPageReloadVersion] = useState(1);
  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const [isShowModal, setIsShowModal] = useState(false);
  const [selectedCode, setSelectedCode] = useState<string | null>(null);
  const [printingQty, setPrintingQty] = useState(INITIAL_PRINTING_ITEM);

  const handleDeleteMaterial = async (code: string) => {
    const waitDelete = await apiCaller({
      api: deleteMaterialApi,
      params: { code },
    });

    if (waitDelete.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Delete successful!");

      setPageReloadVersion((prev) => prev + 1);
      return;
    }

    alert.error("Delete was unsuccessful!. Please try again");
  };

  const handleOpenModal = (code: string) => {
    setSelectedCode(code);
    setIsShowModal(true);
  };

  const handleCloseModal = () => {
    setPrintingQty(1);
    setIsShowModal(false);
  };

  const handleRestoreMaterial = async (code: string) => {
    const waitRestore = await apiCaller({
      api: restoreMaterialApi,
      params: { code },
    });

    if (waitRestore.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Restore successful!");

      setPageReloadVersion((prev) => prev + 1);
      return;
    }

    alert.error("Restore was unsuccessful!. Please try again");
  };

  const handleToggleUiTab = (newActiveTab: number) => {
    setCurrentActiveTab(newActiveTab);
    setPageReloadVersion((prev) => prev + 1);
  };

  return (
    <>
      {currentActiveTab === 0 ? (
        <TablePanel
          apiPath={getMaterialListApi}
          pageReloadVersion={pageReloadVersion}
          isSearchable={false}
          panel={{
            title: "Materials",
            description: "List of materials in the warehouse",
            actionableButtons: [
              {
                actionableType: ACTIONABLE_TYPE.NEW,
                title: "New Material",
                onClick: () => navigate(R.MATERIAL_CREATE),
              },
            ],
            actionArea: () => (
              <>
                <UiTab
                  tabs={["In use", "Deleted"]}
                  selected={currentActiveTab}
                  onChange={handleToggleUiTab}
                />
              </>
            ),
          }}
          tableSchema={schema({
            onDelete: handleDeleteMaterial,
            onPrint: handleOpenModal,
          })}
        />
      ) : (
        <TablePanel
          apiPath={getDeletedMaterialListApi}
          pageReloadVersion={pageReloadVersion}
          isSearchable={false}
          panel={{
            title: "Deleted Material",
            description: "List of deleted materials in the warehouse",

            actionArea: () => (
              <UiTab
                tabs={["In use", "Deleted"]}
                selected={currentActiveTab}
                onChange={handleToggleUiTab}
              />
            ),
          }}
          tableSchema={deletedMaterialSchema({
            onRestore: handleRestoreMaterial,
          })}
        />
      )}
      <ViewPrintingPopup
        isShowModal={isShowModal}
        handleCloseModal={handleCloseModal}
        printingQty={printingQty}
        setPrintingQty={setPrintingQty}
        selectedCode={selectedCode as string}
      />
    </>
  );
};

export default Component;
