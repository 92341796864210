import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { GENERAL_STATUS } from "../../constants/status";
import { generateUrlParams } from "../../utils/url";
import { del, get, patch, post, put } from "../config";

interface IGetTplRoutesApi {
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
}

export interface IUpdateTplRoutesApi {
  id: string | number;
  name: string;
  service: string;
  priority: number;
  status: GENERAL_STATUS.ACTIVATE | GENERAL_STATUS.DEACTIVE;
  links: any[];
}
export interface ICreateTplRoutesApi {
  name: string;
  service: string;
  priority: number;
  status: GENERAL_STATUS.ACTIVATE | GENERAL_STATUS.DEACTIVE;
}

export interface IResetPasswordApi {
  uuid: string;
  password: string;
  password_confirmation: string;
}

export const getTplRoutesApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
}: IGetTplRoutesApi) =>
  get({
    path: `/wms/tpl_routes${generateUrlParams({ page, per, keyword, sort })}`,
  });

export const getTplRoutesDetailApi = ({ id }: { id: string }) =>
  get({ path: `/wms/tpl_routes/${id}` });

export const toggleTplRouteStatusApi = ({ id }: { id: string }) =>
  patch({ path: `/wms/tpl_routes/${id}/status_toggle` });

export const deleleTplRoutesApi = ({ uuid }: { uuid: string }) =>
  del({ path: `/wms/tpl_routes/${uuid}` });

export const getTplRoutesProvinceApi = ({ id }: { id: string }) =>
  get({ path: `/wms/tpl_routes/${id}/assets/provinces` });

export const getTplRoutesDistrictApi = ({ id }: { id: string }) =>
  get({ path: `/wms/tpl_routes/${id}/assets/districts` });

export const getTplRoutesProductsApi = ({ id }: { id: string }) =>
  get({ path: `/wms/tpl_routes/${id}/assets/products` });

export const getTplRoutesWarehousesApi = ({ id }: { id: string }) =>
  get({ path: `/wms/tpl_routes/${id}/assets/warehouses` });

export const updateTplRoutesApi = ({
  id,
  name,
  service,
  priority,
  status,
  links,
}: IUpdateTplRoutesApi) =>
  put({
    path: `/wms/tpl_routes/${id}`,
    data: {
      name,
      status,
      service,
      priority,
      links,
    },
  });

export const createTplRoutesApi = ({
  name,
  service,
  priority,
  status,
}: ICreateTplRoutesApi) =>
  post({
    path: `/wms/tpl_routes`,
    data: {
      name,
      status,
      service,
      priority,
    },
  });
