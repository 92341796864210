import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import * as R from "../../routings/path";
import { getWarehouseLocations } from "../../apis/paths/warehouse";
import { useDynamicDocParams } from "../../hooks/useDynamicDocParams";
import TablePanel from "../../components/panel-table";
import UiButton from "../../components/ui-button";

import { schema } from "./schema";

const WAREHOUSE_LOCATION_DOC_PATH = "/wms/warehouses/{code}/locations";

const Component = () => {
  const navigate = useNavigate();

  const { warehouseId } = useParams();
  const codeWarehouse = warehouseId || "";

  const { generatedFilterParameter, fetchParameter } = useDynamicDocParams();
  const { parameters: filterLocationType } = generatedFilterParameter || {};

  useEffect(() => {
    fetchParameter({
      docField: "location_type",
      docPath: WAREHOUSE_LOCATION_DOC_PATH,
    });
  }, []);

  const formatFilterOptions = (data: any[]) => ({
    field: "location_type",
    operator: "in",
    options: data?.map((option) => ({
      value: option?.value,
      content: option?.title,
    })),
  });

  return (
    <>
      <TablePanel
        apiPath={getWarehouseLocations}
        apiParams={{ code: codeWarehouse }}
        panelClassName="card-profile-bottom"
        filter={{
          optionList: [formatFilterOptions(filterLocationType)],
        }}
        panel={{
          title: "Location List",
          description: "List of locations in the warehouse",
          actionArea: () => (
            <>
              <UiButton
                title={"New Location"}
                onClick={() =>
                  navigate(
                    `${R.WAREHOUSES_LIST}/${codeWarehouse}/locations/create`
                  )
                }
              />
            </>
          ),
        }}
        tableSchema={schema(codeWarehouse)}
      />
    </>
  );
};

export default Component;
