import { TABLE_ACTION } from "../../constants/table-action";
import DateTimeTableCell from "../../components/table-cell-date-time";
import ProductTableCell from "../../components/table-cell-product";
import ActionTableCell from "../../components/table-cell-action";

export const schema = () => [
  {
    name: "Name",
    cell: (data: any) => (
      <ProductTableCell
        title={data?.name || "-"}
        linkTo={`${data?.uuid}`}
        description={`#${data?.code}`}
      />
    ),
  },
  {
    name: "Contact",
    width: 150,
    cell: (data: any) => (
      <ProductTableCell
        subIcon={"fa-phone"}
        linkTo={`tel:${data?.phone}`}
        title={""}
        description={data?.phone}
      />
    ),
  },
  {
    name: "Address",
    width: 350,
    cell: (data: any) => (
      <ProductTableCell
        subIcon={"fa-location-dot"}
        description={data?.full_address}
        descriptionLine={2}
      />
    ),
  },
  {
    name: "Created",
    align: "center",
    width: 130,
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data?.created_at} />,
  },
  {
    name: "Updated",
    align: "center",
    width: 130,
    sortTypes: {
      key: "updated_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data?.updated_at} />,
  },
  {
    name: "Action",
    align: "right",
    width: 120,
    cell: (data: any) => {
      return (
        <ActionTableCell
          actions={data?.instance_actionables}
          viewLink={`${data?.uuid}`}
          editLink={`${data?.uuid}/edit`}
          types={[TABLE_ACTION.VIEW, TABLE_ACTION.EDIT]}
        />
      );
    },
  },
];
