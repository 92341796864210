import classNames from "classnames";
import ActionableButton from "../../components/ui-button-actionable";
import { ACTIONABLE_ACTIONS } from "../../constants/actionable";
import { IActionableButtonItem } from "./component";
import styles from "./styles.module.scss";

const Component = ({
  title,
  description,
  actionArea: ActionArea,
  required = false,
  className = "",
  actionableButtons,
  actions,
}: {
  title?: string;
  description?: string;
  actionArea?: any;
  required?: boolean;
  className?: string;
  actionableButtons?: Array<IActionableButtonItem> | null;
  actions?: ACTIONABLE_ACTIONS;
}) => {
  if (!title && !description) return null;

  return (
    <div className={classNames("card-header", className)}>
      <div className="d-lg-flex">
        <div>
          {title && <h5 className={"mb-0"}>{title}</h5>}
          {description && <p className="text-sm mb-0">{description}</p>}
        </div>
        <div className="ms-auto my-auto mt-lg-0 mt-4">
          <div
            className="ms-auto my-auto"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {ActionArea && <ActionArea />}
            {actions &&
              actionableButtons?.map(
                (actionableButton: IActionableButtonItem) => (
                  <ActionableButton
                    className={styles.actionableButton}
                    actions={actions}
                    {...actionableButton}
                  />
                )
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component;
