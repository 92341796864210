export const CAPACITY_UNIT = ["ml", "gr", "oz", "g", "tablet", "pcs", "bag"];
export const GTIN_TYPE = ["upc", "ean", "isbn", "jan", "custom"];

export const FieldTitle = {
    name: "Name",
    short_name: "Short name",
    image_url: "Image URL",
    code: "Code",
    uuid: "Uuid",
    description: "Description",
    gtin_code: "Gtin code",
    gtin_type: "Gtin type",
    reference_code: "Reference_code",
    unit: "Unit",
    capacity_unit: "Capacity unit",
    capacity_value: "Capacity value",
    display_height: "Display height",
    display_length: "Display length",
    display_width: "Display width",
    packaging_weight: "Packaging weight",
    packaging_width: "Packaging width",
    packaging_height: "Packaging height",
    packaging_length: "Packaging length",
    product_types: "Product types"
  };