import { useState } from "react";
import classNames from "classnames";

import { GENERAL_UI_COLOR } from "../../constants/color";
import { formatDateTime } from "../../utils/date-time";
import { convertNameToColors } from "../../utils/data-transformation";
import UiTooltip from "../ui-tooltip/component";
import Typography from "../ui-typography/component";
import PreviewPopup from "../ui-preview-popup/component";

import styles from "./styles.module.scss";

interface IAvatarProps {
  className?: string;
  imageSrc?: string;
  handleClick?: () => void;
  name?: string;
  size?: "sm" | "lg";
  key?: string;
  preview?: boolean;
  fixSize?: number;
  variant?: GENERAL_UI_COLOR;
  allowPropagation?: boolean;
  allowDynamicBackgroundColor?: boolean;
  isCircleRoundedImage?: boolean;
  statusDot?: {
    workMode?: string;
    outsideUntil?: number;
  };
}

const Avatar = ({
  className,
  imageSrc,
  handleClick,
  name,
  variant = GENERAL_UI_COLOR.SECONDARY,
  size = "sm",
  key,
  preview = false,
  fixSize,
  allowPropagation = false,
  isCircleRoundedImage = false,
  allowDynamicBackgroundColor = false,
  statusDot,
}: IAvatarProps) => {
  const handleSplitFirstLetter = (string: string) => string.substring(0, 1);

  const handleImageError = (
    e: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    e.currentTarget.src =
      "https://placehold.co/600x600?text=!";
  };

  const dynamicBackgroundColor = name && convertNameToColors(name);

  const [showPreviewPopup, setShowPreviewPopup] = useState(false);

  const renderStatusDot = (statusDot: {
    workMode?: string;
    outsideUntil?: number;
  }) => {
    switch (statusDot.workMode) {
      case "available": {
        return <div className={classNames("bg-success", styles.dot)}></div>;
      }
      case "outside": {
        return (
          <UiTooltip
            tooltip={
              <div className={classNames(styles.tooltip, "d-flex gap-2")}>
                <Typography fontWeight="fw-normal">Outside until:</Typography>
                <Typography fontWeight="fw-bolder">
                  {formatDateTime(
                    statusDot?.outsideUntil as number,
                    "YYYY-MM-DD HH:mm"
                  )}
                </Typography>
              </div>
            }
          >
            <div className={classNames("bg-warning", styles.dot)}></div>
          </UiTooltip>
        );
      }
      case "custom": {
        return <div className={classNames("bg-light", styles.dot)}></div>;
      }

      default:
        return <div className={classNames("bg-success", styles.dot)}></div>;
    }
  };

  return (
    <div
      className={`d-inline-block ${className} position-relative`}
      onClick={(e) => {
        !allowPropagation && e.stopPropagation();
        handleClick && handleClick();
      }}
    >
      {!!statusDot && (
        <div className={classNames("position-absolute ", styles.statusDot)}>
          {renderStatusDot(statusDot)}
        </div>
      )}
      {!imageSrc && name ? (
        <div
          className={classNames(
            ` shadow`,
            !allowDynamicBackgroundColor && `bg-${variant}`,
            isCircleRoundedImage ? "avatar rounded-circle" : "avatar"
          )}
          style={{
            width: size === "sm" ? 24 : 35,
            height: size === "sm" ? 24 : 35,
            minWidth: fixSize && fixSize,
            minHeight: fixSize && fixSize,
            fontWeight: 700,
            backgroundColor: allowDynamicBackgroundColor
              ? dynamicBackgroundColor
                ? `rgb(${dynamicBackgroundColor[0] || 0},${
                    dynamicBackgroundColor[1] || 0
                  },${dynamicBackgroundColor[2] || 0})`
                : "rgb(0, 0, 0)"
              : undefined,
          }}
        >
          <div className={classNames(size === "sm" ? "fs-6" : "fs-5")}>
            {handleSplitFirstLetter(name).toUpperCase()}
          </div>
        </div>
      ) : (
        <img
          src={
            imageSrc || "https://js.lixibox.com/image-assets/user/avatar.webp"
          }
          alt={`user-avatar-${key}`}
          className={classNames(
            isCircleRoundedImage ? " avatar rounded-circle" : "avatar"
          )}
          style={{
            width: size === "sm" ? 24 : 35,
            height: size === "sm" ? 24 : 35,
            minWidth: fixSize && fixSize,
            minHeight: fixSize && fixSize,
          }}
          onMouseOver={(e) => {
            setShowPreviewPopup(preview);
          }}
          onMouseLeave={(e) => {
            setShowPreviewPopup(false);
          }}
          onError={handleImageError}
          onClick={(e) => {
            !allowPropagation && e.stopPropagation();
            handleClick && handleClick();
          }}
        />
      )}
      {showPreviewPopup && (<PreviewPopup imageSrc={imageSrc}/>)}
    </div>
  );
};

export default Avatar;
