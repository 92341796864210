import * as R from "../../../routings/path";
import ProductTableCell from "../../../components/table-cell-product";
import Badge from "../../../components/ui-badge/component";

import { GENERAL_UI_SIZE } from "../../../constants/size";
import { GENERAL_UI_COLOR } from "../../../constants/color";

export const confirmationSchema = ({
  productState,
  currentProductInPackagesQty,
}: {
  productState: any;
  currentProductInPackagesQty: Record<string, number>;
}) => {
  return [
    {
      name: "Product",
      cell: (data: any) => (
        <ProductTableCell
          linkTo={`${R.PRODUCTS_LIST}/${data?.uuid}`}
          image={data?.image_url}
          title={data?.name}
          description={data?.code}
          maxWidth={150}
          imageSize={40}
        />
      ),
    },
    {
      name: "Location",
      align: "center",
      cell: (data: any) => (
        <ProductTableCell align="center" title={data?.location} />
      ),
    },
    {
      name: "Expected Quantity",
      align: "center",
      cell: (data: any) => {
        return (
          <ProductTableCell
            align="center"
            title={data?.expected_quantity?.toString()}
          />
        );
      },
    },
    {
      name: "Scanned Qty",
      align: "center",
      cell: (data: any) => {
        return (
          <ProductTableCell
            align="center"
            title={data?.actual_quantity?.toString()}
          />
        );
      },
    },
    {
      name: "Status",
      align: "center",
      textSize: "xl",
      cell: (data: any) => {
        let productStatus: "underStock" | "exceeded" | "ok" = "underStock";

        const scannedQty =
          productState?.[data?.gtin_code]?.[data.location]?.scannedQty;
        const productAlreadyInPackagesQty =
          currentProductInPackagesQty?.[data?.gtin_code] || 0;

        const quantity = data?.quantity;
        const productsInPackages = scannedQty + productAlreadyInPackagesQty;

        if (productsInPackages < quantity) {
          productStatus = "underStock";
        } else if (productsInPackages === quantity) {
          productStatus = "ok";
        } else if (productsInPackages > quantity) {
          productStatus = "exceeded";
        }

        return (
          <>
            {productStatus === "underStock" && (
              <Badge
                content={"Under Stock"}
                size={GENERAL_UI_SIZE.SMALL}
                type={GENERAL_UI_COLOR.WARNING}
              />
            )}
            {productStatus === "exceeded" && (
              <Badge
                content={"Exceeded"}
                size={GENERAL_UI_SIZE.SMALL}
                type={GENERAL_UI_COLOR.DANGER}
              />
            )}
            {productStatus === "ok" && (
              <Badge
                content={<i className="fa-solid fa-check fa-xl text-white"></i>}
                size={GENERAL_UI_SIZE.SMALL}
                type={GENERAL_UI_COLOR.SUCCESS}
              />
            )}
          </>
        );
      },
    },
  ];
};
