const Component = ({
  tabs,
  selectedTab,
  contents,
  onNavigate,
  navigation,
  contentCol = 8,
}: {
  tabs: Array<string>;
  selectedTab: number;
  contents: any;
  onNavigate: (_: number) => any;
  navigation: any;
  contentCol?: number;
}) => {

  return (
    <div className={`col-12 col-lg-${contentCol} m-auto`}>
      <div className="multisteps-form__form">
        {tabs.map((tab, index) => (
          <div
            className={`multisteps-form__panel  ${
              index <= selectedTab || index === 0 ? "js-active" : ""
            }`}
          >
            <div
              className={`card  p-4 border-radius-xl bg-white`}
              style={{
                opacity: index === selectedTab ? 1 : 0,
                visibility: index === selectedTab ? "visible" : "hidden",
              }}
              data-animation="FadeIn"
            >
              <div className="multisteps-form__content">
                {navigation}
                {contents[index]}
              </div>
            </div>
            <div className="py-3">
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Component;
