import * as R from "../../routings/path";

import { TABLE_ACTION } from "../../constants/table-action";
import ActionTableCell from "../../components/table-cell-action";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import WarehouseTableCell from "../../components/table-cell-warehouse";
import DateTimeTableCell from "../../components/table-cell-date-time";

interface ISchemaProps {
  onDelete?: any;
}

export const schema = ({ onDelete }: ISchemaProps) => [
  {
    name: "Number",
    cell: (data: any) => {
      return (
        <ProductTableCell
          title={`#${data?.number}`}
          linkTo={`${R.RELOCATION_SHIPMENTS_LIST}/${data?.uuid}`}
          description={ data?.type == "FastRelocationShipment" ? "Fast" : "Normal" }
        />
      );
    },
  },
  {
    name: "From",
    cell: (data: any) => {
      return (
        <WarehouseTableCell
          warehouse={data?.from_warehouse}
        />
      );
    },
  },
  {
    name: "To",
    cell: (data: any) => {
      return (
        <WarehouseTableCell
          warehouse={data?.to_warehouse}
        />
      );
    },
  },
  {
    name: "Created",
    align: "center",
    width: 100,
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => (
      <DateTimeTableCell
        timeStamp={data?.created_at}
        formatTimestamp={{
          time: "HH:mm YYYY-MM-DD",
          tooltip: "HH:mm YYYY-MM-DD",
        }}
      />
    ),
  },
  {
    name: "Status",
    align: "center",
    width: 100,
    cell: (data: any) => <StatusTableCell status={data?.status} />,
  },
  {
    name: "Action",
    align: "right",
    width: 120,
    cell: (data: any) => {
      return (
        <ActionTableCell
          actions={{
            edit: { actionable: true, message: [] },
            show: { actionable: true, message: [] },
          }}
          types={[TABLE_ACTION.VIEW, TABLE_ACTION.EDIT]}
          toggleData={data?.status}
          viewLink={`${R.RELOCATION_SHIPMENTS_LIST}/${data?.uuid}`}
          editLink={`${R.RELOCATION_SHIPMENTS_LIST}/${data?.uuid}/edit`}
          onDelete={() => {
            onDelete(data?.uuid);
          }}
          popupContent="Are you sure to delete this user?"
        />
      );
    },
  },
];
