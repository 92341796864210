import * as R from "../../../routings/path";
import { TABLE_ACTION } from "../../../constants/table-action";
import { ACTIONABLE_TYPE } from "../../../constants/actionable";
import StatusTableCell from "../../../components/table-cell-status";
import ProductTableCell from "../../../components/table-cell-product";
import ActionTableCell from "../../../components/table-cell-action";

export const assigneeSchema = ({
  selectedAssignee,
  onSelectedAssignee,
}: {
  selectedAssignee: any;
  onSelectedAssignee: any;
}) => [
  {
    name: "Name",
    cell: (data: any) => (
      <ProductTableCell
        title={data.name}
        linkTo={`${R.ACCOUNT_LIST}/${data.uuid}`}
        maxWidth={120}
        image={data.avatar}
        isForceDisplayAvatar
        allowDynamicAvatarBackgroundColor
        userName={data.name}
        displayStatusMode={{
          workMode: data?.work_mode,
          outsideUntil: data?.outside_until,
        }}
      />
    ),
  },
  {
    name: "Status",
    align: "center",
    width: 80,
    cell: (data: any) => {
      return <StatusTableCell status={data.status} />;
    },
  },
  {
    name: "Action",
    align: "right",
    cell: (data: any) => {
      const isExist = selectedAssignee?.uuid === data.uuid;
      if (isExist) return <div> Added</div>;

      return (
        <ActionTableCell
          types={[TABLE_ACTION.CUSTOM]}
          customActionableType={ACTIONABLE_TYPE.FULL}
          customTitle={"Select"}
          onCustomClick={() => onSelectedAssignee(data)}
        />
      );
    },
  },
];
