import classNames from "classnames";
import { htmlStripTag } from "../../utils/format";
import styles from "./styles.module.scss";

const Component = ({
  packages,
  onDeletePackage,
}: {
  packages?: Array<any>;
  onDeletePackage: any;
}) => {
  if (!packages || !packages.length) return null;

  return (
    <div  >
      {packages?.map((packageItem: any, index: number) => {
        return (
          <div className={classNames(styles.packageList, 'mb-3')}>
            <div className={styles.packageItem} key={`package-${index}`}>
              <div className={styles.info}>
                <div>
                  Width: <span>{packageItem?.packageInfo?.width}</span>
                </div>
                <div>
                  Height: <span>{packageItem?.packageInfo?.height}</span>
                </div>
                <div>
                  Length: <span>{packageItem?.packageInfo?.length}</span>
                </div>
                <div>
                  Weight: <span>{packageItem?.packageInfo?.weight}</span>
                </div>
                <div
                  className={styles.actionItem}
                  title="Delete"
                  onClick={() => onDeletePackage(index)}
                >
                  <i
                    className="fas fa-trash text-secondary"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div className={styles.products}>
                {packageItem?.products?.map((product: any) => {
                  return (
                    <div className={styles.productItem}>
                      <img src={product?.image_url} />
                      <div className={styles.name}>
                        {htmlStripTag(product?.name)}
                      </div>
                      <div className={styles.quantity}>{product?.quantity}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Component;
