import React, { useState, useEffect } from "react";
import Typography from "../ui-typography/component";

const Clock = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (date: any) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${seconds}`;
  };

  return (
    <div className="text-right w-100">
      <Typography
        customFontsize={11}
        fontWeight="fw-bold"
        className="text-center"
        textColor="#adb5bd"
      >
        {formatTime(currentTime)}
      </Typography>
    </div>
  );
};

export default Clock;
