import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  IUpdateTplRoutesApi,
  getTplRoutesDetailApi,
  getTplRoutesDistrictApi,
  getTplRoutesProductsApi,
  getTplRoutesProvinceApi,
  getTplRoutesWarehousesApi,
  toggleTplRouteStatusApi,
  updateTplRoutesApi,
} from "../../apis/paths/tpl-routes";
import { ILinkForm } from "../../types/tpl";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { GENERAL_STATUS } from "../../constants/status";
import { IGetTplRoutesApi } from "../../types/setup";
import { apiCaller, useApiCaller } from "../../apis/config";
// import { areObjectsEqual } from "../../utils/compare";
import { LOCAL_STORAGE } from "../../constants/storage";
import HeaderInfoPanel from "../../components/panel-header-info";
import alert from "../../utils/alert";
import Typography from "../../components/ui-typography/component";
import UiButton from "../../components/ui-button";
import NotFoundPanel from "../../components/panel-not-found";
import UiForm from "../../components/ui-form";
import TableCellStatus from "../../components/table-cell-status";
import UiSwitch from "../../components/ui-switch";
import GridLayoutTwoColumn from "../../components/grid-layout-two-column/component";
// import usePreventDirtyFormExit from "../../hooks/usePreventDirtyFormExist";
// import LeavePagePopupConfirmation from "../../components/popup-leave-page-confirmation/component";

import { formSchema } from "./formSchema";
import { LINK_OBJECT_TYPES, LINK_TYPES, OPTIONS_LENGTH } from "./constants";
import EditTplFormRouting from "./view-edit-tpl-route-form-routing";
import EditTplFormInfo from "./view-edit-tpl-route-form-info";

const defaultTplInfo = {
  link: [],
  route: {},
};

const Component = () => {
  const [tplInfo, setTplInfo] = useState<any>(defaultTplInfo);
  const [assets, setAssets] = useState({});
  const [hasStored, setHasStored] = useState(false);
  // TEMP PAUSING: some issue with run build for 'useBlocker' on staging
  // const [isShowLeavePageConfimationModal, setIsShowLeavePageConfimationModal] =
  //   useState(false);

  const initialMappedLink = useRef({});
  const [toggleStatus, setToggleStatus] = useState<
    GENERAL_STATUS.ACTIVATE | GENERAL_STATUS.DEACTIVE
  >(GENERAL_STATUS.DEACTIVE);

  const { route } = tplInfo || {};

  const { routeId } = useParams();
  const id = routeId || "";
  const [params] = useState<{
    page: number;
    per: number;
    id: string;
  }>({
    page: 1,
    per: 50,
    id,
  });

  const { result } = useApiCaller({
    api: getTplRoutesDetailApi,
    params: params,
  });

  useEffect(() => {
    setTplInfo(result?.data);
  }, [result]);

  useEffect(() => {
    setToggleStatus(tplInfo?.route?.status);
  }, [tplInfo?.route?.status]);

  const {
    setValue,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: "all",
  });

  const formFields = getValues();
  useEffect(() => {
    if (
      !hasStored &&
      formFields &&
      formFields.links &&
      Object.keys(formFields.links as any).length > 0
    ) {
      setHasStored(true);
      initialMappedLink.current = formFields;
      if (!localStorage.getItem(LOCAL_STORAGE.TPL_FORM_DEFAULT_VALUE)) {
        localStorage.setItem(
          LOCAL_STORAGE.TPL_FORM_DEFAULT_VALUE,
          JSON.stringify(formFields)
        );
      }
    }
  }, [formFields]);

  useEffect(() => {
    if (!route) return;
    const keys = Object.keys(formSchema?.fields);
    keys.forEach((key: any) => {
      if (key === "priority") {
        const priorityValue = Number(route[key as keyof IGetTplRoutesApi]);
        setValue(
          key,
          priorityValue > OPTIONS_LENGTH ? OPTIONS_LENGTH - 1 : priorityValue
        );
      }
      setValue(key, route[key as keyof IGetTplRoutesApi]);
    });
  }, [result, tplInfo]);

  const checkKeyDown = (e: any) => {
    if (e.key === "Enter") e.preventDefault();
  };

  const onSubmit = async (formData: any, event: any) => {
    event.preventDefault();
    const { name, service, status, priority, links } = formData || {};
    const removedEmptyLink = Object.values(links).filter(
      (link: any) => !!link?.linked_values
    );

    const formatedLinks = (removedEmptyLink as ILinkForm[])?.map(
      (link: ILinkForm) => {
        if (!LINK_TYPES.includes(link?.linked_type as string)) {
          return {
            linked_object_type: link.linked_type,
            linked_object_uuids: link.linked_values,
          };
        }
        return link;
      }
    );

    const formatArrayOfStringToArrayOfObjectLinkedObjectType = (
      formatedLink: any,
      type: string
    ) =>
      Array.isArray(formatedLink?.linked_object_uuids)
        ? (formatedLink as any)?.linked_object_uuids?.map(
            (objUuid: string) => ({
              linked_object_type: type,
              linked_object_uuid: objUuid,
            })
          )
        : formatedLink;

    const formatArrayOfStringToArrayOfObjectLinkedType = (
      formatedLink: any,
      type: string
    ) => {
      return Array.isArray(formatedLink?.linked_values)
        ? (formatedLink as any)?.linked_values?.map((obj: any) => ({
            linked_type: type,
            linked_value: obj.note,
          }))
        : formatedLink;
    };

    const flattenedProducts =
      (formatedLinks as ILinkForm[])?.flatMap((formatedLink: ILinkForm) =>
        LINK_OBJECT_TYPES.includes(formatedLink?.linked_object_type as string)
          ? formatArrayOfStringToArrayOfObjectLinkedObjectType(
              formatedLink,
              formatedLink?.linked_object_type as string
            )
          : formatArrayOfStringToArrayOfObjectLinkedType(
              formatedLink,
              formatedLink?.linked_type as string
            )
      ) || [];

    const waitUpdateRole = await apiCaller({
      api: updateTplRoutesApi,
      params: {
        id,
        name,
        status,
        service,
        priority,
        links: flattenedProducts,
      } as IUpdateTplRoutesApi,
    });

    if (waitUpdateRole.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update successful!");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return;
    }

    alert.error("Update was not successful!. Please try again!");
  };

  const toggleTplRouteStatus = async () => {
    const oldStatus = toggleStatus;
    const nextStatus =
      toggleStatus === GENERAL_STATUS.ACTIVATE
        ? GENERAL_STATUS.DEACTIVE
        : GENERAL_STATUS.ACTIVATE;
    setToggleStatus(nextStatus);

    setValue("status", nextStatus);
    const waitToggle = await apiCaller({
      api: toggleTplRouteStatusApi,
      params: { id },
    });

    if (waitToggle.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Change status successful!.");
      return;
    }

    setToggleStatus(oldStatus);
    alert.error("Change status was not successful!. Please try again");
  };

  const { result: warehouses } = useApiCaller({
    api: getTplRoutesWarehousesApi,
    params: params,
  });
  const { result: provinces } = useApiCaller({
    api: getTplRoutesProvinceApi,
    params: params,
  });
  const { result: districts } = useApiCaller({
    api: getTplRoutesDistrictApi,
    params: params,
  });
  const { result: products } = useApiCaller({
    api: getTplRoutesProductsApi,
    params: params,
  });

  useEffect(() => {
    setAssets((prevAsset) => ({
      ...prevAsset,
      ...tplInfo,
      warehouses: warehouses?.data?.list,
      provinces: provinces?.data?.list,
      districts: districts?.data?.list,
      products: products?.data?.list,
    }));
  }, [tplInfo, warehouses, provinces, districts, products]);

  //TEMP PAUSING: Pausing leave page detection feature as some issue when deploying on server

  // const checkIsFormDirty = () => {
  //   const defaultObject = JSON.parse(
  //     localStorage.getItem(LOCAL_STORAGE.TPL_FORM_DEFAULT_VALUE) as string
  //   );
  //   const updatedObject = watch();

  //   return !areObjectsEqual(defaultObject, updatedObject);
  // };

  // const isFormDirty = checkIsFormDirty();
  // const blocker = usePreventDirtyFormExit(isFormDirty);  //Temp pause detect leave page on this page as there is some bug
  // const blocker = usePreventDirtyFormExit(false);

  // useEffect(() => {
  //   if (blocker.state === "blocked") setIsShowLeavePageConfimationModal(true);
  // }, [blocker]);

  if (tplInfo?.status === "ERROR") return <NotFoundPanel />;

  return (
    <>
      <UiForm onSubmit={handleSubmit(onSubmit)} onKeyDown={checkKeyDown}>
        <div className="mb-4">
          <HeaderInfoPanel
            isDisplayInfoArea={true}
            title={route?.name}
            description="Here you can setup and manage your 3PLs"
            image={tplInfo?.avatar}
            infoArea={() => (
              <TableCellStatus
                status={toggleStatus}
                size={GENERAL_UI_SIZE.SMALL}
              />
            )}
            actionArea={() => (
              <>
                <Typography
                  fontWeight="fw-bolder"
                  className="text-xs text-bold me-2 pt-1"
                >
                  STATUS
                </Typography>

                <UiSwitch
                  checked={toggleStatus === GENERAL_STATUS.ACTIVATE}
                  onChange={toggleTplRouteStatus}
                  isEditable={true}
                  className="me-4"
                />

                <UiButton className="me-2" title={"Update"} />
              </>
            )}
          />
        </div>
        <GridLayoutTwoColumn
          className={{ container: "px-0" }}
          widthDivision={[4, 8]}
          gutter={3}
          leftCol={
            <EditTplFormInfo
              data={tplInfo}
              errors={errors}
              register={register}
              setValue={setValue}
            />
          }
          rightCol={
            <EditTplFormRouting
              data={assets}
              errors={errors}
              register={register}
              setValue={setValue}
            />
          }
        />
      </UiForm>
      {/* <LeavePagePopupConfirmation
        blocker={blocker}
        content={"You have unsaved changes in the form."}
        isShowModal={isShowLeavePageConfimationModal}
        handleCloseModal={() => {
          setIsShowLeavePageConfimationModal(false);
        }}
      /> */}
    </>
  );
};

export default Component;
