import * as yup from "yup";
import { validationMessage } from "../../utils/validation";

export const FieldTitle = {
  name: "Name",
  code: "Code",
  phone: "Phone",
  tax_code: "Tax Code",
  full_address: "Full Address",
};

type FieldSchema = keyof typeof FieldTitle;

const MAX_IMAGE_SIZE = 3145728;

export const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(FieldTitle[path as FieldSchema])
      ),
    code: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(FieldTitle[path as FieldSchema])
      ),
    phone: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(FieldTitle[path as FieldSchema])
      ),
    full_address: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(FieldTitle[path as FieldSchema])
      ),
    tax_code: yup.string().nullable(),
    logo: yup
      .mixed()
      .nullable()
      .test(
        "fileSize",
        "File size is too large, should be smaller than 3Mbs",
        (value: any) => {
          if (!value || !value[0] || !value[0].file) return true; // Pass validation if value is null or empty
          return value?.[0]?.file?.size <= MAX_IMAGE_SIZE;
        }
      )
      .test("fileType", "Unsupported file type", (value: any) => {
        if (!value || !value[0] || !value[0].file) return true; // Pass validation if value is null or empty
        return ["image/jpeg", "image/png", "image/webp"].includes(
          value?.[0]?.file?.type
        );
      }),
  })
  .required();
