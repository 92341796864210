import { useEffect, useState } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

import ViewNodeItem, { IViewNodeItem } from "./view-node-item";

import { LOCAL_STORAGE } from "../../constants/storage";
import { useLocalStorageExists } from "../../hooks/useLocalStorageExist";

import Typography from "../ui-typography/component";
import ModalGeneral from "../ui-modal-general/component";
import useConfiguration from "../../hooks/useConfiguration";
import useHandleSidebarStatus from "../../hooks/useSidebarStatus";

const {
  PACKING_MOUSE_SELECTABLE,
  PACKING_AUTO_PRINT,
  PACKING_PRODUCT_LIST_PRINTABLE,
} = LOCAL_STORAGE;

const PanelSettingController = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const { sidebarStatus, dispatchSidebarStatus } = useHandleSidebarStatus();
  const isSidebarOpened = sidebarStatus?.isOpened;

  const { settingState, dispatchConfigurationStatus } = useConfiguration();

  const {
    isPackingMouseSelectable,
    isPackingAutoPrintable,
    isPackingProductListPrintable,
  } = settingState || {};

  const isPackingAutoPrintFromLocal = useLocalStorageExists(PACKING_AUTO_PRINT);
  const isPackingMouseSelectableFromLocal = useLocalStorageExists(
    PACKING_MOUSE_SELECTABLE
  );
  const isPackingProductListPrintableFromLocal = useLocalStorageExists(
    PACKING_PRODUCT_LIST_PRINTABLE
  );

  /* When first load component -> Assign value get from localStorage to redux store */
  useEffect(() => {
    dispatchConfigurationStatus({
      key: "isPackingAutoPrintable",
      value: isPackingAutoPrintFromLocal,
    });
    dispatchConfigurationStatus({
      key: "isPackingMouseSelectable",
      value: isPackingMouseSelectableFromLocal,
    });
    dispatchConfigurationStatus({
      key: "isPackingProductListPrintable",
      value: isPackingProductListPrintableFromLocal,
    });
  }, []);

  const handleControlLocalStorage = (localStorageKey: string) => {
    const value = localStorage.getItem(localStorageKey);

    if (!!value) {
      localStorage.removeItem(localStorageKey);
      return;
    }

    localStorage.setItem(localStorageKey, JSON.stringify("existed"));
  };

  const handleChangeSidebarStatus = () => {
    dispatchSidebarStatus({ isOpened: !isSidebarOpened });
  };

  const CONFIGURATIONS: IViewNodeItem[] = [
    {
      key: 1,
      icon: "fa-compress",
      title: "Navbar Collapsed",
      onChange: handleChangeSidebarStatus,
      checked: isSidebarOpened,
      subSets: [],
    },
    {
      key: 2,
      parentTitle: "Packing",
      subSets: [
        {
          key: 3,
          icon: "fa-robot",
          title: "Auto Print Label",
          onChange: () => {
            handleControlLocalStorage(PACKING_AUTO_PRINT);
            dispatchConfigurationStatus({
              key: "isPackingAutoPrintable",
              value: !isPackingAutoPrintable,
            });
          },
          checked: isPackingAutoPrintable,
        },
        {
          key: 4,
          icon: "fa-print",
          title: "Auto Print Product List",
          onChange: () => {
            handleControlLocalStorage(PACKING_PRODUCT_LIST_PRINTABLE);
            dispatchConfigurationStatus({
              key: "isPackingProductListPrintable",
              value: !isPackingProductListPrintable,
            });
          },
          checked: isPackingProductListPrintable,
        },
        {
          key: 5,
          icon: "fa-hand-pointer",
          title: "Mouse Selectable",
          onChange: () => {
            handleControlLocalStorage(PACKING_MOUSE_SELECTABLE);
            dispatchConfigurationStatus({
              key: "isPackingMouseSelectable",
              value: !isPackingMouseSelectable,
            });
          },
          checked: isPackingMouseSelectable,
        },
      ],
    },
  ];

  return (
    <>
      <ModalGeneral
        isShowModal={isShowModal}
        sideMode="right"
        onCancel={() => {
          setIsShowModal(false);
        }}
        className={{ body: "p-0 overflow-hidden" }}
        header={
          <Typography fontWeight="fw-bold" fontSize="fs-5" textColor="black">
            Settings
          </Typography>
        }
        isShowFooter={false}
      >
        {CONFIGURATIONS?.map((configuration) => {
          return (
            <ViewNodeItem
              key={configuration?.key}
              configuration={configuration}
            />
          );
        })}
      </ModalGeneral>

      <div
        className={classNames(
          styles.container,
          "d-flex align-items-center justify-content-center"
        )}
        onClick={() => setIsShowModal(true)}
      >
        <i className="fa-solid fa-gear"></i>
      </div>
    </>
  );
};

export default PanelSettingController;
