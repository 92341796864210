import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { generateUrlParams } from "../../utils/url";
import { get, patch, del, post, put } from "../config";

interface IGetShopListApi {
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
}

export interface ICreateShopApi {
  name: string;
  code: string;
  phone: string;
  tax_code?: string;
  full_address: string;
  logo?: any;
}

export interface IUpdateShopApi {
  uuid: string;
  name: string;
  code: string;
  phone: string;
  tax_code?: string;
  full_address: string;
  logo?: any;
}

export const getShopListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
}: IGetShopListApi) =>
  get({
    path: `/wms/shops${generateUrlParams({ page, per, keyword })}`,
  });

export const getDeletedShopListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
}: IGetShopListApi) =>
  get({
    path: `/wms/shops/deleted${generateUrlParams({ page, per })}`,
  });

export const deleteShopApi = ({ code }: { code: string }) =>
  del({ path: `/wms/shops/${code}` });

export const restoreShopApi = ({ code }: { code: string }) =>
  patch({ path: `/wms/shops/${code}/restore` });

export const getShopDetailApi = ({ uuid }: { uuid: string }) =>
  get({ path: `/wms/shops/${uuid}` });

export const getShopBarCodeApi = ({ code }: { code: string }) =>
  get({ path: `/wms/shops/${code}/print` });

export const updateShopApi = ({ uuid, data }: { uuid: string; data: any }) =>
  put({
    path: `/wms/shops/${uuid}`,
    data,
    isFormData: true,
  });

export const createShopApi = (data: any) =>
  post({
    path: `/wms/shops`,
    data,
    isFormData: true,
  });
