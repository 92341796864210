import classnames from "classnames";
import styles from "./style.module.scss";
import noDataImg from "../../styles/images/no-data.jpg";

interface IPanelNotFoundProps {
  title?: string;
  description?: string;
  panelClassName?: string;
}
const NoDataPanel = ({
  title = "Empty!",
  description = "There is no data to display.",
  panelClassName,
}: IPanelNotFoundProps) => (
  <div className={styles.container}>
    <div className={classnames(styles.panel, panelClassName)}>
      <div className={styles.image}>
        <img src={noDataImg} alt="no-data" />
      </div>
      <div className={styles.info}>
        <h3 className="text-center">{title}</h3>
        <p className="lead mb-0">{description}</p>
      </div>
    </div>
  </div>
);

export default NoDataPanel;
