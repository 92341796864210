import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiCaller } from "../apis/config";
import { getDynamicDocsApi } from "../apis/paths/settings";

const sliceName = "dynamicDocParameter";

interface IparametersProps {
  parameters: any;
  isLoading: boolean;
  error: any;
}

const INITIAL_STATE: IparametersProps = {
  parameters: [],
  isLoading: false,
  error: null,
};

interface IDynamicDocParams {
  docPath: string;
  docField: string;
}

export const fetchDocParameter = createAsyncThunk(
  `${sliceName}/fetchDocParameter`,
  async ({ docPath, docField }: IDynamicDocParams) => {
    const response = await apiCaller({
      api: getDynamicDocsApi,
      params: {
        page: 1,
        per: 5,
      },
    });

    return { ...response.data, docPath, docField };
  }
);

export const parametersDocSlice = createSlice({
  name: sliceName,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDocParameter.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchDocParameter.fulfilled, (state, { payload }) => {
      const { paths, docField, docPath } = payload || {};

      const docParameters = paths?.[docPath]?.post?.parameters || [];

      const docParameter =
        docParameters?.filter(
          (paramerter: any) => paramerter?.name === docField
        )[0]?.enum || [];

      const formatText = (string: string) => {
        return string
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      };

      const formatedParameter = docParameter?.map((item: any) => ({
        title: formatText(item),
        value: item,
      }));

      state.parameters = formatedParameter
    });
    builder.addCase(fetchDocParameter.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const { actions } = parametersDocSlice;

export default parametersDocSlice.reducer;
