import { PRODUCT_OVERALL_STATUS } from "../../../constants/status";
import UiInput from "../../../components/ui-input";
import TwoColumnGridLayout from "../../../components/grid-layout-two-column";
import RadioComboBox from "../../../components/ui-radio-combo-box/component";

interface IViewStep3 {
  register: any;
  errors: any;
  isToWarehouseChildOfFromWarehouse: boolean;
  control: any;
}

const ViewStep3 = ({
  register,
  errors,
  isToWarehouseChildOfFromWarehouse,
  control,
}: IViewStep3) => {
  return (
    <div>
      <TwoColumnGridLayout
        className={{ container: "px-0" }}
        leftCol={
          <>
            <UiInput
              title={"Note"}
              name={"note"}
              register={register}
              errorMessage={errors?.note?.message}
            />

            {isToWarehouseChildOfFromWarehouse && (
              <UiInput
                title={"Shipping Service"}
                name={"shiping_service"}
                register={register}
                errorMessage={errors?.shiping_service?.message}
              />
            )}
          </>
        }
        rightCol={
          <RadioComboBox
            className={{ container: "mt-0 mb-3" }}
            control={control}
            options={PRODUCT_OVERALL_STATUS}
            title={"Product Status"}
            name="product_status"
            required
          />
        }
      />
    </div>
  );
};

export default ViewStep3;
