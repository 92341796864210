import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as R from "../../routings/path";
import { apiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { createTaskLoggingApi } from "../../apis/paths/taskLogging";
import { convertYYYYMMDDToUnixTime } from "../../utils/date-time";
import alert from "../../utils/alert";
import UiButton from "../../components/ui-button";
import HeaderInfoPanel from "../../components/panel-header-info";
import MultiStepPanel from "../../components/panel-multi-step";

import { splitAndCapitalize } from "./utils";
import ViewTaskTypeSelect, { ITask } from "./view/view-task-type-selection";
import { taskLoggingSchema } from "./schema/taskLoggingSchema";
import ViewAssignee from "./view/view-assignee";
import ViewDetailTaskLoggingInfo from "./view/view-detail-info";

interface ICreateTaskLoggingParams {
  task_name: string;
  assignee_uuid: string;
  in_progress_at?: number;
  due_at?: number;
  linked_object_code?: string;
  linked_object_type?: string;
  description?: string;
}

const Component = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [taskType, setTaskType] = useState<string | null>(null);
  const [selectedAssignee, setSelectedAssignee] = useState<string | null>(null);
  const [multiStepPanelTabIndex, setMultiStepPanelTabIndex] = useState(0);
  const [multiStepPanelTab, setMultiStepPanelTab] = useState<ITask | null>(
    null
  );

  const {
    setValue,
    register,
    handleSubmit,
    control,
    setError,
    trigger,
    watch,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(taskLoggingSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const linkedObjectType = watch("linked_object_type");
  const linkedObjectCode = watch("linked_object_code");
  const startAt = watch("start_at");
  const dueAt = watch("due_at");

  useEffect(() => {
    if (multiStepPanelTab?.name) {
      setValue("task_name", multiStepPanelTab.name);
      setTaskLoggingSearchParams((prev) => ({
        ...prev,
        task_name: multiStepPanelTab.name,
      }));
    }
  }, [multiStepPanelTab?.name]);

  const handleSelectAssignee = (assignee: any) => {
    setSelectedAssignee(assignee);
    setValue("assignee_uuid", assignee?.uuid);
  };

  const handleSetTaskType = (index: number | string, objectType: ITask) => {
    setTaskType(index as string);
    setMultiStepPanelTab(objectType);

    Object.keys(objectType.pickObject).forEach((key) => {
      setValue("linked_object_type", key);
    });
  };

  const onSubmit = async ({
    task_name,
    assignee_uuid,
    linked_object_code,
    linked_object_type,
    start_at,
    due_at,
    description,
  }: any) => {
    let startAt = start_at;
    let dueAt = due_at;

    if (typeof start_at === "string") {
      startAt = convertYYYYMMDDToUnixTime(start_at);
    }

    if (typeof due_at === "string") {
      dueAt = convertYYYYMMDDToUnixTime(due_at);
    }

    if (startAt || dueAt) {
      if (!(startAt && dueAt)) {
        if (!startAt) {
          setError("start_at", {
            type: "manual",
            message: "Please input this field",
          });
          setMultiStepPanelTabIndex(1);
        }
        if (!due_at) {
          setError("due_at", {
            type: "manual",
            message: "Please input this field",
          });
          setMultiStepPanelTabIndex(1);
        }

        alert.error(
          "Please input both of In Progress At and Due At or None of them"
        );
        return;
      }
    }

    let params: ICreateTaskLoggingParams = {
      task_name,
      assignee_uuid,
      in_progress_at: startAt,
      due_at: dueAt,
      linked_object_code,
      linked_object_type,
      description,
    };

    if (!linked_object_code) {
      params = {
        task_name,
        assignee_uuid,
        in_progress_at: startAt,
        due_at: dueAt,
        description,
      };
    }

    const createTaskLogging = await apiCaller({
      api: createTaskLoggingApi,
      params,
    });
    setLoading(false);

    const { status } = createTaskLogging;
    if (status === API_RESPONSE_STATUS.SUCCESS) {
      navigate(R.TASK_LOGGING_LIST);
    }
  };

  const renderStep1 = () => (
    <ViewTaskTypeSelect
      errorMessage={errors?.task_name?.message}
      taskType={taskType as string}
      setTaskType={handleSetTaskType}
    />
  );

  const initialParams = {
    ...DEFAULT_PAGINATION_PARAMS,
    task_name: "inbound_qc",
  };

  const [taskLoggingSearchParams, setTaskLoggingSearchParams] = useState<{
    page: number;
    per: number;
    keyword: string;
    task_name: string;
    sort: {
      field: string;
      direction: string;
    } | null;
  }>(initialParams);

  const renderStep2 = () => (
    <ViewDetailTaskLoggingInfo
      register={register}
      control={control}
      errors={errors}
      setValue={setValue}
      dirtyFields={dirtyFields}
      startAt={startAt}
      dueAt={dueAt}
      multiStepPanelTab={multiStepPanelTab}
      taskLoggingSearchParams={taskLoggingSearchParams}
      setTaskLoggingSearchParams={setTaskLoggingSearchParams}
    />
  );

  const renderStep3 = () => (
    <div>
      {!!multiStepPanelTab?.name && (
        <ViewAssignee
          selectedType={multiStepPanelTab?.name as string}
          linkedObjectType={linkedObjectType as string}
          linkedObjectCode={linkedObjectCode as string}
          selectedAssignee={selectedAssignee}
          setSelectedAssignee={handleSelectAssignee}
          errorMessage={errors?.assignee_uuid?.message as string}
        />
      )}
    </div>
  );

  const taskName = watch("task_name");
  const assigneeUuid = watch("assignee_uuid");
  const isSubmitable = !!taskName && !!assigneeUuid;

  return (
    <>
      <HeaderInfoPanel
        title={"Create new task"}
        description={"Input info below to create a new task"}
        actionArea={() => (
          <>
            <UiButton
              disabled={!isSubmitable}
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
              className="me-2"
              title={"Create"}
            />
          </>
        )}
      />
      <MultiStepPanel
        defaultTab={multiStepPanelTabIndex}
        onChangeTab={(index: number) => {
          setMultiStepPanelTabIndex(index);
        }}
        onCheckPassStepValidation={async (nextTab: number) => {
          let isValidationPassed = true;
          if (multiStepPanelTabIndex === 0 && nextTab === 1) {
            isValidationPassed = await trigger(["task_name"]);
          }
          if (multiStepPanelTabIndex === 1 && nextTab === 2) {
            isValidationPassed = await trigger([
              "description",
              "linked_object_code",
              "linked_object_type",
            ]);
          }

          return isValidationPassed;
        }}
        tabs={["1. Task Type", "2. Detail", "3. Assign Worker"]}
        contentHeadings={[
          "STEP 1",
          `Detail - ${splitAndCapitalize(multiStepPanelTab?.name as string)}`,
          `Pick a worker - ${splitAndCapitalize(
            multiStepPanelTab?.name as string
          )}`,
        ]}
        contentCol={12}
      >
        {renderStep1()}
        {renderStep2()}
        {renderStep3()}
      </MultiStepPanel>
    </>
  );
};

export default Component;
