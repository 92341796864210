import React, { useEffect, useState } from "react";
import classNames from "classnames";

import {
  calculateRemainingTime,
  convertYYYYMMDDToUnixTime,
} from "../../../utils/date-time";
import { useDebounce } from "../../../hooks/useDebounce";
import { GENERAL_UI_COLOR } from "../../../constants/color";
import { useApiCaller } from "../../../apis/config";
import { addSpacesBeforeUppercase } from "../../../utils/format";
import { getTaskLoggingSelectionItems } from "../../../apis/paths/taskLogging";
import Badge from "../../../components/ui-badge/component";
import Overlay from "../../../components/ui-overlay/component";
import UiTextArea from "../../../components/ui-text-area/component";
import InputSearch from "../../../components/search-input/component";
import DatePicker from "../../../components/ui-date-picker/component";
import UiLoadingPanel from "../../../components/ui-loading-panel";
import SmallCollumnCenterGridLayout from "../../../components/grid-layout-small-collumn-center";

import styles from "./styles.module.scss";
import { ITask } from "./view-task-type-selection";

interface IViewDetailTaskLoggingInfo {
  register: any;
  control: any;
  errors: any;
  setValue: any;
  dirtyFields: any;
  taskLoggingSearchParams: any;
  setTaskLoggingSearchParams: any;
  startAt?: any;
  dueAt?: any;
  multiStepPanelTab: ITask | null;
}

const ViewDetailTaskLoggingInfo = ({
  register,
  control,
  errors,
  setValue,
  dirtyFields,
  setTaskLoggingSearchParams,
  taskLoggingSearchParams,
  startAt,
  dueAt,
  multiStepPanelTab,
}: IViewDetailTaskLoggingInfo) => {
  const [searchValue, setSearchValue] = useState("");

  const relatedFieldName = () => {
    if (!multiStepPanelTab?.pickObject) return "Related";

    return Object.keys(multiStepPanelTab?.pickObject)?.map(
      (fieldName: string) => fieldName
    )?.[0];
  };

  const formatedFieldName = addSpacesBeforeUppercase(relatedFieldName());
  const debouncedValue = useDebounce(searchValue, 500);

  useEffect(() => {
    setTaskLoggingSearchParams((prev: any) => ({
      ...prev,
      keyword: debouncedValue,
    }));
  }, [debouncedValue, setTaskLoggingSearchParams]);

  const { result: selectionItemSearchResult, loading } = useApiCaller({
    api: getTaskLoggingSelectionItems,
    params: taskLoggingSearchParams,
  });

  return (
    <SmallCollumnCenterGridLayout>
      <UiTextArea
        title={"Description"}
        name={"description"}
        register={register}
        errorMessage={errors?.description?.message}
        rows={3}
      />
      <div className="mt-3">
        <label>{formatedFieldName}</label>
        <Overlay
          content={
            <InputSearch
              value={searchValue}
              setValue={setSearchValue}
              className={{ container: "p-0" }}
              placeholder={
                "Type keyword/code to search the related object (SO, PO Pick List, Putaway List, Location List)"
              }
              style={{ backgroundColor: "white" }}
            />
          }
        >
          {loading ? (
            <UiLoadingPanel type={"minimal"} />
          ) : (
            <div className="w-100">
              {selectionItemSearchResult?.data?.list?.map((item: any) => {
                return (
                  <div
                    className={classNames(styles.searchItem)}
                    onClick={() => {
                      setSearchValue(item?.display_code);
                      setValue("linked_object_code", item?.uniq_code);
                    }}
                  >
                    <div className="ps-1">{`${item?.display_code}`}</div>
                  </div>
                );
              })}
            </div>    
          )}
        </Overlay>
      </div>

      <div className="d-flex gap-4 mt-3">
        <div className="d-flex gap-2 flex-grow-1">
          <div className="col-6">
            <DatePicker
              className="dataTable-input"
              name={"start_at"}
              title={"In Progress At"}
              type="datetime-local"
              isDisplayLabel={true}
              control={control}
              errorMessage={errors?.start_at?.message as string}
              dirtyFields={dirtyFields}
            />
          </div>
          <div className="col-6">
            <DatePicker
              className="dataTable-input"
              name={"due_at"}
              title={"Due At"}
              type="datetime-local"
              isDisplayLabel={true}
              control={control}
              errorMessage={errors?.due_at?.message as string}
              dirtyFields={dirtyFields}
            />
          </div>
        </div>
        <div className="text-start pe-2">
          <label>Expect Remaining: </label>
          <div className="text-danger mt-1">
            <Badge
              content={calculateRemainingTime(
                convertYYYYMMDDToUnixTime(dueAt) as number,
                convertYYYYMMDDToUnixTime(startAt) as number
              )}
              type={GENERAL_UI_COLOR.WARNING}
            />
          </div>
        </div>
      </div>
    </SmallCollumnCenterGridLayout>
  );
};

export default ViewDetailTaskLoggingInfo;
