import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiCaller } from "../apis/config";
import { IFilterOptions } from "../components/ui-filter/component";

const sliceName = "assigneeFilter";

interface AssigneeFilterProps {
  options: IFilterOptions[];
  searchingOptions: IFilterOptions[];
  isLoading: boolean;
  error: any;
}

const INITIAL_STATE: AssigneeFilterProps = {
  options: [],
  searchingOptions: [],
  isLoading: false,
  error: null,
};

export const fetchAssignee = createAsyncThunk(
  `${sliceName}/fetchAssignee`,
  async (apiPath: any) => {
    const response = await apiCaller({
      api: apiPath,
      params: {
        keyword: "",
      },
    });
    return response?.data;
  }
);

export const fetchAssigneeWithSearch = createAsyncThunk(
  `${sliceName}/fetchAssigneeWithSearch`,
  async ({ apiPath, keyword }: { apiPath: any; keyword: string }) => {
    const response = await apiCaller({
      api: apiPath,
      params: {
        keyword,
      },
    });
    return response?.data;
  }
);

const mappedAssigneeFilterOptions = (data: any) =>
  data?.map((assignee: any) => ({
    value: assignee.uuid,
    content: assignee.name,
    userDetail: {
      imageUrl: assignee.avatar,
    },
  }));

export const assigneeFilterSlice = createSlice({
  name: sliceName,
  initialState: INITIAL_STATE,
  reducers: {
    addSearchedAssignee: (state, action: PayloadAction<IFilterOptions>) => {
      const optionsWithOrder = [
        action.payload,
        ...state.options.filter(
          (assignee: IFilterOptions) => assignee.value !== action.payload.value
        ),
      ];

      state.options = optionsWithOrder;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAssignee.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchAssignee.fulfilled, (state, { payload }) => {
      state.options = mappedAssigneeFilterOptions(payload?.list);
      state.isLoading = false;
    });
    builder.addCase(fetchAssignee.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    builder.addCase(fetchAssigneeWithSearch.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchAssigneeWithSearch.fulfilled, (state, action) => {
      state.searchingOptions = mappedAssigneeFilterOptions(
        action?.payload?.list
      );
      state.isLoading = false;
    });
    builder.addCase(fetchAssigneeWithSearch.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const { actions } = assigneeFilterSlice;
export const { addSearchedAssignee } = assigneeFilterSlice.actions;

export default assigneeFilterSlice.reducer;
