import * as yup from "yup";

export const formSchema = yup
  .object({
    name: yup.string().required(),
    description: yup.string().nullable(),
    upper_role_uuid: yup.string().nullable(),
    status: yup.string().oneOf(["activate", "deactivate"]).required(),
    permission_ids: yup.array().required("Permission ids are required"),
  })
  .required();
