import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import CreateAccountForm from "./view-create-account-form";
import { formSchema } from "./formSchema";
import { ICreateAccountParams } from "./types";

import { apiCaller } from "../../apis/config";
import { createAccountApi } from "../../apis/paths/accounts";
import { ACCOUNT_LIST } from "../../routings/path";
import { convertYYYYMMDDToUnixTime } from "../../utils/date-time";
import { API_RESPONSE_STATUS } from "../../constants/api";
import alert from "../../utils/alert";
import UiForm from "../../components/ui-form";
import UiButton from "../../components/ui-button";
import HeaderInfoPanel from "../../components/panel-header-info";
import SmallCollumnCenterGridLayout from "../../components/grid-layout-small-collumn-center";

const Component = () => {
  const navigate = useNavigate();
  const {
    setValue,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async ({
    name,
    username,
    status,
    password,
    expired_at,
  }: ICreateAccountParams) => {
    const waitUpdateRole = await apiCaller({
      api: createAccountApi,
      params: {
        name,
        username,
        status,
        password,
        expired_at: convertYYYYMMDDToUnixTime(expired_at),
      },
    });

    if (waitUpdateRole.status === API_RESPONSE_STATUS.SUCCESS) {
      setTimeout(() => {
        alert.success("Create account successful!");
      }, 1000);
      navigate(ACCOUNT_LIST);
      return;
    }

    alert.error("Update was not successful!. Please try again!");
  };

  return (
    <>
      <UiForm onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <HeaderInfoPanel
            isDisplayInfoArea={true}
            title="Invite new member"
            description="Access management"
            actionArea={() => <UiButton className="me-2" title={"Create"} />}
          />
        </div>
        <SmallCollumnCenterGridLayout>
          <CreateAccountForm
            setValue={setValue}
            control={control}
            errors={errors}
            register={register}
          />
        </SmallCollumnCenterGridLayout>
      </UiForm>
    </>
  );
};

export default Component;
