/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Select, { StylesConfig } from "react-select";
import classNames from "classnames";

import Typography from "../../components/ui-typography/component";

import styles from "./style.module.scss";

export interface IOptionItem {
  value?: number;
  content?: string;
}

interface IFormItem {
  typeIds?: number[];
}

const ItemProductType = ({ option }: { option: IOptionItem }) => (
  <div
    className={classNames(
      "d-flex gap-2 align-items-center cursor-pointer p-2",
      styles.hoverEffect
    )}
    key={option?.value}
  >
    <Typography> {option?.content}</Typography>
  </div>
);

const ProductTypesSearchBox = ({
  setSelectedItems,
  formItem,
  errorMessage,
  optionList,
  isDisabled,
  required,
}: {
  setSelectedItems: any;
  formItem: IFormItem;
  errorMessage?: any;
  optionList: any;
  isDisabled?: boolean;
  required?: boolean;
}) => {
  const controlStyles: StylesConfig<any, true> = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: "",
      width: "100%",
      borderRadius: "0.5rem",
    }),
    menuList: (base: any) => ({
      ...base,
      maxHeight: "250px",
      borderRadius: "0.5rem",
      fontSize: "13px",
    }),
    menuPortal: (base, _props) => ({ ...base, zIndex: 9999 }),
  };

  const mappedOptions = optionList?.map((option: any) => ({
    value: option.value,
    label: option.content,
    customComponent: <ItemProductType option={option} />,
  }));

  const defaultOpitonValue = mappedOptions?.filter((option: any) => {
    return formItem?.typeIds?.includes(Number(option.value));
  });

  const CustomOption = ({ innerProps, data }: any) => (
    <div {...innerProps}>{data.customComponent && data.customComponent}</div>
  );

  useEffect(() => {
    if (defaultOpitonValue && defaultOpitonValue?.length > 0) {
      setSelectedItems(defaultOpitonValue);
    }
  }, []);

  return (
    <div className="mb-3">
      <label data-required={required}>Product Types</label>

      <Select
        menuPortalTarget={document.body}
        options={mappedOptions}
        components={{ Option: CustomOption }}
        isSearchable
        styles={controlStyles}
        isMulti
        isDisabled={isDisabled}
        defaultValue={defaultOpitonValue}
        onChange={(choice: any) => setSelectedItems(choice)}
      />
      {errorMessage && errorMessage.length && (
        <label className="mb-3" style={{ color: "#f5365c" }}>
          {errorMessage}
        </label>
      )}
    </div>
  );
};

export default ProductTypesSearchBox;
