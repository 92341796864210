import { ILabelObj } from "../../types/label";

import { TProductWithLocation } from "./component";
import { IPackedPackages, IProductSaleOrder } from "./types";

export const moveToTopAndHighlight = (
  selectedKey: any,
  array: any[],
  field: string
) => {
  let selectedItem;
  const spreadArray = array?.slice(0);
  // Find the selected item by its selectedKey
  for (let i = 0; i < spreadArray?.length; i++) {
    if (spreadArray[i][field] === selectedKey) {
      selectedItem = spreadArray[i];
      spreadArray.splice(i, 1);
      break;
    }
  }

  // If the item was found, add it to the top
  if (selectedItem) {
    spreadArray.unshift(selectedItem);
  }

  return spreadArray;
};

export const transformObject = (
  inputObject: Record<string, Record<string, any>>
) => {
  const resultObject: Record<string, number> = {};

  for (const key in inputObject) {
    resultObject[key] = 0;
  }

  return resultObject;
};

export const findObjectsWithExcessScannedQty = (data: any) => {
  const exceededObjects = [];

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const innerData = data[key];
      for (const locationKey in innerData) {
        const occupiedQty =
          innerData?.[locationKey]?.scannedQty +
          innerData?.[locationKey]?.quantityInPackage;

        if (
          innerData.hasOwnProperty(locationKey) &&
          occupiedQty > innerData?.[locationKey]?.requiredQty
        ) {
          exceededObjects.push({
            gtinCode: key,
            location: locationKey,
            data: innerData?.[locationKey],
          });
        }
      }
    }
  }

  return exceededObjects;
};

export const getTotalQtyOfAllProducts = (products: IProductSaleOrder[]) => {
  if (!products) return 0;

  return products?.reduce((acc: number, product: IProductSaleOrder) => {
    return (acc += product?.updatedQuantity || 0);
  }, 0);
};

export const getTotalQtyOfAllLocationProducts = (
  gtinCode: string,
  productState: Record<string, TProductWithLocation>
) => {
  if (!gtinCode || !productState || !productState[gtinCode]) return 0;

  const totalQtyOfAllLocationProducts = Object.values(
    productState?.[gtinCode]
  )?.reduce((acc, current) => {
    return (acc += current?.requiredQty || 0);
  }, 0);

  return totalQtyOfAllLocationProducts;
};

export const isScannedAllProductOnAllLocations = (
  productState: TProductWithLocation
) => {
  return Object.values(productState)?.every((productWithLocations) => {
    return Object.values(productWithLocations)?.every((productWithLocation) => {
      const remainingProductsQty =
        productWithLocation?.requiredQty -
        (productWithLocation?.quantityInPackage || 0) -
        productWithLocation?.scannedQty;
      return remainingProductsQty === 0;
    });
  });
};

export const getProductQtyOfSinglePackage = (packagesDetail: any[]) => {
  const currentProductsWithPackedProductMap = new Map<string, number>(null);

  packagesDetail?.forEach((item: any) => {
    const productUuid = item?.product?.gtin_code;
    if (!currentProductsWithPackedProductMap.has(item?.product?.gtin_code)) {
      currentProductsWithPackedProductMap.set(productUuid, item.quantity);
    } else {
      const productQty = currentProductsWithPackedProductMap.get(productUuid);
      currentProductsWithPackedProductMap.set(
        productUuid,
        (productQty || 0) + item.quantity
      );
    }
  });

  const productGtinCodes = Object.fromEntries(
    Array.from(currentProductsWithPackedProductMap)
  );

  return productGtinCodes;
};

export const flatenedProductsOfAllPackages = (productPackages: any[]) => {
  const products: IPackedPackages[] = [];
  productPackages?.forEach((productPackage) => {
    products.push(...productPackage.package_products);
  });

  const totalProducts = products?.reduce((acc: number, currProduct) => {
    return acc + currProduct.quantity;
  }, 0);

  return totalProducts;
};

export const getAllQtyProcessingProductsBaseOnProductState = (
  productState: TProductWithLocation
) => {
  return Object.values(productState)?.reduce(
    (acc: number, productInLocations) => {
      const accProductsInLocation = Object.values(productInLocations)?.reduce(
        (eachProductAcc: number, product) => {
          return (eachProductAcc += product?.scannedQty);
        },
        0
      );
      return (acc += accProductsInLocation);
    },
    0
  );
};

export const getAllRemainingQtyProductsBaseOnProductState = (
  productState: TProductWithLocation
) => {
  return Object.values(productState)?.reduce(
    (acc: number, productInLocations) => {
      const accProductsInLocation = Object.values(productInLocations)?.reduce(
        (eachProductAcc: number, product) => {
          const remainingProductsQty =
            product?.requiredQty -
            (product?.quantityInPackage || 0) -
            product?.scannedQty;

          return (eachProductAcc += remainingProductsQty);
        },
        0
      );
      return (acc += accProductsInLocation);
    },
    0
  );
};

export const isSaleOrderHasGiftLabel = (labels: ILabelObj[]) => {
  return labels?.some((obj) => obj?.label?.label_type === "gift");
};
