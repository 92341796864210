import classNames from "classnames";
import React, { useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import styles from "./style.module.scss";

import { GENERAL_UI_COLOR } from "../../constants/color";

export interface IListDropdownProps {
  type: "header" | "link" | "button" | "divider";
  href?: string;
  content?: string | React.ReactNode;
  handleClick?: () => void;
  id?: string | number;
}

/* sample listItems data
const listDropdown: IListDropdownProps[] = [
  { type: "header", content: "Action1" },
  { type: "link", href: "/", content: "Link" },
  {
    type: "button",
    handleClick: () => {
      1("Click me!!!");
    },
    content: "Button",
  },
  { type: "divider" },
  { type: "link", href: "#", content: "Somthing elseee" },
]; */

interface IDropdownProps {
  title: string | React.ReactNode;
  size?: "sm" | "lg" | undefined;
  direction?: "up" | "up-centered" | "down" | "down-centered" | "start" | "end";
  flip?: boolean;
  textColor?: string;
  variant?: GENERAL_UI_COLOR | "transparent";
  className?: {
    container?: string;
  };
}

interface IDropdownWithListItemsProps extends IDropdownProps {
  listItems: any[];
  children?: React.ReactNode;
}
interface IDropdownChildrenProps extends IDropdownProps {
  listItems?: any[];
  children: React.ReactNode;
}

type IUiDropdown = IDropdownWithListItemsProps | IDropdownChildrenProps;

const UiDropdown = ({
  variant = GENERAL_UI_COLOR.PRIMARY,
  title = "Button",
  size = undefined,
  direction = "down-centered",
  listItems,
  children,
  flip = true,
  textColor,
  className = {
    container: "",
  },
}: IUiDropdown) => {
  useEffect(() => {
    if (!textColor) return;
    const root = document.documentElement;
    root?.style.setProperty("--dropdown-text-color", textColor);
  }, [textColor]);

  if (!title || (!listItems && !children)) return null;

  return (
    <DropdownButton
      title={title}
      variant={variant}
      size={size}
      drop={direction}
      flip={flip}
      className={classNames(
        variant === "transparent" && styles.transparent,
        textColor && styles.textColor,
        className?.container
      )}
    >
      {!!children
        ? children
        : listItems?.map((item: IListDropdownProps, index: number) => {
            switch (item.type) {
              case "divider":
                return <Dropdown.Divider key={item.id || index} as="hr" />;
              case "header":
                return <Dropdown.Header>{item.content}</Dropdown.Header>;
              case "link":
                return (
                  <Dropdown.Item href={item.href}>{item.content}</Dropdown.Item>
                );
              case "button":
                return (
                  <Dropdown.Item as="button" onClick={item.handleClick}>
                    {item.content}
                  </Dropdown.Item>
                );
              default:
                return <Dropdown.Item >{item.content}</Dropdown.Item>;
            }
          })}
    </DropdownButton>
  );
};

export default UiDropdown;
