import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPackingListApi } from "../../apis/paths/packing";
import { getPickingListSelectionApi } from "../../apis/paths/picking";
import { useApiCaller } from "../../apis/config";
import { IFilterField } from "../../components/ui-filter/component";
import { generateFilterParamsWithName } from "../../utils/format";
import { schema } from "./schema";
import TablePanel from "../../components/panel-table";
import UiTab from "../../components/ui-tab";

const Component = () => {
  const navigate = useNavigate()
  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const handleToggleUiTab = (newActiveTab: number) => {
    setCurrentActiveTab(newActiveTab);
  };

  const { result: selection } = useApiCaller({
    api: getPickingListSelectionApi,
  })

  const packStatus = currentActiveTab === 0 ? "packable" : "completed";

  const FILTER_OPTION_LIST : IFilterField[] = [
    {
      field: "warehouse_uuid",
      template: 'normal',
      displayTitle: "Warehouse",
      operator: "in",
      options: generateFilterParamsWithName(selection?.data?.warehouses || []),
    },
  ]

  return (
    <TablePanel
      apiPath={getPackingListApi}
      apiParams={{
        status: packStatus,
      }}
      filter={{
        optionList: FILTER_OPTION_LIST,
        pageCode: 'packList',
      }}
      panel={{
        title: "Packing list",
        description: "List of packing in the system",
        actionArea: () => (
          <>
            <UiTab
              tabs={["Packable", "Completed"]}
              selected={currentActiveTab}
              onChange={handleToggleUiTab}
            />
          </>
        ),
      }}
      tableSchema={schema({navigate})}
    />
  );
};

export default Component;
