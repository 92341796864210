import { htmlStripTag } from "../../utils/format";

import styles from "./styles.module.scss";
import { useEffect, useRef } from "react";

const Component = ({
  product,
  onDeleteItem,
  onUpdateItem,
  latestSelectedProduct,
}: {
  product: any;
  onDeleteItem: any;
  onUpdateItem: any;
  latestSelectedProduct?: any;
}) => {
  const shouldFocusAndSelect =
    latestSelectedProduct?.uuid === product?.uuid || false;

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (shouldFocusAndSelect && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [shouldFocusAndSelect]);

  return (
    <>
      <div className={styles.productItem} key={`product-${product.uuid}`}>
        <div className={styles.info}>
          <img src={product?.image_url} alt="product" />
          <div className={styles.content}>
            <div className={styles.address}>{htmlStripTag(product?.name)}</div>
          </div>
        </div>
        <div className={styles.action}>
          <div
            className={styles.actionItem}
            title="Delete"
            onClick={() => onDeleteItem(product)}
          >
            <i className="fas fa-trash text-secondary" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default Component;
