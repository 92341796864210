/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useApiCaller } from "../../apis/config";
import { apiCaller } from "../../apis/config";
import { GENERAL_UI_SIZE } from "../../constants/size";
import {
  getRelocationShipmentDetailApi,
  updateRelocationShipment,
  updateRelocationStatusApi,
} from "../../apis/paths/relocationShipment";
import { PRODUCT_OVERALL_STATUS } from "../../constants/status";

import alert from "../../utils/alert";
import UiInput from "../../components/ui-input";
import UiButton from "../../components/ui-button";
import GeneralPanel from "../../components/panel-general";
import RadioComboBox from "../../components/ui-radio-combo-box/component";
import NotFoundPanel from "../../components/panel-not-found/component";
import UiLoadingPanel from "../../components/ui-loading-panel";
import HeaderInfoPanel from "../../components/panel-header-info";
import TableCellStatus from "../../components/table-cell-status";
import UiStatusDropdown from "../../components/ui-status-dropdown";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import useHandleSidebarStatus from "../../hooks/useSidebarStatus";
import PanelLabelController from "../../components/ui-panel-label-controller/component";

import { schema } from "./schema";
import GeneralHeading from "./view-general-heading";

const Component = () => {
  const [pageReloadVersion, setPageReloadVersion] = useState(1);

  const { register, setValue, control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const { dispatchSidebarStatus } = useHandleSidebarStatus();

  useEffect(() => {
    dispatchSidebarStatus({ isOpened: false });
    return () => {
      dispatchSidebarStatus({ isOpened: true });
    };
  }, []);

  const [relocationShipmentInfo, setRelocationShipmentInfo] = useState<any>({});

  const { relocationShipmentId: uuid } = useParams();
  const [params] = useState({ uuid });

  const { result, loading } = useApiCaller({
    api: getRelocationShipmentDetailApi,
    params: params,
    pageReloadVersion,
  });

  useEffect(() => {
    if (result?.data) {
      let relocationShipmentResult = result?.data;
      setRelocationShipmentInfo(relocationShipmentResult);
      setValue("shiping_service", relocationShipmentResult?.shipping_service);
      setValue("note", relocationShipmentResult?.note);
      setValue("product_status", relocationShipmentResult?.product_status);
    }
  }, [result]);

  const onSubmit = async ({ shiping_service, note, product_status }: any) => {
    const waitUpdateSaleOrder = await apiCaller({
      api: updateRelocationShipment,
      params: {
        uuid,
        shiping_service,
        product_status,
        note,
      },
    });

    if (waitUpdateSaleOrder?.status === "SUCCESS") {
      alert.success("Updated successfull");
      setPageReloadVersion((prev) => prev + 1);
    }
  };

  if (loading) return <UiLoadingPanel />;

  if (result?.status === "ERROR") return <NotFoundPanel />;

  return (
    <>
      <div className="mb-4">
        <HeaderInfoPanel
          isDisplayInfoArea={true}
          title={`Relocation shipment from ${relocationShipmentInfo?.from_warehouse?.name} to ${relocationShipmentInfo?.to_warehouse?.name}`}
          infoArea={() => (
            <>
              <TableCellStatus
                status={relocationShipmentInfo?.status}
                size={GENERAL_UI_SIZE.SMALL}
              />
              <TableCellStatus
                className="ms-2"
                status={relocationShipmentInfo?.shipping_service}
                size={GENERAL_UI_SIZE.SMALL}
              />
            </>
          )}
          actionArea={() => (
            <>
              {relocationShipmentInfo?.status && (
                <UiStatusDropdown
                  status={relocationShipmentInfo?.status}
                  triggerableEvents={relocationShipmentInfo?.triggerable_events}
                  actionApi={updateRelocationStatusApi}
                  paramsTransformation={(newEvent: string) => ({
                    uuid: uuid,
                    event: newEvent,
                  })}
                  onChangeSuccess={(newData: any) => {
                    setRelocationShipmentInfo({
                      ...relocationShipmentInfo,
                      status: newData.status,
                      triggerable_events: newData.triggerable_events,
                    });
                  }}
                />
              )}
              <UiButton
                className="me-2"
                title={"Update"}
                onClick={handleSubmit(onSubmit)}
              />
            </>
          )}
        />
      </div>
      <GeneralPanel className="pt-4">
        <GeneralHeading>General Info</GeneralHeading>
        <TwoColumnGridLayout
          widthDivision={[6, 6]}
          leftCol={
            <>
              <UiInput
                title={"Shiping Service"}
                name={"shiping_service"}
                className="mb-3"
                register={register}
              />

              <RadioComboBox
                className={{ container: "mt-4 mb-3" }}
                control={control}
                options={PRODUCT_OVERALL_STATUS}
                title={"Product Status"}
                name="product_status"
              />
            </>
          }
          rightCol={
            <>
              <UiInput
                title={"Note"}
                name={"note"}
                className="mb-3"
                register={register}
              />
            </>
          }
        />
      </GeneralPanel>
      <GeneralPanel className="pt-4">
        <GeneralHeading>Labels</GeneralHeading>
        <PanelLabelController
          linkedObjectType="RelocationShipment"
          uniqueUuid={uuid as string}
        />
      </GeneralPanel>
    </>
  );
};

export default Component;
