import classNames from "classnames";

import { IThTable } from "../../../components/ui-table/component";

import * as R from "../../../routings/path";
import styles from "../styles.module.scss";
import Typography from "../../../components/ui-typography/component";
import ProductTableCell from "../../../components/table-cell-product";

export const packedProductSchema = ({
  isLargeScreen,
}: {
  isLargeScreen: boolean;
}): IThTable[] => {
  return [
    {
      name: (
        <Typography
          customFontsize={11}
          fontWeight="fw-bolder"
          className="d-flex align-items-center gap-2"
        >
          Packed Products
        </Typography>
      ),
      cell: (data: any, index: number) => (
        <div className="d-flex align-items-center gap-2">
          <span className="badge badge-secondary text-white ">{index + 1}</span>
          <ProductTableCell
            linkTo={`${R.PRODUCTS_LIST}/${data?.uuid}`}
            image={data?.image_url}
            title={data?.name}
            linkTarget="_blank"
            description={data?.gtin_code}
            isOneLineTitle={false}
            maxWidth={isLargeScreen ? 500 : 350}
            imageSize={40}
          />
        </div>
      ),
    },
    {
      name: (
        <Typography
          customFontsize={11}
          fontWeight="fw-bolder"
          className="d-flex align-items-center gap-2"
        >
          Quantity
        </Typography>
      ),
      align: "center",
      cell: (data: any) => {
        return (
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <ProductTableCell
              align="center"
              title={data?.quantity}
              className={{
                container: classNames(styles.qtyItem, styles.customTitle),
              }}
            />
          </div>
        );
      },
    },
  ];
};
