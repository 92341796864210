import React, { memo, useState } from "react";
import classNames from "classnames";

import { GENERAL_UI_SIZE } from "../../constants/size";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { formatDateTime } from "../../utils/date-time";
import Badge from "../../components/ui-badge/component";
import Typography from "../../components/ui-typography/component";
import UiContentRow from "../../components/ui-content-row/component";

import styles from "./styles.module.scss";
import ViewProductSaleOrder from "./view-product-sale-order";
import { useNavigate } from "react-router-dom";
import { SALE_ORDER_LIST } from "../../routings/path";

interface IViewCardKanban {
  saleOrder: any;
  index: number;
}

export const formatBadgeColor = (status: string) => {
  switch (status) {
    case "pending":
      return GENERAL_UI_COLOR.DANGER;
    case "confirmed":
      return GENERAL_UI_COLOR.SECONDARY;
    case "todo":
      return GENERAL_UI_COLOR.WARNING;
    case "picking":
      return GENERAL_UI_COLOR.PRIMARY;
    case "picked":
      return GENERAL_UI_COLOR.INFO;
    case "packing":
      return GENERAL_UI_COLOR.SECONDARY;
    case "packed":
      return GENERAL_UI_COLOR.SUCCESS;

    default:
      return GENERAL_UI_COLOR.PRIMARY;
  }
};

const ViewCardKanban = ({ saleOrder, index }: IViewCardKanban) => {
  const [isDisplayProducts, setIsDisplayProducts] = useState(false);

  const navigate = useNavigate();

  return (
    <div className={classNames(styles.kanbanItem)}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex gap-2 align-items-center">
          <span className="badge badge-primary">{index + 1}</span>
          <Typography
            fontWeight="fw-bold"
            fontSize="fs-5"
            textColor="black"
            className="cursor-pointer"
            handleClick={() => {
              navigate(`${SALE_ORDER_LIST}/${saleOrder?.uuid}`);
            }}
          >
            {saleOrder?.number}
          </Typography>
        </div>
        <Badge
          customFontsize={10}
          content={saleOrder?.status}
          size={GENERAL_UI_SIZE.XSMALL}
          type={formatBadgeColor(saleOrder?.status)}
        />
      </div>
      <div className="mt-2">
        <UiContentRow type="small" label={"Ref:"}>
          {saleOrder?.reference_number}
        </UiContentRow>
        <UiContentRow type="small" label={"Created At:"}>
          {formatDateTime(saleOrder?.created_at as number, "YYYY-MM-DD  HH:mm")}
        </UiContentRow>
        <UiContentRow type="small" label={"COD:"}>
          {saleOrder?.cod}
        </UiContentRow>
        <UiContentRow type="small" label={"Channel:"}>
          {saleOrder?.channel}
        </UiContentRow>
        <UiContentRow type="small" label={"Warehouse Code:"}>
          {saleOrder?.warehouse_code}
        </UiContentRow>
        <UiContentRow type="small" label={"Receiver:"}>
          {saleOrder?.receiver_name}
        </UiContentRow>
        <UiContentRow
          type="small"
          label={"Address:"}
          customStyles={{ value: { wordBreak: "break-word" } }}
        >
          {saleOrder?.address?.full_address
            ? `${saleOrder?.address?.full_address}`
            : `${saleOrder?.address?.street_name}, ${saleOrder?.address?.ward_name}, ${saleOrder?.address?.district_name}, ${saleOrder?.address?.province_name}`}
        </UiContentRow>
        <UiContentRow
          type="small"
          label={"Note:"}
          customStyles={{ value: { wordBreak: "break-word" } }}
        >
          {saleOrder?.note}
        </UiContentRow>
        <UiContentRow
          type="small"
          label={"Product:"}
          className={{ container: "cursor-pointer" }}
          onClick={() => {
            setIsDisplayProducts((prev) => !prev);
          }}
        >
          <div className="d-flex align-items-center gap-2">
            {isDisplayProducts ? (
              <>
                Show less <i className="fa-solid fa-angles-up "></i>
              </>
            ) : (
              <>
                See detail <i className="fa-solid fa-angles-down "></i>
              </>
            )}
          </div>
        </UiContentRow>
        {isDisplayProducts && (
          <div
            className={classNames(
              styles.productPanel,
              isDisplayProducts && styles.isOpened
            )}
          >
            <ViewProductSaleOrder saleOrderUuid={saleOrder?.uuid} />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ViewCardKanban);
