import { useNavigate } from "react-router-dom";

import * as R from "../../routings/path";
import { ACTIONABLE_TYPE } from "../../constants/actionable";
import {
  getPurchaseOrderCountApi,
  getPurchaseOrderListApi,
} from "../../apis/paths/purchase-order";
import TablePanel from "../../components/panel-table";
import { IFilterField } from "../../components/ui-filter/component";

import { schema } from "./schema";
import { useApiCaller } from "../../apis/config";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { generateFilterParamsWithQtyCount } from "../../utils/format";

const Component = () => {
  const navigate = useNavigate();

  const { result: purchaseOrderCount } = useApiCaller({
    api: getPurchaseOrderCountApi,
    params: DEFAULT_PAGINATION_PARAMS,
  });

  const PURCHASE_ORDER_OPTIONS : IFilterField[] = [
    {
      field: "status",
      template: 'bigNumber',
      operator: "in",
      options: generateFilterParamsWithQtyCount(purchaseOrderCount?.data),
    },
  ];

  return (
    <TablePanel
      apiPath={getPurchaseOrderListApi}
      filter={{
        optionList: PURCHASE_ORDER_OPTIONS,
        pageCode: 'purchase_orders',
      }}
      panel={{
        title: "Purchase Orders",
        description: "List of purchase order in the system",
        actionableButtons: [
          {
            actionableType: ACTIONABLE_TYPE.NEW,
            title: "New Purchase Order",
            onClick: () => navigate(R.PURCHASE_ORDERS_CREATE),
          },
        ],
      }}
      tableSchema={schema()}
    />
  );
};

export default Component;
