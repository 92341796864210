import classNames from "classnames";
import UiTable from "../../components/ui-table";

interface IComponent {
  data: any;
  schema: any;
  cardClassName?: string;
  displayHeadingAction?: number[] | null;
  displayLimit?: number;
  trClassName?: string;
  className?: string;
  noDataContent?: string;
}

const Component = ({
  data,
  schema,
  cardClassName = "card",
  displayHeadingAction,
  displayLimit = 0,
  trClassName = "",
  className,
  noDataContent
}: IComponent) => {
  return (
    <div className="row" style={{ height: "100%" }}>
      <div className="col-12">
        <div className={cardClassName} style={{ height: "100%" }}>
          <div className="table-responsive listing-table">
            <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
              <div className="dataTable-container py-2">
                <UiTable
                  displayHeadingAction={displayHeadingAction}
                  data={data}
                  schema={schema}
                  className={classNames("my-0", className)}
                  displayLimit={displayLimit}
                  trClassName={trClassName}
                  noDataContent={noDataContent}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component;
