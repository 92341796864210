import { useRef } from "react";
import classNames from "classnames";

import { IProductSaleOrder, IScannedProduct } from "../types";
import { GENERAL_UI_SIZE } from "../../../constants/size";
import { GENERAL_UI_COLOR } from "../../../constants/color";

import * as R from "../../../routings/path";
import alert from "../../../utils/alert";
import styles from "../styles.module.scss";
import Typography from "../../../components/ui-typography/component";
import ProductTableCell from "../../../components/table-cell-product";
import UiButton from "../../../components/ui-button/component";

export const useProcessingProductSchema = ({
  productState,
  setProductState,
  latestScannedProduct,
  setExceededProducts,
  isInteractable,
  isLargeScreen,
  totalProcessingProductsQty,
  handleRemoveScanningProduct,
}: {
  productState: any;
  setProductState: any;
  latestScannedProduct: IScannedProduct;
  setExceededProducts: any;
  isInteractable: boolean;
  isLargeScreen: boolean;
  totalProcessingProductsQty: number;
  handleRemoveScanningProduct: (params: IProductSaleOrder) => void;
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleSelectAllText = () => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  return [
    {
      name: (
        <Typography
          customFontsize={11}
          fontWeight="fw-bolder"
          className="d-flex align-items-center gap-2"
        >
          Processing Products
          <Typography
            customFontsize={14}
            fontWeight="fw-bold"
            className="text-black"
          >{`(${totalProcessingProductsQty})`}</Typography>
        </Typography>
      ),
      cell: (data: any) => (
        <div className="d-flex align-items-center gap-1">
          <ProductTableCell
            linkTo={`${R.PRODUCTS_LIST}/${data?.uuid}`}
            image={data?.image_url}
            title={data?.name}
            linkTarget="_blank"
            description={data?.code}
            isOneLineTitle={false}
            maxWidth={isLargeScreen ? 500 : 350}
            imageSize={40}
          />
        </div>
      ),
    },
    {
      name: (
        <Typography
          customFontsize={11}
          fontWeight="fw-bolder"
          className="d-flex align-items-center justify-content-center"
        >
          Location
        </Typography>
      ),
      align: "center",
      textSize: "xs",
      cell: (data: any) => (
        <Typography customFontsize={13} fontWeight="fw-bold">
          {data?.location}
        </Typography>
      ),
    },
    {
      name: (
        <Typography
          customFontsize={11}
          fontWeight="fw-bolder"
          className="d-flex align-items-center justify-content-center"
        >
          Scanned Qty
        </Typography>
      ),
      align: "center",
      cell: (data: any) => {
        const productCode = productState?.[data?.gtin_code];
        const productLocation = productCode?.[data?.location];
        const shouldFlashing =
          data?.gtin_code === latestScannedProduct?.qrCode &&
          data?.location === latestScannedProduct?.location;

        const productScannedQty = productCode?.[data.location]?.scannedQty;
        const isEditMode = productCode?.[data.location]?.isEditMode;

        return isEditMode ? (
          <div
            className={classNames(
              styles.action,
              shouldFlashing && styles.customCell
            )}
          >
            <input
              title={"Quantity"}
              name={"quantity"}
              placeholder={" "}
              min={0}
              ref={inputRef}
              onFocus={handleSelectAllText}
              onBlur={() => {
                setProductState(
                  (
                    prev: Record<
                      string,
                      { required: number; scannedQty: number }
                    >
                  ) => ({
                    ...prev,
                    [data?.gtin_code]: {
                      ...prev[data?.gtin_code],
                      [data?.location]: {
                        ...productLocation,
                        isEditMode: false,
                      },
                    },
                  })
                );
              }}
              value={productScannedQty}
              onChange={(e) => {
                if (e.target.value > data?.quantity) {
                  alert.warn("Quantity exceeds the required quantity.");
                }

                setProductState(
                  (
                    prev: Record<
                      string,
                      { required: number; scannedQty: number }
                    >
                  ) => ({
                    ...prev,
                    [data?.gtin_code]: {
                      ...prev[data?.gtin_code],
                      [data?.location]: {
                        ...productLocation,
                        scannedQty: Number(e.target.value),
                      },
                    },
                  })
                );
              }}
              className={classNames(styles.input)}
              type="number"
              required
            />
          </div>
        ) : (
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <ProductTableCell
              align="center"
              title={productScannedQty}
              className={{
                container: classNames(
                  styles.qtyItem,
                  styles.customTitle,
                  shouldFlashing && styles.customCell
                ),
              }}
            />
            {isInteractable && (
              <div
                className="cursor-pointer"
                onClick={() => {
                  setProductState(
                    (
                      prev: Record<
                        string,
                        { required: number; scannedQty: number }
                      >
                    ) => ({
                      ...prev,
                      [data?.gtin_code]: {
                        ...prev[data?.gtin_code],
                        [data?.location]: {
                          ...productLocation,
                          isEditMode: true,
                        },
                      },
                    })
                  );
                }}
              >
                <i className="fa-solid fa-pen-to-square"></i>
              </div>
            )}
          </div>
        );
      },
    },
    {
      name: (
        <Typography
          customFontsize={11}
          fontWeight="fw-bolder"
          className="d-flex align-items-center justify-content-center"
        >
          Action
        </Typography>
      ),
      align: "center",
      textSize: "xs",
      cell: (data: any) => (
        <UiButton
          customType="button"
          size={GENERAL_UI_SIZE.SMALL}
          title={<i className="fa-solid fa-trash"></i>}
          disabled={!isInteractable}
          onClick={() => {
            handleRemoveScanningProduct(data);
          }}
          type={GENERAL_UI_COLOR.DANGER}
        />
      ),
    },
  ];
};
