export const CHANNEL = [
  {
    value: "b2b",
    content: "b2b",
  },
  {
    value: "b2c",
    content: "b2c",
  },
  {
    value: "relocation",
    content: "relocation",
  },
];
