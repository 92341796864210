import classNames from "classnames";
import styles from "./styles.module.scss";

interface IAddressRow {
  type?: "normal" | "small";
  onClick?: () => void;
  className?: {
    container?: string;
  };
  customStyles?: {
    container?: React.CSSProperties;
    lable?: React.CSSProperties;
    value?: React.CSSProperties;
  };
  mainAddress?: string | React.ReactNode;
  detailAddress?: string | React.ReactNode;
}

const AddressRow = ({
  type = "normal",
  onClick = () => {},
  className = {
    container: "",
  },
  mainAddress,
  detailAddress,
  customStyles,
}: IAddressRow) => {
  return (
    <div
      className={classNames(
        styles.value,
        type === "small" && styles.small,
        className.container
      )}
      onClick={onClick}
      style={customStyles?.value}
    >
      <div>{mainAddress}</div>
      <div className={styles.detailAddress}>{detailAddress}</div>
    </div>
  );
};

export default AddressRow;
