import React, { memo, useEffect, useState } from "react";

import { useApiCaller } from "../../apis/config";
import { getSaleOrderListApi } from "../../apis/paths/sale-order";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { IKanbanItem } from "./component";

import ViewKanbanItem from "./view-kanban-item";
import ViewNarrowKanbanItem from "./view-narrow-kanban-item";

export interface IFilterOption {
  page: number;
  per?: number;
  keyword?: string;
  filter?: object | null;
}

interface IViewKanbanList {
  filterOption: IKanbanItem;
}

const ViewKanbanList = ({ filterOption }: IViewKanbanList) => {
  const [kanbanState, setKanbanState] = useState<IFilterOption>({
    page: DEFAULT_PAGINATION_PARAMS.page,
    per: DEFAULT_PAGINATION_PARAMS.per,
    filter: filterOption?.filterParams,
  });

  const [saleOrders, setSaleOrders] = useState<any | null>(null);

  const { result } = useApiCaller({
    api: getSaleOrderListApi,
    params: kanbanState,
  });

  useEffect(() => {
    if (!!result?.data) {
      setSaleOrders((prev: any) => {
        if (!prev) {
          return result?.data;
        }

        if (result?.data?.paging?.current_page !== prev?.paging?.current_page) {
          const newAppendedSaleOrders = [...prev?.list, ...result?.data?.list];
          return { ...result?.data, list: newAppendedSaleOrders };
        }

        return result?.data;
      });
    }
  }, [result]);

  const handleShowmore = () => {
    setKanbanState((prev) => ({ ...prev, page: prev?.page + 1 }));
  };

  const isEmptyList =
    Array.isArray(saleOrders?.list) && saleOrders?.list?.length === 0;

  const [isKanbanCollapsed, setIsKanbanCollapsed] = useState(isEmptyList);

  useEffect(() => {
    setIsKanbanCollapsed(isEmptyList);
  }, [isEmptyList]);

  const handleCollapse = () => {
    setIsKanbanCollapsed(true);
  };

  const handleExpand = () => {
    setIsKanbanCollapsed(false);
  };

  return isKanbanCollapsed ? (
    <ViewNarrowKanbanItem
      filterOption={filterOption}
      saleOrders={saleOrders}
      onExpand={handleExpand}
    />
  ) : (
    <ViewKanbanItem
      filterOption={filterOption}
      saleOrders={saleOrders}
      onShowMore={handleShowmore}
      onCollapse={handleCollapse}
    />
  );
};

export default memo(ViewKanbanList);
