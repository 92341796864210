import { useNavigate} from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  ICreateTplRoutesApi,
  createTplRoutesApi,
} from "../../apis/paths/tpl-routes";
import { apiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import {  TPL_ROUTES} from "../../routings/path";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { GENERAL_STATUS } from "../../constants/status";
import alert from "../../utils/alert";
import UiSwitch from "../../components/ui-switch";
import UiForm from "../../components/ui-form";
import UiButton from "../../components/ui-button";
import TableCellStatus from "../../components/table-cell-status";
import HeaderInfoPanel from "../../components/panel-header-info";
import Typography from "../../components/ui-typography/component";
import SmallCollumnCenterGridLayout from "../../components/grid-layout-small-collumn-center";

import { formSchema } from "./formSchema";
import EditTplFormInfo from "./view-edit-tpl-route-form-info";


const Component = () => {
  const navigate = useNavigate();

  const [toggleStatus, setToggleStatus] = useState<
    GENERAL_STATUS.ACTIVATE | GENERAL_STATUS.DEACTIVE
  >(GENERAL_STATUS.DEACTIVE);

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
     setValue('status', GENERAL_STATUS.DEACTIVE)
  }, [])
  
  const onSubmit = async (formData: any) => {
    const { name, service, status, priority } = formData || {};

    const waitUpdateRole = await apiCaller({
      api: createTplRoutesApi,
      params: {
        name,
        status,
        service,
        priority,
      } as ICreateTplRoutesApi,
    });

    if (waitUpdateRole.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update successful!");
      setTimeout(() => {
        navigate(TPL_ROUTES)
      }, 1000);
      return;
    }

    alert.error("Update was not successful!. Please try again!");
  };

  const toggleTplRouteStatus = async () => {
    const nextStatus =
      toggleStatus === GENERAL_STATUS.ACTIVATE
        ? GENERAL_STATUS.DEACTIVE
        : GENERAL_STATUS.ACTIVATE;
    setToggleStatus(nextStatus);

    setValue("status", nextStatus);
  };

  return (
    <>
      <UiForm onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <HeaderInfoPanel
            isDisplayInfoArea={true}
            title={"New Route"}
            description="Here you can setup and manage your 3PLs"
            infoArea={() => (
              <TableCellStatus
                status={toggleStatus}
                size={GENERAL_UI_SIZE.SMALL}
              />
            )}
            actionArea={() => (
              <>
                <Typography
                  fontWeight="fw-bolder"
                  className="text-xs text-bold me-2 pt-1"
                >
                  STATUS
                </Typography>

                <UiSwitch
                  checked={toggleStatus === GENERAL_STATUS.ACTIVATE}
                  onChange={toggleTplRouteStatus}
                  isEditable={true}
                  className="me-4"
                />

                <UiButton className="me-2" title={"Create"} />
              </>
            )}
          />
        </div>

        <SmallCollumnCenterGridLayout>
          <EditTplFormInfo
            errors={errors}
            register={register}
            setValue={setValue}
          />
        </SmallCollumnCenterGridLayout>
      </UiForm>
    </>
  );
};

export default Component;
