import { htmlStripTag } from "../../utils/format";
import TableCellStatus from "../../components/table-cell-status";
import { GENERAL_STATUS } from "../../constants/status";
import { GENERAL_UI_SIZE } from "../../constants/size";

import styles from "./styles.module.scss";

const SelectedProduct = ({
  label,
  products,
  onDeleteItem,
  onUpdateItem,
  customPanelHeight,
  isDisplayStatusLabel = true,
  isDisplayRequireMark = true,
}: {
  label: string;
  products: any;
  onDeleteItem: any;
  onUpdateItem: any;
  customPanelHeight?: number;
  isDisplayStatusLabel?: boolean;
  isDisplayRequireMark?: boolean;
}) => {
  return (
    <>
      <div className={styles.selectedProductHeading}>
        <label data-required={isDisplayRequireMark}>{label}</label>
        {isDisplayStatusLabel && (
          <TableCellStatus
            status={
              products && products.length
                ? GENERAL_STATUS.SELECTED
                : GENERAL_STATUS.MISSING
            }
            size={GENERAL_UI_SIZE.SMALL}
          />
        )}
      </div>
      <div
        className={styles.selectedProducts}
        style={{ maxHeight: customPanelHeight }}
      >
        {!products || !products.length ? (
          <span className={styles.empty}>No product selected</span>
        ) : (
          products?.map((product: any) => (
            <div className={styles.productItem} key={`product-${product.uuid}`}>
              <div className={styles.info}>
                <img src={product?.image_url} />
                <div className={styles.content}>
                  <div className={styles.address}>
                    {htmlStripTag(product?.name)}
                  </div>
                </div>
              </div>
              <div className={styles.action}>
                <input
                  title={"Quantity"}
                  name={"quantity"}
                  placeholder={" "}
                  min={1}
                  value={product.quantity}
                  onChange={(e) =>
                    onUpdateItem(
                      Object.assign({}, product, {
                        quantity: parseInt(e.target.value),
                      })
                    )
                  }
                  className={styles.input}
                  type="number"
                  required
                />
                <div
                  className={styles.actionItem}
                  title="Delete"
                  onClick={() => onDeleteItem(product)}
                >
                  <i
                    className="fas fa-trash text-secondary"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default SelectedProduct;
