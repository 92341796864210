import { useNavigate } from "react-router-dom";

import * as R from "../../routings/path";
import { ACTIONABLE_TYPE } from "../../constants/actionable";
import {
  getSaleOrderCountApi,
  getSaleOrderListApi,
  getSaleOrderSelectionApi,
} from "../../apis/paths/sale-order";
import { useApiCaller } from "../../apis/config";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import {
  generateFilterParamsWithQtyCount,
  generateFilterParamsWithArray,
  generateFilterParamsWithName,
} from "../../utils/format";
import TablePanel from "../../components/panel-table";
import { IFilterField } from "../../components/ui-filter/component";

import { schema } from "./schema";

const Component = () => {
  const navigate = useNavigate();

  const { result: saleOrderCount } = useApiCaller({
    api: getSaleOrderCountApi,
    params: DEFAULT_PAGINATION_PARAMS,
  });

  const { result: selection } = useApiCaller({
    api: getSaleOrderSelectionApi,
  });

  const SALE_ORDER_OPTIONS: IFilterField[] = [
    {
      field: "channel",
      displayTitle: "Channel",
      operator: "in",
      options: generateFilterParamsWithArray(selection?.data?.channels || []),
    },
    {
      field: "warehouse_uuid",
      template: "normal",
      displayTitle: "Warehouse",
      operator: "in",
      options: generateFilterParamsWithName(selection?.data?.warehouses || []),
    },
    {
      field: "status",
      template: "badgeNumber",
      displayTitle: "Status",
      operator: "in",
      options: generateFilterParamsWithQtyCount(saleOrderCount?.data),
    },
    {
      field: "address_uuid",
      displayTitle: "Address",
      operator: "null",
      isSingleFilter: true,
      options: [
        { value: "0", content: "Valid" },
        { value: "1", content: "Un-Valid" },
      ],
    },
  ];

  return (
    <TablePanel
      apiPath={getSaleOrderListApi}
      filter={{
        optionList: SALE_ORDER_OPTIONS,
        pageCode: "sale_orders",
      }}
      panel={{
        title: "Sale Orders",
        description: "List of sale orders in the system",
        actionableButtons: [
          {
            actionableType: ACTIONABLE_TYPE.NEW,
            title: "New Sale Order",
            onClick: () => navigate(R.SALE_ORDERS_CREATE),
          },
        ],
      }}
      tableSchema={schema}
    />
  );
};

export default Component;
