import styles from "./styles.module.scss";

const Component = ({
  tabs,
  subContentTabs,
  selectedTab,
  onSelect,
}: {
  tabs: Array<any>;
  subContentTabs?: Array<any>;
  selectedTab: number;
  onSelect: Function;
}) => {
  return (
    <div className={`row ${styles.tabs}`}>
      <div className="col-12 col-lg-8 mx-auto my-4">
        <div className="multisteps-form__progress">
          {tabs?.map((tab, index) => (
            <>
              <button
                onClick={() => onSelect(index)}
                className={`multisteps-form__progress-btn ${
                  index <= selectedTab || index === 0 ? "js-active" : ""
                }`}
                type="button"
                title="Address"
              >
                <span className={styles.title}>{tab}</span>

                {Array.isArray(subContentTabs) && !!subContentTabs[index] && (
                  <h6 className="text-sm font-bold flex-grow-1">
                    {subContentTabs[index]}
                  </h6>
                )}
              </button>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Component;
