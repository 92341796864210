import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  getDeletedWarehouseListApi,
  getWarehouseListApi,
  toggleWarehouseStatusApi,
} from "../../apis/paths/warehouse";
import * as R from "../../routings/path";
import { ACTIONABLE_TYPE } from "../../constants/actionable";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { apiCaller } from "../../apis/config";
import alert from "../../utils/alert";

import TablePanel from "../../components/panel-table";
import UiTab from "../../components/ui-tab";

import { schema } from "./schema";
import { deletedWarehouseSchema } from "./deletedWarehousesSchema";

const Component = () => {
  const navigate = useNavigate();
  const [pageReloadVersion, setPageReloadVersion] = useState(1);
  const [currentActiveTab, setCurrentActiveTab] = useState(0);

  const handleDeleteApi = async (code: string) => {
    const waitToggle = await apiCaller({
      api: toggleWarehouseStatusApi,
      params: { code },
    });

    if (waitToggle.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Delete warehouse successful!");
      setPageReloadVersion((prev) => prev + 1);
      return;
    }
    alert.error("Delete warehouse was not successful!. Please try again");
  };

  const handleRestoreApi = async (code: string) => {
    const waitToggle = await apiCaller({
      api: toggleWarehouseStatusApi,
      params: { code },
    });

    if (waitToggle.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Restore warehouse successful!");
      setPageReloadVersion((prev) => prev + 1);
      return;
    }
    alert.error("Restore warehouse was not successful!. Please try again");
  };

  const handleToggleUiTab = (newActiveTab: number) => {
    setCurrentActiveTab(newActiveTab);
    setPageReloadVersion((prev) => prev + 1);
  };

  return currentActiveTab === 0 ? (
    <TablePanel
      apiPath={getWarehouseListApi}
      pageReloadVersion={pageReloadVersion}
      panel={{
        title: "Warehouses",
        description: "List of warehouses in the system",
        actionableButtons: [
          {
            actionableType: ACTIONABLE_TYPE.NEW,
            title: "New Warehouse",
            onClick: () => navigate(R.WAREHOUSES_CREATE),
          },
        ],
        actionArea: () => (
          <>
            <UiTab
              tabs={["In use", "Deleted"]}
              selected={currentActiveTab}
              onChange={handleToggleUiTab}
            />
          </>
        ),
      }}
      tableSchema={schema({
        onDelete: handleDeleteApi,
      })}
    />
  ) : (
    <TablePanel
      apiPath={getDeletedWarehouseListApi}
      pageReloadVersion={pageReloadVersion}
      panel={{
        title: "Deleted warehouses",
        description: "List of deleted warehouse in the system",
        actionArea: () => (
          <UiTab
            tabs={["In use", "Deleted"]}
            selected={currentActiveTab}
            onChange={handleToggleUiTab}
          />
        ),
      }}
      tableSchema={deletedWarehouseSchema({
        onRestore: handleRestoreApi,
      })}
    />
  );
};

export default Component;
