import React from "react";
import UiIcon from "../../components/ui-icon";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { GENERAL_UI_COLOR } from "../../constants/color";
import alert from "../../utils/alert";
import UiAvatar from "../../components/ui-avatar";
import UiSpinner from "../../components/ui-spinner";
import UiProgressBar from "../../components/ui-progress-bar";
import UiPagination from "../../components/ui-pagination";
import UiList from "../../components/ui-list-group";
import UiModalGeneral from "../../components/ui-modal-general";
import UiBadge from "../../components/ui-badge";
import UiCard from "../../components/ui-card";
import UiAlert from "../../components/ui-alert";
import UiInput from "../../components/ui-input";
import UiForm from "../../components/ui-form";
import UiButton from "../../components/ui-button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";
import UiSwitch from "../../components/ui-switch";
import UiTypography from "../../components/ui-typography";
import UiDropdown from "../../components/ui-dropdown";
import { IListDropdownProps } from "../../components/ui-dropdown/component";
import { Dropdown } from "react-bootstrap";
import GridLayoutTwoColumn from "../../components/grid-layout-two-column/component";

interface IRowProps {
  title: string;
  component: React.ReactNode;
}

const Row = ({ title, component }: IRowProps) => (
  <div className="d-flex p-3 border-bottom-2 border border-primary">
    <div className="col-4 d-flex align-items-center align-middle">
      Component: {title}
    </div>
    <div className="col-8 text-start ">{component}</div>
  </div>
);

const Samples = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {};

  const Items = [
    {
      id: 1,
      content: "test",
      handleClick: () => {},
    },
    {
      id: 2,
      content: "test 2",
      handleClick: () => {},
    },
  ];

  const listDropdown: IListDropdownProps[] = [
    { type: "header", content: "Action1" },
    { type: "link", href: "/", content: "Link" },
    {
      type: "button",
      handleClick: () => {},
      content: "Button",
    },
    { type: "divider" },
    { type: "link", href: "#", content: "Somthing elseee" },
  ];

  return (
    <div className="d-flex flex-column">
      <Row
        title="Toast"
        component={
          <button
            onClick={() => {
              alert.info("ahiahi");
            }}
          >
            Toast
          </button>
        }
      />

      <Row
        title="Icon"
        component={
          <GridLayoutTwoColumn
            leftCol={<div>left content</div>}
            rightCol={<div>right content</div>}
            widthDivision={[8, 4]}
          />
        }
      />

      <Row
        title="Dropdown"
        component={
          <>
            <UiDropdown
              title="Dropdown checkbox"
              variant={GENERAL_UI_COLOR.DANGER}
              size="lg"
              listItems={listDropdown}
            />
            <UiDropdown
              title="Dropdown checkbox"
              variant={GENERAL_UI_COLOR.PRIMARY}
              listItems={listDropdown}
            />
            <UiDropdown
              title="Dropdown checkbox"
              variant={GENERAL_UI_COLOR.WARNING}
              size="sm"
              listItems={listDropdown}
            />
            <UiDropdown
              title={
                <div className="d-inline-flex align-items-center">
                  <i className="ni ni-circle-08 me-2"></i>
                  Custom content
                </div>
              }
              variant="transparent"
              size="sm"
            >
              <Dropdown.Item>
                <i className="ni ni-user-run me-2"></i>
                Log out
              </Dropdown.Item>
            </UiDropdown>
          </>
        }
      />

      <Row title="Icon" component={<UiIcon name="wallet" />} />

      <Row
        title="Typography"
        component={
          <>
            <UiTypography
              fontSize="fs-3"
              fontWeight="fw-normal"
              className="test"
              lineHeight="lh-base"
              decoration="text-decoration-underline"
              handleClick={() => {}}
            >
              Typography
            </UiTypography>
            <UiTypography
              fontSize="fs-1"
              fontWeight="fw-bolder"
              className="test"
              lineHeight="lh-base"
              handleClick={() => {}}
            >
              Typography
            </UiTypography>
          </>
        }
      />
      <Row title="Avatar" component={<UiAvatar name="hung" />} />
      <Row title="Spinner" component={<UiSpinner />} />
      <Row
        title="ProgressBar"
        component={
          <UiProgressBar
            value={70}
            min={20}
            striped
            animated
            variant="success"
          />
        }
      />
      <Row
        title="Pagination"
        component={
          <UiPagination
            data={Array(22).fill(3)}
            onChange={() => {
              console.log("Set page pagination fetching action");
            }}
          />
        }
      />
      <Row
        title="List"
        component={<UiList list={Items} variant={GENERAL_UI_COLOR.DANGER} action />}
      />
      <Row
        title="ModalGeneral"
        component={
          <UiModalGeneral isStylingButton header="This is the title">
            <div>"Woo hooooo!, you in the modal"</div>
          </UiModalGeneral>
        }
      />
      <Row
        title="Badge"
        component={
          <div className="d-flex gap-2">
            <UiBadge content="Hello" size={GENERAL_UI_SIZE.SMALL} />
            <UiBadge content="Badge" />
          </div>
        }
      />
      <Row
        title="Alert"
        component={
          <UiAlert highlightMsg="What's up!" bgColor={GENERAL_UI_COLOR.WARNING} message="aloo" />
        }
      />
      <Row
        title="Card"
        component={<UiCard content="Hello" header="Supersaiyan" />}
      />
      <Row
        title="Card"
        component={
          <UiForm onSubmit={handleSubmit(onSubmit)}>
            <UiInput
              title={"Password"}
              name={"password"}
              type={"password"}
              isDisplayLabel={true}
              register={register}
              errorMessage={errors.password?.message}
            />
            <UiInput
              type="range"
              title={"Range"}
              name={"range"}
              isDisplayLabel={true}
              register={register}
              errorMessage={errors.range?.message}
            />
            <UiInput
              type="checkbox"
              title={"Checkbox"}
              name={"checkbox"}
              isDisplayLabel={true}
              register={register}
              errorMessage={errors.checkbox?.message}
            />
            <UiInput
              type="date"
              title={"Date"}
              name={"date"}
              isDisplayLabel={true}
              register={register}
              errorMessage={errors.date?.message}
            />
            <UiSwitch
              title={"Switch"}
              name="switch"
              isDisplayLabel={true}
              register={register}
              errorMessage={errors.switch?.message}
            />
            <UiButton title={"Sign in"} />
          </UiForm>
        }
      />
    </div>
  );
};

export default Samples;
