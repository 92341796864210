import styles from "./styles.module.scss";

interface IComponent {
  min?: number;
  max?: number;
  value: number;
}

const Component = ({ min = 0, max = 100, value }: IComponent) => {
  const percent = (value / max) * 100;
  const color =
    percent <= 20
      ? "bg-gradient-danger"
      : percent <= 60
      ? "bg-gradient-warning"
      : percent < 100
      ? "bg-gradient-success"
      : "bg-gradient-primary";

  return (
    <div className={styles.container}>
      <div className="progress-wrapper">
        <div className="progress">
          <div
            className={`progress-bar ${color}`}
            style={{ width: `${value < 10 ? 10 : value}%` }}
            role="progressbar"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Component;
