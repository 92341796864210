import UiInput from "../../components/ui-input";
import PanelGeneral from "../../components/panel-general/component";

import { formSchema } from "./formSchema";
import GroupCheckbox from "./view-group-radio";
import { detectRequiredlField } from "../../utils/validation";

const CreateAccountForm = ({
  setValue,
  register,
  control,
  errors,
}: {
  setValue: any;
  register: any;
  control: any;
  errors: any;
}) => {
  return (
    <PanelGeneral
      panel={{
        title: "",
      }}
      className="pt-3"
    >
      <UiInput
        className="dataTable-input"
        name={"name"}
        title={"Name"}
        required={detectRequiredlField(formSchema, "name")}
        isDisplayLabel={true}
        register={register}
        errorMessage={errors?.name?.message}
      />
      <UiInput
        className="dataTable-input"
        name={"username"}
        required={detectRequiredlField(formSchema, "username")}
        title={"Username"}
        isDisplayLabel={true}
        register={register}
        errorMessage={errors?.username?.message}
      />
      <UiInput
        className="dataTable-input"
        name={"password"}
        required={detectRequiredlField(formSchema, "password")}
        title={"Password"}
        isDisplayLabel={true}
        register={register}
        errorMessage={errors?.password?.message}
      />
      <UiInput
        className="dataTable-input"
        name={"expired_at"}
        required={detectRequiredlField(formSchema, "expired_at")}
        title={"Expried At"}
        isDisplayLabel={true}
        register={register}
        type="date"
        placeholder="Please input the date"
        errorMessage={errors?.expired_at?.message}
      />
      <GroupCheckbox
        control={control}
        title={"Status"}
        name="status"
        required={detectRequiredlField(formSchema, "status")}
        errorMessage={errors?.status?.message}
      />
    </PanelGeneral>
  );
};

export default CreateAccountForm;
