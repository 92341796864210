import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { apiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import {
  ICreateMaterialApi,
  createMaterialApi,
} from "../../apis/paths/material";
import { BOX_TYPE, IOption, SIZE } from "../material-detail/constants";
import alert from "../../utils/alert";
import SmallCollumnCenterGridLayout from "../../components/grid-layout-small-collumn-center";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import HeaderInfoPanel from "../../components/panel-header-info";
import GeneralPanel from "../../components/panel-general";
import UiButton from "../../components/ui-button";
import UiInput from "../../components/ui-input";
import UiSelectDropdown, {
  IOptionItem,
} from "../../components/ui-select-dropdown/component";
import ImageUploader from "../../components/ui-image-uploader/component";

import { schema } from "./schema";
import { MATERIAL_LIST } from "../../routings/path";

const Component = () => {
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const uploadImage: any = watch("upload_image");

  const onSubmit = async ({
    name,
    size,
    box_type,
    width,
    height,
    length,
  }: any) => {
    const waitUpdateMaterial = await apiCaller({
      api: createMaterialApi,
      params: {
        name,
        size,
        box_type,
        width,
        height,
        length,
        upload_image: uploadImage?.[0]?.file,
      } as ICreateMaterialApi,
    });

    if (waitUpdateMaterial.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Create successful!");
      setTimeout(() => {
        navigate(MATERIAL_LIST);
      }, 500);

      return;
    }

    alert.error("Create was not successful!. Please try again!");
  };

  const formatOptionItems = (data: IOption[]) =>
    data.map((option) => ({
      value: option.value,
      content: option.title,
    }));

  return (
    <>
      <HeaderInfoPanel
        title={"Create new material"}
        description="Input info below to create a new material"
        actionArea={() => (
          <>
            <UiButton
              onClick={handleSubmit(onSubmit)}
              className="me-2"
              title={"Create"}
            />
          </>
        )}
      />
      <SmallCollumnCenterGridLayout>
        <GeneralPanel panel={{ subTitle: "Material Information" }}>
          <TwoColumnGridLayout
            className={{ container: "px-0" }}
            leftCol={
              <>
                <UiInput
                  title={"Name"}
                  name={"name"}
                  required
                  register={register}
                  errorMessage={errors?.name?.message}
                />
                <UiSelectDropdown
                  name="size"
                  title="Size"
                  required
                  isDisplayLabel
                  register={register}
                  optionList={formatOptionItems(SIZE) as IOptionItem[]}
                  errorMessage={errors?.size?.message}
                />
                <UiSelectDropdown
                  name="box_type"
                  title="Box type"
                  required
                  isDisplayLabel
                  register={register}
                  optionList={formatOptionItems(BOX_TYPE) as IOptionItem[]}
                  errorMessage={errors?.box_type?.message}
                />
              </>
            }
            rightCol={
              <>
                <UiInput
                  title={"Height"}
                  name={"height"}
                  type="number"
                  required
                  register={register}
                  errorMessage={errors?.height?.message}
                />
                <UiInput
                  title={"Length"}
                  name={"length"}
                  type="number"
                  required
                  register={register}
                  errorMessage={errors?.length?.message}
                />
                <UiInput
                  title={"Width"}
                  name={"width"}
                  type="number"
                  required
                  register={register}
                  errorMessage={errors?.width?.message}
                />
                <ImageUploader
                  isDisplayLabel
                  title="Image Upload File"
                  isDisplayRemoveAll={false}
                  name="upload_image"
                  setValue={setValue}
                  errorMessage={errors?.upload_image?.message}
                />
              </>
            }
          />
        </GeneralPanel>
      </SmallCollumnCenterGridLayout>
    </>
  );
};

export default Component;
