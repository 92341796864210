import { TABLE_ACTION } from "../../constants/table-action";
import { GENERAL_UI_COLOR } from "../../constants/color";
import DateTimeTableCell from "../../components/table-cell-date-time";
import ProductTableCell from "../../components/table-cell-product";
import ActionTableCell from "../../components/table-cell-action";
import StatusTableCell from "../../components/table-cell-status";

interface ISchemaProps {
  onRestore?: any;
}

export const deletedMaterialSchema = ({ onRestore }: ISchemaProps) => [
  {
    name: "Name",
    cell: (data: any) => (
      <ProductTableCell
        title={data.name || "-"}
        linkTo={`${data?.uuid}`}
        description={data.code}
      />
    ),
  },
  {
    name: "Capacity",
    align: "center",
    cell: (data: any) => data.capacity,
  },
  {
    name: "WHL",
    align: "center",
    cell: (data: any) => `${data.width} x ${data.height} x ${data.length}`,
  },
  {
    name: "Storage Type",
    align: "center",
    cell: (data: any) => (
      <StatusTableCell status={data.size} type={GENERAL_UI_COLOR.SUCCESS} />
    ),
  },
  {
    name: "Box Type",
    align: "center",
    cell: (data: any) => (
      <StatusTableCell status={data.box_type} type={GENERAL_UI_COLOR.SUCCESS} />
    ),
  },
  {
    name: "Created",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data.created_at} />,
  },
  {
    name: "Updated",
    align: "center",
    sortTypes: {
      key: "updated_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data.updated_at} />,
  },
  {
    name: "Action",
    align: "right",
    width: 120,
    cell: (data: any) => {
      return (
        <ActionTableCell
          types={[TABLE_ACTION.RESTORE]}
          onRestore={() => onRestore(data.uuid)}
          popupContent="Are you sure to delete this product type ?"
        />
      );
    },
  },
];
