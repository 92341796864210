import * as R from "../../routings/path";
import { TABLE_ACTION } from "../../constants/table-action";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import ActionTableCell from "../../components/table-cell-action";
import UiButton from "../../components/ui-button/component";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { ACTIONABLE_TYPE } from "../../constants/actionable";

export const productSchema = ({
  onSelectProduct,
  selectedProducts,
  isEditable,
}: {
  onSelectProduct: any;
  selectedProducts: Array<any>;
  isEditable?: boolean;
}) => [
  {
    name: "Product",
    cell: (data: any) => (
      <ProductTableCell
        linkTo={`${R.PRODUCTS_LIST}/${data?.code}`}
        extraField={<StatusTableCell status={data?.status} />}
        image={data?.image_url}
        title={data?.name}
        maxWidth={200}
        imageSize={40}
      />
    ),
  },
  {
    name: "Capacity",
    sortTypes: {
      key: "capacity_value",
      value: ["asc", "desc"],
    },
    align: "right",
    cell: (data: any) => (
      <ProductTableCell
        title={data?.capacity_value || "-"}
        description={data?.capacity_unit}
      />
    ),
  },
  {
    name: "Action",
    align: "right",
    cell: (data: any) => {
      if (!isEditable) {
        return (
          <UiButton title="Select" disabled type={GENERAL_UI_COLOR.LIGHT} />
        );
      }

      const isExist = selectedProducts?.some(
        (productItem: any) => productItem?.product?.uuid === data?.uuid
      );

      if (isExist) return <div> Added</div>;

      return (
        <ActionTableCell
          types={[TABLE_ACTION.CUSTOM]}
          customActionableType={ACTIONABLE_TYPE.FULL}
          customTitle={"Select"}
          onCustomClick={() => onSelectProduct(data)}
        />
      );
    },
  },
];
