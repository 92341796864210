import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

export const initMomentLocale = () => {
  moment.updateLocale("en", {
    weekdays: "Sun_Mon_Tue_Wed_Thu_Fri_Sat".split("_"),
    longDateFormat: {
      LT: "HH:mm",
      LTS: "HH:mm:ss",
      L: "DD/MM/YYYY",
      LL: "D MMMM [year] YYYY",
      LLL: "D MMMM [year] YYYY HH:mm",
      LLLL: "dddd, D MMMM [year] YYYY HH:mm",
      l: "DD/M/YYYY",
      ll: "D MMM YYYY",
      lll: "DD/MM/YYYY [at] HH:mm",
      llll: "dddd, DD/MM/YYYY [at] HH:mm",
    },
    calendar: {
      nextDay: "[Tomorrow at] LT",
      nextWeek: "dddd [next week at] LT",
      lastDay: "[Yesterday at] LT",
      lastWeek: "dddd [at] LT",
      sameElse: "dddd [at] LT",
    },
    relativeTime: {
      s: "Few second",
      m: "One minute",
      h: "One hour",
      d: "One day",
      w: "One week",
      M: "One month",
      y: "One year",
    },
  });

  moment.locale("en");
};

/**
 * Format date time
 * @param {number} unixTime
 * @param {string} type
 * @returns {string}
 */
export const formatDateTime = (
  unixTime: number,
  type: string = "YYYY-MM-DD"
): string => {
  const now = moment();
  const day = moment.unix(unixTime);
  const dayDiff = now.diff(day, "day");

  if (!type || !type.length) {
    /* Format with meaningful content */
    /* Same day */
    if (dayDiff < 1) return day.fromNow();

    /* Same week */
    if (dayDiff < 8) return day.calendar();

    /* Format with DD/MM/YY HH:MM */
    return day.format("lll");
  }

  /* Format with string type */
  return day.format(type);
};

export const formatRemainingDuration = (timestamp: number): string => {
  momentDurationFormatSetup(moment as any); // FIXME: Optimize
  const unixSecondsNow = Math.floor(new Date().getTime() / 1000);
  let remainingSeconds = timestamp - unixSecondsNow;

  return remainingSeconds >= 0
    ? (moment as any).duration(remainingSeconds, "seconds").format("h:mm:ss")
    : "";
};

export const convertYYYYMMDDToUnixTime = (time: string): number => {
  const dateObject = new Date(time);
  const unixTimestamp = Math.floor(dateObject.getTime() / 1000);

  return unixTimestamp;
};

export const convertUnixTimeHHMM = (unixTime: number) => {
  const date = new Date(unixTime * 1000);
  const hours = date.getHours();
  const minutes = "0" + date.getMinutes();
  return hours + ":" + minutes.substr(-2);
};

export const convertUnixTimeToISO = (unixTime: number) => {
  const date = new Date(unixTime * 1000);
  const isoString = date.toISOString();
  return isoString.substr(0, 16); // Get the first 16 characters of the ISO string.
};

export const calculateRemainingTime = (
  endUnixTimestamp: number,
  startUnixTimeStamp = Math.floor(Date.now() / 1000),
  isShortVersion: boolean = false
) => {
  const remainingSeconds = endUnixTimestamp - startUnixTimeStamp;

  if (Number.isNaN(remainingSeconds)) {
    return "Unlimited";
  }

  if (remainingSeconds <= 0) {
    return "Expired";
  }

  const remainingDays = Math.floor(remainingSeconds / 86400); // 86400 seconds in a day
  const remainingHours = Math.floor((remainingSeconds % 86400) / 3600); // 3600 seconds in an hour
  const remainingMinutes = Math.floor((remainingSeconds % 3600) / 60); // 60 seconds in a minute

  if (isShortVersion) {
    if (remainingDays >= 1) {
      return `${remainingDays}d ${remainingHours}h ${remainingMinutes}m`;
    } else if (remainingHours >= 1) {
      return `${remainingHours}h ${remainingMinutes}m`;
    } else {
      return `${remainingMinutes}m`;
    }
  } else {
    if (remainingDays >= 1) {
      return `${remainingDays} day${
        remainingDays > 1 ? "s" : ""
      } ${remainingHours} hour${
        remainingHours > 1 ? "s" : ""
      } ${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""}`;
    } else if (remainingHours >= 1) {
      return `${remainingHours} hour${
        remainingHours > 1 ? "s" : ""
      } ${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""}`;
    } else {
      return `${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""}`;
    }
  }
};

export const getCurrentTime = () => {
  const now = new Date();
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const seconds = now.getSeconds().toString().padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
};

export const mapMonthStringToName = (
  monthString: string
): string | undefined => {
  const monthMap: { [key: string]: string } = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec",
  };

  return monthMap[monthString];
};

export const getUnixTime = (previousDayQty: number = 0) => {
  const now = Date.now();
  const milisecondsInADay = 24 * 60 * 60 * 1000;
  const requiredExpectingUnixTime = now - previousDayQty * milisecondsInADay;

  return Math.round(requiredExpectingUnixTime / 1000);
};

export const getFutureUnixTime = (hours: number = 0, minutes: number = 0) => {
  const now = Date.now();
  const milisecondsInAHour = 60 * 60 * 1000;
  const milisecondsInAMinute = 60 * 60 * 1000;
  const futureTime =
    now + hours * milisecondsInAHour + minutes * milisecondsInAMinute;

  return Math.round(futureTime / 1000);
};

export const getTheStartAndTheEndOfWeekBaseOnCurrentDay = () => {
  const currentDate = new Date();
  const currentDay = currentDate.getDay(); // Get the current day of the week (0-6, where 0 is Sunday)

  // Calculate the start date by subtracting the current day of the week
  const startDate = new Date(currentDate);
  startDate.setDate(currentDate.getDate() - currentDay);

  // Calculate the end date by adding the remaining days until Saturday (6)
  const endDate = new Date(currentDate);
  endDate.setDate(currentDate.getDate() + (6 - currentDay));

  return {
    startOfWeek: Math.floor(startDate.getTime() / 1000),
    endOfWeek: Math.floor(endDate.getTime() / 1000),
  };
};

export const getStartAndEndOfMonth = () => {
  const currentDate = new Date();

  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  return {
    startOfMonth: Math.floor(firstDayOfMonth.getTime() / 1000),
    endOfMonth: Math.floor(lastDayOfMonth.getTime() / 1000),
  };
};

export const formatDatetimeToLocaleString = (
  inputDatetimeStr: string
): string => {
  // Convert the input string to a Date object
  const inputDate: Date = new Date(inputDatetimeStr);

  // Format the date to the desired format
  const options: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const outputStr: string = inputDate.toLocaleString("en-US", options);

  return outputStr;
};
