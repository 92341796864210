import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { generateUrlParams } from "../../utils/url";
import { get, post, put, patch } from "../config";

interface IGetPurchaseOrderListApi {
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
  filter?: object | null;
}

export interface IPrintPackageApi {
  uuid: string;
  packageUuids: Array<string>;
}

export interface ICreatePurchaseOrder {
  name?: string;
  referenceNumber: string;
  warehouseUuid?: string;
  supplier?: string;
  note?: string;
  estimatedArrival?: number;
  channel?: string;
  status?: string;
  product_status?: string;
  products: Array<{
    product_uuid: string;
    product_reference_code: string;
    quantity: string;
  }>;
}

export const getPurchaseOrderListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
  filter = DEFAULT_PAGINATION_PARAMS.filter,
}: IGetPurchaseOrderListApi) =>
  get({
    path: `/wms/purchase_orders${generateUrlParams({
      page,
      per,
      keyword,
      sort,
      filter,
    })}`,
  });

export const getPurchaseOrderDetailApi = ({ uuid }: { uuid: string }) =>
  get({ path: `/wms/purchase_orders/${uuid}` });

export const getPurchaseOrderCountApi = () =>
  get({ path: `/wms/purchase_orders/count` });

export const createPurchaseOrder = ({
  name,
  referenceNumber,
  warehouseUuid,
  products,
  supplier,
  note,
  estimatedArrival,
  channel,
  status,
  product_status,
}: ICreatePurchaseOrder) =>
  post({
    path: `/wms/purchase_orders`,
    data: {
      name,
      reference_number: referenceNumber,
      warehouse_uuid: warehouseUuid,
      purchase_order_products: products,
      supplier,
      note,
      estimated_arrival: estimatedArrival,
      channel,
      status,
      product_status,
    },
  });

export interface IUpdatePurchaseOrderProducts {
  uuid: string;
  products: Array<{
    product_uuid: string;
    product_reference_code: string;
    quantity: string;
  }>;
}

export const updatePurchaseOrderProducts = ({
  uuid,
  products,
}: IUpdatePurchaseOrderProducts) =>
  put({
    path: `/wms/purchase_orders/${uuid}/products`,
    data: {
      purchase_order_products: products,
    },
  });

export interface ICreatePackageForPurchaseOrderApi {
  uuid: string;
  packages: Array<{
    package_products?: Array<{
      product_uuid: string;
      quantity: number;
    }>;
    height?: number;
    width?: number;
    length?: number;
    weight?: number;
  }>;
}

export const createPackageForPurchaseOrderApi = ({
  uuid,
  packages,
}: ICreatePackageForPurchaseOrderApi) =>
  post({
    path: `/wms/purchase_orders/${uuid}/packages`,
    data: {
      packages,
    },
  });

export interface IUpdatePurchaseOrderApi {
  uuid: string;
  warehouseUuid?: string;
  note?: string;
  name?: string;
  channel?: string;
  supplier?: string;
  productStatus?: string;
  estimatedArrival?: string;
}

export const updatePurchaseOrderApi = ({
  uuid,
  name,
  warehouseUuid,
  note,
  channel,
  supplier,
  productStatus,
  estimatedArrival,
}: IUpdatePurchaseOrderApi) =>
  put({
    path: `/wms/purchase_orders/${uuid}`,
    data: {
      uuid,
      name,
      warehouse_uuid: warehouseUuid,
      note,
      channel,
      supplier,
      product_status: productStatus,
      estimated_arrival: estimatedArrival,
    },
  });

export const updatePurchaseOrderStatusApi = ({
  uuid,
  action,
  note,
}: {
  uuid: string;
  action: string;
  note?: string;
}) =>
  patch({
    path: `/wms/purchase_orders/${uuid}/status`,
    data: {
      uuid,
      action,
      note,
    },
  });

export const updatePurchaseOrderPackageStatusApi = ({
  uuid,
  packageUuid,
  action,
}: {
  uuid: string;
  packageUuid: string;
  action: string;
}) =>
  patch({
    path: `/wms/purchase_orders/${uuid}/packages/${packageUuid}/status`,
    data: {
      uuid,
      package_uuid: packageUuid,
      action,
    },
  });

export const printPackageApi = ({ uuid, packageUuids }: IPrintPackageApi) =>
  post({
    path: `/wms/purchase_orders/${uuid}/packages/print`,
    data: {
      uuid,
      package_uuids: packageUuids,
    },
  });

export const importPurchaseOrderCsv = ({ data }: { data: any }) =>
  post({
    path: `/wms/purchase_orders/import`,
    data,
    isFormData: true,
  });
