import { getWarehouseListApi } from "../../../apis/paths/warehouse";
import { toWarehouseSchema } from "../schema/warehouseSchema";

import styles from "../styles.module.scss";
import TablePanel from "../../../components/panel-table";
import TwoColumnGridLayout from "../../../components/grid-layout-two-column";
import SelectedWarehouse from "../view-selected-warehouse";

interface IViewStep2 {
  onSelectWarehouse: (params: any) => void;
  selectedToWarehouse: any;
  selectedFromWarehouse: any;
}

const ViewStep2 = ({
  onSelectWarehouse,
  selectedToWarehouse,
  selectedFromWarehouse,
}: IViewStep2) => {
  return (
    <TwoColumnGridLayout
      widthDivision={[6, 6]}
      leftCol={
        <TablePanel
          apiPath={getWarehouseListApi}
          panelClassName={"mt-0"}
          type={"minimal"}
          tableSchema={toWarehouseSchema({
            selectedFromWarehouse: selectedFromWarehouse?.uuid,
            selectedWarehouse: selectedToWarehouse,
            onSelectWarehouse,
          })}
        />
      }
      rightCol={
        <div>
          <div className={styles.selectWarehouse}>
            <SelectedWarehouse warehouse={selectedToWarehouse} />
          </div>
        </div>
      }
    />
  );
};

export default ViewStep2;
