import * as R from "../../routings/path";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import Typography from "../../components/ui-typography/component";

export const productSchema = [
  {
    name: "Product",
    cell: (data: any) => (
      <ProductTableCell
        linkTo={`${R.PRODUCTS_LIST}/${data?.uuid}`}
        image={data?.image_url}
        title={data?.name}
        description={data?.gtin_code}
        isCopyAble={{ description: true }}
        imageSize={40}
        maxWidth={130}
      />
    ),
  },
  {
    name: "Available / Total Qty",
    align: "center",
    textSize: "xs",
    cell: (data: any) => {
      const totalQty = data?.quantity;
      const unavailableQty =
        data?.quantity_details?.find(
          (item: any) => item?.status === "unavailable"
        )?.quantity || 0;
      const availableQty = totalQty - unavailableQty;

      return (
        <div className="d-flex w-100 justify-content-center align-items-center">
          <Typography className="" fontWeight="fw-bold" fontSize="fs-5">
            {availableQty}
          </Typography>
          /
          <Typography className="" fontWeight="fw-bold" fontSize="fs-5">
            {totalQty}
          </Typography>
        </div>
      );
    },
  },
  {
    name: "Code",
    align: "center",
    textSize: "xs",
    cell: (data: any) => data?.code,
  },
  {
    name: "Status",
    cell: (data: any) => <StatusTableCell status={data?.status} />,
  },
];
