import React from "react";
import GridLayoutTwoColumn from "../../components/grid-layout-two-column/component";
import { Outlet } from "react-router-dom";
import SystemSidebar from "./view-system-sidebar";
import { ISidebarContent } from "./constants";

interface IRoutingNavigationLayout {
  sidebarContent: ISidebarContent[];
  widthDivision?: [number, number];
}
const RoutingNavigationLayout = ({
  sidebarContent,
  widthDivision = [3, 9],
}: IRoutingNavigationLayout) => {
  return (
    <GridLayoutTwoColumn
      widthDivision={widthDivision}
      leftCol={<SystemSidebar sidebarContent={sidebarContent} />}
      rightCol={<Outlet />}
    />
  );
};

export default RoutingNavigationLayout;
