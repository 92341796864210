import { useCallback } from "react";
import { shallowEqual } from "react-redux";

import * as actions from "../states/sidebar.flow";

import { useAppDispatch, useAppSelector } from "./useStateHook";

const useHandleSidebarStatus = () => {
  const dispatch = useAppDispatch();

  const sidebarStatus = useAppSelector(
    (state) => state.sidebar,
    shallowEqual
  );

  const dispatchSidebarStatus = useCallback(
    (data: any) => dispatch(actions.updateSidebarStatusAction(data)),
    [dispatch]
  );

  return {
    sidebarStatus,
    dispatchSidebarStatus,
  };
};

export default useHandleSidebarStatus;
