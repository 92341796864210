import * as R from "../../routings/path";
import { TABLE_ACTION } from "../../constants/table-action";
import DateTimeTableCell from "../../components/table-cell-date-time";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import ActionTableCell from "../../components/table-cell-action";
import Typography from "../../components/ui-typography/component";

export const schema = [
  {
    name: "Location",
    cell: (data: any) => (
      <ProductTableCell
        title={`#${data?.location?.code}`}
        linkTo={`${R.DISPATCH_LIST}/${data?.uuid}`}
        maxWidth={300}
      />
    ),
  },
  {
    name: "Warehouse",
    cell: (data: any) => (
      <ProductTableCell
        title={`${data?.warehouse?.name} - ${data?.warehouse?.storage_type}`}
        description={`${data?.warehouse?.contact_name} - ${data?.warehouse?.contact_phone}`}
        subIcon={"fa-phone"}
        linkTo={`tel:${data?.warehouse?.contact_phone}`}
      />
    ),
  },
  {
    name: "Sale Orders",
    align: "center",
    cell: (data: any) => (
      <Typography
        customFontsize={16}
        fontWeight="fw-bold"
        className="text-center"
      >
        {data?.sale_orders?.length}
      </Typography>
    ),
  },
  {
    name: "Created",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data?.created_at} />,
  },
  {
    name: "Action",
    align: "right",
    cell: (data: any) => (
      <ActionTableCell
        viewLink={`${R.DISPATCH_LIST}/${data?.uuid}`}
        types={[TABLE_ACTION.VIEW]}
      />
    ),
  },
];
