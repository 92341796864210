import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import TableCellStatus from "../../components/table-cell-status";
import HeaderInfoPanel from "../../components/panel-header-info";
import MultiStepPanel from "../../components/panel-multi-step";
import UiLoadingPanel from "../../components/ui-loading-panel";
import TableDataPanel from "../../components/panel-table-data";
import TablePanel from "../../components/panel-table";
import NotFoundPanel from "../../components/panel-not-found";
import UiContentRow from "../../components/ui-content-row";
import GeneralPanel from "../../components/panel-general";
import QuickActionPopupConfirmation from "../../components/popup-quick-aciton-confirmation/component";
import GridLayoutTwoColumn from "../../components/grid-layout-two-column/component";
import UiButton from "../../components/ui-button";
import Avatar from "../../components/ui-avatar";

import { API_RESPONSE_STATUS } from "../../constants/api";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { formatDateTime } from "../../utils/date-time";

import {
  getStockCheckDetailApi,
  processCheckingStockApi,
  printStockCheckReportApi,
  getStockCheckProductCountApi,
  getStockCheckProductListApi,
  getStockCheckUnMatchedProductListApi,
  getStockCheckLocationListApi,
} from "../../apis/paths/stock-check";
import { apiCaller, useApiCaller } from "../../apis/config";
import styles from "./styles.module.scss";
import { unmatchedProductSchema, productSchema, locationSchema } from "./schema";
import ContentSidebar from "./view-content-sidebar";
import { generateFilterParamsWithQtyCount } from "../../utils/format";
import ViewStockCheckProductDoughnutChart from "./view-stock-check-product-doughnut-chart";

const tabSelectedGeneration = (
  created: number,
  started: number,
  ended: number
) => {
  if (!!ended) return 2;
  if (!!started) return 1;
  return 0;
};

const tabsHeadingGeneration = (
  created: number,
  started: number,
  ended: number
) => {
  const createdAt = (
    <>
      <div>Created at </div>
      <span>{formatDateTime(created, "HH:mm DD/MM/YY")}</span>
    </>
  );
  const startedAt = started ? (
    <>
      <div>Started at</div>
      <span>{formatDateTime(created, "HH:mm DD/MM/YY")}</span>
    </>
  ) : (
    "Not Start"
  );
  const endedAt = ended ? (
    <>
      <div>Ended at</div>
      <span>{formatDateTime(ended, "HH:mm DD/MM/YY")}</span>
    </>
  ) : (
    "Not End"
  );

  return [createdAt, startedAt, endedAt];
};

const navigation = ({
  product,
  unMatchedProduct,
  location,
}: {
  product: number;
  unMatchedProduct: number;
  location: number;
}) => [
    {
      content: "Info",
      icon: <i className="fa-solid fa-list-check text-dark opacity-4 me-2"></i>,
    },
    {
      content: "Product",
      badgeNumber: product,
      isDisplayBadgeNumber: true,
      icon: <i className="fa-solid fa-barcode text-dark opacity-4 me-2"></i>,
    },
    {
      content: "Unmatched Product",
      badgeNumber: unMatchedProduct,
      isDisplayBadgeNumber: true,
      icon: <i className="fa-solid fa-barcode text-dark opacity-4 me-2"></i>,
    },
    {
      content: "Location",
      badgeNumber: location,
      isDisplayBadgeNumber: true,
      icon: (
        <i className="fa-solid fa-boxes-packing text-dark opacity-4 me-2"></i>
      ),
    },
  ];

const Component = () => {
  const [stockCheckDetail, setStockCheckDetail] = useState<any>(null);
  const [unMatchedProductList, setUnMatchedProduuctList] = useState<any>([]);
  const [locationtList, setLocationList] = useState<any>([]);
  const [tabSelected, setTabSelected] = useState(0);
  const [tabHeading, setTabHeading] = useState<any>([null, null, null]);
  const [selectedNavigation, setSelectedNavigation] = useState(0);
  const [isShowEndModal, setDisplayEndModal] = useState(false);
  const [isShowCancelModal, setDisplayCancelModal] = useState(false);

  const { stockCheckId: uuid } = useParams();
  const [params] = useState({ uuid });

  const { result, loading } = useApiCaller({
    api: getStockCheckDetailApi,
    params: params,
  });

  const { result: productCountResult } = useApiCaller({
    api: getStockCheckProductCountApi,
    params: params,
  });

  const STOCK_CHECK_PRODUCT_OPTIONS = [
    {
      field: "status",
      operator: "in",
      options: generateFilterParamsWithQtyCount(productCountResult?.data),
    },
  ];


  useEffect(() => {
    if (result?.status === API_RESPONSE_STATUS.SUCCESS) {
      setStockCheckDetail(result?.data);

      setTabSelected(
        tabSelectedGeneration(
          result.data?.created_at,
          result.data?.started_at,
          result.data?.ended_at
        )
      );
      setTabHeading(
        tabsHeadingGeneration(
          result.data?.created_at,
          result.data?.started_at,
          result.data?.ended_at
        )
      );
    }
  }, [result]);

  const { result: unMatchedProductResult } = useApiCaller({
    api: getStockCheckUnMatchedProductListApi,
    params: params,
  });

  useEffect(() => {
    if (unMatchedProductResult?.status === API_RESPONSE_STATUS.SUCCESS) {
      setUnMatchedProduuctList(unMatchedProductResult?.data?.list);
    }
  }, [unMatchedProductResult]);

  const { result: locationResult } = useApiCaller({
    api: getStockCheckLocationListApi,
    params: params,
  });

  useEffect(() => {
    if (locationResult?.status === API_RESPONSE_STATUS.SUCCESS) {
      setLocationList(locationResult?.data?.list);
    }
  }, [locationResult]);

  if (loading) return <UiLoadingPanel />;

  if (result?.status === API_RESPONSE_STATUS.ERROR) return <NotFoundPanel />;

  const handleProcessChecking = async (type: "start" | "end" | "cancel") => {
    const waitProcess = await apiCaller({
      api: processCheckingStockApi,
      params: { uuid, type },
    });

    if (waitProcess?.status === API_RESPONSE_STATUS.SUCCESS) {
      const status = waitProcess?.data?.status;
      setStockCheckDetail({
        ...stockCheckDetail,
        status,
      });
      setTabSelected(
        tabSelectedGeneration(
          waitProcess?.data?.created_at,
          waitProcess?.data?.started_at,
          waitProcess?.data?.ended_at
        )
      );
      setTabHeading(
        tabsHeadingGeneration(
          waitProcess?.data?.created_at,
          waitProcess?.data?.started_at,
          waitProcess?.data?.ended_at
        )
      );
    }
  };

  const handlePrint = async () => {
    const waitPrint = await apiCaller({
      api: printStockCheckReportApi,
      params: { stockCheckUuid: params.uuid },
    });

    if (waitPrint?.status === API_RESPONSE_STATUS.SUCCESS) {
      window.open(waitPrint?.data?.url);
    }
  };

  const handleDoughNutClick = (elements: Array<Record<string, number>>) => {
    // index indicate which section of the doughnut chart is clicked
    const currentClickItemIndex = elements?.[0]?.index;

    if (currentClickItemIndex === 0) {
      setSelectedNavigation(1);
      // TODO: filter for checked product
    }
    else if (currentClickItemIndex === 1) {
      setSelectedNavigation(1);
      // TODO: filter for checking product
    }
    else if (currentClickItemIndex === 2) {
      setSelectedNavigation(1);
      // TODO: filter for rechecking product
    }
    else if (currentClickItemIndex === 3) {
      setSelectedNavigation(1);
      // TODO: filter for unchecked product
    }
  }


  return (
    <>
      <HeaderInfoPanel
        title={`#${stockCheckDetail?.code}`}
        className="mb-4"
        infoArea={() => (
          <TableCellStatus
            className="me-2"
            status={stockCheckDetail?.status}
            size={GENERAL_UI_SIZE.SMALL}
          />
        )}
        actionArea={() => (
          <div className={styles.headingAction}>
            <UiButton onClick={handlePrint} title={"Print Report"} />
            {stockCheckDetail?.status === "started" && (
              <UiButton
                type={GENERAL_UI_COLOR.DANGER}
                onClick={() => setDisplayCancelModal(true)}
                title={"Cancel"}
              />
            )}
            {stockCheckDetail?.status === "started" && (
              <UiButton
                onClick={() => setDisplayEndModal(true)}
                title={"End"}
              />
            )}
            {stockCheckDetail?.status === "created" && (
              <UiButton
                onClick={() => handleProcessChecking("start")}
                title={"Start"}
              />
            )}
          </div>
        )}
      />
      <MultiStepPanel
        isDisabled
        isWithoutContent
        defaultTab={tabSelected}
        contentCol={12}
        contentHeadings={[]}
        tabs={tabHeading}
      >
        <></>
      </MultiStepPanel>
      <GridLayoutTwoColumn
        widthDivision={[3, 9]}
        leftCol={
          <ContentSidebar
            tabs={navigation({
              product: stockCheckDetail?.product_count || 0,
              unMatchedProduct: stockCheckDetail?.unmatched_product_count || 0,
              location: stockCheckDetail?.location_count || 0,
            })}
            selectedTab={selectedNavigation}
            onChange={(selected: number) => setSelectedNavigation(selected)}
          />
        }
        rightCol={
          <>
      <ViewStockCheckProductDoughnutChart productCountByStatusResult={productCountResult?.data} onClick={handleDoughNutClick} />
            <GeneralPanel
              className="pt-4"
              containerClassName={`${styles.tabPanel} ${selectedNavigation === 0 && styles.isDisplay}`}
            >
              <UiContentRow label="Name">{stockCheckDetail?.name}</UiContentRow>
              <UiContentRow label="Code">{stockCheckDetail?.code}</UiContentRow>
              <UiContentRow label="Group Code">
                {stockCheckDetail?.group_code}
              </UiContentRow>
              <UiContentRow label="Checkers">
                <>
                  {stockCheckDetail?.checkers?.map((checker: any) => (
                    <div className={styles.checkerItem}>
                      <Avatar
                        className={styles.checkerAvatar}
                        imageSrc={checker.avatar}
                        name={checker.name}
                        variant={GENERAL_UI_COLOR.SECONDARY}
                      />
                      {checker.name}
                    </div>
                  ))}
                </>
              </UiContentRow>
            </GeneralPanel>
            <div className={`${styles.tabPanel} ${selectedNavigation === 1 && styles.isDisplay}`} >
              <TablePanel
                apiPath={getStockCheckProductListApi}
                tableSchema={productSchema}
                filter={{
                  optionList: STOCK_CHECK_PRODUCT_OPTIONS,
                  showOverlay: true,
                  pageCode: "stock-check-product-list",
                }}
                panel={{
                  title: "Stock Check Product List",
                  description: "List of product in the stock check session",
                }}
                apiParams={{ uuid }}
              />
            </div>
            <div className={`${styles.tabPanel} ${selectedNavigation === 2 && styles.isDisplay}`} >
              <TableDataPanel
                data={unMatchedProductList}
                schema={unmatchedProductSchema}
              />
            </div>
            <div className={`${styles.tabPanel} ${selectedNavigation === 3 && styles.isDisplay}`} >
              <TableDataPanel data={locationtList} schema={locationSchema} />
            </div>
          </>
        }
      />
      <QuickActionPopupConfirmation
        isShowModal={isShowEndModal}
        confirmationButton={"Confirm"}
        content={"Confirm ending for this stock checking?"}
        handleCloseModal={() => setDisplayEndModal(false)}
        onConfirm={() => handleProcessChecking("end")}
      />
      <QuickActionPopupConfirmation
        isShowModal={isShowCancelModal}
        confirmationButton={"Confirm"}
        content={"Confirm cancel for this stock checking?"}
        handleCloseModal={() => setDisplayCancelModal(false)}
        onConfirm={() => handleProcessChecking("cancel")}
      />
    </>
  );
};

export default Component;
