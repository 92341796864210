import * as yup from "yup";
import { GENERAL_STATUS } from "../../constants/status";

export const formSchema = yup
  .object({
    name: yup.string().required(),
    service: yup.string().required("This field is required"),
    priority: yup.number().integer().required('This fiels is required'),
    status: yup
      .string()
      .oneOf([GENERAL_STATUS.ACTIVATE, GENERAL_STATUS.DEACTIVE])
      .required(),
  })
  .required();
