export const LOCATIONS = [
  { content: "Channel", value: "Channel" },
  { content: "Receiver", value: "Receiver" },
  { content: "Warehouse", value: "Warehouse" },
  { content: "Product", value: "Product" },
  { content: "Province", value: "Province" },
  { content: "District", value: "District" },
];

export const LINK_TYPES = ["Channel", "Receiver"];

export const LINK_OBJECT_TYPES = [
  "Warehouse",
  "Product",
  "Province",
  "District",
];

export const OPTIONS_LENGTH = 100;