import { IFilterField, SelectedOptions } from "./component";
import styles from "./styles.module.scss";

interface INormalFilter {
  onToggleOption: any;
  filterField: IFilterField;
  selectedOptions: SelectedOptions;
}

const NormalFilter = ({
  onToggleOption,
  filterField,
  selectedOptions,
}: INormalFilter) => {
  if (!filterField?.options) {
    return null;
  }

  const { isSingleFilter } = filterField;

  const template = filterField?.template || "normal";

  const bigNumberButton = (option: any) => {
    return (
      <div className={styles.bigNumberButton}>
        <span className={styles.content}>{option?.content}</span>
        {option?.quantity && (<span className={styles.filterQuantity}>{option?.quantity}</span>)}
      </div>
    )
  }

  const badgeNumberButton = (option: any) => {
    return (
      <div className={styles.badgeNumberButton}>
        <span className={styles.content}>{option?.content}</span>
        {option?.quantity && (<span className={styles.filterQuantity}>{option?.quantity}</span>)}
      </div>
    )
  }

  return (
    <>
      {filterField?.options?.map((option) => (
        <div
          key={option?.value}
          className={`d-flex align-items-center justify-content-start ${
            styles.normalFilterItem
          } ${
            (selectedOptions[filterField?.field]?.values?.includes(
              option?.value
            ) ||
              selectedOptions[filterField?.field]?.value === option?.value) &&
            styles.selected
          }`}
          onClick={() => {
            onToggleOption({
              field: filterField?.field,
              value: option?.value,
              operatorMatcher: filterField?.operator as string,
              isSingleFilter,
            });
          }}
        >
          {template === "bigNumber" ? bigNumberButton(option) : badgeNumberButton(option)}
        </div>
      ))}
    </>
  );
};

export default NormalFilter;
