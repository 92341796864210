import React, { useEffect, useState } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

interface ISwitchProps {
  isEditable?: boolean;
  title?: string;
  isDisplayLabel?: boolean;
  name?: string | number;
  id?: string;
  readOnly?: boolean;
  className?: string;
  register?: any;
  errorMessage?: string;
  onChange?: (param: any) => any;
  checked?: boolean;
  onSetValue?: any;
  isFallBackPrevious?: boolean;
  disabled?: boolean;
}

const Switch = ({
  isEditable = false,
  title,
  isDisplayLabel = true,
  checked = false,
  name = "",
  id = "",
  readOnly = false,
  className = "",
  register = null,
  errorMessage = "",
  onChange = () => {},
  onSetValue,
  isFallBackPrevious,
  disabled = false,
  ...rest
}: ISwitchProps) => {
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => setIsChecked(checked), [checked]);

  let registerData = {};
  if (register) registerData = register(name);

  const toggleCheck = () => {
    if (disabled) return;

    setIsChecked(!isChecked);
    onChange(!isChecked);
    onSetValue && onSetValue(name, !isChecked);
  };

  useEffect(() => {
    if (isFallBackPrevious) setIsChecked(!isChecked);
  }, [isFallBackPrevious]);

  return isEditable ? (
    <>
      {isDisplayLabel && <label>{title || name}</label>}
      <div className={`d-flex ${className} ${disabled && styles.disabled}`}>
        <div className={classNames("form-check form-switch")}>
          <input
            readOnly={readOnly}
            id={id}
            style={{ height: `calc(1rem + 4px)` }}
            autoComplete="off"
            {...registerData}
            className={classNames("form-check-input")}
            type="checkbox"
            checked={isChecked}
            onChange={toggleCheck}
          />
        </div>
      </div>
      {errorMessage && errorMessage.length && (
        <label className="mb-0" style={{ color: "#f5365c" }}>
          {errorMessage}
        </label>
      )}
    </>
  ) : (
    <>
      {isDisplayLabel && <label>{title || name}</label>}
      <div
        className={`d-flex ${className} ${
          disabled && styles.disabled
        } user-select-none pe-none`}
      >
        <div className={classNames("form-check form-switch ")}>
          <input
            aria-label={title}
            readOnly
            id={id}
            style={{ height: `calc(1rem + 4px)` }}
            autoComplete="off"
            className={classNames("form-check-input")}
            type="checkbox"
            checked={isChecked}
            onChange={toggleCheck}
          />
        </div>
      </div>
    </>
  );
};

export default Switch;
