import { useState } from "react";
import classNames from "classnames";

import Typography from "../ui-typography/component";

import styles from "./styles.module.scss";

interface IUiCopyBadge {
  title: string;
  tooltipContent?: React.ReactNode | string;
  urlForCopy: string;
  onTitleClick?: () => void;
  className?: {
    container?: string;
    title?: string;
  };
}

const UiCopyBadge = ({
  title,
  tooltipContent = "Copied",
  onTitleClick = () => {},
  urlForCopy,
  className = {
    container: "",
    title: "",
  },
}: IUiCopyBadge) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyTextToClipboard = async (text: string) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const handleCopyClick = () => {
    copyTextToClipboard(urlForCopy)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      className={classNames(
        "d-flex bg-gradient-warning text-white align-items-center gap-3 px-3 rounded-1 py-1",
        className.container
      )}
    >
      <div
        className={classNames("cursor-pointer")}
        onClick={() => {
          onTitleClick?.();
        }}
      >
        <Typography
          fontWeight="fw-bold"
          customFontsize={13}
          className={classNames(className.title)}
        >
          {title}
        </Typography>
      </div>
      <div className={classNames(styles.copyButton, "px-2")}>
        <i className={classNames("fa-regular fa-copy", isCopied && "text-secondary")} onClick={handleCopyClick}></i>
        {isCopied && (
          <div className={classNames(styles.floatingContent)}>
            <div className={classNames(styles.subFloatingContent)}>
              {tooltipContent}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UiCopyBadge;
