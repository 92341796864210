import React from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

const ContentSidebar = ({
  selectedTab = 0,
  onChange,
  tabs,
}: {
  selectedTab?: number;
  tabs: any[];
  onChange: any;
}) => {
  return (
    <div className="card">
      <div className="card">
        <ul className="nav flex-column bg-white border-radius-lg p-3">
          {tabs.map((item: any, index: number) => {
            return (
              <li className="nav-item mb-1" key={index}>
                <div
                  onClick={() => onChange(index)}
                  className={classNames(
                    "nav-link text-body d-flex align-items-center px-0",
                    styles.tabItem,
                    selectedTab === index && styles.activeTab
                  )}
                >
                  <div className="p-1 w-20 d-flex justify-content-center align-items-center">
                    {item.icon}
                  </div>
                  <span className="text-sm w-100">{item.content}</span>
                  {item.isDisplayBadgeNumber && (
                    <div
                      className={`${styles.badgeNumber} ${
                        item.badgeNumber === 0 && styles.disabled
                      }`}
                    >
                      {item.badgeNumber}
                    </div>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ContentSidebar;
