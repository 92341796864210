import * as R from "../../../routings/path";
import StatusTableCell from "../../../components/table-cell-status";
import ProductTableCell from "../../../components/table-cell-product";

export const accountSchema = [
  {
    name: "Name",
    cell: (data: any) => (
      <ProductTableCell
        title={data?.name}
        linkTo={`${R.ACCOUNT_LIST}/${data?.uuid}`}
        maxWidth={200}
        image={data?.avatar}
        isForceDisplayAvatar
        userName={data?.name}
        allowDynamicAvatarBackgroundColor
        displayStatusMode={{
          workMode: data?.work_mode,
          outsideUntil: data?.outside_until,
        }}
      />
    ),
  },
  {
    name: "Status",
    align: "center",
    width: 120,
    cell: (data: any) => {
      return <StatusTableCell status={data?.status} />;
    },
  },
];
