import React, { useState } from "react";
import { nanoid } from "nanoid";
import classNames from "classnames";

import { apiCaller } from "../../apis/config";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { toogleShipingServiceStatusApi } from "../../apis/paths/shipping";
import alert from "../../utils/alert";
import Switch from "../../components/ui-switch/component";
import Badge from "../../components/ui-badge/component";

import styles from "./styles.module.scss";
import { IShippingService } from "./types";
import RequiredKeyItem from "./view-required-key-item";

interface IStatusState {
  [key: string]: boolean;
}
const ServiceItem = ({
  service,
  services,
  defaultShippingService,
  setPageReloadVersion,
}: {
  service: IShippingService;
  services: any[];
  defaultShippingService: any;
  setPageReloadVersion: any;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFallBackPreviousValue, setIsFallBackPreviousValue] = useState(false);

  const { code, display_name, logo_url, required_keys } = service || {};

  const toggleExpandInfo = () => {
    setIsExpanded((prev) => !prev);
  };

  const resultObj: IStatusState = {};
  services?.forEach(({ code, enabled }: { code: string; enabled: boolean }) => {
    resultObj[code] = enabled;
  });

  const [shippingServiceStatus, setshippingServiceStatus] =
    useState<IStatusState>(resultObj);

  const handleChangeStatus = async (isChecked: boolean) => {
    setIsFallBackPreviousValue(false);
    const waitToggle = await apiCaller({
      api: toogleShipingServiceStatusApi,
      params: { enabled: isChecked, code },
    });

    if (waitToggle.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update status successful!");

      setshippingServiceStatus((prevState) => ({
        ...prevState,
        [code as string]: !prevState[code as string],
      }));

      setPageReloadVersion((prev: number) => prev + 1);
      return;
    }

    setIsFallBackPreviousValue(true);
    alert.error("Change status was not successful!. Please try again");
  };

  return (
    <>
      <div className="d-flex">
        <img className="width-48-px" src={logo_url} alt={`logo-${code}`} />
        <div className="my-auto ms-3">
          <div className="h-100 cursor-pointer" onClick={toggleExpandInfo}>
            <h5 className="mb-0">{display_name}</h5>
            <div className="text-sm text-body mt-1 ">
              {isExpanded ? "Show less" : "Show more"}
              <i
                className={classNames(
                  "fas fa-chevron-up text-xs ms-1",
                  !isExpanded && styles.rotateIcon
                )}
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>

        <p className="text-sm text-secondary ms-auto me-3 my-auto">
          {defaultShippingService === code && (
            <Badge
              content={"Default"}
              type={GENERAL_UI_COLOR.INFO}
              className="me-3 align-self-center flex-1"
            />
          )}
          Enabled
        </p>
        <Switch
          isDisplayLabel={false}
          isFallBackPrevious={isFallBackPreviousValue}
          checked={shippingServiceStatus[code as string]}
          isEditable
          onChange={handleChangeStatus}
        />
      </div>

      <div
        className={classNames(
          "ps-5 pt-3 ms-3",
          styles.collapseItems,
          isExpanded && styles.active
        )}
      >
        <p className="mb-0 text-sm mb-4">Please add your API token</p>
        {required_keys?.map((requiredKey) => {
          const uniqeKey = nanoid(10);
          return (
            <RequiredKeyItem
              key={uniqeKey}
              requiredKey={requiredKey}
              code={code as string}
            />
          );
        })}
      </div>
      <hr className="horizontal dark mt-4" />
    </>
  );
};

export default ServiceItem;
