import * as R from "../../../routings/path";
import StatusTableCell from "../../../components/table-cell-status";
import ProductTableCell from "../../../components/table-cell-product";
import DateTimeTableCell from "../../../components/table-cell-date-time";

export const purchaseOrderSchema = [
  {
    name: "PO ID",
    cell: (data: any) => (
      <ProductTableCell
        title={`${data?.name}`}
        description={`#${data?.number}`}
        linkTo={`${R.PURCHASE_ORDERS_LIST}/${data?.uuid}`}
        maxWidth={400}
      />
    ),
  },
  {
    name: "Created",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data?.created_at} />,
  },

  {
    name: "Status",
    align: "right",
    cell: (data: any) => <StatusTableCell status={data?.status} />,
  },
];
