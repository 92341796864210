import UiInput from "../../components/ui-input";
import PanelGeneral from "../../components/panel-general/component";

import { formSchema } from "./formSchema";
import { detectRequiredlField } from "../../utils/validation";
import { IProductType } from "../../types/product";
import SelectDropdown from "../../components/ui-select-dropdown/component";
import { STRATEGY } from "../../constants/product";

const ProductTypeDetailForm = ({
  register,
  errors,
  data,
  disabled,
  setValue,
}: {
  register: any;
  control: any;
  errors: any;
  data: IProductType;
  dirtyFields?: any;
  setValue?: any;
  disabled?: boolean;
}) => {
  const { name, description } = data || {};

  return (
    <PanelGeneral
      panel={{
        title: "Edit product type",
      }}
    >
      <UiInput
        className="dataTable-input"
        name={"name"}
        title={"Name"}
        required={detectRequiredlField(formSchema, "name")}
        placeholder={name}
        isDisplayLabel={true}
        register={register}
        disabled={disabled}
        errorMessage={errors?.name?.message}
      />
      <UiInput
        className="dataTable-input"
        name={"description"}
        title={"Description"}
        required={detectRequiredlField(formSchema, "description")}
        placeholder={description}
        isDisplayLabel={true}
        register={register}
        disabled={disabled}
        errorMessage={errors?.description?.message}
      />
      <SelectDropdown
        name="strategy"
        className="w-100"
        title="Strategy"
        required
        disabled
        isDisplayLabel
        register={register}
        setValue={setValue}
        optionList={STRATEGY}
      />
    </PanelGeneral>
  );
};

export default ProductTypeDetailForm;
