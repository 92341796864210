import * as yup from "yup";
import { validationMessage } from "../../utils/validation";
import { FieldSchema, fieldTitle } from "./masterWHschema";

export const subWHschema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(fieldTitle[path as FieldSchema])
      ),
    contactName: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(fieldTitle[path as FieldSchema])
      ),
    contactPhone: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(fieldTitle[path as FieldSchema])
      ),
    valuable: yup.boolean(),
    parentWarehouseUuid: yup.string(),
    storageType: yup.string(),
    wardId: yup.string(),
    streetName: yup.string(),
    detail: yup.string(),
    latitude: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable(),
  longitude: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable(),
  })
  .required();
