import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";

import { getAssignees } from "../../../apis/paths/taskLogging";
import { GENERAL_UI_COLOR } from "../../../constants/color";
import { useDebounce } from "../../../hooks/useDebounce";
import { useApiCaller } from "../../../apis/config";
import { DEFAULT_PAGINATION_PARAMS } from "../../../constants/pagination";
import Overlay from "../../../components/ui-overlay/component";
import UiButton from "../../../components/ui-button/component";
import InputSearch from "../../../components/search-input/component";
import UiLoadingPanel from "../../../components/ui-loading-panel";
import ProductTableCell from "../../../components/table-cell-product/component";
import StatusTableCell from "../../../components/table-cell-status/component";

import styles from "./styles.module.scss";
import SelectedItem from "./view-selected-item";

const ViewAssignee = ({
  selectedAssignee,
  setSelectedAssignee,
}: {
  selectedAssignee: any;
  setSelectedAssignee: any;
}) => {
  const [isShowSearchBar, setIsShowSearchBar] = useState(false);

  const params = useParams();
  const taskId = params.taskId || "";

  const initialParams = {
    ...DEFAULT_PAGINATION_PARAMS,
    uuid: taskId,
  };

  const [taskLoggingSearchParams, setTaskLoggingSearchParams] = useState<{
    page: number;
    per: number;
    keyword: string;
    uuid: string;
  }>(initialParams);

  const handleSelectAssignee = (data: any) => {
    setSelectedAssignee(data);
  };

  const [searchValue, setSearchValue] = useState("");

  const debouncedValue = useDebounce(searchValue, 500);

  useEffect(() => {
    setTaskLoggingSearchParams((prev) => ({
      ...prev,
      keyword: debouncedValue,
    }));
  }, [debouncedValue]);

  const { result: selectionItemSearchResult, loading } = useApiCaller({
    api: getAssignees,
    params: taskLoggingSearchParams,
  });

  const { name, avatar, work_mode, outside_until } = selectedAssignee || {};

  return (
    <div>
      <div className="d-flex align-items-center w-100 gap-3">
        <div className={classNames(styles.selectItem, "flex-grow-1")}>
          <SelectedItem
            label="Selected Assignee"
            noItemContent={"No Assignee Selected"}
            itemName={
              !!selectedAssignee && (
                <div className="d-flex justify-content-start align-items-center">
                  <ProductTableCell
                    description={name}
                    image={avatar}
                    isForceDisplayAvatar
                    userName={name}
                    imageSize={40}
                    allowDynamicAvatarBackgroundColor
                    displayStatusMode={{
                      workMode: work_mode,
                      outsideUntil: outside_until,
                    }}
                    className={{ description: styles.description }}
                  />
                </div>
              )
            }
          />
        </div>
        <UiButton
          onClick={() => {
            setIsShowSearchBar(true);
          }}
          className="mt-3"
          title={"Change Assignee"}
        />
      </div>

      {isShowSearchBar && (
        <div style={{ minHeight: 300 }} className="d-flex  gap-3">
          <div className="flex-grow-1">
            <Overlay
              content={
                <InputSearch
                  value={searchValue}
                  setValue={setSearchValue}
                  className={{ container: "p-0 " }}
                  placeholder={
                    "Type keyword/code to search the related object (SO, PO Pick List, Putaway List, Location List)"
                  }
                  style={{ backgroundColor: "white" }}
                />
              }
            >
              {loading ? (
                <UiLoadingPanel type={"minimal"} />
              ) : (
                <div className=" w-100">
                  {selectionItemSearchResult?.data?.list?.map((item: any) => {
                    const { name, avatar, work_mode, outside_until, status } =
                      item || {};

                    return (
                      <div
                        className={classNames(
                          styles.searchItem,
                          "d-flex align-items-center justify-content-between"
                        )}
                        onClick={() => {
                          setSearchValue("");
                          handleSelectAssignee(item);
                          setIsShowSearchBar(false);
                        }}
                      >
                        <ProductTableCell
                          description={name}
                          image={avatar}
                          isForceDisplayAvatar
                          userName={name}
                          imageSize={30}
                          allowDynamicAvatarBackgroundColor
                          displayStatusMode={{
                            workMode: work_mode,
                            outsideUntil: outside_until,
                          }}
                        />
                        <StatusTableCell status={status} />
                      </div>
                    );
                  })}
                </div>
              )}
            </Overlay>
          </div>
          <div style={{ width: 143 }}>
            <UiButton
              onClick={() => {
                setIsShowSearchBar(false);
              }}
              type={GENERAL_UI_COLOR.DANGER}
              title={"Cancel"}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewAssignee;
