import { splitAndCapitalize } from "../containers/task-logging-create/utils";

/**
 * Format number with decimal spacer
 * @param {number} value number
 * @return {string} number with format
 */
export const numberFormat = (_number: number | any) => {
  /**
   * Validate value
   * - NULL
   * - UNDEFINED
   * NOT A NUMBER
   */
  if (
    null === _number ||
    "undefined" === typeof _number ||
    true === isNaN(_number)
  ) {
    return "0";
  }

  const signValue = _number < 0 ? "-" : "";
  const absValue = Math.abs(_number);

  return (
    signValue + absValue.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
  );
};

export const formatUpperCase = (str: string) => {
  return `${str.charAt(0).toLocaleUpperCase()}${str.slice(1)} `;
};

export const htmlStripTag = (content: string) =>
  content?.replace ? content?.replace(/(<([^>]+)>)/gi, "") : "";

export const truncateText = (text = "", maxLength = 80) => {
  if (!text) text = "";

  return text.length <= maxLength
    ? text
    : `${text.substring(0, maxLength)} ...`;
};

export const addSpacesBeforeUppercase = (inputString: string) => {
  if (!inputString) return "";

  const transformedString = inputString?.replace(/([A-Z])/g, " $1");
  return transformedString.trim();
};

interface IFilterOption {
  quantity: number;
  text: string;
}

export const sortingFilterCount = (array: IFilterOption[]) => {
  if (!array) return [];

  return array?.sort((a: IFilterOption, b: IFilterOption) => {
    if (a?.quantity === 0) {
      return 1;
    } else if (b?.quantity === 0) {
      return -1;
    } else {
      return a?.quantity - b?.quantity;
    }
  });
};

export const generateFilterParamsWithQtyCount = (
  data: Record<string, number>
) => {
  if (!data) return [];

  const formatedSortingValue = Object.keys(data)?.map((key) => ({
    text: key,
    quantity: data[key],
  }));

  return sortingFilterCount(formatedSortingValue)?.map(
    (option: IFilterOption) => ({
      value: option?.text,
      content: splitAndCapitalize(option?.text),
      quantity: option?.quantity,
    })
  );
};

export const generateFilterParamsWithArray = (data: any) => {
  if (!data) return [];

  return data.map((text: string) => {
    return { value: text, content: text };
  });
}

export const generateFilterParamsWithName = (data: any) => {
  if (!data) return [];

  return data.map((record: any) => {
    return { value: record?.uuid, content: record?.name };
  });
}

export const formatNumber = (number: number) => {
  const formattedNumber = number?.toLocaleString("en-US");

  return formattedNumber?.replace(/,/g, ".");
};
