import * as yup from "yup";
import { GENERAL_STATUS } from "../../constants/status";

export const formSchema = yup
  .object({
    name: yup.string().required("Name is required"),
    username: yup.string().required("Username is required"),
    status: yup
      .string()
      .oneOf([GENERAL_STATUS.ACTIVATE, GENERAL_STATUS.DEACTIVE])
      .required("Status is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(6, "Password must have as least 6 characters")
      .max(30, "Password must have maximum 30 characters"),
    expired_at: yup.mixed().required("This field is required"),
  })
  .required();
