import classNames from "classnames";
import React from "react";
import styles from "./styles.module.scss";
import Papa from "papaparse";

interface FileImporterProps {
  onFileUpload: (data: any) => void;
  onFileCancel?: () => void;
  acceptedType?: string;
  title?: string | React.ReactNode;
  required?: boolean;
  className?: {
    container?: string;
    label?: string;
    input?: string;
  };
  disabled?: boolean;
}

const FileImporter: React.FC<FileImporterProps> = ({
  onFileUpload,
  acceptedType = ".csv",
  title,
  required = false,
  className = {
    container: "",
    label: "",
    input: "",
  },
  onFileCancel,
  disabled = false,
}) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      readFile(file);
    } else {
      onFileCancel && onFileCancel();
    }
  };

  const readFile = (file: File) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const csvData = event.target?.result as string;

      parseCSVData(csvData);
    };

    reader.readAsText(file);
  };

  const parseCSVData = (csvData: string) => {
    Papa.parse(csvData, {
      header: true,
      complete: function (results) {
        onFileUpload(results.data);
      },
      error: function (error: any) {},
    });
  };

  return (
    <div className={classNames("w-100 cursor-pointer", className.container)}>
      {title && <label data-required={required}>{title}</label>}
      <div
        className={classNames(
          styles.fileInputContainer,
          "cursor-pointer w-100"
        )}
      >
        <div className={classNames(styles.dragAndDropLabel)}>
          <div>
            <i className="fa-solid fa-file-import fs-3"></i>
          </div>
          Drag & Drop or Click here
        </div>
        <input
          type="file"
          accept={acceptedType}
          onChange={handleFileChange}
          disabled={disabled}
          onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
            const element = event.target as HTMLInputElement;
            element.value = "";
          }}
          className={classNames(
            disabled ? "" : "cursor-pointer",
            className.input
          )}
        />
      </div>
    </div>
  );
};

export default FileImporter;
