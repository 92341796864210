import { useState } from "react";
import { NavLink } from "react-router-dom";

import styles from "./styles.module.scss";

import { GENERAL_UI_SIZE } from "../../constants/size";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { GENERAL_STATUS } from "../../constants/status";
import { TABLE_ACTION } from "../../constants/table-action";
import {
  ACTIONABLE_TYPE,
  ACTIONABLE_ACTIONS,
} from "../../constants/actionable";

import UiButton from "../../components/ui-button";
import UiSwitch from "../../components/ui-switch";
import QuickActionPopupConfirmation from "../popup-quick-aciton-confirmation";

interface IActionItem {
  onClick: any;
  params?:
    | {
        link: string;
        actions: ACTIONABLE_ACTIONS;
      }
    | any;
}

interface IGeneralVEDButton extends IActionItem {
  actionType: ACTIONABLE_TYPE;
  icon: string;
  tooltipMessage: string;
}

const DEFAULT_DISABLED_MESSAGE = "Disabled";
const ActionToolTip = ({ message = "" }: { message: string }) => (
  <div className={styles.tooltip}>{message}</div>
);

const Restore = ({ onClick, params }: IActionItem) => {
  return (
    <NavLink
      className={styles.actionItem}
      title="Restore"
      onClick={onClick}
      to={params}
    >
      <i className="fa-solid fa-rotate-left"></i>
    </NavLink>
  );
};

const ResetPassword = ({ onClick, params }: IActionItem) => {
  return (
    <NavLink
      className={styles.actionItem}
      title="Reset Password"
      onClick={onClick}
      to={params}
    >
      <i className="fa-solid fa-lock-open"></i>
    </NavLink>
  );
};

const Toggle = ({ onClick, params }: IActionItem) => {
  const { isCheckBoxChecked, actions } = params;
  const isEnabled = actions?.[ACTIONABLE_TYPE.EDIT]?.actionable;

  return (
    <span className={`${styles.toggleItem} ${!isEnabled && styles.disabled}`}>
      <UiSwitch
        isDisplayLabel={false}
        checked={isCheckBoxChecked}
        isEditable
        disabled={!isEnabled}
        onChange={isEnabled && onClick}
      />
      <ActionToolTip
        message={isEnabled ? "Change status" : DEFAULT_DISABLED_MESSAGE}
      />
    </span>
  );
};

const GeneralVEDButton = ({
  onClick,
  params: { link, actions },
  actionType,
  icon,
  tooltipMessage,
}: IGeneralVEDButton) => {
  const isEnabled = actions?.[actionType]?.actionable;

  return (
    <NavLink
      className={`${styles.actionItem} ${!isEnabled && styles.disabled}`}
      onClick={isEnabled ? onClick : null}
      to={isEnabled && link}
    >
      <i className={`fas ${icon} text-secondary`} aria-hidden="true"></i>
      <ActionToolTip
        message={isEnabled ? tooltipMessage : DEFAULT_DISABLED_MESSAGE}
      />
    </NavLink>
  );
};

const View = ({ onClick, params }: IActionItem) => (
  <GeneralVEDButton
    onClick={onClick}
    params={{ link: params?.link, actions: params?.actions }}
    actionType={ACTIONABLE_TYPE.SHOW}
    icon={"fa-eye"}
    tooltipMessage={"View"}
  />
);

const Edit = ({ onClick, params }: IActionItem) => (
  <GeneralVEDButton
    onClick={onClick}
    params={{ link: params?.link, actions: params?.actions }}
    actionType={ACTIONABLE_TYPE.EDIT}
    icon={"fa-pen"}
    tooltipMessage={"Edit"}
  />
);

const Delete = ({ onClick, params }: IActionItem) => (
  <GeneralVEDButton
    onClick={onClick}
    params={{ link: params?.link, actions: params?.actions }}
    actionType={ACTIONABLE_TYPE.DESTROY}
    icon={"fa-trash"}
    tooltipMessage={"Delete"}
  />
);

const Custom = ({ params, onClick }: IActionItem) => {
  const { customTitle, customActionableType, actions } = params;
  const isEnabled =
    customActionableType === ACTIONABLE_TYPE.FULL ||
    actions?.[customActionableType]?.actionable;

  return (
    <div className={styles.customButton}>
      <UiButton
        title={customTitle}
        disabled={!isEnabled}
        onClick={isEnabled ? onClick : null}
        size={GENERAL_UI_SIZE.SMALL}
        type={GENERAL_UI_COLOR.LIGHT}
      ></UiButton>
      <ActionToolTip
        message={isEnabled ? customTitle : DEFAULT_DISABLED_MESSAGE}
      />
    </div>
  );
};

interface IComponent {
  className?: string;
  types: Array<TABLE_ACTION>;
  toggleData?: string;
  viewLink?: string;
  editLink?: string;
  onToggle?: any;
  onView?: any;
  onEdit?: any;
  onDelete?: any;
  codeId?: string | number;
  popupContent?: string;
  resetPasswordLink?: string;
  customTitle?: string | React.ReactNode;
  onCustomClick?: any;
  customActionableType?: ACTIONABLE_TYPE;
  onRestore?: any;
  maxWidth?: number;
  align?: "start" | "center" | "end";
  actions?: ACTIONABLE_ACTIONS;
}

const Component = ({
  className,
  types,
  toggleData,
  viewLink,
  editLink,
  resetPasswordLink,
  customTitle = "Action",
  onCustomClick,
  customActionableType,
  onToggle,
  onView,
  onEdit,
  onDelete,
  popupContent,
  onRestore,
  maxWidth,
  align = "end",
  actions,
}: IComponent) => {
  const [isShowModal, setIsShowModal] = useState(false);

  const handleOpenModal = () => {
    setIsShowModal(true);
  };

  const handleCloseModal = () => {
    setIsShowModal(false);
  };

  let isCheckBoxChecked = false;

  switch (toggleData) {
    case GENERAL_STATUS.ACTIVATE:
      isCheckBoxChecked = true;
      break;

    case GENERAL_STATUS.INACTIVE:
    case GENERAL_STATUS.DEACTIVE:
      isCheckBoxChecked = false;
      break;
  }

  const actionTypes = {
    [TABLE_ACTION.RESTORE]: {
      component: Restore,
      params: {},
      onClick: () => onRestore?.(),
    },
    [TABLE_ACTION.RESET_PASSWORD]: {
      component: ResetPassword,
      params: resetPasswordLink,
      onClick: () => {},
    },
    [TABLE_ACTION.TOGGLE]: {
      component: Toggle,
      params: { isCheckBoxChecked, actions },
      onClick: () => onToggle?.(),
    },
    [TABLE_ACTION.VIEW]: {
      component: View,
      params: { link: viewLink, actions },
      onClick: () => onView?.(),
    },
    [TABLE_ACTION.EDIT]: {
      component: Edit,
      params: { link: editLink, actions },
      onClick: () => onEdit?.(),
    },
    [TABLE_ACTION.DELETE]: {
      component: Delete,
      params: { actions },
      onClick: () => handleOpenModal?.(),
    },
    [TABLE_ACTION.CUSTOM]: {
      component: Custom,
      params: { customTitle, customActionableType, actions },
      onClick: onCustomClick,
    },
  };

  const sortedTypes = [
    TABLE_ACTION.TOGGLE,
    TABLE_ACTION.VIEW,
    TABLE_ACTION.RESET_PASSWORD,
    TABLE_ACTION.EDIT,
    TABLE_ACTION.DELETE,
    TABLE_ACTION.RESTORE,
    TABLE_ACTION.CUSTOM,
  ];

  return (
    <>
      <div
        className={`${styles.container} ${className} justify-content-${align} w-100`}
        style={{ maxWidth: maxWidth }}
      >
        {sortedTypes.map((type) => {
          if (types.indexOf(type) < 0) return null

          const { component: Component, onClick, params } = actionTypes[type];

          return <Component onClick={onClick} params={params} />;
        })}
      </div>
      <QuickActionPopupConfirmation
        content={popupContent || "Are you sure to delete this product ?"}
        isShowModal={isShowModal}
        handleCloseModal={handleCloseModal}
        onConfirm={onDelete}
      />
    </>
  );
};

export default Component;
