import { useState } from "react";

import {
  getStartAndEndOfMonth,
  getTheStartAndTheEndOfWeekBaseOnCurrentDay,
  getUnixTime,
} from "../../utils/date-time";
import { useApiCaller } from "../../apis/config";
import { getTaskStatusDashboard } from "../../apis/paths/dashboard";
import UiTab from "../../components/ui-tab";
import Typography from "../../components/ui-typography/component";
import PanelGeneral from "../../components/panel-general/component";
import DoughnutChart from "../../components/ui-doughnut-chart/component";

const Component = () => {
  const [params, setParams] = useState<{
    page: number;
    per: number;
    startDate: number;
    endDate: number;
  }>({
    page: 1,
    per: 50,
    startDate: getUnixTime(1),
    endDate: getUnixTime(0),
  });

  const { result } = useApiCaller({
    api: getTaskStatusDashboard,
    params: params,
  });

  const [currentActiveTab, setCurrentActiveTab] = useState(1);

  const { endOfWeek, startOfWeek } =
    getTheStartAndTheEndOfWeekBaseOnCurrentDay();
  const { endOfMonth, startOfMonth } = getStartAndEndOfMonth();

  const handleToggleUiTab = (newActiveTab: number, data: any) => {
    setCurrentActiveTab(newActiveTab);
    if (newActiveTab === 0) {
      setParams((prev) => {
        return { ...prev, startDate: getUnixTime(1), endDate: getUnixTime(0) };
      });
    }
    if (newActiveTab === 1) {
      setParams((prev) => {
        return { ...prev, startDate: startOfWeek, endDate: endOfWeek };
      });
    } else if (newActiveTab === 2) {
      setParams((prev) => {
        return { ...prev, startDate: startOfMonth, endDate: endOfMonth };
      });
    }
  };

  const taskStatus = result?.data;

  const inboundDoughnutInput = {
    labels: ["To Do", "In Progress", "Done", "Cancelled"],
    datasets: [
      {
        label: "Quantity",
        data: [
          taskStatus?.to_do,
          taskStatus?.in_progress,
          taskStatus?.done,
          taskStatus?.canceled,
        ],
        backgroundColor: [
          "rgba(255, 140, 0, 0.2)",
          "rgba(0, 128, 128, 0.2)",
          "rgba(0, 0, 0, 0.2)",
          "rgba(128, 128, 128, 0.2)",
        ],
        hoverBackgroundColor: [
          "rgba(255, 140, 0, 1)",
          "rgba(0, 128, 128, 1)",
          "rgba(0, 0, 0, 1)",
          "rgba(128, 128, 128, 1)",
        ],
        cutout: 95,
        borderColor: [
          "rgba(255, 140, 0, 1)",
          "rgba(0, 128, 128, 1)",
          "rgba(0, 0, 0, 1)",
          "rgba(128, 128, 128, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <PanelGeneral className="position-relative w-100">
        <div className="d-flex justify-content-between my-2 align-items-center">
          <Typography
            className="text-center my-3"
            customFontsize={14}
            fontWeight="fw-bold"
          >
            Task Statuses
          </Typography>
          <div className="d-flex gap-2 align-items-center justify-content-end">
            <UiTab
              tabs={["Today", "Week", "Month"]}
              selected={currentActiveTab}
              onChange={handleToggleUiTab}
            />
          </div>
        </div>
        <DoughnutChart
          data={inboundDoughnutInput}
          width="100%"
          height={270}
          legend={{ position: "right" }}
        />
      </PanelGeneral>
    </>
  );
};

export default Component;
