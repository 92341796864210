import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as R from "../../routings/path";
import { useApiCaller } from "../../apis/config";
import { GENERAL_STATUS } from "../../constants/status";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { getWarehouseDetailApi } from "../../apis/paths/warehouse";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import HeaderInfoPanel from "../../components/panel-header-info";
import TableCellStatus from "../../components/table-cell-status";
import UiLoadingPanel from "../../components/ui-loading-panel";
import NotFoundPanel from "../../components/panel-not-found";
import GeneralPanel from "../../components/panel-general";
import UiButton from "../../components/ui-button";
import UiInput from "../../components/ui-input";

import { fieldTitle, schema } from "./schema";
import { extractAddressFields } from "../../utils/address";

const transformData = (data: any) => {
  if (!data) return null;

  const { detail, street, ward } = extractAddressFields(
    data?.address?.full_address
  );

  return {
    contactName: data?.contact_name,
    contactPhone: data?.contact_phone,
    storageType: data?.storage_type,
    parentWarehouseUuid: data?.parent_warehouse?.name,
    wardId: ward,
    streetName: street,
    detail,
    longitude: data?.address?.longitude,
    latitude: data?.address?.latitude,
    ...data,
  };
};

const Component = () => {
  const navigate = useNavigate();

  const { warehouseId: code } = useParams();
  const [params] = useState({ code });

  const { result, loading } = useApiCaller({
    api: getWarehouseDetailApi,
    params: params,
  });

  const warehouseInfo = transformData(result?.data);

  useEffect(() => {
    if (!result || result?.status === "ERROR") return;

    const warehouseInfo = transformData(result?.data);
    const keys = Object.keys(schema?.fields);
    keys.forEach((key: any) => {
      setValue(key, warehouseInfo[key]);
    });
  }, [result]);

  const {
    setValue,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  if (loading) return <UiLoadingPanel />;

  if (result?.status === "ERROR" || !warehouseInfo) return <NotFoundPanel />;

  return (
    <>
      <HeaderInfoPanel
        title={`${warehouseInfo?.name} #${warehouseInfo?.code}`}
        infoArea={() => (
          <TableCellStatus
            status={
              warehouseInfo?.deleted_at
                ? GENERAL_STATUS.DEACTIVE
                : GENERAL_STATUS.ACTIVATE
            }
            size={GENERAL_UI_SIZE.SMALL}
          />
        )}
        actionArea={() => (
          <>
            <UiButton
              onClick={() =>
                navigate(`${R.WAREHOUSES_LIST}/${warehouseInfo?.uuid}/edit`)
              }
              className="me-2"
              title={"Edit Warehouse"}
            />
          </>
        )}
      />
      <div className="mt-4">
        <GeneralPanel panel={{ subTitle: "Warehouse Information" }}>
          <TwoColumnGridLayout
            className={{ container: "px-0" }}
            leftCol={
              <>
                <UiInput
                  viewMode
                  title={fieldTitle.name}
                  name={"name"}
                  register={register}
                  errorMessage={errors?.name?.message}
                />
                <UiInput
                  viewMode
                  title={fieldTitle.contactName}
                  name={"contactName"}
                  register={register}
                  errorMessage={errors?.contactName?.message}
                />
                <UiInput
                  viewMode
                  title={fieldTitle.contactPhone}
                  name={"contactPhone"}
                  placeholder={"Ex: 090900000"}
                  register={register}
                  errorMessage={errors?.contactPhone?.message}
                />
              </>
            }
            rightCol={
              <>
                <UiInput
                  viewMode
                  title={fieldTitle.valuable}
                  name={"valuable"}
                  type="checkbox"
                  isDisplayLabel
                  register={register}
                  containerClassName="mb-4"
                />
                <UiInput
                  viewMode
                  title={fieldTitle.parentWarehouseUuid}
                  name={"parentWarehouseUuid"}
                  placeholder="Ex: 1234567890"
                  register={register}
                  errorMessage={errors?.parentWarehouseUuid?.message}
                />
                <UiInput
                  viewMode
                  title={fieldTitle.storageType}
                  name={"storageType"}
                  placeholder={"Ex: packing, reserve..."}
                  register={register}
                  errorMessage={errors?.storageType?.message}
                />
              </>
            }
          />
        </GeneralPanel>

        <GeneralPanel panel={{ subTitle: "Address Information" }}>
          <TwoColumnGridLayout
            className={{ container: "px-0" }}
            leftCol={
              <>
                <UiInput
                  viewMode
                  title={fieldTitle.wardId}
                  name={"wardId"}
                  register={register}
                  errorMessage={errors?.wardId?.message}
                />
                <UiInput
                  viewMode
                  title={fieldTitle.streetName}
                  name={"streetName"}
                  register={register}
                  errorMessage={errors?.streetName?.message}
                />
                <UiInput
                  viewMode
                  title={fieldTitle.detail}
                  name={"detail"}
                  register={register}
                  errorMessage={errors?.detail?.message}
                />
              </>
            }
            rightCol={
              <div className="row">
                <div className="col">
                  <UiInput
                    viewMode
                    title={fieldTitle.latitude}
                    name={"latitude"}
                    register={register}
                    errorMessage={errors?.latitude?.message}
                  />
                </div>
                <div className="col">
                  <UiInput
                    viewMode
                    title={fieldTitle.longitude}
                    name={"longitude"}
                    register={register}
                    errorMessage={errors?.longitude?.message}
                  />
                </div>
              </div>
            }
          />
        </GeneralPanel>
      </div>
    </>
  );
};

export default Component;
