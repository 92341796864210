import { useState } from "react";
import { useParams } from "react-router-dom";

import TableDataPanel from "../../components/panel-table-data";
import HeaderInfoPanel from "../../components/panel-header-info";
import TableCellStatus from "../../components/table-cell-status";
import UiStatusDropdown from "../../components/ui-status-dropdown";
import { useApiCaller } from "../../apis/config";
import {
  getPickingDetailApi,
  updatePickingStatusApi,
} from "../../apis/paths/picking";
import { GENERAL_UI_SIZE } from "../../constants/size";

import { taskSchema } from "./schema";
import styles from "./styles.module.scss";

const Component = () => {
  const { pickingId: uuid } = useParams();

  const [params] = useState({ uuid });
  const [pageReloadVersion, setPageReloadVersion] = useState(0);

  const { result } = useApiCaller({
    api: getPickingDetailApi,
    params,
    pageReloadVersion,
  });

  const pickingDetail = result?.data;

  return (
    <>
      <HeaderInfoPanel
        title={`#${pickingDetail?.code}`}
        className="mb-4"
        infoArea={() => (
          <div className={styles.headerInfoArea}>
            <TableCellStatus
              className="me-2"
              status={pickingDetail?.status}
              size={GENERAL_UI_SIZE.SMALL}
            />
          </div>
        )}
        actionArea={() => (
          <>
            {pickingDetail?.status && (
              <UiStatusDropdown
                status={pickingDetail?.status}
                triggerableEvents={pickingDetail?.triggerable_events}
                actionApi={updatePickingStatusApi}
                paramsTransformation={(newEvent: string) => ({
                  uuid: uuid,
                  action: newEvent,
                })}
                onChangeSuccess={() => {
                  setPageReloadVersion((prev) => prev + 1);
                }}
              />
            )}
          </>
        )}
      />
      <TableDataPanel data={pickingDetail?.picking_tasks} schema={taskSchema} />
    </>
  );
};

export default Component;
