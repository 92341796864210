import * as R from "../../routings/path";
import DateTimeTableCell from "../../components/table-cell-date-time";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import NumberTableCell from "../../components/table-cell-number";
import { splitAndCapitalize } from "../task-logging-create/utils";

export const productSchema = [
  {
    name: "Product",
    cell: (data: any) => {
      return (
        <ProductTableCell
          linkTo={`${R.PRODUCTS_LIST}/${data?.product?.uuid}`}
          image={data?.product?.image_url}
          title={data?.product?.name}
          description={data?.product?.code}
          imageSize={40}
          maxWidth={300}
        />
      );
    },
  },
  {
    name: "Quantity",
    align: "center",
    textSize: "xs",
    width: 120,
    cell: (data: any) => {
      return (
        <NumberTableCell
          value={data?.quantity}
          className={{ content: "fs-5 fw-bold" }}
        />
      );
    },
  },
];

export const taskSchema = [
  {
    name: "Picking task",
    cell: (data: any) => (
      <ProductTableCell
        linkTo={`${R.TASK_LOGGING_LIST}/${data?.uuid}`}
        title={splitAndCapitalize(data?.name)}
        description={data?.detail_name}
        maxWidth={400}
      />
    ),
  },
  {
    name: "Assignee",
    cell: (data: any) => {
      return (
        <ProductTableCell
          image={data?.assignee?.avatar}
          title={data?.assignee?.name}
          userName={data?.assignee?.name}
          isForceDisplayAvatar
          allowDynamicAvatarBackgroundColor
          displayStatusMode={{
            workMode: data?.assignee?.work_mode,
            outsideUntil: data?.assignee?.outside_until,
          }}
        />
      );
    },
  },
  {
    name: "priority",
    align: "center",
    cell: (data: any) => <StatusTableCell status={data?.priority} />,
  },
  {
    name: "Created",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data?.created_at} />,
  },
  {
    name: "In Progress",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data?.in_progress_at} />,
  },
  {
    name: "Status",
    align: "right",
    cell: (data: any) => <StatusTableCell status={data?.status} />,
  },
];

export const checkingTaskSchema = [
  {
    name: "Checking task",
    cell: (data: any) => (
      <ProductTableCell
        linkTo={`${R.TASK_LOGGING_LIST}/${data?.uuid}`}
        title={splitAndCapitalize(data?.name)}
        description={data?.detail_name}
        maxWidth={400}
      />
    ),
  },
  {
    name: "Assignee",
    cell: (data: any) => {
      return (
        <ProductTableCell
          image={data?.assignee?.avatar}
          title={data?.assignee?.name}
          userName={data?.assignee?.name}
          isForceDisplayAvatar
          allowDynamicAvatarBackgroundColor
          displayStatusMode={{
            workMode: data?.assignee?.work_mode,
            outsideUntil: data?.assignee?.outside_until,
          }}
        />
      );
    },
  },
  {
    name: "priority",
    align: "center",
    cell: (data: any) => <StatusTableCell status={data?.priority} />,
  },
  {
    name: "In Progress At",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data?.in_progress_at} />,
  },
  {
    name: "Status",
    align: "center",
    cell: (data: any) => <StatusTableCell status={data?.status} />,
  },
];

export const saleOrderSchema = [
  {
    name: "Sale Order",
    cell: (data: any) => (
      <ProductTableCell
        title={data?.number}
        linkTo={`${R.SALE_ORDER_LIST}/${data?.uuid}`}
        description={`#${data?.channel}`?.toUpperCase()}
        maxWidth={120}
      />
    ),
  },
  {
    name: "Contact",
    cell: (data: any) => (
      <ProductTableCell
        subIcon={"fa-phone"}
        linkTo={`tel:${data?.receiver_phone}`}
        title={data?.receiver_name}
        description={data?.receiver_phone}
      />
    ),
  },
  {
    name: "Status",
    align: "right",
    cell: (data: any) => <StatusTableCell status={data?.status} />,
  },
];
