import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { generateUrlParams } from "../../utils/url";
import { get, post, patch } from "../config";

interface IGetTaskLoggings {
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
  filter?: object | null;
}
interface IGetTaskLoggingsSelectionItems {
  page?: number;
  per?: number;
  linked_object_type?: string;
  linked_object_code?: string;
  task_name: string; //To do: Write dynamic extract TTaskLogging
  keyword?: string;
}

interface IGetAssignees {
  page?: number;
  per?: number;
  uuid: string;
  keyword?: string;
}

interface IGetTaskSelectionItems {
  page?: number;
  per?: number;
  task_name: string;
  keyword?: string;
}

export interface ICreateTaskLoggings {
  uuid: string;
  sla: number;
  in_progress_at: number;
  task_name: string;
  assignee_uuid: string;
  description?: string;
  linked_object_code?: string;
  linked_object_type?: string;
  due_at: string;
}
interface IUpdateTaskLoggings {
  uuid: string;
  sla: number;
  in_progress_at: number;
  due_at: number;
  description?: string;
  task_name: string;
  assignee_uuid: string;
  linked_object_code?: string;
  linked_object_type?: string;
}

export const getTaskLoggings = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
  filter = DEFAULT_PAGINATION_PARAMS.filter,
}: IGetTaskLoggings) =>
  get({
    path: `/wms/tasks${generateUrlParams({
      page,
      per,
      keyword,
      sort,
      filter,
    })}`,
  });

export const getTaskLoggingAssignees = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
  filter = DEFAULT_PAGINATION_PARAMS.filter,
}: IGetTaskLoggings) =>
  get({
    path: `/wms/tasks/assignees${generateUrlParams({
      page,
      per,
      keyword,
      sort,
      filter,
    })}`,
  });

export const getUnassignedTaskLoggings = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
}: IGetTaskLoggings) =>
  get({
    path: `/wms/tasks/unassigned${generateUrlParams({
      page,
      per,
      keyword,
      sort,
    })}`,
  });

export const getTaskLoggingDetail = ({ uuid }: { uuid: string }) =>
  get({
    path: `/wms/tasks/${uuid}`,
  });

export const getSelectionAssignees = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  task_name,
  linked_object_code,
  linked_object_type,
  keyword,
}: IGetTaskLoggingsSelectionItems) => {
  let params: Omit<IGetTaskLoggingsSelectionItems, "task_name"> = {
    page,
    per,
    linked_object_code,
    linked_object_type,
    keyword,
  };

  if (!linked_object_code || !linked_object_type) {
    params = {
      page,
      per,
      keyword,
    };
  }

  return get({
    path: `/wms/tasks/${task_name}/selection_assignees${generateUrlParams(
      params
    )}`,
  });
};

export const getAssignees = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  uuid,
}: IGetAssignees) =>
  get({
    path: `/wms/tasks/${uuid}/assignees${generateUrlParams({
      page,
      per,
      keyword,
    })}`,
  });

export const getTaskLoggingSelectionItems = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  task_name,
}: IGetTaskSelectionItems) =>
  get({
    path: `/wms/tasks/${task_name}/selection_items${generateUrlParams({
      page,
      per,
      keyword,
    })}`,
  });

export const getNamesMapper = () => get({ path: `/wms/tasks/names_mapper` });

export const updateTaskLoggingStatus = ({
  uuid,
  action,
}: {
  uuid: string;
  action: string;
}) =>
  patch({
    path: `/wms/tasks/${uuid}/status`,
    data: {
      action,
    },
  });

export const createTaskLoggingApi = ({
  task_name,
  assignee_uuid,
  sla,
  linked_object_code,
  linked_object_type,
  in_progress_at,
  due_at,
  description,
}: ICreateTaskLoggings) =>
  post({
    path: `/wms/tasks`,
    data: {
      task_name,
      assignee_uuid,
      sla,
      linked_object_code,
      linked_object_type,
      in_progress_at,
      description,
      due_at,
    },
  });

export const updateTaskLoggingApi = ({
  uuid,
  sla,
  in_progress_at,
  description,
  due_at,
  assignee_uuid,
  linked_object_code,
  linked_object_type,
}: IUpdateTaskLoggings) =>
  patch({
    path: `/wms/tasks/${uuid}`,
    data: {
      assignee_uuid,
      sla,
      description,
      in_progress_at,
      due_at,
      linked_object_code,
      linked_object_type,
    },
  });
