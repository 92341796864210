import * as R from "../../routings/path";
import { TABLE_ACTION } from "../../constants/table-action";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import ActionTableCell from "../../components/table-cell-action";
import DateTimeTableCell from "../../components/table-cell-date-time";

interface ISchema {
  onToggle?: any;
  tplRouteStatus?: any;
}

export const schema = ({ onToggle, tplRouteStatus }: ISchema) => [
  {
    name: "Route",
    cell: (data: any) => <ProductTableCell title={data.name || "-"} />,
  },
  {
    name: "Owner",
    width: 150,
    cell: (data: any) => {
      const { name, avatar } = data?.owner || {};
      return <ProductTableCell title={name} image={avatar} previewImage={false} />;
    },
  },
  {
    name: "Created",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data.created_at} />,
  },

  {
    name: "Status",
    align: "center",
    width: 80,
    cell: (data: any) => {
      return <StatusTableCell status={tplRouteStatus?.[data?.id]} />;
    },
  },
  {
    name: "Action",
    align: "center",
    width: 80,
    cell: (data: any) => {
      return (
        <ActionTableCell
          actions={data.instance_actionables}
          types={[TABLE_ACTION.TOGGLE, TABLE_ACTION.VIEW, TABLE_ACTION.EDIT]}
          toggleData={data.status}
          viewLink={`${R.TPL_ROUTES}/${data.id}`}
          editLink={`${R.TPL_ROUTES}/${data.id}/edit`}
          onToggle={() => onToggle(data.id)}
        />
      );
    },
  },
];
