/* eslint-disable import/no-anonymous-default-export */
import {
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import RoutingNavigationLayout from "../layouts/routing-navigation-layout/component";
import MasterLayout from "../layouts/master-layout";
import SignIn from "../containers/auth-sign-in";
import Dashboard from "../containers/dashboard";
import ProductList from "../containers/product-list";
import ProductTypeList from "../containers/product-type-list";
import ProductTypeDetail from "../containers/product-type-detail";
import ProductTypeEdit from "../containers/product-type-edit";
import ProductTypeCreate from "../containers/product-type-create";
import ProductCreate from "../containers/product-create";
import ProductDetail from "../containers/product-detail";
import ProductEdit from "../containers/product-edit";
import WarehouseList from "../containers/warehouse-list";
import WarehouseCreate from "../containers/warehouse-create";
import WarehouseDetail from "../containers/warehouse-detail";
import WarehouseEdit from "../containers/warehouse-edit";
import WarehouseEditRelation from "../containers/warehouse-edit-relation";
import WarehouseLocationList from "../containers/location-list";
import WarehouseLocationCreate from "../containers/location-create";
import WarehouseLocationProductDetail from "../containers/location-product-list";
import PurchaseOrderCreate from "../containers/purchase-order-create";
import PurchaseOrderList from "../containers/purchase-order-list";
import PurchaseOrderDetail from "../containers/purchase-order-detail";
import PurchaseOrderEdit from "../containers/purchase-order-edit";
import PurchaseOrderPackageEdit from "../containers/purchase-order-edit-package";
import PurchaseOrderProductEdit from "../containers/purchase-order-edit-product";
import PurchaseOrderNoteEdit from "../containers/purchase-order-edit-note";
import SaleOrderCreate from "../containers/sale-order-create";
import SaleOrderDetail from "../containers/sale-order-detail";
import SaleOrderEdit from "../containers/sale-order-edit";
import SaleOrderEditProduct from "../containers/sale-order-edit-product";
import SaleOrderEditShippingService from "../containers/sale-order-edit-shipping-service";
import SaleOrderKanban from "../containers/sale-order-kanban";
import SaleOrderAuditingChange from "../containers/sale-order-auditing-detail";
import PickingList from "../containers/picking-list";
import PickingCreate from "../containers/picking-create";
import PickingDetail from "../containers/picking-detail";
import PickingEdit from "../containers/picking-edit";
import PickingEditTask from "../containers/picking-edit-task";
import PickingEditSaleOrder from "../containers/picking-edit-sale-order";
import PackingList from "../containers/packing-list";
import PackingEdit from "../containers/packing-edit";
import PackingSaleOrderEdit from "../containers/packing-sale-order-edit";
import PackingScanProductEdit from "../containers/packing-scan-products";
import RoleList from "../containers/role-list";
import RoleDetail from "../containers/role-detail";
import RoleEdit from "../containers/role-edit";
import RoleCreate from "../containers/role-create";
import AccountList from "../containers/account-list";
import AccountEdit from "../containers/account-edit";
import AccountDetail from "../containers/account-detail";
import AccountCreate from "../containers/account-create";
import AccountResetPassword from "../containers/account-reset-password";
import AccountDevices from "../containers/account-devices";
import TplRoutes from "../containers/tpl-routes-list";
import TplRoutesDetail from "../containers/tpl-routes-detail";
import TplRoutesEdit from "../containers/tpl-routes-edit";
import TplRoutesCreate from "../containers/tpl-routes-create";
import ShippingServices from "../containers/shipping-services";
import CompanyInfo from "../containers/company-info";
import SaleOrderList from "../containers/sale-order-list";
import MaterialList from "../containers/material-list";
import MaterialEdit from "../containers/material-edit";
import MaterialDetail from "../containers/material-detail";
import MaterialCreate from "../containers/material-create";
import ShopList from "../containers/shop-list";
import ShopDetail from "../containers/shop-detail";
import ShopEdit from "../containers/shop-edit";
import ShopCreate from "../containers/shop-create";
import TaskLoggingList from "../containers/task-logging-list";
import TaskLoggingDetail from "../containers/task-logging-detail";
import TaskLoggingEdit from "../containers/task-logging-edit";
import TaskLoggingCreate from "../containers/task-logging-create";
import SlaSettings from "../containers/sla-settings";
import AdvancedSettings from "../containers/dynamic-advanced-settings";
import DispatchList from "../containers/dispatch-list";
import DispatchDetail from "../containers/dispatch-detail";
import StockCheckList from "../containers/stock-check-list";
import StockCheckCreate from "../containers/stock-check-create";
import StockCheckDetail from "../containers/stock-check-detail";
import LabelsList from "../containers/label-list";
import RelocationShipmentList from "../containers/relocation-list";
import RelocationShipmentDetail from "../containers/relocation-shipment-detail";
import RelocationShipmentCreate from "../containers/relocation-shipment-create";
import RelocationShipmentEdit from "../containers/relocation-shipment-edit";
import RelocationShipmentEditProduct from "../containers/relocation-shipment-edit-product";

import LabelsDetail from "../containers/label-detail";
import LabelsCreate from "../containers/label-create";
import LabelsEdit from "../containers/label-edit";
import NotificationList from "../containers/notification-list";
import StickerList from "../containers/sticker-list";
import StickerCreate from "../containers/sticker-create";
import PageNotFound from "../containers/404";
import Samples from "../containers/samples";
import RoutingNavigationLayoutWithParams from "../layouts/routing-navigation-layout-with-params/component";
import PurchaseOrderEditLayout from "../layouts/purchase-order-layout";
import PickingEditLayout from "../layouts/picking-layout";
import SaleOrderEditLayout from "../layouts/sale-order-layout";

import * as R from "./path";
import { SETUP_CONFIG } from "../layouts/routing-navigation-layout/constants";
import {
  accountDetailSidebarContent,
  accountEditSidebarContent,
  generateWarehouseConfig,
  purchaseOrderEditSidebarContent,
  saleOrderEditSidebarContent,
  pickingEditSidebarContent,
  relocationShipmentEditSidebarContent,
} from "../layouts/routing-navigation-layout-with-params/constants";
import ErrorBoundary from "../components/error-boundary/component";

const ErrorBoundaryLayout = () => (
  <ErrorBoundary>
    <Outlet />
  </ErrorBoundary>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<ErrorBoundaryLayout />}>
      <Route path={R.AUTH_SIGN_IN} element={SignIn} />

      <Route path={R.DASHBOARD} element={<MasterLayout />}>
        <Route index element={Dashboard} />

        <Route path={R.PRODUCTS_LIST} element={ProductList} />
        <Route path={R.PRODUCTS_CREATE} element={ProductCreate} />
        <Route path={R.PRODUCTS_DETAIL} element={ProductDetail} />
        <Route path={R.PRODUCTS_EDIT} element={ProductEdit} />

        <Route path={R.PRODUCTS_TYPE_LIST} element={ProductTypeList} />
        <Route path={R.PRODUCTS_TYPE_DETAIL} element={ProductTypeDetail} />
        <Route path={R.PRODUCTS_TYPE_EDIT} element={ProductTypeEdit} />
        <Route path={R.PRODUCTS_TYPE_CREATE} element={ProductTypeCreate} />

        <Route path={R.PURCHASE_ORDERS_CREATE} element={PurchaseOrderCreate} />
        <Route path={R.PURCHASE_ORDERS_LIST} element={PurchaseOrderList} />
        <Route path={R.PURCHASE_ORDERS_DETAIL} element={PurchaseOrderDetail} />
        <Route
          path={R.PURCHASE_ORDERS_EDIT}
          element={
            <PurchaseOrderEditLayout
              sidebarContent={purchaseOrderEditSidebarContent}
            />
          }
        >
          <Route index element={PurchaseOrderEdit} />
          <Route
            path={R.PURCHASE_ORDERS_EDIT_PACKAGE}
            element={PurchaseOrderPackageEdit}
          />
          <Route
            path={R.PURCHASE_ORDERS_EDIT_NOTES}
            element={PurchaseOrderNoteEdit}
          />
          <Route
            path={R.PURCHASE_ORDERS_EDIT_PRODUCTS}
            element={PurchaseOrderProductEdit}
          />
        </Route>

        <Route path={R.SALE_ORDER_LIST} element={SaleOrderList} />
        <Route path={R.SALE_ORDERS_CREATE} element={SaleOrderCreate} />
        <Route path={R.SALE_ORDERS_DETAIL} element={SaleOrderDetail} />
        <Route path={R.SALE_ORDER_KANBAN} element={SaleOrderKanban} />
        <Route
          path={R.SALE_ORDER_AUDIT_CHANGE}
          element={SaleOrderAuditingChange}
        />
        <Route
          path={R.SALE_ORDERS_EDIT}
          element={
            <SaleOrderEditLayout sidebarContent={saleOrderEditSidebarContent} />
          }
        >
          <Route index element={SaleOrderEdit} />
          <Route
            path={R.SALE_ORDERS_EDIT_PRODUCT}
            element={SaleOrderEditProduct}
          />
          <Route
            path={R.SALE_ORDERS_EDIT_SHIPPING_SERVICE}
            element={SaleOrderEditShippingService}
          />
        </Route>

        <Route path={R.PICKINGS_LIST} element={PickingList} />
        <Route path={R.PICKINGS_CREATE} element={PickingCreate} />
        <Route path={R.PICKINGS_DETAIL} element={PickingDetail} />
        <Route
          path={R.PICKINGS_EDIT}
          element={
            <PickingEditLayout sidebarContent={pickingEditSidebarContent} />
          }
        >
          <Route index element={PickingEdit} />
          <Route path={R.PICKINGS_EDIT_TASK} element={PickingEditTask} />
          <Route
            path={R.PICKINGS_EDIT_SALE_ORDER}
            element={PickingEditSaleOrder}
          />
        </Route>

        <Route path={R.PACKINGS_LIST} element={PackingList} />
        <Route path={R.PACKINGS_CREATE} element={PickingCreate} />
        <Route path={R.PACKINGS_DETAIL} element={PackingEdit} />
        <Route
          path={R.PACKINGS_SALE_ORDER_EDIT}
          element={PackingSaleOrderEdit}
        />
        <Route
          path={R.PACKINGS_SCANNED_PRODUCT_EDIT}
          element={PackingSaleOrderEdit}
        />
        <Route
          path={R.PACKINGS_SCAN_PRODUCTS_EDIT}
          element={PackingScanProductEdit}
        />

        <Route path={R.WAREHOUSES_LIST} element={WarehouseList} />
        <Route path={R.WAREHOUSES_CREATE} element={WarehouseCreate} />
        <Route path={R.WAREHOUSES_EDIT} element={WarehouseEdit} />

        <Route
          path={R.WAREHOUSES_DETAIL}
          element={
            <RoutingNavigationLayoutWithParams
              widthDivision={[3, 9]}
              sidebarContent={generateWarehouseConfig}
              paramIds={["warehouseId", "locationCode"]}
            />
          }
        >
          <Route path={R.WAREHOUSES_RELATION} element={WarehouseEditRelation} />
          <Route
            path={R.WAREHOUSES_LOCATION_LIST}
            element={WarehouseLocationList}
          />
          <Route
            path={R.WAREHOUSES_LOCATION_CREATE}
            element={WarehouseLocationCreate}
          />
          <Route
            path={R.WAREHOUSES_LOCATION_DETAIL}
            element={WarehouseLocationProductDetail}
          />
          <Route index element={WarehouseDetail} />
        </Route>

        <Route path={R.ROLES_LIST} element={RoleList} />
        <Route path={R.ROLES_DETAIL} element={RoleDetail} />
        <Route path={R.ROLES_CREATE} element={RoleCreate} />
        <Route path={R.ROLES_EDIT} element={RoleEdit} />

        <Route path={R.ACCOUNT_LIST} element={AccountList} />
        <Route path={R.ACCOUNT_CREATE} element={AccountCreate} />

        <Route
          path={R.ACCOUNT_EDIT}
          element={
            <RoutingNavigationLayoutWithParams
              sidebarContent={accountEditSidebarContent}
              paramIds={["accountId"]}
            />
          }
        >
          <Route index element={AccountEdit} />
          <Route
            path={R.ACCOUNT_RESET_PASSWORD}
            element={AccountResetPassword}
          />
          <Route path={R.ACCOUNT_DEVICES} element={AccountDevices} />
        </Route>

        <Route
          path={R.ACCOUNT_DETAIL}
          element={
            <RoutingNavigationLayoutWithParams
              sidebarContent={accountDetailSidebarContent}
              paramIds={["accountId"]}
            />
          }
        >
          <Route index element={AccountDetail} />
          <Route path={R.ACCOUNT_DETAIL_DEVICES} element={AccountDevices} />
        </Route>

        <Route
          path={R.CONFIGURATION}
          element={<RoutingNavigationLayout sidebarContent={SETUP_CONFIG} />}
        >
          <Route path={R.SHIPPING_SERVICES} element={ShippingServices} />
          <Route path={R.COMPANY_INFO} element={CompanyInfo} />
          <Route path={R.ADVANCED_SETTINGS} element={AdvancedSettings} />
          {/* dynamic-advanced-settings={R.ADVANCED_SETTINGS} element={AdvancedSettings} /> */}
          <Route path={R.SLA} element={SlaSettings} />

          <Route index element={CompanyInfo} />
        </Route>

        <Route path={R.TPL_ROUTES} element={TplRoutes} />
        <Route path={R.TPL_ROUTES_DETAIL} element={TplRoutesDetail} />
        <Route path={R.TPL_ROUTES_EDIT} element={TplRoutesEdit} />
        <Route path={R.TPL_ROUTES_CREATE} element={TplRoutesCreate} />

        <Route path={R.MATERIAL_LIST} element={MaterialList} />
        <Route path={R.MATERIAL_DETAIL} element={MaterialDetail} />
        <Route path={R.MATERIAL_CREATE} element={MaterialCreate} />
        <Route path={R.MATERIAL_EDIT} element={MaterialEdit} />

        <Route path={R.SHOP_LIST} element={ShopList} />
        <Route path={R.SHOP_DETAIL} element={ShopDetail} />
        <Route path={R.SHOP_CREATE} element={ShopCreate} />
        <Route path={R.SHOP_EDIT} element={ShopEdit} />

        <Route path={R.LABELS_LIST} element={LabelsList} />
        <Route path={R.LABELS_DETAIL} element={LabelsDetail} />
        <Route path={R.LABELS_EDIT} element={LabelsEdit} />
        <Route path={R.LABELS_CREATE} element={LabelsCreate} />

        <Route
          path={R.RELOCATION_SHIPMENTS_LIST}
          element={RelocationShipmentList}
        />
        <Route
          path={R.RELOCATION_SHIPMENTS_DETAIL}
          element={RelocationShipmentDetail}
        />
        <Route
          path={R.RELOCATION_SHIPMENTS_EDIT}
          element={
            <RoutingNavigationLayoutWithParams
              widthDivision={[3, 9]}
              sidebarContent={relocationShipmentEditSidebarContent}
              paramIds={["relocationShipmentId"]}
            />
          }
        >
          <Route index element={RelocationShipmentEdit} />
          <Route
            path={R.RELOCATION_SHIPMENTS_EDIT_PRODUCT}
            element={RelocationShipmentEditProduct}
          />
        </Route>

        <Route
          path={R.RELOCATION_SHIPMENTS_CREATE}
          element={RelocationShipmentCreate}
        />

        <Route path={R.TASK_LOGGING_LIST} element={TaskLoggingList} />
        <Route path={R.TASK_LOGGING_DETAIL} element={TaskLoggingDetail} />
        <Route path={R.TASK_LOGGING_EDIT} element={TaskLoggingEdit} />
        <Route path={R.TASK_LOGGING_CREATE} element={TaskLoggingCreate} />

        <Route path={R.DISPATCH_LIST} element={DispatchList} />
        <Route path={R.DISPATCH_DETAIL} element={DispatchDetail} />

        <Route path={R.STOCK_CHECK_LIST} element={StockCheckList} />
        <Route path={R.STOCK_CHECK_CREATE} element={StockCheckCreate} />
        <Route path={R.STOCK_CHECK_DETAIL} element={StockCheckDetail} />

        <Route path={R.NOTIFICATION_LIST} element={NotificationList} />

        <Route path={R.STICKER_LIST} element={StickerList} />
        <Route path={R.STICKER_CREATE} element={StickerCreate} />

        <Route path="*" element={<PageNotFound />} />
      </Route>
      <Route path={R.SAMPLES} element={<Samples />} />
    </Route>
  )
);

export default router;
