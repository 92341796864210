import { useState } from "react";
import classNames from "classnames";

import { htmlStripTag } from "../../utils/format";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { getProductQtyOfSinglePackage } from "./utils";
import alert from "../../utils/alert";

import Avatar from "../../components/ui-avatar/component";
import Typography from "../../components/ui-typography/component";
import UiButton from "../../components/ui-button/component";
import UiTooltip from "../../components/ui-tooltip/component";
import QuickActionPopupConfirmation from "../../components/popup-quick-aciton-confirmation/component";

import {
  IHandleSplitingPackage,
  IPackagePrinting,
  IProductSaleOrder,
} from "./types";

import styles from "./styles.module.scss";

const ViewCardPackage = ({
  onDeletePackage,
  index,
  handleCreatePackageItem,
  packageItem,
  currentProductInPackagesQty,
  onSplitingPackage,
  onPrintSaleOrdersPackagesLabelApi,
  onPrintSaleOrdersProductsListPackagesApi,
  onDeleteSaleOrderPackage,
  allProducts,
  isInteractable,
}: {
  onDeletePackage: any;
  index: number;
  handleCreatePackageItem: (params: any) => void;
  packageItem: any;
  currentProductInPackagesQty: Record<string, number>;
  onSplitingPackage: (params: IHandleSplitingPackage) => void;
  onPrintSaleOrdersPackagesLabelApi: (params: IPackagePrinting) => void;
  onPrintSaleOrdersProductsListPackagesApi: (params: IPackagePrinting) => void;
  onDeleteSaleOrderPackage: (params: string) => void;
  allProducts: IProductSaleOrder[];
  isInteractable: boolean;
}) => {
  const [multiplier, setMultiplier] = useState(1);
  const [isShowCloneConfirmationModal, setIsShowCloneConfirmationModal] =
    useState(false);
  const [isShowDeleteConfirmationModal, setIsShowDeleteConfirmationModal] =
    useState(false);

  const productQtyOfSinglePackage = getProductQtyOfSinglePackage(
    packageItem?.package_products
  );

  const handleClonePackageItem = () => {
    const numPackages = multiplier || 1;

    const mappedTotalQtyProducts = new Map<string, number>();

    allProducts?.forEach((product) => {
      const { gtin_code, quantity } = product || {};

      if (!mappedTotalQtyProducts.has(gtin_code as string)) {
        mappedTotalQtyProducts.set(gtin_code as string, quantity as number);
      } else {
        const accumulatedQty =
          (mappedTotalQtyProducts.get(gtin_code as string) || 0) +
          (quantity as number);

        mappedTotalQtyProducts.set(gtin_code as string, accumulatedQty);
      }
    });

    const remainingQtyOfAllProducts = Array.from(mappedTotalQtyProducts)?.map(
      ([gtin_code, quantity]) => {
        return {
          gtin_code,
          quantity:
            quantity -
            (currentProductInPackagesQty?.[gtin_code as string] || 0),
        };
      }
    );

    const arrProductQtyOfSinglePackage = Object.entries(
      productQtyOfSinglePackage
    );

    const isCurrentRemainingSelectedProductEnoughForNewPackage =
      arrProductQtyOfSinglePackage?.every(
        ([cloningGtinCode, cloningQuantity]) => {
          const foundProductIndex: number =
            remainingQtyOfAllProducts?.findIndex((remainingProduct) => {
              return remainingProduct?.gtin_code === cloningGtinCode;
            });

          if (foundProductIndex === -1) {
            return false;
          }

          return (
            (remainingQtyOfAllProducts[foundProductIndex]
              ?.quantity as number) >=
            cloningQuantity * numPackages
          );
        }
      );

    if (!isCurrentRemainingSelectedProductEnoughForNewPackage) {
      alert.warn("The selected Product not enough for adding the new package!");
      return;
    }

    const saleOrderMaterials = packageItem?.sale_order_materials?.map(
      (material: any) => ({
        quantity: material?.quantity,
        code: material?.code,
      })
    );

    const formatedSubmitProduct = packageItem?.package_products?.map(
      (packageProduct: any) => ({
        gtin_code: packageProduct?.product?.gtin_code,
        quantity: packageProduct?.quantity,
      })
    );

    for (let i = 0; i < numPackages; i++) {
      onSplitingPackage({
        materialsArr: saleOrderMaterials,
        splitingProducts: formatedSubmitProduct,
      });
    }
  };

  return (
    <div>
      <QuickActionPopupConfirmation
        content={`Are you sure to clone this package ${multiplier} ${
          multiplier === 1 ? "time" : "times"
        } ?`}
        confirmationButton="Confirm"
        isShowModal={isShowCloneConfirmationModal}
        variant={GENERAL_UI_COLOR.DARK}
        textColor="#344767"
        handleCloseModal={() => {
          setIsShowCloneConfirmationModal(false);
        }}
        onConfirm={handleClonePackageItem}
      />
      <QuickActionPopupConfirmation
        content={`Are you sure to remove this package?`}
        confirmationButton="Confirm"
        isShowModal={isShowDeleteConfirmationModal}
        handleCloseModal={() => {
          setIsShowDeleteConfirmationModal(false);
        }}
        onConfirm={() => {
          onDeleteSaleOrderPackage(packageItem?.code);
        }}
      />

      <div className={classNames(styles.packageList, "")}>
        <div className={styles.packageItem}>
          <section className="d-flex justify-content-between">
            <div className="d-flex gap-2 align-items-center">
              <span className="badge badge-secondary text-white text-xl align-self-start mx-1">
                {index + 1}
              </span>

              <Typography customFontsize={14} fontWeight="fw-bold">
                {packageItem?.code}
              </Typography>
            </div>
            <Typography customFontsize={18} fontWeight="fw-bold">
              {packageItem?.number_of_items}
            </Typography>
          </section>

          <hr className="horizontal dark mt-2" />
          <section className={styles.info}>
            <div className="col-2">
              <Typography customFontsize={12} fontWeight="fw-bold">
                Materials :
              </Typography>
            </div>
            <div className="col-10">
              {packageItem?.sale_order_materials?.map((material: any) => (
                <div
                  key={material?.code}
                  className={classNames(
                    "d-flex gap-3 p-2 align-items-center",
                    styles.materialChip
                  )}
                >
                  <div className="">
                    <div>{material?.name}</div>
                    <Typography>{`#${material?.code}`}</Typography>
                  </div>
                  <div className="badge badge-danger">{material?.quantity}</div>
                </div>
              ))}
            </div>
          </section>
          <section className={styles.info}>
            <div className="col-2">
              <Typography customFontsize={12} fontWeight="fw-bold">
                Products :
              </Typography>
            </div>
            <div className={classNames(styles.products, "col-10")}>
              {packageItem?.package_products?.map((packageProduct: any) => {
                return (
                  <>
                    <div
                      className={styles.productItem}
                      key={packageProduct?.uuid}
                    >
                      <Avatar
                        imageSrc={packageProduct?.product?.image_url}
                        preview={true}
                      />
                      <div className={styles.name}>
                        <div className="fw-bold">
                          {htmlStripTag(packageProduct?.product?.name)}
                        </div>
                        <div>
                          {htmlStripTag(packageProduct?.product?.gtin_code)}
                        </div>
                      </div>
                      <div className={styles.quantity}>
                        {packageProduct?.quantity}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </section>
          <section className="d-flex gap-1 align-items-center justify-content-between  my-2 ">
            <div className="d-flex align-items-center gap-3 me-3">
              <UiTooltip
                position="bottomLeft"
                tooltip={
                  <Typography className="d-flex align-items-center ">
                    <div className="text-dark ms-1">Print Product List</div>
                  </Typography>
                }
                className={{
                  mainContent: "text-right",
                  floatingContent: styles.floatingContent,
                }}
              >
                <UiButton
                  className={styles.createPackageAction}
                  customType="button"
                  size={GENERAL_UI_SIZE.SMALL}
                  title={
                    <span className="material-icons s-16 d-flex">
                      receipt_long
                    </span>
                  }
                  onClick={() => {
                    onPrintSaleOrdersProductsListPackagesApi({
                      packageCode: packageItem?.code,
                    });
                  }}
                  type={GENERAL_UI_COLOR.LIGHT}
                />
              </UiTooltip>
              <UiTooltip
                position="bottomLeft"
                tooltip={
                  <Typography className="d-flex align-items-center ">
                    <div className="text-dark ms-1">Print Package Label</div>
                  </Typography>
                }
                className={{
                  mainContent: "text-right",
                  floatingContent: styles.floatingContent,
                }}
              >
                <UiButton
                  className={styles.createPackageAction}
                  customType="button"
                  size={GENERAL_UI_SIZE.SMALL}
                  title={
                    <span className="material-icons s-16 d-flex">loyalty</span>
                  }
                  onClick={() => {
                    onPrintSaleOrdersPackagesLabelApi({
                      packageCode: packageItem?.code,
                    });
                  }}
                  type={GENERAL_UI_COLOR.LIGHT}
                />
              </UiTooltip>
            </div>
            <div className="d-flex gap-1 align-items-center justify-content-between  my-2 w-25">
              <div className={classNames(styles.actionInput)}>
                <input
                  min={1}
                  value={multiplier}
                  onChange={(e) => {
                    setMultiplier(Number(e.target.value));
                  }}
                  className={styles.inputMutiply}
                  type="number"
                  required
                />
              </div>
              <Typography className="mx-2" fontWeight="fw-bolder">
                X
              </Typography>
              <UiButton
                className={styles.createPackageAction}
                customType="button"
                size={GENERAL_UI_SIZE.SMALL}
                title="Clone"
                disabled={!isInteractable}
                onClick={() => {
                  setIsShowCloneConfirmationModal(true);
                }}
                type={GENERAL_UI_COLOR.DARK}
              />
              <UiButton
                customType="button"
                size={GENERAL_UI_SIZE.SMALL}
                title={<i className="fa-solid fa-trash"></i>}
                disabled={!isInteractable}
                onClick={() => {
                  setIsShowDeleteConfirmationModal(true);
                }}
                type={GENERAL_UI_COLOR.DANGER}
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ViewCardPackage;
