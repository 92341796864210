import DateTimeTableCell from "../../components/table-cell-date-time";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import { TASK_LOGGING_LIST } from "../../routings/path";

export const taskSchema = [
  {
    name: "Picking task",
    cell: (data: any) => (
      <ProductTableCell
        linkTo={`${TASK_LOGGING_LIST}/${data?.uuid}`}
        title={data?.name}
        description={data?.detail_name}
        maxWidth={400}
      />
    ),
  },
  {
    name: "priority",
    align: "center",
    cell: (data: any) => <StatusTableCell status={data?.priority} />,
  },
  {
    name: "Created",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data?.created_at} />,
  },
  {
    name: "In Progress",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data?.in_progress_at} />,
  },
  {
    name: "Status",
    align: "right",
    cell: (data: any) => <StatusTableCell status={data?.status} />,
  },
];
