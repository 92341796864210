import React, { useEffect, useState } from "react";
import classNames from "classnames";

import { ILinkForm } from "../../types/tpl";
import { GENERAL_UI_COLOR } from "../../constants/color";
import SelectDropdown from "../../components/ui-select-dropdown/component";
import UiButton from "../../components/ui-button";
import GridLayoutTwoColumn from "../../components/grid-layout-two-column/component";

import { LINK_TYPES, LOCATIONS } from "./constants";
import styles from "./style.module.scss";
import InputNote from "./input-note";
import DynamicTplSelectDropdown from "./view-dynamic-tpl-select-dropdown";

const EditTplFormItem = ({
  register,
  setValue,
  errors,
  keyIndex,
  setFormItemComponent,
  data,
  formItem,
}: {
  register: any;
  errors: any;
  data: any;
  setValue?: any;
  keyIndex: number | string;
  setFormItemComponent: any;
  formItem?: any;
}) => {
  const [selectedLinkType, setSelectedLinkType] = useState<string | null>(
    formItem?.linked_type || "Channel"
  );

  useEffect(() => {
    if (formItem?.linked_type) {
      setSelectedLinkType(formItem?.linked_type);
      return;
    } else if (formItem?.linked_object_type) {
      setSelectedLinkType(formItem?.linked_object_type);
    }
  }, [formItem]);

  const handleRemoveFormItem = () => {
    setValue(`links[${keyIndex}].linked_values`, "");
    setFormItemComponent((prevComponents: any[]) => {
      return [
        ...prevComponents.filter(
          (prevComponent) => prevComponent?.props?.keyIndex !== keyIndex
        ),
      ];
    });
  };

  const handleChange = (value: any) => {
    setSelectedLinkType(value);
  };

  useEffect(() => {
    if (formItem?.linked_type) {
      const formatLinkedValues = (formItem: ILinkForm) =>
        formItem?.linked_values?.map((form: any) => ({
          id: keyIndex,
          note: form,
        }));
      setValue(`links[${keyIndex}].linked_type`, formItem?.linked_type);
      setValue(
        `links[${keyIndex}].linked_values`,
        formatLinkedValues(formItem)
      );
    } else if (formItem?.linked_object_type) {
      setValue(`links[${keyIndex}].linked_type`, formItem?.linked_object_type);
      setValue(
        `links[${keyIndex}].linked_values`,
        formItem?.linked_object_uuids
      );
    }
  }, [formItem]);

  if (!data) return null;

  return (
    <div
      key={keyIndex}
      className={classNames(
        "d-flex justify-content-center align-items-center mb-4",
        styles.formRoutingItem
      )}
    >
      <GridLayoutTwoColumn
      widthDivision={[4,8]}
        leftCol={
          <div className="ms-2">
            <SelectDropdown
              name={`links[${keyIndex}].linked_type`}
              title="Scope Type"
              isDisplayLabel
              register={register}
              setValue={setValue}
              optionList={LOCATIONS}
              errorMessage={errors?.linked_type?.message}
              onChange={handleChange}
            />
          </div>
        }
        rightCol={
          LINK_TYPES.includes(selectedLinkType || ("Channel" as string)) ? (
            <InputNote
              formItem={formItem}
              setValue={setValue}
              keyIndex={keyIndex as string}
            />
          ) : (
            <DynamicTplSelectDropdown
              name={`links[${keyIndex}].linked_values`}
              data={data}
              formItem={formItem}
              selectedLinkType={selectedLinkType as string}
              setValue={setValue}
            />
          )
        }
      />
      <UiButton
        className="mx-2 mt-2 p-2"
        customType="button"
        type={GENERAL_UI_COLOR.LIGHT}
        onClick={handleRemoveFormItem}
        title={<i className="fas fa-trash " aria-hidden="true"></i>}
      />
    </div>
  );
};

export default EditTplFormItem;
