import classNames from "classnames";
import React  from "react";

import { GENERAL_UI_SIZE } from "../../constants/size";
import { GENERAL_UI_COLOR } from "../../constants/color";

interface IHeadingProps {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  children: string | React.ReactNode;
  textSize?: GENERAL_UI_SIZE,
  textColor?: GENERAL_UI_COLOR,
  handleClick?: () => void;
  className?: string;
  fontWeight?: "bold" | "bolder" | "normal" | "light" | "lighter";
  decoration?: "underline" | "line-through" | "none";
}

const Heading = ({
  className,
  handleClick,
  children,
  level = 1,
  fontWeight = "light",
  decoration = "none",
  textSize,
  textColor = GENERAL_UI_COLOR.DARK,
}: IHeadingProps) => {

  const TagName = `h${level}` as keyof JSX.IntrinsicElements;

  return (
    <TagName
      onClick={handleClick}
      className={classNames(
        className,
        handleClick ? "pe-auto" : "pe-none",
        fontWeight ? `fw-${fontWeight}` : "",
        decoration ? `text-decoration-#{decoration}` : "",
        textColor ? `text-${textColor}` : "",
        textSize ? `text-${textSize}` : ""
      )}
    >
      {children}
    </TagName>
  );
};

export default Heading;
