import React, { useEffect, useState } from "react";
import { nanoid } from "nanoid";
import classNames from "classnames";

import { IFormatedFormItem, IInputNote, ILinkForm } from "../../../types/tpl";

import styles from "./styles.module.scss";
import NotePosting from "./view-note-posting";

const InputNote = ({ formItem, setValue, keyIndex }: IInputNote) => {
  const formatLinkedValues = (formItem: ILinkForm) =>
    formItem?.linked_values?.map((form: any) => {
      const initialIndex = nanoid(10);
      return {
        id: initialIndex,
        note: form,
      };
    });

  const [linkedValues, setLinkedValue] = useState<IFormatedFormItem[]>(
    formatLinkedValues(formItem) || []
  );

  useEffect(() => {
    if (formItem?.linked_type) {
      setValue(`links[${keyIndex}].linked_values`, linkedValues);
    }
  }, [linkedValues]);

  useEffect(() => {
    setLinkedValue((formatLinkedValues(formItem) as IFormatedFormItem[]) || []);
  }, [formItem]);

  return (
    <div className={classNames("col-12 ", styles.container)}>
      <div className="h-100">
        <div className="my-2 mb-1">
          {linkedValues?.map(({ note, id }) => {
            return (
              <div key={id}>
                <NotePosting key={id} note={note} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default InputNote;
