export const DEFAULT_PER_PAGE = 25;
export const DEFAULT_PAGINATION_PARAMS = {
  page: 1,
  per: DEFAULT_PER_PAGE,
  keyword: "",
  sort: null,
  filter: null,
};

export const ITEM_PER_PAGE_LIST = [25, 50];
