import React from "react";
import { Button } from "react-bootstrap";

import UiTextArea from "../../components/ui-text-area/component";
import PopupConfirmation from "../popup-confirmation/component";
import Typography from "../ui-typography/component";

interface IDeleteConfirmationPopupProps {
  content: string | React.ReactNode;
  isShowModal: boolean;
  handleCloseModal?: () => void;
  onDelete: () => void;
  confirmationButton?: string;
  note?: string;
  setNote?: any;
  isNotable?: boolean;
}

const QuickActionPopupConfirmation = ({
  isShowModal,
  handleCloseModal,
  onDelete,
  content,
  confirmationButton = "Delete",
  note,
  setNote,
  isNotable = false,
}: IDeleteConfirmationPopupProps) => {
  return (
    <PopupConfirmation
      isShowModal={isShowModal}
      onCloseModal={handleCloseModal}
    >
      <div>
        <Typography
          fontSize="fs-5"
          fontWeight="fw-normal"
          textColor="#f5365c"
          className="p-4 ps-3 pe-3"
        >
          <i className="fa-solid fa-circle-exclamation me-2"></i>
          {content}
        </Typography>
        {isNotable && (
          <UiTextArea
            title={"Note"}
            name={"note"}
            placeholder="Write your note"
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
            rows={4}
          />
        )}
        <div className="d-flex mt-3 justify-content-end">
          <Button
            className="mb-2 me-3"
            variant=""
            size="sm"
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            className="mb-2 bg-gradient-danger"
            variant="danger"
            onClick={() => {
              handleCloseModal && handleCloseModal();
              onDelete && onDelete();
            }}
          >
            {confirmationButton}
          </Button>
        </div>
      </div>
    </PopupConfirmation>
  );
};

export default QuickActionPopupConfirmation;
