import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { nanoid } from "nanoid";

import UiInput from "../../../components/ui-input";
import { IFormatedFormItem, IInputNote, ILinkForm } from "../../../types/tpl";

import NotePosting from "./view-note-posting";

const InputNote = ({ formItem, setValue, keyIndex }: IInputNote) => {
  const subKeyIndex = nanoid(10);

  const formatLinkedValues = (formItem: ILinkForm) =>
    formItem?.linked_values?.map((form: any) => {
      const initialIndex = nanoid(10);
      return {
        id: initialIndex,
        note: form,
      };
    });

  const [linkedValues, setLinkedValue] = useState<IFormatedFormItem[]>(
    formatLinkedValues(formItem) || []
  );

  useEffect(() => {
    if (formItem?.linked_type) {
      setValue(`links[${keyIndex}].linked_values`, linkedValues);
    }
  }, [linkedValues]);

  useEffect(() => {
    setLinkedValue((formatLinkedValues(formItem) as IFormatedFormItem[]) || []);
  }, [formItem]);

  const { register, getValues, reset } = useForm({ mode: "onBlur" });

  const handleAddNote = () => {
    const result = getValues();

    if (!result.comment.toString()) return;

    setLinkedValue((prevState: any) => {
      setValue(`links[${keyIndex}].linked_values`, [
        ...prevState,
        {
          id: subKeyIndex,
          note: result.comment.toString(),
        },
      ]);

      return [
        ...prevState,
        {
          id: subKeyIndex,
          note: result.comment.toString(),
        },
      ];
    });

    reset({ comment: "" });
  };

  return (
    <div className="col-12 my-2">
      <div className="card h-100">
        <div className="mx-2">
          <div className="mb-1 mt-3 ">
            {linkedValues?.map(({ note, id }) => {
              return (
                <>
                  <div key={id}>
                    <NotePosting
                      key={id}
                      note={note}
                      parentkeyIndex={keyIndex}
                      setLinkedValue={setLinkedValue}
                      keyIndex={id}
                      parentSetValue={setValue}
                    />
                  </div>
                  <hr className="horizontal dark mt-0 " />
                </>
              );
            })}

            <div className="d-flex">
              <div className="flex-grow-1 my-auto">
                <UiInput
                  className="dataTable-input"
                  name={"comment"}
                  title={"Values"}
                  placeholder="Write your comment"
                  isDisplayLabel={false}
                  register={register}
                  onBlur={handleAddNote}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputNote;
