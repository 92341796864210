import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";

import { persistor } from "../../states/store";
import { IAccount } from "../../states/user.flow";

import * as R from "../../routings/path";
import UiAvatar from "../../components/ui-avatar/component";
import UiDropdown from "../../components/ui-dropdown/component";

interface IViewAccountInfo {
  account: IAccount | null | undefined;
  mySession: any;
  setIsShowModal: (params: boolean) => void;
}

const ViewAccountInfo = ({
  account,
  mySession,
  setIsShowModal,
}: IViewAccountInfo) => {
  const navigate = useNavigate();
  const { avatar, name, uuid } = account || {};

  return (
    <UiDropdown
      title={
        <div className="d-inline-flex align-items-center">
          <div>
            <UiAvatar
              imageSrc={avatar}
              name={name}
              handleClick={() => {}}
              statusDot={{
                workMode: mySession?.work_mode,
                outsideUntil: mySession?.outside_until,
              }}
            />
          </div>
          <div>
            <i className="ni ni-circle-08 me-2"></i>
            {name}
          </div>
        </div>
      }
      textColor="white"
      variant="transparent"
      size="sm"
      className={{ container: styles.dropdownItem }}
    >
      <Dropdown.Item href={`${R.ACCOUNT_LIST}/${uuid}/edit`}>
        <i className="fa-solid fa-user p-2  w-25"></i>
        About me
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          setIsShowModal(true);
        }}
      >
        <i className="fa-solid fa-plug p-2 w-25"></i>
        Update Status
      </Dropdown.Item>
      <Dropdown.Item href={`${R.ACCOUNT_LIST}/${uuid}/edit/devices`}>
        <i className="fa-solid fa-laptop p-2 w-25"></i>
        Manage Devices
      </Dropdown.Item>
      <Dropdown.Item href={`${R.ACCOUNT_LIST}/${uuid}/edit/reset-password`}>
        <i className="fa-solid fa-key p-2 w-25"></i>
        Reset Password
      </Dropdown.Item>
      <Dropdown.Item
        onClick={async () => {
          await persistor.purge();
          navigate(0);
        }}
      >
        <i className="fa-solid fa-right-from-bracket p-2  w-25"></i>
        Log out
      </Dropdown.Item>
    </UiDropdown>
  );
};

export default ViewAccountInfo;
