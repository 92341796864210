import React from "react";

import { useEffect, useState } from "react";

import { productSchema } from "../schema/productSchema";
import { IRelocationShipmentProduct } from "../../../apis/paths/relocationShipment";
import { getProductListApi } from "../../../apis/paths/products";
import { transferArrayToObject } from "../../../utils/data-transformation";
import { useExtractProductFromCsvFile } from "../../../hooks/useExtractProductFromCsvFile";
import { PATH_TO_PURCHASE_ORDER_SAMPLE_FILE } from "../../../constants/staticFile";

import styles from "../styles.module.scss";
import Typography from "../../../components/ui-typography/component";
import TablePanel from "../../../components/panel-table";
import FileImporter from "../../../components/ui-file-uploader/component";
import DownloadButton from "../../../components/ui-download-button/component";
import SelectedProduct from "../view-selected-product";
import TwoColumnGridLayout from "../../../components/grid-layout-two-column";

interface IViewStep4 {
  setValue: any;
}

const ViewStep4 = ({ setValue }: IViewStep4) => {
  const [inputingProductQty, setInputingProductQty] = useState<any>({});
  const [selectedProducts, setSelectedProducts] = useState<any>([]);

  const { fetchProductFromCsvFile, extractedProduct } =
    useExtractProductFromCsvFile();

  useEffect(() => {
    const { products } = extractedProduct || {};
    if (Array.isArray(products) && products?.length > 0) {
      setSelectedProducts(products);
      setInputingProductQty(
        transferArrayToObject(products, "uuid", "quantity")
      );
    }
  }, [fetchProductFromCsvFile, extractedProduct]);

  useEffect(() => {
    if (Array.isArray(selectedProducts) && selectedProducts.length > 0) {
      const formatedRelocationShipmentProduct: IRelocationShipmentProduct[] =
        selectedProducts?.map((product) => ({
          product_uuid: product?.uuid,
          product_gtin_code: product?.gtin_code,
          quantity: product?.quantity,
        }));
      setValue(
        "relocation_shipment_products",
        formatedRelocationShipmentProduct as IRelocationShipmentProduct[]
      );
    }
  }, [selectedProducts]);

  const onUploadCsvFile = async (file: any) => {
    await fetchProductFromCsvFile(file);
  };

  const handleAddProduct = (newProduct: any) => {
    const isExist = selectedProducts.some(
      (product: any) => product.uuid === newProduct.uuid
    );
    if (isExist) return;

    setSelectedProducts([
      {
        ...newProduct,
        quantity: inputingProductQty?.[newProduct?.uuid] || 1,
      },
      ...selectedProducts,
    ]);
  };

  const handleDeleteProduct = (deletedProduct: any) => {
    const newProducts = selectedProducts.filter(
      (product: any) => product.uuid !== deletedProduct.uuid
    );
    setSelectedProducts(newProducts);
  };

  const handleUpdateProduct = (updatedProduct: any) => {
    const newProducts = selectedProducts.map((product: any) =>
      product.uuid !== updatedProduct.uuid ? product : updatedProduct
    );

    setSelectedProducts(newProducts);
  };

  return (
    <TwoColumnGridLayout
      widthDivision={[7, 5]}
      leftCol={
        <>
          <FileImporter
            className={{
              container: "d-flex w-100 justify-content-start",
              label: "",
              input: "w-100",
            }}
            onFileUpload={(value) => {
              onUploadCsvFile(value);
            }}
          />
          <DownloadButton
            href={PATH_TO_PURCHASE_ORDER_SAMPLE_FILE}
            className={{ container: "mt-1" }}
            downloadTitle="Saleorder_products"
            title={
              <Typography
                customFontsize={10}
                fontWeight="fw-bold"
                className="d-flex align-items-center gap-2"
              >
                <i className="fa-solid fa-download fa-sm"></i>
                Download sample CSV
              </Typography>
            }
          />
          <Typography fontWeight="fw-bold" className="my-2 text-center">
            Or
          </Typography>
          <TablePanel
            apiPath={getProductListApi}
            panelClassName={"mt-0"}
            type={"minimal"}
            tableSchema={productSchema({
              onSelectProduct: handleAddProduct,
              selectedProducts,
              onChangeProductQty: setInputingProductQty,
              inputingProductQty,
            })}
          />
        </>
      }
      rightCol={
        <div className={styles.selectProduct}>
          <SelectedProduct
            label={"Selected Product"}
            products={selectedProducts}
            onDeleteItem={handleDeleteProduct}
            onUpdateItem={handleUpdateProduct}
          />
        </div>
      }
    />
  );
};

export default ViewStep4;
