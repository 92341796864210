import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getSaleOrderForPickingListApi } from "../../apis/paths/picking";
import { saleOrderCreatingDynamicFilter } from "../../constants/dynamic-filter";
import { useApiCaller, apiCaller } from "../../apis/config";
import { GENERAL_UI_SIZE } from "../../constants/size";
import {
  getPickingDetailApi,
  updatePickListSaleorderApi,
  updatePickingStatusApi,
} from "../../apis/paths/picking";
import alert from "../../utils/alert";
import * as R from "../../routings/path";
import UiButton from "../../components/ui-button";
import TablePanel from "../../components/panel-table";
import HeaderInfoPanel from "../../components/panel-header-info";
import UiStatusDropdown from "../../components/ui-status-dropdown";
import TableCellStatus from "../../components/table-cell-status";
import useHandleSidebarStatus from "../../hooks/useSidebarStatus";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";

import { saleOrdersSchema, selectedSaleOrdersSchema } from "./schema";
import styles from "./styles.module.scss";
import SelectedSaleOrder from "./view-selected-sale-order";
import PanelGeneral from "../../components/panel-general/component";

const Component = () => {
  const [pageReloadVersion, setPageReloadVersion] = useState(0);
  const [selectedSaleOrders, setSelectedSaleOrders] = useState<any>([]);

  const navigate = useNavigate();

  const { pickingId: uuid } = useParams();

  const [params] = useState({ uuid });

  const { result } = useApiCaller({
    api: getPickingDetailApi,
    params,
    pageReloadVersion,
  });

  const pickingDetail = result?.data || {};

  const handleSelectSaleOrder = (saleOrder: any) => {
    setSelectedSaleOrders([saleOrder, ...selectedSaleOrders]);
  };

  useEffect(() => {
    if (!!pickingDetail) {
      const saleOrderDetail = pickingDetail?.sale_orders || [];
      setSelectedSaleOrders(saleOrderDetail);
    }
  }, [result]);

  const { dispatchSidebarStatus } = useHandleSidebarStatus();
  useEffect(() => {
    dispatchSidebarStatus({ isOpened: false });

    return () => {
      dispatchSidebarStatus({ isOpened: true });
    };
  }, []);

  const handleRemoveSaleOrder = (deletedSaleOrder: any) => {
    const newSaleOrder = selectedSaleOrders?.filter(
      (saleOrder: any) => saleOrder.uuid !== deletedSaleOrder.uuid
    );

    setSelectedSaleOrders(newSaleOrder);
  };

  const handleSubmit = async () => {
    const waitUpdatePickingSaleOrder = await apiCaller({
      api: updatePickListSaleorderApi,
      params: {
        uuid,
        saleOrderUuids: selectedSaleOrders.map(
          (saleOrder: any) => saleOrder.uuid
        ),
      },
    });
    if (waitUpdatePickingSaleOrder.status === "SUCCESS") {
      alert.success("Update successful");
      navigate(`${R.PICKINGS_LIST}/${uuid}`);
    }
  };

  const isEditable = pickingDetail?.status === "draft";

  return (
    <>
      <div className="mb-4">
        <HeaderInfoPanel
          title={`#${pickingDetail?.code}`}
          infoArea={() => (
            <div className={styles.headerInfoArea}>
              <TableCellStatus
                className="me-2"
                status={pickingDetail?.status}
                size={GENERAL_UI_SIZE.SMALL}
              />
            </div>
          )}
          actionArea={() => (
            <>
              {pickingDetail?.status && (
                <UiStatusDropdown
                  status={pickingDetail?.status}
                  triggerableEvents={pickingDetail?.triggerable_events}
                  actionApi={updatePickingStatusApi}
                  paramsTransformation={(newEvent: string) => ({
                    uuid: uuid,
                    action: newEvent,
                  })}
                  onChangeSuccess={(newData: any) => {
                    setPageReloadVersion((prev) => prev + 1);
                  }}
                />
              )}
              <UiButton
                disabled={!isEditable}
                className="me-2"
                title={"Update"}
                onClick={handleSubmit}
              />
            </>
          )}
        />
      </div>
      <PanelGeneral className="pt-4">
        <TwoColumnGridLayout
          widthDivision={[7, 5]}
          leftCol={
            <TablePanel
              apiPath={getSaleOrderForPickingListApi}
              panelClassName={"mt-0"}
              type={"minimal"}
              tableSchema={saleOrdersSchema({
                selectedSaleOrders,
                onSelectSaleOrder: handleSelectSaleOrder,
                isEditable,
              })}
              dynamicFilter={saleOrderCreatingDynamicFilter}
            />
          }
          rightCol={
            <SelectedSaleOrder
              saleOrders={selectedSaleOrders}
              schema={selectedSaleOrdersSchema({
                onRemoveSaleOrder: handleRemoveSaleOrder,
                isEditable,
              })}
            />
          }
        />
      </PanelGeneral>
    </>
  );
};

export default Component;
