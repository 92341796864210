import * as R from "../../routings/path";
import { TABLE_ACTION } from "../../constants/table-action";
import DateTimeTableCell from "../../components/table-cell-date-time";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import WarehouseTableCell from "../../components/table-cell-warehouse";
import ActionTableCell from "../../components/table-cell-action";
import NumberTableCell from "../../components/table-cell-number";

export const schema = () => [
  {
    name: "Number",
    cell: (data: any) => (
      <ProductTableCell
        title={`#${data?.code}`}
        linkTo={`${R.PICKINGS_LIST}/${data?.uuid}`}
        maxWidth={180}
        minWidth={180}
      />
    ),
  },
  {
    name: "Sale Orders Qty",
    align: "center",
    textSize: "xs",
    cell: (data: any) => (
      <NumberTableCell
        value={data?.total_sale_orders}
        className={{ content: "fs-5 fw-bold" }}
      />
    ),
  },
  {
    name: "Products Qty",
    align: "center",
    textSize: "xs",
    cell: (data: any) => (
      <NumberTableCell
        value={data?.product_item_count}
        className={{ content: "fs-5 fw-bold" }}
      />
    ),
  },
  {
    name: "Warehouse",
    cell: (data: any) => {
      return (
        <WarehouseTableCell
          warehouse={data}
        />
      );
    },
  },
  {
    name: "Created",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => (
      <DateTimeTableCell
        timeStamp={data?.created_at}
        formatTimestamp={{
          time: "HH:mm YYYY-MM-DD",
          tooltip: "HH:mm YYYY-MM-DD",
        }}
      />
    ),
  },
  {
    name: "Status",
    align: "center",
    cell: (data: any) => <StatusTableCell status={data?.status} />,
  },
  {
    name: "Action",
    align: "right",
    cell: (data: any) => (
      <ActionTableCell
        actions={data.instance_actionables}
        viewLink={`${R.PICKINGS_LIST}/${data?.uuid}`}
        editLink={`${R.PICKINGS_LIST}/${data?.uuid}/edit`}
        types={[TABLE_ACTION.VIEW, TABLE_ACTION.EDIT]}
      />
    ),
  },
];
