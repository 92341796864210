import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IAccount {
  avatar: string;
  name: string;
  username: string;
  uuid: string;
}
export interface ILoginInfoTypes {
  access_token: string | null;
  account?: IAccount | null;
}

export interface userStateType {
  loginInfo: ILoginInfoTypes;
}

const initialState: userStateType = {
  loginInfo: {
    access_token: null,
    account: null,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateLoginInfoAction: (state, action: PayloadAction<ILoginInfoTypes>) => {
      state.loginInfo = action.payload;
    },
  },
});

export const { updateLoginInfoAction } = userSlice.actions;
export default userSlice.reducer;
