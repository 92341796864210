import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as R from "../../routings/path";
import { apiCaller, useApiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { GENERAL_UI_COLOR } from "../../constants/color";
import alert from "../../utils/alert";
import QuickActionPopupConfirmation from "../../components/popup-quick-aciton-confirmation/component";
import SmallCollumnCenterGridLayout from "../../components/grid-layout-small-collumn-center";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import HeaderInfoPanel from "../../components/panel-header-info";
import NotFoundPanel from "../../components/panel-not-found";
import UiLoadingPanel from "../../components/ui-loading-panel";
import GeneralPanel from "../../components/panel-general";
import UiButton from "../../components/ui-button";
import UiInput from "../../components/ui-input";
import UiSelectDropdown, {
  IOptionItem,
} from "../../components/ui-select-dropdown/component";

import { schema } from "./schema";
import { BOX_TYPE, IOption, SIZE } from "./constants";
import ImageUploader from "../../components/ui-image-uploader/component";
import {
  IUpdateMaterialApi,
  deleteMaterialApi,
  getMaterialDetailApi,
  updateMaterialApi,
} from "../../apis/paths/material";

const Component = () => {
  const [isShowModal, setIsShowModal] = useState(false);

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const handleOpenModal = () => {
    setIsShowModal(true);
  };

  const handleCloseModal = () => {
    setIsShowModal(false);
  };

  const { materialId: code } = useParams();
  const [params] = useState({ code });

  const { result, loading } = useApiCaller({
    api: getMaterialDetailApi,
    params: params,
  });

  const materialInfo = result?.data;
  const uploadImage: any = watch("upload_image");

  useEffect(() => {
    if (!result || result?.status === "ERROR") return;

    const materialInfo = result?.data;

    const keys = Object.keys(schema?.fields);
    keys.forEach((key: any) => {
      if (key !== "upload_image") setValue(key, materialInfo[key]);
    });
  }, [result]);

  const onSubmit = async ({
    code,
    name,
    size,
    box_type,
    width,
    height,
    length,
  }: any) => {
    const waitUpdateMaterial = await apiCaller({
      api: updateMaterialApi,
      params: {
        code,
        name,
        size,
        box_type,
        width,
        height,
        length,
        image_url: materialInfo?.image_url,
        upload_image: uploadImage?.[0]?.file,
      } as IUpdateMaterialApi,
    });

    if (waitUpdateMaterial.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update successful!");
      setTimeout(() => {
        navigate(0);
      }, 500);

      return;
    }

    alert.error("Update was not successful!. Please try again!");
  };

  const handleDeleteMaterial = async () => {
    const waitDelete = await apiCaller({
      api: deleteMaterialApi,
      params: { code: materialInfo?.code },
    });

    if (waitDelete.status === API_RESPONSE_STATUS.SUCCESS) {
      setTimeout(() => {
        alert.success("Delete successful!");
      }, 500);
      navigate(R.MATERIAL_LIST);
    }
  };

  const formatOptionItems = (data: IOption[]) =>
    data.map((option) => ({
      value: option.value,
      content: option.title,
    }));

  if (loading) return <UiLoadingPanel />;

  if (result?.status === "ERROR") return <NotFoundPanel />;

  return (
    <>
      <HeaderInfoPanel
        title={materialInfo?.name}
        image={materialInfo?.image_url}
        actionArea={() => (
          <>
            <UiButton
              onClick={handleSubmit(onSubmit)}
              className="me-2"
              title={"Save Material"}
            />
            <UiButton
              type={GENERAL_UI_COLOR.DANGER}
              onClick={handleOpenModal}
              title={"Delete Material"}
            />
          </>
        )}
      />
      <SmallCollumnCenterGridLayout>
        <GeneralPanel panel={{ subTitle: "Material Information" }}>
          <TwoColumnGridLayout
            className={{ container: "px-0" }}
            leftCol={
              <>
                <UiInput
                  title={"Name"}
                  name={"name"}
                  register={register}
                  errorMessage={errors?.name?.message}
                />
                <UiSelectDropdown
                  name="size"
                  title="Size"
                  isDisplayLabel
                  register={register}
                  optionList={formatOptionItems(SIZE) as IOptionItem[]}
                  errorMessage={errors?.size?.message}
                />
                <UiSelectDropdown
                  name="box_type"
                  title="Box type"
                  isDisplayLabel
                  register={register}
                  optionList={formatOptionItems(BOX_TYPE) as IOptionItem[]}
                  errorMessage={errors?.box_type?.message}
                />
              </>
            }
            rightCol={
              <>
                <UiInput
                  title={"Height"}
                  name={"height"}
                  register={register}
                  errorMessage={errors?.height?.message}
                />
                <UiInput
                  title={"Length"}
                  name={"length"}
                  register={register}
                  errorMessage={errors?.length?.message}
                />
                <UiInput
                  title={"Width"}
                  name={"width"}
                  register={register}
                  errorMessage={errors?.width?.message}
                />
                <ImageUploader
                  isDisplayLabel
                  title="Image Upload File"
                  isDisplayRemoveAll={false}
                  name="upload_image"
                  setValue={setValue}
                  errorMessage={errors?.upload_image?.message}
                />
              </>
            }
          />
        </GeneralPanel>
      </SmallCollumnCenterGridLayout>
      <QuickActionPopupConfirmation
        content={"Are you sure to delete this material ?"}
        isShowModal={isShowModal}
        handleCloseModal={handleCloseModal}
        onConfirm={handleDeleteMaterial}
      />
    </>
  );
};

export default Component;
