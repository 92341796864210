import UiInput from "../../components/ui-input";
import styles from "./styles.module.scss";
import GridLayoutThreeColumn from "../../components/grid-layout-three-column";

const Component = ({
  packageInfo,
  onUpdate,
}: {
  packageInfo: {
    height: string;
    width: string;
    length: string;
    weight: string;
  };
  onUpdate: any;
}) => {
  return (
    <div className={styles.createPackage}>
      <div className={styles.heading}>
        <label>Input Package Info</label>
      </div>
      <div className={styles.panel}>
        <GridLayoutThreeColumn
          widthDivision={[4, 4, 4]}
          leftCol={
            <UiInput
              title={"Width (cm)"}
              name={"width"}
              className="mb-3"
              type="number"
              value={packageInfo.width}
              onChange={(event) => onUpdate("width", event.target.value)}
            />
          }
          centerCol={
            <UiInput
              title={"Height  (cm)"}
              name={"height"}
              className="mb-3"
              type="number"
              value={packageInfo.height}
              onChange={(event) => onUpdate("height", event.target.value)}
            />
          }
          rightCol={
            <>
              <UiInput
                title={"Length  (cm)"}
                name={"length"}
                className="mb-3"
                type="number"
                value={packageInfo.length}
                onChange={(event) => onUpdate("length", event.target.value)}
              />
            </>
          }
        />
        <UiInput
          title={"Weight  (gr)"}
          name={"weight"}
          type="number"
          className="mb-3"
          value={packageInfo.weight}
          onChange={(event) => onUpdate("weight", event.target.value)}
        />
      </div>
    </div>
  );
};

export default Component;
