import * as yup from "yup";
import { validationMessage } from "../../../utils/validation";



export const taskLoggingSchema = yup
  .object()
  .shape({
    description: yup.string(),
    linked_object_code: yup.string().nullable(),
    linked_object_type: yup.string().nullable(),
    assignee_uuid: yup
      .string()
      .required(({ path }) =>
        validationMessage.required('Assignee is required')
      ),
    start_at: yup.mixed(),
    due_at: yup.mixed(),
  })
  .required();
