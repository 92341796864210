import classNames from "classnames";
import { forwardRef } from "react";

import styles from "./styles.module.scss";

interface IViewCustomSearchInput {
  onClickSearch: () => void;
}

const ViewCustomSearchInput = forwardRef(
  ({ onClickSearch }: IViewCustomSearchInput, ref: any) => (
    <div className={classNames("mb-3", styles.customInput)}>
      <div className="position-relative">
        <input
          ref={ref}
          className={classNames(
            "dataTable-input form-control-sm ",
            styles.inputSearch
          )}
          placeholder="Search"
          onClick={onClickSearch}
        />
        <div className={classNames("position-absolute", styles.hint)}>
          Ctrl + K
        </div>
      </div>
    </div>
  )
);

export default ViewCustomSearchInput;
