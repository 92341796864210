import { formatDateTime } from "../../utils/date-time";
import GeneralPanel from "../../components/panel-general";

import SectionHeading from "./view-section-heading";
import RowTextContent from "../../components/ui-row-text-content/component";
import { SALE_ORDER_LIST } from "../../routings/path";

const ViewAddressSection = ({
  packingSaleOrderDetail,
}: {
  packingSaleOrderDetail: any;
}) => (
  <GeneralPanel className="pt-4" containerClassName="mt-4">
    <SectionHeading>Order Info</SectionHeading>
    <div className="mb-4">
      <RowTextContent
        sub={"Order Number:"}
        title={packingSaleOrderDetail?.number}
        linkTo={`${SALE_ORDER_LIST}/${packingSaleOrderDetail?.uuid}`}
      />
      <RowTextContent
        sub={"Reference Number:"}
        title={packingSaleOrderDetail?.reference_number}
      />
      <RowTextContent
        sub={"Created at:"}
        title={formatDateTime(packingSaleOrderDetail?.created_at)}
      />
      <RowTextContent
        sub={"Warehouse:"}
        title={packingSaleOrderDetail?.warehouse_name}
      />
      <RowTextContent
        sub={"Channel:"}
        title={packingSaleOrderDetail?.channel}
      />
      <RowTextContent sub={"COD:"} title={packingSaleOrderDetail?.cod} />
      <RowTextContent sub={"Value:"} title={packingSaleOrderDetail?.value} />
      <RowTextContent
        sub={"Shipping Service:"}
        title={packingSaleOrderDetail?.shipping_service}
      />
      {!!packingSaleOrderDetail?.shop_code && (
        <RowTextContent
          sub={"Shop Code:"}
          title={packingSaleOrderDetail?.shop_code}
        />
      )}
      <RowTextContent
        sub={"Tracking Code:"}
        title={packingSaleOrderDetail?.tracking_code}
      />
    </div>
    <SectionHeading>Receiver Info</SectionHeading>
    <div className="mb-4">
      <RowTextContent
        sub={"Receiver name:"}
        title={packingSaleOrderDetail?.receiver_name}
      />
      <RowTextContent
        sub={"Phone Number:"}
        title={packingSaleOrderDetail?.receiver_phone}
      />

      <RowTextContent
        sub={"Address:"}
        title={
          packingSaleOrderDetail?.address?.full_address
            ? `${packingSaleOrderDetail?.address?.full_address}`
            : `${packingSaleOrderDetail?.address?.street_name}, ${packingSaleOrderDetail?.address?.ward_name}, ${packingSaleOrderDetail?.address?.district_name}, ${packingSaleOrderDetail?.address?.province_name}`
        }
      />
    </div>
  </GeneralPanel>
);

export default ViewAddressSection;
