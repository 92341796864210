import * as R from "../../routings/path";
import { TABLE_ACTION } from "../../constants/table-action";
import DateTimeTableCell from "../../components/table-cell-date-time";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import WarehouseTableCell from "../../components/table-cell-warehouse";
import ActionTableCell from "../../components/table-cell-action";
import NumberTableCell from "../../components/table-cell-number";

export const schema = () => [
  {
    name: "PO ID",
    cell: (data: any) => (
      <ProductTableCell
        title={`${data?.name}`}
        description={`#${data?.number}`}
        linkTo={`${R.PURCHASE_ORDERS_LIST}/${data?.uuid}`}
        maxWidth={200}
      />
    ),
  },
  {
    name: "Packages Qty",
    align: "center",
    textSize: "xs",
    cell: (data: any) => (
      <NumberTableCell
        value={data?.package_count}
        className={{ content: "fs-5 fw-bold" }}
      />
    ),
  },
  {
    name: "PO Product Qty",
    align: "center",
    textSize: "xs",
    cell: (data: any) => (
      <NumberTableCell
        value={data?.purchase_order_product_count}
        className={{ content: "fs-5 fw-bold" }}
      />
    ),
  },
  {
    name: "Product Item Qty",
    align: "center",
    textSize: "xs",
    cell: (data: any) => (
      <NumberTableCell
        value={data?.product_item_count}
        className={{ content: "fs-5 fw-bold" }}
      />
    ),
  },
  {
    name: "Warehouse",
    cell: (data: any) => {
      return <WarehouseTableCell warehouse={data} />;
    },
  },
  {
    name: "Created",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data?.created_at} />,
  },
  {
    name: "Status",
    align: "center",
    cell: (data: any) => <StatusTableCell status={data?.status} />,
  },
  {
    name: "Action",
    align: "right",
    cell: (data: any) => (
      <ActionTableCell
        actions={data?.instance_actionables}
        viewLink={`${R.PURCHASE_ORDERS_LIST}/${data?.uuid}`}
        editLink={`${R.PURCHASE_ORDERS_LIST}/${data?.uuid}/edit`}
        types={[TABLE_ACTION.VIEW, TABLE_ACTION.EDIT]}
        popupContent="Are you sure to delete this product ?"
      />
    ),
  },
];
