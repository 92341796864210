import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  getTplRoutesApi,
  toggleTplRouteStatusApi,
} from "../../apis/paths/tpl-routes";
import { apiCaller, useApiCaller } from "../../apis/config";
import { getDefaultShippingServiceApi } from "../../apis/paths/shipping";
import { TPL_ROUTES_CREATE } from "../../routings/path";
import alert from "../../utils/alert";

import { GENERAL_STATUS } from "../../constants/status";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { ACTIONABLE_TYPE } from "../../constants/actionable";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { API_RESPONSE_STATUS } from "../../constants/api";

import TablePanel from "../../components/panel-table";
import Typography from "../../components/ui-typography/component";
import Badge from "../../components/ui-badge/component";

import { schema } from "./schema";
interface IStatusState {
  [key: string]: GENERAL_STATUS.ACTIVATE | GENERAL_STATUS.DEACTIVE;
}

const Component = () => {
  const navigate = useNavigate();
  const [tplRoutesData, setTplRoutesData] = useState([]);

  const { result: tplRoutes } = useApiCaller({
    api: getTplRoutesApi,
    params: DEFAULT_PAGINATION_PARAMS,
  });

  const { result: defaultShippingService } = useApiCaller({
    api: getDefaultShippingServiceApi,
    params: DEFAULT_PAGINATION_PARAMS,
  });

  useEffect(() => {
    setTplRoutesData(tplRoutes?.data?.list);
  }, [tplRoutes]);

  const resultObj: IStatusState = {};
  tplRoutesData?.forEach(
    ({
      id,
      status,
    }: {
      id: string | number;
      status: GENERAL_STATUS.ACTIVATE | GENERAL_STATUS.DEACTIVE;
    }) => {
      resultObj[id] = status;
    }
  );

  const [tplRouteStatus, setTplRouteStatus] = useState<IStatusState>(resultObj);

  useEffect(() => {
    setTplRouteStatus(resultObj);
  }, [tplRoutesData]);

  const handleToggleStatus = async (id: string) => {
    const waitToggle = await apiCaller({
      api: toggleTplRouteStatusApi,
      params: { id },
    });

    if (waitToggle.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update status successful!");
      setTplRouteStatus((prevState) => ({
        ...prevState,
        [id]:
          prevState[id] === GENERAL_STATUS.ACTIVATE
            ? GENERAL_STATUS.DEACTIVE
            : GENERAL_STATUS.ACTIVATE,
      }));

      return;
    }

    alert.error("Change status was not successful!. Please try again");
  };

  return (
    <>
      <TablePanel
        apiPath={getTplRoutesApi}
        panel={{
          title: "TPL Routes",
          description: "Third Party Logistics Management System",
          actionableButtons: [
            {
              actionableType: ACTIONABLE_TYPE.NEW,
              title: "New Route",
              onClick: () => navigate(TPL_ROUTES_CREATE),
            },
          ],
          actionArea: () => (
            <>
              <Typography
                fontWeight="fw-bolder"
                className="text-xs text-bold me-2 pt-1"
              >
                Default Service:
              </Typography>
              <Badge
                content={defaultShippingService?.data?.display_name}
                type={GENERAL_UI_COLOR.INFO}
                className="me-3"
              />
            </>
          ),
        }}
        tableSchema={schema({
          onToggle: handleToggleStatus,
          tplRouteStatus,
        })}
      />
    </>
  );
};

export default Component;
