import styles from "./style.module.scss";
import classnames from "classnames";

interface PropsType {
  title?: string;
  type?: string;
  isDisplayLabel?: boolean;
  name: string;
  id?: string;
  disabled?: boolean;
  viewMode?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  className?: string;
  register?: any;
  errorMessage?: string;
  onFocus?: (param: any) => any;
  onBlur?: (param: any) => any;
  onChange?: (param: any) => any;
  onClick?: (param: any) => any;
  placeholder?: string;
  rows?: number;
  value?: string;
}

const UiTextArea = ({
  title,
  type = "text",
  isDisplayLabel = true,
  name,
  id = "",
  disabled = false,
  viewMode = false,
  readOnly = false,
  autoFocus = false,
  className = "",
  register = null,
  errorMessage = "",
  onFocus = () => {},
  onBlur = () => {},
  onChange = () => {},
  onClick = () => {},
  placeholder,
  rows = 5,
  value,
  ...rest
}: PropsType) => {
  let registerData = {};
  
  if (register)
    registerData = register(name, {
      onBlur: (e: any) => {
        onBlur && onBlur(e);
      },
    });

  return (
    <>
      {isDisplayLabel && <label>{title || name}</label>}
      <div className="mb-3">
        <textarea
          className={classnames(
            "form-control",
            viewMode && styles.viewMode,
            className
          )}
          rows={rows}
          disabled={disabled || viewMode}
          placeholder={viewMode ? "-" : placeholder || title || name}
          aria-label={title}
          readOnly={readOnly}
          onFocus={onFocus}
          autoFocus={autoFocus}
          id={id}
          autoComplete="off"
          onClick={onClick}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          {...registerData}
        />
      </div>

      {errorMessage && errorMessage.length && (
        <label className="mb-0" style={{ color: "#f5365c" }}>
          {errorMessage}
        </label>
      )}
    </>
  );
};

export default UiTextArea;
