import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import EditAccountForm from "./view-product-type-form";
import { formSchema } from "./formSchema";

import { apiCaller, useApiCaller } from "../../apis/config";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { PRODUCTS_TYPE_LIST } from "../../routings/path";
import { IProductType } from "../../types/product";
import {
  IUpdateProductTypeApi,
  deleteProductTypeApi,
  getProductTypeDetailApi,
  getProductTypeListApi,
  updateProductTypeApi,
} from "../../apis/paths/products";
import alert from "../../utils/alert";
import SmallCollumnCenterGridLayout from "../../components/grid-layout-small-collumn-center";
import UiForm from "../../components/ui-form";
import UiButton from "../../components/ui-button";
import NotFoundPanel from "../../components/panel-not-found";
import HeaderInfoPanel from "../../components/panel-header-info";
import QuickActionPopupConfirmation from "../../components/popup-quick-aciton-confirmation/component";

const Component = () => {
  const [pageReloadVersion, setPageReloadVersion] = useState(1);
  const [isShowModal, setIsShowModal] = useState(false);
  const [productType, setProductType] = useState<IProductType>({});
  const { typeId } = useParams();

  const id = typeId || "";
  const navigate = useNavigate();

  const [params] = useState<{
    page: number;
    per: number;
    id: string;
  }>({
    page: 1,
    per: 50,
    id,
  });

  const { result } = useApiCaller({
    api: getProductTypeDetailApi,
    params,
    pageReloadVersion,
  });

  const { result: productTypes } = useApiCaller({
    api: getProductTypeListApi,
    params: DEFAULT_PAGINATION_PARAMS,
    pageReloadVersion,
  });

  useEffect(() => {
    setProductType(result?.data);
  }, [result]);

  const handleDeleteProductType = async (id: string) => {
    const waitDelete = await apiCaller({
      api: deleteProductTypeApi,
      params: { id },
    });

    if (waitDelete.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Delete successful!");
      navigate(PRODUCTS_TYPE_LIST);
      return;
    }

    alert.error("Delete was unsuccessful!. Please try again");
  };

  const handleOpenModal = () => {
    setIsShowModal(true);
  };

  const handleCloseModal = () => {
    setIsShowModal(false);
  };

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    if (!productType) return;

    const keys = Object.keys(formSchema?.fields);
    keys.forEach((key: any) => {
      setValue(key, productType[key as keyof IProductType]);
    });
  }, [result, productType]);

  const onSubmit = async ({ name, description, strategy }: any) => {
    const waitUpdateRole = await apiCaller({
      api: updateProductTypeApi,
      params: {
        id,
        name,
        description,
        strategy,
      } as IUpdateProductTypeApi,
    });

    if (waitUpdateRole.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update successful!");
      setTimeout(() => {
        setPageReloadVersion((prev) => prev + 1);
      }, 1000);
      navigate(0);
      return;
    }

    alert.error("Update was not successful!. Please try again!");
  };

  if (result?.status === "ERROR" || productTypes?.status === "ERROR")
    return <NotFoundPanel />;

  return (
    <>
      <UiForm onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <HeaderInfoPanel
            isDisplayInfoArea={true}
            title={productType?.name}
            actionArea={() => (
              <>
                <UiButton className="me-2" title={"Update"} />
                <UiButton
                  type={GENERAL_UI_COLOR.DANGER}
                  onClick={handleOpenModal}
                  title={"Delete"}
                />
              </>
            )}
          />
        </div>
        <SmallCollumnCenterGridLayout>
          <EditAccountForm
            data={productType}
            errors={errors}
            register={register}
            setValue={setValue}
          />
        </SmallCollumnCenterGridLayout>
      </UiForm>

      <QuickActionPopupConfirmation
        content={"Are you sure to delete this product type ?"}
        isShowModal={isShowModal}
        handleCloseModal={handleCloseModal}
        onConfirm={() => {
          handleDeleteProductType(id as string);
        }}
      />
    </>
  );
};

export default Component;
