export interface IOption {
  title: string;
  value: string;
}

export const SIZE = [
  { title: "Small", value: "small" },
  { title: "Medium", value: "medium" },
  { title: "Large", value: "large" },
  { title: "Extra large", value: "extra_large" },
  { title: "Extra extra large", value: "extra_extra_large" },
];

export const BOX_TYPE = [
  { title: "Normal", value: "normal" },
  { title: "Gift", value: "gift" },
  { title: "Special", value: "special" },
];

export const FieldTitle = {
  code: "Code",
  name: "Name",
  size: "Size",
  box_type: "Box Type",
  width: "Packaging width",
  height: "Packaging height",
  length: "Packaging length",
  upload_image: "Upload Image",
};
