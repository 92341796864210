import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { WAREHOUSE_STORAGE_TYPE } from "../../constants/warehouse";
import { generateUrlParams } from "../../utils/url";
import { get, post, patch } from "../config";

interface IGetWarehouseListApi {
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
  filter?: object | null;
}

interface IGetStockHistoryProductOnLocation {
  page?: number;
  per?: number;
  warehouseId: string;
  locationCode: string;
  productUuid: string;
}

interface IGetWarehouseLocationApi {
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
  filter?: object | null;
  code: string;
}

export interface ICreateWarehouseLocationApi {
  location_code: string;
  location_type: string[];
  height?: number;
  length?: number;
  width?: number;
  zone?: string;
  code: string;
}

export const getWarehouseListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
  filter = DEFAULT_PAGINATION_PARAMS.filter,
}: IGetWarehouseListApi) =>
  get({
    path: `/wms/warehouses${generateUrlParams({
      page,
      per,
      keyword,
      sort,
      filter,
    })}`,
  });

export const getStockHistoryProductOnLocation = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  warehouseId,
  locationCode,
  productUuid,
}: IGetStockHistoryProductOnLocation) =>
  get({
    path: `/wms/warehouses/${warehouseId}/locations/${locationCode}/stock_histories${generateUrlParams(
      {
        page,
        per,
        product_uuid: productUuid,
      }
    )}`,
  });

export const getDeletedWarehouseListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
}: IGetWarehouseListApi) =>
  get({
    path: `/wms/warehouses/deleted${generateUrlParams({
      page,
      per,
      keyword,
      sort,
    })}`,
  });

export interface ICreateWarehouseApi {
  warehouse: {
    name: string;
    contactName: string;
    contactPhone: string;
    valuable: boolean;
    storageType?:
      | WAREHOUSE_STORAGE_TYPE.PACKING
      | WAREHOUSE_STORAGE_TYPE.RESERVE
      | "";
    parentWarehouseUuid?: string;
  };
  address: {
    wardId: number;
    streetName?: string;
    detail?: string;
    latitude?: number;
    longitude?: number;
  };
}

export const createWarehouseApi = ({
  warehouse,
  address,
}: ICreateWarehouseApi) => {
  const data = {
    warehouse: {
      contact_name: warehouse.contactName,
      contact_phone: warehouse.contactPhone,
      storage_type: warehouse.storageType,
      parent_warehouse_uuid: warehouse.parentWarehouseUuid,
      ...warehouse,
    },
    address: {
      ward_id: address.wardId,
      street_name: address.streetName,
      ...address,
    },
  };

  return post({ path: `/wms/warehouses`, data });
};

export interface IUpdateWarehouseApi {
  code: string;
  warehouse: {
    name: string;
    contactName: string;
    contactPhone: string;
    valuable: boolean;
    storageType?:
      | WAREHOUSE_STORAGE_TYPE.PACKING
      | WAREHOUSE_STORAGE_TYPE.RESERVE
      | "";
    parentWarehouseUuid?: string;
  };
  address: {
    wardId: number;
    streetName?: string;
    detail?: string;
    latitude?: number;
    longitude?: number;
  };
}

export const updateWarehouseApi = ({
  code,
  warehouse,
  address,
}: IUpdateWarehouseApi) => {
  const data = {
    warehouse: {
      contact_name: warehouse.contactName,
      contact_phone: warehouse.contactPhone,
      storage_type: warehouse.storageType,
      parent_warehouse_uuid: warehouse.parentWarehouseUuid,
      ...warehouse,
    },
    address: {
      ward_id: address.wardId,
      street_name: address.streetName,
      ...address,
    },
  };

  return patch({ path: `/wms/warehouses/${code}`, data });
};

interface IGetWarehouseDetailApi {
  code: string;
}

export const getWarehouseDetailApi = ({ code }: IGetWarehouseDetailApi) =>
  get({ path: `/wms/warehouses/${code}` });

export const getCountriesApi = () => get({ path: `/wms/settings/countries` });

export const getProvincesApi = ({ country_id }: { country_id: number }) =>
  get({ path: `/wms/settings/countries/${country_id}/provinces` });

export const getWardsApi = ({
  country_id,
  province_id,
}: {
  country_id: number;
  province_id: number;
}) =>
  get({
    path: `/wms/settings/countries/${country_id}/provinces/${province_id}/wards`,
  });

export const getGeoWardApi = ({
  keyword,
  limit,
}: {
  keyword: string;
  limit?: number;
}) => {
  let lintingKeyword = keyword;
  if (keyword === "") {
    lintingKeyword = " ";
  }

  return get({
    path: `/wms/geos/search${generateUrlParams({
      keyword: lintingKeyword,
      limit,
    })}`,
  });
};

interface IToggleWarehouseStatusApi {
  code: string;
}

export const toggleWarehouseStatusApi = ({ code }: IToggleWarehouseStatusApi) =>
  patch({ path: `/wms/warehouses/${code}/toggle` });

// --------------------LOCATION-----------------------

export const getWarehouseLocations = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
  filter = DEFAULT_PAGINATION_PARAMS.filter,
  code,
}: IGetWarehouseLocationApi) =>
  get({
    path: `/wms/warehouses/${code}/locations${generateUrlParams({
      page,
      per,
      keyword,
      sort,
      filter,
    })}`,
  });

export const getWarehouseLocationsProducts = ({
  location_code,
  code,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
  filter = DEFAULT_PAGINATION_PARAMS.filter,
}: {
  location_code: string;
  code: string;
  keyword?: string;
  sort?: any;
  filter?: any;
}) => {
  return get({
    path: `/wms/warehouses/${code}/locations/${location_code}/products${generateUrlParams(
      {
        keyword,
        sort,
        filter,
      }
    )}`,
  });
};

export const createWarehouseLocationApi = ({
  location_code,
  location_type,
  height,
  length,
  width,
  zone,
  code,
}: ICreateWarehouseLocationApi) =>
  post({
    path: `/wms/warehouses/${code}/locations`,
    data: {
      location_code,
      location_type,
      height,
      length,
      width,
      zone,
    },
  });
