import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { useApiCaller, apiCaller } from "../../apis/config";
import TableCellStatus from "../../components/table-cell-status";
import {
  getSaleOrderDetailApi,
  getSaleOrderProductListApi,
  addProductIntoSaleOrderApi,
  updateProductStatusInSaleOrderApi,
  changeProductSaleOrderQtyApi,
  removeProductFromSaleOrderApi,
  IAddProductIntoSaleOrderApi,
  IRemoveProductFromSaleOrderApi,
  IChangeProductSaleOrderQtyApi,
} from "../../apis/paths/sale-order";
import { getProductListApi } from "../../apis/paths/products";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { API_RESPONSE_STATUS } from "../../constants/api";
import alert from "../../utils/alert";
import TablePanel from "../../components/panel-table";
import Typography from "../../components/ui-typography/component";
import GeneralPanel from "../../components/panel-general/component";
import FileImporter from "../../components/ui-file-uploader/component";
import UiLoadingPanel from "../../components/ui-loading-panel";
import NotFoundPanel from "../../components/panel-not-found/component";
import HeaderInfoPanel from "../../components/panel-header-info";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import useHandleSidebarStatus from "../../hooks/useSidebarStatus";

import { productSchema } from "./schema";
import { PATH_TO_SALE_ORDER_SAMPLE_FILE } from "../../constants/staticFile";
import styles from "./styles.module.scss";
import GeneralHeading from "./view-general-heading";
import SelectedProduct from "./view-selected-product";
import DownloadButton from "../../components/ui-download-button/component";

const Component = () => {
  const [saleOrderInfo, setSaleOrderInfo] = useState<any>({});
  const [selectedProducts, setSelectedProducts] = useState<any>([]);

  const { saleOrderId: uuid } = useParams();
  const [params] = useState({ uuid });

  const { result: getSelectedProductResult } = useApiCaller({
    api: getSaleOrderProductListApi,
    params: params,
  });

  useEffect(() => {
    setSelectedProducts(getSelectedProductResult?.data?.list?.reverse());
  }, [getSelectedProductResult]);

  const latestSelectedProduct = useRef();
  const isEditable = saleOrderInfo?.status === "draft";

  const { dispatchSidebarStatus } = useHandleSidebarStatus();

  useEffect(() => {
    dispatchSidebarStatus({ isOpened: false });
    return () => {
      dispatchSidebarStatus({ isOpened: true });
    };
  }, []);

  const { result, loading } = useApiCaller({
    api: getSaleOrderDetailApi,
    params: params,
  });

  useEffect(() => {
    if (result?.data) {
      /* TODO: Delete after API update */
      let saleOrderResult = result?.data;
      saleOrderResult.address.wardId = 10;

      setSaleOrderInfo(saleOrderResult);
    }
  }, [result]);

  const handleAddProduct = (newProduct: any) => {
    const isExist = selectedProducts.some(
      (productItem: any) => productItem?.product?.uuid === newProduct.uuid
    );

    if (isExist) return;

    (async () => {
      const waitAddProduct = await apiCaller({
        api: addProductIntoSaleOrderApi,
        params: {
          uuid,
          products: [
            {
              code: newProduct.code,
              quantity: 1,
            },
          ],
        } as IAddProductIntoSaleOrderApi,
      });

      if (waitAddProduct?.status === "SUCCESS") {
        setSelectedProducts(waitAddProduct?.data?.list?.reverse());
      }
    })();
  };

  const handleUpdateProduct = (updatedProduct: any) => {
    const newProducts = selectedProducts.map((productItem: any) =>
      productItem?.product?.uuid !== updatedProduct?.product?.uuid
        ? productItem
        : updatedProduct
    );

    setSelectedProducts(newProducts);
  };

  const handleRemoveAllSelectedProducts = async () => {
    apiCaller({
      api: removeProductFromSaleOrderApi,
      params: {
        uuid,
        codes: selectedProducts?.map((product: any) => product.uuid),
      } as IRemoveProductFromSaleOrderApi,
    });
  };

  const handleDeleteProduct = async (deletedProduct: any) => {
    if (!deletedProduct) return;
    setSelectedProducts((prev: any) => {
      return prev.filter(
        (selectedProduct: any) => selectedProduct.code !== deletedProduct?.code
      );
    });

    const waitRemoveProduct = await apiCaller({
      api: changeProductSaleOrderQtyApi,
      params: {
        uuid,
        code: deletedProduct.code,
        quantity: 0,
      } as IChangeProductSaleOrderQtyApi,
    });

    if (waitRemoveProduct?.status === "SUCCESS") {
      latestSelectedProduct.current = waitRemoveProduct?.data?.list?.reverse();
      return;
    }
    alert.error("Delete product unsuccessful");
    setSelectedProducts(latestSelectedProduct.current);
  };

  const onUploadCsvFile = async (file: any) => {
    if (!file) return;
    if (!file.some((row: any) => row.product_uuid)) {
      alert.error('Column "Product_uuid" is missing in table');
      return;
    }

    if (selectedProducts.length !== 0) {
      await handleRemoveAllSelectedProducts();
    }

    const extractProductFromCSVFile = file?.map((row: any) => row.product_uuid);

    const waitGetListImportCsvFile = await apiCaller({
      api: getProductListApi,
      params: {
        filter: [
          {
            field: "uuid",
            values: extractProductFromCSVFile,
            operator: "in",
          },
        ],
      },
    });

    if (waitGetListImportCsvFile.status === API_RESPONSE_STATUS.SUCCESS) {
      const mappingProductResponseWithQtyInCSVFile =
        waitGetListImportCsvFile?.data?.list?.map((product: any) => ({
          ...product,
          quantity:
            Number(
              file?.find(
                (csvImportedProduct: any) =>
                  csvImportedProduct?.product_uuid === product?.uuid
              )?.quantity
            ) || 1,
        }));

      alert.success("Import successful!");
      setSelectedProducts([...mappingProductResponseWithQtyInCSVFile]);

      const mappedProduct = mappingProductResponseWithQtyInCSVFile?.map(
        (selectedProduct: any) => ({
          code: selectedProduct?.code,
          quantity: selectedProduct?.quantity,
        })
      );

      const waitAddCsvImportedProduct = await apiCaller({
        api: addProductIntoSaleOrderApi,
        params: {
          uuid,
          products: mappedProduct,
        } as IAddProductIntoSaleOrderApi,
      });

      if (waitAddCsvImportedProduct.status === API_RESPONSE_STATUS.SUCCESS) {
        latestSelectedProduct.current = waitAddCsvImportedProduct?.data?.list;
      }

      return;
    }

    alert.error("Import was not successful!. Please try again!");
    setSelectedProducts(latestSelectedProduct.current);
  };

  if (loading) return <UiLoadingPanel />;

  if (result?.status === "ERROR") return <NotFoundPanel />;

  return (
    <>
      <div className="mb-4">
        <HeaderInfoPanel
          isDisplayInfoArea={true}
          title={`#${saleOrderInfo?.number}`}
          infoArea={() => (
            <>
              <TableCellStatus
                status={saleOrderInfo?.status}
                size={GENERAL_UI_SIZE.SMALL}
              />
              <TableCellStatus
                className="ms-2"
                status={saleOrderInfo?.shipping_service}
                size={GENERAL_UI_SIZE.SMALL}
              />
            </>
          )}
          actionArea={() => <></>}
        />
      </div>
      <GeneralPanel className="mt-3">
        <GeneralHeading>{"Product List"}</GeneralHeading>
        <TwoColumnGridLayout
          widthDivision={[6, 6]}
          leftCol={
            <>
              <FileImporter
                className={{
                  container: "d-flex w-100 justify-content-start",
                  label: "",
                  input: "w-100",
                }}
                disabled={!isEditable}
                onFileUpload={(value) => {
                  onUploadCsvFile(value);
                }}
              />
              <DownloadButton
                href={PATH_TO_SALE_ORDER_SAMPLE_FILE}
                className={{ container: "mt-1" }}
                downloadTitle="Saleorder_products"
                title={
                  <Typography
                    customFontsize={10}
                    fontWeight="fw-bold"
                    className="d-flex align-items-center gap-2"
                  >
                    <i className="fa-solid fa-download fa-sm"></i>
                    Download sample CSV
                  </Typography>
                }
              />
              <Typography fontWeight="fw-bold" className="my-2 text-center">
                Or
              </Typography>
              <TablePanel
                apiPath={getProductListApi}
                panelClassName={"mt-0"}
                type={"minimal"}
                tableSchema={productSchema({
                  onSelectProduct: handleAddProduct,
                  selectedProducts,
                  isEditable,
                })}
              />
            </>
          }
          rightCol={
            <div className={styles.selectProduct}>
              <SelectedProduct
                updateStatusApi={updateProductStatusInSaleOrderApi}
                label={"Selected Product"}
                saleOrderStatus={saleOrderInfo?.status}
                products={selectedProducts}
                onDeleteItem={handleDeleteProduct}
                onUpdateItem={handleUpdateProduct}
              />
            </div>
          }
        />
      </GeneralPanel>
    </>
  );
};

export default Component;
