import Modal from "react-bootstrap/Modal";
import Typography from "../ui-typography/component";

import { GENERAL_UI_COLOR } from "../../constants/color";

interface IModalProps {
  isShowModal?:boolean
  onCloseModal?:()=>void

  isDisplayButton?: boolean;
  isStylingButton?: boolean;

  titleClassName?: string;
  closeButton?: boolean;
  header?: React.ReactNode | string;
  handleClick?: () => void;
  children?: React.ReactNode | string;
  centered?: boolean;
  scrollable?: boolean;
  contentClassName?: string;
  size?: undefined | "sm" | "lg" | "xl";
  variant?: GENERAL_UI_COLOR;
  fullscreen?:
    | true
    | "sm-down"
    | "md-down"
    | "lg-down"
    | "xl-down"
    | "xxl-down";
}



function PopupConfirmation({
  closeButton = true,
  header,
  children,
  centered = true,
  size = undefined,
  contentClassName,
  scrollable = false,
  fullscreen = "md-down",
  isShowModal,
  onCloseModal,
}: IModalProps) {
  return (
    <>
      <Modal
        show={isShowModal}
        onHide={onCloseModal}
        centered={centered}
        size={size}
        contentClassName={contentClassName}
        scrollable={scrollable}
        fullscreen={fullscreen}
      >
        {header && (
          <Modal.Header closeButton={closeButton}>
            <Typography fontSize="fs-5" fontWeight="fw-bold">
              {header}
            </Typography>
          </Modal.Header>
        )}
        <Modal.Body >{children}</Modal.Body>
      </Modal>
    </>
  );
}

export default PopupConfirmation;
