import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IItemProps {
  api: string;
  errorCount: number;
}

export interface IExpiredSessionState {
  errorState: IItemProps[];
  isTokenExpired: boolean;
}

const initialState: IExpiredSessionState = {
  errorState: [],
  isTokenExpired: false,
};

export const expiredSessionDetectionSlice = createSlice({
  name: "expiredSessionDetection",
  initialState,
  reducers: {
    addErrorApi: (state, action: PayloadAction<{ api: string }>) => {
      const { api } = action.payload;
      const clonedErrorState = [...state.errorState];

      const isApiExisted = clonedErrorState?.find(
        (errorState) => errorState.api === api
      );

      if (isApiExisted) {
        const newErrorState = clonedErrorState?.map(
          (clonedState: IItemProps) => {
            if (clonedState.api === action.payload.api) {
              return { ...clonedState, errorCount: clonedState.errorCount + 1 };
            }
            return clonedState;
          }
        );

        state.errorState = newErrorState;
        return;
      } else {
        state.errorState = [...clonedErrorState, { api, errorCount: 1 }];
      }
    },
    clearErrorApi: (state) => {
      state.errorState = [];
    },
    changeExpiredStatus: (
      state,
      action: PayloadAction<{ status: boolean }>
    ) => {
      state.isTokenExpired = action.payload.status;
    },
  },
});

export const { addErrorApi, clearErrorApi, changeExpiredStatus } =
  expiredSessionDetectionSlice.actions;
export default expiredSessionDetectionSlice.reducer;
