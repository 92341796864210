import { useState } from "react";

import { longHyphen } from "../../constants/character";
import styles from "./styles.module.scss";
import Typography from "../ui-typography/component";

const generateAlignmentClass = (align: string) => {
  switch (align) {
    case "center":
      return "text-center";
    case "right":
      return "text-end";
    default:
      return "text-left";
  }
};

type TSorttype = {
  key: string;
  value: string[];
};

export interface IThTable {
  name: string | React.ReactNode;
  align?: "center" | "right" | "left"
  description?: string;
  headingAction?: any;
  cell: any;
  textSize?: "sm" | "md" | "lg" | "xl" | "xxl";
  sortTypes?: TSorttype;
  width?: number;
  noDataContent?: string;
}

interface IComponent {
  className?: string;
  schema?: any;
  data?: any;
  onSort?: any;
  isVisible?: boolean;
  showHeader?: boolean;
  displayHeadingAction?: number[] | null;
  displayLimit?: number;
  trClassName?: string;
  noDataContent?: string;
}
const Component = ({
  className,
  schema,
  data,
  onSort,
  isVisible = true,
  showHeader = true,
  displayHeadingAction = [],
  displayLimit = 0,
  trClassName = "",
  noDataContent = "No Data",
}: IComponent) => {
  const [collapsed, setCollapse] = useState<boolean>(!!displayLimit);

  const [sort, setSort] = useState<{
    key: string;
    value: string;
  } | null>(null);

  const handleSort = ({
    key,
    value,
  }: {
    key: string;
    value: Array<string>;
  }) => {
    const [asc, desc] = value;

    if (!sort || sort.key !== key) {
      setSort({ key, value: asc });
      onSort({ key, value: asc });
      return;
    }

    if (sort.key === key && sort.value === asc) {
      setSort({ key, value: desc });
      onSort({ key, value: desc });
      return;
    }

    setSort(null);
    onSort(null);
  };

  if (!isVisible) return null;

  const filteredData =
    (data &&
      data?.filter((_: any, index: number) =>
        !displayLimit ? true : !collapsed || displayLimit > index
      )) ||
    [];

  const remainingQtyToShowmore = data?.length - displayLimit;

  return (
    <>
      <table
        className={`table table-flush dataTable-table ${styles.container} ${className}`}
      >
        {
          showHeader && (
            <thead className="thead-light">
              <tr>
                {schema.map((th: IThTable, index: number) => {
                  const align = generateAlignmentClass(th?.align || "left");

                  const sortClassName = !th?.sortTypes
                    ? ""
                    : sort?.key === th?.sortTypes?.key
                    ? sort?.value === th?.sortTypes?.value[0]
                      ? "asc"
                      : "desc"
                    : "";

                  const isDisplayHeadingAction =
                    (displayHeadingAction ?? [])?.indexOf(index) >= 0 &&
                    !!th?.headingAction;

                  return (
                    <th
                      className={`${align} ${sortClassName}`}
                      key={index}
                      style={{
                        paddingRight:
                          index === schema.length - 1 && th?.align === "right"
                            ? "1.5rem"
                            : "0.75rem",
                        height: 42,
                        width: th?.width,
                        opacity: isDisplayHeadingAction ? 1 : 0.7,
                      }}
                      onClick={() => {
                        if (!th?.sortTypes) return;

                        handleSort(th?.sortTypes);
                      }}
                    >
                      {th?.sortTypes ? (
                        <a href="#" className="dataTable-sorter">
                          {isDisplayHeadingAction ? th?.headingAction : th?.name}
                        </a>
                      ) : (
                        <div className="d-flex flex-column justify-content-start">
                          {isDisplayHeadingAction ? th?.headingAction : th?.name}
                          {th?.description && (
                            <span className="table-thead-description">
                              {th?.description}
                            </span>
                          )}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            </thead>
          )
        }
        <tbody>
          {filteredData?.map((tr: any, trIndex: number) => (
            <tr className={trClassName} key={`tr-${tr.id || trIndex}`}>
              {schema.map((td: any, index: number) => {
                const align = generateAlignmentClass(td.align);
                const className = `
                    ${align}
                    text-${td.textSize || "sm"}
                    font-weight-normal
                  `;
                return (
                  <td className={className} key={index}>
                    {td.cell(tr, trIndex) || longHyphen}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      {!filteredData?.length && (
        <div className={styles.emptyTr}>{noDataContent}</div>
      )}
      {!!displayLimit && data?.length > displayLimit && (
        <data
          onClick={() => setCollapse((prev) => !prev)}
          className={styles.showMore}
          title={collapsed ? "Show more" : "Show less"}
        >
          {collapsed && (
            <div className={styles.qtyIcon}>
              <Typography
                customFontsize={12}
                fontWeight="fw-bold"
                className="me-1 position-relative"
              >
                {`+${remainingQtyToShowmore}`}
              </Typography>
              <i
                className={`fas fa-angle-down text-sm  ${
                  !collapsed && styles.viewLess
                } `}
              ></i>
            </div>
          )}
          {!collapsed && (
            <i
              className={`fas fa-angle-down text-sm ${styles.icon} ${styles.viewLess}`}
            ></i>
          )}
        </data>
      )}
    </>
  );
};

export default Component;
