export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const validationMessage = {
  required: (fieldTitle: any) => `Please input ${fieldTitle.toLowerCase()}`,
  min: (fieldTitle: any, min: any) =>
    `Field ${fieldTitle.toLowerCase()} has minimum ${min} characters`, // number
  max: (fieldTitle: any, max: any) =>
    `Field ${fieldTitle.toLowerCase()} has maximum ${max} characters`, // number
  pattern: (fieldTitle: any) =>
    `Pattern ${fieldTitle.toLowerCase()} is not supported`,
  minLength: (fieldTitle: any, minLength: any) =>
    `${capitalize(fieldTitle)} must have as least ${minLength} characters`,
  maxLength: (fieldTitle: any, maxLength: any) =>
    `${capitalize(fieldTitle)} can not exceed ${maxLength} characters`,
  email: (fieldTitle: any) =>
    `Email ${fieldTitle.toLowerCase()} is not correct`,
  minWords: (fieldTitle: any, minWords: any) =>
    `Words ${fieldTitle.toLowerCase()} must have as least ${minWords} words`,
  maxWords: (fieldTitle: any, maxWords: any) =>
    `Words ${fieldTitle.toLowerCase()} can not exceed ${maxWords} words`,
  integer: (fieldTitle: any) =>
    `${capitalize(fieldTitle)} should contain only number`,
  rating: (fieldTitle: any) =>
    `Please input your rating ${fieldTitle.toLowerCase()}`,
  oneOf: (fieldTitle: string, validList: string) =>
    `Field ${fieldTitle.toLowerCase()} must be one of ${validList}`,
  custom: (customMessage: string) => customMessage,
};

export const detectRequiredlField = (schema: any, field: string) =>
  !schema?.fields?.[field]?.spec.optional;
