import * as R from "../../routings/path";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import Typography from "../../components/ui-typography/component";
import ActionTableCell from "../../components/table-cell-action";
import { TABLE_ACTION } from "../../constants/table-action";
import { ACTIONABLE_TYPE } from "../../constants/actionable";

interface ISchema {
  onClickHistory?: (params: any) => void;
}

export const schema = ({ onClickHistory }: ISchema) => [
  {
    name: "Product",
    cell: (data: any) => (
      <ProductTableCell
        linkTo={`${R.PRODUCTS_LIST}/${data?.code}`}
        image={data?.image_url}
        title={data?.name}
        description={data?.description}
        maxWidth={250}
        imageSize={40}
      />
    ),
  },
  {
    name: "Quantity",
    align: "center",
    cell: (data: any) => (
      <Typography fontSize="fs-5" fontWeight="fw-bold">
        {data?.quantity}
      </Typography>
    ),
  },
  {
    name: "Capacity",
    align: "right",
    cell: (data: any) => (
      <ProductTableCell
        title={data?.capacity_value || "-"}
        description={data?.capacity_unit}
      />
    ),
  },
  {
    name: "Weight - WHL",
    align: "center",
    cell: (data: any) => (
      <ProductTableCell
        title={data?.packaging_weight}
        description={`${data?.packaging_width} x ${data?.packaging_height} x ${data?.packaging_length}`}
      />
    ),
  },
  {
    name: "Status",
    align: "center",
    cell: (data: any) => <StatusTableCell status={data?.status} />,
  },
  {
    name: "Action",
    align: "right",
    cell: (data: any) => (
      <ActionTableCell
        customActionableType={ACTIONABLE_TYPE.FULL}
        customTitle="Check History"
        types={[TABLE_ACTION.CUSTOM]}
        onCustomClick={() => {
          onClickHistory?.(data);
        }}
      />
    ),
  },
];
