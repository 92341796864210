export enum GENERAL_STATUS {
  // SUCCESS_STATUS,
  ACTIVE = "active",
  ACTIVATE = "activate",
  ENABLED = "enabled",
  ENABLE = "enable",
  OK = "ok",
  DONE = "done",
  FINAL = "final",
  COMPLETED = "completed",
  SUCCESS = "success",
  SUCCESSFUL = "successful",
  SUCCESSFULLY = "successfully",
  APPROVED = "approved",
  IN_STOCK = "instock",
  ON = "on",
  AVAILABLE = "available",
  MATCHED = "matched",
  GOOD = "good",
  TRUE = "1",
  VALUABLE = "valuable",
  SELECTED = "selected",
  CHECKED = "checked",
  RELOCATION = "relocation",

  // FAIL_STATUS,
  INACTIVE = "inactive",
  DEACTIVE = "deactivate",
  DELETED = "deleted",
  DISABLED = "disabled",
  DISABLE = "disable",
  NO = "no",
  CLOSED = "closed",
  CLOSE = "close",
  FAIL = "fail",
  FAILED = "failed",
  FAILURE = "failure",
  ERROR = "error",
  CANCELED = "canceled",
  CANCELLED = "cancelled",
  CANCEL = "cancel",
  REJECTED = "rejected",
  REJECT = "reject",
  OUT_OF_STOCK = "outofstock",
  OOS = "oos",
  TIMEOUT = "timeout",
  DISCONTINUED = "discontinued",
  DISCONTINUE = "discontinue",
  OFF = "off",
  BLOCKED = "blocked",
  BANNED = "banned",
  UNAVAILABLE = "unavailable",
  LOST = "lost",
  MISSING = "missing",
  UNMATCHED = "unmatched",
  BAD = "bad",
  FALSE = "0",
  EMPTY = "",
  DRAFT = "draft",
  UNDERSTOCK = "understock",

  // WARNING_STATUS,
  PICKING = "picking",
  WARNING = "warning",
  ALERTED = "alerted",
  WAITING = "waiting",
  PENDING = "pending",
  CREATED = "created",
  OVERSTOCK = "overstock",
  FAST_RELOCATION_SHIPMENT = "fastrelocationshipment",
  RECHECKING = "rechecking",
  RETURN = "return",

  // PRIMARY_STATUS,
  DEFAULT = "default",
  IN_PROCESS = "inprocess",
  PROCESSING = "processing",
  RECEIVED = "received",
  PACKING = "packing",
  STARTED = "started",

  // SECONDARY_STATUS,
  REQUESTED = "requested",

  SUBMITTED = "submitted",
  PARTIAL_RECEIVED = "partialreceived",
  UNCHECKED = "unchecked",

  // INFO_STATUS,
  SHIP = "shipp",
  SHIPPED = "shipped",
  IN_TRANSIT = "intransit",
  PARTIALLY_COMPLETED = "partiallycompleted",
  MISPLACED = "misplaced",
  CHECKING = "checking",

  //SIZE_STATUS
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  EXTRA_LARGE = "extralarge",
  EXTRA_EXTRA_LARGE = "extraextralarge",

  // CUSTOM
  PRINT = "print",

  // NOTIFICATION TYPE
  TASKLOGGING = "TaskLogging",
  PICKLIST = "PickList",

  //PACKING
  PACKED = "packed",
}

export const PRODUCT_STATUS = {
  ACTIVATE: GENERAL_STATUS.ACTIVATE,
  INACTIVE: GENERAL_STATUS.INACTIVE,
  DELETED: GENERAL_STATUS.DELETED,
};

export const PRODUCT_OVERALL_STATUS = [
  {
    value: "saleable",
    content: "Saleable",
  },
  {
    value: "usable",
    content: "Usable",
  },
  {
    value: "damaged",
    content: "Damaged",
  },
];

export const SHIPPING_SERVICES_STATUS = [
  {
    value: "Snappy",
    content: "Snappy",
  },
  {
    value: "GiaoHangNhanh",
    content: "Giao Hang Nhanh",
  },
  {
    value: "LixHouse",
    content: "LixHouse",
  },
  {
    value: "Vintrans",
    content: "Vintrans",
  },
];

export const SUCCESS_STATUS = [
  GENERAL_STATUS.ACTIVE,
  GENERAL_STATUS.ACTIVATE,
  GENERAL_STATUS.ENABLED,
  GENERAL_STATUS.ENABLE,
  GENERAL_STATUS.OK,
  GENERAL_STATUS.DONE,
  GENERAL_STATUS.FINAL,
  GENERAL_STATUS.COMPLETED,
  GENERAL_STATUS.SUCCESS,
  GENERAL_STATUS.SUCCESSFUL,
  GENERAL_STATUS.SUCCESSFULLY,
  GENERAL_STATUS.APPROVED,
  GENERAL_STATUS.IN_STOCK,
  GENERAL_STATUS.ON,
  GENERAL_STATUS.AVAILABLE,
  GENERAL_STATUS.MATCHED,
  GENERAL_STATUS.GOOD,
  GENERAL_STATUS.TRUE,
  GENERAL_STATUS.VALUABLE,
  GENERAL_STATUS.SELECTED,
  GENERAL_STATUS.PACKED,
  GENERAL_STATUS.CHECKED,
  GENERAL_STATUS.RELOCATION,
];

export const FAIL_STATUS = [
  GENERAL_STATUS.INACTIVE,
  GENERAL_STATUS.DEACTIVE,
  GENERAL_STATUS.DELETED,
  GENERAL_STATUS.DISABLED,
  GENERAL_STATUS.DISABLE,
  GENERAL_STATUS.NO,
  GENERAL_STATUS.CLOSED,
  GENERAL_STATUS.CLOSE,
  GENERAL_STATUS.FAIL,
  GENERAL_STATUS.FAILED,
  GENERAL_STATUS.FAILURE,
  GENERAL_STATUS.ERROR,
  GENERAL_STATUS.CANCELED,
  GENERAL_STATUS.CANCELLED,
  GENERAL_STATUS.CANCEL,
  GENERAL_STATUS.REJECTED,
  GENERAL_STATUS.REJECT,
  GENERAL_STATUS.OUT_OF_STOCK,
  GENERAL_STATUS.OOS,
  GENERAL_STATUS.TIMEOUT,
  GENERAL_STATUS.DISCONTINUED,
  GENERAL_STATUS.DISCONTINUE,
  GENERAL_STATUS.OFF,
  GENERAL_STATUS.BLOCKED,
  GENERAL_STATUS.BANNED,
  GENERAL_STATUS.UNAVAILABLE,
  GENERAL_STATUS.LOST,
  GENERAL_STATUS.MISSING,
  GENERAL_STATUS.UNMATCHED,
  GENERAL_STATUS.BAD,
  GENERAL_STATUS.FAIL,
  GENERAL_STATUS.EMPTY,
  GENERAL_STATUS.SMALL,
  GENERAL_STATUS.MEDIUM,
  GENERAL_STATUS.LARGE,
  GENERAL_STATUS.EXTRA_LARGE,
  GENERAL_STATUS.EXTRA_EXTRA_LARGE,
  GENERAL_STATUS.DRAFT,
  GENERAL_STATUS.UNDERSTOCK,
];

export const WARNING_STATUS = [
  GENERAL_STATUS.PICKING,
  GENERAL_STATUS.WARNING,
  GENERAL_STATUS.ALERTED,
  GENERAL_STATUS.WAITING,
  GENERAL_STATUS.PENDING,
  GENERAL_STATUS.CREATED,
  GENERAL_STATUS.OVERSTOCK,
  GENERAL_STATUS.FAST_RELOCATION_SHIPMENT,
  GENERAL_STATUS.RECHECKING,
  GENERAL_STATUS.RETURN,

  // Notification
  GENERAL_STATUS.PICKLIST,
];

export const PRIMARY_STATUS = [
  GENERAL_STATUS.DEFAULT,
  GENERAL_STATUS.IN_PROCESS,
  GENERAL_STATUS.PROCESSING,
  GENERAL_STATUS.RECEIVED,
  GENERAL_STATUS.PACKING,
  GENERAL_STATUS.STARTED,
  GENERAL_STATUS.MISPLACED,

  // CUSTOM
  GENERAL_STATUS.PRINT,
];

export const SECONDARY_STATUS = [
  GENERAL_STATUS.REQUESTED,
  GENERAL_STATUS.SUBMITTED,
  GENERAL_STATUS.PARTIAL_RECEIVED,
  GENERAL_STATUS.UNCHECKED,
];

export const INFO_STATUS = [
  GENERAL_STATUS.SHIPPED,
  GENERAL_STATUS.IN_TRANSIT,
  GENERAL_STATUS.PARTIALLY_COMPLETED,
  GENERAL_STATUS.CHECKING,

  // Notification
  GENERAL_STATUS.TASKLOGGING,
];
