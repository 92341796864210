import * as R from "../../../routings/path";
import DateTimeTableCell from "../../../components/table-cell-date-time";
import StatusTableCell from "../../../components/table-cell-status";
import ProductTableCell from "../../../components/table-cell-product";

export const productSchema = [
  {
    name: "Product",
    cell: (data: any) => (
      <ProductTableCell
        linkTo={`${R.PRODUCTS_LIST}/${data?.code}`}
        image={data?.image_url}
        title={data?.name}
        description={data?.gtin_code}
        maxWidth={400}
        imageSize={40}
      />
    ),
  },
  {
    name: "Created",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data?.created_at} />,
  },
  {
    name: "Status",
    align: "right",
    cell: (data: any) => <StatusTableCell status={data?.status} />,
  },
];
