import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as R from "../../routings/path";
import {
  IcreateRelocationShipment,
  createRelocationShipment,
} from "../../apis/paths/relocationShipment";
import { apiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";

import alert from "../../utils/alert";
import UiButton from "../../components/ui-button";
import MultiStepPanel from "../../components/panel-multi-step";
import HeaderInfoPanel from "../../components/panel-header-info";

import { relocationShippmentSchema } from "./schema/schema";

import ViewStep1 from "./step/view-step-1";
import ViewStep2 from "./step/view-step-2";
import ViewStep3 from "./step/view-step-3";
import ViewStep4 from "./step/view-step-4";

const Component = () => {
  const navigate = useNavigate();
  const [multiStepPanelTabIndex, setMultiStepPanelTabIndex] = useState(0);
  const [selectedFromWarehouse, setSelectedFromWarehouse] = useState<any>(null);
  const [selectedToWarehouse, setSelectedToWarehouse] = useState<any>(null);
  const [isLoading, setLoading] = useState(false);

  const {
    setValue,
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(relocationShippmentSchema),
    mode: "onChange",
  });

  const fromWareHouseUuid = watch("from_warehouse_uuid");
  const toWareHouseUuid = watch("to_warehouse_uuid");
  const productStatus = watch("product_status");

  const handleSelectedToWarehouse = (data: any) => {
    setSelectedToWarehouse(data);
    setValue("to_warehouse_uuid", data?.uuid);
  };

  const handleSelectedFromWarehouse = (data: any) => {
    setSelectedFromWarehouse(data);
    setValue("from_warehouse_uuid", data?.uuid);
  };

  const onSubmit: SubmitHandler<IcreateRelocationShipment> = async ({
    from_warehouse_uuid,
    to_warehouse_uuid,
    product_status,
    shiping_service,
    note,
    relocation_shipment_products,
  }) => {
    setLoading(true);

    const formatedOptionRelocationShipmentProducts =
      relocation_shipment_products?.map((product) => {
        const { product_gtin_code, product_uuid, quantity } = product || {};

        if (!!product_gtin_code && !!quantity) {
          return { product_gtin_code, quantity };
        }

        return { product_uuid, quantity };
      });

    const waitedCreateRelocationShipment = await apiCaller({
      api: createRelocationShipment,
      params: {
        from_warehouse_uuid,
        to_warehouse_uuid,
        product_status,
        shiping_service,
        note,
        relocation_shipment_products: formatedOptionRelocationShipmentProducts,
      } as IcreateRelocationShipment,
    });
    setLoading(false);

    const { status } = waitedCreateRelocationShipment;
    if (status === API_RESPONSE_STATUS.SUCCESS) {
      navigate(R.RELOCATION_SHIPMENTS_LIST);
    }
  };

  const isToWarehouseChildOfFromWarehouse =
    selectedToWarehouse?.parent_warehouse?.uuid === selectedFromWarehouse?.uuid;

  return (
    <>
      <HeaderInfoPanel
        title={"Create new relocation shipment"}
        description={"Input info below to create a new relocation shipment"}
        actionArea={() => (
          <>
            <UiButton
              onClick={handleSubmit(onSubmit)}
              className="me-2"
              isLoading={isLoading}
              title={"Create"}
            />
          </>
        )}
      />
      <MultiStepPanel
        defaultTab={multiStepPanelTabIndex}
        onChangeTab={(index: number) => {
          setMultiStepPanelTabIndex(index);
        }}
        onStaticCheckStepValidatation={(index: number) => {
          let isValidationPassed = true;
          if (
            multiStepPanelTabIndex === 0 &&
            index === 1 &&
            !fromWareHouseUuid
          ) {
            isValidationPassed = false;
            alert.error(`Please choose "From Warehouse"`);
            return isValidationPassed;
          }

          if (multiStepPanelTabIndex === 1 && index === 2 && !toWareHouseUuid) {
            isValidationPassed = false;
            alert.error(`Please choose "To Warehouse"`);
            return isValidationPassed;
          }

          if (multiStepPanelTabIndex === 2 && index === 3 && !productStatus) {
            isValidationPassed = false;
            alert.error(`Please select "Product Status"`);
            return isValidationPassed;
          }

          return isValidationPassed;
        }}
        tabs={["1. From", "2. To", "3. Infomations", "4. Products"]}
        subContentTabs={[
          selectedFromWarehouse?.name,
          selectedToWarehouse?.name,
        ]}
        contentHeadings={[
          `CHOOSE FROM WAREHOUSE`,
          "CHOOSE TO WAREHOUSE",
          "INPUT INFORMATIONS",
          "CHOOSE PRODUCT",
        ]}
        contentCol={12}
      >
        <ViewStep1
          onSelectWarehouse={handleSelectedFromWarehouse}
          selectedFromWarehouse={selectedFromWarehouse}
        />
        <ViewStep2
          onSelectWarehouse={handleSelectedToWarehouse}
          selectedFromWarehouse={selectedFromWarehouse}
          selectedToWarehouse={selectedToWarehouse}
        />
        <ViewStep3
          register={register}
          control={control}
          errors={errors}
          isToWarehouseChildOfFromWarehouse={isToWarehouseChildOfFromWarehouse}
        />
        <ViewStep4 setValue={setValue} />
      </MultiStepPanel>
    </>
  );
};

export default Component;
