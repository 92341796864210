import { useNavigate } from "react-router-dom";

import * as R from "../../routings/path";
import { ACTIONABLE_TYPE } from "../../constants/actionable";
import { getShopListApi } from "../../apis/paths/shop";

import TablePanel from "../../components/panel-table";

import { schema } from "./schema";

const Component = () => {
  const navigate = useNavigate();

  return (
    <TablePanel
      apiPath={getShopListApi}
      isSearchable={false}
      panel={{
        title: "Shops",
        description: "List of Shops in the warehouse",
        actionableButtons: [
          {
            actionableType: ACTIONABLE_TYPE.NEW,
            title: "New Shop",
            onClick: () => navigate(R.SHOP_CREATE),
          },
        ],
      }}
      tableSchema={schema()}
    />
  );
};

export default Component;
