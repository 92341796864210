import * as R from "../../routings/path";
import { TABLE_ACTION } from "../../constants/table-action";
import { GENERAL_STATUS } from "../../constants/status";
import { GENERAL_UI_COLOR } from "../../constants/color";
import DateTimeTableCell from "../../components/table-cell-date-time";
import { generateGoogleMapLink } from "../../utils/url";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import ActionTableCell from "../../components/table-cell-action";

interface ISchemaProps {
  onRestore?: any;
}

export const deletedWarehouseSchema = ({ onRestore }: ISchemaProps) => [
  {
    name: "Warehouse",
    cell: (data: any) => (
      <ProductTableCell
        title={data.name}
        linkTo={`${R.WAREHOUSES_LIST}/${data.uuid}`}
        description={`#${data.code}`}
        maxWidth={200}
      />
    ),
  },
  {
    name: "Contact",
    cell: (data: any) => (
      <ProductTableCell
        subIcon={"fa-phone"}
        linkTo={`tel:${data.contact_phone}`}
        title={data.contact_name}
        description={data.contact_phone}
      />
    ),
  },
  {
    name: "Valuable",
    align: "center",
    cell: (data: any) => (
      <StatusTableCell
        status={data.valuable ? GENERAL_STATUS.VALUABLE : GENERAL_STATUS.EMPTY}
      />
    ),
  },
  {
    name: "Storage Type",
    align: "center",
    cell: (data: any) => (
      <StatusTableCell
        status={data.storage_type}
        type={GENERAL_UI_COLOR.SECONDARY}
      />
    ),
  },
  {
    name: "Address",
    cell: (data: any) => (
      <ProductTableCell
        linkTo={generateGoogleMapLink({
          latitude: data.address.latitude,
          longitude: data.address.longitude,
        })}
        linkTarget={"_blank"}
        subIcon={"fa-location-dot"}
        description={data.address.full_address}
        descriptionLine={2}
        maxWidth={150}
      />
    ),
  },
  {
    name: "Created",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data.created_at} />,
  },
  {
    name: "Updated",
    align: "center",
    sortTypes: {
      key: "updated_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data.updated_at} />,
  },
  {
    name: "Action",
    align: "right",
    cell: (data: any) => (
      <ActionTableCell
        viewLink={`${R.WAREHOUSES_LIST}/${data.uuid}`}
        editLink={`${R.WAREHOUSES_LIST}/${data.uuid}/edit`}
        toggleData={
          data.deleted_at ? GENERAL_STATUS.DEACTIVE : GENERAL_STATUS.ACTIVATE
        }
        types={[TABLE_ACTION.RESTORE]}
        onRestore={() => onRestore(data.uuid)}
      />
    ),
  },
];
