import { INote } from "../../components/panel-note/types";
import NotePosting from "../../components/panel-note/view-note-posting";
import styles from "./styles.module.scss";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useApiCaller } from "../../apis/config";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { getPurchaseOrderDetailApi } from "../../apis/paths/purchase-order";
import TableCellStatus from "../../components/table-cell-status";
import HeaderInfoPanel from "../../components/panel-header-info";

const Component = () => {
  const { purchaseOrderId: uuid } = useParams();
  const [purchaseOrderInfo, setPurchaseOrderInfo] = useState<any>({});
  const [params] = useState({ uuid });

  const { result } = useApiCaller({
    api: getPurchaseOrderDetailApi,
    params: params,
  });

  useEffect(() => {
    if (result?.data) {
      setPurchaseOrderInfo(result?.data);
    }
  }, [result]);
  
  const purchaseOrderStatus = purchaseOrderInfo?.purchase_order?.status;

  return (
    <div className="col-12">
      <HeaderInfoPanel
        isDisplayInfoArea={true}
        title={`#${purchaseOrderInfo?.purchase_order?.number}`}
        infoArea={() => (
          <div className="d-flex gap-2">
            <TableCellStatus
              status={purchaseOrderStatus}
              size={GENERAL_UI_SIZE.SMALL}
            />
            <TableCellStatus
              status={purchaseOrderInfo?.purchase_order?.product_status}
              size={GENERAL_UI_SIZE.SMALL}
            />
          </div>
        )}
      />
      <div className="card h-100 mt-4 ">
        <div className="card-body">
          <div className="mb-1 mt-3">
            <div className={styles.container}>
              {result?.data?.purchase_order?.additional_notes?.reverse()?.map(
                (note: INote) => (
                  <div key={note.id}>
                    <NotePosting
                      note={note}
                      uuid={uuid as string}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component;
