import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { generateUrlParams } from "../../utils/url";
import { get, patch, post, put } from "../config";

interface IGetPurchaseOrderListApi {
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
  filter?: object | null;
}

export const getPickingListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
  filter = DEFAULT_PAGINATION_PARAMS.filter,
}: IGetPurchaseOrderListApi) =>
  get({
    path: `/wms/pick_lists${generateUrlParams({
      page,
      per,
      keyword,
      sort,
      filter,
    })}`,
  });

export const getPickingDetailApi = ({ uuid }: { uuid: string }) =>
  get({
    path: `/wms/pick_lists/${uuid}`,
  });

export const updatePickingStatusApi = ({
  uuid,
  action,
}: {
  uuid: string;
  action: string;
}) =>
  patch({
    path: `/wms/pick_lists/${uuid}/status`,
    data: {
      uuid,
      action,
    },
  });

export const createNewPickListApi = ({
  warehouseUuid,
  saleOrderUuids,
}: {
  warehouseUuid: string;
  saleOrderUuids: Array<string>;
}) => {
  return post({
    path: `/wms/pick_lists`,
    data: {
      warehouse_uuid: warehouseUuid,
      sale_order_uuids: saleOrderUuids,
    },
  });
};

export const getPickingListSelectionApi = () =>
  get({
    path: `/wms/pick_lists/selection`,
  });

export const updatePickListSaleorderApi = ({
  uuid,
  saleOrderUuids,
}: {
  uuid: string;
  saleOrderUuids: string[];
}) => {
  return put({
    path: `/wms/pick_lists/${uuid}/sale_orders`,
    data: {
      sale_order_uuids: saleOrderUuids,
    },
  });
};

export const getSaleOrderForPickingListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
  filter = DEFAULT_PAGINATION_PARAMS.filter,
  productFilterUuids,
  productFilterOperator,
}: {
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
  filter?: object | null;
  productFilterUuids: Array<string>;
  productFilterOperator: string;
}) => {
  return get({
    path: `/wms/pick_lists/sale_orders${generateUrlParams({
      product_filter_uuids: productFilterUuids,
      product_filter_operator: productFilterOperator,
      page,
      per,
      keyword,
      sort,
      filter,
    })}`,
  });
};

export const getFilterableSaleOrderForPickingListApi = () => {
  return get({
    path: `/wms/pick_lists/sale_orders/filterable`,
  });
};
