import { useState } from "react";

import { useApiCaller } from "../../apis/config";
import { getRoleDetailApi } from "../../apis/paths/roles";
import { getAccountDetailApi } from "../../apis/paths/accounts";
import { getProductTypeDetailApi } from "../../apis/paths/products";
import { getTplRoutesDetailApi } from "../../apis/paths/tpl-routes";
import { getMaterialDetailApi } from "../../apis/paths/material";
import { getTaskLoggingDetail } from "../../apis/paths/taskLogging";
import { splitAndCapitalize } from "../../containers/task-logging-create/utils";

import { getPurchaseOrderDetailApi } from "../../apis/paths/purchase-order";
import { getPackingDetailApi } from "../../apis/paths/packing";
import { getSaleOrderDetailApi } from "../../apis/paths/sale-order";
import { getWarehouseDetailApi } from "../../apis/paths/warehouse";
import { getPickingDetailApi } from "../../apis/paths/picking";
import { getDispatchDetailApi } from "../../apis/paths/dispatch";
import { getRelocationShipmentDetailApi } from "../../apis/paths/relocationShipment";
import { getShopDetailApi } from "../../apis/paths/shop";

export const DynamicRoleBreadcrumb = ({ match }: any) => {
  const [breacrumbParams] = useState<{
    page: number;
    per: number;
    uuid: string;
  }>({
    page: 1,
    per: 50,
    uuid: match?.params?.roleId,
  });

  const { result, loading } = useApiCaller({
    api: getRoleDetailApi,
    params: breacrumbParams,
  });

  return (
    <>
      {loading ? (
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        result?.data?.role?.name
      )}
    </>
  );
};

export const DynamicProductBreadcrumb = ({ match }: any) => {
  return <>{match?.params?.productCode}</>;
};

export const DynamicWarehouseBreadcrumb = ({ match }: any) => {
  const [params] = useState<{
    code: string;
  }>({
    code: match?.params?.warehouseId,
  });

  const { result, loading } = useApiCaller({
    api: getWarehouseDetailApi,
    params: params,
  });

  return (
    <>
      {loading ? (
        <div className="spinner-border text-primary small" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        result?.data?.name
      )}
    </>
  );
};

export const DynamicPickingBreadcrumb = ({ match }: any) => {
  const [params] = useState<{
    uuid: string;
  }>({
    uuid: match?.params?.pickingId,
  });

  const { result, loading } = useApiCaller({
    api: getPickingDetailApi,
    params: params,
  });

  return (
    <>
      {loading ? (
        <div className="spinner-border text-primary small" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        result?.data?.code
      )}
    </>
  );
};

export const DynamicProductTypeBreadcrumb = ({ match }: any) => {
  const [params] = useState<{
    page: number;
    per: number;
    id: string;
  }>({
    page: 1,
    per: 50,
    id: match?.params?.typeId,
  });

  const { result, loading } = useApiCaller({
    api: getProductTypeDetailApi,
    params,
  });

  return (
    <>
      {loading ? (
        <div className="spinner-border text-primary small" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        result?.data?.name
      )}
    </>
  );
};

export const DynamicPurchaseOrderBreadcrumb = ({ match }: any) => {
  const [params] = useState<{
    page: number;
    per: number;
    uuid: string;
  }>({
    page: 1,
    per: 50,
    uuid: match?.params?.purchaseOrderId,
  });

  const { result, loading } = useApiCaller({
    api: getPurchaseOrderDetailApi,
    params: params,
  });

  return (
    <>
      {loading ? (
        <div className="spinner-border text-primary small" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        result?.data?.purchase_order?.number
      )}
    </>
  );
};

export const DynamicSaleOrderBreadcrumb = ({ match }: any) => {
  const [params] = useState<{
    uuid: string;
  }>({
    uuid: match?.params?.saleOrderId,
  });

  const { result, loading } = useApiCaller({
    api: getSaleOrderDetailApi,
    params: params,
  });

  return (
    <>
      {loading ? (
        <div className="spinner-border text-primary small" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        result?.data?.number
      )}
    </>
  );
};

export const DynamicRelocationShipmentDetailBreadcrumb = ({ match }: any) => {
  const [params] = useState<{
    uuid: string;
  }>({
    uuid: match?.params?.relocationShipmentId,
  });

  const { result, loading } = useApiCaller({
    api: getRelocationShipmentDetailApi,
    params: params,
  });

  return (
    <>
      {loading ? (
        <div className="spinner-border text-primary small" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        result?.data?.name
      )}
    </>
  );
};

export const DynamicAccountBreadcrumb = ({ match }: any) => {
  const [params] = useState<{
    page: number;
    per: number;
    uuid: string;
  }>({
    page: 1,
    per: 50,
    uuid: match?.params?.accountId,
  });

  const { result, loading } = useApiCaller({
    api: getAccountDetailApi,
    params,
  });

  return (
    <>
      {loading ? (
        <div className="spinner-border text-primary small" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        result?.data?.name
      )}
    </>
  );
};

export const DynamicTplBreadcrumb = ({ match }: any) => {
  const [params] = useState<{
    page: number;
    per: number;
    id: string;
  }>({
    page: 1,
    per: 50,
    id: match?.params?.routeId,
  });
  const { result, loading } = useApiCaller({
    api: getTplRoutesDetailApi,
    params,
  });

  return (
    <>
      {loading ? (
        <div className="spinner-border text-primary small" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        result?.data?.route?.name
      )}
    </>
  );
};

export const DynamicMaterialBreadcrumb = ({ match }: any) => {
  const [params] = useState<{
    page: number;
    per: number;
    code: string;
  }>({
    page: 1,
    per: 50,
    code: match?.params?.materialId,
  });
  const { result, loading } = useApiCaller({
    api: getMaterialDetailApi,
    params,
  });

  return (
    <>
      {loading ? (
        <div className="spinner-border text-primary small" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        result?.data?.name
      )}
    </>
  );
};

export const DynamicShopBreadcrumb = ({ match }: any) => {
  const [params] = useState<{
    page: number;
    per: number;
    uuid: string;
  }>({
    page: 1,
    per: 50,
    uuid: match?.params?.shopId,
  });
  const { result, loading } = useApiCaller({
    api: getShopDetailApi,
    params,
  });

  return (
    <>
      {loading ? (
        <div className="spinner-border text-primary small" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        result?.data?.name
      )}
    </>
  );
};

export const DynamicTasksBreadcrumb = ({ match }: any) => {
  const [params] = useState<{
    page: number;
    per: number;
    uuid: string;
  }>({
    page: 1,
    per: 50,
    uuid: match?.params?.taskId,
  });

  const { result, loading } = useApiCaller({
    api: getTaskLoggingDetail,
    params,
  });

  return (
    <>
      {loading ? (
        <div className="spinner-border text-primary small" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        splitAndCapitalize(result?.data?.name)
      )}
    </>
  );
};

export const DynamicDispatchBreadcrumb = ({ match }: any) => {
  const [params] = useState<{
    page: number;
    per: number;
    uuid: string;
  }>({
    page: 1,
    per: 50,
    uuid: match?.params?.dipatchId,
  });

  const { result, loading } = useApiCaller({
    api: getDispatchDetailApi,
    params,
  });

  return (
    <>
      {loading ? (
        <div className="spinner-border text-primary small" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        splitAndCapitalize(result?.data?.location?.code)
      )}
    </>
  );
};

export const DynamicPackingBreadcrumb = ({ match }: any) => {
  const [params] = useState<{
    page: number;
    per: number;
    uuid: string;
  }>({
    page: 1,
    per: 50,
    uuid: match?.params?.packingId,
  });

  const { result, loading } = useApiCaller({
    api: getPackingDetailApi,
    params,
  });

  return (
    <>
      {loading ? (
        <div className="spinner-border text-primary small" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        splitAndCapitalize(result?.data?.code)
      )}
    </>
  );
};
