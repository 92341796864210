import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { apiCaller, useApiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import {
  getAccountDevicesApi,
  toogleDeviceStatusApi,
} from "../../apis/paths/accounts";
import { GENERAL_STATUS } from "../../constants/status";
import { ACCOUNT_LIST } from "../../routings/path";
import alert from "../../utils/alert";
import TablePanel from "../../components/panel-table";

import { schema } from "./schema";

interface IStatusState {
  [key: string]: GENERAL_STATUS.ACTIVATE | GENERAL_STATUS.DEACTIVE;
}

const Component = () => {
  const [devicesData, setdevicesData] = useState([]);
  const location = useLocation();
  const { accountId } = useParams();
  const uuid = accountId || "";

  const isDisabled =
    location?.pathname === `${ACCOUNT_LIST}/${accountId}/devices`;

  const [params] = useState<{
    page: number;
    per: number;
    uuid: string;
  }>({
    page: 1,
    per: 50,
    uuid,
  });

  const { result: devices } = useApiCaller({
    api: getAccountDevicesApi,
    params,
  });

  useEffect(() => {
    setdevicesData(devices?.data?.list);
  }, [devices]);

  const initializeDeviceObj = (devicesData: any) => {
    const resultObj: IStatusState = {};
    devicesData?.forEach(
      ({
        uuid,
        status,
      }: {
        uuid: string;
        status: GENERAL_STATUS.ACTIVATE | GENERAL_STATUS.DEACTIVE;
      }) => {
        resultObj[uuid] = status;
      }
    );

    return resultObj;
  };

  const [deviceStatus, setDeviceStatus] = useState<IStatusState>(
    initializeDeviceObj(devicesData)
  );

  useEffect(() => {
    const deviceObj = initializeDeviceObj(devicesData);
    setDeviceStatus(deviceObj);
  }, [devicesData]);

  const handleToggleStatus = async (device_uuid: string) => {
    const waitToggle = await apiCaller({
      api: toogleDeviceStatusApi,
      params: { uuid, device_uuid },
    });

    if (waitToggle.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update status successful!");

      setDeviceStatus((prevState) => ({
        ...prevState,
        [device_uuid]:
          prevState[device_uuid] === GENERAL_STATUS.ACTIVATE
            ? GENERAL_STATUS.DEACTIVE
            : GENERAL_STATUS.ACTIVATE,
      }));

      return;
    }
    alert.error("Change status was not successful!. Please try again");
  };

  return (
    <>
      <div className="card card-profile-bottom">
        <TablePanel
          apiPath={() => getAccountDevicesApi({ uuid })}
          isDisplayedDataTopTable={false}
          panel={{
            title: "Devices",
            description: "Accounts management system",
          }}
          tableSchema={schema({
            onToggle: handleToggleStatus,
            deviceStatus,
            uuid,
            isDisabled,
          })}
        />
      </div>
    </>
  );
};

export default Component;
