import { useState } from "react";
import { useParams } from "react-router-dom";

import HeaderInfoPanel from "../../components/panel-header-info";
import TableCellStatus from "../../components/table-cell-status";
import GeneralPanel from "../../components/panel-general";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import UiContentRow from "../../components/ui-content-row";
import UiStatusDropdown from "../../components/ui-status-dropdown";

import { formatDateTime } from "../../utils/date-time";
import { useApiCaller } from "../../apis/config";
import {
  getPickingDetailApi,
  updatePickingStatusApi,
} from "../../apis/paths/picking";
import { GENERAL_UI_SIZE } from "../../constants/size";

import styles from "./styles.module.scss";

const Component = () => {
  const { pickingId: uuid } = useParams();

  const [pageReloadVersion, setPageReloadVersion] = useState(0);

  const [params] = useState({ uuid });

  const { result } = useApiCaller({
    api: getPickingDetailApi,
    params,
    pageReloadVersion,
  });

  const pickingDetail = result?.data;

  return (
    <>
      <HeaderInfoPanel
        title={`#${pickingDetail?.code}`}
        infoArea={() => (
          <div className={styles.headerInfoArea}>
            <TableCellStatus
              className="me-2"
              status={pickingDetail?.status}
              size={GENERAL_UI_SIZE.SMALL}
            />
          </div>
        )}
        actionArea={() => (
          <>
            {pickingDetail?.status && (
              <UiStatusDropdown
                status={pickingDetail?.status}
                triggerableEvents={pickingDetail?.triggerable_events}
                actionApi={updatePickingStatusApi}
                paramsTransformation={(newEvent: string) => ({
                  uuid: uuid,
                  action: newEvent,
                })}
                onChangeSuccess={() => {
                  setPageReloadVersion((prev) => prev + 1);
                }}
              />
            )}
          </>
        )}
      />

      <GeneralPanel className="pt-4" containerClassName="mt-4">
        <TwoColumnGridLayout
          leftCol={
            <div>
              <UiContentRow label="Created by">
                {`@${pickingDetail?.creator?.username}`}
              </UiContentRow>
            </div>
          }
          rightCol={
            <div>
              <UiContentRow label="Created at">
                {formatDateTime(pickingDetail?.created_at, "HH:mm YYYY-MM-DD")}
              </UiContentRow>
              <UiContentRow label="Updated at">
                {formatDateTime(pickingDetail?.updated_at, "HH:mm YYYY-MM-DD")}
              </UiContentRow>
            </div>
          }
        />
      </GeneralPanel>
    </>
  );
};

export default Component;
