import React from 'react'
import PanelGeneral from '../../components/panel-general/component'



const MemberRoleCreate = () => {
  return (
    <PanelGeneral
    panel={{
      title: "Members",
      description: "No members",
    }}
  >
  </PanelGeneral>
  )
}

export default MemberRoleCreate