import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { apiCaller, useApiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { WAREHOUSES_LIST } from "../../routings/path";
import { IOption } from "../material-detail/constants";
import { getDynamicDocsApi } from "../../apis/paths/settings";
import {
  ICreateWarehouseLocationApi,
  createWarehouseLocationApi,
} from "../../apis/paths/warehouse";
import alert from "../../utils/alert";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import HeaderInfoPanel from "../../components/panel-header-info";
import GeneralPanel from "../../components/panel-general";
import UiButton from "../../components/ui-button";
import UiInput from "../../components/ui-input";
import UiSelectDropdown, {
  IOptionItem,
} from "../../components/ui-select-dropdown/component";

import { schema } from "./schema";

const WAREHOUSE_LOCATION_DOC_PATH = "/wms/warehouses/{code}/locations";

const Component = () => {
  const urlParams = useParams();
  const warehouseId = urlParams?.warehouseId || "";

  const [params] = useState<{
    page: number;
    per: number;
  }>({
    page: 1,
    per: 50,
  });
  const { result: docs } = useApiCaller({
    api: getDynamicDocsApi,
    params,
  });

  const warehouseLocationFields =
    docs?.data?.paths?.[WAREHOUSE_LOCATION_DOC_PATH]?.post?.parameters;

  const productTypeParameters = warehouseLocationFields?.filter(
    (paramerter: any) => paramerter.name === "location_type"
  )[0]?.enum;

  const formatText = (string: string) => {
    return string
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const formatLocationType = productTypeParameters?.map((item: any) => ({
    title: formatText(item),
    value: item,
  }));

  const formSchema = schema?.(productTypeParameters);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: "onBlur",
  });

  const navigate = useNavigate();

  const onSubmit = async ({
    location_code,
    location_type,
    height,
    length,
    width,
    zone,
  }: any) => {
    const waitCreateWarehouseLocation = await apiCaller({
      api: createWarehouseLocationApi,
      params: {
        location_code,
        location_type,
        height,
        length,
        width,
        zone,
        code: warehouseId,
      } as ICreateWarehouseLocationApi,
    });

    if (waitCreateWarehouseLocation.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Create successful!");
      setTimeout(() => {
        navigate(`${WAREHOUSES_LIST}/${warehouseId}/locations`);
      }, 500);

      return;
    }

    alert.error("Create was not successful!. Please try again!");
  };

  const formatOptionItems = (data: IOption[]) =>
    data?.map((option) => ({
      value: option?.value,
      content: option?.title,
    }));

  return (
    <div className="card-profile-bottom ">
      <HeaderInfoPanel
        title={"Create New Location"}
        description="Input info below to create a new location"
        actionArea={() => (
          <>
            <UiButton
              onClick={handleSubmit(onSubmit)}
              className="me-2"
              title={"Create"}
            />
          </>
        )}
      />

      <div className="mt-4">
        <GeneralPanel panel={{ subTitle: "Location Information" }} className="">
          <TwoColumnGridLayout
            className={{ container: "px-0" }}
            leftCol={
              <>
                <UiInput
                  title={"Location Code"}
                  name={"location_code"}
                  required
                  register={register}
                  errorMessage={errors?.location_code?.message}
                />
                <UiInput
                  title={"Zone"}
                  name={"zone"}
                  register={register}
                  errorMessage={errors?.zone?.message}
                />
                <UiSelectDropdown
                  name="location_type"
                  title="Location Type"
                  required
                  isDisplayLabel
                  register={register}
                  optionList={
                    formatOptionItems(formatLocationType) as IOptionItem[]
                  }
                  errorMessage={errors?.location_type?.message as string}
                />
              </>
            }
            rightCol={
              <>
                <UiInput
                  title={"Height"}
                  name={"height"}
                  type="number"
                  register={register}
                  errorMessage={errors?.height?.message}
                />
                <UiInput
                  title={"Length"}
                  name={"length"}
                  type="number"
                  register={register}
                  errorMessage={errors?.length?.message}
                />
                <UiInput
                  title={"Width"}
                  name={"width"}
                  type="number"
                  register={register}
                  errorMessage={errors?.width?.message}
                />
              </>
            }
          />
        </GeneralPanel>
      </div>
    </div>
  );
};

export default Component;
