import { useState } from "react";
import { NavLink } from "react-router-dom";

import UiButton from "../../components/ui-button";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { GENERAL_UI_COLOR } from "../../constants/color";

import styles from "./styles.module.scss";

interface ITimelineItem {
  icon: string;
  colorIcon?: string;
  title: string;
  subTitle?: string;
  description?: string;
  extraContent?: string;
  extraElement?: any;
  linkTo?: string;
  linkTarget?: string;
}

interface IComponent {
  displayLimit?: number;
  list: Array<ITimelineItem>;
}

const Component = ({ displayLimit = 3, list }: IComponent) => {
  const [collapsed, setCollapse] = useState<boolean>(!!displayLimit);

  return (
    <div className={styles.container}>
      {(!list || list?.length === 0) && <div className="mb-3">No data</div>}
      <div
        className="timeline timeline-one-side"
        data-timeline-axis-style="dotted"
      >
        {list
          ?.filter((_, index) =>
            !displayLimit ? true : !collapsed || displayLimit > index
          )
          ?.map(
            ({
              icon,
              colorIcon = "text-primary",
              title,
              subTitle,
              description,
              extraContent,
              extraElement,
              linkTo,
              linkTarget,
            }: ITimelineItem) => {
              const WrapItem = !!linkTo?.length
                ? ({ children }: any) => (
                    <NavLink
                      className={`timeline-block mb-3 ${styles.notificationItem}`}
                      to={linkTo}
                      target={linkTarget}
                    >
                      {children}
                    </NavLink>
                  )
                : ({ children }: any) => (
                    <div
                      className={`timeline-block mb-3 ${styles.notificationItem}`}
                    >
                      {children}
                    </div>
                  );

              return (
                <WrapItem>
                  <span className="timeline-step">
                    <i
                      className={`fas fa-${icon} ${colorIcon} text-gradient`}
                    ></i>
                  </span>
                  <div className="timeline-content">
                    <h6 className="text-dark  mb-0">
                      <span className={"text-sm font-weight-bold pe-1"}>
                        {title}
                      </span>
                      <span className={"text-sm font-weight-normal"}>
                        {subTitle}
                      </span>
                    </h6>
                    <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                      {description}
                    </p>
                    <p className="text-sm mt-3 mb-2">{extraContent}</p>
                    <div>{extraElement}</div>
                  </div>
                </WrapItem>
              );
            }
          )}

        {!!displayLimit && list?.length > displayLimit && (
          <UiButton
            onClick={() => setCollapse((prev) => !prev)}
            title={collapsed ? "View more" : "View less"}
            size={GENERAL_UI_SIZE.SMALL}
            type={GENERAL_UI_COLOR.LIGHT}
          />
        )}
      </div>
    </div>
  );
};

export default Component;
