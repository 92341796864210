import * as yup from "yup";
import { validationMessage } from "../../utils/validation";

export const fieldTitle = {
  name: "Name",
  contactName: "Contact Name",
  contactPhone: "Contact Phone",
  valuable: "Valuable",
  parentWarehouseUuid: "Master Warehouse",
  storageType: "Storage Type",
  wardId: "Ward",
  streetName: "Street Name",
  detail: "Address Detail",
  latitude: "Latitude",
  longitude: "Longitude",
};

export type FieldSchema = keyof typeof fieldTitle;
export const masterWHschema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(fieldTitle[path as FieldSchema])
      ),
    contactName: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(fieldTitle[path as FieldSchema])
      ),
    contactPhone: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(fieldTitle[path as FieldSchema])
      ),
    valuable: yup.boolean(),
    parentWarehouseUuid: yup.string(),
    storageType: yup.string(),
    wardId: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(fieldTitle[path as FieldSchema])
      ),
    streetName: yup.string(),
    detail: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(fieldTitle[path as FieldSchema])
      ),
    latitude: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable(),
    longitude: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable(),
  })
  .required();
