import * as R from "../../routings/path";

import DateTimeTableCell from "../../components/table-cell-date-time";
import ProductTableCell from "../../components/table-cell-product";
import WarehouseTableCell from "../../components/table-cell-warehouse";
import ActionTableCell from "../../components/table-cell-action";
import NumberTableCell from "../../components/table-cell-number";

import { TABLE_ACTION } from "../../constants/table-action";
import { ACTIONABLE_TYPE } from "../../constants/actionable";
import UiButton from "../../components/ui-button/component";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { GENERAL_UI_SIZE } from "../../constants/size";

interface ISchemaProps {
  navigate?: any;
}

export const schema = ({ navigate }: ISchemaProps) => [
  {
    name: "Number",
    cell: (data: any) => (
      <ProductTableCell
        title={`#${data?.code}`}
        linkTo={`${R.PACKINGS_LIST}/${data?.uuid}`}
      />
    ),
  },
  {
    name: "Packages Qty",
    align: "center",
    textSize: "xs",
    width: 120,
    cell: (data: any) => (
      <NumberTableCell
        value={data?.total_sale_orders}
        className={{ content: "fs-5 fw-bold" }}
      />
    ),
  },
  {
    name: "Products Qty",
    align: "center",
    textSize: "xs",
    width: 120,
    cell: (data: any) => (
      <NumberTableCell
        value={data?.product_item_count}
        className={{ content: "fs-5 fw-bold" }}
      />
    ),
  },
  {
    name: "Warehouse",
    cell: (data: any) => {
      return (
        <WarehouseTableCell
          warehouse={data}
        />
      );
    },
  },
  {
    name: "Created",
    align: "center",
    width: 130,
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data?.created_at} />,
  },
  {
    name: "Action",
    align: "right",
    width: 150,
    cell: (data: any) =>
      !data?.packable ? (
        <ActionTableCell customTitle="Packed" types={[TABLE_ACTION.CUSTOM]} />
      ) : (
        <>
          <UiButton
            onClick={() => navigate(`${R.PACKINGS_LIST}/${data?.uuid}`)}
            title="Pack"
            size={GENERAL_UI_SIZE.SMALL}
            type={GENERAL_UI_COLOR.LIGHT}
          />
        </>
      ),
  },
];
