import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import ViewAdditinalNotes from "./view-additional-notes";
import PanelLabelController from "../../components/ui-panel-label-controller/component";
import GridLayoutTwoColumn from "../../components/grid-layout-two-column/component";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import HeaderInfoPanel from "../../components/panel-header-info";
import TableCellStatus from "../../components/table-cell-status";
import UiLoadingPanel from "../../components/ui-loading-panel";
import NotFoundPanel from "../../components/panel-not-found";
import GeneralPanel from "../../components/panel-general";
import UiContentRow from "../../components/ui-content-row";
import TableDataPanel from "../../components/panel-table-data";
import UiTypography from "../../components/ui-typography";
import UiTimeline from "../../components/ui-timeline";
import UiButton from "../../components/ui-button";

import * as R from "../../routings/path";
import { formatDateTime } from "../../utils/date-time";
import { GENERAL_UI_SIZE } from "../../constants/size";
import {
  IPrintPackageApi,
  getPurchaseOrderDetailApi,
  printPackageApi,
} from "../../apis/paths/purchase-order";
import { usePrinting } from "../../hooks/usePrinting";
import { splitAndCapitalize } from "../task-logging-create/utils";
import { apiCaller, useApiCaller } from "../../apis/config";
import { getStateActivitiesApi } from "../../apis/paths/activities";

import styles from "./styles.module.scss";
import { productSchema, packageSchema } from "./schema";
import UiContentHeading from "./view-section-heading";

const Component = () => {
  const navigate = useNavigate();

  const [selectedPackageToPrint, setSelectedPackageToPrint] = useState<any>([]);

  const { printUrl } = usePrinting();

  const { purchaseOrderId: uuid } = useParams();
  const [params, setParams] = useState({ uuid });
  const [stateActivityParams] = useState({
    linked_object_type: "PurchaseOrder",
    linked_object_uuid: uuid,
  });

  const { result, loading } = useApiCaller({
    api: getPurchaseOrderDetailApi,
    params: params,
  });

  const { result: stateActivities } = useApiCaller({
    api: getStateActivitiesApi,
    params: stateActivityParams,
  });
  const purchaseOrderDetail = result?.data;

  const handleSelectPackageToPrint = (data: any) => {
    const { uuid } = data;

    let newSelectedPackageToPrint;
    if (selectedPackageToPrint.indexOf(uuid) >= 0) {
      newSelectedPackageToPrint = selectedPackageToPrint.filter(
        (item: any) => item !== uuid
      );
    } else {
      newSelectedPackageToPrint = [...selectedPackageToPrint, uuid];
    }

    setSelectedPackageToPrint(newSelectedPackageToPrint);
  };

  const handlePrintPackage = async () => {
    const waitPrintPackage = await apiCaller({
      api: printPackageApi,
      params: {
        uuid,
        packageUuids: selectedPackageToPrint,
      } as IPrintPackageApi,
    });

    if (waitPrintPackage?.status === "SUCCESS") {
      waitPrintPackage?.data?.list?.forEach(async (link: any) => {
        await printUrl(link?.url, "label");
      });
    }
  };

  useEffect(() => {
    setParams({ uuid });
  }, [uuid]);

  if (loading) return <UiLoadingPanel />;

  if (result?.status === "ERROR") return <NotFoundPanel />;

  return (
    <>
      <HeaderInfoPanel
        title={`#${purchaseOrderDetail?.purchase_order?.number}`}
        infoArea={() => (
          <div className={styles.headerInfoArea}>
            <TableCellStatus
              className="me-2"
              status={purchaseOrderDetail?.purchase_order?.status}
              size={GENERAL_UI_SIZE.SMALL}
            />
            <TableCellStatus
              className="me-2"
              status={purchaseOrderDetail?.purchase_order?.product_status}
              size={GENERAL_UI_SIZE.SMALL}
            />
          </div>
        )}
        actionArea={() => (
          <UiButton
            onClick={() => navigate(`${R.PURCHASE_ORDERS_LIST}/${uuid}/edit`)}
            title={"Edit"}
          />
        )}
      />
      <GeneralPanel className="pt-4" containerClassName="mt-4">
        <GridLayoutTwoColumn
          widthDivision={[5, 7]}
          leftCol={
            <>
              <UiContentHeading>Timeline</UiContentHeading>
              <UiTimeline
                displayLimit={3}
                list={stateActivities?.data?.list?.map((activity: any) => ({
                  icon: "gear",
                  title: activity?.state_value,
                  subTitle: `by ${
                    activity?.account?.name || activity?.comment
                  } `,
                  description: formatDateTime(
                    activity?.changed_at,
                    "HH:mm:ss DD-MM-YYYY"
                  ),
                }))}
              />

              <div className="mt-5">
                <UiContentHeading>Additionals Notes</UiContentHeading>
                <div className="mt-3">
                  <ViewAdditinalNotes
                    displayLimit={3}
                    notes={
                      purchaseOrderDetail?.purchase_order?.additional_notes
                    }
                  />
                </div>
              </div>
            </>
          }
          rightCol={
            <>
              <UiContentHeading>Order Info</UiContentHeading>
              <div className="mb-4">
                <UiContentRow label={"Name:"}>
                  {purchaseOrderDetail?.purchase_order?.name}
                </UiContentRow>

                <UiContentRow label={"Reference Number:"}>
                  {purchaseOrderDetail?.purchase_order?.reference_number}
                </UiContentRow>

                <UiContentRow label="Created at:">
                  {formatDateTime(
                    purchaseOrderDetail?.purchase_order?.created_at
                  )}
                </UiContentRow>

                <UiContentRow label="Product Status:">
                  {splitAndCapitalize(
                    purchaseOrderDetail?.purchase_order?.product_status
                  )}
                </UiContentRow>
              </div>

              <UiContentHeading>Order Summary</UiContentHeading>
              <div className="mb-4">
                <UiContentRow label="SKUs:">
                  {purchaseOrderDetail?.purchase_order_summary?.number_of_skus}
                </UiContentRow>
                <UiContentRow label="Units:">
                  {purchaseOrderDetail?.purchase_order_summary?.number_of_items}
                </UiContentRow>
                <UiContentRow label="Weight (kg):">
                  {purchaseOrderDetail?.purchase_order_summary?.total_weight}
                </UiContentRow>
                <UiContentRow label="Capacity (m2):">
                  {purchaseOrderDetail?.purchase_order_summary?.total_capacity}
                </UiContentRow>
              </div>

              <UiContentHeading>Shipping Info</UiContentHeading>
              <div className="mb-4">
                <UiContentRow label="Receiver">_</UiContentRow>
                <UiContentRow label="Address">_</UiContentRow>
                <UiContentRow label="Phone number">_</UiContentRow>
              </div>

              <UiContentHeading>Order Note</UiContentHeading>
              <UiTypography customFontsize={14} className="mb-4">
                {purchaseOrderDetail?.purchase_order?.note || "—"}
              </UiTypography>

              <UiContentHeading>Labels</UiContentHeading>
              <div className="mt-3">
                <PanelLabelController
                  linkedObjectType="PurchaseOrder"
                  isEditable={false}
                  uniqueUuid={uuid as string}
                />
              </div>
            </>
          }
        />
      </GeneralPanel>
      <TwoColumnGridLayout
        className={{ container: "mx-0 px-0" }}
        leftCol={
          <TableDataPanel
            data={purchaseOrderDetail?.purchase_order_summary?.products}
            schema={productSchema}
          />
        }
        rightCol={
          <TableDataPanel
            data={purchaseOrderDetail?.purchase_order?.packages}
            displayHeadingAction={
              !!selectedPackageToPrint && !!selectedPackageToPrint.length
                ? [0]
                : null
            }
            schema={packageSchema({
              onSelectPackageToPrint: handleSelectPackageToPrint,
              onPrint: handlePrintPackage,
            })}
          />
        }
      />
    </>
  );
};

export default Component;
