import { useState, useEffect } from "react";

export const useLocalStorageExists = (key: string, refreshCount?: number) => {
  // State to store whether the key exists in local storage
  const [exists, setExists] = useState(() => {
    // Check if the key exists in local storage
    return localStorage.getItem(key) !== null;
  });

  // Update the state whenever the local storage changes
  useEffect(() => {
    const checkKeyExists = () => {
      setExists(localStorage.getItem(key) !== null);
    };

    // Listen for changes to local storage
    window.addEventListener("storage", checkKeyExists);

    return () => {
      window.removeEventListener("storage", checkKeyExists);
    };
  }, [key, refreshCount]);

  return exists;
};
