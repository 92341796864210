import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { apiCaller } from "../../apis/config";
import { getWarehouseListApi } from "../../apis/paths/warehouse";
import { getProductListApi } from "../../apis/paths/products";
import { PRODUCT_OVERALL_STATUS } from "../../constants/status";
import { PATH_TO_PURCHASE_ORDER_SAMPLE_FILE } from "../../constants/staticFile";
import { ICreateSaleOrder, createSaleOrder } from "../../apis/paths/sale-order";
import GeoSearchSelector, {
  IGeo,
} from "../../components/ui-geo-search-selector/component";
import * as R from "../../routings/path";
import alert from "../../utils/alert";
import TablePanel from "../../components/panel-table";
import UiButton from "../../components/ui-button";
import UiInput from "../../components/ui-input";
import UiTextArea from "../../components/ui-text-area";
import RadioComboBox from "../../components/ui-radio-combo-box/component";
import MultiStepPanel from "../../components/panel-multi-step";
import HeaderInfoPanel from "../../components/panel-header-info";
import Typography from "../../components/ui-typography/component";
import DownloadButton from "../../components/ui-download-button/component";
import FileImporter from "../../components/ui-file-uploader/component";
import UiSelectDropdown from "../../components/ui-select-dropdown/component";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import ThreeColumnGridLayout from "../../components/grid-layout-three-column";

import { infoSchema, warehouseSchema, productSchema } from "./schema";
import { CHANNEL } from "./constants";
import { fieldTitle } from "./schema";
import styles from "./styles.module.scss";
import SelectedProduct from "./view-selected-product";
import SelectedWarehouse from "./view-selected-warehouse";
import { transferArrayToObject } from "../../utils/data-transformation";
import { useExtractProductFromCsvFile } from "../../hooks/useExtractProductFromCsvFile";

const Component = () => {
  const navigate = useNavigate();

  const [inputingProductQty, setInputingProductQty] = useState<any>({});
  const [selectedChannel, setSelectedChannel] = useState<any>(
    CHANNEL[0]?.value
  );

  const [selectedWarehouse, setSelectedWarehouse] = useState<any>(null);
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const [multiStepPanelTabIndex, setMultiStepPanelTabIndex] = useState(0);
  const [isShowModal, setIsShowModal] = useState(false);
  const [wardValue, setWardValue] = useState<IGeo | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    control,
  } = useForm({
    resolver: yupResolver(infoSchema),
  });

  const { fetchProductFromCsvFile, extractedProduct } =
    useExtractProductFromCsvFile();

  useEffect(() => {
    const { products } = extractedProduct || {};
    if (Array.isArray(products) && products?.length > 0) {
      setSelectedProducts(products);
      setInputingProductQty(
        transferArrayToObject(products, "uuid", "quantity")
      );
    }
  }, [fetchProductFromCsvFile, extractedProduct]);

  const handleAddProduct = (newProduct: any) => {
    const isExist = selectedProducts.some(
      (product: any) => product.uuid === newProduct.uuid
    );
    if (isExist) return;

    setSelectedProducts([
      {
        ...newProduct,
        quantity: inputingProductQty?.[newProduct?.uuid] || 1,
      },
      ...selectedProducts,
    ]);
  };

  const handleUpdateProduct = (updatedProduct: any) => {
    const newProducts = selectedProducts.map((product: any) =>
      product.uuid !== updatedProduct.uuid ? product : updatedProduct
    );

    setSelectedProducts(newProducts);
  };

  const handleDeleteProduct = (deletedProduct: any) => {
    const newProducts = selectedProducts.filter(
      (product: any) => product.uuid !== deletedProduct.uuid
    );
    setSelectedProducts(newProducts);
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0 && multiStepPanelTabIndex !== 0) {
      alert.error("Please input some info");
      setMultiStepPanelTabIndex(0);
    }
  }, [errors]);

  const onUploadCsvFile = async (file: any) => {
    await fetchProductFromCsvFile(file);
  };

  const onSubmit = async (formData: any, e: any) => {
    if (!selectedWarehouse) {
      alert.error("Please select warehouse");
      setMultiStepPanelTabIndex(1);

      return;
    }

    if (!selectedProducts || !selectedProducts.length) {
      alert.error("Please select product");
      setMultiStepPanelTabIndex(2);

      return;
    }

    const apiParams = {
      selectedChannel,
      referenceNumber: formData["referenceNumber"],
      warehouseUuid: selectedWarehouse?.uuid,
      streetName: formData["streetName"],
      receiverName: formData["receiverName"],
      receiverPhone: formData["receiverPhone"],
      products: selectedProducts?.map((product: any) => ({
        code: product.uuid,
        quantity: product.quantity,
      })),
      addressDetail: formData["address_detail"],
      productStatus: formData["product_status"],
      wardId: wardValue?.key,
      cod: formData["cod"],
      value: formData["value"],
    };

    const waitCreatePurchaseOrder = await apiCaller({
      api: createSaleOrder,
      params: apiParams as ICreateSaleOrder,
    });

    if (waitCreatePurchaseOrder?.status === "SUCCESS") {
      navigate(`${R.SALE_ORDERS_LIST}/${waitCreatePurchaseOrder?.data?.uuid}`);
      return;
    }
  };

  return (
    <>
      <div className="mb-4">
        <HeaderInfoPanel
          isDisplayInfoArea={true}
          title={"Create a new Sale Order"}
          description="Please adding product and package info to create new order..."
          actionArea={() => (
            <UiButton
              className="me-2"
              title={"Create"}
              onClick={handleSubmit(onSubmit)}
            />
          )}
        />
      </div>

      <MultiStepPanel
        defaultTab={multiStepPanelTabIndex}
        onChangeTab={(index: number) => setMultiStepPanelTabIndex(index)}
        tabs={["1. Info", "2. Warehouse", "3. Product"]}
        contentHeadings={["SALE ORDER INFO", "WAREHOUSE INFO", "PRODUCTS INFO"]}
        contentCol={12}
      >
        <ThreeColumnGridLayout
          widthDivision={[4, 4, 4]}
          leftCol={
            <>
              <UiSelectDropdown
                title={fieldTitle["channel"]}
                name={"channel"}
                isDisplayLabel
                value={selectedChannel}
                setValue={(_: string, value: string) => {
                  setSelectedChannel(value);
                }}
                optionList={CHANNEL}
              />
              <UiInput
                title={fieldTitle["referenceNumber"]}
                name={"referenceNumber"}
                register={register}
                required
                errorMessage={errors?.referenceNumber?.message}
              />
              <RadioComboBox
                control={control}
                options={PRODUCT_OVERALL_STATUS}
                title={"Product Status"}
                name="product_status"
                errorMessage={errors?.product_status?.message}
              />
            </>
          }
          centerCol={
            <>
              <UiInput
                required
                title={fieldTitle["selectProvince"]}
                value={wardValue?.value}
                name={"wardId"}
                onClick={() => setIsShowModal(true)}
                register={register}
                errorMessage={errors?.wardId?.message}
              />
              <UiInput
                title={fieldTitle["streetName"]}
                name={"streetName"}
                register={register}
                required
                errorMessage={errors?.streetName?.message}
                onBlur={() => trigger("wardId")}
              />
              <UiTextArea
                title={fieldTitle["address_detail"]}
                name={"address_detail"}
                register={register}
                errorMessage={errors?.address_detail?.message}
              />
            </>
          }
          rightCol={
            <>
              <UiInput
                title={fieldTitle["receiverName"]}
                name={"receiverName"}
                register={register}
                required
                errorMessage={errors?.receiverName?.message}
              />
              <UiInput
                title={fieldTitle["receiverPhone"]}
                name={"receiverPhone"}
                register={register}
                required
                errorMessage={errors?.receiverPhone?.message}
              />
              <UiInput
                title={"COD"}
                name={"cod"}
                type="number"
                register={register}
                errorMessage={errors?.cod?.message}
              />
              <UiInput
                title={"Value"}
                name={"value"}
                type="number"
                register={register}
                errorMessage={errors?.value?.message}
              />
            </>
          }
        />
        <TwoColumnGridLayout
          widthDivision={[7, 5]}
          leftCol={
            <>
              <TablePanel
                apiPath={getWarehouseListApi}
                panelClassName={"mt-0"}
                type={"minimal"}
                tableSchema={warehouseSchema({
                  selectedWarehouse: selectedWarehouse,
                  onSelectWarehouse: setSelectedWarehouse,
                })}
              />
            </>
          }
          rightCol={
            <div>
              <div className={styles.selectWarehouse}>
                <SelectedWarehouse warehouse={selectedWarehouse} />
              </div>
            </div>
          }
        />
        <TwoColumnGridLayout
          widthDivision={[7, 5]}
          leftCol={
            <>
              <FileImporter
                className={{
                  container: "d-flex w-100 justify-content-start",
                  label: "",
                  input: "w-100",
                }}
                onFileUpload={(value) => {
                  onUploadCsvFile(value);
                }}
              />
              <DownloadButton
                href={PATH_TO_PURCHASE_ORDER_SAMPLE_FILE}
                className={{ container: "mt-1" }}
                downloadTitle="Saleorder_products"
                title={
                  <Typography
                    customFontsize={10}
                    fontWeight="fw-bold"
                    className="d-flex align-items-center gap-2"
                  >
                    <i className="fa-solid fa-download fa-sm"></i>
                    Download sample CSV
                  </Typography>
                }
              />
              <Typography fontWeight="fw-bold" className="my-2 text-center">
                Or
              </Typography>
              <TablePanel
                apiPath={getProductListApi}
                panelClassName={"mt-0"}
                type={"minimal"}
                tableSchema={productSchema({
                  onSelectProduct: handleAddProduct,
                  selectedProducts,
                  onUpdateItem: handleUpdateProduct,
                  onChangeProductQty: setInputingProductQty,
                  inputingProductQty,
                })}
              />
            </>
          }
          rightCol={
            <div className={styles.selectProduct}>
              <SelectedProduct
                label={"Selected Product"}
                products={selectedProducts}
                onDeleteItem={handleDeleteProduct}
                onUpdateItem={handleUpdateProduct}
              />
            </div>
          }
        />
      </MultiStepPanel>

      <GeoSearchSelector
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        onCancel={() => {
          setIsShowModal(false);
        }}
        setWardValue={setWardValue}
      />
    </>
  );
};

export default Component;
