import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useController } from "react-hook-form";

interface ISwitchProps {
  isEditable?: boolean;
  title?: string;
  isDisplayLabel?: boolean;
  name?: string;
  id?: string;
  readOnly?: boolean;
  className?: string;
  register?: any;
  errorMessage?: string;
  onChange?: (param: any) => any;
  checked?: boolean;
  control?: any;
}

const CellFormSwitch = ({
  isEditable = false,
  title,
  isDisplayLabel = true,
  checked = false,
  name = "",
  id = "",
  readOnly = false,
  className = "",
  register = null,
  errorMessage = "",
  control,
  onChange = () => {},
}: ISwitchProps) => {
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => setIsChecked(checked), [checked]);

  const {
    field: { value, onChange: onChangeInternal, onBlur },
  } = useController({
    name: name,
    control,
  });

  let registerData = {};
  if (register) registerData = register(name);

  const toggleCheck = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  };
  
  return isEditable ? (
    <>
      {isDisplayLabel && <label>{title || name}</label>}
      <div className={`d-flex ${className}`}>
        <div className={classNames("form-check form-switch")}>
          <input
            readOnly={readOnly}
            id={id}
            style={{ height: `calc(1rem + 4px)` }}
            autoComplete="off"
            {...registerData}
            className={classNames("form-check-input")}
            type="checkbox"
            value={value}
            checked={isChecked}
            onBlur={onBlur}
            onChange={(e) => {
              onChangeInternal(e);
              toggleCheck();
            }}
          />
        </div>
      </div>
      {errorMessage && errorMessage.length && (
        <label className="mb-0" style={{ color: "#f5365c" }}>
          {errorMessage}
        </label>
      )}
    </>
  ) : (
    <>
      {isDisplayLabel && <label>{title || name}</label>}
      <div className={`d-flex ${className} user-select-none pe-none`}>
        <div className={classNames("form-check form-switch ")}>
          <input
            aria-label={title}
            readOnly
            id={id}
            style={{ height: `calc(1rem + 4px)` }}
            autoComplete="off"
            className={classNames("form-check-input")}
            type="checkbox"
            checked={isChecked}
            onChange={toggleCheck}
          />
        </div>
      </div>
    </>
  );
};

export default CellFormSwitch;
