import { IShippingService } from "../shipping-services/types";
import { useApiCaller } from "../../apis/config";
import { OPTIONS_LENGTH } from "../tpl-routes-edit/constants";
import { getShippingServiceApi } from "../../apis/paths/shipping";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { IAccountUser } from "../../types/account";
import { detectRequiredlField } from "../../utils/validation";
import UiInput from "../../components/ui-input";
import PanelGeneral from "../../components/panel-general/component";
import NotFoundPanel from "../../components/panel-not-found/component";
import SelectDropdown from "../../components/ui-select-dropdown/component";

import { formSchema } from "./formSchema";

const EditTplFormInfo = ({
  register,
  errors,
  data,
  setValue,
}: {
  register: any;
  errors: any;
  data: IAccountUser;
  setValue?: any;
}) => {
  const { name } = data || {};

  const { result } = useApiCaller({
    api: getShippingServiceApi,
    params: DEFAULT_PAGINATION_PARAMS,
  });

  const shippingServices = result?.data?.list || [];

  if (shippingServices?.status === "ERROR") return <NotFoundPanel />;

  const allOptionItem = shippingServices?.map(
    (shippingService: IShippingService) => ({
      value: shippingService.code,
      content: shippingService.display_name,
    })
  );

  const priorityOptions = new Array(OPTIONS_LENGTH)
    .fill(null)
    .map((_, index) => ({ content: index, value: index }));

  return (
    <PanelGeneral
      panel={{
        title: "Route Info",
      }}
    >
      <UiInput
        className="dataTable-input"
        name={"name"}
        title={"Route Name"}
        required={detectRequiredlField(formSchema, "name")}
        placeholder={name}
        disabled
        isDisplayLabel={true}
        register={register}
        errorMessage={errors?.name?.message}
      />

      {allOptionItem?.length > 0 && (
        <SelectDropdown
          name="service"
          title="Shipping Service"
          isDisplayLabel
          required={detectRequiredlField(formSchema, "service")}
          register={register}
          setValue={setValue}
          disabled
          optionList={allOptionItem}
          errorMessage={errors?.service?.message}
        />
      )}

      {allOptionItem?.length > 0 && (
        <SelectDropdown
          name="priority"
          title="Priority"
          isDisplayLabel
          required={detectRequiredlField(formSchema, "priority")}
          register={register}
          setValue={setValue}
          disabled
          optionList={priorityOptions}
          errorMessage={errors?.priority?.message}
        />
      )}
    </PanelGeneral>
  );
};

export default EditTplFormInfo;
