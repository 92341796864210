import React from "react";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "../styles";

import { persistor, store } from "../states/store";
import router from "../routings/router";

const AppEntryPoint = () => {
  const root = createRoot(document.getElementById("root") as HTMLElement);

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RouterProvider router={router} />
          <ToastContainer />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
};
export default AppEntryPoint;
