/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { apiCaller, useApiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { PATH_TO_PURCHASE_ORDER_SAMPLE_FILE } from "../../constants/staticFile";
import { getProductListApi } from "../../apis/paths/products";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { transferArrayToObject } from "../../utils/data-transformation";
import { useExtractProductFromCsvFile } from "../../hooks/useExtractProductFromCsvFile";
import {
  IUpdateRelocationShipmentProducts,
  getRelocationShipmentDetailApi,
  updateRelocationShipmentProducts,
} from "../../apis/paths/relocationShipment";

import alert from "../../utils/alert";
import UiForm from "../../components/ui-form";
import UiButton from "../../components/ui-button";
import TablePanel from "../../components/panel-table";
import GeneralPanel from "../../components/panel-general/component";
import FileImporter from "../../components/ui-file-uploader/component";
import Typography from "../../components/ui-typography/component";
import DownloadButton from "../../components/ui-download-button/component";
import TableCellStatus from "../../components/table-cell-status";
import HeaderInfoPanel from "../../components/panel-header-info";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import useHandleSidebarStatus from "../../hooks/useSidebarStatus";

import { productSchema } from "./schema";
import styles from "./styles.module.scss";
import SelectedProduct from "./view-selected-product";

const Component = () => {
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const [inputingProductQty, setInputingProductQty] = useState<any>({});
  const [pageReloadVersion, setPageReloadVersion] = useState(1);

  const { dispatchSidebarStatus } = useHandleSidebarStatus();

  const { fetchProductFromCsvFile, extractedProduct, addDefaultProducts } =
    useExtractProductFromCsvFile();

  const { relocationShipmentId: uuid } = useParams();
  const [params] = useState({ uuid });

  const { result } = useApiCaller({
    api: getRelocationShipmentDetailApi,
    params: params,
    pageReloadVersion,
  });

  const relocationShipmentInfo = result?.data;

  useEffect(() => {
    if (
      Array.isArray(result?.data?.relocation_shipment_products) &&
      result?.data?.relocation_shipment_products?.length > 0
    ) {
      const flatenedProducts = result?.data?.relocation_shipment_products?.map(
        (productItem: any) => ({
          ...productItem?.product,
          quantity: productItem?.quantity,
        })
      );
      addDefaultProducts(flatenedProducts);
    }
  }, [result]);

  useEffect(() => {
    const { products } = extractedProduct || {};
    if (Array.isArray(products) && products?.length > 0) {
      setSelectedProducts(products);
      setInputingProductQty(
        transferArrayToObject(products, "uuid", "quantity")
      );
    }
  }, [fetchProductFromCsvFile, extractedProduct]);

  useEffect(() => {
    dispatchSidebarStatus({ isOpened: false });

    return () => {
      dispatchSidebarStatus({ isOpened: true });
    };
  }, []);

  const handleAddProduct = (newProduct: any) => {
    const isExist = selectedProducts.some(
      (product: any) => product.uuid === newProduct.uuid
    );
    if (isExist) return;

    setSelectedProducts([
      {
        ...newProduct,
        quantity: inputingProductQty?.[newProduct?.uuid] || 1,
      },
      ...selectedProducts,
    ]);
  };

  const handleUpdateProduct = (updatedProduct: any) => {
    const newProducts = selectedProducts.map((product: any) =>
      product.uuid !== updatedProduct.uuid ? product : updatedProduct
    );

    setSelectedProducts(newProducts);
  };

  const handleDeleteProduct = (deletedProduct: any) => {
    const newProducts = selectedProducts.filter(
      (product: any) => product.uuid !== deletedProduct.uuid
    );
    setSelectedProducts(newProducts);
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();

    const formatedProductPayload = selectedProducts?.map((product: any) => ({
      product_uuid: product?.uuid,
      quantity: product?.quantity,
    }));

    const waitUpdateRelocationShipmentProduct = await apiCaller({
      api: updateRelocationShipmentProducts,
      params: {
        uuid,
        relocation_shipment_products: formatedProductPayload,
      } as IUpdateRelocationShipmentProducts,
    });

    if (
      waitUpdateRelocationShipmentProduct?.status ===
      API_RESPONSE_STATUS.SUCCESS
    ) {
      alert.success("Update product successful");
      setPageReloadVersion((prev) => prev + 1);
      return;
    }

    alert.error("Update Relocation shipment products unsuccessful!");
  };

  const onUploadCsvFile = async (file: any) => {
    await fetchProductFromCsvFile(file);
  };

  const handleCheckKeyDown = (e: any) => {
    if (e.key === "Enter") e.preventDefault();
  };

  return (
    <>
      <UiForm onSubmit={handleSubmit} onKeyDown={handleCheckKeyDown}>
        <div className="mb-4">
          <div className="mb-4">
            <HeaderInfoPanel
              isDisplayInfoArea={true}
              title={`Relocation shipment from ${relocationShipmentInfo?.from_warehouse?.name} to ${relocationShipmentInfo?.to_warehouse?.name}`}
              infoArea={() => (
                <>
                  <TableCellStatus
                    status={relocationShipmentInfo?.status}
                    size={GENERAL_UI_SIZE.SMALL}
                  />
                  <TableCellStatus
                    className="ms-2"
                    status={relocationShipmentInfo?.shipping_service}
                    size={GENERAL_UI_SIZE.SMALL}
                  />
                </>
              )}
              actionArea={() => (
                <>
                  <UiButton
                    className="me-2"
                    title={"Update"}
                    customType="submit"
                  />
                </>
              )}
            />
          </div>
        </div>

        <GeneralPanel className="pt-4">
          <TwoColumnGridLayout
            widthDivision={[7, 5]}
            leftCol={
              <>
                <FileImporter
                  className={{
                    container: "d-flex w-100 justify-content-start",
                    label: "",
                    input: "w-100",
                  }}
                  onFileUpload={(value) => {
                    onUploadCsvFile(value);
                  }}
                />
                <DownloadButton
                  href={PATH_TO_PURCHASE_ORDER_SAMPLE_FILE}
                  className={{ container: "mt-1" }}
                  downloadTitle="Saleorder_products"
                  title={
                    <Typography
                      customFontsize={10}
                      fontWeight="fw-bold"
                      className="d-flex align-items-center gap-2"
                    >
                      <i className="fa-solid fa-download fa-sm"></i>
                      Download sample CSV
                    </Typography>
                  }
                />
                <Typography fontWeight="fw-bold" className="my-2 text-center">
                  Or
                </Typography>
                <TablePanel
                  apiPath={getProductListApi}
                  panelClassName={"mt-0"}
                  type={"minimal"}
                  tableSchema={productSchema({
                    onSelectProduct: handleAddProduct,
                    selectedProducts,
                    onChangeProductQty: setInputingProductQty,
                    inputingProductQty,
                  })}
                />
              </>
            }
            rightCol={
              <div className={styles.selectProduct}>
                <SelectedProduct
                  label={"Selected Product"}
                  products={selectedProducts}
                  onDeleteItem={handleDeleteProduct}
                  onUpdateItem={handleUpdateProduct}
                />
              </div>
            }
          />
        </GeneralPanel>
      </UiForm>
    </>
  );
};

export default Component;
