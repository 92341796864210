import { IHandleSplitingPackage, IPackagePrinting } from "./types";
import { IProductSaleOrder } from "./types";

import ViewCardPackage from "./view-card-package";

const PackagesList = ({
  packages,
  onDeletePackage,
  currentProductInPackagesQty,
  onSplitingPackage,
  onPrintSaleOrdersPackagesLabelApi,
  onPrintSaleOrdersProductsListPackagesApi,
  onDeleteSaleOrderPackage,
  allProducts,
  isInteractable,
}: {
  packages?: Array<any>;
  onDeletePackage: any;
  currentProductInPackagesQty: Record<string, number>;
  onSplitingPackage: (params: IHandleSplitingPackage) => void;
  onPrintSaleOrdersPackagesLabelApi: (params: IPackagePrinting) => void;
  onPrintSaleOrdersProductsListPackagesApi: (params: IPackagePrinting) => void;
  onDeleteSaleOrderPackage: (params: string) => void;
  allProducts: IProductSaleOrder[];
  isInteractable: boolean;
}) => {
  if (!packages || !packages.length) return null;

  return (
    <div>
      {packages
        ?.slice()
        ?.reverse()
        ?.map((packageItem: any, index: number) => {
          return (
            <ViewCardPackage
              currentProductInPackagesQty={currentProductInPackagesQty}
              key={packageItem?.code}
              isInteractable={isInteractable}
              handleCreatePackageItem={() => {}}
              index={index}
              onDeletePackage={() => {}}
              packageItem={packageItem}
              onSplitingPackage={onSplitingPackage}
              allProducts={allProducts}
              onPrintSaleOrdersPackagesLabelApi={
                onPrintSaleOrdersPackagesLabelApi
              }
              onPrintSaleOrdersProductsListPackagesApi={
                onPrintSaleOrdersProductsListPackagesApi
              }
              onDeleteSaleOrderPackage={onDeleteSaleOrderPackage}
            />
          );
        })}
    </div>
  );
};

export default PackagesList;
