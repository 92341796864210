import { TABLE_ACTION } from "../../constants/table-action";
import DateTimeTableCell from "../../components/table-cell-date-time";
import ProductTableCell from "../../components/table-cell-product";
import { ACTIONABLE_TYPE } from "../../constants/actionable";
import ActionTableCell from "../../components/table-cell-action";

interface ISchemaProps {
  onPrint?: any;
}

export const schema = ({ onPrint }: ISchemaProps) => [
  {
    name: "Batch",
    cell: (data: any) => (
      <ProductTableCell title={`#${data.batch}`} maxWidth={300} />
    ),
  },
  {
    name: "Quantity",
    align: "center",
    textSize: "xs",
    cell: (data: any) => data.quantity,
  },
  {
    name: "Created",
    align: "center",
    cell: (data: any) => <DateTimeTableCell timeStamp={data.created_at} />,
  },
  {
    name: "Action",
    align: "right",
    cell: (data: any) => (
      <ActionTableCell
        actions={data.instance_actionables}
        customActionableType={ACTIONABLE_TYPE.PRINT}
        customTitle={"Print"}
        onCustomClick={() => onPrint?.(data)}
        types={[TABLE_ACTION.CUSTOM]}
      />
    ),
  },
];
