import * as yup from "yup";
import { validationMessage } from "../../utils/validation";

export const FieldTitle = {
  location_code: "Location code",
  location_type: "Location type",
  height: "Height",
  length: "Length",
  width: "Width",
  zone: "Zone",
};

type FieldSchema = keyof typeof FieldTitle;

export const schema = (locationTypes: any[]) =>
  yup
    .object()
    .shape({
      location_code: yup
        .string()
        .required(({ path }) =>
          validationMessage.required(FieldTitle[path as FieldSchema])
        ),
      location_type: yup
        .string()
        .oneOf(
          locationTypes || [
            "shelf",
            "pallet",
            "cart",
            "putting",
            "overstock_cart",
          ]
        )
        .required(),
      height: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value)).nullable(),
      length: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value)).nullable()
        .integer(
          "Please enter the correct integer type and avoid using decimals."
        ),
      width: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value)).nullable()
        .integer(
          "Please enter the correct integer type and avoid using decimals."
        ),
      zone: yup.string(),
    })
    .required();
