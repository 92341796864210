import * as R from "../../routings/path";
import ProductTableCell from "../../components/table-cell-product";
import Typography from "../../components/ui-typography/component";

export const productSchema = [
  {
    name: "Product",
    cell: (data: any) => (
      <ProductTableCell
        linkTo={`${R.PRODUCTS_LIST}/${data?.product?.uuid}`}
        image={data?.product?.image_url}
        title={data?.product?.name}
        description={data?.product?.description}
        imageSize={40}
        maxWidth={800}
      />
    ),
  },
  {
    name: "Quantity",
    align: "center",
    textSize: "xs",
    cell: (data: any) => (
      <Typography className="" fontWeight="fw-bold" fontSize="fs-5">
        {data?.quantity}
      </Typography>
    ),
  },
];
