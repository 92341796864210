import { useCallback } from "react";
import { shallowEqual } from "react-redux";

import { useAppDispatch, useAppSelector } from "./useStateHook";

import * as actions from "../states/assigneeFilter.flow";

export const useFilterAssignees = () => {
  const dispatch = useAppDispatch();

  const filteredAssignees = useAppSelector(
    (state) => state.assigneeFilter,
    shallowEqual
  );

  const fetchUserAssignees = useCallback(
    (apiPath: any) => dispatch(actions.fetchAssignee(apiPath)),
    [dispatch]
  );

  const searchUserAssignees = useCallback(
    ({ apiPath, keyword = "" }: { apiPath: any; keyword?: string }) =>
      dispatch(actions.fetchAssigneeWithSearch({ apiPath, keyword })),
    [dispatch]
  );

  const addAssigneesToList = useCallback(
    (data: any) => dispatch(actions.addSearchedAssignee(data)),
    [dispatch]
  );

  return {
    fetchUserAssignees,
    searchUserAssignees,
    filteredAssignees,
    addAssigneesToList,
  };
};
