import classNames from "classnames";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  IUpdatePurchaseOrderProducts,
  getPurchaseOrderDetailApi,
  updatePurchaseOrderProducts,
} from "../../apis/paths/purchase-order";
import { apiCaller, useApiCaller } from "../../apis/config";
import { htmlStripTag } from "../../utils/format";
import { getProductListApi } from "../../apis/paths/products";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { useExtractProductFromCsvFile } from "../../hooks/useExtractProductFromCsvFile";
import { PATH_TO_PURCHASE_ORDER_SAMPLE_FILE } from "../../constants/staticFile";
import alert from "../../utils/alert";
import UiButton from "../../components/ui-button";
import TablePanel from "../../components/panel-table";
import Typography from "../../components/ui-typography/component";
import GeneralPanel from "../../components/panel-general";
import FileImporter from "../../components/ui-file-uploader/component";
import UiLoadingPanel from "../../components/ui-loading-panel";
import DownloadButton from "../../components/ui-download-button/component";
import HeaderInfoPanel from "../../components/panel-header-info";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import useHandleSidebarStatus from "../../hooks/useSidebarStatus";

import { productSchema } from "./schema";
import styles from "./styles.module.scss";
import SelectedProduct from "./view-selected-product";

const Component = () => {
  const [pageReloadVersion, setPageReloadVersion] = useState(1);

  const { purchaseOrderId: uuid } = useParams();
  const [params] = useState({ uuid });

  const { result, loading } = useApiCaller({
    api: getPurchaseOrderDetailApi,
    params: params,
    pageReloadVersion,
  });

  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const [hasRendered, setHasRendered] = useState(false);

  const purchaseOrderProducts =
    result?.data?.purchase_order?.purchase_order_products;

  const isEditable = result?.data?.purchase_order?.status === "draft";

  const { dispatchSidebarStatus } = useHandleSidebarStatus();
  const { fetchProductFromCsvFile, extractedProduct } =
    useExtractProductFromCsvFile();

  useEffect(() => {
    const { products } = extractedProduct || {};
    if (Array.isArray(products) && products?.length > 0) {
      setSelectedProducts(products);
    }
  }, [fetchProductFromCsvFile, extractedProduct]);

  useEffect(() => {
    if (isEditable) {
      dispatchSidebarStatus({ isOpened: false });
    }
    return () => {
      dispatchSidebarStatus({ isOpened: true });
    };
  }, [result]);

  useEffect(() => {
    if (
      Array.isArray(purchaseOrderProducts) &&
      purchaseOrderProducts.length > 0 &&
      !hasRendered
    ) {
      const flatMappedData = purchaseOrderProducts.flatMap((item) => {
        const { product, ...rest } = item;
        return { ...rest, ...product };
      });

      setSelectedProducts(flatMappedData);
      setHasRendered(true);
    }
  }, [result]);

  const handleAddProduct = (newProduct: any) => {
    const isExist = selectedProducts.some(
      (product: any) => product.uuid === newProduct.uuid
    );
    if (isExist) return;

    setSelectedProducts([
      {
        ...newProduct,
        quantity: 1,
      },
      ...selectedProducts,
    ]);
  };

  const handleUpdateProduct = (updatedProduct: any) => {
    const newProducts = selectedProducts.map((product: any) =>
      product.uuid !== updatedProduct.uuid ? product : updatedProduct
    );

    setSelectedProducts(newProducts);
  };

  const handleDeleteProduct = (deletedProduct: any) => {
    const newProducts = selectedProducts.filter(
      (product: any) => product.uuid !== deletedProduct.uuid
    );
    setSelectedProducts(newProducts);
  };

  const handleConfirmationSubmit = async () => {
    const products = selectedProducts.map((product: any) => ({
      product_uuid: product.uuid,
      quantity: product.quantity,
    }));

    const waitCreatePurchaseOrder = await apiCaller({
      api: updatePurchaseOrderProducts,
      params: {
        uuid,
        products,
      } as IUpdatePurchaseOrderProducts,
    });

    if (waitCreatePurchaseOrder.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update puchase order product success");
      setPageReloadVersion((prev) => prev + 1);
    }
  };

  const onUploadCsvFile = async (file: any) => {
    await fetchProductFromCsvFile(file);
  };

  if (loading) return <UiLoadingPanel />;

  return (
    <>
      <div className="mb-4">
        <HeaderInfoPanel
          isDisplayInfoArea={true}
          title={"Edit Purchase Order Products"}
          description="Here you can edit your Purchase Order Products"
          actionArea={() => (
            <UiButton
              className="me-2"
              title={"Update"}
              onClick={handleConfirmationSubmit}
            />
          )}
        />
      </div>
      <GeneralPanel panel={{ subTitle: "Products Information" }}>
        {isEditable ? (
          <TwoColumnGridLayout
            widthDivision={[7, 5]}
            leftCol={
              <>
                <FileImporter
                  className={{
                    container: "d-flex w-100 justify-content-start",
                    label: "",
                    input: "w-100",
                  }}
                  onFileUpload={(value) => {
                    onUploadCsvFile(value);
                  }}
                />
                <DownloadButton
                  href={PATH_TO_PURCHASE_ORDER_SAMPLE_FILE}
                  className={{ container: "mt-1" }}
                  downloadTitle="Purchase_Order_Products"
                  title={
                    <Typography
                      customFontsize={10}
                      fontWeight="fw-bold"
                      className="d-flex align-items-center gap-2"
                    >
                      <i className="fa-solid fa-download fa-sm"></i>
                      Download sample CSV
                    </Typography>
                  }
                />
                <Typography fontWeight="fw-bold" className="my-2 text-center">
                  Or
                </Typography>
                <TablePanel
                  apiPath={getProductListApi}
                  panelClassName={"mt-0"}
                  type={"minimal"}
                  tableSchema={productSchema({
                    onSelectProduct: handleAddProduct,
                    selectedProducts,
                    onUpdateItem: handleUpdateProduct,
                  })}
                />
              </>
            }
            rightCol={
              <div className={styles.selectProduct}>
                <SelectedProduct
                  label={"Selected Product"}
                  products={selectedProducts}
                  onDeleteItem={handleDeleteProduct}
                  onUpdateItem={handleUpdateProduct}
                />
              </div>
            }
          />
        ) : (
          <div className={styles.selectedProducts}>
            {!selectedProducts || !selectedProducts.length ? (
              <span className={styles.empty}>No product selected</span>
            ) : (
              selectedProducts?.map((product: any) => (
                <div
                  className={classNames(
                    styles.productItem,
                    styles.nonEditProductItem
                  )}
                  key={`product-${product.uuid}`}
                >
                  <div className={styles.info}>
                    <img
                      src={product?.image_url}
                      alt="purchase-order-product"
                    />
                    <div className={styles.content}>
                      <div className={styles.address}>
                        {htmlStripTag(product?.name)}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </GeneralPanel>
    </>
  );
};

export default Component;
