import React, { useMemo } from "react";

import { IPermissionProps } from "../../types/roles";
import CellFormSwitch from "../ui-switch-cell-form/component";
interface ITableCell {
  data: any;
  type: string;
  isSystem?: boolean;
  grantedPermissions?: any;
  control?: any;
}
interface IPermission {
  id: number;
  access_model: string;
  access_action: string;
  description: string;
}

const extractValueOfObject = (array: any[], type: string) => {
  for (const obj of array) {
    if (type in obj) {
      return obj[type];
    }
  }
  return null;
};

const ModelCol = ({ model }: any) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center mt-3">
      <div className="mt-1">{model}</div>
    </div>
  );
};

const Component = ({
  data,
  type,
  isSystem,
  grantedPermissions,
  control,
}: ITableCell) => {
  const allGrantedPermissionIds = useMemo(() => {
    return grantedPermissions?.map((permission: IPermission) => permission.id);
  }, [grantedPermissions]);

  const expectData = extractValueOfObject(data, type);

  if (type === "model") return <ModelCol model={expectData} />;

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      {expectData?.map((item: IPermissionProps) => (
        <div
          className="d-flex flex-column align-items-center justify-content-center mt-3"
          key={item?.id}
        >
          <CellFormSwitch
            control={control}
            name={`permission_ids[${item?.id}]`}
            checked={allGrantedPermissionIds?.includes(item?.id)}
            isEditable={!isSystem}
            isDisplayLabel={false}
          />

          { type==="other" && <div className="mt-1">{item?.description}</div>}
        </div>
      ))}
    </div>
  );
};

export default Component;
