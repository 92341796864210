import * as R from "../../routings/path";
import { TABLE_ACTION } from "../../constants/table-action";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import ActionTableCell from "../../components/table-cell-action";
import DateTimeTableCell from "../../components/table-cell-date-time";

interface ISchema {
  onRestore?: any;
}

export const deletedAccountSchema = ({ onRestore }: ISchema) => [
  {
    name: "Name",
    cell: (data: any) => (
      <ProductTableCell
        title={data.name}
        linkTo={`${R.ACCOUNT_LIST}/${data.uuid}`}
        maxWidth={200}
        image={data.avatar}
        isForceDisplayAvatar
        userName={data.name}
      />
    ),
  },
  {
    name: "Invited By",
    width: 120,
    cell: (data: any) => {
      const { name, avatar } = data?.inviter || {};
      return <ProductTableCell title={name} image={avatar} />;
    },
  },
  {
    name: "Created",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data.created_at} />,
  },

  {
    name: "Status",
    align: "center",
    width: 120,
    cell: (data: any) => {
      return <StatusTableCell status={data?.status} />;
    },
  },
  {
    name: "Action",
    align: "center",
    width: 120,
    cell: (data: any) => {
      return (
        <ActionTableCell
          types={[TABLE_ACTION.RESTORE]}
          onRestore={() => onRestore(data.uuid)}
        />
      );
    },
  },
];
