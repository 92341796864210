import * as R from "../../routings/path";
import { TABLE_ACTION } from "../../constants/table-action";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import ActionTableCell from "../../components/table-cell-action";
import { ACTIONABLE_TYPE } from "../../constants/actionable";
import styles from "./styles.module.scss";

export const productSchema = ({
  onSelectProduct,
  selectedProducts,
  onUpdateItem,
}: {
  onSelectProduct: any;
  selectedProducts: Array<any>;
  onUpdateItem: any;
}) => [
  {
    name: "Product",
    cell: (data: any) => (
      <ProductTableCell
        linkTo={`${R.PRODUCTS_LIST}/${data.code}`}
        image={data.image_url}
        title={data.name}
        description={data.code}
        maxWidth={150}
        imageSize={40}
      />
    ),
  },
  {
    name: "Unit",
    textSize: "xs",
    cell: (data: any) => data.unit,
  },
  {
    name: "Capacity",
    sortTypes: {
      key: "capacity_value",
      value: ["asc", "desc"],
    },
    align: "right",
    cell: (data: any) => (
      <ProductTableCell
        title={data.capacity_value || "-"}
        description={data.capacity_unit}
      />
    ),
  },
  {
    name: "Status",
    align: "center",
    cell: (data: any) => <StatusTableCell status={data.status} />,
  },
  {
    name: "Action",
    align: "center",
    textSize: "xs",
    cell: (data: any) => {
      const isExist = selectedProducts?.some(
        (product: any) => product.uuid === data.uuid
      );

      if (isExist) {
        const productQty = selectedProducts?.find(
          (product: any) => product.uuid === data.uuid
        )?.quantity;

        return (
          <div className={styles.actionInput}>
            <input
              title={"Quantity"}
              name={"quantity"}
              placeholder={" "}
              min={1}
              value={productQty}
              onChange={(e) => {
                onUpdateItem(
                  Object.assign({}, data, {
                    quantity: parseInt(e.target.value),
                  })
                );
              }}
              className={styles.input}
              type="number"
              required
            />
            Added
          </div>
        );
      }

      return (
        <ActionTableCell
          types={[TABLE_ACTION.CUSTOM]}
          customActionableType={ACTIONABLE_TYPE.FULL}
          customTitle={"Select"}
          onCustomClick={() => onSelectProduct(data)}
        />
      );
    },
  },
];
