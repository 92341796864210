import { TABLE_ACTION } from "../../../constants/table-action";
import { TASK_LOGGING_LIST } from "../../../routings/path";
import { formatIconBaseOnTaskName } from "../utils";
import { formatDateTime } from "../../../utils/date-time";
import { ACTIONABLE_TYPE } from "../../../constants/actionable";
import ProductTableCell from "../../../components/table-cell-product";
import ActionTableCell from "../../../components/table-cell-action";

interface ITaskUnassignedSchema {
  navigate: any;
}

export const taskUnassignedSchema = ({ navigate }: ITaskUnassignedSchema) => [
  {
    name: "Name",
    cell: (data: any) => (
      <div className="d-flex gap-2">
        <div className="d-flex justify-content-center align-items-center">
          {formatIconBaseOnTaskName(data?.name)}
        </div>
        <ProductTableCell
          maxWidth={260}
          title={data?.detail_name || "-"}
          description={data?.created_at ? formatDateTime(data.created_at, "lll") : "-"}
          linkTo={`${TASK_LOGGING_LIST}/${data.uuid}/edit`}
        />
      </div>
    ),
  },
  {
    name: "Action",
    align: "right",
    width: 120,
    cell: (data: any) => {
      return (
        <ActionTableCell
          customActionableType={ACTIONABLE_TYPE.FULL}
          onCustomClick={() => {
            navigate(`${TASK_LOGGING_LIST}/${data.uuid}/edit`);
          }}
          types={[TABLE_ACTION.CUSTOM]}
          customTitle="Assign"
        />
      );
    },
  },
];
