import { useSelector } from "react-redux";
import { useLocation, Navigate, useNavigate } from "react-router-dom";

import { userStateType } from "../states/user.flow";
import { IExpiredSessionState } from "../states/expiredSessionDetection.flow";

import { useEffect } from "react";
import { persistor } from "../states/store";

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  let location = useLocation();
  const navigate = useNavigate();
  const accessToken = useSelector(
    (state: { user: userStateType }) => state.user.loginInfo.access_token
  );

  const isTokenExpired = useSelector(
    (state: { expiredSessionDetection: IExpiredSessionState }) =>
      state.expiredSessionDetection.isTokenExpired
  );

  useEffect(() => {
    let timeOutId: ReturnType<typeof setTimeout>;

    if (isTokenExpired) {
      const purge = async () => {
        await persistor.purge();
        navigate(0);
      };

      timeOutId = setTimeout(() => {
        purge();
      }, 3000);
    }
    return () => {
      clearTimeout(timeOutId);
    };
  }, [isTokenExpired]);

  if (!accessToken) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  return children;
};

export const RequireNoAuth = ({ children }: { children: JSX.Element }) => {
  let location = useLocation();
  const accessToken = useSelector(
    (state: { user: userStateType }) => state.user.loginInfo.access_token
  );

  if (accessToken) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};
