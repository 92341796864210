import { TASK_LOGGING_LIST } from "../../../routings/path";
import { formatIconBaseOnTaskName } from "../utils";
import styles from "../../dashboard/styles.module.scss";
import Avatar from "../../../components/ui-avatar/component";
import UiTooltip from "../../../components/ui-tooltip/component";
import Typography from "../../../components/ui-typography/component";
import ProductTableCell from "../../../components/table-cell-product";
import TableCellOverdueDateTime from "../../../components/table-cell-overdue-date-time/component";

export const overdueTaskSchema = ({
  isLargeScreen,
}: {
  isLargeScreen: boolean;
}) => [
  {
    name: "Overdue Tasks",
    cell: (data: any) => (
      <div className="d-flex gap-2">
        <div className="d-flex justify-content-center align-items-center">
          {formatIconBaseOnTaskName(data?.name)}
        </div>

        <ProductTableCell
          maxWidth={isLargeScreen ? 320 : 280}
          title={data?.detail_name || "-"}
          description={data?.status || "-"}
          linkTo={`${TASK_LOGGING_LIST}/${data.uuid}/edit`}
        />
      </div>
    ),
  },

  {
    name: "Assignee",
    align: "end",
    width: 30,
    cell: (data: any) => {
      const { name, avatar } = data?.assignee || {};
      return (
        <UiTooltip
          tooltip={<Typography>{name}</Typography>}
          className={{ mainContent: "text-center" }}
        >
          <Avatar
            imageSrc={avatar}
            name={name}
            fixSize={26}
            allowDynamicBackgroundColor
          />
        </UiTooltip>
      );
    },
  },
  {
    name: "Due Date",
    align: "right",
    width: 70,
    cell: (data: any) => {
      return (
        <TableCellOverdueDateTime
          timeStamp={data?.due_at}
          align="end"
          className={{ tooltip: styles.customTooltip }}
        />
      );
    },
  },
];
