import * as R from "../../routings/path";
import { TABLE_ACTION } from "../../constants/table-action";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import ActionTableCell from "../../components/table-cell-action";
import DateTimeTableCell from "../../components/table-cell-date-time";

interface ISchema {
  onDelete?: any;
  onToggle?: any;
  accountStatus?: any;
}

export const schema = ({ onDelete, onToggle, accountStatus }: ISchema) => [
  {
    name: "Name",
    cell: (data: any) => (
      <ProductTableCell
        title={data.name}
        linkTo={`${R.ACCOUNT_LIST}/${data.uuid}`}
        maxWidth={200}
        image={data.avatar}
        isForceDisplayAvatar
        userName={data.name}
        allowDynamicAvatarBackgroundColor
      />
    ),
  },
  {
    name: "Invited By",
    width: 120,
    cell: (data: any) => {
      const { name, avatar } = data?.inviter || {};
      return (
        <ProductTableCell
          title={name}
          image={avatar}
          allowDynamicAvatarBackgroundColor
        />
      );
    },
  },
  {
    name: "Created",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data.created_at} />,
  },

  {
    name: "Status",
    align: "center",
    width: 120,
    cell: (data: any) => {
      return <StatusTableCell status={accountStatus?.[data?.uuid]} />;
    },
  },
  {
    name: "Action",
    align: "center",
    width: 120,
    cell: (data: any) => {
      return (
        <ActionTableCell
          actions={data.instance_actionables}
          types={[
            TABLE_ACTION.TOGGLE,
            TABLE_ACTION.VIEW,
            TABLE_ACTION.EDIT,
            TABLE_ACTION.DELETE,
          ]}
          toggleData={data.status}
          viewLink={`${R.ACCOUNT_LIST}/${data.uuid}`}
          editLink={`${R.ACCOUNT_LIST}/${data.uuid}/edit`}
          resetPasswordLink={`${R.ACCOUNT_LIST}/${data.uuid}/edit/reset-password`}
          onDelete={() => {
            onDelete(data.uuid);
          }}
          popupContent="Are you sure to delete this user?"
          onToggle={() => onToggle(data.uuid)}
        />
      );
    },
  },
];
