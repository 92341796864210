import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as R from "../../routings/path";
import { apiCaller } from "../../apis/config";
import { getWarehouseListApi } from "../../apis/paths/warehouse";
import { createNewPickListApi } from "../../apis/paths/picking";
import { getSaleOrderForPickingListApi } from "../../apis/paths/picking";
import { saleOrderCreatingDynamicFilter } from "../../constants/dynamic-filter";
import alert from "../../utils/alert";
import TablePanel from "../../components/panel-table";
import HeaderInfoPanel from "../../components/panel-header-info";
import UiButton from "../../components/ui-button";
import MultiStepPanel from "../../components/panel-multi-step";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";

import {
  warehouseSchema,
  useSaleOrdersSchema,
  selectedSaleOrdersSchema,
} from "./schema";
import SelectedWarehouse from "./view-selected-warehouse";
import SelectedSaleOrder from "./view-selected-sale-order";
import styles from "./styles.module.scss";

const Component = () => {
  const navigate = useNavigate();
  const [multiStepPanelTabIndex, setMultiStepPanelTabIndex] = useState(0);
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>(null);
  const [selectedSaleOrders, setSelectedSaleOrders] = useState<any[]>([]);
  const [currentPaging, setCurrentPaging] = useState(1);

  const [cachedSaleOrderPerPage, setCachedSaleOrderPerPage] = useState<
    Record<number, any>
  >({});

  const [
    pagesHaveBeenClickedSelectedAllSaleOrders,
    setPageHaveClickedSelectedAllSaleOrders,
  ] = useState<number[]>([]);

  const handleSelectSaleOrder = (saleOrder: any) => {
    setSelectedSaleOrders([saleOrder, ...selectedSaleOrders]);
  };

  const handleRemoveSaleOrder = (deletedSaleOrder: any) => {
    const newSaleOrder = selectedSaleOrders?.filter(
      (saleOrder: any) => saleOrder.uuid !== deletedSaleOrder.uuid
    );

    setSelectedSaleOrders(newSaleOrder);
  };

  const handleSubmit = async () => {
    if (!selectedWarehouse?.uuid) {
      alert.error("Please select warehouse");
      return;
    }

    const waitCreatePurchaseOrder = await apiCaller({
      api: createNewPickListApi,
      params: {
        warehouseUuid: selectedWarehouse?.uuid,
        saleOrderUuids: selectedSaleOrders?.map(
          (saleOrder: any) => saleOrder.uuid
        ),
      },
    });

    if (waitCreatePurchaseOrder.status === "SUCCESS") {
      navigate(`${R.PICKINGS_LIST}/${waitCreatePurchaseOrder?.data.uuid}`);
    }
  };

  const handleSelectAll = () => {
    const extractedSaleOrderBaseOnActivePage =
      pagesHaveBeenClickedSelectedAllSaleOrders?.reduce(
        (acc, currentSOInPage) =>
          acc.concat(cachedSaleOrderPerPage?.[currentSOInPage]),
        []
      );

    setSelectedSaleOrders(extractedSaleOrderBaseOnActivePage);
  };

  useEffect(() => {
    handleSelectAll();
  }, [pagesHaveBeenClickedSelectedAllSaleOrders]);

  return (
    <>
      <div className="mb-4">
        <HeaderInfoPanel
          isDisplayInfoArea={true}
          title={"Create a new Picking list"}
          description="Please select warehouse and sale order to create new picking list..."
          actionArea={() => (
            <UiButton
              className="me-2"
              title={"Create"}
              onClick={handleSubmit}
            />
          )}
        />
      </div>
      <MultiStepPanel
        defaultTab={multiStepPanelTabIndex}
        onChangeTab={(index: number) => setMultiStepPanelTabIndex(index)}
        tabs={["1. Warehouse", "2.Sale orders"]}
        contentHeadings={["SELECT WAREHOUSE", "SELECT SALE ORDERS"]}
        contentCol={12}
        onStaticCheckStepValidatation={(index: number) => {
          let isValidationPassed = true;
          if (
            multiStepPanelTabIndex === 0 &&
            index === 1 &&
            !selectedWarehouse
          ) {
            isValidationPassed = false;
            alert.error("Please select warehouse before moving to next step!");
            return isValidationPassed;
          }
          return isValidationPassed;
        }}
      >
        <TwoColumnGridLayout
          widthDivision={[7, 5]}
          leftCol={
            <TablePanel
              apiPath={getWarehouseListApi}
              panelClassName={"mt-0"}
              type={"minimal"}
              tableSchema={warehouseSchema({
                selectedWarehouse: selectedWarehouse,
                onSelectWarehouse: setSelectedWarehouse,
              })}
            />
          }
          rightCol={
            <div className={styles.selectWarehouse}>
              <SelectedWarehouse warehouse={selectedWarehouse} />
            </div>
          }
        />
        <TwoColumnGridLayout
          widthDivision={[7, 5]}
          leftCol={
            <TablePanel
              apiPath={getSaleOrderForPickingListApi}
              apiParams={{
                per: 50,
                filter: [
                  {
                    field: "warehouse_code",
                    operator: "in",
                    values: [selectedWarehouse?.code],
                  },
                ],
              }}
              panelClassName={"mt-0"}
              type={"minimal"}
              displayHeadingAction={[4]}
              tableSchema={useSaleOrdersSchema({
                selectedSaleOrders,
                currentPaging,
                pagesHaveBeenClickedSelectedAllSaleOrders,
                onSelectSaleOrder: handleSelectSaleOrder,
                onSelectAll: (currentPaging: number) => {
                  setPageHaveClickedSelectedAllSaleOrders((prev: number[]) => {
                    if (
                      pagesHaveBeenClickedSelectedAllSaleOrders.includes(
                        currentPaging
                      )
                    ) {
                      return prev.filter((page) => page !== currentPaging);
                    }
                    return [...prev, currentPaging];
                  });
                },
              })}
              onReceivedData={(data: any) => {
                setCurrentPaging(data?.paging?.current_page);
                setCachedSaleOrderPerPage((prev) => ({
                  ...prev,
                  [data?.paging?.current_page]: data?.list,
                }));
              }}
              dynamicFilter={saleOrderCreatingDynamicFilter}
            />
          }
          rightCol={
            <SelectedSaleOrder
              saleOrders={selectedSaleOrders}
              schema={selectedSaleOrdersSchema({
                onRemoveSaleOrder: handleRemoveSaleOrder,
              })}
            />
          }
        />
      </MultiStepPanel>
    </>
  );
};

export default Component;
