import * as yup from "yup";
import { validationMessage } from "../../utils/validation";

export const FieldTitle = {
  name: "Name",
  code: "Code",
  phone: "Phone",
  tax_code: "Tax Code",
  full_address: "Full Address",
  logo: "Logo",
};

type FieldSchema = keyof typeof FieldTitle;

export const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(FieldTitle[path as FieldSchema])
      ),
    code: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(FieldTitle[path as FieldSchema])
      ),
    phone: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(FieldTitle[path as FieldSchema])
      ),
    full_address: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(FieldTitle[path as FieldSchema])
      ),
    tax_code: yup.string().nullable(),
  })
  .required();
