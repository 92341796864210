export enum GENERAL_FIELDS_MODEL {
  ACCOUNT = "Account",
  DISTRICT = "District",
  DATA_SOURCE = "Import::DataSource",
  ISSUE = "Import::Issue",
  PRODUCT = "Product",
  PROVINCE = "Province",
  ROLE = "Role",
  WARD = "Ward",
}

export enum GENERAL_PERMISSIONS_MODEL {
  SHOW = "show",
  INVITE = "invite",
  RESET_PASSWORD = "reset_password",
  MANAGE = "manage",
  NEW = "new",
  DESTROY = "destroy",
  EDIT = "edit",
  SHARE_ACCESS = "share_access",
  UPDATE_PERMISSIONS = "update_permissions",
}
