/* eslint-disable eqeqeq */
export const areArraysEqual = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const value1 = arr1[i];
    const value2 = arr2[i];

    if (typeof value1 === "string" && typeof value2 === "number") {
      arr1[i] = parseFloat(value1);
    } else if (typeof value1 === "number" && typeof value2 === "string") {
      arr2[i] = parseFloat(value2);
    }

    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};

export const areObjectsEqual = (
  obj1: Record<string, any>,
  obj2: Record<string, any>
) => {
  if (
    obj1 === null ||
    obj2 === null ||
    typeof obj1 !== "object" ||
    typeof obj2 !== "object"
  ) {
    return false;
  }

  // Check if the keys of both objects are the same
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  // Iterate through the keys and compare the values
  for (const key of keys1) {
    const value1 = obj1[key];
    const value2 = obj2[key];

    if (typeof value1 === "object" && typeof value2 === "object") {
      // If both values are objects, compare them recursively
      if (!areObjectsEqual(value1, value2)) {
        console.log('not equal at ', key)
        return false;
      }
    } else if (Array.isArray(value1) && Array.isArray(value2)) {
      // If both values are arrays, compare them
      if (!areArraysEqual(value1, value2)) {
        console.log('not equal at ', key)

        return false;
      }
    } else if (value1 !== value2) {
      if (
        ((typeof value1 === "string" && typeof value2 === "number") ||
          (typeof value1 === "number" && typeof value2 === "string")) &&
        value1 == value2
      ) {
        return true;
      }
      // For non-array values, compare directly
      console.log('not equal at ', key)
      return false;
    }
  }

  // If all properties match, return true
  return true;
};
