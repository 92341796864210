import { numberFormat } from "../../utils/format";
import { longHyphen } from "../../constants/character";

import styles from "./styles.module.scss";
import classNames from "classnames";
import Typography from "../ui-typography/component";

interface IComponent {
  value: string;
  onClick?: any;
  className?: {
    container?: string;
    content?: string;
  };
}

const Component = ({
  value,
  onClick,
  className = {
    container: "",
    content: "",
  },
}: IComponent) => {
  const formattedValue = value ? numberFormat(value) : longHyphen;

  return (
    <div
      className={classNames(styles.container, className?.container)}
      onClick={onClick && onClick}
    >
      <Typography className={classNames(styles.value, className?.content)}>
        {formattedValue}
      </Typography>
    </div>
  );
};

export default Component;
