import classNames from "classnames";
import React from "react";
import styles from "./styles.module.scss";

interface IWarehouseType {
  id: number;
  name: string;
  icon: React.ReactNode;
}

const WAREHOUSE_TYPES: IWarehouseType[] = [
  {
    id: 1,
    name: "Master warehouse",
    icon: <i className="fa-solid fa-industry"></i>,
  },
  {
    id: 2,
    name: "Sub-warehouse",
    icon: <i className="fa-solid fa-warehouse"></i>,
  },
];

const ViewWarehouseTypeSelect = ({
  warehouseType,
  setWarehouseType,
  hasStep1Error,
  setHasStep1Error,
}: {
  warehouseType: number;
  setWarehouseType: (params: number) => void;
  hasStep1Error: boolean;
  setHasStep1Error: any;
}) => {
  return (
    <div>
      {hasStep1Error && (
        <label
          className="mb-0 text-center w-100 justify-content-center"
          style={{ color: "#f5365c" }}
        >
          Please select the warehouse type!
        </label>
      )}
      <div className="row mt-5 justify-content-center gap-4">
        {WAREHOUSE_TYPES.map((warehouse: IWarehouseType) => (
          <div
            className={classNames(
              "col-sm-3 w-20 d-flex justify-content-center flex-column"
            )}
            key={warehouse.id}
            onClick={() => {
              setHasStep1Error(false);
              setWarehouseType(warehouse.id);
            }}
          >
            <div
              className={classNames(
                "btn btn-lg border-2 px-2 py-5",
                styles.warehouseItem,
                warehouseType === warehouse.id && styles.active
              )}
            >
              <div
                className={classNames(
                  styles.icon,
                  warehouseType === warehouse.id && styles.activeIcon
                )}
              >
                {warehouse.icon}
              </div>
            </div>
            <h6 className="text-center">{warehouse.name}</h6>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewWarehouseTypeSelect;
