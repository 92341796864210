import * as R from "../../routings/path";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import Typography from "../../components/ui-typography/component";

export const locationSchema = [
  {
    name: "Code",
    cell: (data: any) => {
      return (
        <ProductTableCell title={data?.code} imageSize={40} maxWidth={200} />
      );
    },
  },
  {
    name: "Quantity",
    align: "center",
    cell: (data: any) => (
      <Typography fontSize="fs-5" fontWeight="fw-bold">
        {data?.product_item_count}
      </Typography>
    ),
  },
];

export const saleOrderSchema = (packingUuid: string) => [
  {
    name: "Number",
    cell: (data: any) => (
      <ProductTableCell
        title={data?.number}
        description={`#${data?.channel}`.toUpperCase()}
        linkTo={`${R.PACKINGS_LIST}/${packingUuid}/sale-orders/${data?.number}`}
        maxWidth={120}
      />
    ),
  },
  {
    name: "Contact",
    cell: (data: any) => (
      <ProductTableCell
        subIcon={"fa-phone"}
        linkTo={`tel:${data?.receiver_phone}`}
        title={data?.receiver_name}
        description={data?.receiver_phone}
      />
    ),
  },
  {
    name: "Status",
    align: "center",
    cell: (data: any) => <StatusTableCell status={data?.status} />,
  },
];
