import { useState } from "react";
import classNames from "classnames";

import { formSchema } from "./formSchema";
import { detectRequiredlField } from "../../utils/validation";
import UiInput from "../../components/ui-input";
import PanelGeneral from "../../components/panel-general/component";

import styles from "./style.module.scss";

const ResetPasswordAccountForm = ({
  register,
  errors,
}: {
  register: any;
  errors: any;
}) => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowResetPassword, setIsShowResetPassword] = useState(false);

  return (
    <PanelGeneral
      panel={{
        title: "Reset Password",
      }}
    >
      <div className="position-relative">
        <UiInput
          className="dataTable-input"
          name={"password"}
          title={"Password"}
          required={detectRequiredlField(formSchema, "password")}
          isDisplayLabel={true}
          register={register}
          errorMessage={errors?.password?.message}
          type={isShowPassword ? "text" : "password"}
        />
        <div
          className={classNames("cursor-pointer right-0 ", styles.eyeIcon)}
          onClick={() => {
            setIsShowPassword((prev) => !prev);
          }}
        >
          {isShowPassword ? (
            <i className="fa-solid fa-eye-slash"></i>
          ) : (
            <i className="fa-solid fa-eye"></i>
          )}
        </div>
      </div>

      <div className="position-relative">
        <UiInput
          className="dataTable-input"
          name={"password_confirmation"}
          required={detectRequiredlField(formSchema, "password_confirmation")}
          title={"Password Confirmation"}
          isDisplayLabel={true}
          register={register}
          errorMessage={errors?.password_confirmation?.message}
          type={isShowResetPassword ? "text" :"password"}
        />
        <div
          className={classNames("cursor-pointer right-0 ", styles.eyeIcon)}
          onClick={() => {
            setIsShowResetPassword((prev) => !prev);
          }}
        >
          {isShowResetPassword ? (
            <i className="fa-solid fa-eye-slash"></i>
          ) : (
            <i className="fa-solid fa-eye"></i>
          )}
        </div>
      </div>
    </PanelGeneral>
  );
};

export default ResetPasswordAccountForm;
