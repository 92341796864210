import classNames from "classnames";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  IParameterInfo,
  dynamicFormSchema,
} from "../../utils/dynamicFormSchema";
import { splitAndCapitalize } from "../task-logging-create/utils";
import { apiCaller, useApiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import {
  getDynamicDocsApi,
  getSettingsConstantApi,
  updateConstantsSlaApi,
} from "../../apis/paths/settings";
import UiInput from "../../components/ui-input";
import alert from "../../utils/alert";
import UiForm from "../../components/ui-form";
import UiButton from "../../components/ui-button";
import NotFoundPanel from "../../components/panel-not-found";
import Typography from "../../components/ui-typography/component";

import { splitFieldByBrackets } from "./utils";
import styles from "./style.module.scss";

const SLA_DOC_PATH = "/wms/constants/sla";
const CompanyInfo = () => {
  const [pageReloadVersion, setPageReloadVersion] = useState(1);

  const [params] = useState<{
    page: number;
    per: number;
  }>({
    page: 1,
    per: 50,
  });

  const { result } = useApiCaller({
    api: getSettingsConstantApi,
    params,
    pageReloadVersion,
  });

  const { result: docs } = useApiCaller({
    api: getDynamicDocsApi,
    params,
  });

  const slaParameters = docs?.data?.paths?.[SLA_DOC_PATH]?.put?.parameters;
  const slaInfo = result?.data?.sla;
  const schema = dynamicFormSchema?.(slaParameters);

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  useEffect(() => {
    if (!slaInfo) return;

    const keys = Object.keys(schema?.fields);
    keys.forEach((key: any) => {
      const groupKey = key.split("-");
      setValue(key, (slaInfo?.[groupKey[0]] as any)?.[groupKey[1] as any]);
    });
  }, [result, docs]);

  const onSubmit = async (formData: Record<string, any>, event: any) => {
    event.preventDefault();
    const transformData: Record<string, Record<string, number>> = {};

    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        const parts = key.split("-");
        const groupName = parts[0];
        const subKey = parts.slice(1).join("-");

        if (!transformData[groupName]) {
          transformData[groupName] = {};
        }

        transformData[groupName][subKey] = formData[key];
      }
    }

    const waitUpdateConstantsSla = await apiCaller({
      api: updateConstantsSlaApi,
      params: { data: transformData },
    });

    if (waitUpdateConstantsSla.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update successful!");
      setPageReloadVersion((prev) => prev + 1);
      return;
    }

    alert.error("Update was not successful!. Please try again!");
  };

  if (slaInfo?.status === "ERROR") return <NotFoundPanel />;

  const groupedData: Record<string, any> =
    slaParameters?.reduce((acc: any, item: any) => {
      const groupName = item?.additionalProperties?.group;
      acc[groupName] = acc[groupName] || [];
      acc[groupName].push(item);

      return acc;
    }, {}) ?? {};

  return (
    <UiForm onSubmit={handleSubmit(onSubmit)}>
      <div className="card card-profile-bottom">
        <div className="card position-sticky top-1">
          <div className="card-header pb-0">
            <div className="d-flex justify-content-between">
              <div>
                <h5>SLA Settings</h5>
                <p className="text-sm">
                  SLA stands for service level agreement.
                </p>
              </div>
              <div className="d-flex align-items-center ">
                <UiButton
                  className="me-2"
                  title={"Update"}
                  onClick={() => {}}
                />
              </div>
            </div>
            <hr className="horizontal dark mt-2" />
          </div>

          <div className="card-body pt-0">
            <div>
              {Object.entries(groupedData)?.map(
                ([parentField, childArray], index) => (
                  <div
                    key={index}
                    className={classNames(
                      styles.groupItems,
                      "mt-4 p-3 border-radius-xl mx-5"
                    )}
                  >
                    <Typography
                      fontWeight="fw-bolder"
                      className="text-start mb-4"
                      textColor="#344767"
                      customFontsize={15}
                    >
                      {splitAndCapitalize(parentField)}
                    </Typography>

                    {childArray?.map((slaParameter: IParameterInfo) => {
                      const { parentText, childText } = splitFieldByBrackets(
                        slaParameter.name as string
                      );

                      return (
                        <div key={slaParameter?.name}>
                          <UiInput
                            className="dataTable-input"
                            name={`${parentText}-${childText}`}
                            title={slaParameter?.description}
                            required={slaParameter?.required}
                            isDisplayLabel={true}
                            inline={true}
                            type="number"
                            register={register}
                            errorMessage={
                              errors[`${parentText}-${childText}`]
                                ?.message as string
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </UiForm>
  );
};

export default CompanyInfo;
