import React from "react";
import classNames from "classnames";

import styles from "./style.module.scss";

import { IWarehouse } from "../role-edit/types";
import PanelGeneral from "../../components/panel-general/component";
import Typography from "../../components/ui-typography/component";
import Heading from "../../components/ui-heading/component";
import StatusTableCell from "../../components/table-cell-status";
import { GENERAL_STATUS } from "../../constants/status";
import { GENERAL_UI_SIZE } from "../../constants/size";

const ViewInvitedWarehouse = ({ warehouses }: { warehouses: IWarehouse[] }) => {
  return (
    <>
      <PanelGeneral
        styles={{ height: warehouses?.length !== 0 ? 360 : 338 }}
        className="ps-0 pe-0"
        panel={{
          title: "Accessible Warehouses",
          description:
            warehouses?.length === 0 ? "No accessible warehouse" : "",
        }}
      >
        {warehouses?.map((warehouse) => (
          <div
            className={classNames(
              "d-flex gap-2 align-items-center p-2 ps-4 pe-4 justify-content-between",
              styles.accountItem
            )}
            key={warehouse?.uuid}
          >
            <div className="d-flex align-items-start flex-column justify-content-center">
              <Heading level={6} className="mb-0" fontWeight="normal" textSize={GENERAL_UI_SIZE.SMALL}>{warehouse?.name}</Heading>
              <Typography textSize={GENERAL_UI_SIZE.XSMALL}>{warehouse?.code}</Typography>
            </div>

            <StatusTableCell
              status={
                warehouse?.deleted_at
                  ? GENERAL_STATUS.DELETED
                  : GENERAL_STATUS.ACTIVATE
              }
            />
          </div>
        ))}
      </PanelGeneral>
    </>
  );
};

export default ViewInvitedWarehouse;
