import React from "react";

import { GENERAL_UI_SIZE } from "../../constants/size";
import { GENERAL_UI_COLOR } from "../../constants/color";

interface IBadgeProps {
  className?: string;
  content?: string | React.ReactNode;
  size?: GENERAL_UI_SIZE;
  type?: GENERAL_UI_COLOR;
  onClick?: any;
  customStyles?: React.CSSProperties;
  customFontsize?: number;
}

const Badge = ({
  className = "",
  content,
  size = GENERAL_UI_SIZE.MEDIUM,
  type = GENERAL_UI_COLOR.PRIMARY,
  onClick,
  customFontsize,
  customStyles,
}: IBadgeProps) => {
  if (!content) return null;

  const customSize =
    size === GENERAL_UI_SIZE.SMALL
      ? "badge-sm"
      : size === GENERAL_UI_SIZE.MEDIUM
      ? "badge-md"
      : size === GENERAL_UI_SIZE.LARGE
      ? "badge-lg"
      : "";

  return (
    <span
      onClick={onClick}
      style={{
        WebkitUserSelect: "none",
        msUserSelect: "none",
        userSelect: "none",
        ...(customFontsize && { fontSize: customFontsize }),
        ...(customStyles && customStyles),
      }}
      className={`badge badge-pill bg-gradient-${type} ${customSize} ${className}`}
    >
      {content}
    </span>
  );
};

export default Badge;
