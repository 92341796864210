import ActionRolePermissionCreateCell from "../../components/table-cell-role-edit-permission";

export interface ISchemaProps {
  register: any;
  isSystem?: boolean;
  control: any;
}

export const schema = ({
  register,
  isSystem,
  control,
}: ISchemaProps) => [
  {
    name: "Model",
    align: "center",
    textSize: "xs",
    cell: (data: any) => (
      <ActionRolePermissionCreateCell
        data={data}
        isSystem={isSystem}
        control={control}
        type="model"
      />
    ),
  },
  {
    name: "View",
    align: "center",
    textSize: "xs",
    cell: (data: any) => (
      <ActionRolePermissionCreateCell
        data={data}
        isSystem={isSystem}
        control={control}
        type="view"
      />
    ),
  },
  {
    name: "Add",
    align: "center",
    textSize: "xs",
    cell: (data: any) => (
      <ActionRolePermissionCreateCell
        data={data}
        isSystem={isSystem}
        control={control}
        type="add"
      />
    ),
  },
  {
    name: "Remove",
    align: "center",
    textSize: "xs",
    cell: (data: any) => (
      <ActionRolePermissionCreateCell
        data={data}
        isSystem={isSystem}
        control={control}
        type="remove"
      />
    ),
  },
  {
    name: "Edit",
    align: "center",
    textSize: "xs",
    cell: (data: any) => (
      <ActionRolePermissionCreateCell
        data={data}
        isSystem={isSystem}
        control={control}
        type="edit"
      />
    ),
  },
  {
    name: "Other",
    align: "center",
    textSize: "xs",
    cell: (data: any) => (
      <ActionRolePermissionCreateCell
        data={data}
        isSystem={isSystem}
        control={control}
        type="other"
      />
    ),
  },
  {
    name: "Full",
    align: "center",
    textSize: "xs",
    cell: (data: any) => (
      <ActionRolePermissionCreateCell
        data={data}
        isSystem={isSystem}
        control={control}
        type="full"
      />
    ),
  },
];
