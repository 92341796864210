import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { generateUrlParams } from "../../utils/url";
import { get } from "../config";

export const getAccountListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
}: {
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
}) =>
  get({
    path: `/wms/notifications/me${generateUrlParams({
      page,
      per,
      keyword,
      sort,
    })}`,
  });
