import styles from "./styles.module.scss";
import logoLight from "../../styles/images/logo-light.png";

const COVER =
"https://api.wms-rc.click/assets/bg-auth-3c5a987dc0699ba90d334d079c49d7e960a22f3955d65f168bd0ee749071328b.webp"

interface PropsType {
  title?: string;
  description?: string;
}

const Component = ({ title, description }: PropsType) => (
  <div
    className="page-header align-items-start min-vh-50 pt-7 pb-9 m-3 border-radius-lg"
    style={{ backgroundImage: `url(${COVER})` }}
  >
    <span className="mask bg-gradient-dark opacity-6"></span>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-5 text-center mx-auto">
          <img
            src={logoLight}
            alt="lixhouse logo"
            className={`img-fluid ${styles.logo}`}
          />
        </div>
      </div>
    </div>
  </div>
);

export default Component;
