import * as R from "../../routings/path";
import { TABLE_ACTION } from "../../constants/table-action";
import { GENERAL_UI_COLOR } from "../../constants/color";
import ProductTableCell from "../../components/table-cell-product";
import ActionTableCell from "../../components/table-cell-action";
import StatusTableCell from "../../components/table-cell-status";

export const schema = (warehouseId: string) => [
  {
    name: "Warehouse",
    cell: (data: any) => (
      <ProductTableCell
        title={`#${data.code}`}
        maxWidth={200}
        linkTo={`${R.WAREHOUSES_LIST}/${warehouseId}/locations-products/${data.code}`}
      />
    ),
  },
  {
    name: "Location Type",
    align: "center",
    width: 80,
    cell: (data: any) => (
      <StatusTableCell
        status={data.location_type}
        type={GENERAL_UI_COLOR.SECONDARY}
      />
    ),
  },
  {
    name: "WHL",
    align: "center",
    width: 80,
    cell: (data: any) => {
      if (!data.width && !data.height && !data.length) {
        return `_ _ _`;
      }
      return `${data.width || "_"} x ${data.height || "_"} x ${
        data.length || "_"
      }`;
    },
  },
  {
    name: "Action",
    align: "right",
    width: 120,
    cell: (data: any) => (
      <ActionTableCell
        viewLink={`${R.WAREHOUSES_LIST}/${warehouseId}/locations-products/${data.code}`}
        types={[TABLE_ACTION.VIEW]}
      />
    ),
  },
];
