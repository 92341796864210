import UiTextArea from "../../components/ui-text-area";
import ActionTableCell from "../../components/table-cell-action";
import Badge from "../../components/ui-badge-button";

import { GENERAL_UI_SIZE } from "../../constants/size";
import { ACTIONABLE_TYPE } from "../../constants/actionable";
import { TABLE_ACTION } from "../../constants/table-action";

interface ISchemaProps {
  onAdding?: any;
  setNoteValue: any;
}

export const schema = ({ onAdding, setNoteValue }: ISchemaProps) => [
  {
    name: "Name",
    width: 150,
    cell: (data: any) => (
      <div className="">
        <Badge
          content={data?.name}
          className="my-1"
          type={data?.color}
          size={GENERAL_UI_SIZE.SMALL}
          onClick={() => onAdding(data.uuid)}
        />
      </div>
    ),
  },
  {
    name: "Description",
    align: "left",
    width: 200,
    cell: (data: any) => (
      <div style={{ width: 200, whiteSpace: "break-spaces" }}>
        {data?.description}
      </div>
    ),
  },
  {
    name: "Note",
    align: "left",
    // width: 150,
    cell: (data: any) => (
      <UiTextArea
        isDisplayLabel={false}
        onChange={(e) => {
          setNoteValue(e.target.value);
        }}
        name={"commentEdit"}
        className="mt-2"
        placeholder="Write your comment"
        rows={3}
      />
    ),
  },
  {
    name: "Action",
    align: "right",
    width: 120,
    cell: (data: any) => {
      return (
        <ActionTableCell
          customActionableType={ACTIONABLE_TYPE.FULL}
          types={[TABLE_ACTION.CUSTOM]}
          customTitle="Add"
          onCustomClick={() => onAdding(data.uuid)}
        />
      );
    },
  },
];
