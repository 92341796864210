import classNames from "classnames";
import React from "react";

interface IInputSearch {
  placeholder?: string;
  value: string;
  setValue: any;
  className?: {
    container?: string;
  };
}
const InputSearch = ({
  placeholder,
  value,
  setValue,
  className = {
    container: "p-2",
  },
}: IInputSearch) => {
  
  return (
    <div className={classNames(className?.container)}>
      <div
        style={{ position: "relative" }}
        className="d-flex justify-content-center align-items-center "
      >
        <i
          className="fa-solid fa-magnifying-glass position-absolute "
          style={{ top: 15, left: 15 }}
        ></i>
      </div>
      <div>
        <input
          autoComplete="new-password" // using this instead of 'off' due to some case not works as expected
          placeholder={placeholder || "Search"}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          style={{
            width: "100%",
            height: 40,
            fontSize: 13,
            minHeight: 40,
            borderRadius: 8,
            paddingRight: 15,
            paddingLeft: 40,
            color: "rgb(142, 157, 171)",
            background: "rgb(245, 246, 246)",
            border: "none",
            boxShadow: "none",
            outline: "none",
            top: 0,
          }}
        ></input>
      </div>

      <i
        className="fa-solid fa-circle-xmark position-absolute cursor-pointer "
        style={{ top: 20, right: 15 }}
        onClick={() => {
          setValue("");
        }}
      ></i>
    </div>
  );
};

export default InputSearch;
