import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { generateUrlParams } from "../../utils/url";
import { get, patch, post } from "../config";

interface sessionsLoginApiType {
  username: string;
  password: string;
  byPassErrorAlert?: boolean;
}

interface IGetMySession {
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
}

export interface IsessionsWorkModeApi {
  work_mode: string;
  outside_until?: number;
}

export const sessionsLoginApi = (
  { username, password }: sessionsLoginApiType,
  byPassErrorAlert: boolean
) =>
  post(
    {
      path: "/wms/sessions",
      data: {
        username,
        password,
      },
    },
    byPassErrorAlert
  );

export const getMySessionsApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
}: IGetMySession) =>
  get({
    path: `/wms/sessions/me${generateUrlParams({ page, per })}`,
  });

export const sessionsWorkModeApi = (
  { work_mode, outside_until }: IsessionsWorkModeApi,
  byPassErrorAlert: boolean
) => {
  const formatedParams =
    work_mode === "outside"
      ? {
          work_mode,
          outside_until,
        }
      : {
          work_mode,
        };

  return patch(
    {
      path: "/wms/sessions/work_mode",
      data: formatedParams,
    },
    byPassErrorAlert
  );
};
