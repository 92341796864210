import { useState } from "react";

import {
  assignLabelsApi,
  getLabelsListApi,
} from "../../apis/paths/labels";
import { apiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import alert from "../../utils/alert";
import TablePanel from "../../components/panel-table";

import { schema } from "./schema";

interface IPanelAddLabels {
  linkedObjectType: string;
  onPageReload: any;
  linkedObjectUuid: string;
  selectedLabels: string[];
}

const PanelAddLabels = ({
  linkedObjectType,
  linkedObjectUuid,
  onPageReload,
  selectedLabels,
}: IPanelAddLabels) => {
  const [noteValue, setNoteValue] = useState("");

  const handleAddLabels = async (uuid: string) => {
    const waitDelete = await apiCaller({
      api: assignLabelsApi,
      params: {
        label_uuid: uuid,
        linked_object_type: linkedObjectType,
        linked_object_uuid: linkedObjectUuid,
        note: noteValue,
      },
    });

    if (waitDelete.status === API_RESPONSE_STATUS.SUCCESS) {
      onPageReload((prev: number) => prev + 1);
      return;
    }

    alert.error("Delete was unsuccessful!. Please try again");
  };

  const removedSelectedLabels = (fullLabelsList: any[]) => {
    return fullLabelsList?.filter(
      (label: any) => !selectedLabels?.includes(label?.uuid)
    );
  };

  return (
    <div className="mt-2">
      <TablePanel
        apiPath={getLabelsListApi}
        dataTransformation={(result: any) =>
          result && {
            data: removedSelectedLabels(result?.data?.list),
          }
        }
        noDataDescription={"There is no labels to display"}
        tableClassName={{container3:'pt-3'}}
        tableSchema={schema({
          onAdding: handleAddLabels,
          setNoteValue,
        })}
      />
    </div>
  );
};

export default PanelAddLabels;
