import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { generateUrlParams } from "../../utils/url";
import { del, get, patch, post } from "../config";

interface IGetNotesApi {
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
  notable_uuid: string;
  notable_type: string;
}

interface IAddNoteApi {
  content: string;
  notable_type: string;
  notable_uuid: string;
}
interface IUpdateNoteApi {
  content: string;
  id: string;
}

export const getNotesApi = ({
  notable_uuid,
  notable_type,
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
}: IGetNotesApi) =>
  get({
    path: `/wms/notes/${notable_uuid}${generateUrlParams({
      notable_type,
      page,
      per,
    })}`,
  });

export const createNoteApi = ({
  content,
  notable_type,
  notable_uuid,
}: IAddNoteApi) =>
  post({
    path: `/wms/notes`,
    data: {
      content,
      notable_type,
      notable_uuid,
    },
  });

export const updateNoteApi = ({ content, id }: IUpdateNoteApi) =>
  patch({
    path: `/wms/notes/${id}`,
    data: {
      content,
    },
  });

  export const deleleNoteApi = ({ id }: { id: string }) =>
  del({ path: `/wms/notes/${id}` });
