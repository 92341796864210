import * as yup from "yup";
import { GENERAL_UI_COLOR } from "../../constants/color";
import Typography from "../../components/ui-typography/component";
import StatusTableCell from "../../components/table-cell-status";
import ActionTableCell from "../../components/table-cell-action";
import DateTimeTableCell from "../../components/table-cell-date-time";
import ProductTableCell from "../../components/table-cell-product";
import { ACTIONABLE_TYPE } from "../../constants/actionable";
import { TABLE_ACTION } from "../../constants/table-action";

export const schema = yup
  .object({
    name: yup.string().required(),
    short_name: yup.string().required(),
    code: yup.string().required(),
    uuid: yup.string().required(),
    description: yup.string().required(),
    gtin_code: yup.string().required(),
    gtin_type: yup.string().required(),
    reference_code: yup.string().required(),
    unit: yup.string().required(),
    capacity_unit: yup.string().required(),
    capacity_value: yup.string().required(),
    display_height: yup.string().required(),
    display_length: yup.string().required(),
    display_width: yup.string().required(),
    packaging_weight: yup.string().required(),
    packaging_width: yup.string().required(),
    packaging_height: yup.string().required(),
    packaging_length: yup.string().required(),
    product_types: yup.mixed().required(),
  })
  .required();

export const locationSchema = ({
  onClickItem,
  onClickCheckHistory,
}: {
  onClickItem: (params: any) => void;
  onClickCheckHistory: (params: any) => void;
}) => [
    {
      name: "Warehouse",
      cell: (data: any) => (
        <div
          onClick={() => {
            onClickItem(data);
          }}
          className="cursor-pointer"
        >
          <ProductTableCell
            title={data?.warehouse?.name}
            description={`#${data?.warehouse?.code}`}
            maxWidth={200}
          />
        </div>
      ),
    },
    {
      name: "Damaged Qty",
      align: "center",
      cell: (data: any) => (
        <Typography fontSize="fs-5" fontWeight="fw-bold">
          {data?.damaged}
        </Typography>
      ),
    },
    {
      name: "Saleable Qty",
      align: "center",
      cell: (data: any) => (
        <Typography fontSize="fs-5" fontWeight="fw-bold">
          {data?.saleable}
        </Typography>
      ),
    },
    {
      name: "Usable Qty",
      align: "center",
      cell: (data: any) => (
        <Typography fontSize="fs-5" fontWeight="fw-bold">
          {data?.usable}
        </Typography>
      ),
    },
    {
      name: "Storage Type",
      align: "center",
      width: 80,
      cell: (data: any) => (
        <StatusTableCell
          status={data?.warehouse?.storage_type}
          type={GENERAL_UI_COLOR.SECONDARY}
        />
      ),
    },
    {
      name: "Action",
      align: "right",
      cell: (data: any) => (
        <div className="btn-group">
          <ActionTableCell
            customActionableType={ACTIONABLE_TYPE.FULL}
            customTitle="Check Locations"
            className="w-100"
            types={[TABLE_ACTION.CUSTOM]}
            onCustomClick={() => {
              onClickItem(data);
            }}
          />
          <ActionTableCell
            customActionableType={ACTIONABLE_TYPE.FULL}
            customTitle="Check History"
            className="w-100"
            types={[TABLE_ACTION.CUSTOM]}
            onCustomClick={() => {
              onClickCheckHistory(data);
            }}
          />

        </div>
      ),
    },
  ];

export const stockByWarehouseSchema = () => [
  {
    name: "Location",
    cell: (data: any) => (
      <ProductTableCell title={data?.location_code} maxWidth={200} />
    ),
  },
  {
    name: "Damaged Qty",
    align: "center",
    cell: (data: any) => (
      <Typography fontSize="fs-5" fontWeight="fw-bold">
        {data?.damaged}
      </Typography>
    ),
  },
  {
    name: "Saleable Qty",
    align: "center",
    cell: (data: any) => (
      <Typography fontSize="fs-5" fontWeight="fw-bold">
        {data?.saleable}
      </Typography>
    ),
  },
  {
    name: "Usable Qty",
    align: "center",
    cell: (data: any) => (
      <Typography fontSize="fs-5" fontWeight="fw-bold">
        {data?.usable}
      </Typography>
    ),
  },
];

export const stockHistorySchema = () => [
  {
    name: "Location",
    cell: (data: any) => (
      <ProductTableCell title={data?.location_code} maxWidth={140} />
    ),
  },
  {
    name: "Before Qty",
    align: "center",
    cell: (data: any) => (
      <Typography fontSize="fs-5" fontWeight="fw-bold">
        {data?.before_quantity}
      </Typography>
    ),
  },
  {
    name: "After Qty",
    align: "center",
    cell: (data: any) => (
      <Typography fontSize="fs-5" fontWeight="fw-bold">
        {data?.after_quantity}
      </Typography>
    ),
  },
  {
    name: "Stock Change",
    align: "center",
    cell: (data: any) => (
      <Typography fontSize="fs-5" fontWeight="fw-bold">
        {data?.stock_change}
      </Typography>
    ),
  },
  {
    name: "Requester",
    width: 120,
    cell: (data: any) => {
      const { name, avatar, work_mode, outside_until } = data?.requester || {};
      return (
        <ProductTableCell
          description={name}
          image={avatar}
          isForceDisplayAvatar
          userName={name}
          allowDynamicAvatarBackgroundColor
          displayStatusMode={{
            workMode: work_mode,
            outsideUntil: outside_until,
          }}
        />
      );
    },
  },
  {
    name: "When",
    cell: (data: any) => (
      <DateTimeTableCell
        timeStamp={data?.created_at}
        formatTimestamp={{
          time: "HH:mm YYYY-MM-DD",
          tooltip: "HH:mm YYYY-MM-DD",
        }}
      />
    ),
  },
];
