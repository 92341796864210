import { useState, useEffect } from "react";

import {
  SUCCESS_STATUS,
  FAIL_STATUS,
  WARNING_STATUS,
  PRIMARY_STATUS,
  SECONDARY_STATUS,
} from "../../constants/status";
import { apiCaller } from "../../apis/config";
import { GENERAL_UI_COLOR } from "../../constants/color";

import styles from "./styles.module.scss";
import PopupConfirmation from "./view-popup-confirmation";

const calculateColor = (status: string) => {
  const formattedStatus =
    status
      ?.toLowerCase()
      .replaceAll(" ", "")
      .replaceAll("-", "")
      .replaceAll("_", "")
      .replaceAll(".", "")
      .replaceAll(",", "") || "";

  if (status && status.length) {
    if (SUCCESS_STATUS.indexOf(formattedStatus as any) >= 0) {
      return `${GENERAL_UI_COLOR.SUCCESS}`;
    } else if (FAIL_STATUS.indexOf(formattedStatus as any) >= 0) {
      return `${GENERAL_UI_COLOR.DANGER}`;
    } else if (WARNING_STATUS.indexOf(formattedStatus as any) >= 0) {
      return `${GENERAL_UI_COLOR.WARNING}`;
    } else if (PRIMARY_STATUS.indexOf(formattedStatus as any) >= 0) {
      return `${GENERAL_UI_COLOR.PRIMARY}`;
    } else if (SECONDARY_STATUS.indexOf(formattedStatus as any) >= 0) {
      return `${GENERAL_UI_COLOR.SECONDARY}`;
    } else {
      return `${GENERAL_UI_COLOR.SECONDARY}`;
    }
  }

  return `${GENERAL_UI_COLOR.PRIMARY}`;
};

interface IComponent {
  className?: string;
  status: string;
  triggerableEvents: Array<string>;
  actionApi: any;
  paramsTransformation: Function;
  onChangeSuccess?: Function;
  size?: "normal" | "small";
  isNotable?: boolean;
}

const Component = ({
  className,
  status,
  triggerableEvents,
  actionApi,
  paramsTransformation,
  onChangeSuccess,
  size = "normal",
  isNotable = false,
}: IComponent) => {
  const [isVisible, setVisible] = useState(false);
  const [isShowConfirmation, setShowConfirmation] = useState(false);
  const [confirmationType, setConfirmationType] = useState("");
  const [colorType, setColorType] = useState(calculateColor(status));

  const [note, setNote] = useState("");

  const handleClickHeading = () => setVisible(!isVisible);
  const handleClickOerlay = () => setVisible(false);
  const handleClickItem = (event: string) => {
    setShowConfirmation(true);
    setConfirmationType(event);
    setVisible(false);
  };

  const handleConfirmation = async () => {
    const waitGetWarehouseDetail = await apiCaller({
      api: actionApi,
      params: isNotable
        ? paramsTransformation(confirmationType, note)
        : paramsTransformation(confirmationType),
    });

    if (waitGetWarehouseDetail?.status === "SUCCESS") {
      onChangeSuccess && onChangeSuccess(waitGetWarehouseDetail?.data);
    }
  };

  useEffect(() => {
    setColorType(calculateColor(status));
  }, [status]);

  if (!status || !status.length) return null;

  return (
    <>
      <div
        className={`${styles.container} ${isVisible && styles.visible} ${
          size === "small" && styles.small
        } ${className}`}
      >
        <div
          className={`bg-gradient-${colorType} ${styles.heading}`}
          onClick={handleClickHeading}
        >
          <div className={styles.buttonPanel}>
            <div className={`link-${colorType} ${styles.status}`}>
              {status}
              <div
                className={styles.line}
                style={{ background: `var(--bs-${colorType})` }}
              />
            </div>

            <div className={styles.icon}>
              <i
                className="fas fa-angle-down text-sm"
                style={{ color: `var(--bs-${colorType})` }}
              ></i>
            </div>
          </div>
        </div>

        <div className={styles.overlay} onClick={handleClickOerlay}></div>

        <div className={styles.dropdown}>
          <div className={styles.list}>
            {triggerableEvents?.length ? (
              triggerableEvents?.map((event: any, index: number) => (
                <div
                  className={styles.item}
                  key={`event-${index}`}
                  onClick={() => {
                    handleClickItem(event);
                  }}
                >
                  {event}
                </div>
              ))
            ) : (
              <div
                className={`${styles.item} ${styles.disabled}`}
                key={`event-0`}
              >
                No action
              </div>
            )}
          </div>
        </div>
      </div>

      <PopupConfirmation
        isShowModal={isShowConfirmation}
        confirmationButton={"Change"}
        content={`Do you want to change to ${confirmationType.toUpperCase()}?`}
        handleCloseModal={() => setShowConfirmation(false)}
        isNotable={isNotable}
        note={note}
        setNote={setNote}
        onDelete={handleConfirmation}
      />
    </>
  );
};

export default Component;
