import classNames from "classnames";

import { GENERAL_UI_SIZE } from "../../../constants/size";
import { GENERAL_UI_COLOR } from "../../../constants/color";
import { IThTable } from "../../../components/ui-table/component";
import { IScannedProduct } from "../types";

import * as R from "../../../routings/path";
import styles from "../styles.module.scss";
import ProductTableCell from "../../../components/table-cell-product";
import Badge from "../../../components/ui-badge/component";
import Typography from "../../../components/ui-typography/component";
import useConfiguration from "../../../hooks/useConfiguration";

export const useRemainingProductSchema = ({
  productState,
  latestScannedProduct,
  setExceededProducts,
  isLargeScreen,
  currentProductInPackagesQty,
  totalRemainingProductsQty,
  handleMouseSelectProduct,
}: {
  productState: any;
  latestScannedProduct: IScannedProduct;
  setExceededProducts: any;
  isLargeScreen: boolean;
  currentProductInPackagesQty: Record<string, number>;
  totalRemainingProductsQty: number;
  handleMouseSelectProduct: (params: string) => void;
}): IThTable[] => {
  const { settingState } = useConfiguration();
  const { isPackingMouseSelectable } = settingState || {};

  return [
    {
      name: (
        <Typography
          customFontsize={11}
          fontWeight="fw-bolder"
          className="d-flex align-items-center gap-2"
        >
          Remaining Products
          <Typography
            customFontsize={12}
            fontWeight="fw-bold"
            className="text-black"
          >{`(${totalRemainingProductsQty || 0})`}</Typography>
        </Typography>
      ),
      cell: (data: any, index: number) => (
        <div className="d-flex align-items-center gap-2">
          <span className="badge badge-secondary text-white ">{index + 1}</span>
          <ProductTableCell
            linkTo={`${R.PRODUCTS_LIST}/${data?.uuid}`}
            image={data?.image_url}
            title={data?.name}
            linkTarget="_blank"
            description={data?.gtin_code}
            isCopyAble={{ description: true }}
            isOneLineTitle={false}
            maxWidth={isLargeScreen ? 500 : 350}
            imageSize={40}
          />
        </div>
      ),
    },

    {
      name: (
        <Typography
          customFontsize={11}
          fontWeight="fw-bolder"
          className="d-flex align-items-center justify-content-center"
        >
          Locations
        </Typography>
      ),
      align: "center",
      textSize: "xl",
      cell: (data: any) => (
        <Typography customFontsize={13} fontWeight="fw-bold">
          {data?.location}
        </Typography>
      ),
    },
    {
      name: (
        <Typography
          customFontsize={11}
          fontWeight="fw-bolder"
          className="d-flex align-items-center gap-2"
        >
          Remaining Qty
        </Typography>
      ),
      align: "center",
      cell: (data: any) => {
        const productObject = productState?.[data?.gtin_code];

        const productLocation = productObject?.[data?.location];
        const shouldFlashing =
          data?.gtin_code === latestScannedProduct?.qrCode &&
          data?.location === latestScannedProduct?.location;

        const productScannedQty = productLocation?.scannedQty || 0;

        const productRequiredScannedQty = data?.updatedQuantity || 0;

        const productRemainedQty =
          productRequiredScannedQty - productScannedQty;

        if (productRemainedQty < 0) {
          setExceededProducts(data);
        }

        return (
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <ProductTableCell
              align="center"
              title={productRemainedQty}
              className={{
                container: classNames(
                  styles.qtyItem,
                  styles.customTitle,
                  productRemainedQty < 0 && styles.dangerTitle,
                  shouldFlashing && styles.customCell
                ),
              }}
            />
            {isPackingMouseSelectable && (
              <div
                className={classNames(styles.mouseSelect)}
                onClick={() => {
                  handleMouseSelectProduct(data?.gtin_code);
                }}
              >
                <i className="fa-solid fa-hand-pointer fa-xl"></i>
              </div>
            )}
          </div>
        );
      },
    },
    {
      name: "Status",
      align: "center",
      textSize: "xl",
      width: 150,
      cell: (data: any) => {
        let productStatus: "underStock" | "exceeded" | "ok" = "underStock";
        const quantity = data?.quantity;

        const scannedQty =
          productState?.[data?.gtin_code]?.[data?.location]?.scannedQty;
        const productAlreadyInPackagesQty =
          currentProductInPackagesQty?.[data?.gtin_code] || 0;

        const productsOccupiedQty = scannedQty + productAlreadyInPackagesQty;

        if (productsOccupiedQty < quantity) {
          productStatus = "underStock";
        } else if (productsOccupiedQty === quantity) {
          productStatus = "ok";
        } else if (productsOccupiedQty > quantity) {
          productStatus = "exceeded";
          setExceededProducts(data);
        }

        return (
          <div
            style={{ width: 100 }}
            className={classNames(productStatus === "ok" && "reduceOpacity")}
          >
            {productStatus === "underStock" && (
              <Badge
                content={"Under Stock"}
                size={GENERAL_UI_SIZE.SMALL}
                type={GENERAL_UI_COLOR.WARNING}
              />
            )}
            {productStatus === "exceeded" && (
              <Badge
                content={"Exceeded"}
                size={GENERAL_UI_SIZE.SMALL}
                type={GENERAL_UI_COLOR.DANGER}
              />
            )}
            {productStatus === "ok" && (
              <Badge
                content={<i className="fa-solid fa-check fa-xl text-white"></i>}
                size={GENERAL_UI_SIZE.SMALL}
                type={GENERAL_UI_COLOR.SUCCESS}
              />
            )}
          </div>
        );
      },
    },
  ];
};
