import React, { useState } from "react";

import { IWarehouse } from "./types";
import { removeDuplicateArrayItems } from "./utils";
import { apiCaller, useApiCaller } from "../../apis/config";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { inviteWarehouseApi } from "../../apis/paths/roles";
import { getWarehouseListApi } from "../../apis/paths/warehouse";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import alert from "../../utils/alert";
import PanelGeneral from "../../components/panel-general/component";
import AddMemberSearchBox from "./view-add-item-search-box";
import UiButton from "../../components/ui-button";

const ViewAddNewWarehouse = ({
  onCancel,
  uuid,
  roleWarehouses,
  setPageReloadVersion,
}: {
  onCancel: () => void;
  uuid: string;
  roleWarehouses: Array<IWarehouse>;
  setPageReloadVersion: any;
}) => {
  const [invitedWarehouse, setInvitedWarehouse] = useState<any>();
  const [warehouseParams, setWarehouseParams] = useState(DEFAULT_PAGINATION_PARAMS);

  const formatInvitedWarehouses = (list: any[]) => {
    return list?.map((item) => item.value);
  };

  const handleConfirmAddNewWarehouse = async () => {
    if (!invitedWarehouse) return;

    const waitAddInviteWarehouse = await apiCaller({
      api: inviteWarehouseApi,
      params: { uuid, uuids: formatInvitedWarehouses(invitedWarehouse) },
    });

    if (waitAddInviteWarehouse.status === API_RESPONSE_STATUS.SUCCESS) {
      setTimeout(() => {
        alert.success("Invite successful!");
      }, 1000);
      setPageReloadVersion((prev: number) => prev + 1);
      return;
    }

    alert.error("Delete was unsuccessful!. Please try again");
  };

  const { result: availableWarehouses } = useApiCaller({
    api: getWarehouseListApi,
    params: warehouseParams,
  });

  const onSearching = (value: string) => {
    setWarehouseParams((prev) => ({ ...prev, keyword: value }));
  };

  const listingWarehouses = removeDuplicateArrayItems(roleWarehouses, availableWarehouses?.data?.list);

  return (
    <PanelGeneral
      styles={{ height: 340 }}
      panel={{
        title: "Warehouse",
        actionArea: () => (
          <>
            <UiButton
              className="me-2"
              type={GENERAL_UI_COLOR.LIGHT}
              onClick={onCancel}
              title={"Cancel"}
            />
            <UiButton
              className="me-2"
              type={GENERAL_UI_COLOR.PRIMARY}
              onClick={() => {
                onCancel();
                handleConfirmAddNewWarehouse();
              }}
              title={"Add"}
              disabled={!invitedWarehouse}
            />
          </>
        ),
      }}
    >
      <AddMemberSearchBox
        data={listingWarehouses}
        onInputChange={onSearching}
        setInvitedItem={setInvitedWarehouse}
      />
    </PanelGeneral>
  );
};

export default ViewAddNewWarehouse;
