import classNames from "classnames";

import UiTypography from "../../components/ui-typography";
import styles from "./styles.module.scss";
import { NavLink } from "react-router-dom";

const RowTextContent = ({
  sub,
  title,
  className = {
    container: "",
    content1: "",
    content2: "",
  },
  linkTo,
}: {
  sub: string;
  title: any;
  subWidth?: number;
  linkTo?: string;
  className?: {
    container?: string;
    content1?: string;
    content2?: string;
  };
}) => (
  <UiTypography
    fontSize="fs-6"
    className={classNames(styles.rowTextContent, className.container)}
  >
    <span className={classNames(styles.key, className.content1)}>{sub}</span>
    {!!linkTo && linkTo.length ? (
      <NavLink
        target="_blank"
        to={linkTo}
        className={classNames(
          styles.value,
          className.content2,
          linkTo && styles.link,
          "cursor-pointer"
        )}
      >
        {title}
      </NavLink>
    ) : (
      <span className={classNames(styles.value, className.content2)}>
        {title}
      </span>
    )}
  </UiTypography>
);

export default RowTextContent;
