import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IItemProps {
  isOpened: boolean;
}

export interface IItemsState {
  itemsQuantity: IItemProps;
}

const initialState: IItemProps = {
  isOpened: true,
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    updateSidebarStatusAction: (state, action: PayloadAction<IItemProps>) => {
      state.isOpened = action.payload.isOpened;
    },
  },
});

export const { updateSidebarStatusAction } = sidebarSlice.actions;
export default sidebarSlice.reducer;
