import { TABLE_ACTION } from "../../constants/table-action";
import DateTimeTableCell from "../../components/table-cell-date-time";
import { truncateText } from "../../utils/format";
import ProductTableCell from "../../components/table-cell-product";
import ActionTableCell from "../../components/table-cell-action";

interface ISchemaProps {
  onRestore?: any;
}

export const deletedLabelsSchema = ({ onRestore }: ISchemaProps) => [
  {
    name: "Name",
    cell: (data: any) => <ProductTableCell title={data.name || "-"} />,
  },
  {
    name: "Description",
    align: "left",
    width: 150,
    cell: (data: any) => truncateText(data.description),
  },
  {
    name: "Created",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data.created_at} />,
  },
  {
    name: "Updated",
    align: "center",
    sortTypes: {
      key: "updated_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data.updated_at} />,
  },
  {
    name: "Action",
    align: "right",
    width: 120,
    cell: (data: any) => {
      return (
        <ActionTableCell
          viewLink={`${data.uuid}`}
          editLink={`${data.uuid}/edit`}
          types={[TABLE_ACTION.RESTORE]}
          onRestore={() => onRestore(data.uuid)}
          popupContent="Are you sure to delete this product type ?"
        />
      );
    },
  },
];
