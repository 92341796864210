import UiButton from "../../components/ui-button";
import UiTooltip from "../../components/ui-tooltip/component";
import Typography from "../../components/ui-typography/component";

import styles from "./styles.module.scss";
import { IHandlePacking } from "./types";

interface IViewActionButtons {
  handlePrintSaleOrder: (param: any) => Promise<void>;
  handlePacking: (params: IHandlePacking) => Promise<void>;
  saleOrderStatus: string;
}
const ViewActionButtons = ({
  handlePrintSaleOrder,
  handlePacking,
  saleOrderStatus,
}: IViewActionButtons) => {
  return (
    <>
      <UiTooltip
        position="bottomRight"
        tooltip={
          <Typography className="d-flex align-items-center ">
            Type
            <span className="badge badge-primary text-xl align-self-start mx-1">
              PP
            </span>
            for quick
            <div className="text-primary ms-1 fw-bold">Printing Action</div>
          </Typography>
        }
        className={{
          mainContent: "text-center",
          floatingContent: styles.floatingContent,
        }}
      >
        <UiButton
          title="Print Label (P)"
          onClick={handlePrintSaleOrder}
          className="mx-3"
        />
      </UiTooltip>

      <UiTooltip
        position="bottomRight"
        tooltip={
          <Typography className="d-flex align-items-center ">
            Type
            <span className="badge badge-primary text-xl align-self-start mx-1">
              NN
            </span>
            for quick
            <div className="text-primary ms-1 fw-bold">Next Action</div>
          </Typography>
        }
        className={{
          mainContent: "text-center",
          floatingContent: styles.floatingContent,
        }}
      >
        <UiButton
          onClick={() => {
            handlePacking({});
          }}
          disabled={!(saleOrderStatus === "packing")}
          title={"Next (N)"}
        />
      </UiTooltip>
    </>
  );
};

export default ViewActionButtons;
