import TableCellStatus from "../../components/table-cell-status";
import { GENERAL_STATUS } from "../../constants/status";
import { GENERAL_UI_SIZE } from "../../constants/size";
import TableDataPanel from "../../components/panel-table-data";
import styles from "./styles.module.scss";

const Component = ({
  saleOrders,
  schema,
}: {
  saleOrders: Array<any>;
  schema: any;
}) => {
  return (
    <div className={styles.selectedSaleOder}>
      <div className={styles.heading}>
        <label>Selected Sale Orders</label>
        <TableCellStatus
          status={
            saleOrders?.length
              ? GENERAL_STATUS.SELECTED
              : GENERAL_STATUS.MISSING
          }
          size={GENERAL_UI_SIZE.SMALL}
        />
      </div>
      {saleOrders?.length ? (
        <TableDataPanel
          data={saleOrders}
          schema={schema}
          cardClassName={styles.saleOrderTable}
          trClassName={styles.saleOrderTableRow}
        />
      ) : (
        <div className={styles.saleOrderTableEmpty}>No sale order selected</div>
      )}
    </div>
  );
};

export default Component;
