import { useNavigate } from "react-router-dom";

import * as R from "../../routings/path";
import { getPickingListApi } from "../../apis/paths/picking";
import TablePanel from "../../components/panel-table";
import UiButton from "../../components/ui-button";

import { schema } from "./schema";

export const PICKING_FILTER_OPTIONS = [
  {
    field: "status",
    operator: "in",
    options: [
      { value: "draft", content: "Draft" },
      { value: "picking", content: "Picking" },
      { value: "picked", content: "Picked" },
      { value: "checking", content: "Checking" },
      { value: "completed", content: "Completed" },
      { value: "incompleted", content: "Incompleted" },
    ],
  },
];

const Component = () => {
  const navigate = useNavigate();

  return (
    <TablePanel
      apiPath={getPickingListApi}
      panel={{
        title: "Picking list",
        description: "List of picking in the system",
        actionArea: () => (
          <UiButton
            title={"New Picking List"}
            onClick={() => navigate(R.PICKINGS_CREATE)}
          />
        ),
      }}
      filter={{
        optionList: PICKING_FILTER_OPTIONS,
      }}
      tableSchema={schema()}
    />
  );
};

export default Component;
