/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";

import { useApiCaller } from "../../apis/config";
import {
  getDynamicMenuItemsApi,
  getSettingsConstantApi,
} from "../../apis/paths/settings";
import logo from "../../styles/images/logo.png";
import Timer from "../ui-timer/component";
import appImage from "../../styles/images/app.webp";
import useHandleSidebarStatus from "../../hooks/useSidebarStatus";

import { GENERAL_UI_COLOR } from "../../constants/color";
import styles from "./styles.module.scss";

export interface IDynamicNodeProps {
  id: string | number;
  icon?: string;
  color?: GENERAL_UI_COLOR;
  text: string;
  children?: IDynamicNodeProps[];
  href?: string;
  handleClick?: () => void;
}

interface IDropdownState {
  [key: number | string]: boolean;
}

const Component = () => {
  const [dropdownState, setDropdownState] = useState<IDropdownState>({});
  const [hasClickToNavbar, setHasClickToNavbar] = useState(false);
  const [menuItems, setMenuItem] = useState([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { sidebarStatus, dispatchSidebarStatus } = useHandleSidebarStatus();
  const { isOpened } = sidebarStatus || {};

  const [params] = useState<{
    page: number;
    per: number;
  }>({
    page: 1,
    per: 50,
  });

  const { result } = useApiCaller({
    api: getSettingsConstantApi,
    params,
  });

  const { result: menuResult } = useApiCaller({
    api: getDynamicMenuItemsApi,
    params,
  });

  useEffect(() => {
    if (!!menuResult && menuResult?.data?.list?.length > 0) {
      setMenuItem(menuResult?.data?.list);
    }
  }, [menuResult]);

  const liveLogo = result?.data?.workspace?.logo;

  const handleSetDropdownState = (itemId: number | string) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  const findElementByHrefWithPath = (
    data: IDynamicNodeProps[],
    href: string,
    path: Array<string | number> = []
  ): Array<string | number> | null => {
    if (!data) return null;
    for (const item of data) {
      if (item?.href === pathname) {
        return [...path, item.id];
      }
      if (item?.children) {
        const foundPath = findElementByHrefWithPath(item?.children, href, [
          ...path,
          item?.id,
        ]);
        if (foundPath) {
          return foundPath;
        }
      }
    }
    return null;
  };

  const elementPath = findElementByHrefWithPath(menuItems, pathname);

  useEffect(() => {
    if (!hasClickToNavbar) {
      const activeTab = elementPath && (elementPath[0] as string | number);

      if (activeTab !== undefined) {
        setDropdownState((prevState) => ({
          ...prevState,
          [activeTab as string | number]: true,
        }));
      }
    }
  }, []);

  const renderNode = ({
    id,
    icon,
    text,
    children,
    href,
    color,
    handleClick,
  }: IDynamicNodeProps) => {
    return (
      <li
        className={classNames(
          "nav-item",
          href === '#' && children?.length === 0 && 'd-none',
          pathname === href && styles.selectedItem
        )}
        onClick={(event) => {
          event.stopPropagation();
          setHasClickToNavbar(true);
          handleClick && handleClick();
          handleSetDropdownState(id);
        }}
        key={id}
      >
        <Link
          to={href || "#"}
          className={classNames("nav-link")}
          aria-controls="dashboardsExamples"
          role="button"
          aria-expanded="false"
        >
          {!!icon && (
            <div
              className={`${styles.itemIcon} icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center`}
            >
              <i
                className={`fas fa-${icon} text-${color} text-sm opacity-10`}
              ></i>
            </div>
          )}
          <span
            className={classNames(
              "nav-link-text ms-1",
              pathname === href && styles.selectedItem
            )}
          >
            {text}
          </span>
          {!!children && children.length > 0 && (
            <div
              className={`${styles.itemIcon} icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center ms-auto`}
            >
              <i
                className={classNames(
                  `fas fa-caret-down text-secondary text-sm opacity-10 `,
                  dropdownState[id] && styles.rotateIcon
                )}
              ></i>
            </div>
          )}
        </Link>

        <div
          className={classNames(
            dropdownState[id] && styles.active,
            styles.dropdownItems
          )}
        >
          {!!children && (
            <ul className={classNames("nav ms-4")}>
              {children?.map((node) => renderNode(node))}
            </ul>
          )}
        </div>
      </li>
    );
  };

  const renderNodes = (data: IDynamicNodeProps[]) => {
    return data?.map((item, index) => (
      <div
        className={classNames(
          "nav-item",
          pathname === item.href && styles.selectedItem
        )}
        key={item.id || index}
      >
        {renderNode(item)}
      </div>
    ));
  };

  return (
    <>
      <aside
        className={classNames(
          "sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4",
          styles.changeZindex,
          !isOpened && styles.sidebarCollapsed
        )}
        id="sidenav-main"
      >
        <div
          className={classNames(
            "position-absolute cursor-pointer ",
            styles.toggleSidebar
          )}
          onClick={() => {
            dispatchSidebarStatus({ isOpened: false });
          }}
        >
          <i className="fa-solid fa-chevron-left opacity-4 fa-sm"></i>
        </div>

        <div
          className={classNames(
            "d-flex m-4 mb-1 pb-3 justify-content-center cursor-pointer",
            styles.image
          )}
          onClick={() => navigate("/")}
        >
          <img
            src={liveLogo || logo}
            alt="lixhouse logo"
            className={`img-fluid ${styles.logo}`}
          />
        </div>
        <hr className="horizontal dark mt-0" />

        <div
          className={classNames(
            "collapse navbar-collapse w-auto",
            styles.navbarContainer
          )}
          id="sidenav-collapse-main"
        >
          <ul className="navbar-nav">{renderNodes(menuItems)}</ul>
        </div>
        <div className="sidenav-footer mx-3 my-1">
          <div className="card card-plain shadow-none" id="sidenavCard">
            <img
              src={appImage}
              className="img-fluid mx-auto my-2"
              alt="download-mobile-app"
              width="60"
            />
          </div>

          <a
            href="https://api.wms-rc.click/download-app"
            target="_blank"
            rel="noreferrer"
            className={`btn btn-dark btn-sm w-100 mb-1 ${styles.downloadAppButton}`}
          >
            Work on Mobile? Install App
          </a>

          <Timer />
        </div>
      </aside>
    </>
  );
};

export default memo(Component);
