const Component = ({ children }: { children: any }) => (
  <h5
    style={{ textTransform: "uppercase" }}
    className="text-sm mb-0 font-bold pb-3 d-flex justify-content-between align-items-center"
  >
    {children}
  </h5>
);

export default Component;
