
import * as R from "../../routings/path";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { GENERAL_UI_COLOR } from "../../constants/color";

import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import UiButton from "../../components/ui-button/component";

import styles from "./styles.module.scss";


export const productSchema = ({
  onSelectProduct,
  selectedProducts,
  onChangeProductQty,
  inputingProductQty,
}: {
  onSelectProduct: any;
  selectedProducts: Array<any>;
  onChangeProductQty: any;
  inputingProductQty: Record<string, number>;
}) => [
  {
    name: "Product",
    cell: (data: any) => (
      <ProductTableCell
        linkTo={`${R.PRODUCTS_LIST}/${data?.code}`}
        image={data?.image_url}
        title={data?.name}
        description={data?.gtin_code}
        maxWidth={150}
        imageSize={40}
      />
    ),
  },
  {
    name: "Unit",
    textSize: "xs",
    cell: (data: any) => data?.unit,
  },
  {
    name: "Capacity",
    sortTypes: {
      key: "capacity_value",
      value: ["asc", "desc"],
    },
    align: "right",
    cell: (data: any) => (
      <ProductTableCell
        title={data?.capacity_value || "-"}
        description={data?.capacity_unit}
      />
    ),
  },
  {
    name: "Status",
    align: "center",
    cell: (data: any) => <StatusTableCell status={data?.status} />,
  },
  {
    name: "Action",
    align: "center",
    textSize: "xs",
    cell: (data: any) => {
      const isExist = selectedProducts?.some(
        (product: any) => product.uuid === data?.uuid
      );

      if (isExist) {
        return <div className={styles.actionInput}>Added</div>;
      }

      return (
        <div className="d-flex gap-2 justify-content-center">
          <div className={styles.actionInput}>
            <input
              title={"Quantity"}
              name={"quantity"}
              placeholder={" "}
              min={1}
              value={inputingProductQty?.[data?.uuid] || 1}
              onChange={(e) => {
                onChangeProductQty((prev: any) => ({
                  ...prev,
                  [data?.uuid]: parseInt(e.target.value),
                }));
              }}
              className={styles.input}
              type="number"
              required
            />
          </div>
          <div>
            <UiButton
              customType="button"
              onClick={() => {
                onSelectProduct(data);
              }}
              title={"Select"}
              size={GENERAL_UI_SIZE.XSMALL}
              type={GENERAL_UI_COLOR.LIGHT}
            />
          </div>
        </div>
      );
    },
  },
];
