import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import ProductTypeDetailForm from "./view-product-type-form";
import { formSchema } from "./formSchema";

import { IProductType } from "../../types/product";
import { useApiCaller } from "../../apis/config";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { PRODUCTS_TYPE_LIST } from "../../routings/path";
import HeaderInfoPanel from "../../components/panel-header-info";
import UiButton from "../../components/ui-button";
import NotFoundPanel from "../../components/panel-not-found";
import SmallCollumnCenterGridLayout from "../../components/grid-layout-small-collumn-center";
import {
  getProductTypeDetailApi,
  getProductTypeListApi,
} from "../../apis/paths/products";

const Component = () => {
  const [productType, setProductType] = useState<IProductType>({});
  const { typeId } = useParams();

  const id = typeId || "";
  const navigate = useNavigate();

  const [params] = useState<{
    page: number;
    per: number;
    id: string;
  }>({
    page: 1,
    per: 50,
    id,
  });

  const { result } = useApiCaller({
    api: getProductTypeDetailApi,
    params,
  });

  const { result: productTypes } = useApiCaller({
    api: getProductTypeListApi,
    params: DEFAULT_PAGINATION_PARAMS,
  });

  useEffect(() => {
    setProductType(result?.data);
  }, [result]);

  const {
    setValue,
    register,
    control,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    if (!productType) return;

    const keys = Object.keys(formSchema?.fields);
    keys.forEach((key: any) => {
      setValue(key, productType[key as keyof IProductType]);
    });
  }, [result, productType]);

  if (result?.status === "ERROR" || productTypes?.status === "ERROR")
    return <NotFoundPanel />;

  return (
    <>
      <div>
        <div className="mb-4">
          <HeaderInfoPanel
            isDisplayInfoArea={true}
            title={productType?.name}
            actionArea={() => (
              <UiButton
                type={GENERAL_UI_COLOR.PRIMARY}
                onClick={() => {
                  navigate(`${PRODUCTS_TYPE_LIST}/${id}/edit`);
                }}
                title={"Edit Product Type"}
              />
            )}
          />
        </div>
        <SmallCollumnCenterGridLayout>
          <ProductTypeDetailForm
            data={productType}
            disabled
            control={control}
            errors={errors}
            register={register}
            dirtyFields={dirtyFields}
          />
        </SmallCollumnCenterGridLayout>
      </div>
    </>
  );
};

export default Component;
