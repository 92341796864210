import * as R from "../../routings/path";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import NumberTableCell from "../../components/table-cell-number";

export const locationSchema = [
  {
    name: "Code",
    cell: (data: any) => {
      return (
        <ProductTableCell title={data?.code} imageSize={40} maxWidth={200} />
      );
    },
  },
  {
    name: "Items",
    align: "right",
    cell: (data: any) => data?.product_item_count,
  },
];

export const saleOrderSchema = (packingUuid: string) => [
  {
    name: "Number",
    cell: (data: any) => (
      <ProductTableCell
        title={data?.number}
        description={`#${data?.channel}`.toUpperCase()}
        linkTo={`${R.PACKINGS_LIST}/${packingUuid}/${data?.number}`}
        maxWidth={170}
        isCopyAble={{ title: true }}
      />
    ),
  },
  {
    name: "Quantity",
    align: "center",
    textSize: "xs",
    width: 120,
    cell: (data: any) => (
      <NumberTableCell
        value={data?.product_items_count}
        className={{ content: "fs-5 fw-bold" }}
      />
    ),
  },
  {
    name: "Status",
    align: "right",
    cell: (data: any) => <StatusTableCell status={data?.status} />,
  },
];
