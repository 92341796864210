import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { generateUrlParams } from "../../utils/url";
import { get, post } from "../config";

export const getDispatchListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
  filter = DEFAULT_PAGINATION_PARAMS.filter,
}: {
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
  filter?: object | null;
}) =>
  get({
    path: `/wms/dispatches${generateUrlParams({
      page,
      per,
      keyword,
      sort,
      filter,
    })}`,
  });

export const getDispatchDetailApi = ({ uuid }: { uuid: string }) =>
  get({
    path: `/wms/dispatches/${uuid}`,
  });

export const printDispatchApi = ({ uuid }: { uuid: string }) =>
  post({
    path: `/wms/dispatches/${uuid}/print`,
  });
