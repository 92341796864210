import classnames from "classnames";
import Icon from "../ui-icon";
import styles from "./style.module.scss";

interface IPanelNotFoundProps {
  title?: string;
  description?: string;
  panelClassName?: string;
}
const NotFoundPanel = ({
  title = "Page not found",
  description = "Something went wrong, please try again",
  panelClassName,
}: IPanelNotFoundProps) => (
  <div className={classnames("row mb-4", styles.container)}>
    <div className="col-12 ">
      <div
        className={classnames(
          "card p-4 d-flex align-items-center justify-content-center",
          styles.panel,
          panelClassName
        )}
      >
        <div>
          <Icon
            name="notfound"
            className={classnames(styles.configIcon, "d-block")}
          />
        </div>
        <h2>{title}</h2>
        <p className="lead mb-0">{description}</p>
      </div>
    </div>
  </div>
);

export default NotFoundPanel;
