/* eslint-disable react-hooks/exhaustive-deps */
import { htmlStripTag } from "../../utils/format";
import UiButton from "../../components/ui-button";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { GENERAL_UI_COLOR } from "../../constants/color";

import styles from "./styles.module.scss";
import { useEffect } from "react";

const Component = ({
  products,
  createdPackages,
  productsToCreatePackage,
  onAddProduct,
  setProductPrepareForPackage,
}: {
  products: Array<any>;
  createdPackages: Array<any>;
  productsToCreatePackage: Array<any>;
  onAddProduct: any;
  setProductPrepareForPackage: any;
}) => {
  const combinedProductPrepareForPackage = products?.map((product: any) => {
    let quantity = product.quantity;

    productsToCreatePackage?.forEach((product2CP: any) => {
      if (product.uuid === product2CP.uuid) {
        quantity = quantity - product2CP.quantity;
      }
    });

    createdPackages?.forEach((createdPackage: any) => {
      createdPackage?.products?.forEach((productInPackage: any) => {
        if (product.uuid === productInPackage.uuid) {
          quantity = quantity - productInPackage.quantity;
        }
      });
    });

    return {
      ...product,
      quantity,
    };
  });

  useEffect(() => {
    setProductPrepareForPackage(combinedProductPrepareForPackage);
  }, [products, createdPackages]);

  return (
    <>
      <div className={styles.selectedProductHeading}>
        <label>Selected Product</label>
      </div>
      <div className={styles.selectedProducts}>
        {!combinedProductPrepareForPackage ||
        !combinedProductPrepareForPackage.length ||
        combinedProductPrepareForPackage.every(
          (product: any) => product.quantity <= 0
        ) ? (
          <span className={styles.empty}>Please add product in Step. 2</span>
        ) : (
          combinedProductPrepareForPackage?.map((product: any) => {
            if (!product || product.quantity <= 0) return null;

            return (
              <div
                className={styles.productItem}
                key={`product-${product.uuid}`}
              >
                <div className={styles.info}>
                  <img src={product?.image_url} />
                  <div className={styles.content}>
                    <div className={styles.address}>
                      {htmlStripTag(product?.name)}
                    </div>
                  </div>
                </div>
                <div className={styles.action}>
                  <div
                    className={`${styles.count} ${
                      !product.quantity && styles.outOfStock
                    }`}
                  >
                    {product.quantity}
                  </div>
                  <UiButton
                    title="Add"
                    disabled={!product.quantity}
                    onClick={() =>
                      product.quantity > 0 && onAddProduct(product)
                    }
                    customType="button"
                    size={GENERAL_UI_SIZE.SMALL}
                    type={GENERAL_UI_COLOR.DARK}
                  />
                </div>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

export default Component;
