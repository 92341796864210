import ActionRolePermissionCell from "../../components/table-cell-role-edit-permission";

export interface ISchemaProps {
  onSetValue: any;
  isSystem?: boolean;
  grantedPermissions?: any;
  control?: any;
}

export const schema = ({
  onSetValue,
  isSystem,
  grantedPermissions,
  control,
}: ISchemaProps) => [
  {
    name: "Model",
    align: "center",
    textSize: "xs",
    cell: (data: any) => (
      <ActionRolePermissionCell
        data={data}
        isSystem
        control={control}
        type="model"
        grantedPermissions={grantedPermissions}
      />
    ),
  },
  {
    name: "View",
    align: "center",
    description:"List, Detail",
    textSize: "xs",
    cell: (data: any) => (
      <ActionRolePermissionCell
        data={data}
        isSystem
        control={control}
        type="view"
        grantedPermissions={grantedPermissions}
      />
    ),
  },
  {
    name: "Add",
    align: "center",
    description:"Create New Record",
    textSize: "xs",
    cell: (data: any) => (
      <ActionRolePermissionCell
        data={data}
        isSystem
        control={control}
        type="add"
        grantedPermissions={grantedPermissions}
      />
    ),
  },
  {
    name: "Remove",
    align: "center",
    description:"Destroy Record",
    textSize: "xs",
    cell: (data: any) => (
      <ActionRolePermissionCell
        data={data}
        isSystem
        control={control}
        type="remove"
        grantedPermissions={grantedPermissions}
      />
    ),
  },
  {
    name: "Edit",
    align: "center",
    description:"Update Record",
    textSize: "xs",
    cell: (data: any) => (
      <ActionRolePermissionCell
        data={data}
        isSystem
        control={control}
        type="edit"
        grantedPermissions={grantedPermissions}
      />
    ),
  },
  {
    name: "Other",
    align: "center",
    description:"Extra Actions",
    textSize: "xs",
    cell: (data: any) => (
      <ActionRolePermissionCell
        isSystem
        data={data}
        control={control}
        type="other"
        grantedPermissions={grantedPermissions}
      />
    ),
  },
  {
    name: "Full",
    align: "center",
    description:"All actions",
    textSize: "xs",
    cell: (data: any) => (
      <ActionRolePermissionCell
        data={data}
        isSystem
        control={control}
        type="full"
        grantedPermissions={grantedPermissions}
      />
    ),
  },
];
