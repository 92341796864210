import React from "react";
import { useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface IPreviewPipelineProps {
  imageSrc?: string;
  width?: number;
  height?: number;
}

const PreviewPopup = ({
  imageSrc,
  width = 350,
  height = 350,
}: IPreviewPipelineProps) => {
  const [loaded, setLoaded] = useState(false);

  if (!imageSrc) return null;

  return (
    <div
      className={
          classNames(
            styles.popup,
            loaded ? 'd-flex justify-content-center align-items-center' : 'd-none',
          )
        }
      style={{
        width: width,
        height: height,
      }}
      >
        <img
          src={imageSrc}
          alt="Preview"
          className={styles.mainImage}
          onError={(e) => {
            setLoaded(false);
          }}
          onLoad={(e) => {
            setLoaded(true);
          }}
        />
    </div>
  );
};

export default PreviewPopup;
