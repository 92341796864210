import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getNotesApi } from "../../apis/paths/note";
import { getProductDetailApi } from "../../apis/paths/products";
import { useApiCaller } from "../../apis/config";
import { apiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import UiLoadingPanel from "../../components/ui-loading-panel";
import NotFoundPanel from "../../components/panel-not-found/component";
import GridLayoutTwoColumn from "../../components/grid-layout-two-column/component";

import ProductDetailSidebar from "./sidebar";
import PanelEdit from "./view-panel-detail";

const Component = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [noteQuantity, setNoteQuantity] = useState(0);
  const [productInfo, setProductInfo] = useState<any>({});

  const { productCode: code } = useParams();
  const [params] = useState({ code });

  const { result, loading } = useApiCaller({
    api: getProductDetailApi,
    params: params,
  });

  useEffect(() => {
    if (result?.data) setProductInfo(result?.data);
  }, [result]);

  const getNotes = async (notable_uuid: string) => {
    const waitUpdateProduct = await apiCaller({
      api: getNotesApi,
      params: {
        notable_type: "Product",
        notable_uuid,
      },
    });
    if (waitUpdateProduct.status === API_RESPONSE_STATUS.SUCCESS) {
      setNoteQuantity(waitUpdateProduct?.data?.list?.length);
    }
  };

  useEffect(() => {
    if (productInfo?.uuid) {
      getNotes(productInfo?.uuid);
    }
  }, [productInfo]);

  if (loading) return <UiLoadingPanel />;

  if (result?.status === "ERROR") return <NotFoundPanel />;

  return (
    <>
      <GridLayoutTwoColumn
        widthDivision={[3, 9]}
        leftCol={
          <ProductDetailSidebar
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            noteQuantity={noteQuantity}
          />
        }
        rightCol={
          <PanelEdit
            activeTab={activeTab}
            setNoteQuantity={setNoteQuantity}
            product={productInfo}
          />
        }
      />
    </>
  );
};

export default Component;
