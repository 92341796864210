import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiCaller } from "../apis/config";
import { getMySessionsApi } from "../apis/paths/sessions";

const sliceName = "mySession";

interface IMySessionProps {
  mySession: any;
  isLoading: boolean;
  error: any;
}

const INITIAL_STATE: IMySessionProps = {
  mySession: [],
  isLoading: false,
  error: null,
};

export const fetchMySession = createAsyncThunk(
  `${sliceName}/fetchMySession`,
  async () => {
    const response = await apiCaller({
      api: getMySessionsApi,
      params:{
        page:1,
        per:5
      }
    });

    return response?.data;
  }
);

export const mySessionSlice = createSlice({
  name: sliceName,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMySession.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchMySession.fulfilled, (state, { payload }) => {
      state.mySession = payload;
    });
    builder.addCase(fetchMySession.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const { actions } = mySessionSlice;

export default mySessionSlice.reducer;
