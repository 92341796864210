import classNames from "classnames";

const GeneralHeading = ({
  children,
  className = "mb-3 pb-3",
}: {
  children: any;
  className?: string;
}) => (
  <h5
    style={{ textTransform: "uppercase" }}
    className={classNames("text-sm font-bold", className)}
  >
    {children}
  </h5>
);

export default GeneralHeading;
