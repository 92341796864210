import { get, patch } from "../config";

export const getShippingServiceApi = () =>
  get({
    path: `/wms/configurations/shipping_services`,
  });


export const getDefaultShippingServiceApi = () =>
  get({
    path: `/wms/configurations/shipping_services/default`,
  });

export const updateDefaultShippingServiceApi = ({
  code,
}: {
  code: string;
}) =>
  patch({
    path: `/wms/configurations/shipping_services/default`,
    data: {
      code,
    },
  });

export const toogleShipingServiceStatusApi = ({
  code,
  enabled,
}: {
  code: string;
  enabled: boolean;
}) =>
  patch({
    path: `/wms/configurations/shipping_services/${code}/status`,
    data: {
      enabled,
    },
  });

export const updateConfigurationRequiredKeys = ({
  code,
  value,
  key,
}: {
  code: string;
  key: string;
  value: string;
}) =>
  patch({
    path: `/wms/configurations/shipping_services/${code}/required_keys`,
    data: {
      configurations: [{ key, value }],
    },
  });
