import { useEffect, useState } from "react";

const SERVER_HOST = "http://localhost:9111";

export const usePrinting = () => {
  const [error, setError] = useState<string | null>(null);

  const printHtml = async (html: string): Promise<void> => {
    try {
      await fetch(`${SERVER_HOST}/print/by_html`, {
        method: "POST",
        body: JSON.stringify({ content: html }),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
    } catch (error) {
      setError("Error printing HTML");
    }
  };

  const printUrl = async (
    url: string,
    type: "label" | "paper" = "paper",
    size: {
      height: number;
      width: number;
    } = {
        height: 380,
        width: 300,
      }
  ): Promise<void> => {
    try {
      if (type === "label") {
        // If type is "label", perform the fetch operation
        await fetch(`${SERVER_HOST}/print/by_url`, {
          method: "POST",
          body: JSON.stringify({ link: url }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
      } else {
        // If type is not "label", open the URL in a new window
        const printWindow = window.open(
          url,
          "_blank",
          `height=${size?.height},width=${size?.width}`
        );
        printWindow?.print();
      }
    } catch (error) {
      window.open(url, "_blank", `height=${size?.height},width=${size?.width}`);
      setError("Error fetching URL");
    }
  };

  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    return () => {
      clearError();
    };
  }, []);

  return {
    printHtml,
    printUrl,
    error,
    clearError,
  };
};
