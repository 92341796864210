import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { generateUrlParams } from "../../utils/url";
import { del, get, patch, post, put } from "../config";

interface IGetLabelsListApi {
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
  filter?: object | null;
}

interface IGetLabelDetailApi {
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
  filter?: object | null;
  uuid: string;
}

export interface ICreateLabel {
  name: string;
  description: string;
  label_type: string;
  color?: string;
}

export interface IUpdateLabel {
  uuid: string;
  name: string;
  description: string;
  label_type: string;
  color?: string;
}

export const getLabelsListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
  filter = DEFAULT_PAGINATION_PARAMS.filter,
}: IGetLabelsListApi) =>
  get({
    path: `/wms/labels${generateUrlParams({
      page,
      per,
      keyword,
      sort,
      filter,
    })}`,
  });

export const getLabelDetailApi = ({ uuid }: IGetLabelDetailApi) =>
  get({
    path: `/wms/labels${generateUrlParams({
      filter: [
        {
          field: "uuid",
          operator: "cont_any",
          value: uuid,
        },
      ],
    })}`,
  });

export const createLabelsApi = ({
  name,
  description,
  label_type,
  color,
}: ICreateLabel) =>
  post({
    path: `/wms/labels`,
    data: {
      name,
      description,
      label_type,
      color,
    },
  });

export const updateLabelsApi = ({
  uuid,
  name,
  description,
  label_type,
  color,
}: IUpdateLabel) =>
  put({
    path: `/wms/labels/${uuid}`,
    data: {
      name,
      description,
      label_type,
      color,
    },
  });

export const getDeletedLabelsListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
  filter = DEFAULT_PAGINATION_PARAMS.filter,
}: IGetLabelsListApi) =>
  get({
    path: `/wms/labels/deleted${generateUrlParams({
      page,
      per,
      keyword,
      sort,
      filter,
    })}`,
  });

export const getLinkObjectLabelsApi = ({
  linked_object_type,
  linked_object_uuid,
}: {
  linked_object_type: string;
  linked_object_uuid?: string;
}) =>
  get({
    path: `/wms/labels/linked_object_labels${generateUrlParams({
      linked_object_type,
      linked_object_uuid,
    })}`,
  });

export const unassignLabelsApi = ({
  label_assignment_uuid,
}: {
  label_assignment_uuid: string;
}) =>
  del({
    path: `/wms/labels/unassign_label${generateUrlParams({
      label_assignment_uuid,
    })}`,
  });

export const assignLabelsApi = ({
  label_uuid,
  linked_object_type,
  linked_object_uuid,
  note,
}: {
  label_uuid: string;
  linked_object_type: string;
  linked_object_uuid?: string;
  note?: string;
}) =>
  post({
    path: `/wms/labels/assign_label`,
    data: {
      label_uuid,
      linked_object_type,
      linked_object_uuid,
      note,
    },
  });

export const editLabelsApi = ({
  label_assignment_uuid,
  note,
}: {
  label_assignment_uuid: string;
  note?: string;
}) =>
  put({
    path: `/wms/labels/label_assignments/${label_assignment_uuid}`,
    data: {
      note,
    },
  });

export const toggleLabelsApi = ({ uuid }: { uuid: string }) =>
  patch({
    path: `/wms/labels/${uuid}/toggle`,
  });

export const printProductCustomerMessageApi = ({ uuid }: { uuid: string }) =>
  post({
    path: `/wms/labels/label_assignments/${uuid}/print`,
  });
