import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as R from "../../routings/path";
import { GENERAL_UI_SIZE } from "../../constants/size";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import TableCellStatus from "../../components/table-cell-status";
import HeaderInfoPanel from "../../components/panel-header-info";
import GeneralPanel from "../../components/panel-general";
import TablePanel from "../../components/panel-table";
import UiTextArea from "../../components/ui-text-area";
import UiButton from "../../components/ui-button";
import UiInput from "../../components/ui-input";
import PanelNote from "../../components/panel-note/component";
import ProductTypesSearchBox from "../product-edit/view-product-type-search-box";

import { locationSchema, schema } from "./schema";
import { useApiCaller } from "../../apis/config";
import {
  getProductTypeListApi,
  getStockProductDetailApi,
} from "../../apis/paths/products";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import UiContentHeading from "../purchase-order-detail/view-section-heading";
import PanelLabelController from "../../components/ui-panel-label-controller/component";
import StockInLocationPopUp from "./view-popup-stock-by-warehouse";
import StockHistoryPopUp from "./view-popup-stock-history";

const PanelEdit = ({
  activeTab,
  setNoteQuantity,
  product,
}: {
  activeTab: number;
  setNoteQuantity: any;
  product: any;
}) => {
  const navigate = useNavigate();
  const [isOpenStockInLocationModal, setIsOpenStockInLocationModal] = useState(false);
  const [isOpenStockHistoryModal, setIsOpenStockHistoryModal] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState<any | null>(null);
  const uuid = product?.uuid || "";
  const [selectedItems, setSelectedItems] = useState<any>([]);

  const typeIds = product?.types?.map((type: any) => type?.id);

  const { result: productTypes } = useApiCaller({
    api: getProductTypeListApi,
    params: DEFAULT_PAGINATION_PARAMS,
  });

  const formatedAllOptionProductTypes = productTypes?.data?.list?.map(
    (productType: any) => ({
      value: productType.id,
      content: productType.name,
    })
  );

  useEffect(() => {
    setValue(
      "product_types",
      selectedItems?.map((item: any) => ({ id: item.value }))
    );
  }, [selectedItems]);

  const { register, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!product) return;

    const keys = Object.keys(schema?.fields);
    keys.forEach((key: any) => {
      setValue(key, product[key]);
    });
  }, [product]);

  const renderDetail = () => (
    <>
      <GeneralPanel panel={{ subTitle: "Product Information" }}>
        <TwoColumnGridLayout
          className={{ container: "px-0" }}
          leftCol={
            <>
              <UiInput
                title={"Name"}
                name={"name"}
                register={register}
                viewMode
              />
              <UiInput
                title={"Short name"}
                name={"short_name"}
                register={register}
                viewMode
              />
              <UiTextArea
                title={"Description"}
                name={"description"}
                register={register}
                rows={9}
                viewMode
              />
            </>
          }
          rightCol={
            <>
              <UiInput
                title={"Code"}
                name={"code"}
                register={register}
                viewMode
              />
              <UiInput
                title={"Gtin Code"}
                name={"gtin_code"}
                register={register}
                viewMode
              />
              <UiInput
                title={"Gtin Type"}
                name={"gtin_type"}
                register={register}
                viewMode
              />
              <UiInput
                title={"Reference Code"}
                name={"reference_code"}
                register={register}
                viewMode
              />
              <UiInput
                title={"UUID"}
                name={"uuid"}
                register={register}
                viewMode
              />
              {formatedAllOptionProductTypes &&
                typeIds &&
                formatedAllOptionProductTypes.length > 0 && (
                  <ProductTypesSearchBox
                    isDisabled
                    optionList={formatedAllOptionProductTypes}
                    setSelectedItems={setSelectedItems}
                    formItem={{ typeIds: typeIds }}
                  />
                )}
            </>
          }
        />
      </GeneralPanel>
      <GeneralPanel panel={{ subTitle: "Packaging Information" }}>
        <TwoColumnGridLayout
          className={{ container: "px-0" }}
          leftCol={
            <>
              <UiInput
                title={"Capacity Value"}
                name={"capacity_value"}
                register={register}
                viewMode
              />
              <UiInput
                title={"Capacity Unit"}
                name={"capacity_unit"}
                register={register}
                viewMode
              />
              <UiInput
                title={"Display Width"}
                name={"display_width"}
                register={register}
                viewMode
              />
              <UiInput
                title={"Display Length"}
                name={"display_length"}
                register={register}
                viewMode
              />
              <UiInput
                title={"Display Height"}
                name={"display_height"}
                register={register}
                viewMode
              />
            </>
          }
          rightCol={
            <>
              <UiInput
                title={"Packaging Weight"}
                name={"packaging_weight"}
                register={register}
                viewMode
              />
              <UiInput
                title={"Packaging Width"}
                name={"packaging_width"}
                register={register}
                viewMode
              />
              <UiInput
                title={"Packaging Height"}
                name={"packaging_height"}
                register={register}
                viewMode
              />
              <UiInput
                title={"Packaging Length"}
                name={"packaging_length"}
                register={register}
                viewMode
              />
            </>
          }
        />
      </GeneralPanel>

      <GeneralPanel className="pt-3">
        <UiContentHeading>Labels</UiContentHeading>
        {uuid && (
          <div className="mt-3">
            <PanelLabelController
              linkedObjectType="Product"
              isEditable={false}
              uniqueUuid={uuid as string}
            />
          </div>
        )}
      </GeneralPanel>
    </>
  );

  const handleClickItem = (selectedWarehouse: any) => {
    setIsOpenStockInLocationModal(true);
    setSelectedWarehouse(selectedWarehouse);
  };

  const handleClickCheckHistory = (selectedWarehouse: any) => {
    setIsOpenStockHistoryModal(true);
    setSelectedWarehouse(selectedWarehouse);
  };

  const warehouseUuid = selectedWarehouse?.warehouse?.uuid;

  return (
    <>
      {!!warehouseUuid && !!uuid && (
      <div>
        <StockInLocationPopUp
          onCloseModal={() => {
            setIsOpenStockInLocationModal(false);
          }}
          key={warehouseUuid}
          product={product}
          productCode={uuid}
          warehouseUuid={warehouseUuid}
          isShowModal={isOpenStockInLocationModal}
        />
        <StockHistoryPopUp
          onCloseModal={() => {
            setIsOpenStockHistoryModal(false);
          }}
          key={warehouseUuid}
          product={product}
          productCode={uuid}
          warehouseUuid={warehouseUuid}
          isShowModal={isOpenStockHistoryModal}
        />
        </div>
      )}

      <HeaderInfoPanel
        title={product?.name}
        image={product?.image_url}
        infoArea={() => (
          <TableCellStatus
            status={product?.status}
            size={GENERAL_UI_SIZE.SMALL}
          />
        )}
        actionArea={() =>
          activeTab === 0 && (
            <UiButton
              onClick={() =>
                navigate(`${R.PRODUCTS_LIST}/${product.code}/edit`)
              }
              title={"Edit"}
            />
          )
        }
      />
      <div className="mt-4">
        {activeTab === 0 && renderDetail()}
        {activeTab === 1 && product?.uuid && (
          <PanelNote
            notable_type="Product"
            notable_uuid={product?.uuid as string}
            setNoteQuantity={setNoteQuantity}
          />
        )}
        {activeTab === 2 && product?.uuid && (
          <>
            <TablePanel
              apiPath={getStockProductDetailApi}
              apiParams={{ code: product?.uuid }}
              panel={{
                title: "Stock By Warehouse",
                description: "List of locations that contains this product",
              }}
              tableSchema={locationSchema({ onClickItem: handleClickItem, onClickCheckHistory: handleClickCheckHistory})}
            />
          </>
        )}
      </div>
    </>
  );
};

export default PanelEdit;
