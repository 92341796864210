import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { generateUrlParams } from "../../utils/url";
import { get, post, patch, del } from "../config";

interface IGetPackingListApi {
  page?: number;
  per?: number;
  keyword?: string;
  filter?: any[] | null;
  sort?: any[] | null;
  status?: string;
}

export interface IPackedProduct {
  gtin_code: string;
  quantity: number;
}

export interface IPackedMaterial {
  code: string;
  quantity: number;
}

export interface IDeletePackingSaleOrderMaterialApi {
  packingId: string;
  saleOrderNumber: string;
  materialCode: string;
}

export interface IDeletePackingSaleOrderPackageApi {
  packingId: string;
  saleOrderNumber: string;
  packageNumber: string;
}

export const getPackingListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  filter = DEFAULT_PAGINATION_PARAMS.filter,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
  status,
}: IGetPackingListApi) =>
  get({
    path: `/wms/pack_lists${generateUrlParams({
      page,
      per,
      keyword,
      filter,
      sort,
      status,
    })}`,
  });

export const getPackingDetailApi = ({ uuid }: { uuid: string }) =>
  get({
    path: `/wms/pack_lists/${uuid}/packing`,
  });

export const getPackingProductsApi = ({ uuid }: { uuid: string }) =>
  get({
    path: `/wms/pack_lists/${uuid}/products`,
  });

export const getPackingSaleOrderDetailApi = ({
  packingId,
  saleOrderNumber,
}: {
  packingId: string;
  saleOrderNumber: string;
}) =>
  get({
    path: `/wms/pack_lists/${packingId}/packing/sale_orders/${saleOrderNumber}`,
  });

export const getGroupedPackingSaleOrderDetailApi = ({
  packingId,
  saleOrderNumber,
}: {
  packingId: string;
  saleOrderNumber: string;
}) =>
  get({
    path: `/wms/pack_lists/${packingId}/packing/sale_orders/${saleOrderNumber}/products`,
  });

export const getPackingSaleOrderFromScannedProductApi = ({
  uuid,
  gtin_code,
}: {
  uuid: string;
  gtin_code: string;
}) =>
  get({
    path: `/wms/pack_lists/${uuid}/packing/sale_orders/next${generateUrlParams({
      gtin_code,
    })}`,
  });

export const deletePackingSaleOrderMaterialApi = ({
  packingId,
  saleOrderNumber,
  materialCode,
}: IDeletePackingSaleOrderMaterialApi) =>
  del({
    path: `/wms/pack_lists/${packingId}/packing/sale_orders/${saleOrderNumber}/materials/${materialCode}`,
  });

export const deletePackingSaleOrderPackageApi = ({
  packingId,
  saleOrderNumber,
  packageNumber,
}: IDeletePackingSaleOrderPackageApi) =>
  del({
    path: `/wms/pack_lists/${packingId}/packing/sale_orders/${saleOrderNumber}/packages/${packageNumber}`,
  });

export interface IaddMaterialsToPackingListApi {
  packingId: string;
  saleOrderNumber: string;
  materialCode: string;
}

export const addMaterialsToPackingListApi = ({
  packingId,
  saleOrderNumber,
  materialCode,
}: IaddMaterialsToPackingListApi) =>
  post({
    path: `/wms/pack_lists/${packingId}/packing/sale_orders/${saleOrderNumber}/materials`,
    data: {
      material_code: materialCode,
    },
  });

export const printSaleOrdersLabelApi = ({ number }: { number: string }) =>
  post({
    path: `/wms/sale_orders/${number}/print`,
  });

export const printSaleOrdersProductsPackagesApi = ({
  number,
  package_code,
}: {
  number: string;
  package_code: string;
}) =>
  post({
    path: `/wms/sale_orders/${number}/packages/${package_code}/print_products`,
  });

export const printSaleOrdersPackagesLabelApi = ({
  number,
  package_code,
}: {
  number: string;
  package_code: string;
}) =>
  post({
    path: `/wms/sale_orders/${number}/packages/${package_code}/print_label`,
  });

export interface IUpdatePackageApi {
  packedProducts: IPackedProduct[];
  confirm?: boolean;
  saleOrderNumber: string;
  packingId: string;
}

export interface ISplitingPackageApi {
  splitingProducts: IPackedProduct[];
  saleOrderNumber: string;
  packingId: string;
  materials?: IPackedMaterial[];
  isManualSpliting?: boolean;
}

export const updatePackedApi = ({
  packedProducts,
  confirm,
  saleOrderNumber,
  packingId,
}: IUpdatePackageApi) =>
  patch(
    {
      path: `/wms/pack_lists/${packingId}/packing/sale_orders/${saleOrderNumber}/packed`,
      data: {
        packed_products: packedProducts,
        confirm,
      },
    },
    true
  );

export const createSplitingPackedApi = ({
  splitingProducts,
  saleOrderNumber,
  packingId,
  materials,
  isManualSpliting,
}: ISplitingPackageApi) => {
  let formatedMaterials = materials;

  if (isManualSpliting) {
    formatedMaterials = [];
  }

  return post(
    {
      path: `/wms/pack_lists/${packingId}/packing/sale_orders/${saleOrderNumber}/packages`,
      data: {
        products: splitingProducts,
        materials: formatedMaterials,
      },
      // data: {
      //   products: splitingProducts,
      //   materials,
      // },
    },
    true
  );
};

export const getSaleOrderPackagesApi = ({
  saleOrderNumber,
}: {
  saleOrderNumber: string;
}) => {
  if (!saleOrderNumber) return;

  return get({
    path: `/wms/sale_orders/${saleOrderNumber}/packages`,
  });
};
