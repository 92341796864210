import * as yup from "yup";
import { GENERAL_STATUS } from "../../constants/status";

export const formSchema = yup
  .object({
    name: yup.string().required(),
    username: yup.string().required(),
    status: yup.string().oneOf([GENERAL_STATUS.ACTIVATE ,GENERAL_STATUS.DEACTIVE]).required(),
    expired_at: yup.mixed().required('Expired at is required'),
  })
  .required();
