import classNames from "classnames";

const Component = ({
  title,
  subTitle,
  description,
  actionArea: ActionArea,
  className,
}: {
  title?: string;
  subTitle?: String;
  description?: string;
  actionArea?: any;
  className?: string;
}) => {
  if (!title && !subTitle && !description) return null;

  return (
    <div className={classNames("card-header", className)}>
      <div className="d-lg-flex">
        <div>
          {title && <h5 className="mb-0">{title}</h5>}
          {subTitle && (
            <h5
              style={{ textTransform: "uppercase" }}
              className="text-sm mb-0 font-bold"
            >
              {subTitle}
            </h5>
          )}
          {description && <p className="text-sm mb-0">{description}</p>}
        </div>
        <div className="ms-auto my-auto mt-lg-0 mt-4">
          <div
            className="ms-auto my-auto"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {ActionArea && <ActionArea />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component;
