import React, { useState } from "react";

import { stockHistorySchema } from "./schema";

import { getProductStockHistoryApi } from "../../apis/paths/products";
import { useApiCaller } from "../../apis/config";
import Typography from "../../components/ui-typography/component";
import TableDataPanel from "../../components/panel-table-data";
import PopupConfirmation from "../../components/popup-confirmation/component";

interface IStockHistoryPopup {
  isShowModal: boolean;
  onCloseModal?: () => void;
  title?: string | React.ReactNode;
  header?: string | React.ReactNode;
  productCode: string;
  product: any;
  warehouseUuid: string;
}

const StockHistoryPopup = ({
  isShowModal,
  onCloseModal,
  product,
  productCode,
  warehouseUuid,
}: IStockHistoryPopup) => {
  const [params] = useState<{
    page: number;
    per: number;
    code: string;
    warehouse_uuid: string;
  }>({
    page: 1,
    per: 50, // TODO: Add Pagination for this modal
    code: productCode,
    warehouse_uuid: warehouseUuid,
  });

  const { result: stockHistoryOfProduct } = useApiCaller({
    api: getProductStockHistoryApi,
    params,
  });

  return (
    <PopupConfirmation
      isShowModal={isShowModal}
      onCloseModal={onCloseModal}
      size="xl"
      header={
        <Typography fontSize="fs-5" fontWeight="fw-bold">
          Stock History of:{" "}
          <Typography fontSize="fs-5" fontWeight="fw-normal">
            {product?.name}
          </Typography>
        </Typography>
      }
    >
      <div className="modal-body">
        <TableDataPanel
          data={stockHistoryOfProduct?.data?.list}
          schema={stockHistorySchema()}
        />
      </div>
    </PopupConfirmation>
  );
};

export default StockHistoryPopup;
