import React, { useState } from "react";
import classNames from "classnames";

import { useOutsideClick } from "../../hooks/useOutsideClick";
import Typography from "../ui-typography/component";
import QuickActionPopupConfirmation from "../popup-quick-aciton-confirmation/component";

import styles from "./styles.module.scss";
interface IOverlayAction {
  handleEdit: () => void;
  handleDelete: () => void;
}
const OverlayAction = ({ handleEdit, handleDelete }: IOverlayAction) => {
  const [isOpenOverlay, setIsOpenOverlay] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);

  const handleOpenModal = () => {
    setIsShowModal(true);
  };

  const handleCloseModal = () => {
    setIsShowModal(false);
  };

  const handleClickOutside = () => {
    setIsOpenOverlay(false);
  };

  const overlayRef = useOutsideClick(handleClickOutside);

  return (
    <>
      <div
        className={classNames(
          "text-end ms-auto p-2 px-3 pt-0",
          styles.moreButton
        )}
        onClick={() => {
          setIsOpenOverlay((prev) => !prev);
        }}
      >
        <i className="fas fa-ellipsis-v"></i>
        {isOpenOverlay && (
          <div className={classNames(styles.overlay)} ref={overlayRef}>
            <div
              className={classNames(styles.dropdownItem)}
              onClick={handleEdit}
            >
              <i className="fa-solid fa-pen-to-square opacity-4 me-2"></i>
              <Typography fontWeight="fw-normal" customFontsize={14}>
                Edit
              </Typography>
            </div>
            <div
              className={classNames(styles.dropdownItem)}
              onClick={handleOpenModal}
            >
              <i className="fa-solid fa-trash opacity-4 me-2"></i>
              <Typography fontWeight="fw-normal" customFontsize={14}>
                Delete
              </Typography>
            </div>
          </div>
        )}
      </div>
      <QuickActionPopupConfirmation
        content={ "Are you sure to delete this note ?"}
        isShowModal={isShowModal}
        handleCloseModal={handleCloseModal}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default OverlayAction;
