export const DASHBOARD = "/";

export const AUTH_SIGN_IN = "/sign-in";

export const SALE_ORDER_LIST = "/sale-orders";

export const PRODUCTS_LIST = "/products";
export const PRODUCTS_DETAIL = `${PRODUCTS_LIST}/:productCode`;
export const PRODUCTS_EDIT = `${PRODUCTS_LIST}/:productCode/edit`;
export const PRODUCTS_CREATE = `${PRODUCTS_LIST}/create`;

export const PRODUCTS_TYPE_LIST = `/product-types`;
export const PRODUCTS_TYPE_DETAIL = `${PRODUCTS_TYPE_LIST}/:typeId`;
export const PRODUCTS_TYPE_EDIT = `${PRODUCTS_TYPE_LIST}/:typeId/edit`;
export const PRODUCTS_TYPE_CREATE = `${PRODUCTS_TYPE_LIST}/create`;

export const LABELS_LIST = `/labels`;
export const LABELS_DETAIL = `${LABELS_LIST}/:labelId`;
export const LABELS_EDIT = `${LABELS_LIST}/:labelId/edit`;
export const LABELS_CREATE = `${LABELS_LIST}/create`;

export const RELOCATION_SHIPMENTS_LIST = `/relocation-shipments`;
export const RELOCATION_SHIPMENTS_DETAIL = `${RELOCATION_SHIPMENTS_LIST}/:relocationShipmentId`;
export const RELOCATION_SHIPMENTS_EDIT = `${RELOCATION_SHIPMENTS_LIST}/:relocationShipmentId/edit`;
export const RELOCATION_SHIPMENTS_EDIT_PRODUCT = `${RELOCATION_SHIPMENTS_LIST}/:relocationShipmentId/edit/product`;
export const RELOCATION_SHIPMENTS_CREATE = `${RELOCATION_SHIPMENTS_LIST}/create`;

export const WAREHOUSES_LIST = "/warehouses";
export const WAREHOUSES_DETAIL = `${WAREHOUSES_LIST}/:warehouseId`;

export const WAREHOUSES_EDIT = `${WAREHOUSES_LIST}/:warehouseId/edit`;
export const WAREHOUSES_RELATION = `${WAREHOUSES_LIST}/:warehouseId/relations`;
export const WAREHOUSES_LOCATION_LIST = `${WAREHOUSES_LIST}/:warehouseId/locations`;
export const WAREHOUSES_LOCATION_CREATE = `${WAREHOUSES_LIST}/:warehouseId/locations/create`;
export const WAREHOUSES_LOCATION_DETAIL = `${WAREHOUSES_LIST}/:warehouseId/locations-products/:locationCode`;
export const WAREHOUSES_CREATE = `${WAREHOUSES_LIST}/create`;

export const PURCHASE_ORDERS_LIST = "/purchase-orders";
export const PURCHASE_ORDERS_CREATE = `${PURCHASE_ORDERS_LIST}/create`;
export const PURCHASE_ORDERS_DETAIL = `${PURCHASE_ORDERS_LIST}/:purchaseOrderId`;
export const PURCHASE_ORDERS_EDIT = `${PURCHASE_ORDERS_LIST}/:purchaseOrderId/edit`;
export const PURCHASE_ORDERS_EDIT_PACKAGE = `${PURCHASE_ORDERS_LIST}/:purchaseOrderId/edit/packages`;
export const PURCHASE_ORDERS_EDIT_PRODUCTS = `${PURCHASE_ORDERS_LIST}/:purchaseOrderId/edit/products`;
export const PURCHASE_ORDERS_EDIT_NOTES = `${PURCHASE_ORDERS_LIST}/:purchaseOrderId/edit/notes`;

export const SALE_ORDERS_LIST = `/sale-orders`;
export const SALE_ORDERS_CREATE = `${SALE_ORDERS_LIST}/create`;
export const SALE_ORDERS_DETAIL = `${SALE_ORDERS_LIST}/:saleOrderId`;
export const SALE_ORDERS_EDIT = `${SALE_ORDERS_LIST}/:saleOrderId/edit`;
export const SALE_ORDERS_EDIT_PRODUCT = `${SALE_ORDERS_LIST}/:saleOrderId/edit/product`;
export const SALE_ORDERS_EDIT_SHIPPING_SERVICE = `${SALE_ORDERS_LIST}/:saleOrderId/edit/shipping-service`;
export const SALE_ORDER_KANBAN = `${SALE_ORDERS_LIST}/kanban`;
export const SALE_ORDER_AUDIT_CHANGE = `${SALE_ORDERS_LIST}/:saleOrderId/audit-change`;

export const PICKINGS_LIST = `/pickings`;
export const PICKINGS_CREATE = `/pickings/create`;
export const PICKINGS_DETAIL = `/pickings/:pickingId`;
export const PICKINGS_EDIT = `/pickings/:pickingId/edit`;
export const PICKINGS_EDIT_TASK = `/pickings/:pickingId/edit/task`;
export const PICKINGS_EDIT_SALE_ORDER = `/pickings/:pickingId/edit/sale-order`;

export const PACKINGS_LIST = `/packings`;
export const PACKINGS_CREATE = `${PACKINGS_LIST}/create`;
export const PACKINGS_DETAIL = `${PACKINGS_LIST}/:packingId`;
export const PACKINGS_SALE_ORDER_EDIT = `${PACKINGS_LIST}/:packingId/:saleOrderId`;
export const PACKINGS_SCANNED_PRODUCT_EDIT = `${PACKINGS_LIST}/:packingId/scan-products/:gtinCode`;
export const PACKINGS_SCAN_PRODUCTS_EDIT = `${PACKINGS_LIST}/:packingId/scan-products`;

export const ROLES_LIST = "/roles";
export const ROLES_EDIT = `${ROLES_LIST}/:roleId/edit`;
export const ROLES_DETAIL = `${ROLES_LIST}/:roleId`;
export const ROLES_CREATE = `${ROLES_LIST}/create`;

export const ACCOUNT_LIST = "/accounts";
export const ACCOUNT_CREATE = `${ACCOUNT_LIST}/create`;

export const ACCOUNT_EDIT = `${ACCOUNT_LIST}/:accountId/edit`;
export const ACCOUNT_RESET_PASSWORD = `${ACCOUNT_LIST}/:accountId/edit/reset-password`;
export const ACCOUNT_DEVICES = `${ACCOUNT_LIST}/:accountId/edit/devices`;

export const ACCOUNT_DETAIL = `${ACCOUNT_LIST}/:accountId`;
export const ACCOUNT_DETAIL_DEVICES = `${ACCOUNT_LIST}/:accountId/devices`;

export const CONFIGURATION = `/configurations`;
export const SLA = `${CONFIGURATION}/sla`;
export const SHIPPING_SERVICES = `${CONFIGURATION}/shipping-services`;
export const COMPANY_INFO = `${CONFIGURATION}/company-info`;
export const COMPANY_INFO_EDIT = `${CONFIGURATION}/company-info/edit`;
export const ADVANCED_SETTINGS = `${CONFIGURATION}/advanced-settings`;

export const TPL_ROUTES = `/tpl-routes`;
export const TPL_ROUTES_DETAIL = `${TPL_ROUTES}/:routeId`;
export const TPL_ROUTES_EDIT = `${TPL_ROUTES}/:routeId/edit`;
export const TPL_ROUTES_CREATE = `${TPL_ROUTES}/create`;

export const TASK_LOGGING_LIST = `/task-logging`;
export const TASK_LOGGING_DETAIL = `${TASK_LOGGING_LIST}/:taskId`;
export const TASK_LOGGING_EDIT = `${TASK_LOGGING_LIST}/:taskId/edit`;
export const TASK_LOGGING_CREATE = `${TASK_LOGGING_LIST}/create`;

export const MATERIAL_LIST = `/material`;
export const MATERIAL_DETAIL = `${MATERIAL_LIST}/:materialId`;
export const MATERIAL_EDIT = `${MATERIAL_LIST}/:materialId/edit`;
export const MATERIAL_CREATE = `${MATERIAL_LIST}/create`;

export const SHOP_LIST = `/shops`;
export const SHOP_DETAIL = `${SHOP_LIST}/:shopId`;
export const SHOP_EDIT = `${SHOP_LIST}/:shopId/edit`;
export const SHOP_CREATE = `${SHOP_LIST}/create`;

export const DISPATCH_LIST = `/dispatches`;
export const DISPATCH_DETAIL = `${DISPATCH_LIST}/:dipatchId`;

export const STOCK_CHECK_LIST = `/stock-checks`;
export const STOCK_CHECK_CREATE = `${STOCK_CHECK_LIST}/create`;
export const STOCK_CHECK_DETAIL = `${STOCK_CHECK_LIST}/:stockCheckId`;

export const NOTIFICATION_LIST = `/notifications`;
export const NOTIFICATION_DETAIL = `${NOTIFICATION_LIST}/:notificationId`;

export const STICKER_LIST = `/stickers`;
export const STICKER_CREATE = `${STICKER_LIST}/create`;

export const SAMPLES = "/samples";
