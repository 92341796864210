import { useNavigate } from "react-router-dom";

import * as R from "../../routings/path";
import { ACTIONABLE_TYPE } from "../../constants/actionable";
import { getStickerListApi, printStickerApi } from "../../apis/paths/sticker";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { apiCaller } from "../../apis/config";
import alert from "../../utils/alert";

import TablePanel from "../../components/panel-table";

import { schema } from "./schema";

const Component = () => {
  const navigate = useNavigate();

  const handlePrint = async ({ batch }: any) => {
    const waitPrintSticker = await apiCaller({
      api: printStickerApi,
      params: { batch },
    });

    if (waitPrintSticker.status === API_RESPONSE_STATUS.SUCCESS) {
      window.open(waitPrintSticker?.data?.url);
      return;
    }

    alert.error("Update was not successful!. Please try again!");
  };

  return (
    <>
      <TablePanel
        apiPath={getStickerListApi}
        panel={{
          title: "Stickers",
          description: "List of stickers in the warehouse",
          actionableButtons: [
            {
              actionableType: ACTIONABLE_TYPE.NEW,
              title: "New Sticker",
              onClick: () => navigate(R.STICKER_CREATE),
            },
          ],
        }}
        tableSchema={schema({ onPrint: handlePrint })}
      />
    </>
  );
};

export default Component;
