import * as yup from "yup";
export const schema = yup
  .object()
  .shape({
    quantity: yup
      .number()
      .integer(
        "Please enter the correct integer type and avoid using decimals."
      )
      .min(1)
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required("Please input the quantity"),
  })
  .required();
