import { useCallback } from "react";
import { shallowEqual } from "react-redux";

import { useAppDispatch, useAppSelector } from "./useStateHook";

import * as actions from "../states/extractProductFromCsvFile.flow";

export const useExtractProductFromCsvFile = () => {
  const dispatch = useAppDispatch();

  const extractedProduct = useAppSelector(
    (state) => state.extractProductFromCsvFile,
    shallowEqual
  );

  const fetchProductFromCsvFile = useCallback(
    (file: any[]) => dispatch(actions.fetchProductFromCsvFile(file)),
    [dispatch]
  );

  const clearProducts = useCallback(
    () => dispatch(actions.clearProducts()),
    [dispatch]
  );
  const addDefaultProducts = useCallback(
    (products:any[]) => dispatch(actions.addDefaultProducts(products)),
    [dispatch]
  );

  return {
    extractedProduct,
    fetchProductFromCsvFile,
    clearProducts,
    addDefaultProducts,
  };
};
