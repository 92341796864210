import React from "react";
import classNames from "classnames";

import styles from "./style.module.scss";

import PanelGeneral from "../../components/panel-general/component";
import Avatar from "../../components/ui-avatar/component";
import Typography from "../../components/ui-typography/component";
import Heading from "../../components/ui-heading/component";
import StatusTableCell from "../../components/table-cell-status";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { IAccounts } from "../role-edit/types";

const ViewInvitedMember = ({ accounts }: { accounts: IAccounts[] }) => {
  return (
    <>
      <PanelGeneral
        styles={{ height: 360 }}
        className="ps-0 pe-0"
        panel={{
          title: "Members",
          description: accounts?.length === 0 ? "No members" : "",
        }}
      >
        {accounts?.map((account) => (
          <div
            className={classNames(
              "d-flex gap-2 align-items-center p-2 ps-4 pe-4 ",
              styles.accountItem
            )}
            key={account?.uuid}
          >
            <Avatar
              imageSrc={account?.avatar}
              name={account?.name}
              variant={GENERAL_UI_COLOR.SECONDARY}
            />
            <div className="d-flex align-items-start flex-column justify-content-center">
              <Heading level={6} className="mb-0" fontWeight="normal" textSize={GENERAL_UI_SIZE.SMALL}>{account?.name}</Heading>
              <Typography textSize={GENERAL_UI_SIZE.XSMALL}>{account?.username}</Typography>
            </div>
            <div className="ms-auto">
              <StatusTableCell status={account?.status} />
            </div>
          </div>
        ))}
      </PanelGeneral>
    </>
  );
};

export default ViewInvitedMember;
