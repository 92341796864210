import React, { useState } from "react";
import classNames from "classnames";
import { useParams } from "react-router-dom";

import { IWarehouse } from "./types";
import styles from "./style.module.scss";

import { apiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { removeWarehouseFromAccountApi } from "../../apis/paths/roles";
import alert from "../../utils/alert";
import UiButton from "../../components/ui-button";
import PanelGeneral from "../../components/panel-general/component";
import Typography from "../../components/ui-typography/component";
import Heading from "../../components/ui-heading/component";
import QuickActionPopupConfirmation from "../../components/popup-quick-aciton-confirmation/component";

const ViewInvitedWarehouse = ({
  warehouses,
  onClick,
  setPageReloadVersion,
}: {
  warehouses: IWarehouse[];
  onClick: () => void;
  setPageReloadVersion?: any;
}) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [selectedDeteteMember, setSelectedDeteteMember] = useState<
    string | null
  >(null);
  const { roleId: uuid } = useParams();

  const handleOpenModal = () => {
    setIsShowModal(true);
  };

  const handleCloseModal = () => {
    setIsShowModal(false);
  };

  const handleRemoveWarehouse = async (warehouseUuid: string) => {
    const waitDelete = await apiCaller({
      api: removeWarehouseFromAccountApi,
      params: { uuid, warehouse_uuid: warehouseUuid },
    });

    if (waitDelete.status === API_RESPONSE_STATUS.SUCCESS) {
      setTimeout(() => {
        alert.success("Remove successful!");
      }, 1000);
      setPageReloadVersion((prev: number) => prev + 1);
      return;
    }

    alert.error("Delete was unsuccessful!. Please try again");
  };
  return (
    <>
      <PanelGeneral
        styles={{ height: warehouses?.length !== 0 ? 345 : 336 }}
        className="ps-0 pe-0"
        panel={{
          title: "Accessible Warehouses",
          description:
            warehouses?.length === 0 ? "No accessible warehouse" : "",
          actionArea: () => (
            <UiButton
              type={GENERAL_UI_COLOR.PRIMARY}
              size={GENERAL_UI_SIZE.XSMALL}
              onClick={onClick}
              title={"Add"}
            />
          ),
        }}
      >
        {warehouses?.map((warehouse) => (
          <div
            className={classNames(
              "d-flex gap-2 align-items-center p-2 ps-4 pe-4 justify-content-between",
              styles.accountItem
            )}
            key={warehouse?.uuid}
          >
            <div className="d-flex align-items-start flex-column justify-content-center">
              <Heading level={6} className="mb-0" fontWeight="normal" textSize={GENERAL_UI_SIZE.SMALL}>{warehouse?.name}</Heading>
              <Typography textSize={GENERAL_UI_SIZE.XSMALL}>{warehouse?.code}</Typography>
            </div>
            <div
              className={classNames(
                "ms-4 p-1 ps-2 pe-2 cursor-pointer",
                styles.actionIcon
              )}
              onClick={() => {
                handleOpenModal();
                setSelectedDeteteMember(warehouse.uuid as string);
              }}
            >
              <i className="fas fa-trash text-secondary" aria-hidden="true"></i>
            </div>
          </div>
        ))}
      </PanelGeneral>
      <QuickActionPopupConfirmation
        content={"Are you sure to remove this warehouse ?"}
        isShowModal={isShowModal}
        handleCloseModal={handleCloseModal}
        onConfirm={() => {
          handleRemoveWarehouse(selectedDeteteMember as string);
        }}
      />
    </>
  );
};

export default ViewInvitedWarehouse;
