import { useState } from "react";
import classNames from "classnames";

import ModalGeneral from "../ui-modal-general/component";
import Typography from "../ui-typography/component";

import styles from "./styles.module.scss";

const PanelHelper = () => {
  const [isShowModal, setIsShowModal] = useState(false);

  const generateDocUrl = () => {
    const environment = process.env.REACT_APP_ENV;

    switch (environment) {
      case "production": {
        return "https://docs.lixhouse.com";
      }

      case "staging": {
        return "https://docs.wms-rc.click";
      }

      default:
        return "https://docs.wms-rc.click";
    }
  };

  const docUrl = generateDocUrl();

  return (
    <>
      <ModalGeneral
        isShowModal={isShowModal}
        sideMode="right"
        onCancel={() => {
          setIsShowModal(false);
        }}
        className={{ body: "p-0 overflow-hidden" }}
        header={<Typography fontWeight="fw-bold">Support</Typography>}
        isShowFooter={false}
      >
        <iframe
          className={styles.iframe}
          src={docUrl}
          title="Iframe Example"
        ></iframe>
      </ModalGeneral>

      <div
        className={classNames(
          styles.container,
          "p-2 rounded-circle d-flex align-items-center justify-content-center"
        )}
        onClick={() => setIsShowModal(true)}
      >
        <i className="fa-solid fa-question"></i>
      </div>
    </>
  );
};

export default PanelHelper;
