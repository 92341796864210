import { useState } from "react";
import { useNavigate } from "react-router-dom";

import * as R from "../../routings/path";
import { ACTIONABLE_TYPE } from "../../constants/actionable";
import { deleteProductApi, getProductListApi } from "../../apis/paths/products";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { apiCaller } from "../../apis/config";
import alert from "../../utils/alert";

import TablePanel from "../../components/panel-table";

import { schema } from "./schema";

const Component = () => {
  const navigate = useNavigate();
  const [pageReloadVersion, setPageReloadVersion] = useState(1);

  const handleDeleteProduct = async (productCode: string) => {
    const waitDelete = await apiCaller({
      api: deleteProductApi,
      params: { code: productCode },
    });

    if (waitDelete.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Delete successful!");

      setPageReloadVersion((prev) => prev + 1);
      return;
    }

    alert.error("Delete was unsuccessful!. Please try again");
  };

  return (
    <>
      <TablePanel
        apiPath={getProductListApi}
        pageReloadVersion={pageReloadVersion}
      panel={{
          title: "Products",
          description: "List of product in the warehouse",
          actionableButtons: [
            {
              actionableType: ACTIONABLE_TYPE.NEW,
              title: "New Product",
              onClick: () => navigate(R.PRODUCTS_CREATE),
            },
          ],
        }}
        tableSchema={schema({ onDelete: handleDeleteProduct })}
      />
    </>
  );
};

export default Component;
