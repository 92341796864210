import classNames from "classnames";
import Header from "./view.header";

interface IComponent {
  panel?: {
    title?: string;
    subTitle?: string;
    description?: string;
    actionArea?: any;
    className?: string;
  };
  isDisplayedDataTopTable?: boolean;
  children?: any;
  className?: string;
  containerClassName?: string;
  styles?: any;
}

const GeneralPanel = ({
  panel: panelInfo,
  children: Component,
  className,
  containerClassName,
  styles,
}: IComponent) => {
  return (
    <div className={classNames("row mb-4", containerClassName)}>
      <div className="col-12">
        <div className="card">
          <Header {...panelInfo} />
          <div className="table-responsive">
            <div
              className={classNames("p-4 pt-0 overflow-auto ", className)}
              style={styles}
            >
              <div className="dataTable-container">{Component}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralPanel;
