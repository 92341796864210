import React from "react";
import { Button } from "react-bootstrap";
import PopupConfirmation from "../../components/popup-confirmation/component";

interface IDeleteConfirmationPopupProps {
  content: string | React.ReactNode;
  isShowModal: boolean;
  handleCloseModal?: () => void;
  title?: string | React.ReactNode;
  header?: string | React.ReactNode;
  handleConfirm?: any;
}

const PackingConfirmationPopup = ({
  isShowModal,
  handleCloseModal,
  content,
  title = "The quantity is mismatched!",
  header = "Your attention is required",
  handleConfirm,
}: IDeleteConfirmationPopupProps) => {
  return (
    <PopupConfirmation
      isShowModal={isShowModal}
      onCloseModal={handleCloseModal}
      header={header}
      size="lg"
    >
      <div>
        <div className="modal-body">
          <div className=" text-center">
            <i className="ni ni-bell-55 ni-3x"></i>
            <h4 className="text-gradient text-danger mt-4">{title}</h4>
            <p className="mt-4">{content}</p>
          </div>
        </div>
        <hr className="horizontal dark mt-3" />
        <div className="d-flex mt-3 justify-content-end">
          <Button
            className="mb-2 me-3"
            variant=""
            size="sm"
            onClick={() => {
              handleCloseModal && handleCloseModal();
            }}
          >
            <i className="fa-solid fa-xmark me-2"></i> Let me check
          </Button>

          {handleConfirm && (
            <Button
              size="sm"
              className="mb-2 bg-gradient-primary"
              variant="danger"
              onClick={() => {
                handleConfirm && handleConfirm();
                handleCloseModal && handleCloseModal();
              }}
            >
              Confirm
            </Button>
          )}
        </div>
      </div>
    </PopupConfirmation>
  );
};

export default PackingConfirmationPopup;
