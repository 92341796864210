import React, { useState } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { useOutsideClick } from "../../hooks/useOutsideClick";

interface IOverlay {
  children: any;
  content: any;
  className?: {
    button?: string;
    content?: string;
    overlay?: string;
  };
}
const Overlay = ({ children, content, className }: IOverlay) => {
  const [isOpenOverlay, setIsOpenOverlay] = useState(false);

  const handleClickOutside = () => {
    setIsOpenOverlay(false);
  };

  const overlayRef = useOutsideClick(handleClickOutside);

  return (
    <>
      <div
        className={classNames(styles.moreButton, className?.button)}
        onClick={() => {
          setIsOpenOverlay((prev) => !prev);
        }}
      >
        <div className={classNames(className?.content)}> {content}</div>
        {isOpenOverlay && (
          <div
            className={classNames(styles.overlay, className?.overlay)}
            ref={overlayRef}
          >
            {children}
          </div>
        )}
      </div>
    </>
  );
};

export default Overlay;
