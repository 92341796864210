import { useMemo } from "react";
import { Outlet } from "react-router-dom";

import FixedBannerLayout from "../components/layout-fixed-banner";
import AsideDynamicNavigationLayout from "../components/layout-dynamic-aside-navigation";
import MainContentLayout from "../components/layout-main-content";

const Component = () => {
  const fixedBanner = useMemo(() => <FixedBannerLayout />, []);
  const asideNavigation = useMemo(() => <AsideDynamicNavigationLayout />, []);

  return (
    <>
      {fixedBanner}
      {asideNavigation}
      <MainContentLayout>
        <Outlet />
      </MainContentLayout>
    </>
  );
};

export default Component;
