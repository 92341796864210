import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { generateUrlParams } from "../../utils/url";
import { get, post, put, del, patch } from "../config";

interface IGetRoleListApi {
  page?: number;
  per?: number;
}
export interface IUpdateRoleDetailApi {
  uuid: string;
  name: string;
  status: "activate" | "deactivate";
  description?: string;
  upper_role_uuid?: string;
  permission_ids: number[];
}

export interface ICreateRoleDetailApi {
  name: string;
  status: "activate" | "deactivate";
  description?: string;
  upper_role_uuid?: string;
  permission_ids: number[];
}

interface IGetRoleDetailApi {
  uuid?: string;
}
interface IInviteAccountApi {
  uuid: string;
  uuids: string[];
}

export const getRoleListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
}: IGetRoleListApi) =>
  get({ path: `/wms/roles${generateUrlParams({ page, per })}` });

export const getDeleteRoleApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
}: IGetRoleListApi) =>
  get({ path: `/wms/roles/deleted${generateUrlParams({ page, per })}` });

export const getPermissionApi = () => get({ path: `/wms/permissions` });

export const getRoleDetailApi = ({ uuid }: IGetRoleDetailApi) =>
  get({ path: `/wms/roles/${uuid}` });

export const updateRoleDetailApi = ({
  uuid,
  name,
  status,
  description,
  upper_role_uuid,
  permission_ids,
}: IUpdateRoleDetailApi) =>
  put({
    path: `/wms/roles/${uuid}`,
    data: {
      uuid,
      name,
      status,
      description,
      upper_role_uuid,
      permission_ids,
    },
  });

export const createRoleDetailApi = ({
  name,
  status,
  description,
  upper_role_uuid,
  permission_ids,
}: ICreateRoleDetailApi) =>
  post({
    path: `/wms/roles`,
    data: {
      name,
      status,
      description,
      upper_role_uuid,
      permission_ids,
    },
  });

export const deleleRoleApi = ({ uuid }: { uuid: string }) =>
  del({ path: `/wms/roles/${uuid}` });

export const toogleRoleStatusApi = ({ uuid }: { uuid: string }) =>
  patch({ path: `/wms/roles/${uuid}/status` });

export const restoreRoleApi = ({ uuid }: { uuid: string }) =>
  patch({ path: `/wms/roles/${uuid}/restore` });

export const inviteAccountApi = ({ uuid, uuids }: IInviteAccountApi) =>
  post({
    path: `/wms/roles/${uuid}/members`,
    data: {
      uuids,
    },
  });

export const inviteWarehouseApi = ({ uuid, uuids }: IInviteAccountApi) =>
  post({
    path: `/wms/roles/${uuid}/warehouses`,
    data: {
      uuids,
    },
  });

export const removeMemberFromAccountApi = ({
  uuid,
  account_uuid,
}: {
  uuid: string;
  account_uuid: string;
}) => del({ path: `/wms/roles/${uuid}/members/${account_uuid}` });

export const removeWarehouseFromAccountApi = ({
  uuid,
  warehouse_uuid,
}: {
  uuid: string;
  warehouse_uuid: string;
}) => del({ path: `/wms/roles/${uuid}/warehouses/${warehouse_uuid}` });
