import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useApiCaller } from "../../apis/config";
import { apiCaller } from "../../apis/config";
import { GENERAL_UI_SIZE } from "../../constants/size";
import {
  getWarehouseDetailApi,
  getWarehouseListApi,
} from "../../apis/paths/warehouse";
import {
  getPurchaseOrderDetailApi,
  updatePurchaseOrderApi,
  updatePurchaseOrderStatusApi,
} from "../../apis/paths/purchase-order";
import alert from "../../utils/alert";
import UiButton from "../../components/ui-button";
import UiInput from "../../components/ui-input";
import TablePanel from "../../components/panel-table";
import GeneralPanel from "../../components/panel-general";
import NotFoundPanel from "../../components/panel-not-found/component";
import DatePicker from "../../components/ui-date-picker/component";
import UiLoadingPanel from "../../components/ui-loading-panel";
import RadioComboBox from "../../components/ui-radio-combo-box/component";
import TableCellStatus from "../../components/table-cell-status";
import UiSelectDropdown from "../../components/ui-select-dropdown";
import UiStatusDropdown from "../../components/ui-status-dropdown";
import HeaderInfoPanel from "../../components/panel-header-info";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import PanelLabelController from "../../components/ui-panel-label-controller/component";

import { schema, warehouseSchema } from "./schema";
import { CHANNEL } from "./constants";
import styles from "./styles.module.scss";
import GeneralHeading from "./view-general-heading";
import SelectedWarehouse from "./view-selected-warehouse";
import { convertYYYYMMDDToUnixTime } from "../../utils/date-time";
import { PRODUCT_OVERALL_STATUS } from "../../constants/status";

const Component = () => {
  const navigate = useNavigate();

  const {
    register,
    getValues,
    setValue,
    control,
    formState: { dirtyFields },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [selectedChannel, setSelectedChannel] = useState<any>(
    CHANNEL[0]?.value
  );

  const [purchaseOrderInfo, setPurchaseOrderInfo] = useState<any>({});
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>(null);

  const { purchaseOrderId: uuid } = useParams();
  const [params] = useState({ uuid });

  const { result, loading } = useApiCaller({
    api: getPurchaseOrderDetailApi,
    params: params,
  });

  useEffect(() => {
    if (result?.data) {
      setPurchaseOrderInfo(result?.data);
      setValue(
        "estimatedArrival",
        result?.data?.purchase_order?.estimated_arrival
      );
      setValue("note", result?.data?.purchase_order?.note);
      setValue("name", result?.data?.purchase_order?.name);
      setValue("product_status", result?.data?.purchase_order?.product_status);
      setSelectedChannel(result?.data?.purchase_order?.channel);

      (async () => {
        const waitGetWarehouseDetail = await apiCaller({
          api: getWarehouseDetailApi,
          params: {
            code: result?.data?.purchase_order?.warehouse_uuid,
          },
        });

        if (waitGetWarehouseDetail?.status === "SUCCESS") {
          setSelectedWarehouse(waitGetWarehouseDetail.data);
        }
      })();
    }
  }, [result]);

  const handleUpdateInfo = async () => {
    const data = {
      uuid,
      warehouseUuid: selectedWarehouse.uuid,
      name: getValues("name"),
      note: getValues("note"),
      channel: selectedChannel,
      supplier: getValues("supplier"),
      productStatus: getValues("product_status"),
      estimatedArrival: convertYYYYMMDDToUnixTime(
        getValues("estimatedArrival")
      ),
    };

    const waitUpdatePurchaseOrder = await apiCaller({
      api: updatePurchaseOrderApi,
      params: data,
    });

    if (waitUpdatePurchaseOrder?.status === "SUCCESS") {
      navigate(0);
      alert.success("Update success");
    }
  };

  const purchaseOrderStatus = purchaseOrderInfo?.purchase_order?.status;
  const isEditable = purchaseOrderStatus === "draft";

  if (loading) return <UiLoadingPanel />;

  if (result?.status === "ERROR") return <NotFoundPanel />;

  return (
    <>
      <div className="mb-4">
        <HeaderInfoPanel
          isDisplayInfoArea={true}
          title={`#${purchaseOrderInfo?.purchase_order?.number}`}
          infoArea={() => (
            <div className="d-flex gap-2">
              <TableCellStatus
                status={purchaseOrderStatus}
                size={GENERAL_UI_SIZE.SMALL}
              />
              <TableCellStatus
                status={purchaseOrderInfo?.purchase_order?.product_status}
                size={GENERAL_UI_SIZE.SMALL}
              />
            </div>
          )}
          actionArea={() => (
            <>
              {purchaseOrderInfo?.purchase_order?.status && (
                <UiStatusDropdown
                  isNotable
                  status={purchaseOrderInfo?.purchase_order?.status}
                  triggerableEvents={
                    purchaseOrderInfo?.purchase_order?.triggerable_events
                  }
                  actionApi={updatePurchaseOrderStatusApi}
                  paramsTransformation={(newEvent: string, note: string) => ({
                    uuid: uuid,
                    action: newEvent,
                    note,
                  })}
                  onChangeSuccess={(newData: any) => {
                    setPurchaseOrderInfo(
                      Object.assign({}, purchaseOrderInfo, {
                        purchase_order: newData,
                      })
                    );
                  }}
                />
              )}
              <UiButton
                className="me-2"
                title={"Update"}
                customType="submit"
                onClick={handleUpdateInfo}
              />
            </>
          )}
        />
      </div>
      <GeneralPanel className="pt-4">
        <GeneralHeading>General Info</GeneralHeading>
        <TwoColumnGridLayout
          widthDivision={[6, 6]}
          leftCol={
            <>
              <UiInput
                title={"Name"}
                name={"name"}
                className="mb-4"
                register={register}
                disabled={!isEditable}
              />
              <UiInput
                title={"Note"}
                name={"note"}
                className="mb-4"
                register={register}
                disabled={!isEditable}
              />
              <UiInput
                title={"Supplier"}
                name={"supplier"}
                className="mb-4"
                register={register}
                disabled={!isEditable}
              />
              <RadioComboBox
                control={control}
                options={PRODUCT_OVERALL_STATUS}
                title={"Product Status"}
                name="product_status"
                disabled={!isEditable}
              />
            </>
          }
          rightCol={
            <>
              <UiSelectDropdown
                title={"Channel"}
                name={"channel"}
                className="mb-4"
                isDisplayLabel
                value={selectedChannel}
                setValue={(_: string, value: string) =>
                  setSelectedChannel(value)
                }
                optionList={CHANNEL}
                disabled={!isEditable}
              />
              <DatePicker
                className="dataTable-input"
                name={"estimatedArrival"}
                title={"Estimated Arrival"}
                isDisplayLabel={true}
                control={control}
                disabled={!isEditable}
                dirtyFields={dirtyFields}
              />
            </>
          }
        />
      </GeneralPanel>
      <GeneralPanel className="pt-4">
        <GeneralHeading>Labels</GeneralHeading>
        <PanelLabelController
          linkedObjectType="PurchaseOrder"
          uniqueUuid={uuid as string}
        />
      </GeneralPanel>

      <GeneralPanel className="pt-4">
        <GeneralHeading>Warehouse</GeneralHeading>
        <TwoColumnGridLayout
          widthDivision={[7, 5]}
          leftCol={
            <TablePanel
              apiPath={getWarehouseListApi}
              panelClassName={"mt-0"}
              type={"minimal"}
              tableSchema={warehouseSchema({
                selectedWarehouse: selectedWarehouse,
                onSelectWarehouse: setSelectedWarehouse,
              })}
            />
          }
          rightCol={
            <div className={styles.selectWarehouse}>
              <SelectedWarehouse warehouse={selectedWarehouse} />
            </div>
          }
        />
      </GeneralPanel>
    </>
  );
};

export default Component;
