import { GENERAL_PERMISSIONS_MODEL } from "../../constants/roleModel";
import { IPermissionProps, IUpperRole } from "../../types/roles";

export const flattenedObject = (arrayToFlatten: any[]) => {
  arrayToFlatten.reduce((result, item) => {
    const key = Object.keys(item)[0];
    const value = item[key];
    result[key] = value;
    return result;
  }, {});
};

export const extractRolesFields = (data: IPermissionProps[]) => {
  const uniqueAccessModels = new Set();
  data?.forEach((item) => {
    uniqueAccessModels.add(item.access_model);
  });
  return Array.from(uniqueAccessModels);
};

interface IRoleField {
  [key: string]: {
    model: string;
    view: any[];
    add: any[];
    remove: any[];
    edit: any[];
    other: any[];
    full: any[];
  };
}

export const handlePreSortingData = (data: IPermissionProps[]) => {
  const generateDefaultGroupPermission = () => {
    if (!data) return;
    const roleFields = extractRolesFields(data);

    const object: IRoleField = {};
    roleFields?.forEach((field: any) => {
      object[field] = {
        model: field,
        view: [],
        add: [],
        remove: [],
        edit: [],
        other: [],
        full: [],
      };
    });

    return object;
  };

  const groupedPermissions = generateDefaultGroupPermission();

  data.forEach((permission: IPermissionProps) => {
    const accessAction = permission.access_action;
    const accessModel = permission.access_model;

    switch (accessAction) {
      case GENERAL_PERMISSIONS_MODEL.SHOW:
        return !!accessModel
          ? groupedPermissions?.[accessModel].view.push(permission)
          : null;
      // case GENERAL_PERMISSIONS_MODEL.INVITE:
      // case GENERAL_PERMISSIONS_MODEL.RESET_PASSWORD:
      // case GENERAL_PERMISSIONS_MODEL.SHARE_ACCESS:
      // case GENERAL_PERMISSIONS_MODEL.UPDATE_PERMISSIONS:
      //   return !!accessModel
      //     ? groupedPermissions?.[accessModel].other.push(permission)
      //     : null;
      case GENERAL_PERMISSIONS_MODEL.DESTROY:
        return !!accessModel
          ? groupedPermissions?.[accessModel].remove.push(permission)
          : null;
      case GENERAL_PERMISSIONS_MODEL.EDIT:
        return !!accessModel
          ? groupedPermissions?.[accessModel].edit.push(permission)
          : null;
      case GENERAL_PERMISSIONS_MODEL.MANAGE:
        return !!accessModel
          ? groupedPermissions?.[accessModel].full.push(permission)
          : null;
      case GENERAL_PERMISSIONS_MODEL.NEW:
        return !!accessModel
          ? groupedPermissions?.[accessModel].add.push(permission)
          : null;
      default:
        return !!accessModel
          ? groupedPermissions?.[accessModel].other.push(permission)
          : null;
    }
  });

  const modifiedPermission =
    groupedPermissions &&
    Object.keys(groupedPermissions).map((groupedPermission) =>
      Object.keys(groupedPermissions[groupedPermission]).map(
        (permissionKey) => ({
          [permissionKey]: (groupedPermissions as any)[groupedPermission][
            permissionKey
          ],
        })
      )
    );

  return modifiedPermission;
};

export const flattenNestedObjToArray = (
  obj: IUpperRole,
  attribute: keyof IUpperRole
) => {
  if (!obj) return;
  const result: any[] = [];

  result.push(obj);
  function flatten(obj: IUpperRole) {
    if (obj && obj[attribute]) {
      result.push(obj[attribute]);
      flatten(obj[attribute] as IUpperRole);
    }
  }

  flatten(obj);
  return result;
};

export const extractNumberFromString = (inputString: string) => {
  const regex = /\[(\d+)\]/; // Matches any number within square brackets
  const match = inputString.match(regex);
  if (match && match[1]) {
    return parseInt(match[1], 10); // Convert the matched number to an integer
  } else {
    return null;
  }
};

export const removeEmptyObjects = (array: any[]) => {
  return array?.filter(
    (obj) => Object.keys(obj).length !== 0 && obj.value !== undefined
  );
};

export const updateArray = (arr1: any[], arr2: any[]) => {
  for (let i = 0; i < arr2.length; i++) {
    const number = arr2[i];
    const index = arr1.indexOf(number);

    if (index !== -1) {
      arr1.splice(index, 1);
    } else {
      arr1.push(number);
    }
  }

  return arr1;
};

export const removeDuplicateArrayItems = (arr1: any[], arr2: any[]) => {
  const mappedArray = new Map(arr1?.map((item) => [item.uuid, item]));
  const uniqueItemsArray: any[] = [];
  arr2?.forEach((item) => {
    if (!mappedArray?.has(item.uuid)) {
      uniqueItemsArray.push(item);
    }
  });
  return uniqueItemsArray;
};
