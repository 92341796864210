export const formatAddress = (addressValue: any) => {
  if ("string" === typeof addressValue) return addressValue;

  const { ward, province, district } = addressValue || {};
  if (!ward || !province) return "";

  const formatedWard = `${ward?.unit} ${ward?.name}`;
  const formatedProvince = `${province?.unit} ${province?.name}`;
  const formatedDistrict = `${district?.unit} ${district?.name}`;

  return `${formatedWard}, ${formatedDistrict}, ${formatedProvince}, Vietnam`;
};

export const extractAddressFields = (address: string) => {
  const strings = address?.split(",");
  return {
    detail: strings?.[0].trim(),
    street: strings?.[1].trim(),
    ward: strings?.slice(2),
  };
};

export const extractDynamicAddressFields = (
  address: string,
  orders: [number[], number[], number[]] = [
    [0, 1],
    [1, 3],
    [3, 6],
  ]
) => {
  const strings = address?.split(",");
  return {
    detail: strings?.slice(orders[0][0], orders[0][1]) || null,
    street: strings?.slice(orders[1][0], orders[1][1]) || null,
    ward: strings?.slice(orders[2][0], orders[2][1]) || null,
  };
};
