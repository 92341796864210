import * as yup from "yup";

export const relocationShippmentSchema = yup
  .object()
  .shape({
    from_warehouse_uuid: yup.string().required("This field is required"),
    to_warehouse_uuid: yup.string().required("This field is required"),
    product_status: yup
      .string()
      .oneOf(["saleable", "usable", "damaged"])
      .required(),
    shiping_service: yup.string().nullable(),
    note: yup.string().nullable(),
    relocation_shipment_products: yup.array().of(
      yup.object().shape({
        product_uuid: yup.string().nullable(),
        product_gtin_code: yup.string().nullable(),
        quantity: yup.number().required("Quantity is required"),
      })
    ),
  })
  .required();
