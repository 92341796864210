interface IOperators {
  operator: string;
  operatorAlias: string;
  valueType: string;
}

type TOperator = Record<string, IOperators>;

export interface IDynamicFilter {
  key: string;
  title: string;
  operator: string;
  operatorAlias?: string;
  valueType?: string;
}

const OPERATOR: TOperator = {
  eqAny: {
    operator: "eq_any",
    operatorAlias: "eq_any",
    valueType: "array",
  },
  contAny: {
    operator: "cont_any",
    operatorAlias: "cont_any",
    valueType: "array",
  },
  in: {
    operator: "in",
    operatorAlias: "in",
    valueType: "array",
  },
};

export const saleOrderCreatingDynamicFilter: IDynamicFilter[] = [
  {
    key: "reference_code",
    title: "Reference Code",
    ...OPERATOR.contAny,
  },
  {
    key: "number",
    title: "Sale Order Number",
    ...OPERATOR.contAny,
  },
  {
    key: "receiver_name",
    title: "Receiver Name",
    ...OPERATOR.contAny,
  },
  {
    key: "receiver_phone",
    title: "Receiver Phone",
    ...OPERATOR.contAny,
  },
  {
    key: "channels",
    title: "Channel",
    ...OPERATOR.in,
  },
  {
    key: "shipping_services",
    title: "Shipping Service",
    ...OPERATOR.in,
  },
  {
    key: "shipping_service_names",
    title: "Shipping Service Names",
    ...OPERATOR.in,
  },
];
