import React from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

export interface ISidebarContent {
  id: number;
  content: string;
  icon: any;
}

const sidebarContent: ISidebarContent[] = [
  {
    id: 0,
    content: "Detail",
    icon: <i className="fa fa-info text-dark opacity-4 me-2"></i>,
  },
  {
    id: 1,
    content: "Notes",
    icon: <i className="far fa-comments text-dark opacity-4 me-2"></i>,
  },
  {
    id: 2,
    content: "Stock by WH",
    icon: (
      <i className="fa-solid fa-location-crosshairs text-dark opacity-4 me-2"></i>
    ),
  },
];

const ProductDetailSidebar = ({
  activeTab,
  setActiveTab,
  noteQuantity,
}: {
  activeTab: number;
  setActiveTab: (params: any) => void;
  noteQuantity: number;
}) => {
  return (
    <div className="card card-profile-bottom">
      <div className="card position-sticky top-1">
        <ul className="nav flex-column bg-white border-radius-lg p-3">
          {sidebarContent.map((item: ISidebarContent, index: number) => {
            return (
              <li className="nav-item mb-1 cursor-pointer " key={index}>
                <div
                  className={classNames(
                    "nav-link text-body d-flex align-items-center",
                    activeTab === item.id && styles.active
                  )}
                  onClick={() => {
                    setActiveTab(item.id);
                  }}
                >
                  <div className="p-1 position-relative">{item.icon}</div>
                  <span className="text-sm position-relative">
                    {item.content}
                    {!!noteQuantity && item.id === 1 && (
                      <span
                        className={classNames(styles.customBadge, "bg-primary")}
                      >
                        {noteQuantity > 99 ? "99+" : noteQuantity}
                      </span>
                    )}
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ProductDetailSidebar;
