import { useEffect } from "react";

import UiInput from "../../components/ui-input";
import PanelGeneral from "../../components/panel-general/component";

import { formSchema } from "./formSchema";
import { IRoleProps } from "../../types/roles";
import { detectRequiredlField } from "../../utils/validation";
import GroupCheckbox from "./view-group-radio";

const EditRoleForm = ({
  setValue,
  register,
  control,
  errors,
  roles,
  rolesList,
}: {
  setValue: any;
  register: any;
  control: any;
  errors: any;
  roles: any;
  rolesList: IRoleProps[];
}) => {
  const { name, description, is_system, uuid } = roles || {};

  useEffect(() => {
    Object.keys(formSchema?.fields).forEach((field: any) => {
      if (field !== "permission_ids") {
        setValue(field, roles[field]);
      }
    });
  }, [setValue, roles]);

  //TEMP PAUSING "upper_role_uuid"
  // const handlePreProcessOptionItems = (array: any[]) => {
  //   if (!array || !array.length) return [];
  //   return array.map((upperRole: IUpperRole) => ({
  //     value: upperRole.uuid,
  //     content: upperRole.name,
  //   }));
  // };

  // const allOptionItem = handlePreProcessOptionItems(rolesList);
  // const removeCurrentRoleOptionItem = allOptionItem?.filter(
  //   (option) => option.value !== uuid
  // );

  return (
    <PanelGeneral
      panel={{
        title: "Role",
      }}
    >
      <UiInput
        className="dataTable-input"
        name={"name"}
        title={"Name"}
        required={detectRequiredlField(formSchema, "name")}
        placeholder={name}
        isDisplayLabel={true}
        register={register}
        errorMessage={errors?.name?.message}
        disabled={is_system}
      />
      <UiInput
        className="dataTable-input"
        name={"description"}
        required={detectRequiredlField(formSchema, "description")}
        title={"Description"}
        isDisplayLabel={true}
        register={register}
        placeholder={description}
        errorMessage={errors?.description?.message}
        disabled={is_system}
      />
      <GroupCheckbox
        control={control}
        title={"Status"}
        name="status"
        required={detectRequiredlField(formSchema, "status")}
        errorMessage={errors?.status?.message}
      />

      {/* {allOptionItem.length > 0 && (
        <SelectDropdown
          disabled={is_system}
          name="upper_role_uuid"
          title="Upper role UUID"
          isDisplayLabel
          required={detectRequiredlField(formSchema, "status")}
          register={register}
          setValue={setValue}
          optionList={removeCurrentRoleOptionItem as IOptionItem[]}
        />
      )} */}
    </PanelGeneral>
  );
};

export default EditRoleForm;
