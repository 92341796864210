export const LABELS_TYPE = [
  { value: "packing", content: "Packing" },
  { value: "picking", content: "Picking" },
  { value: "cancelling", content: "Cancelling" },
  { value: "damaged", content: "Damaged" },
  { value: "expired", content: "Expired" },
  { value: "lost", content: "Lost" },
];

export const LABELS_COLORS = [
  { value: "primary", content: "Primary" },
  { value: "secondary", content: "Secondary" },
  { value: "info", content: "Info" },
  { value: "success", content: "Success" },
  { value: "danger", content: "Danger" },
  { value: "warning", content: "Warning" },
  { value: "light", content: "Light" },
  { value: "dark", content: "Dark" },
];
