import UiTypography from "../../components/ui-typography";
import styles from "./styles.module.scss";

const Component = ({
  sub,
  title,
}: {
  sub: string;
  title: string;
  subWidth?: number;
}) => (
  <UiTypography fontSize="fs-6" className={styles.rowTextContent}>
    <span className={styles.key}>{sub}</span>
    <span className={styles.value}>{title}</span>
  </UiTypography>
);

export default Component;
