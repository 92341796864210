import { useEffect, useState } from "react";


import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { getAuditStateActivitiesApi } from "../../apis/paths/activities";
import { useApiCaller } from "../../apis/config";

import LoadingPanel from "../ui-loading-panel/component";
import UiButton from "../ui-button/component";

import styles from "./styles.module.scss";
import ViewActivityCard from "./view-activity-card";

interface IPanelStateAuditActivities {
  objectType: string;
  objectCode: string;
}

export interface IFilterOption {
  page: number;
  per?: number;
  linked_object_type: string;
  linked_object_code: string;
}

interface IPaging {
  total_pages: number;
  per_page: number;
  current_page: number;
  total_count: number;
}

interface IActivitiesParams {
  list: any[];
  paging: IPaging;
}
const PanelStateAuditActivities = ({
  objectType,
  objectCode,
}: IPanelStateAuditActivities) => {
  const [activities, setActivities] = useState<IActivitiesParams | null>(null);
  const [params, setParams] = useState({
    linked_object_type: objectType,
    linked_object_code: objectCode,
    page: DEFAULT_PAGINATION_PARAMS.page,
    per: DEFAULT_PAGINATION_PARAMS.per,
  });

  const { result, loading } = useApiCaller({
    api: getAuditStateActivitiesApi,
    params,
  });

  useEffect(() => {
    if (Array.isArray(result?.data?.list)) {
      setActivities((prev: any) => {
        if (!Array.isArray(prev?.list)) {
          return result?.data;
        }
        if (result?.data?.paging?.current_page !== prev?.paging?.current_page) {
          const newAppendedSaleOrders = [...prev?.list, ...result?.data?.list];
          return { ...result?.data, list: newAppendedSaleOrders };
        }

        return result?.data;
      });
    }
  }, [result]);

  if (loading) return <LoadingPanel />;

  const currentPage = activities?.paging?.current_page;
  const totalPage = activities?.paging?.total_pages;
  const shouldDisplayShowmoreButton =
    !!currentPage && !!totalPage && currentPage < totalPage;

  const handleShowmore = () => {
    setParams((prev) => ({ ...prev, page: prev?.page + 1 }));
  };

  return (
    <div className="w-full d-flex justify-content-center flex-column align-items-center">
      <div className="w-60">
        {activities?.list?.map((activity: any) => (
          <ViewActivityCard activity={activity} />
        ))}
      </div>
      {shouldDisplayShowmoreButton && (
        <UiButton
          title={
            <div
              onClick={handleShowmore}
              className="d-flex align-items-center gap-2 "
            >
              Show More
              <i className={`fas fa-angle-down text-sm ${styles.icon}`}></i>
            </div>
          }
        />
      )}
    </div>
  );
};

export default PanelStateAuditActivities;
