import {
  ADVANCED_SETTINGS,
  COMPANY_INFO,
  SHIPPING_SERVICES,
  SLA,
} from "../../routings/path";

export interface ISidebarContent {
  href: string;
  content: string;
  icon: any;
}

export const SETUP_CONFIG: ISidebarContent[] = [
  {
    href: COMPANY_INFO,
    content: "Company Info",
    icon: <i className="fa-solid fa-info opacity-4 me-2  "></i>,
  },
  {
    href: SLA,
    content: "SLA",
    icon: <i className="fa-solid fa-handshake opacity-4 me-2 "></i>,
  },
  {
    href: SHIPPING_SERVICES,
    content: "Logistics",
    icon: <i className="fas fa-truck-fast text-dark opacity-4 me-2 "></i>,
  },
  {
    href: ADVANCED_SETTINGS,
    content: "Advanced",
    icon: <i className="fa-solid fa-wrench opacity-4 me-2 "></i>,
  },
];
