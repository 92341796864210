import * as yup from "yup";

export const formSchema = yup
  .object({
    description: yup.string().required(),
    name: yup.string().required(),
    strategy: yup
      .string()
      .oneOf(["default", "fifo", "lifo", "fefo", "lefo"])
      .required(),
  })
  .required();
