import { useNavigate } from "react-router-dom";

import { getStockCheckListApi } from "../../apis/paths/stock-check";
import { ACTIONABLE_TYPE } from "../../constants/actionable";
import TablePanel from "../../components/panel-table";
import * as R from "../../routings/path";

import { schema } from "./schema";

const Component = () => {
  const navigate = useNavigate();

  return (
    <TablePanel
      apiPath={getStockCheckListApi}
      tableSchema={schema}
      panel={{
        title: "Stock Checks",
        description: "List of stock checks in the system",
        actionableButtons: [
          {
            actionableType: ACTIONABLE_TYPE.NEW,
            title: "New Stock Checks",
            onClick: () => navigate(R.STOCK_CHECK_CREATE),
          },
        ],
      }}
    />
  );
};

export default Component;
