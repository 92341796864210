import { useApiCaller } from "../../apis/config";
import { useWindowResize } from "../../hooks/useWindowResize";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { getInventoryFlow } from "../../apis/paths/dashboard";
import Typography from "../../components/ui-typography/component";
import PanelGeneral from "../../components/panel-general/component";
import DoughnutChart from "../../components/ui-doughnut-chart/component";

import { LARGE_SCREEN_SIZE, MEDIUM_SCREEN_SIZE } from "./component";

const Component = () => {
  const { result: inventoryFlow } = useApiCaller({
    api: getInventoryFlow,
    params: DEFAULT_PAGINATION_PARAMS,
  });

  const inventoryStatus = inventoryFlow?.data;

  const outboundDoughnutInput = {
    labels: ["Todo", "Processing", "Handover"],
    datasets: [
      {
        label: "Quantity",
        data: [
          inventoryStatus?.outbound?.todo,
          inventoryStatus?.outbound?.in_process,
          inventoryStatus?.outbound?.ready_to_ship,
        ],
        cutout: 85,
        backgroundColor: [
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        hoverBackgroundColor: [
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const { windowWidth } = useWindowResize();
  const isLargeScreen = windowWidth && windowWidth >= LARGE_SCREEN_SIZE;
  const isMediumScreen =
    (windowWidth && windowWidth <= MEDIUM_SCREEN_SIZE) || true;

  return (
    <>
      <PanelGeneral>
        <div className="d-flex justify-content-between my-2 align-items-center">
          <Typography
            className="text-center my-3"
            customFontsize={14}
            fontWeight="fw-bold"
          >
            Outbound Orders
          </Typography>
        </div>
        <DoughnutChart
          data={outboundDoughnutInput}
          width="100%"
          height={280}
          legend={{ position: "bottom" }}
        />
      </PanelGeneral>
    </>
  );
};

export default Component;
