import { useCallback } from "react";
import { shallowEqual } from "react-redux";

import { useAppDispatch, useAppSelector } from "./useStateHook";

import * as actions from "../states/mySession.flow";

export const useMySession = () => {
  const dispatch = useAppDispatch();

  const session = useAppSelector((state) => state.mySession, shallowEqual);

  const fetchMySession = useCallback(
    () => dispatch(actions.fetchMySession()),
    [dispatch]
  );

  return {
    fetchMySession,
    session,
  };
};
