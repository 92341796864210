import classNames from "classnames";
import styles from "./style.module.scss";

interface IUiTooltipProps {
  children: any;
  tooltip: any;
  className?: {
    mainContent?: string;
    floatingContent?: string;
    subFloatingContent?: string;
  };
  position?:
    | "top"
    | "bottom"
    | "left"
    | "right"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight";
}

const UiTooltip = ({
  children,
  tooltip,
  className = {
    mainContent: "",
    floatingContent: "",
    subFloatingContent: "",
  },
  position = "bottom",
}: IUiTooltipProps) => {
  return (
    <div className={classNames(styles.mainContent, className.mainContent)}>
      {children}
      <div
        className={classNames(
          styles.floatingContent,
          className.floatingContent,
          styles[position]
        )}
      >
        <div
          className={classNames(
            styles.subFloatingContent,
            className.subFloatingContent,
            styles[position]
          )}
        >
          {tooltip}
        </div>
      </div>
    </div>
  );
};

export default UiTooltip;
