import React from "react";
import classNames from "classnames";

interface ISelectDropdownProps {
  title?: string;
  disabled?: boolean;
  isDisplayLabel?: boolean;
  name?: string;
  id?: string;
  readOnly?: boolean;
  className?: string;
  errorMessageClassName?: string;
  register?: any;
  errorMessage?: string;
  onChange?: (param: any) => any;
  checked?: boolean;
  optionList: IOptionItem[];
  value?: any;
  setValue?: any;
  required?: boolean;
  style?: React.CSSProperties;
  size?: "normal" | "small";
  containerClassName?: string;
}

export interface IOptionItem {
  value: string | number;
  content: string | React.ReactNode;
}

const SelectDropdown = ({
  optionList,
  title,
  value,
  isDisplayLabel = true,
  name = "",
  className = "",
  errorMessageClassName = "mb-0",
  register = null,
  errorMessage = "",
  onChange = () => {},
  disabled = false,
  setValue,
  required = false,
  style,
  size = "normal",
  containerClassName = "mb-3",
}: ISelectDropdownProps) => {
  let registerData = {};
  if (register) registerData = register(name);

  const handleChange = (event: any) => {
    onChange(event.target.value);
    setValue && setValue(name, event.target.value);
  };

  return (
    <div className={containerClassName}>
      {isDisplayLabel && (
        <label data-required={required}>{title || name}</label>
      )}
      <select
        {...registerData}
        className={classNames(
          "form-select",
          size === "small" && "form-select-sm",
          className
        )}
        value={value}
        onChange={handleChange}
        style={style}
      >
        {optionList?.map((option: IOptionItem) => (
          <option disabled={disabled} value={option.value} key={option.value}>
            {option.content}
          </option>
        ))}
      </select>
      {errorMessage && errorMessage.length && (
        <label className={errorMessageClassName} style={{ color: "#f5365c" }}>
          {errorMessage}
        </label>
      )}
    </div>
  );
};

export default SelectDropdown;
