import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { TFIlTER_TYPE } from "../../types/filter";

import Typography from "../ui-typography/component";

import { handleCountTotalSelectedItems } from "./utils";
import styles from "./styles.module.scss";
import AssigneeFilter from "./view-assignee-filter";
import NormalFilter from "./view-normal-filter";

interface IFilter {
  optionList: IFilterField[];
  onFilter: any;
  showOverlay?: boolean;
  pageCode?: string;
}

export interface IFilterOptions {
  content: string;
  value: string;
  quantity?: number;
  userDetail?: {
    imageUrl?: string | null;
  };
}

export interface IFilterField {
  field: string;
  template?: TFIlTER_TYPE;
  displayTitle?: string;
  isSingleFilter?: boolean;
  operator: string;
  type?: string | "avatar" | undefined;
  options?: IFilterOptions[];
  assigneeApiPath?: any;
}

export interface SelectedOptions {
  [key: string]: { value?: string; values?: string[]; operator: string };
}

const Filter = ({ optionList, onFilter, showOverlay, pageCode }: IFilter) => {
  const defaultShowOverlay =
    localStorage.getItem(`filter-${pageCode || "default"}`) === "Show";
  const [isOpenOverlay, setIsOpenOverlay] = useState(
    defaultShowOverlay || showOverlay
  );
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({});
  const actualTotalSelectedFieldItemsQty = useRef(0);

  const toggleOption = ({
    field,
    value,
    operatorMatcher,
    isSingleFilter,
  }: {
    field: string | null;
    value: string;
    operatorMatcher: string;
    isSingleFilter?: boolean;
  }) => {
    setSelectedOptions((prev) => {
      if (!field) return {};

      const fieldOptions = prev[field] || { values: [], operator: "in" };
      const { values } = fieldOptions;

      if (!!isSingleFilter) {
        const clonedSelectedOptions = { ...prev };
        delete clonedSelectedOptions[field];

        return fieldOptions?.value === value
          ? clonedSelectedOptions
          : {
              ...prev,
              [field]: {
                operator: operatorMatcher,
                value: value,
              },
            };
      } else {
        if (values?.includes(value)) {
          return {
            ...prev,
            [field]: {
              operator: operatorMatcher,
              values: values?.filter((option) => option !== value),
            },
          };
        } else {
          return {
            ...prev,
            [field]: {
              operator: operatorMatcher,
              values: [...(values || []), value],
            },
          };
        }
      }
    });

    actualTotalSelectedFieldItemsQty.current = activeSelectedFilterQty;
  };

  const preProcessValue = Object.entries(selectedOptions)?.map(
    ([field, options]) => ({
      field,
      values: options?.values,
      value: options?.value,
      operator: options?.operator,
    })
  );

  useEffect(() => {
    onFilter(preProcessValue);
  }, [selectedOptions]);

  const activeSelectedFilterQty =
    handleCountTotalSelectedItems(selectedOptions);

  const inlineFilter =
    optionList?.length <= 1 ||
    optionList?.every((option) => !option?.displayTitle);

  return (
    <>
      <div
        className={classNames(
          "text-end ms-auto p-3 px-3 pt-0",
          styles.moreButton
        )}
      >
        <div
          className="d-flex align-items-center"
          onClick={() => {
            const newValue = !isOpenOverlay;
            if (newValue) {
              localStorage.setItem(`filter-${pageCode || "default"}`, "Show");
            } else {
              localStorage.setItem(`filter-${pageCode || "default"}`, "Hide");
            }
            setIsOpenOverlay(newValue);
          }}
        >
          <i className="fa-solid fa-filter mx-2"></i>
          <Typography fontWeight="fw-normal" customFontsize={12}>
            {activeSelectedFilterQty === 0
              ? "Filter"
              : activeSelectedFilterQty === 1
              ? "1 filter applied"
              : `${activeSelectedFilterQty} filters applied`}
          </Typography>
        </div>
      </div>

      <div className={styles.breakLine}></div>

      {isOpenOverlay && (
        <div className={classNames(styles.panel)}>
          <div
            className={classNames(
              styles.list,
              inlineFilter ? styles.wrapFilters : null
            )}
          >
            {optionList?.map((filterField) => {
              const template = filterField?.template || "normal";

              if (filterField?.options?.length === 0 && template !== "account")
                return null;

              return (
                <div className="d-flex flex-row">
                  {optionList.length > 1 && filterField?.displayTitle && (
                    <div className={styles.title}>
                      {filterField?.displayTitle}:
                    </div>
                  )}
                  <div
                    className={classNames(
                      "d-flex flex-wrap",
                      template === "badgeNumber" ? styles.badgeNumber : null,
                      template === "bigNumber" ? styles.bigNumber : null
                    )}
                  >
                    <React.Fragment key={filterField?.field}>
                      {template === "normal" && (
                        <NormalFilter
                          filterField={filterField}
                          onToggleOption={toggleOption}
                          selectedOptions={selectedOptions}
                        />
                      )}
                      {template === "account" && (
                        <AssigneeFilter
                          onToggleOption={toggleOption}
                          filterField={filterField}
                          filterKey={filterField?.field}
                          selectedOptions={selectedOptions}
                        />
                      )}
                      {template === "bigNumber" && (
                        <NormalFilter
                          filterField={filterField}
                          onToggleOption={toggleOption}
                          selectedOptions={selectedOptions}
                        />
                      )}
                      {template === "badgeNumber" && (
                        <NormalFilter
                          filterField={filterField}
                          onToggleOption={toggleOption}
                          selectedOptions={selectedOptions}
                        />
                      )}
                    </React.Fragment>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className={styles.close}
            onClick={() => {
              setIsOpenOverlay(false);
              localStorage.setItem(`filter-${pageCode || "default"}`, "Hide");
            }}
          ></div>
        </div>
      )}
    </>
  );
};

export default Filter;
