import React from "react";
import { useController } from "react-hook-form";
import { GENERAL_STATUS } from "../../constants/status";

interface GroupRadioProps {
  control: any;
  title?: string;
  name: string;
  errorMessage?: string;
  required?: boolean;
  disabled?: boolean;
}

const GroupRadio = ({
  control,
  title,
  name,
  required = false,
  errorMessage,
  disabled = false,
}: GroupRadioProps) => {
  const {
    field: { value, onChange, onBlur },
  } = useController({
    name: name,
    control,
  });

  return (
    <div className="mt-3 mb-3">
      {title && <label data-required={required}>{title}</label>}
      <div className="d-flex gap-4">
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            value={GENERAL_STATUS.ACTIVATE}
            checked={value === GENERAL_STATUS.ACTIVATE}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
          />
          Activate
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            value={GENERAL_STATUS.DEACTIVE}
            checked={value === GENERAL_STATUS.DEACTIVE}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
          />
          Deactivate
        </div>
      </div>
      {errorMessage && errorMessage.length && (
        <label className="mb-0" style={{ color: "#f5365c" }}>
          {errorMessage}
        </label>
      )}
    </div>
  );
};

export default GroupRadio;
