import * as yup from "yup";
import { validationMessage } from "../../utils/validation";
import { FieldTitle } from "./constants";

type FieldSchema = keyof typeof FieldTitle;

const MAX_IMAGE_SIZE = 3145728;

export const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(FieldTitle[path as FieldSchema])
      ),
    short_name: yup.string().nullable(),
    code: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(FieldTitle[path as FieldSchema])
      ),
    uuid: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(FieldTitle[path as FieldSchema])
      ),
    description: yup.string().nullable(),
    product_types: yup.mixed().nullable(),
    reference_code: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(FieldTitle[path as FieldSchema])
      ),
    capacity_unit: yup
      .string()
      .oneOf(["ml", "gr", "oz", "g", "tablet", "pcs", "bag"]),
    strategy: yup
      .string()
      .oneOf(["default", "fifo", "lifo", "fefo", "lefo"])
      .required(),
    capacity_value: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable(),
    display_height: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable(),
    display_length: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .integer(
        "Please enter the correct integer type and avoid using decimals."
      )
      .nullable(),
    display_width: yup
      .number()
      .integer(
        "Please enter the correct integer type and avoid using decimals."
      )
      .nullable(),
    packaging_weight: yup.number().nullable(),
    packaging_width: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .integer(
        "Please enter the correct integer type and avoid using decimals."
      )
      .nullable(),
    packaging_height: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .integer(
        "Please enter the correct integer type and avoid using decimals."
      )
      .nullable(),
    packaging_length: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .integer(
        "Please enter the correct integer type and avoid using decimals."
      )
      .nullable(),
    upload_image: yup
      .mixed()
      .test(
        "fileSize",
        "File size is too large, should be smaller than 3Mbs",
        (value: any) => {
          if (!value) return true;
          return value?.[0]?.file?.size <= MAX_IMAGE_SIZE;
        }
      )
      .test("fileType", "Unsupported file type", (value: any) => {
        if (!value) return true;
        return ["image/jpeg", "image/png", "image/webp"].includes(
          value?.[0]?.file?.type
        );
      }),
  })
  .required();
