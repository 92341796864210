import { TABLE_ACTION } from "../../constants/table-action";
import { GENERAL_UI_COLOR } from "../../constants/color";
import ProductTableCell from "../../components/table-cell-product";
import DateTimeTableCell from "../../components/table-cell-date-time";
import ActionTableCell from "../../components/table-cell-action";
import StatusTableCell from "../../components/table-cell-status";
import NumberTableCell from "../../components/table-cell-number";

export const schema = () => [
  {
    name: "Task",
    cell: (data: any) => (
      <ProductTableCell
        title={data?.detail_name || "-"}
        description={data?.description}
        linkTo={`${data.uuid}`}
      />
    ),
  },
  {
    name: "Type",
    align: "center",
    width: 80,
    cell: (data: any) => (
      <StatusTableCell status={data.name} type={GENERAL_UI_COLOR.SECONDARY} />
    ),
  },
  {
    name: "SLA",
    description: "Minutes",
    align: "center",
    width: 80,
    cell: (data: any) => <NumberTableCell value={data.sla} />,
  },
  {
    name: "Assignee",
    width: 120,
    cell: (data: any) => {
      const { name, avatar } = data?.assignee || {};
      return (
        <ProductTableCell
          description={name}
          image={avatar}
          previewImage={false}
          isForceDisplayAvatar
          userName={name}
          allowDynamicAvatarBackgroundColor
          displayStatusMode={{
            workMode: data?.work_mode,
            outsideUntil: data?.outside_until,
          }}
        />
      );
    },
  },
  {
    name: "Status",
    align: "center",
    width: 80,
    cell: (data: any) => <StatusTableCell status={data.status} />,
  },
  {
    name: "Created",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data.created_at} />,
  },
  {
    name: "Updated",
    align: "center",
    sortTypes: {
      key: "updated_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data.updated_at} />,
  },
  {
    name: "Action",
    align: "right",
    width: 120,
    cell: (data: any) => {
      return (
        <ActionTableCell
          actions={data.instance_actionables}
          viewLink={`${data.uuid}`}
          editLink={`${data.uuid}/edit`}
          types={[TABLE_ACTION.VIEW, TABLE_ACTION.EDIT]}
        />
      );
    },
  },
];
