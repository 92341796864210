interface PropsType {
  title?: string;
  description?: string;
  children?: string;
}

const Component = ({ title, description, children }: PropsType) => (
  <div className="container">
    <div className="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
        <div className="card mt-5">
          <div className="card-header pb-0 text-start">
            <h3 className="font-weight-bolder">{title}</h3>
            <p className="mb-0">{description}</p>
          </div>
          <div className="card-body">{children}</div>
        </div>
      </div>
    </div>
  </div>
);

export default Component;
