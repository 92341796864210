import { addSpacesBeforeUppercase } from "../../../utils/format";
import { GENERAL_UI_COLOR } from "../../../constants/color";
import {
  calculateRemainingTime,
  convertYYYYMMDDToUnixTime,
} from "../../../utils/date-time";
import UiInput from "../../../components/ui-input";
import Badge from "../../../components/ui-badge/component";
import UiTextArea from "../../../components/ui-text-area/component";
import DatePicker from "../../../components/ui-date-picker/component";

interface IViewDetailTaskLoggingInfo {
  register: any;
  control: any;
  errors: any;
  dirtyFields: any;
  searchValue: string;
  startAt?: any;
  dueAt?: any;
  linkedObjectType?: any;
}

const ViewDetailTaskLoggingInfo = ({
  register,
  control,
  errors,
  dirtyFields,
  searchValue,
  startAt,
  dueAt,
  linkedObjectType,
}: IViewDetailTaskLoggingInfo) => {
  return (
    <div>
      <UiTextArea
        title={"Description"}
        name={"description"}
        register={register}
        disabled
        errorMessage={errors?.description?.message}
        rows={4}
      />

      <div className="mt-3">
        <UiInput
          className="dataTable-input"
          name={"username"}
          title={addSpacesBeforeUppercase(linkedObjectType) || "Related"}
          value={searchValue}
          viewMode
          isDisplayLabel={true}
          errorMessage={errors?.username?.message}
        />
      </div>

      <div className="d-flex gap-4 mt-3">
        <div className="d-flex gap-2 flex-grow-1">
          <div className="col-6">
            <DatePicker
              className="dataTable-input"
              name={"start_at"}
              title={"In Progress At"}
              type="datetime-local"
              isDisplayLabel={true}
              required
              disabled
              control={control}
              errorMessage={errors?.start_at?.message as string}
              dirtyFields={dirtyFields}
            />
          </div>
          <div className="col-6">
            <DatePicker
              className="dataTable-input"
              name={"due_at"}
              title={"Due At"}
              type="datetime-local"
              isDisplayLabel={true}
              disabled
              required
              control={control}
              errorMessage={errors?.due_at?.message as string}
              dirtyFields={dirtyFields}
            />
          </div>
        </div>
        <div className="text-start pe-2">
          <label>Expect Remaining: </label>
          <div className="text-danger mt-1">
            <Badge
              content={calculateRemainingTime(
                convertYYYYMMDDToUnixTime(dueAt) as number,
                convertYYYYMMDDToUnixTime(startAt) as number
              )}
              type={GENERAL_UI_COLOR.WARNING}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDetailTaskLoggingInfo;
