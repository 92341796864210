import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useApiCaller } from "../../apis/config";
import { formSchema } from "./formSchema";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { ROLES_LIST } from "../../routings/path";
import { getAccountListApi } from "../../apis/paths/accounts";
import { handlePreSortingData } from "../role-edit/utils";
import {
  getPermissionApi,
  getRoleDetailApi,
  getRoleListApi,
} from "../../apis/paths/roles";
import UiButton from "../../components/ui-button";
import TablePanel from "../../components/panel-table";
import HeaderInfoPanel from "../../components/panel-header-info";
import NotFoundPanel from "../../components/panel-not-found";
import GridLayoutDynamicCollumn from "../../components/grid-layout-dynamic-collum/component";

import { schema } from "./schema";
import EditRoleForm from "./view-edit-role-form";
import ViewInvitedMember from "./view-invited-member";
import ViewInvitedWarehouse from "./view-invited-warehouse";

const Component = () => {
  const [grantedPermissionsData, setGrantedPermissionsData] = useState([]);

  const { roleId } = useParams();
  const uuid = roleId || "";
  const navigate = useNavigate();

  const [params] = useState<{
    page: number;
    per: number;
    uuid: string;
  }>({
    page: 1,
    per: 50,
    uuid,
  });

  const { result: roleDetail } = useApiCaller({
    api: getRoleDetailApi,
    params,
  });

  useEffect(() => {
    if (roleDetail) setGrantedPermissionsData(roleDetail?.data?.permissions);
  }, [roleDetail]);

  const { result: rolesList } = useApiCaller({
    api: getRoleListApi,
    params: DEFAULT_PAGINATION_PARAMS,
  });

  const roles = useMemo(
    () => roleDetail?.data?.role || {},
    [roleDetail?.data?.role]
  );

  const { result: accountList } = useApiCaller({
    api: getAccountListApi,
    params: DEFAULT_PAGINATION_PARAMS,
  });

  const {
    setValue,
    register,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const handleSetValue = (key: any, isChecked: boolean) => {
    setValue(key, { [key]: isChecked });
  };

  if (
    roleDetail?.status === "ERROR" ||
    rolesList?.status === "ERROR" ||
    accountList?.status === "ERROR"
  )
    return <NotFoundPanel />;

  return (
    <>
      <div>
        <div className="pb-4">
          <HeaderInfoPanel
            isDisplayInfoArea={true}
            title="View Role & Permission "
            description="Management system"
            actionArea={() => (
              <>
                <UiButton
                  type={GENERAL_UI_COLOR.PRIMARY}
                  onClick={() => {
                    navigate(`${ROLES_LIST}/${uuid}/edit`);
                  }}
                  title={"Edit Role"}
                />
              </>
            )}
          />
        </div>
        <GridLayoutDynamicCollumn widthDivision={[4, 4, 4]} className="px-0">
          <EditRoleForm
            rolesList={rolesList?.data?.list}
            roles={roles}
            setValue={setValue}
            control={control}
            errors={errors}
            register={register}
          />
          <ViewInvitedWarehouse warehouses={roleDetail?.data?.warehouses} />
          <ViewInvitedMember accounts={roleDetail?.data?.accounts} />
        </GridLayoutDynamicCollumn>

        <div className="pt-2">
          <TablePanel
            apiPath={getPermissionApi}
            panel={{
              title: "Permissions",
              description: roles?.is_system ? "The system role can't be modified" : "Actionable items of this role",
            }}
            isDisplayedDataTopTable={false}
            showPerPageSelection={false}
            dataTransformation={(result: any) =>
              result && {
                data: handlePreSortingData(result?.data?.list),
              }
            }
            tableSchema={schema({
              control,
              onSetValue: handleSetValue,
              isSystem: roles?.is_system,
              grantedPermissions: grantedPermissionsData || [],
            })}
          />
        </div>
      </div>
    </>
  );
};

export default Component;
