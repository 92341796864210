import { useState } from "react";

import { GENERAL_UI_SIZE } from "../../constants/size";
import {
  updatePurchaseOrderPackageStatusApi,
} from "../../apis/paths/purchase-order";
import UiStatusDropdown from "../../components/ui-status-dropdown";
import TableCellStatus from "../../components/table-cell-status";

import styles from "./styles.module.scss";

const Component = ({ packageItemSource, purchaseOrderUuid: uuid }: any) => {
  const [packageItem, setPackageItem] = useState<any>(packageItemSource);

  return (
    <div className={styles.heading}>
      <div className={styles.info}>
        <div className={styles.code}>{packageItem?.code}</div>
        <TableCellStatus
          status={packageItem?.status}
          size={GENERAL_UI_SIZE.SMALL}
        />
      </div>

      <UiStatusDropdown
        className={styles.action}
        size="small"
        status={packageItem?.status}
        triggerableEvents={packageItem?.triggerable_events}
        actionApi={updatePurchaseOrderPackageStatusApi}
        paramsTransformation={(newEvent: string) => ({
          uuid: uuid,
          packageUuid: packageItem?.uuid,
          action: newEvent,
        })}
        onChangeSuccess={(newData: any) => {
          setPackageItem({
            ...packageItem,
            status: newData.status,
            triggerable_events: newData.triggerable_events,
          });
        }}
      />
    </div>
  );
};

export default Component;
