import { useState, useEffect } from "react";

import Contents from "./view-content";
import Tabs from "./view-tabs";
import Navigation from "./view-navigation";

interface IComponent {
  tabs: Array<string>;
  contentHeadings: Array<string> | Array<any>;
  subContentTabs?: Array<string> | Array<any>;
  children: any;
  contentCol?: number;
  defaultTab?: number;
  onChangeTab?: any;
  isWithoutContent?: boolean;
  isDisabled?: boolean;
  className?: string;
  onCheckPassStepValidation?: (index: number) => Promise<boolean>;
  onStaticCheckStepValidatation?: (index: number) => boolean;
}

const Component = ({
  tabs,
  contentHeadings,
  subContentTabs,
  children,
  contentCol = 8,
  defaultTab = 0,
  onChangeTab = () => {},
  isWithoutContent = false,
  isDisabled = false,
  className = "",
  onCheckPassStepValidation,
  onStaticCheckStepValidatation,
}: IComponent) => {
  const [selectedTab, setTab] = useState(0);

  const handleChangeTab = (index: number) => {
    setTab(index);
    onChangeTab(index);
  };

  const handleStepVerification = async (index: number) => {
    if (!!isDisabled) return;

    let isPassTriggerCheck = true;
    let isPassStaticCheck = true;
    if (onCheckPassStepValidation) {
      isPassTriggerCheck = await onCheckPassStepValidation(index);
    }
    if (!!onStaticCheckStepValidatation) {
      isPassStaticCheck = onStaticCheckStepValidatation(index);
    }

    if (isPassTriggerCheck && isPassStaticCheck) {
      handleChangeTab(index);
    }
  };

  useEffect(() => {
    setTab(defaultTab);
  }, [defaultTab]);

  return (
    <div className={`styles.container ${className}`}>
      <div className="multisteps-form mb-5">
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          subContentTabs={subContentTabs}
          onSelect={handleStepVerification}
        />

        {!isWithoutContent && (
          <Contents
            tabs={tabs}
            selectedTab={selectedTab}
            contents={children}
            onNavigate={handleStepVerification}
            contentCol={contentCol}
            navigation={
              <Navigation
                tabs={tabs}
                contentHeadings={contentHeadings}
                selectedTab={selectedTab}
                onPrev={() => handleChangeTab(selectedTab - 1)}
                onNext={() => {
                  handleStepVerification(selectedTab + 1);
                }}
              />
            }
          />
        )}
      </div>
    </div>
  );
};

export default Component;
