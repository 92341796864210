import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { generateUrlParams } from "../../utils/url";
import { del, get, patch, post, put } from "../config";

interface IGetRelocationShipmentApi {
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
  filter?: object | null;
}

export interface IUpdateRelocationStatusApi {
  uuid: string;
  event: string;
}

export interface IRelocationShipmentProduct {
  product_uuid?: string | null | undefined;
  product_gtin_code?: string | null | undefined;
  quantity: number;
}

export interface IAddProductRelocationShipmentApi {
  uuid: string;
  relocation_shipment_products: IRelocationShipmentProduct[];
}

export interface IcreateRelocationShipment {
  from_warehouse_uuid?: string;
  to_warehouse_uuid?: string;
  product_status: "saleable" | "usable" | "damaged" | undefined | null;
  shiping_service: string | null | undefined;
  note: string | undefined | null;
  relocation_shipment_products?: IRelocationShipmentProduct[];
}
export interface IUpdateRelocationShipment {
  uuid: string;
  shiping_service: string;
  product_status: "saleable" | "usable" | "damaged";
  note: string;
}

export const getRelocationShipmentApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
  filter = DEFAULT_PAGINATION_PARAMS.filter,
}: IGetRelocationShipmentApi) =>
  get({
    path: `/wms/relocation_shipments${generateUrlParams({
      page,
      per,
      keyword,
      sort,
      filter,
    })}`,
  });

export const getRelocationShipmentDetailApi = ({ uuid }: { uuid: string }) =>
  get({
    path: `/wms/relocation_shipments/${uuid}`,
  });

export const createRelocationShipment = ({
  from_warehouse_uuid,
  to_warehouse_uuid,
  product_status,
  shiping_service,
  note,
  relocation_shipment_products,
}: IcreateRelocationShipment) =>
  post({
    path: `/wms/relocation_shipments`,
    data: {
      from_warehouse_uuid,
      to_warehouse_uuid,
      product_status,
      shiping_service,
      note,
      relocation_shipment_products,
    },
  });

export interface IUpdateRelocationShipmentProducts {
  uuid: string;
  relocation_shipment_products: IRelocationShipmentProduct[];
}

export const updateRelocationShipmentProducts = ({
  uuid,
  relocation_shipment_products,
}: IUpdateRelocationShipmentProducts) =>
  put({
    path: `/wms/relocation_shipments/${uuid}/products`,
    data: {
      relocation_shipment_products,
    },
  });

export const updateRelocationShipment = ({
  uuid,
  shiping_service,
  note,
  product_status,
}: IUpdateRelocationShipment) =>
  patch({
    path: `/wms/relocation_shipments/${uuid}`,
    data: {
      shiping_service,
      note,
      product_status,
    },
  });

export const deleleRelocationShipment = ({ uuid }: { uuid: string }) =>
  del({ path: `/wms/relocation_shipments/${uuid}` });

export const updateRelocationStatusApi = ({
  uuid,
  event,
}: IUpdateRelocationStatusApi) =>
  put({
    path: `/wms/relocation_shipments/${uuid}/events`,
    data: {
      uuid,
      event,
    },
  });

export const addProductIntoRelocationShipmentApi = ({
  uuid,
  relocation_shipment_products,
}: IAddProductRelocationShipmentApi) =>
  post({
    path: `/wms/relocation_shipments/${uuid}/products`,
    data: {
      relocation_shipment_products,
    },
  });

export interface IRemoveProductFromSaleOrderApi {
  uuid: string;
  product_uuid?: string[];
}

export const removeProductFromRelocationShipmentApi = ({
  uuid,
  product_uuid,
}: IRemoveProductFromSaleOrderApi) =>
  del({
    path: `/wms/sale_orders/${uuid}/products`,
    data: {
      product_uuid,
    },
  });
