import React from "react";
import PopupConfirmation from "../popup-confirmation/component";
import { Button } from "react-bootstrap";
import type { unstable_Blocker as Blocker } from "react-router-dom";

interface IDeleteConfirmationPopupProps {
  content: string | React.ReactNode;
  isShowModal: boolean;
  handleCloseModal?: () => void;
  blocker: Blocker;
  title?: string | React.ReactNode;
  header?: string | React.ReactNode;
}

const LeavePagePopupConfirmation = ({
  isShowModal,
  handleCloseModal,
  content,
  blocker,
  title = "Are you sure to leave this page?",
  header = "Your attention is required?",
}: IDeleteConfirmationPopupProps) => {
  
  return (
    <PopupConfirmation
      isShowModal={isShowModal}
      onCloseModal={handleCloseModal}
      header="Your attention is required"
    >
      <div>
        <div className="modal-body">
          <i className="fa-solid fa-circle-exclamation me-2 mt-1 fa-3x text-center w-100"></i>
          <div className="py-3 text-center">
            <i className="ni ni-bell-55 ni-3x"></i>
            <h4 className="text-gradient text-danger mt-4">{title}</h4>
            <p>{content}</p>
          </div>
        </div>
        <hr className="horizontal dark mt-3" />
        <div className="d-flex mt-3 justify-content-end">
          <Button
            className="mb-2 me-3"
            variant=""
            size="sm"
            onClick={() => {
              handleCloseModal && handleCloseModal();
              blocker.proceed?.();
            }}
          >
            <i className="fa-solid fa-xmark me-2"></i> Let me through
          </Button>
          <Button
            size="sm"
            className="mb-2 bg-gradient-info"
            variant="danger"
            onClick={() => {
              handleCloseModal && handleCloseModal();
              blocker.reset?.();
            }}
          >
            <i className="fa-solid fa-trash me-2"></i>
            Keep me here
          </Button>
        </div>
      </div>
    </PopupConfirmation>
  );
};

export default LeavePagePopupConfirmation;
