import classnames from "classnames";
import styles from "./styles.module.scss";

const Component = ({ children }: { children: any }) => (
  <h5 className={classnames("text-sm font-bold mb-2", styles.sectionHeading)}>
    {children}
  </h5>
);

export default Component;
