import * as R from "../../../routings/path";
import StatusTableCell from "../../../components/table-cell-status";
import ProductTableCell from "../../../components/table-cell-product";

export const saleOrderSchema = [
  {
    name: "Number",
    cell: (data: any) => (
      <ProductTableCell
        title={data?.number}
        linkTo={`${R.SALE_ORDER_LIST}/${data?.uuid}`}
        description={`#${data?.channel}`.toUpperCase()}
        maxWidth={200}
      />
    ),
  },
  {
    name: "Contact",
    cell: (data: any) => (
      <ProductTableCell
        subIcon={"fa-phone"}
        linkTo={`tel:${data?.receiver_phone}`}
        title={data?.receiver_name}
        description={data?.receiver_phone}
      />
    ),
  },
  {
    name: "Address",
    cell: (data: any) => (
      <ProductTableCell
        subIcon={"fa-location-dot"}
        description={data?.address?.full_address}
        descriptionLine={2}
      />
    ),
  },
  {
    name: "Status",
    align: "right",
    cell: (data: any) => <StatusTableCell status={data?.status} />,
  },
];
