import * as yup from "yup";

const MAX_IMAGE_SIZE = 3145728;

export const formSchema = yup
  .object({
    name: yup.string().required("This field is required"),
    email: yup.string().nullable(),
    phone: yup.string().nullable(),
    website: yup.string().nullable(),
    address: yup.string().nullable(),
    logo: yup
      .mixed()
      .test(
        "fileSize",
        "File size is too large, should be smaller than 3Mbs",
        (value: any) => {
          if (!value) return true;
          return value?.[0]?.file?.size <= MAX_IMAGE_SIZE;
        }
      )
      .test("fileType", "Unsupported file type", (value: any) => {
        if (!value) return true;
        return ["image/jpeg", "image/png", "image/webp"].includes(
          value?.[0]?.file?.type
        );
      })
      .nullable(),
  })
  .required();
