export const extractPickList = (object: any, name: string) => {
  if (!object) return;
  if (object.hasOwnProperty(name)) {
    return Object.keys(object[name].pick_objects)[0];
  } else {
    return null;
  }
};

export const mappingTaskLoggingStatus = {
  to_do: "Not defined",
  in_progress: "start",
  completed: "complete",
  canceled: "cancel",
};
