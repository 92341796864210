import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { apiCaller, useApiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { GENERAL_UI_SIZE } from "../../constants/size";
import {
  getNamesMapper,
  getTaskLoggingDetail,
  updateTaskLoggingApi,
  updateTaskLoggingStatus,
} from "../../apis/paths/taskLogging";
import {
  convertUnixTimeToISO,
  convertYYYYMMDDToUnixTime,
} from "../../utils/date-time";
import alert from "../../utils/alert";
import UiButton from "../../components/ui-button";
import GeneralPanel from "../../components/panel-general";
import HeaderInfoPanel from "../../components/panel-header-info";
import TableCellStatus from "../../components/table-cell-status";
import SmallCollumnCenterGridLayout from "../../components/grid-layout-small-collumn-center";
import UiStatusDropdown from "../../components/ui-status-dropdown";

import { TASK_LOGGING_LIST } from "../../routings/path";
import { IUpdateTaskLoggingParams } from "./types";
import { taskLoggingSchema } from "./schema/taskLoggingSchema";
import { extractPickList, mappingTaskLoggingStatus } from "./util";
import ViewAssignee from "./view/view-assignee";
import ViewDetailTaskLoggingInfo from "./view/view-detail-info";

const Component = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");

  const param = useParams();
  const taskId = param?.taskId || "";

  const [params] = useState<{
    page: number;
    per: number;
    uuid: string;
  }>({
    page: 1,
    per: 50,
    uuid: taskId,
  });

  const { result } = useApiCaller({
    api: getTaskLoggingDetail,
    params,
  });

  const { result: nameMapper } = useApiCaller({
    api: getNamesMapper,
    params: DEFAULT_PAGINATION_PARAMS,
  });

  const taskDetail = result?.data;
  const {
    detail_name,
    status,
    name,
    linked_item,
    assignee,
    triggerable_events,
  } = taskDetail || {};
  const { uniq_code, display_code } = linked_item || {};

  const [taskLoggingStatus, setTaskLoggingStatus] = useState<any>(status);
  useEffect(() => {
    setTaskLoggingStatus(status);
  }, [status]);

  const [selectedAssignee, setSelectedAssignee] = useState<any>(assignee);
  useEffect(() => {
    setSelectedAssignee(assignee);
  }, [assignee]);

  const {
    setValue,
    register,
    handleSubmit,
    control,
    setError,
    watch,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(taskLoggingSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const formatData = (taskDetail: any) => ({
    ...taskDetail,
    task_name: taskDetail?.detail_name,
    description: taskDetail?.description || "",
    assignee_uuid: taskDetail?.assignee?.uuid,
    linked_object_code: uniq_code,
    linked_object_type:
      taskDetail?.linked_object_type || extractPickList(nameMapper?.data, name),
    start_at: taskDetail?.in_progress_at,
    due_at: taskDetail?.due_at,
  });

  useEffect(() => {
    if (!taskDetail) return;

    const formatedTaskDetail = formatData(taskDetail);
    const keys = Object.keys(taskLoggingSchema?.fields);
    keys.forEach((key: any) => {
      setValue(key, formatedTaskDetail[key]);
      if (key === "linked_object_code") {
        setSearchValue(`${display_code || ""}`);
      }
    });
  }, [result, taskDetail]);

  const startAt = watch("start_at");
  const dueAt = watch("due_at");

  const handleSelectAssignee = (assignee: any) => {
    setSelectedAssignee(assignee);
    setValue("assignee_uuid", assignee?.uuid);
  };

  const onSubmit = async ({
    assignee_uuid,
    linked_object_code,
    linked_object_type,
    start_at,
    due_at,
    description,
  }: any) => {
    let startAt = start_at;
    let dueAt = due_at;

    if (typeof start_at === "string") {
      startAt = convertYYYYMMDDToUnixTime(start_at);
    }

    if (typeof due_at === "string") {
      dueAt = convertYYYYMMDDToUnixTime(due_at);
    }

    if (startAt || dueAt) {
      if (!(startAt && dueAt)) {
        if (!startAt) {
          setError("start_at", {
            type: "manual",
            message: "Please input this field",
          });
        }
        if (!due_at) {
          setError("due_at", {
            type: "manual",
            message: "Please input this field",
          });
        }
        alert.error(
          "Please input both of In Progress At and Due At or None of them"
        );
        return;
      }
    }

    let params: IUpdateTaskLoggingParams = {
      uuid: taskId,
      assignee_uuid,
      in_progress_at: startAt,
      linked_object_code,
      linked_object_type,
      due_at: dueAt,
      description,
    };

    if (!linked_object_code) {
      params = {
        uuid: taskId,
        assignee_uuid,
        in_progress_at: startAt,
        due_at: dueAt,
        description,
      };
    }

    const updateTaskLogging = await apiCaller({
      api: updateTaskLoggingApi,
      params,
    });

    const { status } = updateTaskLogging;
    if (status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update successful");
      navigate(`${TASK_LOGGING_LIST}/${taskId}`);
    }
  };

  const initialParams = {
    ...DEFAULT_PAGINATION_PARAMS,
    task_name: "inbound_qc",
  };

  const [taskLoggingSearchParams, setTaskLoggingSearchParams] = useState<{
    page: number;
    per: number;
    keyword: string;
    task_name: string;
    sort: {
      field: string;
      direction: string;
    } | null;
  }>(initialParams);

  useEffect(() => {
    setTaskLoggingSearchParams((prev) => ({
      ...prev,
      task_name: name,
    }));
  }, [name]);

  return (
    <>
      <HeaderInfoPanel
        title={detail_name}
        infoArea={() => (
          <div className="d-flex gap-2 mt-2">
            <TableCellStatus status={name} size={GENERAL_UI_SIZE.SMALL} />
            <TableCellStatus
              status={taskLoggingStatus}
              size={GENERAL_UI_SIZE.SMALL}
            />
          </div>
        )}
        actionArea={() => (
          <>
            <UiStatusDropdown
              status={
                mappingTaskLoggingStatus[
                  taskLoggingStatus as keyof typeof mappingTaskLoggingStatus
                ]
              }
              triggerableEvents={triggerable_events}
              actionApi={updateTaskLoggingStatus}
              paramsTransformation={(newEvent: string) => ({
                uuid: taskId,
                action: newEvent,
              })}
              onChangeSuccess={(newData: any) => {
                setTaskLoggingStatus(newData?.status);
              }}
            />

            <UiButton
              onClick={handleSubmit(onSubmit)}
              className="me-2"
              title={"Update"}
            />
          </>
        )}
      />
      <SmallCollumnCenterGridLayout className="col-xl-10">
        <GeneralPanel
          panel={{
            subTitle: "Task Information",
          }}
        >
          {taskLoggingSearchParams?.task_name && (
            <ViewDetailTaskLoggingInfo
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              dirtyFields={dirtyFields}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              startAt={
                typeof startAt === "number"
                  ? convertUnixTimeToISO(startAt)
                  : startAt
              }
              fieldName={taskDetail?.linked_object_type}
              dueAt={
                typeof dueAt === "number" ? convertUnixTimeToISO(dueAt) : dueAt
              }
              taskLoggingSearchParams={taskLoggingSearchParams}
              setTaskLoggingSearchParams={setTaskLoggingSearchParams}
            />
          )}
        </GeneralPanel>
        <GeneralPanel
          panel={{
            subTitle: "Assignee Information",
          }}
        >
          <ViewAssignee
            selectedAssignee={selectedAssignee}
            setSelectedAssignee={handleSelectAssignee}
          />
        </GeneralPanel>
      </SmallCollumnCenterGridLayout>
    </>
  );
};

export default Component;
