import { TABLE_ACTION } from "../../constants/table-action";
import { GENERAL_STATUS } from "../../constants/status";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { ACTIONABLE_TYPE } from "../../constants/actionable";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import ActionTableCell from "../../components/table-cell-action";

import { validationMessage } from "../../utils/validation";
import { generateGoogleMapLink } from "../../utils/url";
import * as yup from "yup";

export const fieldTitle = {
  channel: "Channel",
  referenceNumber: "Reference Number",
  selectProvince: "Select Ward / District / Province",
  streetName: "Street Name",
  addresDetail: "Address Detail",
  receiverName: "Receiver Name",
  receiverPhone: "Receiver Phone",
};

type FieldSchema = keyof typeof fieldTitle;
export const schema = yup
  .object({
    referenceNumber: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(fieldTitle[path as FieldSchema])
      ),
    wardId: yup.string().required(),
    streetName: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(fieldTitle[path as FieldSchema])
      ),
    receiverName: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(fieldTitle[path as FieldSchema])
      ),
    receiverPhone: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(fieldTitle[path as FieldSchema])
      ),
    cod: yup.number().nullable(),
    value: yup.number().nullable(),
  })
  .required();

export const warehouseSchema = ({
  selectedWarehouse,
  onSelectWarehouse,
}: {
  selectedWarehouse: any;
  onSelectWarehouse: any;
}) => [
  {
    name: "Warehouse",
    cell: (data: any) => (
      <ProductTableCell
        title={data.name}
        linkTo={generateGoogleMapLink({
          latitude: data.address.latitude,
          longitude: data.address.longitude,
        })}
        linkTarget={"_blank"}
        subIcon={"fa-location-dot"}
        description={data.address.full_address}
        descriptionLine={2}
        maxWidth={150}
      />
    ),
  },
  {
    name: "Storage Type",
    align: "center",
    cell: (data: any) => (
      <StatusTableCell
        status={data.storage_type}
        type={GENERAL_UI_COLOR.SECONDARY}
      />
    ),
  },

  {
    name: "Status",
    align: "center",
    cell: (data: any) => (
      <StatusTableCell
        status={
          data.deleted_at ? GENERAL_STATUS.DEACTIVE : GENERAL_STATUS.ACTIVATE
        }
      />
    ),
  },
  {
    name: "Action",
    align: "right",
    cell: (data: any) => {
      const isExist = selectedWarehouse?.uuid === data.uuid;
      if (isExist) return <div> Added</div>;

      return (
        <ActionTableCell
          types={[TABLE_ACTION.CUSTOM]}
          customActionableType={ACTIONABLE_TYPE.FULL}
          customTitle={"Select"}
          onCustomClick={() => onSelectWarehouse(data)}
        />
      );
    },
  },
];
