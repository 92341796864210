import { useCallback } from "react";
import { shallowEqual } from "react-redux";

import * as actions from "../states/configuration.flow";

import { useAppDispatch, useAppSelector } from "./useStateHook";

const useConfiguration = () => {
  const dispatch = useAppDispatch();

  const settingState = useAppSelector(
    (state) => state.confifuration,
    shallowEqual
  );

  const dispatchConfigurationStatus = useCallback(
    (data: actions.IBooleanPayload) =>
      dispatch(actions.updateBooleanValueAction(data)),
    [dispatch]
  );

  return {
    settingState,
    dispatchConfigurationStatus,
  };
};

export default useConfiguration;
