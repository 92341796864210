import * as yup from "yup";

export const formSchema = yup
  .object({
    description: yup.string().required(),
    name: yup.string().required(),
    label_type: yup
      .string()
      .oneOf(["packing", "picking", "cancelling", "damaged", "expired", "lost"])
      .required(),
    color: yup
      .string()
      .oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "danger",
        "warning",
        "light",
        "dark",
      ]),
  })
  .required();
