import { useLocation } from "react-router-dom";

const Component = () => {
  const location = useLocation();

  const extractMainRoute = location.pathname.split("/")?.[1];
  const routeBackgrounds: { [key: string]: string } = {
    products:
      "https://lixibox-production-uploads.s3.ap-southeast-1.amazonaws.com/wms-assets/cover/sku.webp",
    "product-types":
      "https://lixibox-production-uploads.s3.ap-southeast-1.amazonaws.com/wms-assets/cover/sku.webp",
    warehouses:
      "https://lixibox-production-uploads.s3.ap-southeast-1.amazonaws.com/wms-assets/cover/warehouse.webp",
    roles:
      "https://lixibox-production-uploads.s3.ap-southeast-1.amazonaws.com/wms-assets/cover/role-member.webp",
    accounts:
      "https://lixibox-production-uploads.s3.ap-southeast-1.amazonaws.com/wms-assets/cover/role-member.webp",
    configurations:
      "https://lixibox-production-uploads.s3.ap-southeast-1.amazonaws.com/wms-assets/cover/setup.webp",
    "tpl-routes":
      "https://lixibox-production-uploads.s3.ap-southeast-1.amazonaws.com/wms-assets/cover/routing.webp",
  };

  const backgroundImage =
    routeBackgrounds[extractMainRoute] ||
    "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/profile-layout-header.jpg";

  return (
    <div className="position-absolute w-100 min-height-300 top-0">
      <img
        src={backgroundImage}
        alt="background"
        style={{
          position: "absolute",
          top: 0,
          height: "100%",
          width: "100%",
          objectFit: "cover",
          objectPosition: "center",
        }}
      />
      <span
        className="mask opacity-7"
        style={{ backgroundColor: "#344767" }}
      ></span>
    </div>
  );
};

export default Component;
