import { useState } from "react";
import { useNavigate } from "react-router-dom";

import * as R from "../../routings/path";
import alert from "../../utils/alert";
import { apiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import TablePanel from "../../components/panel-table";
import UiButton from "../../components/ui-button";

import { schema } from "./schema";
import {
  deleleRelocationShipment,
  getRelocationShipmentApi,
} from "../../apis/paths/relocationShipment";

export const RELOCATION_SHIPMENT_FILTER_OPTIONS = [
  {
    field: "status",
    operator: "in",
    options: [
      { value: "draft", content: "Draft" },
      { value: "confirmed", content: "Confirmed" },
      { value: "in_process", content: "In Process" },
      { value: "picking", content: "Picking" },
      { value: "shipping", content: "Shipping" },
      { value: "received", content: "Received" },
      { value: "fulfilled", content: "Fulfilled" },
    ],
  },
];

const Component = () => {
  const navigate = useNavigate();
  const [pageReloadVersion, setPageReloadVersion] = useState(1);

  const handleDeleteRelocationShipment = async (uuid: string) => {
    const waitDelete = await apiCaller({
      api: deleleRelocationShipment,
      params: { uuid },
    });

    if (waitDelete.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Delete successful!");

      setPageReloadVersion((prev) => prev + 1);
      return;
    }

    alert.error("Delete was unsuccessful!. Please try again");
  };

  return (
    <TablePanel
      apiPath={getRelocationShipmentApi}
      pageReloadVersion={pageReloadVersion}
      panel={{
        title: "Relocation shipments",
        description: "All relocation shipments",
        actionArea: () => (
          <>
            <UiButton
              title={"New"}
              onClick={() => navigate(`${R.RELOCATION_SHIPMENTS_CREATE}`)}
            />
          </>
        ),
      }}
      filter={{
        optionList: RELOCATION_SHIPMENT_FILTER_OPTIONS,
      }}
      tableSchema={schema({ onDelete: handleDeleteRelocationShipment })}
    />
  );
};

export default Component;
