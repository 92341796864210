import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { generateUrlParams } from "../../utils/url";
import { get, post, patch, del } from "../config";

interface IGetSaleOrderListApi {
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
  filter?: object | null;
}

export interface IUpdateSaleOrderShippingService {
  uuid: string;
  shipping_service: string;
  shipping_service_name?: string;
  tracking_code?: string;
}

export const getSaleOrderListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
  filter = DEFAULT_PAGINATION_PARAMS.filter,
}: IGetSaleOrderListApi) =>
  get({
    path: `/wms/sale_orders${generateUrlParams({
      page,
      per,
      keyword,
      sort,
      filter,
    })}`,
  });

export const getSaleOrderDetailApi = ({ uuid }: { uuid: string }) => {
  return get({ path: `/wms/sale_orders/${uuid}` });
};

export const getSaleOrderTrackingLink = ({ uuid }: { uuid: string }) => {
  return get({ path: `/wms/sale_orders/${uuid}/tracking_link` });
};
export const getSaleOrderCountApi = () =>
  get({ path: `/wms/sale_orders/count` });

export const getSaleOrderSelectionApi = () =>
  get({ path: `/wms/sale_orders/selection` });

export interface ICreateSaleOrder {
  selectedChannel: string;
  referenceNumber: string;
  warehouseUuid: string;
  wardId: number;
  streetName: string;
  addressDetail: string;
  receiverName: string;
  receiverPhone: string;
  products: Array<any>;
  productStatus: string;
  cod: number;
  value: number;
}

export const createSaleOrder = ({
  selectedChannel,
  referenceNumber,
  warehouseUuid,
  wardId,
  streetName,
  addressDetail,
  receiverName,
  receiverPhone,
  products,
  productStatus,
  cod,
  value,
}: ICreateSaleOrder) =>
  post({
    path: `/wms/sale_orders`,
    data: {
      channel: selectedChannel,
      reference_number: referenceNumber,
      warehouse_code: warehouseUuid,
      ward_id: wardId,
      street_name: streetName,
      address_detail: addressDetail,
      receiver_name: receiverName,
      receiver_phone: receiverPhone,
      products: products,
      product_status: productStatus,
      cod,
      value,
    },
  });

export interface IUpdateSaleOrder {
  selectedChannel: string;
  warehouseUuid: string;
  wardId: number;
  streetName: string;
  addressDetail: string;
  receiverName: string;
  receiverPhone: string;
  referenceNumber: string;
  cod: number;
  value: number;
}

export const updateSaleOrder = ({
  selectedChannel,
  warehouseUuid,
  wardId,
  streetName,
  addressDetail,
  receiverName,
  receiverPhone,
  referenceNumber,
  cod,
  value,
}: IUpdateSaleOrder) =>
  patch({
    path: `/wms/sale_orders/${referenceNumber}`,
    data: {
      number: referenceNumber,
      channel: selectedChannel,
      warehouse_code: warehouseUuid,
      ward_id: wardId,
      street_name: streetName,
      address_detail: addressDetail,
      receiver_name: receiverName,
      receiver_phone: receiverPhone,
      cod,
      value,
    },
  });

export const updateSaleOrderShippingService = ({
  uuid,
  shipping_service,
  shipping_service_name,
  tracking_code,
}: IUpdateSaleOrderShippingService) =>
  patch({
    path: `/wms/sale_orders/${uuid}/shipping_service`,
    data: {
      shipping_service,
      shipping_service_name,
      tracking_code,
    },
  });

export interface IUpdateSaleOrderStatusApi {
  uuid: string;
  event: string;
}
export const updateSaleOrderStatusApi = ({
  uuid,
  event,
}: IUpdateSaleOrderStatusApi) =>
  patch({
    path: `/wms/sale_orders/${uuid}/status`,
    data: {
      number: uuid,
      action: event,
    },
  });

export const getSaleOrderProductListApi = ({
  uuid,
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
}: {
  uuid: string;
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
}) =>
  get({
    path: `/wms/sale_orders/${uuid}/products${generateUrlParams({
      page,
      per,
      keyword,
      sort,
    })}`,
  });

export interface IAddProductIntoSaleOrderApi {
  uuid: string;
  products: Array<{
    code: string;
    quantity: string | number;
  }>;
}

export const addProductIntoSaleOrderApi = ({
  uuid,
  products,
}: IAddProductIntoSaleOrderApi) =>
  post({
    path: `/wms/sale_orders/${uuid}/products`,
    data: {
      number: uuid,
      products,
    },
  });

export interface IRemoveProductFromSaleOrderApi {
  uuid: string;
  codes: string[];
}

export const removeProductFromSaleOrderApi = ({
  uuid,
  codes,
}: IRemoveProductFromSaleOrderApi) =>
  del({
    path: `/wms/sale_orders/${uuid}/products`,
    data: {
      codes,
    },
  });

export interface IChangeProductSaleOrderQtyApi {
  uuid: string;
  code: string;
  quantity: number;
}

export const changeProductSaleOrderQtyApi = ({
  uuid,
  code,
  quantity,
}: IChangeProductSaleOrderQtyApi) =>
  patch({
    path: `/wms/sale_orders/${uuid}/products/${code}/quantity`,
    data: {
      quantity,
    },
  });

export const changeSaleOrderPriorityToProcessApi = ({
  uuid,
  highest,
}: {
  uuid: string;
  highest: boolean | undefined;
}) =>
  patch({
    path: `/wms/sale_orders/${uuid}/priority_to_process`,
    data: {
      highest,
    },
  });

export const deleteProductFromSaleOrderApi = ({
  uuid,
  productItemId,
}: {
  uuid: string;
  productItemId: string;
}) =>
  del({
    path: `/wms/sale_orders/${uuid}/products/${productItemId}`,
    data: {
      number: uuid,
      id: productItemId,
    },
  });

export const updateProductStatusInSaleOrderApi = ({
  uuid,
  productItemId,
  event,
}: {
  uuid: string;
  productItemId: string;
  event: string;
}) =>
  patch({
    path: `/wms/sale_orders/${uuid}/products/${productItemId}/status`,
    data: {
      number: uuid,
      id: productItemId,
      action: event,
    },
  });
