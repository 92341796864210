import { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";

import { updateLoginInfoAction } from "../../states/user.flow";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { sessionsLoginApi } from "../../apis/paths/sessions";
import { apiCaller } from "../../apis/config";
import alert from "../../utils/alert";
import Typography from "../../components/ui-typography/component";
import AuthLayout from "../../layouts/auth-layout";
import UiButton from "../../components/ui-button";
import UiInput from "../../components/ui-input";
import UiForm from "../../components/ui-form";

import { schema } from "./schema";
import styles from "./styles.module.scss";

const Component = () => {
  const [isLoading, setLoading] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();

  const onSubmit = async ({ username, password }: any) => {
    setLoading(true);
    const loginInfo = await apiCaller({
      api: sessionsLoginApi,
      params: { username, password },
    });
    setLoading(false);

    const { status, data } = loginInfo;

    if (status === API_RESPONSE_STATUS.SUCCESS) {
      dispatch(
        updateLoginInfoAction({
          access_token: data.access_token,
          account: data.account,
        })
      );
    }
  };

  const authLayoutProps = {
    cover: {
      title: "LIXHOUSE",
      description: "Fullfilment Center",
    },
    form: {
      title: "Welcome back",
      // description: "Please input your username and password",
    },
  };

  return (
    <AuthLayout {...authLayoutProps}>
      <UiForm onSubmit={handleSubmit(onSubmit)}>
        <UiInput
          title={"Username"}
          name={"username"}
          isDisplayLabel={false}
          register={register}
          errorMessage={errors.username?.message}
        />
        <div className="position-relative">
          <UiInput
            title={"Password"}
            name={"password"}
            type={isShowPassword ? "text" : "password"}
            isDisplayLabel={false}
            register={register}
            errorMessage={errors.password?.message}
          />
          <div
            className={classNames("cursor-pointer right-0 ", styles.eyeIcon)}
            onClick={() => {
              setIsShowPassword((prev) => !prev);
            }}
          >
            {isShowPassword ? (
              <i className="fa-solid fa-eye-slash"></i>
            ) : (
              <i className="fa-solid fa-eye"></i>
            )}
          </div>
        </div>
        <UiButton
          isLoading={isLoading}
          title={"Sign in"}
          className={"mt-2 w-100"}
        />
        <hr className="horizontal dark mt-3" />
        <Typography
          className="my-2 text-end cursor-pointer"
          customFontsize={13}
          handleClick={() => {
            alert.info("Coming soon!");
          }}
        >
          Forgot password?
        </Typography>
      </UiForm>
    </AuthLayout>
  );
};

export default Component;
