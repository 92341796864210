import { useState } from "react";
import styles from "./styles.module.scss";

interface IComponent {
  title?: string;
  description?: string;
  list?: Array<{
    icon: string;
    title: string;
    id: number;
  }>;
  errorMessage?: string;
  onSelect: Function;
}

const Component = ({
  title = "",
  description = "",
  list,
  errorMessage = "",
  onSelect,
}: IComponent) => {
  const [selected, setSelected] = useState(-1);

  if (!list || !list.length) return null;

  const handleSelect = (item: any) => {
    setSelected(item.id);
    onSelect?.(item);
  };

  return (
    <div className={styles.container}>
      <div className={styles.panel}>
        {title && <div className={styles.title}>{title}</div>}
        {description && <div className={styles.description}>{description}</div>}

        <div className={styles.list}>
          {list.map((item: any, index: number) => (
            <div
              className={`${styles.item} ${
                item.id === selected && styles.active
              }`}
              key={`item-${item.id || index}`}
              onClick={() => handleSelect(item)}
            >
              <div className={styles.icon}>
                <i className={`fa-solid  ${item.icon}`}></i>
              </div>
              <div className={styles.name}>{item.title}</div>
            </div>
          ))}
        </div>

        <div className={styles.errorMessage}> {errorMessage || ""} </div>
      </div>
    </div>
  );
};

export default Component;
