import * as CryptoJS from "crypto-js";

const DARK_FACTOR = 0.7;

export const defaultDataTransformation = (result: any) =>
  result
    ? {
        data: result?.data?.list,
        paging: {
          currentPage: result?.data?.paging?.current_page,
          perPage: result?.data?.paging?.per_page,
          totalPages: result?.data?.paging?.total_pages,
          totalCount: result?.data?.paging?.total_count,
        },
        actionable: result?.data?.actionable,
        filterable: result?.data?.filterable,
      }
    : null;

export const transferArrayToObject = (
  array: any[],
  itemKey: string,
  itemValue: string
) =>
  array?.reduce((acc, item) => {
    acc[item[itemKey]] = item[itemValue];
    return acc;
  }, {});

export const convertNameToColors = (name: string): [number, number, number] => {
  const hash: string = CryptoJS.MD5(name).toString();

  // Extract parts of the hash and use them as the red, green, and blue components
  const r: number = parseInt(hash.slice(0, 2), 16);
  const g: number = parseInt(hash.slice(2, 4), 16);
  const b: number = parseInt(hash.slice(4, 6), 16);

  const darkR = Math.floor(r * DARK_FACTOR);
  const darkG = Math.floor(g * DARK_FACTOR);
  const darkB = Math.floor(b * DARK_FACTOR);

  return [darkR, darkG, darkB];
};
