import React from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

const NotePosting = ({ note }: { note: any }) => {
  return (
    <div className="d-flex ">
      <div className=" d-flex flex-grow-1 mx-3 ">
        <div className="w-100">
          <p
            className={classNames(
              "text-sm p-1 px-2 border-radius-sm",
              styles.noteItem
            )}
          >
            {note}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotePosting;
