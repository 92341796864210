import { useApiCaller } from "../../apis/config";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { getInventoryFlow } from "../../apis/paths/dashboard";
import { useWindowResize } from "../../hooks/useWindowResize";
import Typography from "../../components/ui-typography/component";
import PanelGeneral from "../../components/panel-general/component";
import DoughnutChart from "../../components/ui-doughnut-chart/component";

import { LARGE_SCREEN_SIZE, MEDIUM_SCREEN_SIZE } from "./component";

const Component = () => {
  const { result: inventoryFlow } = useApiCaller({
    api: getInventoryFlow,
    params: DEFAULT_PAGINATION_PARAMS,
  });

  const inventoryStatus = inventoryFlow?.data;

  const inboundDoughnutInput = {
    labels: ["Received", "Processing"],
    datasets: [
      {
        label: "Quantity",
        data: [
          inventoryStatus?.inbound?.received,
          inventoryStatus?.inbound?.processing,
        ],
        backgroundColor: ["rgba(54, 162, 235, 0.2)", "rgba(255, 206, 86, 0.2)"],
        hoverBackgroundColor: [
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
        ],
        cutout: 90,
        borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const { windowWidth } = useWindowResize();
  const isLargeScreen = windowWidth && windowWidth >= LARGE_SCREEN_SIZE;
  const isMediumScreen =
    (windowWidth && windowWidth <= MEDIUM_SCREEN_SIZE) || true;

  return (
    <div>
      <PanelGeneral className="position-relative w-100">
        <div className="d-flex justify-content-between my-2 align-items-center">
          <Typography
            className="text-center my-3"
            customFontsize={14}
            fontWeight="fw-bold"
          >
            Inbound Orders
          </Typography>
        </div>
        <DoughnutChart
          data={inboundDoughnutInput}
          width="100%"
          height={280}
          legend={{ position: "bottom" }}
        />
      </PanelGeneral>
    </div>
  );
};

export default Component;
