/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as R from "../../routings/path";
import { useApiCaller } from "../../apis/config";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { GENERAL_UI_SIZE } from "../../constants/size";
import {
  getNamesMapper,
  getTaskLoggingDetail,
} from "../../apis/paths/taskLogging";
import { convertUnixTimeToISO } from "../../utils/date-time";
import UiButton from "../../components/ui-button";
import HeaderInfoPanel from "../../components/panel-header-info";
import TableCellStatus from "../../components/table-cell-status";
import GeneralPanel from "../../components/panel-general";
import SmallCollumnCenterGridLayout from "../../components/grid-layout-small-collumn-center";

import { taskLoggingSchema } from "./schema/taskLoggingSchema";
import { extractPickList } from "./util";
import ViewAssignee from "./view/view-assignee";
import ViewDetailTaskLoggingInfo from "./view/view-detail-info";

const Component = () => {
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");

  const param = useParams();
  const taskId = param?.taskId || "";

  const [params] = useState<{
    page: number;
    per: number;
    uuid: string;
  }>({
    page: 1,
    per: 50,
    uuid: taskId,
  });

  const { result } = useApiCaller({
    api: getTaskLoggingDetail,
    params,
  });

  const { result: nameMapper } = useApiCaller({
    api: getNamesMapper,
    params: DEFAULT_PAGINATION_PARAMS,
  });

  const taskDetail = result?.data;
  const { detail_name, status, name, linked_item, assignee } = taskDetail || {};
  const { display_code, warehouse_name } = linked_item || {};

  const [taskLoggingStatus, setTaskLoggingStatus] = useState<any>(status);
  useEffect(() => {
    setTaskLoggingStatus(status);
  }, [status]);

  const [selectedAssignee, setSelectedAssignee] = useState<any>(assignee);
  useEffect(() => {
    setSelectedAssignee(assignee);
  }, [assignee]);

  const {
    setValue,
    register,
    control,
    watch,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(taskLoggingSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const formatData = (taskDetail: any) => ({
    ...taskDetail,
    task_name: taskDetail?.detail_name,
    description: taskDetail?.description || "",
    assignee_uuid: taskDetail?.assignee?.uuid,
    linked_object_code: taskDetail?.linked_object_uuid,
    linked_object_type:
      taskDetail?.linked_object_type || extractPickList(nameMapper?.data, name),
    start_at: taskDetail?.in_progress_at,
    due_at: taskDetail?.due_at,
  });

  useEffect(() => {
    if (!taskDetail) return;

    const formatedTaskDetail = formatData(taskDetail);
    const keys = Object.keys(taskLoggingSchema?.fields);
    keys.forEach((key: any) => {
      setValue(key, formatedTaskDetail[key]);
      if (key === "linked_object_code") {
        setSearchValue(`${display_code || ""} - ${warehouse_name || ""}` || "");
      }
    });
  }, [result, taskDetail]);

  const startAt = watch("start_at");
  const dueAt = watch("due_at");

  const initialParams = {
    ...DEFAULT_PAGINATION_PARAMS,
    task_name: "inbound_qc",
  };

  const [taskLoggingSearchParams, setTaskLoggingSearchParams] = useState<{
    page: number;
    per: number;
    keyword: string;
    task_name: string;
    sort: {
      field: string;
      direction: string;
    } | null;
  }>(initialParams);

  useEffect(() => {
    setTaskLoggingSearchParams((prev) => ({
      ...prev,
      task_name: name,
    }));
  }, [name]);

  return (
    <>
      <HeaderInfoPanel
        title={detail_name}
        infoArea={() => (
          <div className="d-flex gap-2 mt-2">
            <TableCellStatus status={name} size={GENERAL_UI_SIZE.SMALL} />
            <TableCellStatus
              status={taskLoggingStatus}
              size={GENERAL_UI_SIZE.SMALL}
            />
          </div>
        )}
        actionArea={() => (
          <>
            <UiButton
              onClick={() => {
                navigate(`${R.TASK_LOGGING_LIST}/${taskId}/edit`);
              }}
              className="me-2"
              title={"Edit"}
            />
          </>
        )}
      />
      <SmallCollumnCenterGridLayout className="col-xl-10">
        <GeneralPanel
          panel={{
            subTitle: "Task Information",
          }}
        >
          {taskLoggingSearchParams?.task_name && (
            <ViewDetailTaskLoggingInfo
              register={register}
              control={control}
              errors={errors}
              linkedObjectType = {taskDetail?.linked_object_type}
              dirtyFields={dirtyFields}
              searchValue={searchValue}
              startAt={
                typeof startAt === "number"
                  ? convertUnixTimeToISO(startAt)
                  : startAt
              }
              dueAt={
                typeof dueAt === "number" ? convertUnixTimeToISO(dueAt) : dueAt
              }
            />
          )}
        </GeneralPanel>
        <GeneralPanel
          panel={{
            subTitle: "Assignee Information",
          }}
        >
          <ViewAssignee selectedAssignee={selectedAssignee} />
        </GeneralPanel>
      </SmallCollumnCenterGridLayout>
    </>
  );
};

export default Component;
