export const CHANNEL = [
  {
    value: "purchase",
    content: "purchase",
  },
  {
    value: "return",
    content: "return",
  },
  {
    value: "relocation",
    content: "relocation",
  },
];
