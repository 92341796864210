import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProductListApi } from "../apis/paths/products";
import { apiCaller } from "../apis/config";
import alert from "../utils/alert";

const sliceName = "extractingProductFromCSVFile";

interface IExtractProductFromCsvFileProps {
  products: any[];
  isLoading: boolean;
  error: any;
}

const INITIAL_STATE: IExtractProductFromCsvFileProps = {
  products: [],
  isLoading: false,
  error: null,
};

export const fetchProductFromCsvFile = createAsyncThunk(
  `${sliceName}/getProductFromCSVFile`,
  async (file: any[], { rejectWithValue }) => {
    if (!file) return;
    if (
      !file.some((row: any) => row.product_uuid) &&
      !file.some((row: any) => row.product_code) &&
      !file.some((row: any) => row.product_gtin_code)
    ) {
      alert.error(
        'Please input as least one column "product_uuid" or "product_code" or "product_gtin_code"'
      );
      return rejectWithValue("Imported file is not corrected");
    }

    const extractProductFromCSVFile = (columnKey: string) =>
      file?.map((row: any) => row[columnKey]);

    const definedFilterParams = () => {
      if (file.some((row: any) => row?.product_uuid)) {
        return {
          param: {
            field: "uuid",
            values: extractProductFromCSVFile("product_uuid"),
            operator: "in",
          },
          csvKey: "product_uuid",
          responseKey: "uuid",
        };
      } else if (file.some((row: any) => row?.product_gtin_code)) {
        return {
          param: {
            field: "gtin_code",
            values: extractProductFromCSVFile("product_gtin_code"),
            operator: "in",
          },
          csvKey: "product_gtin_code",
          responseKey: "gtin_code",
        };
      } else if (file.some((row: any) => row?.product_code)) {
        return {
          param: {
            field: "code",
            values: extractProductFromCSVFile("product_code"),
            operator: "in",
          },
          csvKey: "product_code",
          responseKey: "code",
        };
      }
    };

    const response = await apiCaller({
      api: getProductListApi,
      params: {
        filter: [definedFilterParams()?.param],
      },
    });

    return {
      data: response?.data,
      params: definedFilterParams(),
      file,
    };
  }
);

export const extractProductFromCsvSlice = createSlice({
  name: sliceName,
  initialState: INITIAL_STATE,
  reducers: {
    clearProducts: (state) => {
      state.products = [];
    },
    addDefaultProducts: (state, { payload }) => {
      state.products = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductFromCsvFile.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchProductFromCsvFile.fulfilled, (state, { payload }) => {
      if (state.error) return;

      const { data, params, file } = payload || {};
      const { csvKey, responseKey } = params || {};

      const mappingProductResponseWithQtyInCSVFile = data?.list?.map(
        (product: any) => ({
          ...product,
          quantity:
            Number(
              file?.find(
                (csvImportedProduct: any) =>
                  csvImportedProduct?.[csvKey as string] ===
                  product?.[responseKey as string]
              )?.quantity
            ) || 1,
        })
      );

      state.products = mappingProductResponseWithQtyInCSVFile;
      alert.success("Import successful!");
      state.isLoading = false;
    });
    builder.addCase(fetchProductFromCsvFile.rejected, (state, action) => {
      state.isLoading = false;
      alert.error("Import failed!");
      state.error = action.error;
    });
  },
});

export const { actions } = extractProductFromCsvSlice;
export const { clearProducts, addDefaultProducts } =
  extractProductFromCsvSlice.actions;

export default extractProductFromCsvSlice.reducer;
