import React from "react";
import { Button } from "react-bootstrap";

import PopupConfirmation from "../../components/popup-confirmation/component";
import PrintingProvider from "../../components/ui-printing-provider/component";
import Typography from "../../components/ui-typography/component";

import ViewBarCodePrinting from "./view-bar-code-printing";

interface IViewPrintingPopup {
  isShowModal: boolean;
  handleCloseModal: () => void;
  printingQty: number;
  setPrintingQty: any;
  selectedCode: string;
}
const ViewPrintingPopup = ({
  isShowModal = false,
  handleCloseModal,
  printingQty,
  setPrintingQty,
  selectedCode,
}: IViewPrintingPopup) => {
  return (
    <PopupConfirmation
      isShowModal={isShowModal}
      onCloseModal={handleCloseModal}
    >
      <Typography fontWeight="fw-bold" customFontsize={14} className="mb-3">
        How many item?
      </Typography>
      <input
        type="number"
        className="form-control mb-3"
        value={printingQty}
        onChange={(e: any) => {
          setPrintingQty(e.target.value);
        }}
      />
      <div className="d-flex mt-3 justify-content-end">
        <Button
          className="mb-2 me-3"
          variant=""
          size="sm"
          onClick={handleCloseModal}
        >
          <i className="fa-solid fa-xmark me-2"></i> Cancel
        </Button>
        {selectedCode && (
          <PrintingProvider
            buttonTitle={
              <Button
                size="sm"
                className="mb-2 bg-gradient-primary "
                variant="primary"
              >
                <i className="fa-solid fa-print me-2"></i>
                Print
              </Button>
            }
          >
            {printingQty && (
              <ViewBarCodePrinting
                code={selectedCode as string}
                title="Materials"
                itemQuantity={printingQty}
                width={185}
              />
            )}
          </PrintingProvider>
        )}
      </div>
    </PopupConfirmation>
  );
};

export default ViewPrintingPopup;
