const LoadingPanel = ({ type = "normal" }: { type?: "normal" | "minimal" }) => {
  const size = type === "normal" ? "fa-3x" : "fa-2x";

  return (
    <div className="d-flex justify-content-center align-items-center pt-7">
      <div className="text-center">
        <i className={`fa fa-spinner fa-pulse fa-fw ${size}`}></i>
        <p className="mt-1">Loading...</p>
      </div>
    </div>
  );
};

export default LoadingPanel;
