import styles from "./styles.module.scss";
import Typography from "../../components/ui-typography/component";
import PanelGeneral from "../../components/panel-general/component";

interface IComponent {
  body?: any;
  panel?: {
    title?: string | undefined;
    subTitle?: string | undefined;
    actionArea?: any | undefined;
  };
}


const Component = ({
  body,
  panel,
}: IComponent) => {
  return (
    <>
      <PanelGeneral>
        <div className="d-flex justify-content-between my-2 align-items-center">
          <Typography
            className="my-3"
            customFontsize={14}
            fontWeight="fw-bold"
          >
            <span className="mb-0">{panel?.title}</span>
            {panel?.subTitle && (<p className="mb-0 fs-6">{panel?.subTitle}</p>)}
          </Typography>
          <div>{panel?.actionArea ? panel.actionArea() : null}</div>
        </div>
        <>{body ? body : null}</>
      </PanelGeneral>
    </>
  );
};

export default Component;
