import React from "react";
import ListGroup from "react-bootstrap/ListGroup";

import { GENERAL_UI_COLOR } from "../../constants/color";

interface IListGroupProps {
  list: any[];
  horizontal?: boolean;
  action?: boolean; //This field to trigger hover action
  variant?: GENERAL_UI_COLOR;
}

const List = ({
  list,
  horizontal = false,
  variant = GENERAL_UI_COLOR.PRIMARY,
  action = false,
}: IListGroupProps) => {

  return (
    <ListGroup horizontal={horizontal}>
      {list?.map((item, index) => (
        <ListGroup.Item
          as="div"
          action={item?.action || action}
          disabled={item?.disable || false}
          key={item?.id || index}
          variant={variant}
          onClick={item.handleClick}
        >
          {item?.content || ""}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default List;
