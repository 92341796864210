import React, { useState } from "react";

import { useApiCaller } from "../../apis/config";
import { getMaterialBarCodeApi } from "../../apis/paths/material";
import { getCurrentTime } from "../../utils/date-time";
import Typography from "../../components/ui-typography/component";

const ViewBarCodePrinting = ({
  code,
  title = "",
  itemQuantity = 5,
  width = 180,
}: {
  code: string;
  title?: string;
  itemQuantity?: number;
  buttonTitle?: string | React.ReactNode;
  width?: number;
}) => {
  const [params] = useState<{
    code: string;
  }>({
    code,
  });

  const { result } = useApiCaller({
    api: getMaterialBarCodeApi,
    params,
  });

  const barcode = result?.data?.qrcode;

  return (
    <div>
      <div className="d-flex p-2">
        <Typography customFontsize={18} fontWeight="fw-bold">
          {getCurrentTime()}
        </Typography>
        <Typography
          className="ms-auto"
          customFontsize={18}
          fontWeight="fw-bold"
        >
          {title}
        </Typography>
      </div>
      <div className="d-flex flex-wrap">
        {Array(Number(itemQuantity)).fill(
          <img src={barcode} alt={barcode} style={{ width: width }} />
        )}
      </div>
    </div>
  );
};

export default ViewBarCodePrinting;
