import React from "react";

import { convertYYYYMMDDToUnixTime } from "../../utils/date-time";

import UiContentRow from "../ui-content-row/component";
import Typography from "../ui-typography/component";
import DateTimeTableCell from "../table-cell-date-time/component";

interface IViewActivitiItem {
  index: string;
  change: any[];
}

const ViewActivitiItem = ({ index, change }: IViewActivitiItem) => {
  const formatChangeValue = (change: string) =>
    change === null ? "null" : change;

  const renderChangeValue = (key: string, change: any) => {
    if (key === "updated_at" || key === "created_at") {
      return (
        <DateTimeTableCell
          timeStamp={convertYYYYMMDDToUnixTime(change)}
          formatTimestamp={{
            time: "HH:mm YYYY-MM-DD",
            tooltip: "HH:mm YYYY-MM-DD",
          }}
        />
      );
    }

    return <Typography>{formatChangeValue(change)}</Typography>;
  };

  return (
    <UiContentRow
      label={index}
      className={{ container: "w-100" }}
      customStyles={{ value: { flexGrow: 1 } }}
    >
      <div className="align-items-center row">
        <div className="col col-5">{renderChangeValue(index, change[0])}</div>
        <div className="col col-2">
          <i className="fa-solid fa-arrow-right mx-3"></i>
        </div>
        <div className="col col-5">{renderChangeValue(index, change[1])}</div>
      </div>
    </UiContentRow>
  );
};

export default ViewActivitiItem;
