import React from "react";

import { assigneeSchema } from "../schema/assigneeSchema";
import { getSelectionAssignees } from "../../../apis/paths/taskLogging";
import { GENERAL_UI_COLOR } from "../../../constants/color";
import Avatar from "../../../components/ui-avatar/component";
import TwoColumnGridLayout from "../../../components/grid-layout-two-column";
import TablePanel from "../../../components/panel-table";

import styles from "./styles.module.scss";
import SelectedItem from "./view-selected-item";

interface IParams {
  task_name: string;
  linked_object_type?: string;
  linked_object_code?: string;
}
const ViewAssignee = ({
  selectedAssignee,
  setSelectedAssignee,
  selectedType,
  errorMessage,
  linkedObjectType,
  linkedObjectCode,
}: {
  selectedAssignee: any;
  setSelectedAssignee: any;
  selectedType: string;
  errorMessage: string;
  linkedObjectType: string;
  linkedObjectCode: string;
}) => {
  if (!selectedType) return null;

  let apiParams: IParams = { task_name: selectedType };

  if (linkedObjectCode) {
    apiParams = {
      task_name: selectedType,
      linked_object_type: linkedObjectType,
      linked_object_code: linkedObjectCode,
    };
  }

  return (
    <TwoColumnGridLayout
      widthDivision={[6, 6]}
      leftCol={
        <>
          <TablePanel
            apiPath={getSelectionAssignees}
            apiParams={apiParams}
            panelClassName={"mt-0"}
            type={"minimal"}
            tableSchema={assigneeSchema({
              selectedAssignee: selectedAssignee,
              onSelectedAssignee: setSelectedAssignee,
            })}
          />
        </>
      }
      rightCol={
        <div>
          <div className={styles.selectItem}>
            <SelectedItem
              label="Selected Assignee"
              noItemContent={"No Assignee Selected"}
              itemName={
                !!selectedAssignee && (
                  <div className="d-flex justify-content-start align-items-center">
                    <Avatar
                      imageSrc={selectedAssignee?.avatar}
                      name={selectedAssignee?.name}
                      variant={GENERAL_UI_COLOR.SECONDARY}
                      fixSize={36}
                    />
                    <div className="ms-2">{selectedAssignee?.name}</div>
                  </div>
                )
              }
            />
          </div>
          {errorMessage && errorMessage.length && (
            <label className="mb-0" style={{ color: "#f5365c" }}>
              {errorMessage}
            </label>
          )}
        </div>
      }
    />
  );
};

export default ViewAssignee;
