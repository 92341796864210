import { useEffect, useState } from "react";
import Fuse from "fuse.js";
import { useNavigate, useParams } from "react-router-dom";

import { useApiCaller } from "../../apis/config";
import { PACKINGS_LIST } from "../../routings/path";
import { getPackingDetailApi } from "../../apis/paths/packing";
import { useKeyPress } from "../../hooks/useTypingLetterSelection";
import UiTooltip from "../../components/ui-tooltip/component";
import InputSearch from "../../components/search-input/component";
import Typography from "../../components/ui-typography/component";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import HeaderInfoPanel from "../../components/panel-header-info";
import TableDataPanel from "../../components/panel-table-data";
import UiButton from "../../components/ui-button";

import { locationSchema, saleOrderSchema } from "./schema";
import styles from "./styles.module.scss";

const Component = () => {
  const { packingId } = useParams();
  const uuid = packingId || "";
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");

  const [params] = useState({ uuid });

  const { result } = useApiCaller({
    api: getPackingDetailApi,
    params,
  });

  const isKeyPressed1 = useKeyPress(["S", "S"]);
  const isKeyPressed2 = useKeyPress(["s", "s"]);

  const isProcessStart = isKeyPressed1 || isKeyPressed2;

  useEffect(() => {
    if (isProcessStart) {
      navigate(`${PACKINGS_LIST}/${uuid}/scan-products`);
    }
  }, [isProcessStart]);

  const pickingDetail = result?.data;
  const saleOrders = pickingDetail?.sale_orders || [];

  const handleSearch = (value: string, fields: any) => {
    if (value === "") return fields;
    const fuse = new Fuse(fields, {
      keys: ["number", "reference_number", "uuid"],
      threshold: 0.5,
    });

    const searchTerm = value.toLowerCase();
    const results = fuse.search(searchTerm);

    const matchedObjects = results.map((result) => result.item);
    return matchedObjects;
  };

  return (
    <>
      <HeaderInfoPanel
        title={`Packing task of Picklist ${pickingDetail?.code}`}
        actionArea={() => (
          <UiTooltip
            position="bottomRight"
            tooltip={
              <Typography className="d-flex align-items-center ">
                Type
                <span className="badge badge-primary text-xl align-self-start mx-1">
                  SS
                </span>
                for quick <div className="text-primary ms-1 fw-bold">Start</div>
              </Typography>
            }
            className={{
              mainContent: "text-center",
              floatingContent: styles.floatingContent,
            }}
          >
            <UiButton
              onClick={() => {
                navigate(`${PACKINGS_LIST}/${uuid}/scan-products`);
              }}
              disabled={!pickingDetail?.packable}
              title={"Start (S)"}
            />
          </UiTooltip>
        )}
      />

      <TwoColumnGridLayout
        className={{ container: "mt-4 px-0" }}
        leftCol={
          <>
            <Typography
              className="text-start mb-2 pt-2"
              textColor="black"
              fontWeight="fw-bold"
            >
              Locations
            </Typography>
            <div className={styles.productTable}>
              <TableDataPanel
                data={pickingDetail?.locations}
                schema={locationSchema}
              />
            </div>
          </>
        }
        rightCol={
          <>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <Typography
                className="text-start"
                textColor="black"
                fontWeight="fw-bold"
              >
                Sale Orders
              </Typography>
              <InputSearch
                value={searchValue}
                setValue={setSearchValue}
                className={{ container: "p-0" }}
                style={{ backgroundColor: "white", height: 30, minHeight: 30 }}
              />
            </div>

            <TableDataPanel
              data={handleSearch(searchValue, saleOrders)}
              schema={saleOrderSchema(uuid)}
            />
          </>
        }
      />
    </>
  );
};

export default Component;
