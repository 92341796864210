import * as R from "../../routings/path";

export interface ISidebarContent {
  href: string;
  content: string;
  icon: any;
  similarActiveHref?: string[];
}

export const generateWarehouseConfig: (
  paramIds: string[]
) => ISidebarContent[] = (paramIds) => [
  {
    href: `${R.WAREHOUSES_LIST}/${paramIds[0]}`,

    content: "Informations",
    icon: <i className="fa-solid fa-info opacity-4 "></i>,
  },
  {
    href: `${R.WAREHOUSES_LIST}/${paramIds[0]}/relations`,
    content: "Related warehouse",
    icon: <i className="fa-solid fa-handshake opacity-4  "></i>,
  },
  {
    href: `${R.WAREHOUSES_LIST}/${paramIds[0]}/locations`,
    similarActiveHref: [
      `${R.WAREHOUSES_LIST}/${paramIds[0]}/locations/create`,
      `${R.WAREHOUSES_LIST}/${paramIds[0]}/locations-products/${paramIds[1]}`,
    ],
    content: "Locations",
    icon: <i className="fas fa-truck-fast text-dark opacity-4  "></i>,
  },
];

export const accountEditSidebarContent = (uuid: string): ISidebarContent[] => [
  {
    href: `${R.ACCOUNT_LIST}/${uuid}/edit`,
    content: "Profile",
    icon: <i className="far fa-address-card text-dark opacity-4 me-2"></i>,
  },
  {
    href: `${R.ACCOUNT_LIST}/${uuid}/edit/reset-password`,
    content: "Password",
    icon: <i className="fa fa-key text-dark opacity-4 me-2"></i>,
  },
  {
    href: `${R.ACCOUNT_LIST}/${uuid}/edit/devices`,
    content: "Devices",
    icon: (
      <i className="fa fa-mobile-screen-button text-dark opacity-4 me-2"></i>
    ),
  },
];

export const accountDetailSidebarContent = (
  uuid: string
): ISidebarContent[] => [
  {
    href: `${R.ACCOUNT_LIST}/${uuid}`,
    content: "Profile",
    icon: <i className="fa-solid fa-user text-dark opacity-4 me-2"></i>,
  },
  {
    href: `${R.ACCOUNT_LIST}/${uuid}/devices`,
    content: "Devices",
    icon: <i className="fa-solid fa-mobile text-dark opacity-4 me-2"></i>,
  },
];

export const purchaseOrderEditSidebarContent = (
  uuid: string
): ISidebarContent[] => [
  {
    href: `${R.PURCHASE_ORDERS_LIST}/${uuid}/edit`,
    content: "Informations",
    icon: <i className="fa-solid fa-cart-plus text-dark opacity-4 me-2"></i>,
  },
  {
    href: `${R.PURCHASE_ORDERS_LIST}/${uuid}/edit/packages`,
    content: "Packages",
    icon: <i className="fa-solid fa-box text-dark opacity-4 me-2"></i>,
  },
  {
    href: `${R.PURCHASE_ORDERS_LIST}/${uuid}/edit/products`,
    content: "Products",
    icon: <i className="fa-solid fa-bowl-food text-dark opacity-4 me-2"></i>,
  },
  {
    href: `${R.PURCHASE_ORDERS_LIST}/${uuid}/edit/notes`,
    content: "Notes",
    icon: <i className="fa-solid fa-comments text-dark opacity-4 me-2"></i>,
  },
];

export const saleOrderEditSidebarContent = (
  uuid: string
): ISidebarContent[] => [
  {
    href: `${R.SALE_ORDERS_LIST}/${uuid}/edit`,
    content: "Edit Sale Order",
    icon: <i className="fa-solid fa-cart-plus text-dark opacity-4 me-2"></i>,
  },
  {
    href: `${R.SALE_ORDERS_LIST}/${uuid}/edit/product`,
    content: "Edit Products",
    icon: <i className="fa-solid fa-tag text-dark opacity-4 me-2"></i>,
  },
  {
    href: `${R.SALE_ORDERS_LIST}/${uuid}/edit/shipping-service`,
    content: "Edit Shipping Services",
    icon: <i className="fa-solid fa-truck-fast text-dark opacity-4 me-2"></i>,
  },
];

export const relocationShipmentEditSidebarContent = (
  uuid: string
): ISidebarContent[] => [
  {
    href: `${R.RELOCATION_SHIPMENTS_LIST}/${uuid}/edit`,
    content: "Edit Info",
    icon: <i className="fa-solid fa-cart-plus text-dark opacity-4 me-2"></i>,
  },
  {
    href: `${R.RELOCATION_SHIPMENTS_LIST}/${uuid}/edit/product`,
    content: "Edit Products",
    icon: <i className="fa-solid fa-tag text-dark opacity-4 me-2"></i>,
  },
];

export const pickingEditSidebarContent = (uuid: string): ISidebarContent[] => [
  {
    href: `${R.PICKINGS_LIST}/${uuid}/edit`,
    content: "Informations",
    icon: <i className="fa-solid fa-cart-plus text-dark opacity-4 me-2"></i>,
  },
  {
    href: `${R.PICKINGS_LIST}/${uuid}/edit/task`,
    content: "Picking task",
    icon: <i className="fa-solid fa-list-check text-dark opacity-4 me-2"></i>,
  },
  {
    href: `${R.PICKINGS_LIST}/${uuid}/edit/sale-order`,
    content: "Sale order",
    icon: (
      <i className="fa-solid fa-scale-unbalanced text-dark opacity-4 me-2"></i>
    ),
  },
];
