import { useState, useEffect } from "react";
import UiInput from "../../components/ui-input";
import UiForm from "../../components/ui-form";
import styles from "./styles.module.scss";

const Component = ({
  initKeyword,
  onSearch,
}: {
  initKeyword?: string;
  onSearch: (keyword: string) => any;
}) => {
  const [value, setValue] = useState(initKeyword);
  useEffect(() => setValue(initKeyword), [initKeyword]);
  const handleUpdateValue = (event: any) => setValue(event.target.value);
  const handleClearSearch = () => {
    onSearch("");
    setValue("");
  };

  return (
    <div className={`dataTable-search ${styles.tableSearch}`}>
      <UiForm onSubmit={() => alert}>
        <UiInput
          className={`dataTable-input ${styles.inputSearch}`}
          value={value}
          name={"search"}
          title={"Search..."}
          isDisplayLabel={false}
          onChange={handleUpdateValue}
          onKeyUp={(value: string) => onSearch(value)}
        />
        {!!value && (
          <div
            onClick={handleClearSearch}
            className={styles.clearSearch}
            title="Clear input search"
          ></div>
        )}
      </UiForm>
    </div>
  );
};

export default Component;
