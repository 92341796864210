import classNames from "classnames";
import { useNavigate, Link } from "react-router-dom";

import {
  getTaskLoggings,
  getUnassignedTaskLoggings,
} from "../../apis/paths/taskLogging";
import { TASK_LOGGING_LIST, PURCHASE_ORDERS_LIST } from "../../routings/path";
import { useWindowResize } from "../../hooks/useWindowResize";
import { getPurchaseOrderListApi } from "../../apis/paths/purchase-order";
import TablePanel from "../../components/panel-table";
import ViewTaskDoughnutChart from "../dashboard/view-doughnut-task-status";
import ViewShipingOrderLineChart from "../dashboard/view-shiping-orders-line-chart";

import { convertUnixTimeToRansackFormatTime } from "./utils";
import { overdueTaskSchema } from "./schema/overdueTaskSchema";
import { taskUnassignedSchema } from "./schema/taskUnassignedSchema";
import { upcomingPurchaseOrderSchema } from "./schema/upcomingPurchaseOrderSchema";
import styles from "./styles.module.scss";
import ViewInboundDoughnutChart from "./view-doughnut-inbound-chart";
import ViewOutboundDoughnutChart from "./view-doughnut-outbound-chart";
import ViewTablePanel from "./view-table-panel";

const MAX_ESTIMATION_DAYS = 7;
export const LARGE_SCREEN_SIZE = 1900;
export const MEDIUM_SCREEN_SIZE = 1480;

const Component = () => {
  const navigate = useNavigate();
  const windowWidth = useWindowResize()?.windowWidth || window.innerWidth;
  const isLargeScreen = windowWidth && windowWidth >= LARGE_SCREEN_SIZE;

  const renderUpcomingPOs = () => (
    <TablePanel
      apiPath={getPurchaseOrderListApi}
      isSearchable={false}
      showPerPageSelection={false}
      minHeight={{ tableContainer: 310 }}
      apiParams={{
        per: 5,
        filter: [
          {
            field: "status",
            operator: "in",
            values: ["shipping"],
          },
          {
            field: "estimated_arrival",
            operator: "lteq",
            value: convertUnixTimeToRansackFormatTime(MAX_ESTIMATION_DAYS),
          },
        ],
      }}
      tableSchema={upcomingPurchaseOrderSchema()}
      type="minimal"
      panelClassName="border-0 pt-0"
      modules={{
        header: false,
        search: false,
        pagination: false,
      }}
    />
  );

  const renderUnassignedTasks = () => (
    <>
      <TablePanel
        apiPath={getUnassignedTaskLoggings}
        apiParams={{
          per: 5,
        }}
        minHeight={{ tableContainer: 310 }}
        onReceivedData={(data: any) => {
          if (Array.isArray(data?.list)) {
            if (data?.list?.length === 0) {
              return;
            }
          }
        }}
        showPerPageSelection={false}
        isSearchable={false}
        tableSchema={taskUnassignedSchema({
          navigate,
        })}
        type="minimal"
        panelClassName="border-0 pt-0"
        modules={{
          header: false,
          search: false,
          pagination: false,
        }}
      />
    </>
  );

  const renderOverdueTasks = () => (
    <>
      <TablePanel
        apiPath={getTaskLoggings}
        isSearchable={false}
        showPerPageSelection={false}
        minHeight={{ tableContainer: 310 }}
        apiParams={{
          per: 5,
          filter: [
            {
              field: "due_at",
              operator: "lteq",
              value: convertUnixTimeToRansackFormatTime(0),
            },
            {
              field: "status",
              operator: "not_in",
              values: ["completed", "canceled"],
            },
          ],
        }}
        tableSchema={overdueTaskSchema({
          isLargeScreen: isLargeScreen as boolean,
        })}
        type="minimal"
        panelClassName="border-0 pt-0"
        modules={{
          header: false,
          search: false,
          pagination: false,
        }}
      />
    </>
  );

  return (
    <>
      <div className="row">
        <div className={classNames(isLargeScreen ? "col-3" : "col-6")}>
          <ViewInboundDoughnutChart />
        </div>
        <div className={classNames(isLargeScreen ? "col-3" : "col-6")}>
          <ViewOutboundDoughnutChart />
        </div>
        <div className={classNames(isLargeScreen ? "col-6" : "col-12")}>
          <ViewTaskDoughnutChart />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ViewShipingOrderLineChart />
        </div>
      </div>
      <div className="row">
        <div className={classNames(isLargeScreen ? "col-3" : "col-6")}>
          <ViewTablePanel
            body={renderUpcomingPOs()}
            panel={{
              title: "Upcoming inbound orders",
              subTitle: "Top 5 estimated arrival within 7 days",
              actionArea: () => (
                <Link to={PURCHASE_ORDERS_LIST} className="text-secondary">All</Link>
              ),
            }}
          />
        </div>
        <div className={classNames(isLargeScreen ? "col-4" : "col-6")}>
          <ViewTablePanel
            body={renderUnassignedTasks()}
            panel={{
              title: "Need To Assign",
              subTitle: "The top 5 tasks need to check",
              actionArea: () => (
                <Link to={TASK_LOGGING_LIST} className="text-secondary">All</Link>
              ),
            }}
          />
        </div>
        <div className={classNames(isLargeScreen ? "col-5" : "col-12")}>
          <ViewTablePanel
            body={renderOverdueTasks()}
            panel={{
              title: "Overdue Tasks",
              subTitle: "The top 5 overdue tasks, need to check",
              actionArea: () => (
                <Link to={TASK_LOGGING_LIST} className="text-secondary">All</Link>
              ),
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Component;
