interface PropsType {
  children?: any;
  onSubmit?: (param: any) => any;
  className?: string;
  onKeyDown?: (param: any) => any;
}

const Component = ({
  children = "Submit Button",
  onSubmit = () => {},
  className = "",
  onKeyDown,
}: PropsType) => {
  return (
    <form
      onSubmit={onSubmit}
      autoComplete="off"
      className={className}
      onKeyDown={onKeyDown}
    >
      {children}
    </form>
  );
};

export default Component;
