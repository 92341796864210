import { useState } from "react";
import classNames from "classnames";

import { GENERAL_UI_COLOR } from "../../constants/color";

interface IAlertProps {
  highlightMsg?: string;
  message?: string;
  leftIcon?: string; //This icon should check on https://www.creative-tim.com/learning-lab/bootstrap/icons/argon-dashboard
  rightIcon?: string; //This icon should check on https://www.creative-tim.com/learning-lab/bootstrap/icons/argon-dashboard
  handleClick?: () => void;
  bgColor?: GENERAL_UI_COLOR;
}

const Alert = ({
  highlightMsg,
  message = "This is a dark alert—check it out!",
  leftIcon = "like-2",
  rightIcon,
  handleClick,
  bgColor = GENERAL_UI_COLOR.PRIMARY,
}: IAlertProps) => {
  const [isDisplay, setIsDisplay] = useState(true);

  return (
    <div
      className={classNames(
        ` alert mt-3 alert-${bgColor} alert-dismissible `,
        !isDisplay && "d-none"
      )}
      role="alert"
    >
      <span className="alert-icon text-white m-2">
        <i className={`ni ni-${leftIcon}`}></i>
      </span>
      <span className="text-white">
        {highlightMsg && <strong className="me-2">{highlightMsg}!</strong>}
        {message}
      </span>
      <button
        type="button"
        className="btn-close d-flex align-items-center justify-content-center "
        data-bs-dismiss="alert"
        aria-label="Close"
        onClick={() => {
          setIsDisplay(false);
          handleClick && handleClick();
        }}
      >
        <span className="alert-icon text-white d-flex align-items-center justify-content-center ">
          {rightIcon ? (
            <i className={`ni ni-${rightIcon}`}></i>
          ) : (
            <span aria-hidden="true">&times;</span>
          )}
        </span>
      </button>
    </div>
  );
};

export default Alert;
