import * as R from "../../routings/path";
import { GENERAL_STATUS } from "../../constants/status";
import { numberFormat } from "../../utils/format";

import UiInput from "../../components/ui-input";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import UiProgress from "../../components/ui-progress";

import styles from "./styles.module.scss";

export const productSchema = [
  {
    name: "Product",
    cell: (data: any) => (
      <ProductTableCell
        linkTo={`${R.PRODUCTS_LIST}/${data?.product?.code}`}
        image={data?.product?.image_url}
        title={data?.product?.name}
        description={data?.product?.code}
        imageSize={40}
        maxWidth={200}
      />
    ),
  },
  {
    name: "Missing",
    align: "center",
    textSize: "xs",
    cell: (data: any) => data.missing,
  },
  {
    name: "Process",
    cell: (data: any) => (
      <UiProgress value={(data.processed / data.total) * 100} />
    ),
  },
  {
    name: "Units",
    align: "right",
    textSize: "xs",
    cell: (data: any) =>
      `${numberFormat(data.processed)} / ${numberFormat(data.total)}`,
  },
];

export const packageSchema = ({ onSelectPackageToPrint, onPrint }: any) => [
  {
    name: "",
    width: 110,
    headingAction: (
      <StatusTableCell
        className={styles.packagePrintHeadingAction}
        status={GENERAL_STATUS.PRINT}
        onClick={onPrint}
      />
    ),
    cell: (data: any) => (
      <UiInput
        containerClassName=""
        type="checkbox"
        title={"Checkbox"}
        name={"checkbox"}
        isDisplayLabel={false}
        onChange={() => onSelectPackageToPrint(data)}
      />
    ),
  },
  {
    name: "Package",
    cell: (data: any) => <ProductTableCell title={data?.code} />,
  },
  {
    name: "Size (cm)",
    align: "center",
    textSize: "xs",
    cell: (data: any) =>
      `${numberFormat(data?.width)} x ${numberFormat(
        data?.height
      )} x ${numberFormat(data?.length)}`,
  },
  {
    name: "Status",
    align: "center",
    cell: (data: any) => <StatusTableCell status={data?.status} />,
  },
  {
    name: "Units",
    align: "right",
    textSize: "xs",
    cell: (data: any) => "--",
  },
];
