import { useEffect } from "react";

import { IRoleProps, IUpperRole } from "../../types/roles";
import { detectRequiredlField } from "../../utils/validation";
import UiInput from "../../components/ui-input";
import PanelGeneral from "../../components/panel-general/component";

import { formSchema } from "./formSchema";
import GroupCheckbox from "./view-group-radio";

const EditRoleForm = ({
  setValue,
  register,
  control,
  errors,
  roles,
  rolesList,
}: {
  setValue: any;
  register: any;
  control: any;
  errors: any;
  roles: any;
  rolesList: IRoleProps[];
}) => {
  const { name, description } = roles || {};

  useEffect(() => {
    Object.keys(formSchema?.fields).forEach((field: any) => {
      setValue(field, roles[field]);
    });
  }, [setValue, roles]);

  const handlePreProcessOptionItems = (array: any[]) => {
    if (!array || !array.length) return [];
    return array.map((upperRole: IUpperRole) => ({
      value: upperRole.uuid,
      content: upperRole.name,
    }));
  };

  const allOptionItem = handlePreProcessOptionItems(rolesList);

  return (
    <PanelGeneral
      panel={{
        title: "Role",
      }}
    >
      <UiInput
        className="dataTable-input"
        name={"name"}
        title={"Name"}
        required={detectRequiredlField(formSchema, "name")}
        placeholder={name}
        isDisplayLabel={true}
        register={register}
        errorMessage={errors?.name?.message}
        disabled
      />
      <UiInput
        className="dataTable-input"
        name={"description"}
        required={detectRequiredlField(formSchema, "description")}
        title={"Description"}
        isDisplayLabel={true}
        register={register}
        placeholder={description}
        errorMessage={errors?.description?.message}
        disabled
      />
      <GroupCheckbox
        control={control}
        title={"Status"}
        name="status"
        required={detectRequiredlField(formSchema, "status")}
        errorMessage={errors?.status?.message}
        disabled
      />
    </PanelGeneral>
  );
};

export default EditRoleForm;
