import classNames from "classnames";
import { useState } from "react";

import styles from "./styles.module.scss";

import { getSaleOrderProductListApi } from "../../apis/paths/sale-order";
import { useApiCaller } from "../../apis/config";
import { PRODUCTS_LIST } from "../../routings/path";
import ProductTableCell from "../../components/table-cell-product/component";

const ViewProductSaleOrder = ({ saleOrderUuid }: { saleOrderUuid: string }) => {
  const [params] = useState({ uuid: saleOrderUuid });

  const { result: products } = useApiCaller({
    api: getSaleOrderProductListApi,
    params: params,
  });

  return (
    <div
      className={classNames("d-flex flex-column p-1 pt-1")}
      style={{ backgroundColor: "#fafafb" }}
    >
      {products?.data?.list?.map((product: any) => (
        <div>
          <hr className="horizontal dark mt-1" />
          <ProductTableCell
            linkTo={`${PRODUCTS_LIST}/${product?.code}`}
            image={product?.image_url}
            title={product?.name}
            description={product?.gtin_code}
            imageSize={25}
            className={{ title: styles.customTitle }}
          />
        </div>
      ))}
    </div>
  );
};

export default ViewProductSaleOrder;
