export const CHANNEL = [
  {
    value: "purchase",
    content: "purchase",
  },
  {
    value: "return",
    content: "return",
  },
  {
    value: "relocation",
    content: "relocation",
  },
];

export const ORDER_STATUS = [
  {
    value: "draft",
    content: "Draft",
  },
  {
    value: "shipping",
    content: "Shipping",
  },
];

