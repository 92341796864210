import * as R from "../../../routings/path";
import { formatIconBaseOnChannel } from "../utils";
import { formatDateTime } from "../../../utils/date-time";
import ProductTableCell from "../../../components/table-cell-product";
import StatusTableCell from "../../../components/table-cell-status";

export const upcomingPurchaseOrderSchema = () => [
  {
    name: "Number",
    cell: (data: any) => (
      <div className="d-flex gap-2">
        <div className="d-flex justify-content-center align-items-center">
          {formatIconBaseOnChannel(data?.channel)}
        </div>
        <ProductTableCell
          title={data.number}
          linkTo={`${R.PURCHASE_ORDERS_LIST}/${data.uuid}`}
          description={formatDateTime(data?.estimated_arrival, "lll")}
          maxWidth={200}
        />
      </div>
    ),
  },
  {
    name: "Channel",
    align: "right",
    cell: (data: any) => (
      <StatusTableCell status={data?.channel} />
    ),
  },
];
