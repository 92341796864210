import React, { useEffect, useState } from "react";

import AddTplProductSearchBox from "./view-add-tpl-product-search-box";

const DynamicTplSelectDropdown = ({
  name,
  setValue,
  data,
  selectedLinkType,
  formItem,
}: {
  name:string
  data: any;
  setValue?: any;
  selectedLinkType: string;
  formItem?: any;
}) => {
  
  const [defaultLinkType, setDefaultLinkType] = useState(selectedLinkType);

  useEffect(() => {
    setDefaultLinkType(selectedLinkType);
  }, [selectedLinkType]);

  const [selectedItems, setSelectedItems] = useState<any>();

  const fomatedSelectedProduct = selectedItems?.map(
    (product: any) => product.value
  );

  useEffect(() => {
    if (selectedItems)
      setValue(name, fomatedSelectedProduct);
  }, [selectedItems]);

  const formatDataTypeOption = () => {
    switch (defaultLinkType) {
      case "Warehouse":
        return data?.warehouses?.map((warehouse: any) => ({
          content: warehouse?.name,
          value: warehouse?.uuid,
        }));
      case "Province":
        return data?.provinces?.map((province: any) => ({
          content: province?.name,
          value: province?.uuid,
        }));
      case "District":
        return data?.districts?.map((district: any) => ({
          content: district?.name,
          value: district?.uuid,
        }));
      case "Product":
        return data?.products?.map((product: any) => ({
          content: product?.name,
          value: product?.uuid,
          image:product?.image_url
        }));
      default:
        break;
    }
  };

  return (
    <AddTplProductSearchBox
      selectedLinkType={defaultLinkType}
      optionList={formatDataTypeOption()}
      formItem={formItem}
      setSelectedItems={setSelectedItems}
    />
  );
};

export default DynamicTplSelectDropdown;
