import {
  GENERAL_STATUS,
  SUCCESS_STATUS,
  FAIL_STATUS,
  WARNING_STATUS,
  PRIMARY_STATUS,
  SECONDARY_STATUS,
} from "../../constants/status";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { GENERAL_UI_COLOR } from "../../constants/color";
import UIBadge from "../ui-badge";

interface IStatusTableCell {
  className?: string;
  status?: GENERAL_STATUS;
  size?: GENERAL_UI_SIZE;
  type?: GENERAL_UI_COLOR;
  onClick?: any;
}

const StatusTableCell = ({
  className = "",
  status,
  size = GENERAL_UI_SIZE.SMALL,
  type,
  onClick,
}: IStatusTableCell) => {
  if (!status || !status.length) return null;

  const formattedStatus =
    status
      ?.toLowerCase()
      .replaceAll(" ", "")
      .replaceAll("-", "")
      .replaceAll("_", "")
      .replaceAll(".", "")
      .replaceAll(",", "") || "";

  let combinedType;

  if (!type || !type.length) {
    if (SUCCESS_STATUS.indexOf(formattedStatus as any) >= 0) {
      combinedType = GENERAL_UI_COLOR.SUCCESS;
    } else if (FAIL_STATUS.indexOf(formattedStatus as any) >= 0) {
      combinedType = GENERAL_UI_COLOR.DANGER;
    } else if (WARNING_STATUS.indexOf(formattedStatus as any) >= 0) {
      combinedType = GENERAL_UI_COLOR.WARNING;
    } else if (PRIMARY_STATUS.indexOf(formattedStatus as any) >= 0) {
      combinedType = GENERAL_UI_COLOR.PRIMARY;
    } else if (SECONDARY_STATUS.indexOf(formattedStatus as any) >= 0) {
      combinedType = GENERAL_UI_COLOR.SECONDARY;
    } else {
      combinedType = GENERAL_UI_COLOR.INFO;
    }
  } else {
    combinedType = type;
  }

  return (
    <UIBadge
      content={status}
      type={combinedType}
      size={size}
      className={className}
      onClick={onClick}
    />
  );
};

export default StatusTableCell;
