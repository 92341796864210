import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  getTplRoutesDetailApi,
  getTplRoutesDistrictApi,
  getTplRoutesProductsApi,
  getTplRoutesProvinceApi,
  getTplRoutesWarehousesApi,
} from "../../apis/paths/tpl-routes";
import { TPL_ROUTES } from "../../routings/path";
import { OPTIONS_LENGTH } from "../tpl-routes-edit/constants";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { GENERAL_STATUS } from "../../constants/status";
import { IGetTplRoutesApi } from "../../types/setup";
import { useApiCaller } from "../../apis/config";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import HeaderInfoPanel from "../../components/panel-header-info";
import UiButton from "../../components/ui-button";
import NotFoundPanel from "../../components/panel-not-found";
import UiForm from "../../components/ui-form";
import TableCellStatus from "../../components/table-cell-status";
import GridLayoutTwoColumn from "../../components/grid-layout-two-column/component";

import EditTplFormRouting from "./view-edit-tpl-route-form-routing";
import EditTplFormInfo from "./view-edit-tpl-route-form-info";
import { formSchema } from "./formSchema";

const defaultTplInfo = {
  link: [],
  route: {},
};

const Component = () => {
  const [tplInfo, setTplInfo] = useState<any>(defaultTplInfo);
  const [assets, setAssets] = useState({});
  const [toggleStatus, setToggleStatus] = useState<
    GENERAL_STATUS.ACTIVATE | GENERAL_STATUS.DEACTIVE
  >(GENERAL_STATUS.DEACTIVE);

  const { route } = tplInfo || {};

  const navigate = useNavigate();
  const { routeId } = useParams();
  const id = routeId || "";

  const { result } = useApiCaller({
    api: () => getTplRoutesDetailApi({ id }),
    params: DEFAULT_PAGINATION_PARAMS,
  });

  useEffect(() => {
    setTplInfo(result?.data);
  }, [result]);

  useEffect(() => {
    setToggleStatus(tplInfo?.route?.status);
  }, [tplInfo?.route?.status]);

  const {
    setValue,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: "all",
  });

  useEffect(() => {
    if (!route) return;

    const keys = Object.keys(formSchema?.fields);
    keys.forEach((key: any) => {
      if (key === "priority") {
        const priorityValue = Number(route[key as keyof IGetTplRoutesApi]);
        setValue(
          key,
          priorityValue > OPTIONS_LENGTH ? OPTIONS_LENGTH - 1 : priorityValue
        );
      }

      setValue(key, route[key as keyof IGetTplRoutesApi]);
    });
  }, [result, tplInfo]);

  const { result: warehouses } = useApiCaller({
    api: () => getTplRoutesWarehousesApi({ id }),
    params: DEFAULT_PAGINATION_PARAMS,
  });

  const { result: provinces } = useApiCaller({
    api: () => getTplRoutesProvinceApi({ id }),
    params: DEFAULT_PAGINATION_PARAMS,
  });

  const { result: districts } = useApiCaller({
    api: () => getTplRoutesDistrictApi({ id }),
    params: DEFAULT_PAGINATION_PARAMS,
  });

  const { result: products } = useApiCaller({
    api: () => getTplRoutesProductsApi({ id }),
    params: DEFAULT_PAGINATION_PARAMS,
  });

  useEffect(() => {
    setAssets((prevAsset) => ({
      ...prevAsset,
      ...tplInfo,
      warehouses: warehouses?.data?.list,
      provinces: provinces?.data?.list,
      districts: districts?.data?.list,
      products: products?.data?.list,
    }));
  }, [tplInfo, warehouses, provinces, districts, products]);

  if (tplInfo?.status === "ERROR") return <NotFoundPanel />;

  return (
    <>
      <UiForm>
        <div className="mb-4">
          <HeaderInfoPanel
            isDisplayInfoArea={true}
            title={route?.name}
            description="Here you can setup and manage your 3PLs"
            image={tplInfo?.avatar}
            infoArea={() => (
              <TableCellStatus
                status={toggleStatus}
                size={GENERAL_UI_SIZE.SMALL}
              />
            )}
            actionArea={() => (
              <UiButton
                className="me-2"
                title={"Edit 3PLs"}
                onClick={() => {
                  navigate(`${TPL_ROUTES}/${id}/edit`);
                }}
              />
            )}
          />
        </div>
        <GridLayoutTwoColumn
          className={{ container: "px-0" }}
          widthDivision={[4, 8]}
          leftCol={
            <EditTplFormInfo
              data={tplInfo}
              errors={errors}
              register={register}
              setValue={setValue}
            />
          }
          rightCol={
            <EditTplFormRouting
              data={assets}
              errors={errors}
              register={register}
              setValue={setValue}
            />
          }
        />
      </UiForm>
    </>
  );
};

export default Component;
