import classNames from "classnames";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { GENERAL_UI_SIZE } from "../../constants/size";
import UiButton from "../ui-button/component";

interface IDownloadButton {
  title?: string | React.ReactNode;
  href?: string;
  downloadTitle?: string;
  className?: {
    container?: string;
    atag?: string;
    button?: string;
  };
}

const DownloadButton = ({
  title = "Download File",
  href,
  className = {
    container: "",
    atag: "",
    button: "",
  },
  downloadTitle = "Download file",
}: IDownloadButton) => {
  return (
    <div className={classNames(className.container)}>
      <a
        href={href}
        download={downloadTitle}
        target="_blank"
        rel="noreferrer"
        className={classNames(className.atag)}
      >
        <UiButton
          customType="button"
          title={title}
          className={classNames(className.button)}
          size={GENERAL_UI_SIZE.XSMALL}
          type={GENERAL_UI_COLOR.SECONDARY}
        />
      </a>
    </div>
  );
};
export default DownloadButton;
