/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { GENERAL_UI_COLOR } from "../../constants/color";
import { useFilterAssignees } from "../../hooks/useFilterAssignee";
import Avatar from "../ui-avatar/component";
import UiTooltip from "../ui-tooltip/component";
import Typography from "../ui-typography/component";
import InputSearch from "../search-input/component";

import { IFilterField, IFilterOptions, SelectedOptions } from "./component";
import styles from "./styles.module.scss";

const MAX_ASSIGNEES_DISPLAY = 7;

interface IAssigneeFilter {
  filterField: IFilterField;
  onToggleOption: any;
  filterKey: string;
  selectedOptions: SelectedOptions;
}

const AssigneeFilter = ({
  onToggleOption,
  filterField,
  selectedOptions,
  filterKey,
}: IAssigneeFilter) => {
  const [searchValue, setSearchValue] = useState("");
  const [isSearchMode, setIsSearchMode] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const assigneeApiPath = filterField?.assigneeApiPath;

  const {
    fetchUserAssignees,
    searchUserAssignees,
    filteredAssignees,
    addAssigneesToList,
  } = useFilterAssignees();

  const { searchingOptions, options: allAssignees } = filteredAssignees || {};

  useEffect(() => {
    fetchUserAssignees(assigneeApiPath);
  }, []);

  useEffect(() => {
    searchUserAssignees({
      apiPath: assigneeApiPath,
      keyword: searchValue,
    });
  }, [searchValue]);

  const selectedAssigneeUuid = selectedOptions[filterKey]?.values || [];
  const nonSelectedAssignees = allAssignees?.filter(
    (assignee: IFilterOptions) => !selectedAssigneeUuid.includes(assignee.value)
  );
  const preOrderSelectedToFirstAssignees = allAssignees
    ?.filter((assignee: IFilterOptions) =>
      selectedAssigneeUuid?.includes(assignee?.value)
    )
    .concat(nonSelectedAssignees);

  return (
    <>
      {preOrderSelectedToFirstAssignees
        .slice(0, MAX_ASSIGNEES_DISPLAY)
        .map((option) => (
          <UiTooltip
            tooltip={
              <Typography
                customFontsize={13}
                fontWeight="fw-normal"
                textColor="black"
                className="text-start text-nowrap"
              >
                {option?.content}
              </Typography>
            }
          >
            <div
              key={option?.value}
              className="d-flex align-items-center justify-content-start"
              onClick={() => {
                onToggleOption({
                  field: filterField?.field,
                  value: option?.value,
                  operatorMatcher: filterField?.operator as string,
                });
                addAssigneesToList({
                  value: option?.value,
                  content: option?.content,
                  userDetail: {
                    imageUrl: option?.userDetail?.imageUrl,
                  },
                });
              }}
            >
              <div
                className={classNames(
                  styles.avatarGroup,
                  selectedOptions[filterField?.field]?.values?.includes(
                    option?.value
                  ) && styles.highlightItem
                )}
              >
                <Avatar
                  imageSrc={option?.userDetail?.imageUrl as string}
                  allowPropagation
                  allowDynamicBackgroundColor
                  isCircleRoundedImage
                  name={option?.content}
                  variant={GENERAL_UI_COLOR.SECONDARY}
                  fixSize={32}
                />
                {selectedOptions[filterField?.field]?.values?.includes(
                  option?.value
                ) && (
                  <i
                    className={classNames(
                      "fa-solid fa-circle-check text-primary",
                      styles.selectedIcon
                    )}
                  ></i>
                )}
              </div>
            </div>
          </UiTooltip>
        ))}

      {preOrderSelectedToFirstAssignees?.length > MAX_ASSIGNEES_DISPLAY && (
        <div
          className={classNames(
            "d-flex justify-content-center align-middle align-items-center rounded-circle",
            styles.moreUserButton
          )}
          style={{ width: 33, height: 33 }}
        >
          <div
            className={`h-100 w-100 ${styles.viewMoreUser}`}
            onClick={() => {
              setIsSearchMode((prev) => {
                if (!prev) {
                  if (inputRef.current) {
                    inputRef.current.focus();
                    setSearchValue("");
                  }
                }
                return !prev;
              });
            }}
          >
            <Typography
              className="text-center text-white"
              fontWeight="fw-bolder"
            >
              ...
            </Typography>
          </div>
          <div
            className={classNames(
              styles.overlayAction,
              !isSearchMode && "d-none"
            )}
          >
            <div>
              <InputSearch
                ref={inputRef}
                value={searchValue}
                setValue={setSearchValue}
                className={{ container: "p-2" }}
                placeholder="Type assignee name"
                style={{ backgroundColor: "white" }}
              />
            </div>
            <div className="d-flex w-100 flex-column">
              {searchingOptions
                ?.filter(
                  (searchingOption) =>
                    !selectedAssigneeUuid.includes(searchingOption.value)
                )
                ?.map((assignee: IFilterOptions) => (
                  <div
                    className={classNames(
                      "w-100  d-flex align-items-center gap-2 py-2 px-3 text-start",
                      styles.selectAssignee
                    )}
                    key={assignee?.value}
                    onClick={() => {
                      addAssigneesToList(assignee);
                      onToggleOption({
                        field: filterField?.field,
                        value: assignee?.value,
                        operatorMatcher: filterField?.operator as string,
                      });
                      setIsSearchMode(false);
                    }}
                  >
                    <Avatar
                      imageSrc={assignee?.userDetail?.imageUrl as string}
                      allowPropagation
                      allowDynamicBackgroundColor
                      isCircleRoundedImage
                      name={assignee?.content}
                      fixSize={26}
                    />
                    <Typography>{assignee?.content}</Typography>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AssigneeFilter;
