import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as R from "../../routings/path";
import { useApiCaller } from "../../apis/config";
import SmallCollumnCenterGridLayout from "../../components/grid-layout-small-collumn-center";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import HeaderInfoPanel from "../../components/panel-header-info";
import NotFoundPanel from "../../components/panel-not-found";
import UiLoadingPanel from "../../components/ui-loading-panel";
import GeneralPanel from "../../components/panel-general";
import UiButton from "../../components/ui-button";
import UiInput from "../../components/ui-input";
import UiSelectDropdown, {
  IOptionItem,
} from "../../components/ui-select-dropdown/component";

import { schema } from "./schema";
import { BOX_TYPE, IOption, SIZE } from "./constants";
import { getMaterialDetailApi } from "../../apis/paths/material";

const Component = () => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const { materialId: code } = useParams();
  const [params] = useState({ code });

  const { result, loading } = useApiCaller({
    api: getMaterialDetailApi,
    params: params,
  });

  const materialInfo = result?.data;

  useEffect(() => {
    if (!result || result?.status === "ERROR") return;

    const materialInfo = result?.data;

    const keys = Object.keys(schema?.fields);
    keys.forEach((key: any) => {
      if (key !== "upload_image") setValue(key, materialInfo[key]);
    });
  }, [result]);

  const formatOptionItems = (data: IOption[]) =>
    data.map((option) => ({
      value: option.value,
      content: option.title,
    }));

  if (loading) return <UiLoadingPanel />;

  if (result?.status === "ERROR") return <NotFoundPanel />;

  return (
    <>
      <HeaderInfoPanel
        title={materialInfo?.name}
        image={materialInfo?.image_url}
        actionArea={() => (
          <>
            <UiButton
              onClick={() => {
                navigate(`${R.MATERIAL_LIST}/${code}/edit`);
              }}
              className="me-2"
              title={"Edit"}
            />
          </>
        )}
      />
      <SmallCollumnCenterGridLayout>
        <GeneralPanel panel={{ subTitle: "Material Information" }}>
          <TwoColumnGridLayout
            className={{ container: "px-0" }}
            leftCol={
              <>
                <UiInput
                  title={"Name"}
                  name={"name"}
                  disabled
                  register={register}
                  errorMessage={errors?.name?.message}
                />
                <UiSelectDropdown
                  name="size"
                  title="Size"
                  isDisplayLabel
                  disabled
                  register={register}
                  optionList={formatOptionItems(SIZE) as IOptionItem[]}
                  errorMessage={errors?.size?.message}
                />
                <UiSelectDropdown
                  name="box_type"
                  title="Box type"
                  isDisplayLabel
                  disabled
                  register={register}
                  optionList={formatOptionItems(BOX_TYPE) as IOptionItem[]}
                  errorMessage={errors?.box_type?.message}
                />
              </>
            }
            rightCol={
              <>
                <UiInput
                  title={"Height"}
                  name={"height"}
                  disabled
                  register={register}
                  errorMessage={errors?.height?.message}
                />
                <UiInput
                  title={"Length"}
                  name={"length"}
                  disabled
                  register={register}
                  errorMessage={errors?.length?.message}
                />
                <UiInput
                  title={"Width"}
                  name={"width"}
                  disabled
                  register={register}
                  errorMessage={errors?.width?.message}
                />
              </>
            }
          />
        </GeneralPanel>
      </SmallCollumnCenterGridLayout>
    </>
  );
};

export default Component;
