import React from "react";
import classNames from "classnames";

import { GENERAL_UI_SIZE } from "../../constants/size";
import { GENERAL_UI_COLOR } from "../../constants/color";

interface IBadgeProps {
  notificationQty?: number;
  content?: string | React.ReactNode;
  onClick?: (param?: any) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  size?: GENERAL_UI_SIZE;
  type?: GENERAL_UI_COLOR;
  buttonType?: "submit" | "button";
  className?:string
}

const Badge = ({
  notificationQty,
  content,
  onClick,
  isLoading = false,
  isDisabled = false,
  size = GENERAL_UI_SIZE.MEDIUM,
  type = GENERAL_UI_COLOR.PRIMARY,
  buttonType='button',
  className
}: IBadgeProps) => {
  if (!content) return null;

  return (
    <>
      <button
        type={buttonType}
        onClick={onClick}
        disabled={isDisabled || isLoading}
        className={classNames(
          `btn position-relative bg-gradient-${type} btn-${size} `
        ,className)}
      >
        <span>{isLoading ? "...Loading" : content}</span>
        {!!notificationQty && (
          <span className="custom-badge badge badge-primary position-absolute top-0 end-n1 text-primary">
            {notificationQty}
          </span>
        )}
      </button>
    </>
  );
};

export default Badge;
