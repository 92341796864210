import { useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface IComponent {
  tabs: Array<string>;
  selected?: number;
  className?: string;
  onChange?: (index: number, data?: any) => any;
}

const Component = ({
  tabs,
  selected,
  className = "",
  onChange,
}: IComponent) => {
  const [active, setActive] = useState(selected || 0);
  const handleChangeTab = (newActive: number, data?: any) => {
    if (newActive === active) return;

    setActive(newActive);
    onChange && onChange(newActive, data);
  };

  return (
    <ul
      className={classNames(
        `nav nav-pills nav-fill py-1 px-2 ms-2`,
        styles.container,
        className
      )}
      role="tablist"
    >
      {tabs.map((tab, index) => (
        <li className="nav-item cursor-pointer" role="presentation">
          <div
            className={`nav-link mb-0 px-4 py-1 active ${styles.item} ${
              index === active && styles.selectedItem
            }`}
            data-bs-toggle="tab"
            role="tab"
            aria-controls="profile"
            aria-selected="true"
            onClick={() => handleChangeTab(index, tab)}
          >
            {tab}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default Component;
