import React, { useEffect } from "react";
import Select, { StylesConfig } from "react-select";
import classNames from "classnames";

import Typography from "../../components/ui-typography/component";

export interface IOptionItem {
  value?: number;
  content?: string;
}

interface IFormItem {
  selectedIds?: any[];
}

const SearchedItem = ({ option }: { option: IOptionItem }) => (
  <div
    className={classNames("d-flex gap-2 align-items-center cursor-pointer p-2")}
    key={option?.value}
  >
    <Typography> {option?.content}</Typography>
  </div>
);

const ComboBoxSearch = ({
  setSelectedItems,
  formItem,
  errorMessage,
  optionList,
  isDisabled,
  required = false,
  size = "normal",
  isDisplayLabel = true,
  title = "",
  onChange,
  className = {
    label: "",
    select: "",
  },
}: {
  setSelectedItems?: any;
  formItem: IFormItem;
  errorMessage?: any;
  optionList: any;
  isDisabled?: boolean;
  required?: boolean;
  size?: "small" | "normal";
  isDisplayLabel?: boolean;
  title?: string;
  className?: {
    label?: string;
    select?: string;
  };
  onChange?: (params: any) => void;
}) => {
  const controlStyles: StylesConfig<any, true> = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: "white",
      width: "100%",
      borderRadius: "0.5rem",
    }),
    menuList: (base: any) => ({
      ...base,
      maxHeight: "250px",
      borderRadius: "0.5rem",
      fontSize: "13px",
    }),
    menuPortal: (base, _props) => ({ ...base, zIndex: 9999 }),
  };

  const mappedOptions = optionList?.map((option: any) => ({
    value: option.value,
    label: option.content,
    customComponent: <SearchedItem option={option} />,
  }));

  const defaultOpitonValue = mappedOptions?.filter((option: any) => {
    return formItem?.selectedIds?.includes(Number(option.value));
  });

  const CustomOption = ({ innerProps, data }: any) => (
    <div {...innerProps}>{data.customComponent && data.customComponent}</div>
  );

  useEffect(() => {
    if (defaultOpitonValue && defaultOpitonValue?.length > 0) {
      setSelectedItems?.(defaultOpitonValue);
    }
  }, []);

  return (
    <div className="mb-3">
      {isDisplayLabel && (
        <label data-required={required} className={classNames(className.label)}>
          {title}
        </label>
      )}

      <Select
        menuPortalTarget={document.body}
        options={mappedOptions}
        components={{ Option: CustomOption }}
        isSearchable
        styles={controlStyles}
        isMulti
        className={classNames(className.select)}
        isDisabled={isDisabled}
        defaultValue={defaultOpitonValue}
        onChange={(choice: any) => {
          onChange?.(choice);
          setSelectedItems?.(choice);
        }}
      />
      {errorMessage && errorMessage.length && (
        <label className="mb-3" style={{ color: "#f5365c" }}>
          {errorMessage}
        </label>
      )}
    </div>
  );
};

export default ComboBoxSearch;
