import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { TCapacityUnit, TGtinType, TStrategy } from "../../types/product";
import { generateUrlParams } from "../../utils/url";
import { get, patch, del, post } from "../config";

interface IGetProductListApi {
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
  filter?: object | null;
}

export const getProductListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
  filter = DEFAULT_PAGINATION_PARAMS.filter,
}: IGetProductListApi) =>
  get({
    path: `/wms/products${generateUrlParams({
      page,
      per,
      keyword,
      sort,
      filter,
    })}`,
  });

interface IGetProductDetailApi {
  code: string;
}

export const getProductDetailApi = ({ code }: IGetProductDetailApi) =>
  get({ path: `/wms/products/${code}` });

export const getStockProductDetailApi = ({ code }: IGetProductDetailApi) =>
  get({ path: `/wms/products/${code}/stock_by_warehouse` });

export const getStockProductByWarehouseApi = ({
  code,
  warehouse_uuid,
}: {
  code: string;
  warehouse_uuid: string;
}) =>
  get({ path: `/wms/products/${code}/stock_by_warehouse/${warehouse_uuid}` });

export const getProductStockHistoryApi = ({
  code,
  warehouse_uuid,
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
}: {
  code: string;
  warehouse_uuid: string;
  page?: number;
  per?: number;
}) =>
  get({ path: `/wms/products/${code}/stock_histories${generateUrlParams({ page, per, warehouse_uuid})}` });

interface IProductType {
  id: number;
}
export interface IUpdateProductDetailApi {
  unit?: string;
  name: string;
  short_name: string;
  description: string;
  code?: string;
  reference_code: string;
  gtin_code?: string;
  gtin_type?: TGtinType;
  capacity_value: string;
  capacity_unit: TCapacityUnit;
  display_width: number;
  display_length: number;
  display_height: number;
  packaging_weight: number;
  packaging_width: number;
  packaging_height: number;
  packaging_length: number;
  image_url: string;
  status: string;
  product_types: IProductType[];
  upload_image: any;
  strategy: TStrategy;
}

export interface ICreateProductApi {
  reference_code: string;
  image_url: string;
  status: string;
  name: string;
  product_types: IProductType[];
  description: string;
  short_name: string;
  capacity_unit: TCapacityUnit;
  capacity_value: string;
  packaging_weight: number;
  packaging_width: number;
  packaging_height: number;
  packaging_length: number;
  display_width: number;
  display_length: number;
  display_height: number;
  upload_image: any;
  strategy: TStrategy;
}

export const updateProductDetailApi = ({
  code,
  data,
}: {
  code: string;
  data: any;
}) =>
  patch({
    path: `/wms/products/${code}`,
    data,
    isFormData: true,
  });

export const createProductApi = (data: any) =>
  post({
    path: `/wms/products`,
    data,
    isFormData: true,
  });
interface IToggleProductStatusApi {
  code: string;
}

export const toggleProductStatusApi = ({ code }: IToggleProductStatusApi) =>
  patch({ path: `/wms/products/${code}/status_toggle` });

interface IDeleteProductApi {
  code: string;
}

export const deleteProductApi = ({ code }: IDeleteProductApi) =>
  del({ path: `/wms/products/${code}` });

// ----------------------- PRODUCT TYPE --------------------------

export interface IUpdateProductTypeApi {
  id: string | number;
  name: string;
  description: string;
  strategy: TStrategy;
}

export interface ICreateProductTypeApi {
  name: string;
  description: string;
  strategy: TStrategy;
}

export const getProductTypeListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
}: IGetProductListApi) =>
  get({
    path: `/wms/product_types${generateUrlParams({ page, per })}`,
  });

export const getDeletedProductTypeListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
}: IGetProductListApi) =>
  get({
    path: `/wms/product_types/deleted${generateUrlParams({ page, per })}`,
  });

export const deleteProductTypeApi = ({ id }: { id: string }) =>
  del({ path: `/wms/product_types/${id}` });

export const restoreProductTypeApi = ({ id }: { id: string }) =>
  patch({ path: `/wms/product_types/${id}/restore` });

export const getProductTypeDetailApi = ({ id }: { id: string }) =>
  get({ path: `/wms/product_types/${id}` });

export const updateProductTypeApi = ({
  id,
  name,
  description,
  strategy,
}: IUpdateProductTypeApi) =>
  patch({
    path: `/wms/product_types/${id}`,
    data: {
      name,
      description,
      strategy,
    },
  });

export const createProductTypeApi = ({
  name,
  description,
  strategy,
}: ICreateProductTypeApi) =>
  post({
    path: `/wms/product_types`,
    data: {
      name,
      description,
      strategy,
    },
  });
