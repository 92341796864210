export enum ACTIONABLE_TYPE {
  FULL = "full",
  IMPORT = "import",
  INDEX = "index",
  NEW = "new",
  EDIT = "edit",
  DESTROY = "destroy",
  SHOW = "show",
  PRINT = "print",
  PICK = "pick",
  PACK = "pack",
}

interface ACTIONABLE_ACTION_TYPE {
  actionable: boolean;
  message: Array<string>;
}

export interface ACTIONABLE_ACTIONS {
  [ACTIONABLE_TYPE.FULL]?: ACTIONABLE_ACTION_TYPE;
  [ACTIONABLE_TYPE.IMPORT]?: ACTIONABLE_ACTION_TYPE;
  [ACTIONABLE_TYPE.INDEX]?: ACTIONABLE_ACTION_TYPE;
  [ACTIONABLE_TYPE.NEW]?: ACTIONABLE_ACTION_TYPE;
  [ACTIONABLE_TYPE.EDIT]?: ACTIONABLE_ACTION_TYPE;
  [ACTIONABLE_TYPE.DESTROY]?: ACTIONABLE_ACTION_TYPE;
  [ACTIONABLE_TYPE.SHOW]?: ACTIONABLE_ACTION_TYPE;
  [ACTIONABLE_TYPE.PRINT]?: ACTIONABLE_ACTION_TYPE;
  [ACTIONABLE_TYPE.PICK]?: ACTIONABLE_ACTION_TYPE;
  [ACTIONABLE_TYPE.PACK]?: ACTIONABLE_ACTION_TYPE;
}
