import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { generateUrlParams } from "../../utils/url";
import { get, post } from "../config";

export const getStickerListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
  filter = DEFAULT_PAGINATION_PARAMS.filter,
}: {
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
  filter?: object | null;
}) =>
  get({
    path: `/wms/stickers${generateUrlParams({
      page,
      per,
      keyword,
      sort,
      filter,
    })}`,
  });

export const createStickerApi = ({ quantity }: { quantity: number }) =>
  post({
    path: `/wms/stickers`,
    data: { quantity },
  });

export const printStickerApi = ({ batch }: { batch: string }) =>
  post({
    path: `/wms/stickers/${batch}/print`,
    isFormData: true,
  });
