import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  deleleAccountApi,
  getAccountListApi,
  getDeletedAcccountListApi,
  restoreAccountApi,
  toogleAccountStatusApi,
} from "../../apis/paths/accounts";
import { apiCaller, useApiCaller } from "../../apis/config";
import { ACCOUNT_CREATE } from "../../routings/path";
import alert from "../../utils/alert";

import { GENERAL_STATUS } from "../../constants/status";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { ACTIONABLE_TYPE } from "../../constants/actionable";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";

import TablePanel from "../../components/panel-table";
import UiTab from "../../components/ui-tab";

import { schema } from "./schema";
import { deletedAccountSchema } from "./deletedAccountSchema";

interface IStatusState {
  [key: string]: GENERAL_STATUS.ACTIVATE | GENERAL_STATUS.DEACTIVE;
}

const Component = () => {
  const navigate = useNavigate();
  const [pageReloadVersion, setPageReloadVersion] = useState(1);
  const [accountListData, setAccountListData] = useState([]);
  const [currentActiveTab, setCurrentActiveTab] = useState(0);

  const { result: accountList } = useApiCaller({
    api: getAccountListApi,
    params: DEFAULT_PAGINATION_PARAMS,
    pageReloadVersion,
  });

  useEffect(() => {
    setAccountListData(accountList?.data?.list);
  }, [accountList]);

  const resultObj: IStatusState = {};
  accountListData?.forEach(
    ({
      uuid,
      status,
    }: {
      uuid: string;
      status: GENERAL_STATUS.ACTIVATE | GENERAL_STATUS.DEACTIVE;
    }) => {
      resultObj[uuid] = status;
    }
  );

  const [accountStatus, setAccountStatus] = useState<IStatusState>(resultObj);

  useEffect(() => {
    setAccountStatus(resultObj);
  }, [accountListData]);

  const handleDeleteAccount = async (uuid: string) => {
    const waitDelete = await apiCaller({
      api: deleleAccountApi,
      params: { uuid },
    });

    if (waitDelete.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Delete successful!");

      setPageReloadVersion((prev) => prev + 1);
      return;
    }

    alert.error("Delete was unsuccessful!. Please try again");
  };

  const handleToggleStatus = async (uuid: string) => {
    const waitToggle = await apiCaller({
      api: toogleAccountStatusApi,
      params: { uuid },
    });

    if (waitToggle.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update status successful!");

      setAccountStatus((prevState) => ({
        ...prevState,
        [uuid]:
          prevState[uuid] === GENERAL_STATUS.ACTIVATE
            ? GENERAL_STATUS.DEACTIVE
            : GENERAL_STATUS.ACTIVATE,
      }));

      return;
    }
    alert.error("Change status was not successful!. Please try again");
  };

  const handleRestoreAccountApi = async (uuid: string) => {
    const waitRestore = await apiCaller({
      api: restoreAccountApi,
      params: { uuid },
    });

    if (waitRestore.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Restore account successful!");
      setPageReloadVersion((prev) => prev + 1);
      return;
    }
    alert.error("Restore account was not successful!. Please try again");
  };

  const handleToggleUiTab = (newActiveTab: number) => {
    setCurrentActiveTab(newActiveTab);
    setPageReloadVersion((prev) => prev + 1);
  };

  return currentActiveTab === 0 ? (
    <TablePanel
      apiPath={getAccountListApi}
      pageReloadVersion={pageReloadVersion}
      panel={{
        title: "Members",
        description: "Members access management",
        actionableButtons: [
          {
            actionableType: ACTIONABLE_TYPE.NEW,
            title: "Invite member",
            onClick: () => navigate(ACCOUNT_CREATE),
          },
        ],

        actionArea: () => (
          <>
            <UiTab
              tabs={["In use", "Deleted"]}
              selected={currentActiveTab}
              onChange={handleToggleUiTab}
            />
          </>
        ),
      }}
      tableSchema={schema({
        onDelete: handleDeleteAccount,
        onToggle: handleToggleStatus,
        accountStatus,
      })}
    />
  ) : (
    <TablePanel
      apiPath={getDeletedAcccountListApi}
      pageReloadVersion={pageReloadVersion}
      panel={{
        title: "Deleted warehouses",
        description: "List of deleted warehouse in the system",
        actionArea: () => (
          <UiTab
            tabs={["In use", "Deleted"]}
            selected={currentActiveTab}
            onChange={handleToggleUiTab}
          />
        ),
      }}
      tableSchema={deletedAccountSchema({
        onRestore: handleRestoreAccountApi,
      })}
    />
  );
};

export default Component;
