import React, { CSSProperties, forwardRef } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface IInputSearch {
  placeholder?: string;
  value: string;
  setValue: any;
  className?: {
    container?: string;
    searchInput?: string;
  };
  position?: {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
  };
  style?: CSSProperties;
  disabled?: boolean;
  isDisplayCloseButton?: boolean;
  ref?: any;
}
const InputSearch = forwardRef<any, IInputSearch>(
  (
    {
      placeholder,
      value,
      setValue,
      className = { container: "p-2", searchInput: "" },
      position = { top: 15, left: 15 },
      style,
      disabled = false,
      isDisplayCloseButton = true,
    }: IInputSearch,
    ref
  ) => {
    return (
      <div className={classNames(className?.container, "position-relative")}>
        <i
          className={classNames(
            "fa-solid fa-magnifying-glass position-absolute ",
            styles.searchIcon
          )}
        ></i>
        <div>
          <input
            className={classNames(styles.searchInput, className?.searchInput)}
            autoComplete="new-password" // using this instead of 'off' due to some case not works as expected
            placeholder={placeholder || "Search"}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            ref={ref}
            style={style}
            disabled={disabled}
          />
        </div>
        {isDisplayCloseButton && (
          <i
            className={classNames(
              "fa-solid fa-circle-xmark position-absolute cursor-pointer fa-sm opacity-6",
              styles.resetIcon
            )}
            onClick={() => {
              setValue("");
            }}
          ></i>
        )}
      </div>
    );
  }
);

export default InputSearch;
