import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { GENERAL_UI_SIZE } from "../../constants/size";
import {
  apiCaller,
  handleErrorToastMessageAlert,
  useApiCaller,
} from "../../apis/config";
import {
  IDeletePackingSaleOrderMaterialApi,
  IDeletePackingSaleOrderPackageApi,
  ISplitingPackageApi,
  IUpdatePackageApi,
  IaddMaterialsToPackingListApi,
  addMaterialsToPackingListApi,
  createSplitingPackedApi,
  deletePackingSaleOrderMaterialApi,
  deletePackingSaleOrderPackageApi,
  getPackingDetailApi,
  getPackingSaleOrderDetailApi,
  getSaleOrderPackagesApi,
  printSaleOrdersLabelApi,
  printSaleOrdersPackagesLabelApi,
  printSaleOrdersProductsPackagesApi,
  updatePackedApi,
} from "../../apis/paths/packing";
import {
  IUpdateSaleOrderStatusApi,
  updateSaleOrderStatusApi,
} from "../../apis/paths/sale-order";
import { areObjectsEqual } from "../../utils/compare";
import { API_RESPONSE_STATUS } from "../../constants/api";
import UiSelectDropdown, {
  IOptionItem,
} from "../../components/ui-select-dropdown/component";
import { printProductCustomerMessageApi } from "../../apis/paths/labels";
import { useWindowResize } from "../../hooks/useWindowResize";
import { LARGE_SCREEN_SIZE } from "../dashboard/component";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { PACKINGS_LIST } from "../../routings/path";
import { usePrinting } from "../../hooks/usePrinting";
import { useKeyPress } from "../../hooks/useTypingLetterSelection";

import alert from "../../utils/alert";
import giftImg from "../../styles/images/gift-box.png";
import GeneralPanel from "../../components/panel-general";
import HeaderInfoPanel from "../../components/panel-header-info";
import TableCellStatus from "../../components/table-cell-status";
import GeneralHeading from "../../components/ui-general-heading/component";
import UiButton from "../../components/ui-button/component";
import PanelNote from "../../components/panel-note/component";
import UiTooltip from "../../components/ui-tooltip/component";
import Typography from "../../components/ui-typography/component";
import NotFoundPanel from "../../components/panel-not-found/component";
import TableDataPanel from "../../components/panel-table-data";
import useConfiguration from "../../hooks/useConfiguration";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import useHandleSidebarStatus from "../../hooks/useSidebarStatus";
import PanelLabelController from "../../components/ui-panel-label-controller/component";
import useBarCodeScanDetection from "../../hooks/useBarCodeScanDetection";
import QuickActionPopupConfirmation from "../../components/popup-quick-aciton-confirmation/component";

import {
  IExceededProduct,
  IHandlePacking,
  IHandleSplitingPackage,
  IPackagePrinting,
  IProductSaleOrder,
  IProductState,
  IScannedProduct,
  TGroupedProducts,
} from "./types";
import {
  findObjectsWithExcessScannedQty,
  flatenedProductsOfAllPackages,
  getAllQtyProcessingProductsBaseOnProductState,
  getAllRemainingQtyProductsBaseOnProductState,
  isSaleOrderHasGiftLabel,
  isScannedAllProductOnAllLocations,
  transformObject,
} from "./utils";
import { useProcessingProductSchema } from "./schema/useProcessingProductSchema";
import { confirmationSchema } from "./schema/confirmationSchema";
import { packedProductSchema } from "./schema/packedProductSchema";
import { useRemainingProductSchema } from "./schema/useRemainingProductSchema";

import styles from "./styles.module.scss";
import PackagesList from "./view-packages";
import ViewActionButtons from "./view-action-buttons";
import ViewAddressSection from "./view-address-section";
import ViewAutoPrintSection from "./view-auto-print-section";
import PackingConfirmationPopup from "./view-popup-packing-confirmation";

const initialExceededProduct = {
  location: "",
  gtin_code: "",
  actual_quantity: 0,
  expected_quantity: 0,
};

export type TProductWithLocation = Record<string, IProductState>;

const DEEP_LINK_PREFIX = "lhs://ml#";

const Component = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { settingState } = useConfiguration();
  const { isPackingAutoPrintable, isPackingProductListPrintable } =
    settingState || {};

  const urlSearchParams = new URLSearchParams(location?.search);
  const gtinCodeGetFromScannedProductPage = urlSearchParams.get("productCode");

  const [pageReloadVersion, setPageReloadVersion] = useState(1);
  const [nextSaleOrderId, setNextSaleOrderId] = useState<string | null>(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const [
    updatedProductsSyncWithPackedProducts,
    setUpdatedProductsSyncWithPackedProducts,
  ] = useState<any>([]);
  const [
    cachedProductSyncWithPackedProducts,
    setcachedProductSyncWithPackedProducts,
  ] = useState<any>(null);

  const [currentProductInPackagesQty, setCurrentProductInPackagesQty] =
    useState<any>();

  const isProcessAutoPrinting = isPackingAutoPrintable || false;
  const [hasAppeared, setHasAppeared] = useState(false);
  const [isCancelAutoPrint, setIsCancelAutoPrint] = useState(false);
  const [isDisplayCoutdownAutoPrint, setIsDisplayCoutdownAutoPrint] =
    useState(true);

  const [mismatchQtyProducts, setMismatchQtyProducts] = useState([]);
  const [isShowPackingPopupConfirmation, setIsShowPackingPopupConfirmation] =
    useState(false);
  const [isShowAlertExceedQty, setIsShowAlertExceedQty] = useState(false);
  const [qrCode, setQrCode] = useState<string>("");
  const [qrCodeScanningTime, setQrCodeScanningTime] = useState(0);
  const [enclosedLabels, setEnclosedLabels] = useState([]);
  const [
    hasSetDefaultForFirstTimeRedirectFromScannedPage,
    setHasSetDefaultForFirstTimeRedirectFromScannedPage,
  ] = useState(false);
  const [exceededProducts, setExceededProducts] = useState<IExceededProduct>(
    initialExceededProduct
  );
  const [locationOfProductIndex, setLocationOfProductIndex] = useState<
    Record<string, number>
  >({});

  const [newScannedProductPackage, setNewScannedProductPackage] = useState<
    string[]
  >([]);

  const topPanelRef = useRef<HTMLDivElement | null>(null);
  const latestScannedQtyMaterial = useRef<string | null>(null);
  const latestScannedQtyProduct = useRef<IScannedProduct | null>(null);
  const previousExceededProducts = useRef(exceededProducts);

  const { printUrl } = usePrinting();

  const isKeyPressed0 = useKeyPress(["Meta", "v"]);
  const isKeyPressed1 = useKeyPress(["N", "N"]);
  const isKeyPressed2 = useKeyPress(["n", "n"]);
  const isKeyPressed3 = useKeyPress(["P", "P"]);
  const isKeyPressed4 = useKeyPress(["p", "p"]);
  const isKeyPressed5 = useKeyPress(["S", "S"]);
  const isKeyPressed6 = useKeyPress(["s", "s"]);

  const isGetClipboard = isKeyPressed0;
  const isProcessNextStep = isKeyPressed1 || isKeyPressed2;
  const isProcessPrinting = isKeyPressed3 || isKeyPressed4;
  const isProcessSpliting = isKeyPressed5 || isKeyPressed6;

  const isDisplayGiftLabelAlert = isSaleOrderHasGiftLabel(enclosedLabels);

  /* Watch short cut interaction for quick user handling */
  useEffect(() => {
    if (isGetClipboard && window.document.hasFocus()) {
      window.navigator.clipboard.readText().then((clipText) => {
        setQrCode(clipText as string);
        setQrCodeScanningTime((prev) => prev + 1);
      });
    }
  }, [isGetClipboard]);

  useEffect(() => {
    if (isProcessNextStep && isInteractable) {
      handlePacking({});
    }
  }, [isProcessNextStep]);

  useEffect(() => {
    if (isProcessPrinting) {
      handlePrintSaleOrder();
    }
  }, [isProcessPrinting]);

  useEffect(() => {
    if (isProcessSpliting) {
      // handleSplitingPackage({ isManualSpliting: true });
      handleProcessSplitingPackageManually();
    }
  }, [isProcessSpliting]);

  /* Watch the Product interaction to triger pop up alert */
  useEffect(() => {
    const shouldShowExceededQtyAlert =
      (!!exceededProducts.gtin_code &&
        !areObjectsEqual(exceededProducts, previousExceededProducts.current)) ||
      exceededProducts.actual_quantity !== exceededProducts.expected_quantity;

    if (shouldShowExceededQtyAlert) {
      setIsShowAlertExceedQty(true);
      previousExceededProducts.current = exceededProducts;
    }
  }, [exceededProducts]);

  const { dispatchSidebarStatus } = useHandleSidebarStatus();
  /* Watch expand sidebar status */
  useEffect(() => {
    dispatchSidebarStatus({ isOpened: false });
    return () => {
      dispatchSidebarStatus({ isOpened: true });
    };
  }, []);

  /* Watch any qrcode scanning action */
  useBarCodeScanDetection({
    onComplete: (qrCode) => {
      const removeShiftQrCode = qrCode?.replace(/Shift/g, "");
      setQrCode(removeShiftQrCode as string);
      setQrCodeScanningTime((prev) => prev + 1);
    },
    onError: (error) => {},
  });

  const { windowWidth } = useWindowResize();
  const isLargeScreen =
    (windowWidth && windowWidth >= LARGE_SCREEN_SIZE) || false;

  const { packingId, saleOrderId, gtinCode } = useParams();

  const [packingDetailParams] = useState({ uuid: packingId });

  const [saleOrderParams, setSaleOrderParams] = useState({
    saleOrderNumber: saleOrderId,
  });

  const [selectedFromSaleOrderParams] = useState<{
    packingId: string;
    saleOrderNumber: string;
  }>({
    packingId: packingId as string,
    saleOrderNumber: saleOrderId as string,
  });

  const { result: packingDetail } = useApiCaller({
    api: getPackingDetailApi,
    params: packingDetailParams,
  });

  const { result: packagesDetail } = useApiCaller({
    api: getSaleOrderPackagesApi,
    params: saleOrderParams,
  });

  const currentProductsWithPackedProductMap = new Map<string, number>(null);

  packagesDetail?.data?.list?.forEach((packageProduct: any) => {
    packageProduct?.package_products?.forEach((item: any) => {
      const productUuid = item?.product?.gtin_code;
      if (!currentProductsWithPackedProductMap.has(item?.product?.gtin_code)) {
        currentProductsWithPackedProductMap.set(productUuid, item?.quantity);
      } else {
        const productQty = currentProductsWithPackedProductMap.get(productUuid);
        currentProductsWithPackedProductMap.set(
          productUuid,
          (productQty || 0) + item?.quantity
        );
      }
    });
  });

  const { result } = useApiCaller({
    api: getPackingSaleOrderDetailApi,
    params: selectedFromSaleOrderParams,
    pageReloadVersion,
  });

  // const { result } = useApiCaller({
  //   api:
  //     gtinCode && packingId
  //       ? getPackingSaleOrderFromScannedProductApi
  //       : getPackingSaleOrderDetailApi,
  //   params:
  //     gtinCode && packingId
  //       ? scannedQtyFromProductParams
  //       : selectedFromSaleOrderParams,
  //   pageReloadVersion,
  // });

  const packingSaleOrderDetail = result?.data;
  const saleOrderStatus = packingSaleOrderDetail?.status;
  const products = packingSaleOrderDetail?.products;

  /* Watch saleOrderNumber if packingSaleOrderDetail change */
  useEffect(() => {
    if (result?.data?.number) {
      setSaleOrderParams({ saleOrderNumber: result?.data?.number });
    }
  }, [result]);

  /* Sync all products of sale orders with products already packed in packages */
  const processingProducts = updatedProductsSyncWithPackedProducts?.filter(
    (product: IProductSaleOrder) =>
      newScannedProductPackage?.includes(product?.gtin_code as string)
  );

  const saleOrderUuid = packingSaleOrderDetail?.uuid;
  const isInteractable =
    saleOrderStatus === "packing" || saleOrderStatus === "picked";

  const isAutoPrintable =
    saleOrderStatus === "packing" || saleOrderStatus === "packed";

  /* Watch the scanner action when navigating from  "Packing Scan Products" route*/
  /* If this page is navigating from `${PACKINGS_LIST}/:packingId/scan-products/:gtinCode`*/
  /* then add count this product to 1  */
  useEffect(() => {
    if (
      !!gtinCodeGetFromScannedProductPage &&
      packingSaleOrderDetail &&
      !hasSetDefaultForFirstTimeRedirectFromScannedPage
    ) {
      setQrCode(gtinCodeGetFromScannedProductPage as string);
      setQrCodeScanningTime((prev) => prev + 1);
      setHasSetDefaultForFirstTimeRedirectFromScannedPage(true);
    }
  }, [result]);

  /* Watch to extract product in packages quantity */
  useEffect(() => {
    const productGtinCodes = Object.fromEntries(
      Array.from(currentProductsWithPackedProductMap)
    );
    if (Object.keys(productGtinCodes).length > 0) {
      setCurrentProductInPackagesQty(productGtinCodes);
    }
  }, [packagesDetail, result]);

  const [productState, setProductState] = useState<
    Record<string, TProductWithLocation>
  >({});

  /* Create the state to store and observe all change of product interaction */
  const createProductObj = (
    products: IProductSaleOrder[],
    productsInPackages: Record<string, number>
  ) => {
    let productObj: Record<string, TProductWithLocation> = {};

    products?.forEach((product: IProductSaleOrder, index: number) => {
      const { gtin_code, code, quantity, location } = product || {};

      if (!!code && !!quantity && location && !!gtin_code) {
        if (!productObj[gtin_code]) {
          productObj[gtin_code] = {};
        }

        if (!productObj[gtin_code]?.[location]) {
          productObj[gtin_code][location] = {
            requiredQty: quantity,
            scannedQty: 0,
            location: (location || index).toString(),
            isEditMode: false,
            gtinCode: gtin_code,
            quantityInPackage: productsInPackages?.[gtin_code] || 0,
          };
        }
      }
    });

    return productObj;
  };

  const totalProcessingProductsBaseOnProductState =
    getAllQtyProcessingProductsBaseOnProductState(productState);

  /* Watch change on ProductState and define location Index */
  useEffect(() => {
    const productObj = createProductObj(
      updatedProductsSyncWithPackedProducts,
      currentProductInPackagesQty
    );

    if (!!productObj && Object.keys(productObj).length > 0) {
      setProductState(productObj);

      setLocationOfProductIndex(transformObject(productObj));
    }
  }, [updatedProductsSyncWithPackedProducts, currentProductInPackagesQty]);

  const exceededScannedQtyItems = findObjectsWithExcessScannedQty(productState);

  const mappedExceededScannedQtyItems = exceededScannedQtyItems?.map((item) => {
    const foundSku = products?.find(
      (product: any) =>
        product?.gtin_code === item?.gtinCode &&
        product?.location === item?.location
    );

    return {
      ...item?.data,
      ...foundSku,
      expected_quantity: item?.data.requiredQty - item?.data?.quantityInPackage,
      actual_quantity: item?.data.scannedQty,
    };
  });

  /* Temp pausing delete material features */
/*   const handleDeleteMaterial = async (materialCode: string) => {
    const waitDelete = await apiCaller({
      api: deletePackingSaleOrderMaterialApi,
      params: {
        packingId,
        saleOrderNumber: saleOrderId || packingSaleOrderDetail?.number,
        materialCode,
      } as IDeletePackingSaleOrderMaterialApi,
    });

    if (waitDelete.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Delete successful!");

      setPageReloadVersion((prev) => prev + 1);
      return;
    }

    alert.error("Delete was unsuccessful!. Please try again");
  }; */

  const handleDeletePackages = async (packageNumber: string) => {
    const waitDelete = await apiCaller({
      api: deletePackingSaleOrderPackageApi,
      params: {
        packingId,
        saleOrderNumber: saleOrderId || packingSaleOrderDetail?.number,
        packageNumber,
      } as IDeletePackingSaleOrderPackageApi,
    });

    if (waitDelete.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Delete successful!");
      window.location.reload();

      return;
    }

    alert.error("Delete was unsuccessful!. Please try again");
  };

  const handleRemoveScanningProduct = (data: IProductSaleOrder) => {
    const removeGtinCode = data?.gtin_code;
    setNewScannedProductPackage((prev) =>
      prev.filter((code) => code !== removeGtinCode)
    );

    const productCode = productState?.[data?.gtin_code as string];
    const productLocation = productCode?.[data?.location as string];

    setProductState((prev: Record<string, TProductWithLocation>) => ({
      ...prev,
      [data?.gtin_code as string]: {
        ...prev[data?.gtin_code as string],
        [data?.location as string]: {
          ...productLocation,
          scannedQty: 0,
        },
      },
    }));
  };

  const handleUpdateSaleOrderStatus = async () => {
    await apiCaller({
      api: updateSaleOrderStatusApi,
      params: {
        uuid: saleOrderId || packingSaleOrderDetail?.number,
        event: "packing",
      } as IUpdateSaleOrderStatusApi,
    });

    setPageReloadVersion((prev) => prev + 1);
  };

  const handleSplitingPackage = async ({
    materialCode,
    splitingProducts,
    materialsArr,
    isManualSpliting = false,
    isForceReload = true,
  }: IHandleSplitingPackage) => {
    const formatedPackedProductsInProcessing = formatedPackedProducts()?.filter(
      (item) => newScannedProductPackage.includes(item?.gtin_code)
    );

    const waitedSplitingPackage = await apiCaller({
      api: createSplitingPackedApi,
      params: {
        isManualSpliting,
        splitingProducts:
          splitingProducts || formatedPackedProductsInProcessing,
        saleOrderNumber: saleOrderId || packingSaleOrderDetail?.number,
        packingId,
        materials: materialsArr || [{ quantity: 1, code: materialCode }],
      } as ISplitingPackageApi,
    });

    if (waitedSplitingPackage.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Spliting packages successful!");
      setNewScannedProductPackage([]);

      const packageCode = waitedSplitingPackage?.data?.code;
      handlePrintSaleOrdersPackagesLabelApi({ packageCode });
      isPackingProductListPrintable &&
        handlePrintSaleOrdersProductsListPackagesApi({ packageCode });

      /* If still remain other products and this gtinCode is from scanning pages
      ==> Navigate to packing page to clear original urlSearchParams and refresh pages
       */
      if (!!gtinCodeGetFromScannedProductPage && !isScannedAllProducts) {
        navigate(
          `${PACKINGS_LIST}/${packingId}/${packingSaleOrderDetail?.number}`
        );
        window.location.reload();
        return;
      }

      isForceReload && window.location.reload();
      return;
    }

    handleErrorToastMessageAlert(waitedSplitingPackage?.messages);
    alert.error("Spliting packages was unsuccessful!. Please try again");
  };

  const handlePrintSaleOrder = async () => {
    const waitedPrintSaleOrder = await apiCaller({
      api: printSaleOrdersLabelApi,
      params: {
        number: saleOrderId || packingSaleOrderDetail?.number,
      },
    });

    if (waitedPrintSaleOrder.status === API_RESPONSE_STATUS.SUCCESS) {
      const printingUrl = waitedPrintSaleOrder?.data?.url;
      await printUrl(printingUrl, "label", { height: 630, width: 400 });
    }
  };

  const handlePrintSaleOrdersProductsListPackagesApi = async ({
    packageCode,
  }: {
    packageCode: string;
  }) => {
    const waitedPrintSaleOrderProducts = await apiCaller({
      api: printSaleOrdersProductsPackagesApi,
      params: {
        number: saleOrderId || packingSaleOrderDetail?.number,
        package_code: packageCode,
      },
    });

    if (waitedPrintSaleOrderProducts.status === API_RESPONSE_STATUS.SUCCESS) {
      const printingUrl = waitedPrintSaleOrderProducts?.data?.url;
      await printUrl(printingUrl);
    }
  };

  const handlePrintSaleOrdersPackagesLabelApi = async ({
    packageCode,
  }: IPackagePrinting) => {
    const waitedPrintSaleOrderPackageLabel = await apiCaller({
      api: printSaleOrdersPackagesLabelApi,
      params: {
        number: saleOrderId || packingSaleOrderDetail?.number,
        package_code: packageCode,
      },
    });

    if (
      waitedPrintSaleOrderPackageLabel.status === API_RESPONSE_STATUS.SUCCESS
    ) {
      const printingUrl = waitedPrintSaleOrderPackageLabel?.data?.url;
      await printUrl(printingUrl, "label", { height: 630, width: 400 });
    }
  };

  const handleCheckAndMoveToNextRemainingSaleOrder = async () => {
    const waitedPackingDetail = await apiCaller({
      api: getPackingDetailApi,
      params: {
        uuid: packingId,
      },
    });

    if (waitedPackingDetail.status === API_RESPONSE_STATUS.SUCCESS) {
      const isStillRemainingPackableSaleorder =
        waitedPackingDetail?.data?.sale_orders?.some(
          (saleOrder: any) =>
            saleOrder.status === "picked" || saleOrder.status === "packing"
        );

      if (isStillRemainingPackableSaleorder) {
        navigate(
          `${PACKINGS_LIST}/${packingId}/scan-products?previousSaleOrderCode=${
            saleOrderId || packingSaleOrderDetail?.number
          }`
        );
        return;
      }

      setTimeout(() => {
        alert.success("All packable Sale Order have been completed");
      }, 400);

      navigate(`${PACKINGS_LIST}/${packingId}`);
    }
  };

  const handleAddMaterialToPackingList = async (materialCode: string) => {
    if (saleOrderStatus === "picked") {
      await handleUpdateSaleOrderStatus();
    }

    const waitAddingMaterial = await apiCaller({
      api: addMaterialsToPackingListApi,
      params: {
        packingId,
        saleOrderNumber: saleOrderId || packingSaleOrderDetail?.number,
        materialCode,
      } as IaddMaterialsToPackingListApi,
    });

    if (waitAddingMaterial.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Add material successful!");

      setPageReloadVersion((prev) => prev + 1);
      return;
    }

    alert.error("Add material was unsuccessful!. Please try again");
  };

  const extractItemsFromArrayBasedOnField = (
    array: any[],
    field: string,
    searchCode: string
  ) => {
    if (!array || !field) return [];
    return array.filter((item: any) => item[field] === searchCode);
  };

  const handleAddProductslToPackingList = async () => {
    const latestProductWithLocationGroups = extractItemsFromArrayBasedOnField(
      cachedProductSyncWithPackedProducts ||
        updatedProductsSyncWithPackedProducts,
      "gtin_code",
      qrCode
    );

    if (!isInteractable) {
      alert.warn(
        `Status ${saleOrderStatus?.toUpperCase()} can't add new product`
      );
      return;
    }

    if (saleOrderStatus !== "packing") {
      await handleUpdateSaleOrderStatus();
    }

    setNewScannedProductPackage((prev) => [...prev, qrCode]);
    setProductState((prev: Record<string, TProductWithLocation>) => {
      if (!prev || !prev[qrCode]) {
        alert.warn("This product doesn't exist in sale order");
        return { ...prev };
      }

      let clonedState = { ...prev };

      const activeArrayLocationIndex = locationOfProductIndex[qrCode];

      const sku: IProductSaleOrder =
        latestProductWithLocationGroups[activeArrayLocationIndex];
      const currentSkuLocation = sku?.location;
      const currentScannedSkuLocationQty =
        prev[qrCode]?.[currentSkuLocation as string]?.scannedQty;

      if (
        !!currentSkuLocation &&
        typeof sku?.quantity === "number" &&
        typeof currentScannedSkuLocationQty === "number"
      ) {
        const currentProductLocationObj = prev[qrCode]?.[currentSkuLocation];

        /* Use this useRef to store the latest scan => Triger highlight effect */
        latestScannedQtyProduct.current = {
          qrCode,
          location: currentSkuLocation,
        };

        const checkUpdateQtyZero = (
          currentIndex: number = activeArrayLocationIndex
        ): any => {
          const nextSku: IProductSaleOrder =
            latestProductWithLocationGroups[currentIndex + 1];

          if (
            nextSku?.updatedQuantity === 0 &&
            currentIndex + 2 <= latestProductWithLocationGroups?.length - 1
          ) {
            setLocationOfProductIndex((prev) => ({
              ...prev,
              [qrCode]: currentIndex + 1,
            }));
            return checkUpdateQtyZero(currentIndex + 1);
          }

          const nextCurrentSkuLocation = nextSku?.location;
          const nextProductLocationObj =
            prev[qrCode]?.[nextCurrentSkuLocation as string];
          const nextCurrentScannedSkuLocationQty =
            prev[qrCode]?.[nextCurrentSkuLocation as string]?.scannedQty;

          const clonedState = {
            ...prev,
            [qrCode]: {
              ...prev[qrCode],
              [nextCurrentSkuLocation as string]: {
                ...nextProductLocationObj,
                scannedQty: (Number(nextCurrentScannedSkuLocationQty) +
                  1) as number,
              },
            },
          };

          if (
            currentScannedSkuLocationQty + 1 === sku?.updatedQuantity &&
            activeArrayLocationIndex + 1 <=
              latestProductWithLocationGroups?.length - 1
          ) {
            setLocationOfProductIndex((prev) => ({
              ...prev,
              [qrCode]: activeArrayLocationIndex + 1,
            }));
          }

          const modifiedProductSyncWithPackedProducts = {
            ...nextSku,
            updatedQuantity: (nextSku?.updatedQuantity as number) - 1,
          };

          const latestSyncProductsWithPackedProducts =
            updatedProductsSyncWithPackedProducts?.map(
              (sku: IProductSaleOrder) => {
                if (
                  sku.gtin_code === nextSku.gtin_code &&
                  sku.location === nextSku.location
                ) {
                  return modifiedProductSyncWithPackedProducts;
                }

                return sku;
              }
            );

          setcachedProductSyncWithPackedProducts(
            latestSyncProductsWithPackedProducts
          );

          return clonedState;
        };
        if (
          sku?.updatedQuantity === 0 &&
          activeArrayLocationIndex + 1 <=
            latestProductWithLocationGroups?.length - 1
        ) {
          return checkUpdateQtyZero();
        }

        clonedState = {
          ...prev,
          [qrCode]: {
            ...prev[qrCode],
            [currentSkuLocation]: {
              ...currentProductLocationObj,
              scannedQty: (Number(currentScannedSkuLocationQty) + 1) as number,
            },
          },
        };

        if (
          currentScannedSkuLocationQty + 1 === sku?.updatedQuantity &&
          activeArrayLocationIndex + 1 <=
            latestProductWithLocationGroups?.length - 1
        ) {
          setLocationOfProductIndex((prev) => ({
            ...prev,
            [qrCode]: activeArrayLocationIndex + 1,
          }));
        }
      }

      return clonedState;
    });
  };

  const isScannedAllProducts = isScannedAllProductOnAllLocations(productState);

  const handleCombineNextActionIntoMaterialScanning = async (
    materialCode: string
  ) => {
    if (isScannedAllProducts) {
      await handleSplitingPackage({ materialCode, isForceReload: false });
      await handlePacking({ isPrintLabel: false });
      return;
    }

    await handleSplitingPackage({ materialCode });
  };

  const handleProcessSplitingPackageManually = async () => {
    if (isScannedAllProducts) {
      await handleSplitingPackage({
        isManualSpliting: true,
        isForceReload: false,
      });
      await handlePacking({ isPrintLabel: false });
      return;
    }

    await handleSplitingPackage({
      isManualSpliting: true,
    });
  };

  /* Watch QrCode scanning to detect if Material or Product */
  useEffect(() => {
    if (!!qrCode) {
      const prefix = qrCode?.split("-");
      if (prefix[0] === `${DEEP_LINK_PREFIX}M`) {
        const materialCode = qrCode?.split("#")?.[1];

        if (isInteractable) {
          handleCombineNextActionIntoMaterialScanning(materialCode);
        } else {
          handleAddMaterialToPackingList(materialCode);
        }

        latestScannedQtyMaterial.current = materialCode;
      } else {
        topPanelRef.current?.scrollIntoView();
        handleAddProductslToPackingList();
        latestScannedQtyProduct.current = { qrCode };
      }
    }
  }, [qrCode, qrCodeScanningTime]);

  const isPageDirty = Object.keys(productState)?.some(
    (productGtincode: any) => {
      const productItem = productState[productGtincode];
      return Object.keys(productItem).some(
        (location: any) => (productItem?.[location]?.scannedQty as number) > 0
      );
    }
  );

  const handleMouseSelectProduct = (gtinCode: string) => {
    setQrCode(gtinCode);
    setQrCodeScanningTime((prev) => prev + 1);
  };

  const formatedPackedProducts = (isHandleNextAction: boolean = false) => {
    return Object.entries(productState)?.map(([gtinCode, values]) => {
      let totalProductQty = 0;

      totalProductQty = Object.values(values)?.reduce(
        (acc, current) => acc + (current?.scannedQty || 0),
        0
      );

      if (isHandleNextAction) {
        totalProductQty = Object.values(values)?.reduce(
          (acc, current) =>
            acc +
            (current?.scannedQty || 0) +
            (current?.quantityInPackage || 0),
          0
        );
      }
      return {
        gtin_code: gtinCode,
        quantity: totalProductQty,
      };
    });
  };

  const handlePacking = async ({
    isConfirmed,
    isPrintLabel = true,
  }: IHandlePacking) => {
    const waitPacked = await apiCaller({
      api: updatePackedApi,
      params: {
        packingId,
        saleOrderNumber: saleOrderId || packingSaleOrderDetail?.number,
        packedProducts: formatedPackedProducts(true),
        confirm: isConfirmed,
      } as IUpdatePackageApi,
    });

    const mismatchProducts = waitPacked?.data?.unmatched_sale_order_products;

    if (
      !!mismatchProducts &&
      Array.isArray(mismatchProducts) &&
      mismatchProducts.length > 0 &&
      !isConfirmed
    ) {
      setMismatchQtyProducts(mismatchProducts as any);
      setIsShowPackingPopupConfirmation(true);
      return;
    }

    if (waitPacked?.data?.status === "packed") {
      isPrintLabel && handlePrintSaleOrder();
      handleCheckAndMoveToNextRemainingSaleOrder();
      setPageReloadVersion((prev) => prev + 1);
    }

    if (waitPacked?.status === "ERROR") {
      alert.error(waitPacked?.messages?.[0]);
    }
  };

  const formatOptionItems = (data: any) => {
    return data.map((option: any) => ({
      value: option?.number,
      content: (
        <div className="">{`${
          option?.number
        } / ${option?.status.toUpperCase()}`}</div>
      ),
    }));
  };

  const handleNavigateToOtherSaleOrder = (saleOrderNumber: string) => {
    if (saleOrderNumber === saleOrderId) return;

    if (!isPageDirty) {
      navigate(`${PACKINGS_LIST}/${packingId}/${saleOrderNumber}`);
      window.location.reload();
      return;
    }

    setNextSaleOrderId(saleOrderNumber);
    setIsShowModal(true);
  };

  /* TEMP: Pausing material schema scanning */
  // const materialSchema = useMaterialSchema({
  //   onDelete: handleDeleteMaterial,
  //   latestScannedCode: latestScannedQtyMaterial.current as string,
  // });

  const processingProductSchema = useProcessingProductSchema({
    productState,
    setProductState,
    setExceededProducts,
    isInteractable,
    latestScannedProduct: latestScannedQtyProduct.current as IScannedProduct,
    isLargeScreen,
    handleRemoveScanningProduct,
    totalProcessingProductsQty: totalProcessingProductsBaseOnProductState,
  });

  const onRemainingProductSchema = useRemainingProductSchema({
    productState,
    currentProductInPackagesQty,
    setExceededProducts,
    latestScannedProduct: latestScannedQtyProduct.current as IScannedProduct,
    isLargeScreen,
    handleMouseSelectProduct,
    totalRemainingProductsQty:
      getAllRemainingQtyProductsBaseOnProductState(productState),
  });

  const remainingProductSchema = isInteractable
    ? onRemainingProductSchema
    : packedProductSchema({
        isLargeScreen,
      });

  const handlePrintCustomerMessage = async (uuid: string) => {
    const waitedPrintCustomerMessage = await apiCaller({
      api: printProductCustomerMessageApi,
      params: { uuid },
    });

    if (waitedPrintCustomerMessage.status === API_RESPONSE_STATUS.SUCCESS) {
      await printUrl(waitedPrintCustomerMessage?.data?.url, "label", {
        height: 500,
        width: 600,
      });
    }
  };

  const handleCountdownEnd = () => {
    if (!hasAppeared && !isCancelAutoPrint) {
      handlePrintSaleOrder();
      setHasAppeared(true);
      setIsDisplayCoutdownAutoPrint(false);
    }
  };

  const updatedProductsRealtime = (
    products: IProductSaleOrder[],
    currentProductInPackagesQty: any
  ) => {
    const cloneCurrentProductInPackagesQty = { ...currentProductInPackagesQty };

    const updatingProducts = products?.map((product) => {
      const gtinCode = product?.gtin_code;
      const requiredQtyInEachProductLocation = product?.quantity || 0;

      const qtyInPackage =
        cloneCurrentProductInPackagesQty?.[gtinCode as string] || 0;

      /* If current packed packages not contain this gtincode
      => Product will not packed yet
      => Keep it old quantity */
      if (!currentProductInPackagesQty?.[gtinCode as string]) {
        return {
          ...product,
          diffWithCurrentProductInPackageQty: 0,
          updatedQuantity: requiredQtyInEachProductLocation,
        };
      }

      if (requiredQtyInEachProductLocation >= qtyInPackage) {
        const updatedQty = requiredQtyInEachProductLocation - qtyInPackage;

        cloneCurrentProductInPackagesQty[gtinCode as string] = 0;

        return {
          ...product,
          updatedQuantity: updatedQty,
          diffWithCurrentProductInPackageQty: 0,
        };
      }

      cloneCurrentProductInPackagesQty[gtinCode as string] =
        qtyInPackage - requiredQtyInEachProductLocation;

      return {
        ...product,
        updatedQuantity: 0,
        diffWithCurrentProductInPackageQty: 0,
      };
    });

    return updatingProducts;
  };
  /* This is the mock function, will delete soon */
  const handlePreGroupProducts = (products: IProductSaleOrder[]) => {
    const groupedProducts: TGroupedProducts[] = [];

    products.forEach((product) => {
      const itemIndex = groupedProducts?.findIndex(
        (productInside) => productInside?.gtin_code === product?.gtin_code
      );

      if (itemIndex === -1) {
        const groupedProduct: TGroupedProducts = {
          ...product,
          groupedLocations: {},
        };
        // Set the initial count for the current location
        groupedProduct.groupedLocations[product?.location || ""] = 1;

        groupedProducts.push(groupedProduct);
        return;
      }

      const accProductQty =
        (groupedProducts[itemIndex]?.quantity || 0) + (product?.quantity || 0);

      const accUpdatedProductQty =
        (groupedProducts[itemIndex]?.updatedQuantity || 0) +
        (product?.updatedQuantity || 0);

      groupedProducts[itemIndex] = {
        ...groupedProducts[itemIndex],
        quantity: accProductQty,
        updatedQuantity: accUpdatedProductQty,
        location: groupedProducts[itemIndex].location,
      };

      groupedProducts[itemIndex].groupedLocations[product?.location || ""] =
        (groupedProducts[itemIndex].groupedLocations[product?.location || ""] ||
          0) + 1;
    });

    return groupedProducts;
  };

  useEffect(() => {
    if (products && Array.isArray(products)) {
      const updatedProducts = updatedProductsRealtime(
        products,
        currentProductInPackagesQty
      );
      const preGroupProducts = handlePreGroupProducts(updatedProducts);
      setUpdatedProductsSyncWithPackedProducts(preGroupProducts);
    }
  }, [products, currentProductInPackagesQty]);

  if (result?.status === "ERROR") return <NotFoundPanel />;

  return (
    <>
      <QuickActionPopupConfirmation
        content={
          <>
            Are you sure to leave this sale order?
            <Typography className="mt-2 ms-4 " fontWeight="fw-bold">
              Please return items to theirs previous location!
            </Typography>
          </>
        }
        isShowModal={isShowModal}
        handleCloseModal={() => {
          setIsShowModal(false);
        }}
        variant={GENERAL_UI_COLOR.DANGER}
        confirmationButton="Yes, I have returned"
        onConfirm={() => {
          navigate(`${PACKINGS_LIST}/${packingId}/${nextSaleOrderId}`);
          window.location.reload();
        }}
      />
      <PackingConfirmationPopup
        content={
          <TableDataPanel
            data={mappedExceededScannedQtyItems}
            schema={confirmationSchema({
              productState,
              currentProductInPackagesQty,
            })}
          />
        }
        isShowModal={isShowAlertExceedQty}
        handleCloseModal={() => {
          setIsShowAlertExceedQty(false);
        }}
      />

      <PackingConfirmationPopup
        content={
          <TableDataPanel
            data={mismatchQtyProducts}
            schema={confirmationSchema({
              productState,
              currentProductInPackagesQty,
            })}
          />
        }
        isShowModal={isShowPackingPopupConfirmation}
        handleCloseModal={() => {
          setIsShowPackingPopupConfirmation(false);
        }}
        handleConfirm={() => {
          handlePacking({ isConfirmed: true });
        }}
      />
      <div ref={topPanelRef}>
        <HeaderInfoPanel
          title={`#${packingSaleOrderDetail?.number}`}
          infoArea={() => (
            <div className={styles.headerInfoArea}>
              <TableCellStatus
                className="me-2"
                status={saleOrderStatus}
                size={GENERAL_UI_SIZE.SMALL}
              />
            </div>
          )}
          infoActionArea={() =>
            !!packingDetail &&
            Array.isArray(packingDetail?.data?.sale_orders) && (
              <UiSelectDropdown
                name="box_type"
                title="Box type"
                containerClassName="mb-0 me-5"
                isDisplayLabel={false}
                value={saleOrderId}
                optionList={
                  formatOptionItems(
                    packingDetail?.data?.sale_orders
                  ) as IOptionItem[]
                }
                onChange={(value) => {
                  handleNavigateToOtherSaleOrder(value);
                }}
                style={{
                  minWidth: "fit-content",
                  width: "calc(100% + 16px)",
                }}
              />
            )
          }
          actionArea={() => (
            <div className="d-flex justify-content-between w-100">
              {isProcessAutoPrinting &&
                isDisplayCoutdownAutoPrint &&
                isAutoPrintable && (
                  <ViewAutoPrintSection
                    isPlaying
                    duration={4}
                    onCancel={() => {
                      setIsCancelAutoPrint(true);
                      setIsDisplayCoutdownAutoPrint(false);
                    }}
                    onComplete={handleCountdownEnd}
                  />
                )}
              <ViewActionButtons
                handlePrintSaleOrder={handlePrintSaleOrder}
                handlePacking={handlePacking}
                saleOrderStatus={saleOrderStatus}
              />
            </div>
          )}
        />
      </div>

      <TwoColumnGridLayout
        className={{ container: "px-0" }}
        widthDivision={[4, 8]}
        leftCol={
          <>
            <ViewAddressSection
              packingSaleOrderDetail={packingSaleOrderDetail}
            />
            {saleOrderUuid && (
              <PanelNote
                isDisplayHeading
                notable_type="SaleOrder"
                notable_uuid={saleOrderUuid as string}
                setNoteQuantity={() => {}}
                isNoteEditable={false}
                isNewNoteAddable={false}
              />
            )}

            {saleOrderUuid && (
              <GeneralPanel className="pt-4" containerClassName="mt-4">
                <GeneralHeading>Labels</GeneralHeading>
                <PanelLabelController
                  linkedObjectType="SaleOrder"
                  uniqueUuid={saleOrderUuid}
                  isEditable={false}
                  isDisplayPrintButton
                  onPrint={handlePrintCustomerMessage}
                  onReceiveResult={(result) => {
                    setEnclosedLabels(result);
                  }}
                />
              </GeneralPanel>
            )}
          </>
        }
        rightCol={
          <div className="mt-4">
            <div className="customTable">
              <TableDataPanel
                data={updatedProductsSyncWithPackedProducts}
                schema={remainingProductSchema}
                className={styles.customTable}
              />
            </div>

            {isInteractable && (
              <section>
                <div className="d-flex justify-content-end mt-4 mb-2">
                  <UiTooltip
                    position="bottomRight"
                    tooltip={
                      <Typography className="d-flex align-items-center ">
                        Type
                        <span className="badge badge-primary text-xl align-self-start mx-1">
                          SS
                        </span>
                        for quick
                        <div className="text-primary ms-1 fw-bold">
                          Spliting Package Action
                        </div>
                      </Typography>
                    }
                    className={{
                      mainContent: "text-right",
                      floatingContent: styles.floatingContent,
                    }}
                  >
                    {/* <UiButton
                      title="Material test"
                      onClick={() => {
                        qqqq
                        lhs://ml#M-53H
                        lhs://ml#M-9X
                        setQrCode("lhs://ml#M-53H");
                        setQrCodeScanningTime((prev) => prev + 1);
                      }}
                      className=""
                      type={GENERAL_UI_COLOR.SUCCESS}
                      size={GENERAL_UI_SIZE.XSMALL}
                    />
                   */}
                    <UiButton
                      title="Spliting Pakage (S)"
                      disabled={totalProcessingProductsBaseOnProductState === 0}
                      onClick={handleProcessSplitingPackageManually}
                      className=""
                      type={GENERAL_UI_COLOR.DARK}
                      size={GENERAL_UI_SIZE.XSMALL}
                    />
                  </UiTooltip>
                </div>
                <div
                  className={classNames(
                    isDisplayGiftLabelAlert && styles.processingPanel
                  )}
                >
                  {isDisplayGiftLabelAlert && (
                    <div className={classNames(styles.giftBox)}>
                      <img src={giftImg} />
                    </div>
                  )}
                  <TableDataPanel
                    data={processingProducts}
                    schema={processingProductSchema}
                    noDataContent="NO SCANNED PRODUCT YET!"
                  />
                </div>
              </section>
            )}
            <GeneralPanel className="pt-4" containerClassName="mt-4">
              <GeneralHeading className="mb-3 d-flex align-items-center gap-2">
                <Typography
                  customFontsize={15}
                  fontWeight="fw-bold"
                  className="badge badge-success"
                >
                  {packagesDetail?.data?.list?.length}
                </Typography>
                Packed Packages
                <Typography
                  customFontsize={12}
                  fontWeight="fw-bold"
                  className="text-black"
                >{`(${
                  flatenedProductsOfAllPackages(packagesDetail?.data?.list) || 0
                })`}</Typography>
              </GeneralHeading>

              <PackagesList
                allProducts={products}
                packages={packagesDetail?.data?.list}
                onDeletePackage={() => {}}
                isInteractable={isInteractable}
                currentProductInPackagesQty={currentProductInPackagesQty}
                onSplitingPackage={handleSplitingPackage}
                onPrintSaleOrdersPackagesLabelApi={
                  handlePrintSaleOrdersPackagesLabelApi
                }
                onPrintSaleOrdersProductsListPackagesApi={
                  handlePrintSaleOrdersProductsListPackagesApi
                }
                onDeleteSaleOrderPackage={handleDeletePackages}
              />
            </GeneralPanel>

            {/* TEMP: Pausing material scanning section
                <div className="mt-4">
                  <TableDataPanel
                    data={moveToTopAndHighlight(
                      latestScannedQtyMaterial.current,
                      materials,
                      "code"
                    )}
                    schema={materialSchema}
                  />
                </div>
            */}
          </div>
        }
      />
    </>
  );
};

export default Component;
