import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useForm } from "react-hook-form";

import UiInput from "../../components/ui-input";

import styles from "./style.module.scss";

interface IDynamicInput {
  formItems: string[];
  setValue?: any;
  name: string;
  title: string;
  errorMessage: string;
  required: boolean;
}

const DynamicInput = ({
  formItems,
  setValue: setFieldValue,
  name,
  title,
  errorMessage,
  required,
}: IDynamicInput) => {
  const [fields, setFields] = useState(formItems);

  useEffect(() => {
    setFields(formItems);
  }, [formItems]);

  const { register, getValues, reset } = useForm({ mode: "onBlur" });

  const handleAddNote = () => {
    const result = getValues();

    if (!result.field.toString()) return;

    setFields((prevState: any) => {
      const newUpdatedFields = [...prevState, result.field.toString()];
      setFieldValue(name, newUpdatedFields);
      return newUpdatedFields;
    });

    reset({ field: "" });
  };

  const handleDelete = (fieldIndex: number) => {
    setFields((preState: any) => {
      const fieldsWithRemovedItems = preState.filter(
        (_: any, index: number) => fieldIndex !== index
      );
      setFieldValue(name, fieldsWithRemovedItems);
      return fieldsWithRemovedItems;
    });
  };

  return (
    <div className="col-12 my-2 d-flex gap-3">
      <label data-required={required} className={styles.labelDynamicInput}>
        {title}
      </label>
      <div className="card h-100 flex-grow-1">
        <div className="mx-2">
          <div className="mb-1 mt-3 ">
            {fields?.map((field, index) => {
              return (
                <>
                  <div className="d-flex mt-2" key={index}>
                    <div className=" d-flex flex-grow-1 ms-3 ">
                      <div className="me-4 w-100">
                        <p className={classNames("text-sm")}>{field}</p>
                      </div>
                      <div className={classNames("d-flex")}>
                        <div
                          className={classNames("cursor-pointer")}
                          onClick={() => {
                            handleDelete(index);
                          }}
                        >
                          <i className="fa-solid fa-x opacity-5 me-2 fa-sm"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="horizontal dark mt-0 " />
                </>
              );
            })}

            <div className="d-flex">
              <div className="flex-grow-1 my-auto">
                <UiInput
                  className="dataTable-input"
                  name={"field"}
                  title={"Values"}
                  placeholder="Add field"
                  isDisplayLabel={false}
                  register={register}
                  onBlur={handleAddNote}
                />
              </div>
            </div>
            {errorMessage && errorMessage.length && (
              <p className={styles.errorMessage}>{errorMessage}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicInput;
