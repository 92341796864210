import classNames from "classnames";
import styles from "./styles.module.scss";
import { NavLink } from "react-router-dom";

interface IUiContentRow {
  label: string;
  children: any;
  type?: "normal" | "small";
  onClick?: () => void;
  className?: {
    container?: string;
  };
  isOVerflowHidden?: boolean;
  customStyles?: {
    container?: React.CSSProperties;
    lable?: React.CSSProperties;
    value?: React.CSSProperties;
  };
  linkTo?: string;
}

const UiContentRow = ({
  label,
  children,
  type = "normal",
  onClick,
  className = {
    container: "",
  },
  isOVerflowHidden = false,
  customStyles,
  linkTo,
}: IUiContentRow) => {
  return (
    <div
      className={classNames(
        styles.container,
        type === "small" && styles.small,
        className.container
      )}
      style={customStyles?.container}
      onClick={onClick}
    >
      <div
        className={classNames(
          styles.label,
          type === "small" && styles.small,
          isOVerflowHidden && styles.overFlowHidden
        )}
        style={customStyles?.lable}
      >
        {label}
      </div>
      <div
        className={classNames(
          styles.value,
          type === "small" && styles.small,
          isOVerflowHidden && styles.overFlowHidden
        )}
        style={customStyles?.value}
      >
        {!!linkTo && linkTo.length ? (
          <NavLink target="_blank" to={linkTo}>
            {children}
          </NavLink>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default UiContentRow;
