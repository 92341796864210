import React from "react";
import classNames from "classnames";
import { useController } from "react-hook-form";

import Typography from "../ui-typography/component";
import styles from "./styles.module.scss";

interface IOption {
  value: string | number;
  content: string | React.ReactNode;
}

interface RadioComboBoxProps {
  control: any;
  title?: string;
  name: string;
  errorMessage?: string;
  required?: boolean;
  disabled?: boolean;
  options: IOption[];
  className?: {
    label?: string;
    container?: string;
    content?: string;
  };
}

const RadioComboBox = ({
  control,
  title,
  name,
  required = false,
  errorMessage,
  disabled = false,
  options,
  className = {
    label: "",
    container: "mt-3 mb-3",
    content: "",
  },
}: RadioComboBoxProps) => {
  const {
    field: { value, onChange, onBlur },
  } = useController({
    name: name,
    control,
  });

  return (
    <div className={classNames(styles.comboBox, className.container)}>
      {title && <label data-required={required}>{title}</label>}
      <div className="d-flex gap-4">
        {options?.map((option: IOption) => (
          <div
            className={classNames(
              "form-check d-flex align-items-center gap-1",
              className.content
            )}
            key={option.value}
          >
            <label
              htmlFor={`radio-${option.value}`}
              style={{ cursor: "pointer" }}
            >
              <input
                id={`radio-${option.value}`}
                className={classNames(
                  "form-check-input cursor-pointer",
                  styles.smallSize
                )}
                type="radio"
                value={option.value}
                checked={value === option.value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={disabled}
              />
              <Typography
                className={classNames("mt-1 ms-1", className.content)}
                customFontsize={13}
                fontWeight="fw-normal"
              >
                {option.content}
              </Typography>
            </label>
          </div>
        ))}
      </div>
      {errorMessage && errorMessage.length && (
        <label className="mb-0" style={{ color: "#f5365c" }}>
          {errorMessage}
        </label>
      )}
    </div>
  );
};

export default RadioComboBox;
