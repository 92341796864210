import { useState } from "react";
import styles from "./styles.module.scss";

import { GENERAL_UI_SIZE } from "../../constants/size";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { INote } from "../../components/panel-note/types";
import UiButton from "../../components/ui-button";
import NotePosting from "../../components/panel-note/view-note-posting";
import Typography from "../../components/ui-typography/component";

interface IComponent {
  displayLimit?: number;
  notes: INote[];
}

const Component = ({ notes, displayLimit = 3 }: IComponent) => {
  const [collapsed, setCollapse] = useState<boolean>(!!displayLimit);

  return (
    <div className={styles.container}>
      {(!notes || notes?.length === 0) &&   <Typography customFontsize={14}>No Notes</Typography>}
      {notes
        ?.reverse()
        ?.filter((_, index) =>
          !displayLimit ? true : !collapsed || displayLimit > index
        )
        ?.map((note: INote) => (
          <div key={note.id}>
            <NotePosting note={note} />
          </div>
        ))}
      {!!displayLimit && notes?.length > displayLimit && (
        <UiButton
          onClick={() => setCollapse((prev) => !prev)}
          title={collapsed ? "View more" : "View less"}
          size={GENERAL_UI_SIZE.SMALL}
          type={GENERAL_UI_COLOR.LIGHT}
        />
      )}
    </div>
  );
};

export default Component;
