import React from "react";
import GridLayoutTwoColumn from "../../components/grid-layout-two-column/component";
import AccountSidebar from "./view-sidebar";
import { Outlet } from "react-router-dom";

const PurchaseOrderLayout = ({ sidebarContent }: { sidebarContent: any }) => {
  return (
    <GridLayoutTwoColumn
      widthDivision={[3, 9]}
      leftCol={<AccountSidebar sidebarContent={sidebarContent} />}
      rightCol={<Outlet />}
    />
  );
};

export default PurchaseOrderLayout;
