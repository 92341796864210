import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { schema } from "./schema";

import { apiCaller, useApiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import {
  ICreateShopApi,
  IUpdateShopApi,
  getShopDetailApi,
  updateShopApi,
} from "../../apis/paths/shop";
import { SHOP_LIST } from "../../routings/path";

import alert from "../../utils/alert";
import UiButton from "../../components/ui-button";
import UiInput from "../../components/ui-input";
import GeneralPanel from "../../components/panel-general";
import LoadingPanel from "../../components/ui-loading-panel/component";
import ImageUploader from "../../components/ui-image-uploader/component";
import HeaderInfoPanel from "../../components/panel-header-info";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import SmallCollumnCenterGridLayout from "../../components/grid-layout-small-collumn-center";

const Component = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { shopId: uuid } = useParams();

  const [params] = useState({ uuid });

  const { result, loading } = useApiCaller({
    api: getShopDetailApi,
    params: params,
  });

  const shopDetail = result?.data;
  const uploadImage: any = watch("logo");

  useEffect(() => {
    if (!shopDetail) return;

    const keys = Object.keys(schema?.fields);
    keys.forEach((key: any) => {
      setValue(key, shopDetail[key as keyof IUpdateShopApi]);
    });
  }, [result, shopDetail]);

  const navigate = useNavigate();

  const onSubmit = async ({
    name,
    code,
    phone,
    tax_code,
    full_address,
  }: any) => {
    const data: ICreateShopApi = {
      name,
      code,
      phone,
      tax_code,
      full_address,
      logo: uploadImage?.[0]?.file,
    };

    const formData = new FormData();

    for (const key in data) {
      if (!!data[key as keyof ICreateShopApi]) {
        formData.append(key, data[key as keyof ICreateShopApi]);
      }
    }

    const waitUpdateShop = await apiCaller({
      api: updateShopApi,
      params: {
        uuid,
        data: formData,
      },
    });

    if (waitUpdateShop.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Create successful!");
      setTimeout(() => {
        navigate(SHOP_LIST);
      }, 500);

      return;
    }

    alert.error("Create was not successful!. Please try again!");
  };

  if (loading) return <LoadingPanel />;

  return (
    <>
      <HeaderInfoPanel
        title={shopDetail?.name}
        image={shopDetail?.logo}
        description="Input info below to edit shop"
        actionArea={() => (
          <>
            <UiButton
              onClick={handleSubmit(onSubmit)}
              className="me-2"
              title={"Update"}
            />
          </>
        )}
      />
      <SmallCollumnCenterGridLayout>
        <GeneralPanel panel={{ subTitle: "Shop Information" }}>
          <TwoColumnGridLayout
            className={{ container: "px-0" }}
            leftCol={
              <>
                <UiInput
                  title={"Name"}
                  name={"name"}
                  required
                  register={register}
                  errorMessage={errors?.name?.message}
                />
                <UiInput
                  title={"Code"}
                  name={"code"}
                  required
                  register={register}
                  errorMessage={errors?.code?.message}
                />
                <UiInput
                  title={"Full Address"}
                  name={"full_address"}
                  required
                  register={register}
                  errorMessage={errors?.full_address?.message}
                />
              </>
            }
            rightCol={
              <>
                <UiInput
                  title={"Phone"}
                  name={"phone"}
                  required
                  register={register}
                  errorMessage={errors?.phone?.message}
                />
                <UiInput
                  title={"Tax Code"}
                  name={"tax_code"}
                  register={register}
                  errorMessage={errors?.tax_code?.message}
                />
                <ImageUploader
                  isDisplayLabel
                  title="Image Upload File"
                  isDisplayRemoveAll={false}
                  name="logo"
                  setValue={setValue}
                  errorMessage={errors?.logo?.message}
                />
              </>
            }
          />
        </GeneralPanel>
      </SmallCollumnCenterGridLayout>
    </>
  );
};

export default Component;
