import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import DateTimeTableCell from "../../components/table-cell-date-time";

export const saleOredrSchema = [
  {
    name: "Sale Order",
    cell: (data: any) => (
      <ProductTableCell title={data?.number} imageSize={40} maxWidth={200} />
    ),
  },
  {
    name: "Product status",
    cell: (data: any) => <StatusTableCell status={data?.product_status} />,
  },
  {
    name: "Receiver",
    cell: (data: any) => (
      <ProductTableCell
        title={data?.receiver_name}
        description={data?.receiver_phone}
        imageSize={40}
        maxWidth={200}
      />
    ),
  },
  {
    name: "Warehouse",
    align: "center",
    textSize: "xs",
    cell: (data: any) => data?.warehouse_code,
  },
  {
    name: "Created ay",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data?.created_at} />,
  },
  {
    name: "Status",
    cell: (data: any) => <StatusTableCell status={data?.status} />,
  },
];
