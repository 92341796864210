import { useState } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

interface IUiCopyIcon {
  tooltipContent?: React.ReactNode | string;
  copyContent: string
  className?: {
    container?: string;
    title?: string;
  };
}

const UiCopyIcon = ({
  tooltipContent = "Copied",
  copyContent,
  className = {
    container: "",
  },
}: IUiCopyIcon) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyTextToClipboard = async (text: string) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const handleCopyClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    copyTextToClipboard(copyContent)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <div
      className={classNames(styles.copyButton, "px-2", className.container)}
      onClick={handleCopyClick}
    >
      <i
        className={classNames("fa-regular fa-copy text-secondary", isCopied && "text-success")}
      ></i>
      {isCopied && (
        <div className={classNames(styles.floatingContent)}>
          <div className={classNames(styles.subFloatingContent)}>
            {tooltipContent}
          </div>
        </div>
      )}
    </div>
  );
};

export default UiCopyIcon;
