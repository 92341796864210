import React, { useState } from "react";
import classNames from "classnames";
import { useForm } from "react-hook-form";

import { GENERAL_UI_SIZE } from "../../../constants/size";
import { GENERAL_UI_COLOR } from "../../../constants/color";
import UiTextArea from "../../../components/ui-text-area";
import Badge from "../../../components/ui-badge-button/component";
import { IFormatedFormItem } from "../../../types/tpl";

import styles from './styles.module.scss'

const NotePosting = ({
  note,
  setLinkedValue,
  keyIndex,
  parentkeyIndex,
  parentSetValue,
}: {
  note: any;
  setLinkedValue: any;
  keyIndex: string;
  parentkeyIndex: string;
  parentSetValue: any;
}) => {
  const [isDisplayEditMode, setIsDisplayEditMode] = useState(false);
  const { setValue, register, reset, getValues } = useForm();

  const handleEdit = () => {
    setValue("commentEdit", note);
    setIsDisplayEditMode(true);
  };

  const handleCancelEdit = () => {
    setIsDisplayEditMode(false);
    reset({ commentEdit: "" });
  };

  const handleDelete = async () => {
    setLinkedValue((prevState: IFormatedFormItem[]) => {
      const index = prevState?.findIndex(
        (note: IFormatedFormItem) => note.id === keyIndex
      );

      if (index !== -1) {
        const updateState = prevState.filter(
          (note: IFormatedFormItem) => note.id !== keyIndex
        );

        parentSetValue(`links[${parentkeyIndex}].linked_values`, [
          ...updateState,
        ]);
        return [...updateState];
      }

      return [...prevState];
    });
  };

  const handleEditNote = async () => {
    const { commentEdit } = getValues();
    if (!commentEdit) return;

    setLinkedValue((prevState: IFormatedFormItem[]) => {
      const index = prevState?.findIndex(
        (note: IFormatedFormItem) => note.id === keyIndex
      );
      if (index !== -1) {
        const updateState = prevState.map((item: IFormatedFormItem) =>
          item.id === keyIndex ? { ...item, note: commentEdit } : item
        );

        parentSetValue(`links[${parentkeyIndex}].linked_values`, [
          ...updateState,
        ]);
        return [...updateState];
      }

      return [...prevState];
    });

    reset({ commentEdit: "" });
    setIsDisplayEditMode(false);
  };
  return (
    <div className="d-flex mt-2">
      <div className=" d-flex flex-grow-1 ms-3 ">
        <div className="me-4 w-100">
          {isDisplayEditMode ? (
            <>
              <UiTextArea
                isDisplayLabel={false}
                name={"commentEdit"}
                register={register}
                className="mt-2"
                placeholder="Write your comment"
                rows={3}
              />
              <div className="d-flex justify-content-end mt-2 w-100 gap-3">
                <Badge
                  content="Cancel"
                  size={GENERAL_UI_SIZE.SMALL}
                  type={GENERAL_UI_COLOR.LIGHT}
                  onClick={handleCancelEdit}
                />
                <Badge
                  content="Edit"
                  onClick={handleEditNote}
                  size={GENERAL_UI_SIZE.SMALL}
                />
              </div>
            </>
          ) : (
            <p className={classNames("text-sm")}>{note}</p>
          )}
        </div>

        {!isDisplayEditMode && (
          <div className={classNames("d-flex")}>
            <div className={classNames("cursor-pointer")} onClick={handleEdit}>
              <i className={classNames("fa-solid fa-pen-to-square opacity-5 me-2 fa-sm",styles.icon)}></i>
            </div>
            <div
              className={classNames("cursor-pointer")}
              onClick={handleDelete}
            >
              <i className="fa-solid fa-x opacity-5 me-2 fa-sm"></i>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotePosting;
