import { longHyphen } from "../../constants/character";
import { numberFormat } from "../../utils/format";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import { PRODUCTS_LIST }  from "../../routings/path";

export const checkerSchema = [
  {
    name: "Checkers",
    cell: (data: any) => (
      <ProductTableCell
        title={data?.name}
        image={data?.avatar}
        maxWidth={100}
      />
    ),
  },
  {
    name: "Status",
    align: "center",
    cell: (data: any) => <StatusTableCell status={data?.status} />,
  },
];

export const productSchema = [
  {
    name: "Product",
    cell: (data: any) => (
      <ProductTableCell
        linkTo={`${PRODUCTS_LIST}/${data?.code}`}
        linkTarget="_blank"
        title={data?.name || longHyphen}
        description={data?.code || longHyphen}
        image={data?.image_url}
        maxWidth={250}
      />
    ),
  },
  {
    name: "Locations",
    align: "center",
    cell: (data: any) => data?.locations || longHyphen,
  },
  {
    name: "Status",
    align: "center",
    cell: (data: any) => (
      <StatusTableCell
        status={data?.check_status}
      />
    ),
  },
  {
    name: "GTIN Code",
    align: "right",
    textSize: "xs",
    cell: (data: any) => data?.gtin_code || longHyphen,
  },
];

export const unmatchedProductSchema = [
  {
    name: "Product",
    cell: (data: any) => (
      <ProductTableCell
        linkTo={`${PRODUCTS_LIST}/${data?.product?.code}`}
        linkTarget="_blank"
        title={data?.product?.name || longHyphen}
        description={data?.product?.code || longHyphen}
        image={data?.product?.image_url}
        maxWidth={250}
      />
    ),
  },
  {
    name: "System stock",
    cell: (data: any) => numberFormat(data?.system_stock),
  },
  {
    name: "Checked stock",
    cell: (data: any) => numberFormat(data?.checked_stock),
  },
  {
    name: "Diff stock",
    cell: (data: any) => numberFormat(Math.abs(data?.diff_stock)),
  },
  {
    name: "Status",
    align: "center",
    cell: (data: any) => (
      <StatusTableCell
        status={data?.stock_status}
      />
    ),
  },
  {
    name: "GTIN Code",
    align: "right",
    textSize: "xs",
    textColor: 'danger',
    cell: (data: any) => data?.product?.gtin_code || longHyphen,
  },
];

export const locationSchema = [
  {
    name: "Product",
    cell: (data: any) => (
      <ProductTableCell title={`# ${data?.code}`} maxWidth={150} />
    ),
  },
  {
    name: "Type",
    align: "center",
    textSize: "xs",
    cell: (data: any) => data?.location_type || longHyphen,
  },
  {
    name: "Zone",
    align: "center",
    textSize: "xs",
    cell: (data: any) => data?.zone || longHyphen,
  },
  {
    name: "Status",
    align: "right",
    cell: (data: any) => <StatusTableCell status={data?.status} />,
  },
];
