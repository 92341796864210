import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { generateUrlParams } from "../../utils/url";
import { get, patch, del, post } from "../config";

interface IGetMaterialListApi {
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
}

export interface ICreateMaterialApi {
  name?: string;
  size?: "small" | "medium" | "large" | "extra_large" | "extra_extra_large";
  box_type?: "normal" | "gift" | "special";
  width?: string;
  height?: string;
  length?: string;
  upload_image?: string;
}

export interface IUpdateMaterialApi {
  code?: string;
  name?: string;
  size?: "small" | "medium" | "large" | "extra_large" | "extra_extra_large";
  box_type?: "normal" | "gift" | "special";
  width?: string;
  height?: string;
  length?: string;
  upload_image?: string;
}

export const getMaterialListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
}: IGetMaterialListApi) =>
  get({
    path: `/wms/materials${generateUrlParams({ page, per, keyword })}`,
  });

export const getDeletedMaterialListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
}: IGetMaterialListApi) =>
  get({
    path: `/wms/materials/deleted${generateUrlParams({ page, per })}`,
  });

export const deleteMaterialApi = ({ code }: { code: string }) =>
  del({ path: `/wms/materials/${code}` });

export const restoreMaterialApi = ({ code }: { code: string }) =>
  patch({ path: `/wms/materials/${code}/restore` });

export const getMaterialDetailApi = ({ code }: { code: string }) =>
  get({ path: `/wms/materials/${code}` });

export const getMaterialBarCodeApi = ({ code }: { code: string }) =>
  get({ path: `/wms/materials/${code}/print` });

export const updateMaterialApi = ({
  code,
  name,
  size,
  box_type,
  width,
  height,
  length,
  upload_image,
}: IUpdateMaterialApi) =>
  patch({
    path: `/wms/materials/${code}`,
    data: {
      name,
      size,
      box_type,
      width,
      height,
      length,
      upload_image,
    },
  });

export const createMaterialApi = ({
  name,
  size,
  box_type,
  width,
  height,
  length,
  upload_image,
}: ICreateMaterialApi) =>
  post({
    path: `/wms/materials`,
    data: {
      name,
      size,
      box_type,
      width,
      height,
      length,
      upload_image,
    },
  });
