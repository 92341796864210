import classNames from "classnames";

interface IComponent {
  children?: any;
  className?: string;
}

const Component = ({ children, className }: IComponent) => {
  return (
    <div className={classNames("col-lg-12 col-xl-8 m-auto pt-4", className)}>
      {children}
    </div>
  );
};

export default Component;
