import { GENERAL_UI_SIZE } from "../../constants/size";
import { GENERAL_UI_COLOR } from "../../constants/color";

import styles from "./styles.module.scss";

export interface UiButtonPropsType {
  title?: string | React.ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
  size?: GENERAL_UI_SIZE;
  type?: GENERAL_UI_COLOR;
  onClick?: (param: any) => any;
  customType?: "button" | "submit";
  children?: any;
}

const UiButton = ({
  disabled = false,
  title = "Submit Button",
  isLoading = false,
  size = GENERAL_UI_SIZE.MEDIUM,
  type = GENERAL_UI_COLOR.PRIMARY,
  className,
  onClick = () => {},
  customType = "submit",
  children,
}: UiButtonPropsType) => {
  const customSize =
    size === GENERAL_UI_SIZE.MEDIUM
      ? "badge-md"
      : size === GENERAL_UI_SIZE.LARGE
      ? "badge-lg"
      : "badge-sm";

  return (
    <button
      type={customType}
      disabled={disabled || isLoading}
      className={`btn bg-gradient-${type} mb-0 px-3 ${className} ${customSize} ${styles.container}`}
      onClick={onClick}
    >
      {children}
      {isLoading ? "Loading..." : title}
    </button>
  );
};

export default UiButton;
