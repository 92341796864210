import TableCellStatus from "../../components/table-cell-status";
import { GENERAL_STATUS } from "../../constants/status";
import { GENERAL_UI_SIZE } from "../../constants/size";
import styles from "./styles.module.scss";

const Component = ({ warehouse }: { warehouse: any }) => {
  return (
    <>
      <div className={styles.selectedWarehouseHeading}>
        <label>Selected Warehouse</label>
        <TableCellStatus
          status={warehouse ? GENERAL_STATUS.SELECTED : GENERAL_STATUS.MISSING}
          size={GENERAL_UI_SIZE.SMALL}
        />
      </div>
      <div className={styles.selectedWarehouse}>
        {!warehouse ? (
          <span className={styles.empty}>No warehouse selected</span>
        ) : (
          <div className={styles.info}>
            <div className={styles.name}>{warehouse.name}</div>
            <div className={styles.address}>
              <i className="fa-solid fa-location-dot fa-sm opacity-4 me-1"></i>
              {warehouse.address.full_address}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Component;
