import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Link } from "react-router-dom";
import { BREADCRUMB_ROUTES } from "./routesConfig";

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(BREADCRUMB_ROUTES);

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
        {breadcrumbs.map(({ breadcrumb, match }, index) => (
          <li className="breadcrumb-item text-sm text-white" key={index}>
            <span key={match.pathname} className="text-white">
              <Link className="text-white" to={match.pathname}>
                {breadcrumb}
              </Link>
            </span>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
