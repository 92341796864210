import classnames from "classnames";
import styles from "./style.module.scss";

interface PropsType {
  title?: string;
  type?: string;
  isDisplayLabel?: boolean;
  isSmallLabel?: boolean;
  subLabel?: any;
  disabled?: boolean;
  viewMode?: boolean;
  name: string;
  id?: string;
  readOnly?: boolean;
  autoFocus?: boolean;
  inline?: boolean;
  className?: string;
  containerClassName?: string;
  register?: any;
  errorMessage?: string;
  onFocus?: (param: any) => any;
  onBlur?: (param: any) => any;
  onChange?: (param: any) => any;
  onClick?: (param: any) => any;
  onKeyUp?: (value: string) => any;
  placeholder?: string;
  hint?: string;
  help?: string;
  checked?: boolean;
  required?: boolean;
  value?: any;
  icon?: string;
  size?: "small" | "normal";
}

const UiInput = ({
  title,
  type = "text",
  isDisplayLabel = true,
  isSmallLabel = false,
  subLabel = null,
  name,
  id = "",
  disabled = false,
  viewMode = false,
  readOnly = false,
  autoFocus = false,
  inline = false,
  className = "",
  register = null,
  errorMessage = "",
  onFocus = () => {},
  onBlur = () => {},
  onChange = () => {},
  onClick = () => {},
  onKeyUp,
  placeholder,
  hint,
  help,
  checked,
  value,
  required = false,
  containerClassName = "mb-3",
  icon,
  size = "normal",
  ...rest
}: PropsType) => {
  let registerData = {};
  if (register)
    registerData = register(name, {
      onBlur: (e: any) => {
        onBlur && onBlur(e);
      },
    });

  let timer: any = null;
  const handleKeyUp = (event: any) => {
    event.preventDefault();
    if (!onKeyUp) return;

    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      onKeyUp(event.target.value);
    }, 350);
  };

  const inputClassNames = () => {
    return classnames(
      "form-group",
      inline && styles.inlineInput,
      errorMessage && errorMessage.length && styles.inputError,
      subLabel && styles.subLabelInput
    );
  };

  return (
    <div className={inputClassNames()}>
      {isDisplayLabel && (
        <label
          data-required={required}
          className={classnames(isSmallLabel && styles.isSmallLabel)}
        >
          {title || name}
          {subLabel && <span className={styles.subLabel}>{subLabel}</span>}
        </label>
      )}

      {help && (
        <a href={help} className="float-end" target="_blank" rel="noreferrer">
          <i className="fas fa-circle-question" />
        </a>
      )}

      <div className={classnames(containerClassName)}>
        <div className={styles.inputOuter}>
          {type === "checkbox" ? (
            <>
              {viewMode ? (
                <span>{checked ? "Yes" : "No"}</span>
              ) : (
                <div className="form-check">
                  <input
                    type="checkbox"
                    disabled={disabled}
                    className={classnames(
                      "form-check-input",
                      viewMode && styles.viewMode,
                      className
                    )}
                    placeholder={placeholder || title || name}
                    id={id}
                    checked={checked}
                    autoComplete="off"
                    onClick={onClick}
                    onKeyUp={handleKeyUp}
                    onChange={onChange}
                    {...registerData}
                  />
                </div>
              )}
            </>
          ) : (
            <input
              type={type}
              disabled={disabled || viewMode}
              className={classnames(
                viewMode && styles.viewMode,
                styles.input,
                type === "range" ? "w-100" : "form-control",
                type === "date" && styles.fullClickable,
                size === "small" && "form-control-sm",
                className
              )}
              placeholder={viewMode ? "-" : placeholder || title || name}
              aria-label={title}
              readOnly={readOnly}
              autoFocus={autoFocus}
              onClick={onClick}
              value={value}
              id={id}
              autoComplete="off"
              onKeyUp={handleKeyUp}
              onChange={onChange}
              {...registerData}
            />
          )}

          {icon && icon.length && <i className={`fas fa-${icon} text-sm`} />}
        </div>

        {hint && hint.length && <div className="form-text">{hint}</div>}

        {errorMessage && errorMessage.length && (
          <p className={styles.errorMessage}>{errorMessage}</p>
        )}
      </div>
    </div>
  );
};

export default UiInput;
