/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

export const useWindowResize = () => {
  const [windowSize, setWindowSize] = useState<{
    windowWidth: number;
    windowHeight: number;
  }>();

  useEffect(() => {
    if (typeof window === "undefined") return () => {};

    const handleResize = (): void => {
      setWindowSize({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });
    };

    if (!windowSize) handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const { windowWidth, windowHeight } = windowSize || {};
  
  return { windowWidth, windowHeight };
};
