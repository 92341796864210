import { useState } from "react";
import { useNavigate } from "react-router-dom";

import UiInput from "../../components/ui-input";
import MultiStepPanel from "../../components/panel-multi-step";
import HeaderInfoPanel from "../../components/panel-header-info";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import TypeSelection from "../../components/type-selection";
import TablePanel from "../../components/panel-table";
import UiButton from "../../components/ui-button";

import {
  createStockCheckApi,
  createStockCheckByProductUuidApi,
  getAvailableProductsByWarehouseApi,
} from "../../apis/paths/stock-check";
import { apiCaller } from "../../apis/config";
import { getWarehouseListApi } from "../../apis/paths/warehouse";
import * as R from "../../routings/path";
import { API_RESPONSE_STATUS } from "../../constants/api";
import alert from "../../utils/alert";

import { productSchema, warehouseSchema } from "./schema";
import SelectedWarehouse from "./view-selected-warehouse";
import SelectedProduct from "./view-selected-product";
import styles from "./styles.module.scss";

const Component = () => {
  const navigate = useNavigate();
  const [isSubmiting, setSubmiting] = useState(false);
  const [tabs, setTabs] = useState([
    "1. Types",
    "2. Warehouse",
    "3. Cycle Checks",
  ]);
  const [tabContentHeadings, setTabContentHeadings] = useState([
    "SELECT STOCK CHECKS TYPES",
    "SELECT WAREHOUSE",
    "SET CYCLE CHECKS",
  ]);
  const [currentActiveTab, setCurrentActiveTab] = useState(-1);
  const [validTypeErrorMessage, setValidTypeErrorMessage] = useState("");
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>(null);
  const [multiStepPanelTabIndex, setMultiStepPanelTabIndex] = useState(0);
  const [cycleChecks, setCycleChecks] = useState<any>("");

  const handleToggleTypes = (newTypeTabs: any) => {
    if (newTypeTabs.id === 0) {
      setTabs(["1. Types", "2. Warehouse", "3. Cycle Checks"]);
      setTabContentHeadings([
        "SELECT STOCK CHECKS TYPES",
        "SELECT WAREHOUSE",
        "SET CYCLE CHECKS",
      ]);
    }

    if (newTypeTabs.id === 1) {
      setTabs(["1. Types", "2. Warehouse", "3. Product"]);
      setTabContentHeadings([
        "SELECT STOCK CHECKS TYPES",
        "SELECT WAREHOUSE",
        "SELECT PRODUCT",
      ]);
    }

    setValidTypeErrorMessage("");
    setCurrentActiveTab(newTypeTabs.id);
  };

  const handleDeleteProduct = (deletedProduct: any) => {
    const newProducts = selectedProducts.filter(
      (product: any) => product.uuid !== deletedProduct.uuid
    );
    setSelectedProducts(newProducts);
  };

  const handleUpdateProduct = (updatedProduct: any) => {
    const newProducts = selectedProducts.map((product: any) =>
      product.uuid !== updatedProduct.uuid ? product : updatedProduct
    );

    setSelectedProducts(newProducts);
  };

  const handleAddProduct = (newProduct: any) => {
    const isExist = selectedProducts.some(
      (product: any) => product.uuid === newProduct.uuid
    );
    if (isExist) return;

    setSelectedProducts([
      {
        ...newProduct,
        quantity: 1,
      },
      ...selectedProducts,
    ]);
  };

  const handleCreate = async () => {
    if (currentActiveTab === 0) {
      if (!selectedWarehouse) {
        alert.warn("Please select Warehouse");
        return;
      }

      if (!cycleChecks) {
        alert.warn("Please select Cycle checks");
        return;
      }

      setSubmiting(true);
      const waitCreateStockCheck = await apiCaller({
        api: createStockCheckApi,
        params: {
          warehouseUuid: selectedWarehouse?.uuid,
          cycleChecks: cycleChecks * 1,
        },
      });
      setSubmiting(false);

      if (waitCreateStockCheck?.status === API_RESPONSE_STATUS.SUCCESS) {
        navigate(R.STOCK_CHECK_LIST);
      }
    } else {
      if (!selectedWarehouse) {
        alert.warn("Please select Warehouse");
        return;
      }

      if (!selectedProducts.length) {
        alert.warn("Please select Product");
        return;
      }

      setSubmiting(true);
      const waitCreateStockCheck = await apiCaller({
        api: createStockCheckByProductUuidApi,
        params: {
          warehouseUuid: selectedWarehouse?.uuid,
          productUuids: selectedProducts.map((product: any) => product.uuid),
        },
      });
      setSubmiting(false);

      if (waitCreateStockCheck?.status === API_RESPONSE_STATUS.SUCCESS) {
        navigate(R.STOCK_CHECK_LIST);
      }
    }
  };

  const handleChangeCycleChecks = (data: any) => {
    setCycleChecks(data?.target?.value);
  };

  const handleSetWarehouse = (data: any) => {
    setSelectedWarehouse(data);
    setSelectedProducts([]);
  };

  return (
    <>
      <HeaderInfoPanel
        className="mb-4"
        isDisplayInfoArea={true}
        title="Create new stock checks"
        description="Create new stock checking by warehouse"
        actionArea={() => (
          <>
            <UiButton
              onClick={handleCreate}
              className="me-2"
              disabled={isSubmiting}
              isLoading={isSubmiting}
              title={"Create"}
            />
          </>
        )}
      />
      <MultiStepPanel
        tabs={tabs}
        defaultTab={multiStepPanelTabIndex}
        contentHeadings={tabContentHeadings}
        onStaticCheckStepValidatation={(index: number) => {
          if ((index === 1 || index === 2) && currentActiveTab === -1) {
            setValidTypeErrorMessage("Please select stock check type first");
            return false;
          }

          return true;
        }}
        onChangeTab={(index: number) => setMultiStepPanelTabIndex(index)}
        contentCol={12}
      >
        <div>
          <TypeSelection
            description="Please select one of stock checks types below"
            errorMessage={validTypeErrorMessage}
            onSelect={handleToggleTypes}
            list={[
              {
                id: 0,
                icon: "fa-refresh",
                title: "By Cycle",
              },
              {
                id: 1,
                icon: "fa-tags",
                title: "By Product",
              },
            ]}
          />
        </div>
        <TwoColumnGridLayout
          widthDivision={[7, 5]}
          leftCol={
            <TablePanel
              apiPath={getWarehouseListApi}
              panelClassName={"mt-0"}
              type={"minimal"}
              tableSchema={warehouseSchema({
                selectedWarehouse: selectedWarehouse,
                onSelectWarehouse: handleSetWarehouse,
              })}
            />
          }
          rightCol={
            <div className={styles.selectWarehouse}>
              <SelectedWarehouse warehouse={selectedWarehouse} />
            </div>
          }
        />
        {currentActiveTab === 0 ? (
          <TwoColumnGridLayout
            widthDivision={[6, 6]}
            leftCol={
              <UiInput
                onChange={handleChangeCycleChecks}
                title={"Cycle Checks"}
                name={"cycle_checks"}
                className="mb-3"
                required
              />
            }
            rightCol={null}
          />
        ) : (
          <TwoColumnGridLayout
            widthDivision={[7, 5]}
            leftCol={
              <>
                {selectedWarehouse?.uuid ? (
                  <TablePanel
                    apiPath={getAvailableProductsByWarehouseApi}
                    apiParams={{ warehouseUuid: selectedWarehouse?.uuid || 0 }}
                    panelClassName={"mt-0"}
                    type={"minimal"}
                    tableSchema={productSchema({
                      onSelectProduct: handleAddProduct,
                      selectedProducts,
                      onUpdateItem: handleUpdateProduct,
                    })}
                  />
                ) : (
                  <div className={styles.missingWarehouse}>
                    Please select warehouse first
                  </div>
                )}
              </>
            }
            rightCol={
              <div className={styles.selectProduct}>
                <SelectedProduct
                  label={"Selected Product"}
                  products={selectedProducts}
                  onDeleteItem={handleDeleteProduct}
                  onUpdateItem={handleUpdateProduct}
                />
              </div>
            }
          />
        )}
      </MultiStepPanel>
    </>
  );
};

export default Component;
