import { useState } from "react";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import GeneralPanel from "../../components/panel-general";
import UiInput from "../../components/ui-input";

import { fieldTitle } from "./schema";
import GeoSearchSelector from "../../components/ui-geo-search-selector/component";

interface IViewWarehouseInformation {
  register: any;
  errors: any;
  ward: any;
  isSubwarehouse: boolean;
  addressValue: any;
  setAddressValue: any;
  setWardValue: any;
}
const ViewWarehouseInformation = ({
  register,
  errors,
  ward,
  isSubwarehouse,
  setWardValue,
}: IViewWarehouseInformation) => {
  const [isShowModal, setIsShowModal] = useState(false);

  return (
    <div className="">
      <GeoSearchSelector
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        onCancel={() => {
          setIsShowModal(false);
        }}
        setWardValue={setWardValue}
      />
      <GeneralPanel panel={{ subTitle: "Warehouse Information" }} className="">
        <TwoColumnGridLayout
          className={{ container: "px-0" }}
          leftCol={
            <>
              <UiInput
                required
                title={fieldTitle.name}
                name={"name"}
                register={register}
                errorMessage={errors?.name?.message}
              />
              <UiInput
                required
                title={fieldTitle.contactName}
                name={"contactName"}
                register={register}
                errorMessage={errors?.contactName?.message}
              />
              <UiInput
                required
                title={fieldTitle.contactPhone}
                type="number"
                name={"contactPhone"}
                placeholder={"Ex: 0985672143"}
                register={register}
                errorMessage={errors?.contactPhone?.message}
              />
            </>
          }
          rightCol={
            <>
              <UiInput
                title={fieldTitle.valuable}
                name={"valuable"}
                type="checkbox"
                isDisplayLabel
                register={register}
                containerClassName="mb-4 mt-2"
              />

              <UiInput
                title={fieldTitle.parentWarehouseUuid}
                name={"parentWarehouseUuid"}
                placeholder={isSubwarehouse ? ward : "This WH is Master"}
                disabled
                register={register}
                errorMessage={errors?.parentWarehouseUuid?.message}
              />
              <UiInput
                title={fieldTitle.storageType}
                name={"storageType"}
                placeholder={"Ex: packing, reserve..."}
                register={register}
                errorMessage={errors?.storageType?.message}
              />
            </>
          }
        />
      </GeneralPanel>

      <GeneralPanel panel={{ subTitle: "Address Information" }}>
        <TwoColumnGridLayout
          className={{ container: "px-0" }}
          leftCol={
            <>
              <UiInput
                title={fieldTitle.detail}
                disabled={isSubwarehouse}
                name={"detail"}
                register={register}
                errorMessage={errors?.detail?.message}
                placeholder={"Ex: No 4..."}
              />
              <UiInput
                title={fieldTitle.streetName}
                disabled={isSubwarehouse}
                name={"streetName"}
                register={register}
                errorMessage={errors?.streetName?.message}
                placeholder={"Ex: Pasteur st"}
              />
              <UiInput
                required
                title={fieldTitle.wardId}
                hint={"Search by ward, district, province name"}
                value={ward}
                disabled={isSubwarehouse}
                name={"wardId"}
                onClick={() => setIsShowModal(true)}
                register={register}
                errorMessage={errors?.wardId?.message}
              />
            </>
          }
          rightCol={
            <div className="row">
              <div className="col">
                <UiInput
                  title={fieldTitle.latitude}
                  disabled={isSubwarehouse}
                  name={"latitude"}
                  type="number"
                  register={register}
                  errorMessage={errors?.latitude?.message}
                  placeholder={"Ex: 10.826....."}
                  help={
                    "https://support.google.com/maps/answer/18539?hl=en&co=GENIE.Platform%3DDesktop&oco=0"
                  }
                />
              </div>
              <div className="col">
                <UiInput
                  title={fieldTitle.longitude}
                  disabled={isSubwarehouse}
                  name={"longitude"}
                  type="number"
                  register={register}
                  errorMessage={errors?.longitude?.message}
                  placeholder={"Ex: 106.626....."}
                  help={
                    "https://support.google.com/maps/answer/18539?hl=en&co=GENIE.Platform%3DDesktop&oco=0"
                  }
                />
              </div>
            </div>
          }
        />
      </GeneralPanel>
    </div>
  );
};

export default ViewWarehouseInformation;
