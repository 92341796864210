import { TABLE_ACTION } from "../../constants/table-action";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import ActionTableCell from "../../components/table-cell-action";
import DateTimeTableCell from "../../components/table-cell-date-time";

interface ISchema {
  onRestore?: any;
}

export const deletedRoleSchema = ({ onRestore }: ISchema) => [
  {
    name: "Name",
    cell: (data: any) => (
      <ProductTableCell
        title={data.name}
        description={data.description}
        maxWidth={400}
      />
    ),
  },
  {
    name: "Created At",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data.created_at} />,
  },
  {
    name: "Deleted At",
    align: "center",
    sortTypes: {
      key: "deleted_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data.deleted_at} />,
  },
  {
    name: "Status",
    align: "center",
    cell: (data: any) => <StatusTableCell status={data.status} />,
  },
  {
    name: "Action",
    align: "center",
    cell: (data: any) => {
      return (
        <ActionTableCell
          types={[TABLE_ACTION.RESTORE]}
          onRestore={() => onRestore(data.uuid)}
          className="justify-content-center"
        />
      );
    },
  },
];
