import Modal from "react-bootstrap/Modal";
import classNames from "classnames";

import { GENERAL_UI_COLOR } from "../../constants/color";
import UiButton from "../../components/ui-button";
import Typography from "../ui-typography/component";

interface IModalProps {
  isShowModal?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  isStylingButton?: boolean;
  titleClassName?: string;
  closeButton?: boolean;
  header?: React.ReactNode | string;
  children?: React.ReactNode | string;
  centered?: boolean;
  scrollable?: boolean;
  contentClassName?: string;
  size?: undefined | "sm" | "lg" | "xl";
  fullscreen?:
    | true
    | "sm-down"
    | "md-down"
    | "lg-down"
    | "xl-down"
    | "xxl-down";
  variant?: GENERAL_UI_COLOR;
  isShowFooter?: boolean;
  leftHeaderButton?: React.ReactNode;
  className?: {
    header?: string;
    body?: string;
    footer?: string;
    dialog?: string;
  };
  sideMode?: "" | "left" | "right";
}

function ModalGeneral({
  closeButton = true,
  header,
  children,
  variant = GENERAL_UI_COLOR.DANGER,
  centered = true,
  size = undefined,
  contentClassName,
  scrollable = false,
  fullscreen = "md-down",
  isShowModal,
  onCancel,
  onConfirm,
  isShowFooter = true,
  leftHeaderButton,
  className = {
    header: "",
    body: "",
    footer: "",
    dialog: "",
  },
  sideMode = "",
}: IModalProps) {
  return (
    <>
      <Modal
        show={isShowModal}
        className={classNames(sideMode)}
        onHide={onCancel}
        centered={centered}
        size={size}
        contentClassName={contentClassName}
        scrollable={scrollable}
        fullscreen={fullscreen}
        dialogClassName={classNames(className?.dialog, "modal-dialog-slideout")}
      >
        {header && (
          <Modal.Header
            closeButton={closeButton}
            className={className.header}
            style={{ paddingLeft: 20 }}
          >
            {leftHeaderButton && leftHeaderButton}
            <Typography
              fontSize="fs-5"
              fontWeight="fw-normal"
              className="text-center w-100"
            >
              {header}
            </Typography>
          </Modal.Header>
        )}

        <Modal.Body className={className.body}>{children}</Modal.Body>
        {isShowFooter && (
          <Modal.Footer style={{ padding: 5 }} className={className.footer}>
            <UiButton
              className="mb-2 me-3"
              title="Close"
              type={GENERAL_UI_COLOR.SECONDARY}
              onClick={() => {
                onCancel && onCancel();
              }}
            />
            <UiButton
              className="mb-2 "
              title="Submit"
              type={GENERAL_UI_COLOR.PRIMARY}
              onClick={onConfirm}
            />
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ModalGeneral;
