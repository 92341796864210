import { useState } from "react";

import { ITEM_PER_PAGE_LIST } from "../../constants/pagination";

const Component = ({
  onChange,
  type,
}: {
  onChange: Function;
  type: "minimal" | "normal";
}) => {
  const combinedPerPageList =
    type === "minimal" ? [7, ...ITEM_PER_PAGE_LIST] : ITEM_PER_PAGE_LIST;

  const [selected, setSelected] = useState(combinedPerPageList[0]);
  const onSelectChange = (event: any) => {
    onChange(event.target.value);
    setSelected(event.target.value);
  };

  return (
    <div className="dataTable-dropdown">
      <select
        className="dataTable-selector me-2"
        onChange={onSelectChange}
        value={selected}
      >
        {combinedPerPageList.map((item) => (
          <option selected={item === selected} value={item} key={item}>
            {item}
          </option>
        ))}
      </select>{" "}
    </div>
  );
};

export default Component;
