import React, { useState } from "react";
import classNames from "classnames";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { useApiCaller } from "../../apis/config";
import { getShipingOrdersDashboard } from "../../apis/paths/dashboard";
import { mapMonthStringToName } from "../../utils/date-time";
import styles from "../dashboard/styles.module.scss";
import UiTab from "../../components/ui-tab";
import Typography from "../../components/ui-typography/component";
import LineChart from "../../components/ui-multiple-line-chart/component";
import PanelGeneral from "../../components/panel-general/component";

import { SAMPLE_BACKGROUND_COLORS, SAMPLE_BORDER_COLORS } from "./utils";

interface IShippingOrder {
  warehouse_name: string;
  time: string;
  quantity: number;
}

const Component = () => {
  const [params, setParams] = useState<{
    page: number;
    per: number;
    group_by: string;
  }>({
    page: 1,
    per: 50,
    group_by: "day",
  });

  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const handleToggleUiTab = (newActiveTab: number, data: any) => {
    setCurrentActiveTab(newActiveTab);
    setParams((prev) => ({ ...prev, group_by: data?.toLowerCase() }));
  };

  const { result: shippingOrdersDashboard } = useApiCaller({
    api: getShipingOrdersDashboard,
    params,
  });

  const shippingOrdersTimeLine = shippingOrdersDashboard?.data?.list;

  const formatedTimeForDayTrendline = (time: string) => {
    const removedYearTime = time?.slice(0, -5);
    const formatedLongTypeMonth = mapMonthStringToName(
      removedYearTime?.slice(-2)
    );
    const day = removedYearTime?.slice(0, 2);
    return `${day}-${formatedLongTypeMonth}`;
  };

  const formatedTimeForMonthTrendline = (time: string) => {
    const year = time?.slice(-4);
    const formatedLongTypeMonth = mapMonthStringToName(time?.slice(0, 2));
    return `${formatedLongTypeMonth}-${year}`;
  };

  const mappedLabels = shippingOrdersTimeLine?.[0]?.map(
    (shippingOrder: IShippingOrder) => {
      return params?.group_by === "day"
        ? formatedTimeForDayTrendline(shippingOrder?.time)
        : formatedTimeForMonthTrendline(shippingOrder?.time);
    }
  );

  const mappedDataSets = (shippingOrdersOfWarehouse: any) =>
    shippingOrdersOfWarehouse?.map(
      (shippingOrder: IShippingOrder) => shippingOrder?.quantity
    );

  const data = {
    labels: mappedLabels,
    datasets: shippingOrdersTimeLine?.map(
      (shippingOrdersOfWarehouse: any, index: number) => ({
        label: shippingOrdersOfWarehouse?.[0]?.warehouse_name,
        data: mappedDataSets(shippingOrdersOfWarehouse),
        borderColor: SAMPLE_BORDER_COLORS[index],
        backgroundColor: SAMPLE_BACKGROUND_COLORS[index],
        options: {
          scales: {
            x: {
              ticks: {
                maxRotation: 90,
                minRotation: 90,
              },
            },
          },
        },
      })
    ),
  };

  const options = {
    responsive: true,
    plugins: {
      maintainAspectRatio: false,
      legend: {
        position: "bottom" as const,
        align: "center" as const,
      },
      title: {
        display: false,
        text: "",
      },
    },
  };

  const handleFullscreen = useFullScreenHandle();
  const isFullScreen = handleFullscreen.active;

  return (
    <PanelGeneral className="position-relative">
      <div className="d-flex justify-content-between my-2 align-items-center">
        <Typography
          className="text-center"
          customFontsize={14}
          fontWeight="fw-bold"
        >
          Dispatched SOs
        </Typography>
        <div className="w-35 d-flex gap-2 align-items-center justify-content-end">
          <UiTab
            tabs={["Day", "Month"]}
            selected={currentActiveTab}
            onChange={handleToggleUiTab}
          />
          {/* <div
            className="d-flex align-items-center cursor-pointer"
            onClick={handleFullscreen.enter}
          >
            <i className="fa-solid fa-expand"></i>
          </div> */}
        </div>
      </div>
      <FullScreen handle={handleFullscreen}>
        <div
          className={classNames(
            isFullScreen && "w-100 h-100 d-flex align-items-center",
            styles.lineChartContainer,
          )}
        >
          {data && data.datasets && (
            <LineChart
              data={data}
              options={options}
              className={styles.fullScreenChildComponent}
              styles={{ maxHeight: !isFullScreen ? 275 : 500 }}
            />
          )}
        </div>
      </FullScreen>
    </PanelGeneral>
  );
};

export default Component;
