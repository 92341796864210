import * as yup from "yup";

import * as R from "../../routings/path";
import { TABLE_ACTION } from "../../constants/table-action";
import { GENERAL_STATUS } from "../../constants/status";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { generateGoogleMapLink } from "../../utils/url";
import { validationMessage } from "../../utils/validation";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import ActionTableCell from "../../components/table-cell-action";
import { ACTIONABLE_TYPE } from "../../constants/actionable";
import styles from "./styles.module.scss";
import UiButton from "../../components/ui-button/component";
import { GENERAL_UI_SIZE } from "../../constants/size";

export const fieldTitle = {
  channel: "Channel",
  referenceNumber: "Reference Number",
  selectProvince: "Select Ward / District / Province",
  streetName: "Street Name",
  address_detail: "Address Detail",
  receiverName: "Receiver Name",
  receiverPhone: "Receiver Phone",
  cod: "COD",
  value: "Value",
};

type FieldSchema = keyof typeof fieldTitle;
export const infoSchema = yup.object().shape({
  referenceNumber: yup
    .string()
    .required(({ path }) =>
      validationMessage.required(fieldTitle[path as FieldSchema])
    ),
  wardId: yup.string().required(),
  streetName: yup
    .string()
    .required(({ path }) =>
      validationMessage.required(fieldTitle[path as FieldSchema])
    ),
  address_detail: yup.string().nullable(),
  receiverName: yup
    .string()
    .required(({ path }) =>
      validationMessage.required(fieldTitle[path as FieldSchema])
    ),
  receiverPhone: yup
    .string()
    .required(({ path }) =>
      validationMessage.required(fieldTitle[path as FieldSchema])
    ),
  cod: yup.number().nullable(),
  value: yup.number().nullable(),
  product_status: yup
    .string()
    .oneOf(["saleable", "usable", "damaged"])
    .nullable(),
});

export const warehouseSchema = ({
  selectedWarehouse,
  onSelectWarehouse,
}: {
  selectedWarehouse: any;
  onSelectWarehouse: any;
}) => [
  {
    name: "Warehouse",
    cell: (data: any) => (
      <ProductTableCell
        title={data.name}
        linkTo={generateGoogleMapLink({
          latitude: data.address.latitude,
          longitude: data.address.longitude,
        })}
        linkTarget={"_blank"}
        subIcon={"fa-location-dot"}
        description={data.address.full_address}
        descriptionLine={2}
        maxWidth={150}
      />
    ),
  },
  {
    name: "Storage Type",
    align: "center",
    cell: (data: any) => (
      <StatusTableCell
        status={data.storage_type}
        type={GENERAL_UI_COLOR.SECONDARY}
      />
    ),
  },

  {
    name: "Status",
    align: "center",
    cell: (data: any) => (
      <StatusTableCell
        status={
          data.deleted_at ? GENERAL_STATUS.DEACTIVE : GENERAL_STATUS.ACTIVATE
        }
      />
    ),
  },
  {
    name: "Action",
    align: "right",
    cell: (data: any) => {
      const isExist = selectedWarehouse?.uuid === data.uuid;
      if (isExist) return <div> Added</div>;

      return (
        <ActionTableCell
          types={[TABLE_ACTION.CUSTOM]}
          customActionableType={ACTIONABLE_TYPE.FULL}
          customTitle={"Select"}
          onCustomClick={() => onSelectWarehouse(data)}
        />
      );
    },
  },
];

export const productSchema = ({
  onSelectProduct,
  selectedProducts,
  onUpdateItem,
  onChangeProductQty,
  inputingProductQty,
}: {
  onSelectProduct: any;
  selectedProducts: Array<any>;
  onUpdateItem: any;
  onChangeProductQty: any;
  inputingProductQty: Record<string, number>;
}) => [
  {
    name: "Product",
    cell: (data: any) => (
      <ProductTableCell
        linkTo={`${R.PRODUCTS_LIST}/${data.code}`}
        image={data.image_url}
        title={data.name}
        description={data.code}
        maxWidth={150}
        imageSize={40}
      />
    ),
  },
  {
    name: "Unit",
    cell: (data: any) => data.unit,
  },
  {
    name: "Capacity",
    sortTypes: {
      key: "capacity_value",
      value: ["asc", "desc"],
    },
    align: "right",
    cell: (data: any) => (
      <ProductTableCell
        title={data.capacity_value || "-"}
        description={data.capacity_unit}
      />
    ),
  },
  {
    name: "Status",
    align: "center",
    cell: (data: any) => <StatusTableCell status={data.status} />,
  },
  {
    name: "Action",
    align: "center",
    textSize: "xs",
    cell: (data: any) => {
      const isExist = selectedProducts?.some(
        (product: any) => product.uuid === data.uuid
      );

      if (isExist) {
        return <div className={styles.actionInput}>Added</div>;
      }

      return (
        <div className="d-flex gap-2 justify-content-center">
          <div className={styles.actionInput}>
            <input
              title={"Quantity"}
              name={"quantity"}
              placeholder={" "}
              min={1}
              value={inputingProductQty?.[data?.uuid] || 1}
              onChange={(e) => {
                onChangeProductQty((prev: any) => ({
                  ...prev,
                  [data?.uuid]: parseInt(e.target.value),
                }));
              }}
              className={styles.input}
              type="number"
              required
            />
          </div>
          <div>
            <UiButton
              customType="button"
              onClick={() => {
                onSelectProduct(data);
              }}
              title={"Select"}
              size={GENERAL_UI_SIZE.XSMALL}
              type={GENERAL_UI_COLOR.LIGHT}
            />
          </div>
        </div>
      );
    },
  },
];
