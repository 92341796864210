import { useEffect, useState } from "react";

import { useApiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { getAccountListApi } from "../../apis/paths/notification";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";

export const useNotifications = ({ page }: { page: number }) => {
  const [notifications, setNotifications] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(-1);

  const [pagingParams, setPaging] = useState<{
    page: number;
    per: number;
    keyword: string;
    sort: {
      field: string;
      direction: string;
    } | null;
  }>({ ...DEFAULT_PAGINATION_PARAMS, per: 10 });

  const { result, loading } = useApiCaller({
    api: getAccountListApi,
    params: pagingParams,
  });

  useEffect(() => {
    if (page !== 1 && totalPage !== -1 && page <= totalPage) {
      setPaging({
        ...pagingParams,
        page,
      });
    }
  }, [page]);

  useEffect(() => {
    if (result?.status === API_RESPONSE_STATUS.SUCCESS) {
      if (result?.data?.paging?.current_page > currentPage) {
        setCurrentPage(result?.data?.paging?.current_page);
        setNotifications([...notifications, ...result?.data?.list]);
        totalPage === -1 && setTotalPage(result?.data?.paging?.total_pages);
      }
    }
  }, [result]);

  return { notifications, totalPage, loading };
};
