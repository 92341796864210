import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { formatUpperCase } from "../../utils/format";
import { apiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { updateConfigurationRequiredKeys } from "../../apis/paths/shipping";
import UiInput from "../../components/ui-input";
import alert from "../../utils/alert";
import UiForm from "../../components/ui-form";

import { formSchema } from "./formSchema";

const RequiredKeyItem = ({
  requiredKey,
  code,
}: {
  requiredKey: string;
  code: string;
}) => {
  
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const handleConfigurationRequireKey = async ({ value }: any) => {
    const waitUpdateProduct = await apiCaller({
      api: updateConfigurationRequiredKeys,
      params: { code, value, key: requiredKey },
    });

    if (waitUpdateProduct.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update successful!");
      reset({ value: "" });
      return;
    }

    alert.error("Update was not successful!. Please try again!");
  };
  return (
    <div
      key={requiredKey}
      className="d-flex bg-gray-100 border-radius-lg p-2 my-2 justify-content-between align-items-center "
    >
      <p className="text-sm font-weight-bold my-auto ps-sm-2">
        {formatUpperCase(requiredKey)}
      </p>
      <UiForm
        onSubmit={handleSubmit(handleConfigurationRequireKey)}
        className="w-40"
      >
        <div className="d-flex align-items-center gap-3">
          <UiInput
            containerClassName="flex-grow-1"
            name={"value"}
            placeholder="xxxxxxxx"
            isDisplayLabel={false}
            register={register}
            errorMessage={errors?.value?.message}
          />
          <button
            className="btn btn-sm bg-gradient-primary my-sm-auto mt-2 mb-0"
            type="submit"
          >
            Add
          </button>
        </div>
      </UiForm>
    </div>
  );
};

export default RequiredKeyItem;
