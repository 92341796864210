/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { areArraysEqual } from "../utils/compare";

export const useKeyPress = (targetKeys: string[], resetTime = 100) => {
  const [groupKeys, setGroupKeys] = useState<string[]>([]);
  const timerRef = useRef<any>();

  const downHandler = (event: any) => {
    const targetOnBody = event?.target?.tagName === "BODY";
    if (targetOnBody) {
      setGroupKeys((prev: string[]) => [...prev, event?.key]);
    }
  };

  const upHandler = () => {
    timerRef.current = setTimeout(() => setGroupKeys([]), resetTime);
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
      clearTimeout(timerRef.current);
    };
  }, []);

  return areArraysEqual(targetKeys, groupKeys);
};
