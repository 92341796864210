import { GENERAL_UI_COLOR } from "../../constants/color";
import Typography from "../../components/ui-typography/component";
import UiButton from "../../components/ui-button/component";

import { CountdownCircleTimer } from "react-countdown-circle-timer";

const ViewAutoPrintSection = ({
  duration = 3,
  isPlaying = true,
  onCancel,
  onComplete,
}: {
  duration: number;
  isPlaying: boolean;
  onCancel: () => void;
  onComplete: () => void;
}) => {
  return (
    <div className="d-flex justify-content-between gap-2 ">
      <Typography
        className="mt-1 d-flex gap-2 align-items-center"
        fontWeight="fw-normal"
      >
        Auto print in
        <CountdownCircleTimer
          isPlaying={isPlaying}
          duration={duration}
          size={40}
          rotation="clockwise"
          initialRemainingTime={4}
          isSmoothColorTransition
          strokeWidth={5}
          colors={["#004777", "#F7B801", "#c59f0a", "#A30000"]}
          colorsTime={[3, 2, 1, 0]}
          onComplete={onComplete}
        >
          {({ remainingTime }) => (
            <Typography customFontsize={14} fontWeight="fw-bold">
              {remainingTime}
            </Typography>
          )}
        </CountdownCircleTimer>
      </Typography>
      <UiButton
        onClick={() => {
          onCancel();
        }}
        type={GENERAL_UI_COLOR.DANGER}
        title={"Cancel"}
      />
    </div>
  );
};

export default ViewAutoPrintSection;
