import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import ResetPasswordAccountForm from "./view-account-reset-password-form";
import { formSchema } from "./formSchema";

import {
  getAccountDetailApi,
  resetPasswordApi,
} from "../../apis/paths/accounts";
import { apiCaller, useApiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { IAccountUser } from "../../types/account";
import alert from "../../utils/alert";
import UiForm from "../../components/ui-form";
import HeaderInfoPanel from "../../components/panel-header-info";
import GridLayoutTwoColumn from "../../components/grid-layout-two-column/component";
import Typography from "../../components/ui-typography/component";
import UiButton from "../../components/ui-button";
import NotFoundPanel from "../../components/panel-not-found";

interface IResetPassword {
  password: string;
  password_confirmation: string;
}
const Component = () => {
  const [accountInfoData, setAccountInfoData] = useState<IAccountUser>({});

  const { accountId } = useParams();
  const uuid = accountId || "";
  const navigate = useNavigate();

  const [params] = useState<{
    page: number;
    per: number;
    uuid: string;
  }>({
    page: 1,
    per: 50,
    uuid,
  });

  const { result: accountInfo } = useApiCaller({
    api: getAccountDetailApi,
    params,
  });

  useEffect(() => {
    setAccountInfoData(accountInfo?.data);
  }, [accountInfo]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async ({
    password,
    password_confirmation,
  }: IResetPassword) => {
    const waitUpdateRole = await apiCaller({
      api: resetPasswordApi,
      params: { uuid, password, password_confirmation },
    });

    if (waitUpdateRole.status === API_RESPONSE_STATUS.SUCCESS) {
      setTimeout(() => {
        alert.success("Update password successful!");
      }, 1000);
      navigate(-1);
      return;
    }

    alert.error("Update was not successful!. Please try again!");
  };

  if (accountInfo?.status === "ERROR") return <NotFoundPanel />;

  return (
    <>
      <UiForm onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <HeaderInfoPanel
            isDisplayInfoArea={true}
            title={accountInfoData?.name}
            image={accountInfoData?.avatar}
            actionArea={() => <UiButton className="me-2" title={"Update"} />}
          />
        </div>
        <GridLayoutTwoColumn
          className={{ container: "ps-0 pe-0" }}
          leftCol={
            <ResetPasswordAccountForm errors={errors} register={register} />
          }
          rightCol={
            <div>
              <Typography fontWeight="fw-bold">
                Password requirements
              </Typography>
              <Typography fontSize="fs-6" className="mt-3">
                Please follow this guide for a strong password:
              </Typography>
              <ul>
                <li className="pt-2">
                  <Typography>One special characters</Typography>
                </li>
                <li className="pt-2">
                  <Typography>Min 6 characters</Typography>
                </li>
                <li className="pt-2">
                  <Typography>One number (2 are recommended)</Typography>
                </li>
                <li className="pt-2">
                  <Typography>Change it often</Typography>
                </li>
              </ul>
            </div>
          }
        />
      </UiForm>
    </>
  );
};

export default Component;
