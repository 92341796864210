import React from "react";
import Select, { StylesConfig } from "react-select";
import classNames from "classnames";

import { IOptionItem } from "../../types/tpl";
import Typography from "../../components/ui-typography/component";

import styles from "./style.module.scss";

const ItemProduct = ({ option }: { option: IOptionItem }) => (
  <div
    className={classNames(
      "d-flex gap-2 align-items-center cursor-pointer p-2",
      styles.hoverEffect
    )}
    key={option?.value}
  >
    <img
      src={option?.image}
      alt="option"
      className=""
      style={{ height: 30, width: 30 }}
    />
    <Typography> {option?.content}</Typography>
  </div>
);

const ItemNormal = ({ option }: { option: IOptionItem }) => (
  <div
    className={classNames(
      "d-flex gap-2 align-items-center cursor-pointer p-2",
      styles.hoverEffect
    )}
    key={option?.value}
  >
    <Typography> {option?.content}</Typography>
  </div>
);

const AddTplProductSearchBox = ({
  setSelectedItems,
  formItem,
  optionList,
  selectedLinkType,
}: {
  setSelectedItems: any;
  formItem: any;
  optionList: any[];
  selectedLinkType: string;
}) => {
  const controlStyles: StylesConfig<any, true> = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: "",
      width: "100%",
      borderRadius: "0.5rem",
    }),
    menuList: (base: any) => ({
      ...base,
      maxHeight: "250px",
      borderRadius: "0.5rem",
      fontSize: "13px",
    }),
    menuPortal: (base, _props) => ({ ...base, zIndex: 9999 }),
  };

  const mappedOptions = optionList?.map((option: any) => ({
    value: option.value,
    label: option.content,
    customComponent:
      selectedLinkType === "Product" ? (
        <ItemProduct option={option} />
      ) : (
        <ItemNormal option={option} />
      ),
  }));

  const defaultOpitonValue = mappedOptions?.filter((option: any) => {
    return formItem?.linked_object_uuids?.includes(option.value.toString());
  });

  const CustomOption = ({ innerProps, data }: any) => (
    <div {...innerProps}>{data.customComponent && data.customComponent}</div>
  );

  return (
    <div className="mb-3">
      <label>Values</label>

      <Select
        menuPortalTarget={document.body}
        options={mappedOptions}
        components={{ Option: CustomOption }}
        isSearchable
        styles={controlStyles}
        isMulti
        defaultValue={defaultOpitonValue}
        onChange={(choice: any) => setSelectedItems(choice)}
      />
    </div>
  );
};

export default AddTplProductSearchBox;
