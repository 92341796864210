interface IParameterInfo {
  description?: string;
  format?: string;
  in?: string;
  maximum?: number;
  minimum?: number;
  name?: string;
  required?: boolean;
  type?: string;
}

export const generateDynamicYupValidation = (parameters?: IParameterInfo[]) => {
  return parameters?.map((parameter: IParameterInfo) => {
    return {
      [parameter.name as string]: parameter?.name,
    };
  });
};

export const splitFieldByBrackets = (text: string) => {
  const splitTexts = text?.split("[");

  return {
    parentText: splitTexts[0],
    childText: splitTexts[1]?.slice(0, -1),
  };
};

export const convertDashFieldToBracketField = (text: string) => {
  const splitTexts = text?.split("-");

  return `${splitTexts[0]}[${splitTexts[1]}]`;
};

export const extractSlaFields = (data:Record<string,any>) => {
    const uniqueAccessModels = new Set();

    Object.keys(data)?.forEach((item) => {
      uniqueAccessModels.add(item);
    });
    return Array.from(uniqueAccessModels);
  };