import React from "react";
import PopupConfirmation from "../popup-confirmation/component";
import Typography from "../ui-typography/component";
import { Button } from "react-bootstrap";
import { GENERAL_UI_COLOR } from "../../constants/color";
import classNames from "classnames";

interface IDeleteConfirmationPopupProps {
  content: string | React.ReactNode;
  isShowModal: boolean;
  handleCloseModal?: () => void;
  onConfirm: () => void;
  confirmationButton?: string;
  variant?: GENERAL_UI_COLOR;
  textColor?: string;
  children?: React.ReactNode;
}

const QuickActionPopupConfirmation = ({
  isShowModal,
  handleCloseModal,
  onConfirm,
  content,
  confirmationButton = "Delete",
  variant = GENERAL_UI_COLOR.DANGER,
  textColor = "#f5365c",
  children,
}: IDeleteConfirmationPopupProps) => {
  return (
    <PopupConfirmation
      isShowModal={isShowModal}
      onCloseModal={handleCloseModal}
    >
      <div>
        <Typography
          fontSize="fs-5"
          fontWeight="fw-normal"
          textColor={textColor}
          className="p-4 ps-3 pe-3"
        >
          <i className="fa-solid fa-circle-exclamation me-2"></i>
          {content}
        </Typography>
        {children}
        <div className="d-flex mt-3 justify-content-end">
          <Button
            className="mb-2 me-3"
            variant=""
            size="sm"
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            className={classNames("mb-2", !variant && "bg-gradient-danger")}
            variant={variant}
            onClick={() => {
              handleCloseModal && handleCloseModal();
              onConfirm?.();
            }}
          >
            {confirmationButton}
          </Button>
        </div>
      </div>
    </PopupConfirmation>
  );
};

export default QuickActionPopupConfirmation;
