import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

interface IGridLayoutProps {
  fluid?: boolean;
  leftCol: any;
  rightCol: any;
  centerCol: any;
  widthDivision?: [number, number, number];
  className?: any;
}
const GridLayoutTwoColumn = ({
  fluid = false,
  leftCol,
  rightCol,
  centerCol,
  widthDivision = [3, 6, 3],
  className,
}: IGridLayoutProps) => {
  return (
    <Container fluid={fluid} className={`px-0 ${className}`}>
      <Row>
        <Col md={widthDivision[0]}>{leftCol}</Col>
        <Col md={widthDivision[1]}>{centerCol}</Col>

        <Col md={widthDivision[2]}>{rightCol}</Col>
      </Row>
    </Container>
  );
};

export default GridLayoutTwoColumn;
