import { formatDateTime } from "../../utils/date-time";
import { longHyphen } from "../../constants/character";
import styles from "./styles.module.scss";

interface IDateTimeTableCell {
  className?: string;
  timeStamp: number;
  formatTimestamp?: {
    time?: string;
    tooltip?: string;
  };
}

const DateTimeTableCell = ({
  className = "",
  timeStamp,
  formatTimestamp = {
    time: "YYYY-MM-DD",
    tooltip: "lll",
  },
}: IDateTimeTableCell) => {
  if (!timeStamp) return <div className={styles.empty}>{longHyphen}</div>;

  return (
    <div className={styles.container}>
      <div className={styles.time}>
        {formatDateTime(timeStamp, formatTimestamp?.time)}
      </div>
      <div className={styles.tooltip}>
        {formatDateTime(timeStamp, formatTimestamp?.tooltip)}
      </div>
    </div>
  );
};

export default DateTimeTableCell;
