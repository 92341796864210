import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import classNames from "classnames";

import { apiCaller, useApiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { userStateType } from "../../states/user.flow";
import { createNoteApi, getNotesApi } from "../../apis/paths/note";
import alert from "../../utils/alert";
import Avatar from "../ui-avatar/component";
import UiButton from "../../components/ui-button";
import UiTextArea from "../../components/ui-text-area";
import NotFoundPanel from "../panel-not-found/component";

import { INote } from "./types";
import NotePosting from "./view-note-posting";
import GeneralHeading from "../ui-general-heading/component";

interface IPanelNote {
  notable_uuid: string;
  notable_type: string;
  setNoteQuantity: any;
  isDisplayHeading?: boolean;
  className?: {
    container?: string;
    container1?: string;
    card?: string;
    cardBody?: string;
    heading?: string;
    editArea?: string;
  };
  displayLimit?: number;
  isNewNoteAddable?: boolean;
  isNoteEditable?: boolean;
}

const PanelNote = ({
  notable_uuid,
  notable_type,
  setNoteQuantity,
  isDisplayHeading = false,
  className = {
    container: "",
    container1: "",
    card: "",
    cardBody: "",
    heading: "",
    editArea: "mt-4",
  },
  displayLimit = 3,
  isNewNoteAddable = true,
  isNoteEditable = true,
}: IPanelNote) => {
  const [pageReloadVersion, setPageReloadVersion] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [noteParams, setNoteParams] = useState<{
    page: number;
    per: number;
    notable_uuid: string;
    notable_type: string;
  }>({
    page: 1,
    per: 50,
    notable_uuid,
    notable_type,
  });

  useEffect(() => {
    setNoteParams((prevState) => ({
      ...prevState,
      notable_uuid,
      notable_type,
    }));
  }, [notable_uuid, notable_type]);

  const { result: notes } = useApiCaller({
    api: getNotesApi,
    params: noteParams,
    pageReloadVersion,
  });

  const account = useSelector(
    (state: { user: userStateType }) => state.user.loginInfo.account
  );

  useEffect(() => {
    if (notes?.data?.list) {
      setNoteQuantity(notes?.data?.list?.length);
    }
  }, [notes]);

  const { avatar, name, uuid } = account || {};

  const { register, handleSubmit, reset, watch } = useForm();

  const handleAddNote = async ({ comment }: any) => {
    const waitUpdateProduct = await apiCaller({
      api: createNoteApi,
      params: {
        content: comment,
        notable_type,
        notable_uuid,
      },
    });

    if (waitUpdateProduct.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update successful!");
      reset({ comment: "" });
      setPageReloadVersion((prev) => prev + 1);
      return;
    }

    alert.error("Update was not successful!. Please try again!");
  };

  const noteItems = useMemo(
    () =>
      isCollapsed
        ? notes?.data?.list?.slice()?.reverse()?.slice(0, displayLimit)
        : notes?.data?.list?.slice()?.reverse(),
    [notes, displayLimit, isCollapsed]
  );

  if (!notable_uuid || !notable_type) return null;

  if (notes?.status === "ERROR") return <NotFoundPanel />;

  const inputText = watch("comment");

  return (
    <div className={classNames("col-12", className.container)}>
      <div className={classNames("card h-100", className.card)}>
        <div className={classNames("card-body", className.cardBody)}>
          <div className="d-flex flex-column">
            {isDisplayHeading && (
              <GeneralHeading className={classNames(className.heading,"mb-3")}>
                Notes
              </GeneralHeading>
            )}
            <div
              className={classNames(
                "mb-1 d-flex flex-column",
                className.container1
              )}
            >
              {noteItems?.map((note: INote) => (
                <div key={note.id}>
                  <NotePosting
                    note={note}
                    isNoteEditable={isNoteEditable}
                    setPageReloadVersion={setPageReloadVersion}
                    uuid={uuid as string}
                  />
                </div>
              ))}
              {notes?.data?.list?.length > displayLimit && (
                <UiButton
                  className="mt-3 mb-3 me-auto"
                  onClick={() => setIsCollapsed((prev) => !prev)}
                  title={isCollapsed ? "View more" : "View less"}
                  size={GENERAL_UI_SIZE.SMALL}
                  type={GENERAL_UI_COLOR.LIGHT}
                />
              )}

              {notes?.data?.list?.length > 0 && isNewNoteAddable && (
                <hr className="horizontal dark mt-0 " />
              )}

              {isNewNoteAddable && (
                <div className={classNames("d-flex", className.editArea)}>
                  <div className="flex-shrink-0 me-3">
                    <Avatar
                      imageSrc={avatar}
                      name={name}
                      variant={GENERAL_UI_COLOR.SECONDARY}
                      size="lg"
                      allowDynamicBackgroundColor
                    />
                  </div>
                  <div className="flex-grow-1 my-auto">
                    <form onSubmit={handleSubmit(handleAddNote)}>
                      <UiTextArea
                        title={"Add Comment"}
                        name={"comment"}
                        register={register}
                        placeholder="Write your comment"
                        rows={2}
                      />
                      {!!inputText && (
                        <div className="d-flex justify-content-end mt-2">
                          <UiButton className="ms-auto" title={"Add"} />
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanelNote;
