import React, { memo, useState } from "react";
import classNames from "classnames";
import { useForm } from "react-hook-form";

import { apiCaller } from "../../apis/config";
import { deleleNoteApi, updateNoteApi } from "../../apis/paths/note";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { formatDateTime } from "../../utils/date-time";
import UiTextArea from "../../components/ui-text-area";
import alert from "../../utils/alert";
import Badge from "../ui-badge-button/component";
import Avatar from "../ui-avatar/component";
import Typography from "../ui-typography/component";

import { INote } from "./types";
import OverlayAction from "./view-overlay-action";

const NotePosting = ({
  note,
  setPageReloadVersion = () => {},
  uuid,
  isNoteEditable = true,
}: {
  note: INote;
  setPageReloadVersion?: (params: any) => void;
  uuid?: string;
  isNoteEditable?: boolean;
}) => {
  const [isDisplayEditMode, setIsDisplayEditMode] = useState(false);
  const { account, content, created_at, id } = note || {};
  const { avatar, name, uuid: noteAccountUuid } = account || {};
  const { setValue, register, handleSubmit, reset } = useForm();

  const handleEdit = () => {
    setValue("commentEdit", content);
    setIsDisplayEditMode(true);
  };

  const handleCancelEdit = () => {
    setIsDisplayEditMode(false);
    reset({ commentEdit: "" });
  };

  const handleDelete = async () => {
    const waitDelete = await apiCaller({
      api: deleleNoteApi,
      params: { id },
    });

    if (waitDelete.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Delete successful!");

      setPageReloadVersion((prev: any) => prev + 1);
      return;
    }

    alert.error("Delete was unsuccessful!. Please try again");
  };

  const handleEditNote = async ({ commentEdit }: any) => {
    const waitUpdateNote = await apiCaller({
      api: updateNoteApi,
      params: {
        content: commentEdit,
        id,
      },
    });

    if (waitUpdateNote.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Adding note successful!");
      reset({ commentEdit: "" });
      setIsDisplayEditMode(false);
      setPageReloadVersion((prev: any) => prev + 1);
      return;
    }

    alert.error("Adding note was not successful!. Please try again!");
  };

  return (
    <div className="d-flex mt-2">
      <div className="flex-shrink-0">
        <Avatar
          imageSrc={avatar}
          name={name}
          variant={GENERAL_UI_COLOR.SECONDARY}
          size="lg"
          allowDynamicBackgroundColor
        />
      </div>
      <div className=" d-flex flex-grow-1 ms-3 ">
        <div className="me-4 w-100">
          <div className="d-flex gap-2">
            <Typography fontWeight="fw-bolder" customFontsize={12}>
              {name}
            </Typography>
            <Typography customFontsize={12}>
              {formatDateTime(created_at as number, "YYYY-MM-DD  HH:mm")}
            </Typography>
          </div>
          {isDisplayEditMode ? (
            <form onSubmit={handleSubmit(handleEditNote)}>
              <UiTextArea
                isDisplayLabel={false}
                name={"commentEdit"}
                register={register}
                className="mt-2"
                placeholder="Write your comment"
                rows={3}
              />
              <div className="d-flex justify-content-end mt-2 w-100 gap-3">
                <Badge
                  content="Cancel"
                  size={GENERAL_UI_SIZE.SMALL}
                  type={GENERAL_UI_COLOR.LIGHT}
                  onClick={handleCancelEdit}
                />
                <Badge
                  content="Add"
                  buttonType="submit"
                  size={GENERAL_UI_SIZE.SMALL}
                />
              </div>
            </form>
          ) : (
            <p className={classNames("text-sm")}>{content}</p>
          )}
        </div>
        {uuid === noteAccountUuid && isNoteEditable && (
          <OverlayAction handleEdit={handleEdit} handleDelete={handleDelete} />
        )}
      </div>
    </div>
  );
};

export default memo(NotePosting);
