import { generateUrlParams } from "../../utils/url";
import { get } from "../config";

export const getInventoryFlow = () =>
  get({ path: `/wms/dashboards/inventory_flow` });

export const getShipingOrdersDashboard = ({ group_by }: { group_by: string }) =>
  get({
    path: `/wms/dashboards/shipping_orders${generateUrlParams({
      group_by,
    })}`,
  });

export const getTaskStatusDashboard = ({
  startDate,
  endDate,
}: {
  startDate: number;
  endDate: number;
}) =>
  get({
    path: `/wms/dashboards/task_statuses${generateUrlParams({
      start_date: startDate,
      end_date: endDate,
    })}`,
  });

export const getSpotlightsApi = ({ keyword }: { keyword: string }) => {
  if (!keyword) return;

  return get({
    path: `/wms/spotlights${generateUrlParams({
      keyword,
    })}`,
  });
};
