import * as R from "../../routings/path";
import { TABLE_ACTION } from "../../constants/table-action";
import DateTimeTableCell from "../../components/table-cell-date-time";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import ActionTableCell from "../../components/table-cell-action";

interface ISchemaProps {
  onDelete?: any;
}

export const schema = ({ onDelete }: ISchemaProps) => [
  {
    name: "Product",
    cell: (data: any) => (
      <ProductTableCell
        linkTo={`${R.PRODUCTS_LIST}/${data.code}`}
        image={data.image_url}
        title={data.name}
        description={data.gtin_code}
        maxWidth={400}
        imageSize={40}
      />
    ),
  },
  {
    name: "Unit",
    cell: (data: any) => data.unit,
  },
  {
    name: "Capacity",
    sortTypes: {
      key: "capacity_value",
      value: ["asc", "desc"],
    },
    align: "right",
    cell: (data: any) => (
      <ProductTableCell
        align="end"
        title={data.capacity_value || "-"}
        description={data.capacity_unit}
      />
    ),
  },
  {
    name: "Weight | Size (WHL)",
    align: "center",
    cell: (data: any) => (
      <ProductTableCell
        align="center"
        title={data.packaging_weight}
        description={`${data.packaging_width} x ${data.packaging_height} x ${data.packaging_length}`}
      />
    ),
  },
  {
    name: "Created",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data.created_at} />,
  },
  {
    name: "Updated",
    align: "center",
    sortTypes: {
      key: "updated_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data.updated_at} />,
  },
  {
    name: "Status",
    align: "center",
    cell: (data: any) => <StatusTableCell status={data.status} />,
  },
  {
    name: "Action",
    align: "right",
    cell: (data: any) => (
      <ActionTableCell
        actions={data.instance_actionables}
        viewLink={`${R.PRODUCTS_LIST}/${data.code}`}
        editLink={`${R.PRODUCTS_LIST}/${data.code}/edit`}
        types={[TABLE_ACTION.VIEW, TABLE_ACTION.EDIT, TABLE_ACTION.DELETE]}
        onDelete={() => onDelete(data.code)}
        popupContent="Are you sure to delete this product ?"
      />
    ),
  },
];
