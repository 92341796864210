import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const defaultOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
      align: "start" as const,
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
};

interface IOptions {
  responsive: boolean;
  plugins: {
    legend: {
      position?: "bottom" | "center" | "top" | "chartArea" | "left" | "right";
      align?: "start" | "center" | "end";
    };
    title: {
      display?: boolean;
      text?: string;
    };
    layout: {
      padding: 10;
    };
  };
}

export interface IDataset {
  label: string;
  data: number[];
  backgroundColor: string;
  borderColor: string;
  borderWidth?: number;
  customSize?: {
    width?: number;
    height?: number;
  };
}

interface IDataProps {
  labels: string[];
  datasets: IDataset[];
}

interface ILineChartProps {
  options?: IOptions & any;
  data: IDataProps & any;
  className?: string;
  styles?: {
    maxHeight?: number;
    minHeight?: number;
  };
}

const LineChart = ({
  options = defaultOptions,
  data,
  className,
  styles,
}: ILineChartProps) => {
  return (
    <Line
      options={options}
      data={data}
      className={className}
      style={{ maxHeight: styles?.maxHeight, minHeight: styles?.minHeight }}
    />
  );
};

export default LineChart;
