import classNames from "classnames";
import styles from "./styles.module.scss";

interface IComponent {
  warehouse: {
    name: string,
    code: string,
    uuid: string,
    full_address: string,
    warehouse_name: string, // SaleOrder, PurchaseOrder
    warehouse_code: string, // SaleOrder, PurchaseOrder
  }
}

const WarehouseTableCell = ({
  warehouse,
}: IComponent) => {
  return (
    <div>
      <div className={classNames('text-bold text-truncate', styles.warehouseName)}>{warehouse?.warehouse_name || warehouse?.name}</div>
      <div className="fs-6">{warehouse?.warehouse_code || warehouse?.code}</div>
    </div>
  );
};

export default WarehouseTableCell;
