import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  getSaleOrderDetailApi,
  updateSaleOrderStatusApi,
  updateSaleOrder,
  IUpdateSaleOrder,
} from "../../apis/paths/sale-order";
import {
  getWarehouseDetailApi,
  getWarehouseListApi,
} from "../../apis/paths/warehouse";
import { formatAddress } from "../../utils/address";
import { useApiCaller } from "../../apis/config";
import { apiCaller } from "../../apis/config";
import { GENERAL_UI_SIZE } from "../../constants/size";

import alert from "../../utils/alert";
import UiInput from "../../components/ui-input";
import UiButton from "../../components/ui-button";
import TablePanel from "../../components/panel-table";
import UiTextArea from "../../components/ui-text-area";
import GeneralPanel from "../../components/panel-general";
import UiLoadingPanel from "../../components/ui-loading-panel";
import HeaderInfoPanel from "../../components/panel-header-info";
import TableCellStatus from "../../components/table-cell-status";
import UiSelectDropdown from "../../components/ui-select-dropdown";
import UiStatusDropdown from "../../components/ui-status-dropdown";
import NotFoundPanel from "../../components/panel-not-found/component";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import useHandleSidebarStatus from "../../hooks/useSidebarStatus";
import PanelLabelController from "../../components/ui-panel-label-controller/component";

import { CHANNEL } from "./constants";
import { fieldTitle, schema, warehouseSchema } from "./schema";
import styles from "./styles.module.scss";
import GeneralHeading from "./view-general-heading";
import SelectedWarehouse from "./view-selected-warehouse";
import GeoSearchSelector, {
  IGeo,
} from "../../components/ui-geo-search-selector/component";
import { extractAddressFields } from "../task-logging-create/utils";

const Component = () => {
  const [wardValue, setWardValue] = useState<IGeo | null>(null);
  const [pageReloadVersion, setPageReloadVersion] = useState(1);

  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { dispatchSidebarStatus } = useHandleSidebarStatus();

  useEffect(() => {
    dispatchSidebarStatus({ isOpened: false });
    return () => {
      dispatchSidebarStatus({ isOpened: true });
    };
  }, []);

  const [selectedChannel, setSelectedChannel] = useState<any>(
    CHANNEL[0]?.value
  );

  const [addressValue, setAddressValue] = useState<any>({});
  const [saleOrderInfo, setSaleOrderInfo] = useState<any>({});
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>(null);
  const [isShowModal, setIsShowModal] = useState(false);

  const { saleOrderId: uuid } = useParams();
  const [params] = useState({ uuid });

  const { result, loading } = useApiCaller({
    api: getSaleOrderDetailApi,
    params: params,
    pageReloadVersion,
  });

  useEffect(() => {
    if (result?.data) {
      /* TODO: Delete after API update */
      let saleOrderResult = result?.data;
      setSaleOrderInfo(saleOrderResult);
      setSelectedChannel(saleOrderResult?.channel);
      setValue("referenceNumber", saleOrderResult?.reference_number);
      setValue("receiverName", saleOrderResult?.receiver_name);
      setValue("receiverPhone", saleOrderResult?.receiver_phone);
      setValue("streetName", saleOrderResult?.address?.street_name);
      setValue("wardId", saleOrderResult?.address?.wardId);
      setValue("cod", saleOrderResult?.cod);
      setValue("value", saleOrderResult?.value);
      setAddressValue(
        `${saleOrderResult?.address?.ward_name}, ${saleOrderResult?.address?.district_name}, ${saleOrderResult?.address?.province_name}`
      );

      const ward = extractAddressFields(
        saleOrderResult.address?.full_address
      )?.ward?.join(",");

      const originalWardId = result?.data?.address?.ward_id;
      setWardValue({ key: originalWardId, value: ward });

      (async () => {
        const waitGetWarehouseDetail = await apiCaller({
          api: getWarehouseDetailApi,
          params: {
            code: saleOrderResult?.warehouse_code,
          },
        });

        if (waitGetWarehouseDetail?.status === "SUCCESS") {
          setSelectedWarehouse(waitGetWarehouseDetail.data);
        }
      })();
    }
  }, [result]);

  const handleUpdateInfo = async () => {
    const apiParams: IUpdateSaleOrder = {
      selectedChannel,
      warehouseUuid: selectedWarehouse?.code,
      wardId: wardValue?.key as number,
      streetName: getValues("streetName"),
      addressDetail: ``,
      receiverName: getValues("receiverName"),
      receiverPhone: getValues("receiverPhone"),
      referenceNumber: getValues("referenceNumber"),
      cod: getValues("cod") as number,
      value: getValues("value") as number,
    };

    const waitUpdateSaleOrder = await apiCaller({
      api: updateSaleOrder,
      params: apiParams,
    });

    if (waitUpdateSaleOrder?.status === "SUCCESS") {
      alert.success("Updated successfull");
      setPageReloadVersion((prev) => prev + 1);
    }
  };

  if (loading) return <UiLoadingPanel />;

  if (result?.status === "ERROR") return <NotFoundPanel />;

  return (
    <>
      <div className="mb-4">
        <HeaderInfoPanel
          isDisplayInfoArea={true}
          title={`#${saleOrderInfo?.number}`}
          infoArea={() => (
            <>
              <TableCellStatus
                status={saleOrderInfo?.status}
                size={GENERAL_UI_SIZE.SMALL}
              />
              <TableCellStatus
                className="ms-2"
                status={saleOrderInfo?.shipping_service}
                size={GENERAL_UI_SIZE.SMALL}
              />
            </>
          )}
          actionArea={() => (
            <>
              {saleOrderInfo?.status && (
                <UiStatusDropdown
                  status={saleOrderInfo?.status}
                  triggerableEvents={saleOrderInfo?.triggerable_events}
                  actionApi={updateSaleOrderStatusApi}
                  paramsTransformation={(newEvent: string) => ({
                    uuid: uuid,
                    event: newEvent,
                  })}
                  onChangeSuccess={(newData: any) => {
                    setSaleOrderInfo({
                      ...saleOrderInfo,
                      status: newData.status,
                      triggerable_events: newData.triggerable_events,
                    });
                  }}
                />
              )}
              <UiButton
                className="me-2"
                title={"Update"}
                onClick={handleUpdateInfo}
              />
            </>
          )}
        />
      </div>
      <GeneralPanel className="pt-4">
        <GeneralHeading>General Info</GeneralHeading>
        <TwoColumnGridLayout
          widthDivision={[7, 5]}
          leftCol={
            <>
              <UiSelectDropdown
                title={"Channel"}
                name={"channel"}
                isDisplayLabel
                value={selectedChannel}
                setValue={(_: string, value: string) =>
                  setSelectedChannel(value)
                }
                optionList={CHANNEL}
              />
              <UiInput
                title={"Reference Number"}
                name={"referenceNumber"}
                className="mb-3"
                register={register}
              />
              <UiInput
                title={"Receiver Name"}
                name={"receiverName"}
                className="mb-3"
                register={register}
              />
              <UiInput
                title={"Receiver Phone"}
                name={"receiverPhone"}
                className="mb-3"
                register={register}
              />
              <UiInput
                title={"COD"}
                name={"cod"}
                type="number"
                register={register}
                errorMessage={errors?.cod?.message}
              />
              <UiInput
                title={"Value"}
                name={"value"}
                type="number"
                register={register}
                errorMessage={errors?.value?.message}
              />
            </>
          }
          rightCol={
            <>
              <UiInput
                required
                title={fieldTitle["selectProvince"]}
                value={wardValue?.value}
                name={"wardId"}
                onClick={() => setIsShowModal(true)}
                register={register}
                errorMessage={errors?.wardId?.message}
              />
              <UiInput
                title={fieldTitle["streetName"]}
                name={"streetName"}
                register={register}
                required
                errorMessage={errors?.streetName?.message}
                onBlur={() => trigger("wardId")}
              />
              <UiTextArea
                title={fieldTitle["addresDetail"]}
                name={"address_detail"}
                value={`${
                  getValues("streetName") ? getValues("streetName") + "," : ""
                } ${formatAddress(addressValue)}`}
                disabled
              />
            </>
          }
        />
      </GeneralPanel>
      <GeneralPanel className="pt-4">
        <GeneralHeading>Labels</GeneralHeading>
        <PanelLabelController
          linkedObjectType="SaleOrder"
          uniqueUuid={uuid as string}
        />
      </GeneralPanel>
      <GeneralPanel className="pt-4">
        <GeneralHeading>Warehouse</GeneralHeading>
        <TwoColumnGridLayout
          widthDivision={[7, 5]}
          leftCol={
            <TablePanel
              apiPath={getWarehouseListApi}
              panelClassName={"mt-0"}
              type={"minimal"}
              tableSchema={warehouseSchema({
                selectedWarehouse: selectedWarehouse,
                onSelectWarehouse: setSelectedWarehouse,
              })}
            />
          }
          rightCol={
            <div className={styles.selectWarehouse}>
              <SelectedWarehouse warehouse={selectedWarehouse} />
            </div>
          }
        />
      </GeneralPanel>
      <GeoSearchSelector
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        onCancel={() => {
          setIsShowModal(false);
        }}
        setWardValue={setWardValue}
      />
    </>
  );
};

export default Component;
