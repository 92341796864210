import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as R from "../../routings/path";
import { apiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import {
  ICreateWarehouseApi,
  createWarehouseApi,
  getWarehouseListApi,
} from "../../apis/paths/warehouse";
import GeoSearchSelector, {
  IGeo,
} from "../../components/ui-geo-search-selector/component";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import HeaderInfoPanel from "../../components/panel-header-info";
import UiButton from "../../components/ui-button";
import UiInput from "../../components/ui-input";
import MultiStepPanel from "../../components/panel-multi-step";
import TablePanel from "../../components/panel-table";

import styles from "./styles.module.scss";
import { subWHschema } from "./subWHschema";
import { warehouseSchema } from "./warehouseSchema";
import { fieldTitle, masterWHschema } from "./masterWHschema";
import SelectedWarehouse from "./view-selected-warehouse";
import ViewWarehouseTypeSelect from "./view-warehouse-type-selection";

const Component = () => {
  const navigate = useNavigate();
  const [multiStepPanelTabIndex, setMultiStepPanelTabIndex] = useState(0);
  const [hasStep1Error, setHasStep1Error] = useState(false);
  const [warehouseType, setWarehouseType] = useState(0);
  const [wardValue, setWardValue] = useState<IGeo | null>(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>(null);
  const [isLoading, setLoading] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);

  const {
    setValue,
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(warehouseType === 2 ? subWHschema : masterWHschema),
    mode: "onChange",
  });

  useEffect(() => {
    if (selectedWarehouse)
      setValue("parentWarehouseUuid", selectedWarehouse?.uuid);
  }, [selectedWarehouse]);

  const onSubmit = async ({
    name,
    contactName,
    contactPhone,
    valuable,
    parentWarehouseUuid,
    storageType,
    streetName,
    detail,
    latitude,
    longitude,
  }: any) => {
    setLoading(true);
    const createWarehouse = await apiCaller({
      api: createWarehouseApi,
      params: {
        warehouse: {
          name,
          contactName,
          contactPhone,
          valuable,
          parentWarehouseUuid,
          storageType,
        },
        address: {
          wardId: wardValue?.key,
          streetName,
          detail,
          latitude,
          longitude,
        },
      } as ICreateWarehouseApi,
    });
    setLoading(false);

    const { status } = createWarehouse;
    if (status === API_RESPONSE_STATUS.SUCCESS) {
      navigate(R.WAREHOUSES_LIST);
    }
  };

  const renderStep1 = () => (
    <ViewWarehouseTypeSelect
      hasStep1Error={hasStep1Error}
      setHasStep1Error={setHasStep1Error}
      warehouseType={warehouseType}
      setWarehouseType={setWarehouseType}
    />
  );

  const renderStep2 = () => (
    <div>
      {warehouseType === 1 ? (
        <TwoColumnGridLayout
          className={{ container: "px-0" }}
          leftCol={
            <>
              <UiInput
                required
                title={fieldTitle.wardId}
                name={"wardId"}
                register={register}
                value={wardValue?.value}
                errorMessage={errors?.wardId?.message}
                onClick={() => setIsShowModal(true)}
                onBlur={() => trigger("wardId")}
                icon="angle-down"
              />
              <UiInput
                title={fieldTitle.streetName}
                name={"streetName"}
                register={register}
                errorMessage={errors?.streetName?.message}
              />
              <UiInput
                required
                title={fieldTitle.detail}
                name={"detail"}
                register={register}
                errorMessage={errors?.detail?.message}
              />
            </>
          }
          rightCol={
            <>
              <UiInput
                title={fieldTitle.latitude}
                name={"latitude"}
                type="number"
                register={register}
                errorMessage={errors?.latitude?.message}
              />
              <UiInput
                title={fieldTitle.longitude}
                type="number"
                name={"longitude"}
                register={register}
                errorMessage={errors?.longitude?.message}
              />
            </>
          }
        />
      ) : (
        <TwoColumnGridLayout
          widthDivision={[6, 6]}
          leftCol={
            <TablePanel
              apiPath={getWarehouseListApi}
              panelClassName={"mt-0"}
              type={"minimal"}
              tableSchema={warehouseSchema({
                selectedWarehouse: selectedWarehouse,
                onSelectWarehouse: setSelectedWarehouse,
              })}
            />
          }
          rightCol={
            <div>
              <div className={styles.selectWarehouse}>
                <SelectedWarehouse warehouse={selectedWarehouse} />
              </div>
            </div>
          }
        />
      )}
    </div>
  );

  const renderStep3 = () => (
    <div>
      <TwoColumnGridLayout
        className={{ container: "px-0" }}
        leftCol={
          <>
            <UiInput
              required
              title={fieldTitle.name}
              name={"name"}
              register={register}
              errorMessage={errors?.name?.message}
            />
            <UiInput
              required
              title={fieldTitle.contactName}
              name={"contactName"}
              register={register}
              errorMessage={errors?.contactName?.message}
            />
            <UiInput
              required
              title={fieldTitle.contactPhone}
              name={"contactPhone"}
              placeholder={"Ex: 090900000"}
              register={register}
              errorMessage={errors?.contactPhone?.message}
            />
          </>
        }
        rightCol={
          <>
            <UiInput
              title={fieldTitle.valuable}
              name={"valuable"}
              type="checkbox"
              isDisplayLabel
              register={register}
              containerClassName="mb-4"
            />
            <UiInput
              title={fieldTitle.storageType}
              name={"storageType"}
              placeholder={"Ex: packing, reserve..."}
              register={register}
              errorMessage={errors?.storageType?.message}
            />
          </>
        }
      />
    </div>
  );

  return (
    <>
      <GeoSearchSelector
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        onCancel={() => setIsShowModal(false)}
        setWardValue={setWardValue}
      />
      <HeaderInfoPanel
        title={"Create new warehouse"}
        description={"Input info below to create a new warehouse"}
        actionArea={() => (
          <>
            <UiButton
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
              className="me-2"
              title={"Create"}
            />
          </>
        )}
      />
      <MultiStepPanel
        defaultTab={multiStepPanelTabIndex}
        onChangeTab={(index: number) => {
          setMultiStepPanelTabIndex(index);
        }}
        onCheckPassStepValidation={async (index: number) => {
          let isValidationPassed = true;
          if (warehouseType === 1 && index === 2) {
            isValidationPassed = await trigger([
              "wardId",
              "streetName",
              "detail",
              "latitude",
              "longitude",
            ]);
          }

          return isValidationPassed;
        }}
        onStaticCheckStepValidatation={(index: number) => {
          let isValidationPassed = true;
          if (multiStepPanelTabIndex === 0 && warehouseType === 0) {
            isValidationPassed = false;
            setHasStep1Error(true);
            return isValidationPassed;
          }
          setHasStep1Error(false);
          return isValidationPassed;
        }}
        tabs={
          warehouseType === 2
            ? ["1. Types", "2. SELECT MASTER WH", "3. Warehouse"]
            : ["1. Types", "2. Address", "3. Warehouse"]
        }
        contentHeadings={
          warehouseType === 2
            ? [
                "SELECT WAREHOUSE TYPE TO SETUP",
                "CHOOSE MASTER WH",
                "WAREHOUSE INFORMATION",
              ]
            : [
                "SELECT WAREHOUSE TYPE TO SETUP",
                "ADDRESS INFORMATION",
                "WAREHOUSE INFORMATION",
              ]
        }
        contentCol={12}
      >
        {renderStep1()}
        {renderStep2()}
        {renderStep3()}
      </MultiStepPanel>
    </>
  );
};

export default Component;
