import { useCallback } from "react";
import { shallowEqual } from "react-redux";

import { useAppDispatch, useAppSelector } from "./useStateHook";
import * as actions from "../states/dynamicDocParameter.flow";

interface IDynamicDocParams {
  docPath: string;
  docField: string;
}

export const useDynamicDocParams = () => {
  const dispatch = useAppDispatch();

  const generatedFilterParameter = useAppSelector(
    (state) => state.docParamerter,
    shallowEqual
  );

  const fetchParameter = useCallback(
    ({ docPath, docField }: IDynamicDocParams) =>
      dispatch(actions.fetchDocParameter({ docPath, docField })),
    [dispatch]
  );

  return {
    generatedFilterParameter,
    fetchParameter,
  };
};
