import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  getDefaultShippingServiceApi,
  getShippingServiceApi,
  updateDefaultShippingServiceApi,
} from "../../apis/paths/shipping";
import { apiCaller, useApiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import alert from "../../utils/alert";
import NotFoundPanel from "../../components/panel-not-found/component";
import SelectDropdown from "../../components/ui-select-dropdown/component";
import Typography from "../../components/ui-typography/component";

import { IShippingService } from "./types";
import ServiceItem from "./view-service-item";

const ShippingServices = () => {
  const [pageReloadVersion, setPageReloadVersion] = useState(0);

  const { result } = useApiCaller({
    api: getShippingServiceApi,
    params: DEFAULT_PAGINATION_PARAMS,
  });

  const shippingServicesList = result?.data?.list || [];

  const { result: defaultShippingService } = useApiCaller({
    api: getDefaultShippingServiceApi,
    params: DEFAULT_PAGINATION_PARAMS,
    pageReloadVersion,
  });

  const defaultShippingCode = defaultShippingService?.data?.code;

  const { setValue, register } = useForm();

  const optionItems = shippingServicesList?.map(
    (service: IShippingService) => ({
      value: service.code,
      content: service.display_name,
    })
  );

  const onSubmit = async (selectedShippingService: any) => {
    if (
      !selectedShippingService ||
      selectedShippingService === defaultShippingCode
    ) {
      return;
    }

    const waitCreateProductType = await apiCaller({
      api: updateDefaultShippingServiceApi,
      params: {
        code: selectedShippingService,
      },
    });
    if (waitCreateProductType.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update successful!");
      setPageReloadVersion((prev: number) => prev + 1);
      return;
    }
    alert.error("Update was not successful!. Please try again!");
  };

  useEffect(() => {
    setValue("defaultShippingService", defaultShippingCode);
  }, [defaultShippingService, setValue]);

  if (result?.status === "ERROR") return <NotFoundPanel />;

  if(!shippingServicesList) return null

  return (
    <div className="card card-profile-bottom">
      <div className="card position-sticky top-1">
        <div className="card-header ">
          <div className="d-flex justify-content-between">
            <div>
              <h5>Logistics</h5>
              <p className="text-sm">Here you can setup and manage your 3PLs</p>
            </div>
            <div className="d-flex align-items-center ">
              <Typography
                fontWeight="fw-bolder"
                className="text-xs text-bold me-2 pt-1"
              >
                Change default:
              </Typography>
              <div className="mt-3">
                <SelectDropdown
                  name="defaultShippingService"
                  isDisplayLabel={false}
                  register={register}
                  setValue={setValue}
                  onChange={onSubmit}
                  optionList={optionItems}
                  style={{ width: 170 }}
                />
              </div>
            </div>
          </div>
          <hr className="horizontal dark mt-2" />
        </div>
        <div className="card-body pt-0">
          {shippingServicesList?.map((service: IShippingService) => (
            <div key={service?.code}>
              <ServiceItem
                service={service}
                services={shippingServicesList}
                defaultShippingService={defaultShippingCode}
                setPageReloadVersion={setPageReloadVersion}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShippingServices;
