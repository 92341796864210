import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { schema } from "./schema";

import { apiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { ICreateShopApi, createShopApi } from "../../apis/paths/shop";
import { SHOP_LIST } from "../../routings/path";

import alert from "../../utils/alert";
import SmallCollumnCenterGridLayout from "../../components/grid-layout-small-collumn-center";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import HeaderInfoPanel from "../../components/panel-header-info";
import GeneralPanel from "../../components/panel-general";
import UiButton from "../../components/ui-button";
import UiInput from "../../components/ui-input";
import ImageUploader from "../../components/ui-image-uploader/component";

const Component = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const uploadImage: any = watch("logo");

  const onSubmit = async ({
    name,
    code,
    phone,
    tax_code,
    full_address,
  }: any) => {
    const data: ICreateShopApi = {
      name,
      code,
      phone,
      tax_code,
      full_address,
      logo: uploadImage?.[0]?.file,
    };

    const formData = new FormData();

    for (const key in data) {
      if (!!data[key as keyof ICreateShopApi]) {
        formData.append(key, data[key as keyof ICreateShopApi]);
      }
    }

    const waitUpdateShop = await apiCaller({
      api: createShopApi,
      params: formData,
    });

    if (waitUpdateShop.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Create successful!");
      setTimeout(() => {
        navigate(SHOP_LIST);
      }, 500);

      return;
    }

    alert.error("Create was not successful!. Please try again!");
  };

  return (
    <>
      <HeaderInfoPanel
        title={"Create new Shop"}
        description="Input info below to create a new Shop"
        actionArea={() => (
          <>
            <UiButton
              onClick={handleSubmit(onSubmit)}
              className="me-2"
              title={"Create"}
            />
          </>
        )}
      />
      <SmallCollumnCenterGridLayout>
        <GeneralPanel panel={{ subTitle: "Shop Information" }}>
          <TwoColumnGridLayout
            className={{ container: "px-0" }}
            leftCol={
              <>
                <UiInput
                  title={"Name"}
                  name={"name"}
                  required
                  register={register}
                  errorMessage={errors?.name?.message}
                />
                <UiInput
                  title={"Code"}
                  name={"code"}
                  required
                  register={register}
                  errorMessage={errors?.code?.message}
                />
                <UiInput
                  title={"Full Address"}
                  name={"full_address"}
                  required
                  register={register}
                  errorMessage={errors?.full_address?.message}
                />
              </>
            }
            rightCol={
              <>
                <UiInput
                  title={"Phone"}
                  name={"phone"}
                  required
                  register={register}
                  errorMessage={errors?.phone?.message}
                />
                <UiInput
                  title={"Tax Code"}
                  name={"tax_code"}
                  register={register}
                  errorMessage={errors?.tax_code?.message}
                />
                <ImageUploader
                  isDisplayLabel
                  title="Logo"
                  isDisplayRemoveAll={false}
                  name="logo"
                  setValue={setValue}
                  errorMessage={errors?.logo?.message}
                />
              </>
            }
          />
        </GeneralPanel>
      </SmallCollumnCenterGridLayout>
    </>
  );
};

export default Component;
