import classNames from "classnames";

import { GENERAL_STATUS } from "../../constants/status";
import Typography from "../../components/ui-typography/component";
import StatusTableCell from "../../components/table-cell-status";

const ViewChildWarehouse = ({ warehouse }: { warehouse: any }) => {
  if (!warehouse) return null;

  return (
    <div>
      {warehouse?.map((warehouse: any) => (
        <>
          <div
            className={classNames(
              "d-flex gap-2 align-items-center justify-content-between p-2 ps-4 pe-4 "
            )}
            key={warehouse?.uuid}
          >
            <Typography>
              {warehouse?.name}
            </Typography>
            <div className="d-flex align-items-center gap-3">
              <StatusTableCell
                status={
                  warehouse.valuable
                    ? GENERAL_STATUS.VALUABLE
                    : GENERAL_STATUS.EMPTY
                }
              />
              <div className="ms-auto d-flex align-items-center">
                <StatusTableCell
                  status={
                    warehouse?.deleted_at
                      ? GENERAL_STATUS.DEACTIVE
                      : GENERAL_STATUS.ACTIVATE
                  }
                />
              </div>
            </div>
          </div>
          <hr className="horizontal dark mt-0 " />
        </>
      ))}
    </div>
  );
};

export default ViewChildWarehouse;
