import * as R from "../../routings/path";
import { TABLE_ACTION } from "../../constants/table-action";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import ActionTableCell from "../../components/table-cell-action";
import { GENERAL_DEVICE } from "../../constants/device";
import Badge from "../../components/ui-badge/component";
import { GENERAL_UI_SIZE } from "../../constants/size";
import DateTimeTableCell from "../../components/table-cell-date-time";

interface ISchema {
  onToggle?: any;
  deviceStatus?: any;
  uuid?: string;
  isDisabled?: boolean;
}

export const schema = ({
  onToggle,
  deviceStatus,
  uuid,
  isDisabled,
}: ISchema) => {
  const schemaArray = [
    {
      name: "CLIENT",
      cell: (data: any) => {
        let icon = null;
        switch (data?.platform) {
          case GENERAL_DEVICE.MAC_OS:
            icon = <i className="fa-solid fa-desktop fa-2xl opacity-4"></i>;
            break;
          case GENERAL_DEVICE.IOS_PHONE:
            icon = <i className="fa-solid fa-mobile fa-2xl opacity-4"></i>;
            break;

          default:
            icon = <i className="fa-solid fa-desktop fa-2xl opacity-4"></i>;
            break;
        }

        return (
          <ProductTableCell
            title={data?.client_name}
            description={data?.last_ip}
            icon={icon}
            extraField={
              data?.uuid === uuid && (
                <Badge content="Current session" size={GENERAL_UI_SIZE.SMALL} />
              )
            }
          />
        );
      },
    },
    {
      name: "LOCATION",
      textSize: "xs",
      cell: (data: any) => {
        return data?.client_location;
      },
    },
    {
      name: "CREATED AT",
      align: "center",
      sortTypes: {
        key: "created_at",
        value: ["asc", "desc"],
      },
      cell: (data: any) => <DateTimeTableCell timeStamp={data?.created_at} />,
    },
    {
      name: "LAST ACTIVATED",
      align: "center",
      sortTypes: {
        key: "last_time",
        value: ["asc", "desc"],
      },
      cell: (data: any) => <DateTimeTableCell timeStamp={data?.last_time} />,
    },
    {
      name: "STATUS",
      align: "center",
      cell: (data: any) => {
        return <StatusTableCell status={deviceStatus?.[data.uuid]} />;
      },
    },
  ];

  if (!isDisabled) {
    schemaArray.push({
      name: "ACTION",
      align: "center",
      cell: (data: any) => {
        return (
          <ActionTableCell
            types={[TABLE_ACTION.TOGGLE]}
            className="justify-content-center"
            toggleData={data.status}
            editLink={`${R.ACCOUNT_LIST}/${data.uuid}`}
            resetPasswordLink={`${R.ACCOUNT_LIST}/${data.uuid}/reset-password`}
            popupContent="Are you sure to delete this user?"
            onToggle={() => {
              onToggle(data.uuid);
            }}
          />
        );
      },
    });
  }

  return schemaArray;
};
