import * as R from "../routings/path";
import { formatDateTime } from "../utils/date-time";
import TableCellStatus from "../components/table-cell-status";
import { GENERAL_UI_SIZE } from "../constants/size";

const notificationType = {
  TaskLogging: "TaskLogging",
  PickList: "PickList",
};

const notificationIcon = {
  [notificationType.TaskLogging]: "list-check",
  [notificationType.PickList]: "hand-fist",
};

const notificationColor = {
  [notificationType.TaskLogging]: "text-info",
  [notificationType.PickList]: "text-success",
};

const notificationLinkGeneration = (notificationItem: any) => {
  switch (notificationItem?.notification?.linked_object_type) {
    case notificationType.TaskLogging:
      return {
        linkTo: `${R.TASK_LOGGING_LIST}/${notificationItem?.notification?.linked_object_uuid}`,
        linkTarget: "_blank",
      };
    case notificationType.PickList:
      return {
        linkTo: `${R.PICKINGS_LIST}/${notificationItem?.notification?.linked_object_uuid}`,
        linkTarget: "_blank",
      };

    default:
      return null;
  }
};

export const notificationTransformation = (notificationItem: any) =>
  !notificationItem
    ? null
    : {
        icon: notificationIcon[
          notificationItem?.notification?.linked_object_type
        ],
        colorIcon:
          notificationColor[notificationItem?.notification?.linked_object_type],
        title: notificationItem?.notification?.title,
        description: formatDateTime(
          notificationItem?.notification?.created_at,
          "HH:mm:ss DD-MM-YYYY"
        ),
        extraContent: notificationItem?.notification?.message_text,
        extraElement: (
          <TableCellStatus
            status={notificationItem?.notification?.linked_object_type}
            size={GENERAL_UI_SIZE.SMALL}
          />
        ),
        ...notificationLinkGeneration(notificationItem),
      };
