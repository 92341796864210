export const formatAddress = (addressValue: any) => {
  const { ward, province, district } = addressValue || {};
  if (!ward || !province) return "";

  const formatedWard = `${ward?.unit} ${ward?.name}`;
  const formatedProvince = `${province?.unit} ${province?.name}`;
  const formatedDistrict = `${district?.unit} ${district?.name}`;

  return `${formatedWard}, ${formatedDistrict}, ${formatedProvince}, Vietnam`;
};

export const extractAddressFields = (address: string) => {
  const strings = address?.split(",");

  return {
    detail: strings?.[0],
    street: strings?.[1],
    ward: strings?.slice(2),
  };
};

export const capitalize = (str: string) => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

export const splitAndCapitalize = (str: string, splitKey: string = "_") => {
  return str
    ?.split(splitKey as string)
    ?.map((string: string) => capitalize(string))
    .join(" ");
};

export const convertTimeEstimationToMinutes = (timeEstimation: string) => {
  let value = 0;
  let isValidFormat = true;
  if (!timeEstimation || typeof timeEstimation !== "string") {
    return {
      value,
      isValidFormat,
    };
  }

  const regex = /^(\d+w\s*)?(\d+d\s*)?(\d+h\s*)?(\d+m\s*)?$/;
  const match = timeEstimation?.match(regex);

  if (match) {
    const weeks = parseInt(match[1]) || 0;
    const days = parseInt(match[2]) || 0;
    const hours = parseInt(match[3]) || 0;
    const minutes = parseInt(match[4]) || 0;

    value = weeks * 7 * 24 * 60 + days * 24 * 60 + hours * 60 + minutes;
  } else {
    isValidFormat = false;
  }

  return {
    value,
    isValidFormat,
  };
};
