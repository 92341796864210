import styles from "./styles.module.scss";

const Component = ({
  tabs,
  contentHeadings,
  subContentHeading,
  selectedTab,
  onPrev,
  onNext,
}: {
  tabs: Array<string>;
  contentHeadings: Array<string>;
  subContentHeading?: Array<string>;
  selectedTab: number;
  onPrev: (_: any) => any;
  onNext: (_: any) => any;
}) => {
  return (
    <div className={styles.navigation}>
      <h5
        style={{ textTransform: "uppercase" }}
        className="text-sm font-bold flex-grow-1"
      >
        {contentHeadings[selectedTab]}
      </h5>
      {Array.isArray(subContentHeading) && !!subContentHeading[selectedTab] && (
        <h6
          // style={{ textTransform: "uppercase" }}
          className="text-sm font-bold flex-grow-1"
        >
          {subContentHeading[selectedTab]}
        </h6>
      )}
      <div className="button-row d-flex">
        <button
          onClick={onPrev}
          className="btn bg-gradient-dark mb-0 js-btn-prev me-3"
          type="button"
          disabled={selectedTab === 0}
          title="Prev"
        >
          Prev
        </button>

        <button
          onClick={onNext}
          className="btn bg-gradient-dark ms-auto mb-0"
          type="button"
          title="Send"
          disabled={selectedTab === tabs.length - 1}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Component;
