import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as R from "../../routings/path";
import { apiCaller, useApiCaller } from "../../apis/config";
import { GENERAL_STATUS } from "../../constants/status";
import { GENERAL_UI_SIZE } from "../../constants/size";
import {
  getWarehouseDetailApi,
  updateWarehouseApi,
  toggleWarehouseStatusApi,
  IUpdateWarehouseApi,
} from "../../apis/paths/warehouse";
import alert from "../../utils/alert";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { extractAddressFields } from "../../utils/address";
import { IGeo } from "../../components/ui-geo-search-selector/component";
import HeaderInfoPanel from "../../components/panel-header-info";
import TableCellStatus from "../../components/table-cell-status";
import UiLoadingPanel from "../../components/ui-loading-panel";
import NotFoundPanel from "../../components/panel-not-found";
import GeneralPanel from "../../components/panel-general";
import UiTypography from "../../components/ui-typography";
import UiSwitch from "../../components/ui-switch";
import UiButton from "../../components/ui-button";
import SmallCollumnCenterGridLayout from "../../components/grid-layout-small-collumn-center";

import { schema } from "./schema";
import ViewChildWarehouse from "./view-child-warehouse";
import ViewWarehouseInformation from "./view-warehouse-information";

const transformData = (data: any) => {
  if (!data) return null;

  const { detail, street } = extractAddressFields(data?.address?.full_address);

  return {
    contactName: data?.contact_name,
    contactPhone: data?.contact_phone,
    storageType: data?.storage_type,
    parentWarehouseUuid: data?.parent_warehouse?.name,
    wardId: data?.address?.ward_id,
    streetName: street,
    detail,
    longitude: data?.address?.longitude,
    latitude: data?.address?.latitude,
    ...data,
  };
};

const Component = () => {
  const [isLoading, setLoading] = useState(false);
  const [addressValue, setAddressValue] = useState<any>({});
  const [wardValue, setWardValue] = useState<IGeo | null>(null);

  const [warehouseStatus, setWarehouseStatus] = useState<
    GENERAL_STATUS.ACTIVATE | GENERAL_STATUS.DEACTIVE
  >(GENERAL_STATUS.DEACTIVE);

  const navigate = useNavigate();
  const { warehouseId: code } = useParams();
  const [params] = useState({ code });

  const { result, loading } = useApiCaller({
    api: getWarehouseDetailApi,
    params: params,
  });

  const warehouseInfo = transformData(result?.data);

  useEffect(() => {
    const ward = extractAddressFields(
      result?.data?.address?.full_address
    )?.ward?.join(",");

    const originalWardId = result?.data?.address?.ward_id;
    setWardValue({ key: originalWardId, value: ward });
  }, [result]);

  const isSubwarehouse = !!result?.data?.parent_warehouse;

  useEffect(() => {
    if (!result || result?.status === "ERROR") return;

    const warehouseInfo = transformData(result?.data);
    const keys = Object.keys(schema?.fields);
    keys.map((key: any) => setValue(key, warehouseInfo[key]));

    setWarehouseStatus(
      warehouseInfo?.deleted_at
        ? GENERAL_STATUS.DEACTIVE
        : GENERAL_STATUS.ACTIVATE
    );
  }, [result]);

  const {
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleToggleStatus = async (nextValue: boolean) => {
    const nextStatus =
      nextValue === true ? GENERAL_STATUS.ACTIVATE : GENERAL_STATUS.DEACTIVE;

    const waitToggle = await apiCaller({
      api: toggleWarehouseStatusApi,
      params: { code },
    });

    if (waitToggle.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update status successful!");
      setWarehouseStatus(nextStatus);
      return;
    }
    alert.error("Change status was not successful!. Please try again");
  };

  const onSubmit = async ({
    name,
    contactName,
    contactPhone,
    valuable,
    parentWarehouseUuid,
    storageType,
    streetName,
    detail,
    latitude,
    longitude,
  }: any) => {
    setLoading(true);
    const updateWarehouse = await apiCaller({
      api: updateWarehouseApi,
      params: {
        code,
        warehouse: {
          name,
          contactName,
          contactPhone,
          valuable,
          parentWarehouseUuid,
          storageType,
        },
        address: {
          wardId: wardValue?.key,
          streetName,
          detail,
          latitude: Number(latitude),
          longitude: Number(longitude),
        },
      } as IUpdateWarehouseApi,
    });
    setLoading(false);

    const { status } = updateWarehouse;
    if (status === API_RESPONSE_STATUS.SUCCESS) {
      navigate(`${R.WAREHOUSES_LIST}/${code}`);
    }
  };

  if (loading) return <UiLoadingPanel />;

  if (result?.status === "ERROR" || !warehouseInfo) return <NotFoundPanel />;

  return (
    <>
      <HeaderInfoPanel
        title={`${warehouseInfo?.name} #${warehouseInfo?.code}`}
        infoArea={() => (
          <TableCellStatus
            status={warehouseStatus}
            size={GENERAL_UI_SIZE.SMALL}
          />
        )}
        actionArea={() => (
          <>
            <UiTypography
              fontWeight="fw-bolder"
              className="text-xs text-bold me-2 pt-1"
            >
              STATUS
            </UiTypography>
            <UiSwitch
              checked={!warehouseInfo?.deleted_at}
              onChange={handleToggleStatus}
              isEditable={true}
              className="me-4"
            />
            <UiButton
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
              className="me-2"
              title={"Save Warehouse Info"}
            />
          </>
        )}
      />
      <SmallCollumnCenterGridLayout>
        <div className="mt-4">
          <ViewWarehouseInformation
            register={register}
            errors={errors}
            setWardValue={setWardValue}
            ward={wardValue?.value}
            isSubwarehouse={isSubwarehouse}
            addressValue={addressValue}
            setAddressValue={setAddressValue}
          />
          <GeneralPanel panel={{ subTitle: "Child warehouses" }}>
            <ViewChildWarehouse warehouse={result?.data?.child_warehouses} />
          </GeneralPanel>
        </div>
      </SmallCollumnCenterGridLayout>
    </>
  );
};

export default Component;
