import styles from "./styles.module.scss";

interface IComponent {
  title?: string;
  description?: string;
  image?: string;
  infoArea?: any;
  actionArea?: any;
  infoActionArea?: any;
  isDisplayInfoArea?: boolean;
  className?: string;
}

const Component = ({
  title = "Header info",
  description,
  image,
  infoArea: InfoArea,
  actionArea: ActionArea,
  isDisplayInfoArea = true,
  infoActionArea: InfoActionArea,
  className = "",
}: IComponent) => {
  return (
    <div className={`card shadow-lg card-profile-bottom ${className}`}>
      <div className="card-body p-3">
        <div className="row gx-4">
          {image && (
            <div className="col-auto">
              <div className="avatar avatar-xl position-relative">
                <img
                  src={image}
                  alt="profile_image"
                  className="w-100 border-radius-lg"
                />
              </div>
            </div>
          )}

          {isDisplayInfoArea && (
            <div className={`col-auto my-auto ${styles.colInfo}`}>
              <div className="h-100">
                {title && (
                  <h5 className={`mb-1 ${styles.ellipsisText}`}>{title}</h5>
                )}
                {description && (
                  <p className="mb-0 font-weight-bold text-sm">{description}</p>
                )}
                {InfoArea && <InfoArea />}
              </div>
            </div>
          )}

          {InfoActionArea && (
            <div className={`col-auto my-auto `}>
              <InfoActionArea />
            </div>
          )}

          {ActionArea && (
            <div className="col-auto my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
              <div className={styles.actionArea}>
                <ActionArea />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Component;
