import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IItemProps {
  [key: string]: any;
}

export interface IItemsState {
  itemsQuantity: IItemProps;
}

const initialState: IItemsState = {
  itemsQuantity: {
    productDetailNoteQty: 0,
  },
};

export const userSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    updateItemQuantityAction: (state, action: PayloadAction<IItemProps>) => {
      state.itemsQuantity = { ...state.itemsQuantity, ...action.payload };
    },
  },
});

export const { updateItemQuantityAction } = userSlice.actions;
export default userSlice.reducer;
