import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import EditAccountForm from "./view-edit-account-form";
import { formSchema } from "./formSchema";

import UiButton from "../../components/ui-button";
import NotFoundPanel from "../../components/panel-not-found";
import { useApiCaller } from "../../apis/config";
import HeaderInfoPanel from "../../components/panel-header-info";
import { ACCOUNT_LIST } from "../../routings/path";
import { getAccountDetailApi } from "../../apis/paths/accounts";
import TableCellStatus from "../../components/table-cell-status";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { GENERAL_STATUS } from "../../constants/status";
import { IAccountUser } from "../../types/account";
import { formatDateTime } from "../../utils/date-time";

const defaultAccountInfo = {
  avatar: "",
  inviter: null,
  name: "",
  status: GENERAL_STATUS.DEACTIVE,
  username: "",
  uuid: "",
};

const Component = () => {
  const [accountInfo, setAccountInfo] =
    useState<IAccountUser>(defaultAccountInfo);
  const [toggleStatus, setToggleStatus] = useState<
    GENERAL_STATUS.ACTIVATE | GENERAL_STATUS.DEACTIVE
  >(GENERAL_STATUS.DEACTIVE);
  const { accountId } = useParams();

  const uuid = accountId || "";
  const navigate = useNavigate();

  const [params] = useState<{
    page: number;
    per: number;
    uuid: string;
  }>({
    page: 1,
    per: 50,
    uuid,
  });

  const { result } = useApiCaller({
    api: getAccountDetailApi,
    params,
  });

  useEffect(() => {
    setAccountInfo(result?.data);
  }, [result]);

  useEffect(() => {
    setToggleStatus(accountInfo?.status);
  }, [accountInfo?.status]);

  const {
    setValue,
    register,
    control,
    watch,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    if (!accountInfo) return;

    const keys = Object.keys(formSchema?.fields);
    keys.forEach((key: any) => {
      if (key === "expired_at")
        setValue(
          "expired_at",
          formatDateTime(accountInfo[key as keyof IAccountUser])
        );
      setValue(key, accountInfo[key as keyof IAccountUser]);
    });
  }, [result, accountInfo]);

  if (accountInfo?.status === "ERROR") return <NotFoundPanel />;

  return (
    <>
      <div className="mb-4">
        <HeaderInfoPanel
          isDisplayInfoArea={true}
          title={accountInfo?.name}
          image={accountInfo?.avatar}
          infoArea={() => (
            <TableCellStatus
              status={toggleStatus}
              size={GENERAL_UI_SIZE.SMALL}
            />
          )}
          actionArea={() => (
            <UiButton
              className="me-2"
              title={"Edit Account"}
              onClick={() => {
                navigate(`${ACCOUNT_LIST}/${uuid}/edit`);
              }}
            />
          )}
        />
      </div>
      <EditAccountForm
        data={accountInfo}
        control={control}
        errors={errors}
        register={register}
        dirtyFields={dirtyFields}
        watch={watch}
      />
    </>
  );
};

export default Component;
