import * as yup from "yup";

export const formSchema = yup
  .object({
    name: yup.string().required('Name field is required'),
    description: yup.string(),
    upper_role_uuid: yup.string(),
    status: yup.string().oneOf(["activate", "deactivate"]).required('Status field is required'),
    permission_ids: yup
      .array()
      .required("Permission ids are required"),
   
  })
  .required();
