import * as R from "../../routings/path";
import { TABLE_ACTION } from "../../constants/table-action";
import DateTimeTableCell from "../../components/table-cell-date-time";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import WarehouseTableCell from "../../components/table-cell-warehouse";
import ActionTableCell from "../../components/table-cell-action";

export const schema = [
  {
    name: "Number",
    cell: (data: any) => (
      <ProductTableCell
        title={data?.number}
        linkTo={`${R.SALE_ORDER_LIST}/${data?.uuid}`}
        description={`#${data?.channel}`.toUpperCase()}
        maxWidth={120}
      />
    ),
  },
  {
    name: "Contact",
    cell: (data: any) => (
      <ProductTableCell
        subIcon={"fa-phone"}
        title={data?.receiver_name}
        description={data?.receiver_phone}
      />
    ),
  },
  {
    name: "Address",
    cell: (data: any) => (
      <ProductTableCell
        subIcon={"fa-location-dot"}
        description={data?.address?.full_address}
        descriptionLine={2}
        maxWidth={250}
      />
    ),
  },
  {
    name: "Warehouse",
    cell: (data: any) => <WarehouseTableCell warehouse={data} />,
  },
  {
    name: "Created",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data?.created_at} />,
  },
  {
    name: "Status",
    align: "center",
    cell: (data: any) => <StatusTableCell status={data?.status} />,
  },
  {
    name: "Action",
    align: "right",
    cell: (data: any) => (
      <ActionTableCell
        actions={data.instance_actionables}
        viewLink={`${R.SALE_ORDER_LIST}/${data?.uuid}`}
        editLink={`${R.SALE_ORDER_LIST}/${data?.uuid}/edit`}
        types={[TABLE_ACTION.VIEW, TABLE_ACTION.EDIT]}
        popupContent="Are you sure to delete this product ?"
      />
    ),
  },
];
