import * as R from "../../routings/path";
import {
  DynamicAccountBreadcrumb,
  DynamicMaterialBreadcrumb,
  DynamicPackingBreadcrumb,
  DynamicProductBreadcrumb,
  DynamicProductTypeBreadcrumb,
  DynamicPurchaseOrderBreadcrumb,
  DynamicSaleOrderBreadcrumb,
  DynamicWarehouseBreadcrumb,
  DynamicRoleBreadcrumb,
  DynamicTasksBreadcrumb,
  DynamicTplBreadcrumb,
  DynamicPickingBreadcrumb,
  DynamicDispatchBreadcrumb,
  DynamicRelocationShipmentDetailBreadcrumb,
  DynamicShopBreadcrumb,
} from "./dynamicComponents";

export const BREADCRUMB_ROUTES = [
  { path: R.AUTH_SIGN_IN, breadcrumb: "Sign In" },
  { path: R.DASHBOARD, breadcrumb: "Dashboard" },

  //------------------- PRODUCT -----------------------
  { path: R.PRODUCTS_LIST, breadcrumb: "Products" },
  { path: R.PRODUCTS_CREATE, breadcrumb: "New" },
  {
    path: R.PRODUCTS_DETAIL,
    breadcrumb: DynamicProductBreadcrumb,
    element: <>Product Detail</>,
  },
  { path: R.PRODUCTS_EDIT, breadcrumb: "Edit" },

  { path: R.PRODUCTS_TYPE_LIST, breadcrumb: "Product Type" },
  {
    path: R.PRODUCTS_TYPE_DETAIL,
    breadcrumb: DynamicProductTypeBreadcrumb,
    element: <>Product Type Detail</>,
  },
  { path: R.PRODUCTS_TYPE_EDIT, breadcrumb: "Edit" },
  { path: R.PRODUCTS_TYPE_CREATE, breadcrumb: "New" },

  //------------------- PURCHASE ORDER -----------------------
  { path: R.PURCHASE_ORDERS_LIST, breadcrumb: "Purchase Orders" },
  { path: R.PURCHASE_ORDERS_CREATE, breadcrumb: "Create" },
  { path: R.PURCHASE_ORDERS_EDIT, breadcrumb: "Edit" },
  {
    path: R.PURCHASE_ORDERS_DETAIL,
    breadcrumb: DynamicPurchaseOrderBreadcrumb,
    element: <>Purchase Orders Detail</>,
  },

  //------------------- SALE ORDER -----------------------
  { path: R.SALE_ORDERS_LIST, breadcrumb: "Sale Orders" },
  { path: R.SALE_ORDERS_CREATE, breadcrumb: "Create" },
  { path: R.SALE_ORDERS_EDIT, breadcrumb: "Edit" },
  { path: R.SALE_ORDER_KANBAN, breadcrumb: "Kanban" },
  {
    path: R.SALE_ORDERS_DETAIL,
    breadcrumb: DynamicSaleOrderBreadcrumb,
    element: <>Sale Order Detail</>,
  },

  //------------------- RELOCATION SHIPMENT -----------------------
  { path: R.RELOCATION_SHIPMENTS_LIST, breadcrumb: "Relocation Shipments" },
  { path: R.RELOCATION_SHIPMENTS_CREATE, breadcrumb: "Create" },
  { path: R.RELOCATION_SHIPMENTS_EDIT, breadcrumb: "Edit" },
  {
    path: R.RELOCATION_SHIPMENTS_DETAIL,
    breadcrumb: DynamicRelocationShipmentDetailBreadcrumb,
    element: <>Relocation Shipment Detail</>,
  },

  //------------------- WAREHOUSES -----------------------
  { path: R.WAREHOUSES_LIST, breadcrumb: "Warehouses" },
  {
    path: R.WAREHOUSES_DETAIL,
    breadcrumb: DynamicWarehouseBreadcrumb,
    element: <>Warehouse Detail</>,
  },
  { path: R.WAREHOUSES_CREATE, breadcrumb: "New" },
  { path: R.WAREHOUSES_EDIT, breadcrumb: "Edit" },
  { path: R.WAREHOUSES_RELATION, breadcrumb: "Relation" },
  { path: R.WAREHOUSES_LOCATION_LIST, breadcrumb: "Locations" },
  { path: R.WAREHOUSES_LOCATION_CREATE, breadcrumb: "Create" },
  { path: R.WAREHOUSES_LOCATION_DETAIL, breadcrumb: "Products Location" },

  //------------------- ROLES -----------------------
  { path: R.ROLES_LIST, breadcrumb: "Roles" },
  {
    path: R.ROLES_DETAIL,
    breadcrumb: DynamicRoleBreadcrumb,
    element: <>Role Detail</>,
  },
  { path: R.ROLES_CREATE, breadcrumb: "New" },
  { path: R.ROLES_EDIT, breadcrumb: "Edit" },

  //------------------- ACCOUNTS -----------------------
  { path: R.ACCOUNT_LIST, breadcrumb: "Accounts" },
  { path: R.ACCOUNT_CREATE, breadcrumb: "New" },
  { path: R.ACCOUNT_EDIT, breadcrumb: "Edit" },
  { path: R.ACCOUNT_RESET_PASSWORD, breadcrumb: "Reset Password" },
  { path: R.ACCOUNT_DETAIL_DEVICES, breadcrumb: "Devices" },
  { path: R.ACCOUNT_DEVICES, breadcrumb: "Devices" },
  {
    path: R.ACCOUNT_DETAIL,
    breadcrumb: DynamicAccountBreadcrumb,
    element: <>Account Detail</>,
  },
  { path: R.ACCOUNT_DEVICES, breadcrumb: "Devices" },

  //------------------- SHIPPING SERVICES -----------------------
  { path: R.SHIPPING_SERVICES, breadcrumb: "Shipping Services" },
  { path: R.COMPANY_INFO, breadcrumb: "Company Info" },
  { path: R.SLA, breadcrumb: "Service Level Agreement" },
  { path: R.ADVANCED_SETTINGS, breadcrumb: "Advanced Settings" },

  //------------------- SHIPPING SERVICES -----------------------
  { path: R.PICKINGS_LIST, breadcrumb: "Pickings" },
  { path: R.PICKINGS_EDIT, breadcrumb: "Edit" },
  { path: R.PICKINGS_CREATE, breadcrumb: "Create" },
  {
    path: R.PICKINGS_DETAIL,
    breadcrumb: DynamicPickingBreadcrumb,
    element: <>Packing Detail</>,
  },

  //------------------- PACKINGS -----------------------
  { path: R.PACKINGS_LIST, breadcrumb: "Packing" },
  { path: R.PACKINGS_CREATE, breadcrumb: "Create" },
  { path: R.PACKINGS_SALE_ORDER_EDIT, breadcrumb: "Packing Process" },
  { path: R.PACKINGS_SCANNED_PRODUCT_EDIT, breadcrumb: "Packing Process" },
  { path: R.PACKINGS_SCAN_PRODUCTS_EDIT, breadcrumb: "Scanning" },
  {
    path: R.PACKINGS_DETAIL,
    breadcrumb: DynamicPackingBreadcrumb,
    element: <>Packing Detail</>,
  },

  //------------------- TPLs -----------------------
  { path: R.TPL_ROUTES, breadcrumb: "TPLs Routes" },
  { path: R.TPL_ROUTES_CREATE, breadcrumb: " New" },
  { path: R.TPL_ROUTES_EDIT, breadcrumb: "Edit" },
  {
    path: R.TPL_ROUTES_DETAIL,
    breadcrumb: DynamicTplBreadcrumb,
    element: <>TPLs Route Detail</>,
  },

  //------------------- Materials -----------------------
  { path: R.MATERIAL_LIST, breadcrumb: "Materials" },
  { path: R.MATERIAL_CREATE, breadcrumb: "New" },
  { path: R.MATERIAL_EDIT, breadcrumb: "Edit" },
  {
    path: R.MATERIAL_DETAIL,
    breadcrumb: DynamicMaterialBreadcrumb,
    element: <>Material Detail</>,
  },

  //------------------- Shop -----------------------
  { path: R.SHOP_LIST, breadcrumb: "Shops" },
  { path: R.SHOP_CREATE, breadcrumb: "New" },
  { path: R.SHOP_EDIT, breadcrumb: "Edit" },
  {
    path: R.SHOP_DETAIL,
    breadcrumb: DynamicShopBreadcrumb,
    element: <>Shop Detail</>,
  },

  //------------------- Tasks -----------------------
  { path: R.TASK_LOGGING_LIST, breadcrumb: "Tasks" },
  { path: R.TASK_LOGGING_CREATE, breadcrumb: "New" },
  { path: R.TASK_LOGGING_EDIT, breadcrumb: "Edit" },
  {
    path: R.TASK_LOGGING_DETAIL,
    breadcrumb: DynamicTasksBreadcrumb,
    element: <>Task Detail</>,
  },

  //------------------- Dispatch -----------------------
  { path: R.DISPATCH_LIST, breadcrumb: "Dispatches" },
  {
    path: R.DISPATCH_DETAIL,
    breadcrumb: DynamicDispatchBreadcrumb,
    element: <>Dispatch Detail</>,
  },
];
