import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { generateUrlParams } from "../../utils/url";
import { get } from "../config";

export const getStateActivitiesApi = ({
  linked_object_type,
  linked_object_uuid,
}: {
  linked_object_type: string;
  linked_object_uuid: string;
}) =>
  get({
    path: `/wms/activities/state_activities${generateUrlParams({
      linked_object_type,
      linked_object_uuid,
    })}`,
  });

export const getAuditStateActivitiesApi = ({
  linked_object_type,
  linked_object_code,
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
}: {
  linked_object_type: string;
  linked_object_code: string;
  page?: number;
  per?: number;
}) =>
  get({
    path: `/wms/activities/${linked_object_type}/${linked_object_code}${generateUrlParams({
      page,
      per,
    })}`,
  });
