import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { formSchema } from "./formSchema";

import { apiCaller } from "../../apis/config";
import { detectRequiredlField } from "../../utils/validation";
import { ICreateLabel, createLabelsApi } from "../../apis/paths/labels";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { LABELS_LIST } from "../../routings/path";
import { LABELS_COLORS, LABELS_TYPE } from "../../constants/labels";
import alert from "../../utils/alert";
import UiForm from "../../components/ui-form";
import UiInput from "../../components/ui-input";
import UiButton from "../../components/ui-button";
import PanelGeneral from "../../components/panel-general/component";
import SelectDropdown from "../../components/ui-select-dropdown/component";
import HeaderInfoPanel from "../../components/panel-header-info";
import SmallCollumnCenterGridLayout from "../../components/grid-layout-small-collumn-center";

const Component = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async ({ name, description, label_type, color }: any) => {
    const waitCreateLabel = await apiCaller({
      api: createLabelsApi,
      params: {
        name,
        description,
        label_type,
        color,
      } as ICreateLabel,
    });

    if (waitCreateLabel.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Create successful!");
      navigate(LABELS_LIST);
      return;
    }

    alert.error("Create label was not successful!. Please try again!");
  };

  return (
    <>
      <UiForm onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <HeaderInfoPanel
            isDisplayInfoArea={true}
            title={"Create a new label"}
            description="You can utilize the labels to remark various status of PO, SO, ..."
            actionArea={() => <UiButton className="me-2" title={"Create"} />}
          />
        </div>
        <SmallCollumnCenterGridLayout>
          <PanelGeneral
            panel={{
              title: "Information",
            }}
          >
            <UiInput
              className="dataTable-input"
              name={"name"}
              title={"Name"}
              required={detectRequiredlField(formSchema, "name")}
              isDisplayLabel={true}
              register={register}
              errorMessage={errors?.name?.message}
            />
            <UiInput
              className="dataTable-input"
              name={"description"}
              title={"Description"}
              required={detectRequiredlField(formSchema, "description")}
              isDisplayLabel={true}
              register={register}
              errorMessage={errors?.description?.message}
            />
            <SelectDropdown
              name="label_type"
              className="w-100"
              title="Label Type"
              required
              isDisplayLabel
              register={register}
              setValue={setValue}
              optionList={LABELS_TYPE}
            />
            <SelectDropdown
              name="color"
              className="w-100"
              title="Color"
              isDisplayLabel
              register={register}
              setValue={setValue}
              optionList={LABELS_COLORS}
            />
          </PanelGeneral>
        </SmallCollumnCenterGridLayout>
      </UiForm>
    </>
  );
};

export default Component;
