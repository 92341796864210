import * as R from "../../routings/path";
import { TABLE_ACTION } from "../../constants/table-action";
import DateTimeTableCell from "../../components/table-cell-date-time";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import ActionTableCell from "../../components/table-cell-action";

interface ISchema {
  onDelete?: any;
  onToggle?: any;
  roleStatus?: any;
}

export const schema = ({ onDelete, onToggle, roleStatus }: ISchema) => [
  {
    name: "Name",
    cell: (data: any) => (
      <ProductTableCell
        title={data.name}
        description={data.description}
        maxWidth={400}
        linkTo={`${R.ROLES_LIST}/${data.uuid}`}
      />
    ),
  },
  {
    name: "Created",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data.created_at} />,
  },
  {
    name: "Status",
    align: "center",
    width: 120,
    cell: (data: any) => <StatusTableCell status={roleStatus[data.uuid]} />,
  },
  {
    name: "Action",
    align: "center",
    width: 120,
    cell: (data: any) => {
      return (
        <ActionTableCell
          actions={data.instance_actionables}
          types={[
            TABLE_ACTION.VIEW,
            TABLE_ACTION.TOGGLE,
            TABLE_ACTION.EDIT,
            TABLE_ACTION.DELETE,
          ]}
          toggleData={data.status}
          editLink={`${R.ROLES_LIST}/${data.uuid}/edit`}
          viewLink={`${R.ROLES_LIST}/${data.uuid}`}
          onDelete={() => {
            onDelete(data.uuid);
          }}
          popupContent="Are you sure to delete this role?"
          onToggle={() => {
            onToggle(data.uuid);
          }}
        />
      );
    },
  },
];
