import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { schema } from "./schema";
import { formSchema } from "./formSchema";
import MemberRoleCreate from "./view-create-member";

import { API_RESPONSE_STATUS } from "../../constants/api";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { ROLES_LIST } from "../../routings/path";
import { handlePreSortingData } from "../role-edit/utils";
import EditRoleForm from "../role-edit/view-edit-role-form";
import TablePanel from "../../components/panel-table";
import { apiCaller, useApiCaller } from "../../apis/config";
import {
  ICreateRoleDetailApi,
  createRoleDetailApi,
  getPermissionApi,
  getRoleListApi,
} from "../../apis/paths/roles";
import alert from "../../utils/alert";
import UiButton from "../../components/ui-button";
import GridLayoutTwoColumn from "../../components/grid-layout-two-column/component";
import NotFoundPanel from "../../components/panel-not-found";
import UiForm from "../../components/ui-form";
import HeaderInfoPanel from "../../components/panel-header-info";

const Component = () => {
  const navigate = useNavigate();
  const { result: rolesList } = useApiCaller({
    api: getRoleListApi,
    params: DEFAULT_PAGINATION_PARAMS,
  });

  const { result: permissionList } = useApiCaller({
    api: getPermissionApi,
    params: DEFAULT_PAGINATION_PARAMS,
  });

  const roles = useMemo(
    () => rolesList?.data?.role || {},
    [rolesList?.data?.role]
  );

  const {
    setValue,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  interface ICreateParams {
    name: string;
    status: "activate" | "deactivate";
    description?: string;
    permission_ids: any;
    upper_role_uuid?: any;
  }
  const onSubmit = async ({
    name,
    status,
    description,
    permission_ids,
    upper_role_uuid,
  }: ICreateParams) => {
    const selectingPermissionIds = [];
    if (permission_ids) {
      for (let i = 0; i < permission_ids?.length; i++) {
        if (permission_ids[i] === true) {
          selectingPermissionIds.push(i);
        }
      }
    }

    const waitUpdateRole = await apiCaller({
      api: createRoleDetailApi,
      params: {
        name,
        status,
        description,
        permission_ids: selectingPermissionIds,
        upper_role_uuid,
      } as ICreateRoleDetailApi,
    });

    if (waitUpdateRole.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update successful!");
      navigate(ROLES_LIST);
      return;
    }

    alert.error("Update was not successful!. Please try again!");
  };

  if (rolesList?.status === "ERROR" || permissionList?.status === "ERROR")
    return <NotFoundPanel />;

  return (
    <>
      <UiForm onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <HeaderInfoPanel
            isDisplayInfoArea={true}
            title="Create Role & Permission "
            description="Management system"
            actionArea={() => (
              <>
                <UiButton className="me-2" title={"Create"} />
              </>
            )}
          />
        </div>
        <GridLayoutTwoColumn
          className={{ container: "px-0" }}
          leftCol={
            <EditRoleForm
              rolesList={rolesList?.data?.list}
              roles={roles}
              setValue={setValue}
              control={control}
              errors={errors}
              register={register}
            />
          }
          rightCol={<MemberRoleCreate />}
        />

        <div className="pt-2">
          <TablePanel
            apiPath={getPermissionApi}
            panel={{
              title: "Permission",
              required: true,
            }}
            isDisplayedDataTopTable={false}
            dataTransformation={(result: any) =>
              result && {
                data: handlePreSortingData(result?.data?.list),
                role: result?.data?.role,
              }
            }
            tableSchema={schema({
              register,
              isSystem: roles?.is_system,
              control,
            })}
          />
        </div>
      </UiForm>
    </>
  );
};

export default Component;
