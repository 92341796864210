import React, { useState } from "react";

import { useApiCaller } from "../../apis/config";
import { formatDateTime } from "../../utils/date-time";
import { getStockHistoryProductOnLocation } from "../../apis/paths/warehouse";
import UiTimeline from "../../components/ui-timeline";
import Typography from "../../components/ui-typography/component";
import RowTextContent from "../../components/ui-row-text-content/component";
import LoadingPanel from "../../components/ui-loading-panel/component";
import PopupConfirmation from "../../components/popup-confirmation/component";

interface ICheckProductHistoriesPopup {
  isShowModal: boolean;
  handleCloseModal?: () => void;
  title?: string | React.ReactNode;
  params: {
    warehouseId: string;
    locationCode: string;
    productCode: string;
    productUuid: string;
  };
}

const CheckProductHistoriesPopup = ({
  isShowModal,
  handleCloseModal,
  params,
}: ICheckProductHistoriesPopup) => {
  const { warehouseId, locationCode, productCode, productUuid } = params || {};

  const [popupLocationParams] = useState<{
    warehouseId: string;
    locationCode: string;
    productUuid: string;
  }>({
    warehouseId,
    locationCode,
    productUuid,
  });

  const { result, loading } = useApiCaller({
    api: getStockHistoryProductOnLocation,
    params: popupLocationParams,
  });

  return (
    <PopupConfirmation
      isShowModal={isShowModal}
      onCloseModal={handleCloseModal}
      header={
        <div className="d-flex align-items-center gap-2">
          <Typography fontSize="fs-5" fontWeight="fw-bolder">
            {productCode}
          </Typography>
          -
          <Typography fontSize="fs-5" fontWeight="fw-bold">
            Stocks change history
          </Typography>
        </div>
      }
    >
      <div>
        <div className="modal-body">
          {loading ? (
            <LoadingPanel />
          ) : (
            <UiTimeline
              displayLimit={3}
              list={result?.data?.list?.map((activity: any) => ({
                icon: "gear",
                title: activity?.comment,
                subTitle: activity?.requester?.name
                  ? `by ${activity?.requester?.name}`
                  : `by ${activity?.comment}`,
                description: formatDateTime(
                  activity?.created_at,
                  "HH:mm:ss DD-MM-YYYY"
                ),
                extraElement: (
                  <div className="d-flex flex-column">
                    <RowTextContent
                      sub={"Quantity before:"}
                      title={activity?.before_quantity}
                      className={{ content2: "me-3" }}
                    />
                    <RowTextContent
                      sub={"Quantity after:"}
                      title={activity?.after_quantity}
                      className={{ content2: "me-3" }}
                    />
                    <RowTextContent
                      sub={"Stock change:"}
                      className={{ content2: "text-warning me-3" }}
                      title={activity?.stock_change}
                    />
                  </div>
                ),
              }))}
            />
          )}
        </div>
        <hr className="horizontal dark mt-3" />
      </div>
    </PopupConfirmation>
  );
};

export default CheckProductHistoriesPopup;
