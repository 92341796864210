import { Controller } from "react-hook-form";
import classnames from "classnames";

import { convertUnixTimeToISO, formatDateTime } from "../../utils/date-time";
import styles from "./style.module.scss";

//To do [HUNG]- Refactor this Datepicker for display default value
interface PropsType {
  title?: string;
  isDisplayLabel?: boolean;
  disabled?: boolean;
  name: string;
  className?: string;
  errorMessage?: string;
  required?: boolean;
  value?: any;
  control: any;
  dirtyFields: any;
  type?: "date" | "datetime-local";
}

const DatePicker = ({
  title,
  isDisplayLabel = true,
  name,
  disabled = false,
  className = "",
  errorMessage = "",
  value,
  required = false,
  control,
  dirtyFields,
  type = "date",
}: PropsType) => {
  return (
    <>
      {isDisplayLabel && (
        <label data-required={required}>{title || name}</label>
      )}
      <div className="mb-3">
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            let selectedDay = field.value;
            if (!dirtyFields?.[name]) {
              selectedDay = formatDateTime(field.value as number);
              if (type === "datetime-local" && field.value) {
                selectedDay = convertUnixTimeToISO(field.value as number);
              }
            }
            return (
              <input
                disabled={disabled}
                className={classnames(
                  "form-control",
                  styles.fullClickable,
                  className
                )}
                type={type}
                {...field}
                value={selectedDay}
                onChange={(e) => field.onChange(e.target.value)}
              />
            );
          }}
        />
        {errorMessage && errorMessage.length && (
          <label className="mb-0" style={{ color: "#f5365c" }}>
            {errorMessage}
          </label>
        )}
      </div>
    </>
  );
};

export default DatePicker;
