import  { useEffect, useMemo, useState } from "react";
import classNames from "classnames";

import { useApiCaller } from "../../apis/config";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { getAttachmentsApi } from "../../apis/paths/attachments";
import UiButton from "../../components/ui-button";
import NotFoundPanel from "../panel-not-found/component";
import Typography from "../ui-typography/component";

import { IAttachment } from "./types";
import AttachmentItem from "./view-attachment-item";
import GeneralHeading from "../ui-general-heading/component";

interface IPanelAttachment {
  attachable_uuid: string;
  attachable_type: string;
  setAttachmentQuantity: any;
  isDisplayHeading?: boolean;
  className?: {
    container?: string;
    container1?: string;
    card?: string;
    cardBody?: string;
    heading?: string;
    editArea?: string;
  };
  displayLimit?: number;
}

const PanelAttachment = ({
  attachable_uuid,
  attachable_type,
  setAttachmentQuantity,
  isDisplayHeading = false,
  className = {
    container: "",
    container1: "",
    card: "",
    cardBody: "",
    heading: "",
    editArea: "mt-4",
  },
  displayLimit = 3,
}: IPanelAttachment) => {
  const [pageReloadVersion, setPageReloadVersion] = useState(0);
  const [isRendered, setIsRendered] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [attachmentParams, setAttachmentParams] = useState<{
    page: number;
    per: number;
    attachable_uuid: string;
    attachable_type: string;
  }>({
    page: 1,
    per: 50,
    attachable_uuid,
    attachable_type,
  });

  useEffect(() => {
    if (!isRendered && !!attachable_uuid && !!attachable_type) {
      setAttachmentParams((prevState) => ({
        ...prevState,
        attachable_uuid,
        attachable_type,
      }));
      setIsRendered(true);
    }
  }, [attachable_uuid, attachable_type]);

  const { result: attachments } = useApiCaller({
    api: getAttachmentsApi,
    params: attachmentParams,
    pageReloadVersion,
  });

  useEffect(() => {
    if (attachments?.data?.list) {
      setAttachmentQuantity(attachments?.data?.list?.length);
    }
  }, [attachments]);

  const attachmentItems = useMemo(
    () =>
      isCollapsed
        ? attachments?.data?.list?.slice()?.reverse()?.slice(0, displayLimit)
        : attachments?.data?.list?.slice()?.reverse(),
    [attachments, displayLimit, isCollapsed]
  );

  if (!attachable_uuid || !attachable_type) return null;

  if (attachments?.status === "ERROR") return <NotFoundPanel />;

  return (
    <div className={classNames("col-12", className.container)}>
      <div className={classNames("card h-100", className.card)}>
        <div className={classNames("card-body", className.cardBody)}>
          <div className="d-flex flex-column">
            {isDisplayHeading && (
              <GeneralHeading className={classNames(className.heading, "mb-3")}>
                Attachments ({attachments?.data?.list?.length})
              </GeneralHeading>
            )}
            <div
              className={classNames(
                "mb-1 d-flex flex-column",
                className.container1
              )}
            >
            {
              attachments?.data?.list?.length === 0 && (
                <Typography customFontsize={14}>No files</Typography>
              )
            }

              {attachmentItems?.map((attachment: IAttachment) => (
                <div>
                  <AttachmentItem
                    attachment={attachment}
                    setPageReloadVersion={setPageReloadVersion}
                  />
                </div>
              ))}
              {attachments?.data?.list?.length > displayLimit && (
                <UiButton
                  className="mt-3 mb-3 me-auto"
                  onClick={() => setIsCollapsed((prev) => !prev)}
                  title={isCollapsed ? "View more" : "View less"}
                  size={GENERAL_UI_SIZE.SMALL}
                  type={GENERAL_UI_COLOR.LIGHT}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanelAttachment;
