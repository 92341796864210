import { Children } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

interface IGridLayoutProps {
  fluid?: boolean;
  widthDivision?: number[];
  className?: any;
  children: any;
}

const GridLayoutDynamicCollumn = ({
  fluid = false,
  widthDivision = [6, 6],
  className,
  children,
}: IGridLayoutProps) => {
  const childArray = Children.toArray(children);

  return (
    <Container fluid={fluid} className={className}>
      <Row className="flex-wrap">
        {childArray?.map((child, index) => (
          <Col key={index} xs={widthDivision[index]}>
            <div className="flex-grow-1"> {child} </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default GridLayoutDynamicCollumn;
