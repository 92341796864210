import React, { memo } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { IKanbanItem } from "./component";
import { formatBadgeColor } from "./view-card-kanban";

import { GENERAL_UI_SIZE } from "../../constants/size";
import Badge from "../../components/ui-badge/component";
import Typography from "../../components/ui-typography/component";

const ViewNarrowKanbanItem = ({
  saleOrders,
  filterOption,
  onExpand,
}: {
  saleOrders: any;
  filterOption: IKanbanItem;
  onExpand: () => void;
}) => {
  return (
    <div className={classNames(styles.narrowKanbanListContainer)}>
      <div
        className={classNames(
          "d-flex gap-2 py-4 flex-column align-items-center",
          styles.rotatedZone
        )}
      >
        <div className={classNames(styles.kanbanHeader, styles.textDirection)}>
          <Typography
            fontSize="fs-5"
            fontWeight="fw-bold"
            textColor="black"
            className=""
          >
            {filterOption?.title}
          </Typography>
        </div>

        <div className={styles.textDirection}>
          <Badge
            customFontsize={10}
            content={filterOption?.title?.toLocaleLowerCase()}
            size={GENERAL_UI_SIZE.XSMALL}
            type={formatBadgeColor(filterOption?.title?.toLocaleLowerCase())}
          />
        </div>
        <Typography fontWeight="fw-bold" className={styles.textDirection}>
          {`${saleOrders?.list?.length || 0}`}
        </Typography>
        <div
          className={classNames(" mt-1", styles.expandIcon)}
          onClick={onExpand}
        >
          <i className="fa-solid fa-up-right-and-down-left-from-center"></i>
        </div>
      </div>
    </div>
  );
};

export default memo(ViewNarrowKanbanItem);
