import * as yup from "yup";
import { splitFieldByBrackets } from "../containers/dynamic-advanced-settings/utils";

export interface IParameterInfo {
  description?: string;
  format?: string;
  in?: string;
  enum?: any[];
  maximum?: number;
  minimum?: number;
  name?: string;
  required?: boolean;
  type?: string;
}

const createValidationSchema = (parameter: IParameterInfo) => {
  switch (parameter?.type) {
    case "string":
      return parameter?.required
        ? yup.string().required("This field is required")
        : yup.string().nullable();
    case "boolean":
      return parameter?.required
        ? yup.boolean().required("This field is required")
        : yup.boolean().nullable();
    case "integer":
      return parameter?.required
        ? yup
            .number()
            .integer()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .required("This field is required")
        : yup
            .number()
            .integer()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable();

    case "number":
      return parameter?.required
        ? yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .required("This field is required")
        : yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable();
    case "array":
      return parameter?.required
        ? yup
            .array()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .required("This field is required")
        : yup
            .array()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable();
    default:
      return yup.string().nullable();
  }
};

export const dynamicFormSchema = (parameters: IParameterInfo[]) => {
  const schemaObject: Record<string, any> = {};

  parameters?.forEach((parameter) => {
    if (parameter.name && parameter.type) {
      const { parentText, childText } = splitFieldByBrackets(parameter?.name);
      schemaObject[`${parentText}-${childText}`] =
        createValidationSchema(parameter);
    }
  });

  return yup.object(schemaObject).required();
};
