export enum GENERAL_UI_COLOR {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  INFO = "info",
  SUCCESS = "success",
  DANGER = "danger",
  WARNING = "warning",
  LIGHT = "light",
  DARK = "dark",
}
