import { GENERAL_UI_SIZE } from "../../constants/size";
import { GENERAL_UI_COLOR } from "../../constants/color";

import styles from "./styles.module.scss";

import React, { memo, useState } from "react";
import classNames from "classnames";
import { formatDateTime } from "../../utils/date-time";
import Avatar from "../ui-avatar/component";
import Typography from "../ui-typography/component";
import DownloadButton from "../../components/ui-download-button/component";
import UiButton from "../../components/ui-button";
import { usePrinting } from "../../hooks/usePrinting";

import { IAttachment } from "./types";

import anyFileImage from "../../styles/images/file_image_any.png";
import PDFFileImage from "../../styles/images/file_image_pdf.jpg";
import TEXTFileImage from "../../styles/images/file_image_text.png";


const fileExtensionImageMap: Record<string, string> = {
  pdf: PDFFileImage,
  txt: TEXTFileImage,
  any: anyFileImage,
  // Add more file extensions and corresponding images as needed
};
const extractFileExtension = (fileName: string) => {
  return fileName.split(".").pop()?.toLowerCase();
};
const getFileImage = (fileName: string) => {
  const fileExtension = extractFileExtension(fileName) || 'any'
  return fileExtension in fileExtensionImageMap ? fileExtensionImageMap[fileExtension] : fileExtensionImageMap['any']
};

interface PrintButtonProps {
  fileUrl: string;
}

const PrintButton: React.FC<PrintButtonProps> = ({ fileUrl }) => {
  const handlePrint = () => {
    const newWindow = window.open(fileUrl, '_blank');

    if (newWindow) {
      newWindow.onload = () => {
        newWindow.print();
      };
    } else {
      alert('Pop-up blocker is preventing the new window from opening.');
    }
  };

  return (
    <button onClick={handlePrint}>
      Print File
    </button>
  );
};


const AttachmentItem = ({
  attachment,
  setPageReloadVersion = () => { }, // TODO: Recheck to use this
}: {
  attachment: IAttachment;
  setPageReloadVersion?: (params: any) => void;
  uuid?: string;
  isNoteEditable?: boolean;
}) => {
  const { filename, created_at, url, printable } = attachment || {};
  const { printUrl } = usePrinting();


  return (
    <div className="d-flex mt-2">
      <div className="flex-shrink-0">
        <Avatar
          imageSrc={getFileImage(filename)}
          name={'pdf'}
          variant={GENERAL_UI_COLOR.SECONDARY}
          size="lg"
          allowDynamicBackgroundColor
        />
      </div>
      <div className=" d-flex flex-grow-1 ms-3 ">
        <div className="me-4 w-100">
          <div className="d-flex gap-2">
            <Typography customFontsize={12}>
              {formatDateTime(created_at as number, "YYYY-MM-DD  HH:mm")}
            </Typography>
          </div>
          <a className={classNames("text-sm")} href={url} target={'_blank'}>{filename}</a>
        </div>
        <div className={classNames("mt-1", styles.printButtonContainer)}>
          <UiButton
            size={GENERAL_UI_SIZE.XSMALL}
            title={""}
            onClick={() => printUrl(url || '', 'paper')}
            disabled={!printable}
          >
            <Typography
              customFontsize={10}
              fontWeight="fw-bold"
              className="d-flex align-items-center gap-2"
            >
              Print
            </Typography>
          </UiButton>
        </div>
      </div>
    </div>
  );
};

export default memo(AttachmentItem);
