import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { formSchema } from "./formSchema";

import UiButton from "../../components/ui-button";
import { apiCaller } from "../../apis/config";
import UiForm from "../../components/ui-form";
import HeaderInfoPanel from "../../components/panel-header-info";
import alert from "../../utils/alert";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { PRODUCTS_TYPE_LIST } from "../../routings/path";
import SmallCollumnCenterGridLayout from "../../components/grid-layout-small-collumn-center";
import {
  ICreateProductTypeApi,
  createProductTypeApi,
} from "../../apis/paths/products";
import PanelGeneral from "../../components/panel-general/component";
import UiInput from "../../components/ui-input";
import { detectRequiredlField } from "../../utils/validation";
import SelectDropdown from "../../components/ui-select-dropdown/component";
import { STRATEGY } from "../../constants/product";

const Component = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async ({ name, description, strategy }: any) => {
    const waitCreateProductType = await apiCaller({
      api: createProductTypeApi,
      params: {
        name,
        description,
        strategy,
      } as ICreateProductTypeApi,
    });

    if (waitCreateProductType.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Create successful!");
      navigate(PRODUCTS_TYPE_LIST);
      return;
    }

    alert.error("Create Product Type was not successful!. Please try again!");
  };

  return (
    <>
      <UiForm onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <HeaderInfoPanel
            isDisplayInfoArea={true}
            title={"Create a new product type"}
            description="You can utilize the ProductType to manage various types of Products like Expire, Bulky, Fragile, etc..."
            actionArea={() => <UiButton className="me-2" title={"Create"} />}
          />
        </div>
        <SmallCollumnCenterGridLayout>
          <PanelGeneral
            panel={{
              title: "Information",
            }}
          >
            <UiInput
              className="dataTable-input"
              name={"name"}
              title={"Name"}
              required={detectRequiredlField(formSchema, "name")}
              isDisplayLabel={true}
              register={register}
              errorMessage={errors?.name?.message}
            />
            <UiInput
              className="dataTable-input"
              name={"description"}
              title={"Description"}
              required={detectRequiredlField(formSchema, "description")}
              isDisplayLabel={true}
              register={register}
              errorMessage={errors?.description?.message}
            />
            <SelectDropdown
              name="strategy"
              className="w-100"
              title="Strategy"
              required
              isDisplayLabel
              register={register}
              setValue={setValue}
              optionList={STRATEGY}
            />
          </PanelGeneral>
        </SmallCollumnCenterGridLayout>
      </UiForm>
    </>
  );
};

export default Component;
