import * as R from "../../routings/path";
import { TABLE_ACTION } from "../../constants/table-action";
import { ACTIONABLE_TYPE } from "../../constants/actionable";

import DateTimeTableCell from "../../components/table-cell-date-time";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import ActionTableCell from "../../components/table-cell-action";

export const saleOrdersSchema = ({
  selectedSaleOrders,
  onSelectSaleOrder,
  isEditable,
}: {
  selectedSaleOrders: Array<any>;
  onSelectSaleOrder: Function;
  isEditable: boolean;
}) => [
  {
    name: "Number",
    cell: (data: any) => (
      <ProductTableCell
        title={data.number}
        linkTo={`${R.SALE_ORDER_LIST}/${data.uuid}`}
        description={`#${data.channel}`.toUpperCase()}
        maxWidth={200}
      />
    ),
  },
  {
    name: "Created",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data.created_at} />,
  },
  {
    name: "Status",
    align: "center",
    cell: (data: any) => <StatusTableCell status={data.status} />,
  },
  {
    name: "Action",
    align: "right",
    textSize: "xs",
    cell: (data: any) => {
      const isExist = selectedSaleOrders?.some(
        (saleOrder: any) => saleOrder?.uuid === data?.uuid
      );

      if (isExist) return <div> Added</div>;

      return (
        <ActionTableCell
          types={[TABLE_ACTION.CUSTOM]}
          customActionableType={ACTIONABLE_TYPE.EDIT}
          actions={{ edit: { actionable: isEditable, message: [] } }}
          customTitle={"Select"}
          onCustomClick={() => onSelectSaleOrder(data)}
        />
      );
    },
  },
];

export const selectedSaleOrdersSchema = ({
  onRemoveSaleOrder,
  isEditable,
}: {
  onRemoveSaleOrder: Function;
  isEditable: boolean;
}) => [
  {
    name: "Number",
    cell: (data: any) => (
      <ProductTableCell
        title={data.number}
        description={`#${data.channel}`.toUpperCase()}
        maxWidth={130}
      />
    ),
  },
  {
    name: "Created",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data.created_at} />,
  },
  {
    name: "Action",
    align: "right",
    textSize: "xs",
    cell: (data: any) => {
      return (
        <ActionTableCell
          types={[TABLE_ACTION.CUSTOM]}
          customActionableType={ACTIONABLE_TYPE.EDIT}
          customTitle={"Remove"}
          actions={{ edit: { actionable: isEditable, message: [] } }}
          onCustomClick={() => onRemoveSaleOrder(data)}
        />
      );
    },
  },
];
