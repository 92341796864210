import * as R from "../../routings/path";
import { TABLE_ACTION } from "../../constants/table-action";
import { longHyphen } from "../../constants/character";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import ActionTableCell from "../../components/table-cell-action";
import SearchableTableCell from "../../components/table-cell-searchable";
import DateTimeTableCell from "../../components/table-cell-date-time";

export const schema = [
  {
    name: "Code",
    cell: (data: any) => (
      <ProductTableCell
        title={data?.code}
        descriptionLine={2}
        linkTo={`${R.STOCK_CHECK_LIST}/${data?.uuid}`}
        maxWidth={80}
      />
    ),
  },
  {
    name: "Group Code",
    align: "center",
    textSize: "xs",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <SearchableTableCell value={data?.group_code} />,
  },
  {
    name: "Checkers",
    cell: (data: any) => (
      <div>
        {data?.checkers?.length
          ? data?.checkers?.map((checker: any) => (
              <ProductTableCell
                isForceDisplayAvatar={true}
                title={checker?.name}
                userName={checker?.name}
                image={checker?.avatar}
                previewImage={false}
                maxWidth={100}
              />
            ))
          : longHyphen}
      </div>
    ),
  },
  {
    name: "Created",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data?.created_at} />,
  },
  {
    name: "Start",
    align: "center",
    sortTypes: {
      key: "started_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data?.started_at} />,
  },
  {
    name: "End",
    align: "center",
    sortTypes: {
      key: "ended_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data?.ended_at} />,
  },
  {
    name: "Status",
    align: "center",
    cell: (data: any) => <StatusTableCell status={data?.status} />,
  },
  {
    name: "Action",
    align: "right",
    cell: (data: any) => (
      <ActionTableCell
        actions={data.instance_actionables}
        viewLink={`${R.STOCK_CHECK_LIST}/${data?.uuid}`}
        types={[TABLE_ACTION.VIEW]}
      />
    ),
  },
];
