import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  deleleAccountApi,
  getAccountDetailApi,
  toogleAccountStatusApi,
  updateAccountApi,
} from "../../apis/paths/accounts";
import {
  formatDateTime,
  convertYYYYMMDDToUnixTime,
} from "../../utils/date-time";
import { IAccountUser } from "../../types/account";
import { ACCOUNT_LIST } from "../../routings/path";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { GENERAL_STATUS } from "../../constants/status";
import { apiCaller, useApiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { GENERAL_UI_COLOR } from "../../constants/color";
import alert from "../../utils/alert";
import UiForm from "../../components/ui-form";
import UiSwitch from "../../components/ui-switch";
import UiButton from "../../components/ui-button";
import NotFoundPanel from "../../components/panel-not-found";
import TableCellStatus from "../../components/table-cell-status";
import Typography from "../../components/ui-typography/component";
import HeaderInfoPanel from "../../components/panel-header-info";
import QuickActionPopupConfirmation from "../../components/popup-quick-aciton-confirmation/component";

import { formSchema } from "./formSchema";
import EditAccountForm from "./view-edit-account-form";

const defaultAccountInfo = {
  avatar: "",
  inviter: null,
  name: "",
  status: GENERAL_STATUS.DEACTIVE,
  username: "",
  uuid: "",
};

const Component = () => {
  const [pageReloadVersion, setPageReloadVersion] = useState(1);
  const [isShowModal, setIsShowModal] = useState(false);
  const [accountInfo, setAccountInfo] =
    useState<IAccountUser>(defaultAccountInfo);
  const [toggleStatus, setToggleStatus] = useState<
    GENERAL_STATUS.ACTIVATE | GENERAL_STATUS.DEACTIVE
  >(GENERAL_STATUS.DEACTIVE);
  const { accountId } = useParams();

  const uuid = accountId || "";
  const navigate = useNavigate();

  const [params] = useState<{
    page: number;
    per: number;
    uuid: string;
  }>({
    page: 1,
    per: 50,
    uuid,
  });

  const { result } = useApiCaller({
    api: getAccountDetailApi,
    params,
    pageReloadVersion,
  });

  useEffect(() => {
    setAccountInfo(result?.data);
  }, [result]);

  useEffect(() => {
    setToggleStatus(accountInfo?.status);
  }, [accountInfo?.status]);

  const handleDeleteAccount = async (uuid: string) => {
    const waitDelete = await apiCaller({
      api: deleleAccountApi,
      params: { uuid },
    });

    if (waitDelete.status === API_RESPONSE_STATUS.SUCCESS) {
      setTimeout(() => {
        alert.success("Delete successful!");
      }, 1000);

      navigate(ACCOUNT_LIST);
      return;
    }

    alert.error("Delete was unsuccessful!. Please try again");
  };

  const handleOpenModal = () => {
    setIsShowModal(true);
  };

  const handleCloseModal = () => {
    setIsShowModal(false);
  };

  const {
    setValue,
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    if (!accountInfo) return;

    const keys = Object.keys(formSchema?.fields);
    keys.forEach((key: any) => {
      if (key === "expired_at")
        setValue(
          "expired_at",
          formatDateTime(accountInfo[key as keyof IAccountUser])
        );
      setValue(key, accountInfo[key as keyof IAccountUser]);
    });
  }, [result, accountInfo]);

  const onSubmit = async ({ name, username, status, expired_at }: any) => {
    let expiredAt = expired_at;
    if (typeof expired_at === "string") {
      expiredAt = convertYYYYMMDDToUnixTime(expired_at);
    }
    const waitUpdateRole = await apiCaller({
      api: updateAccountApi,
      params: {
        uuid,
        name,
        username,
        status,
        expired_at: expiredAt,
      },
    });

    if (waitUpdateRole.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update successful!");
      setTimeout(() => {
        setPageReloadVersion((prev) => prev + 1);
      }, 1000);
      navigate(0);
      return;
    }

    alert.error("Update was not successful!. Please try again!");
  };

  const toggleUserStatus = async () => {
    const oldStatus = toggleStatus;
    const nextStatus =
      toggleStatus === GENERAL_STATUS.ACTIVATE
        ? GENERAL_STATUS.DEACTIVE
        : GENERAL_STATUS.ACTIVATE;
    setToggleStatus(nextStatus);

    setValue("status", nextStatus);
    const waitToggle = await apiCaller({
      api: toogleAccountStatusApi,
      params: { uuid },
    });

    if (waitToggle.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Change status successful!.");
      return;
    }
    setToggleStatus(oldStatus);
    alert.error("Change status was not successful!. Please try again");
  };
  if (accountInfo?.status === "ERROR") return <NotFoundPanel />;

  return (
    <>
      <UiForm onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <HeaderInfoPanel
            isDisplayInfoArea={true}
            title={accountInfo?.name}
            image={accountInfo?.avatar}
            infoArea={() => (
              <TableCellStatus
                status={toggleStatus}
                size={GENERAL_UI_SIZE.SMALL}
              />
            )}
            actionArea={() => (
              <>
                <Typography
                  fontWeight="fw-bolder"
                  className="text-xs text-bold me-2 pt-1"
                >
                  STATUS
                </Typography>

                <UiSwitch
                  checked={toggleStatus === GENERAL_STATUS.ACTIVATE}
                  onChange={toggleUserStatus}
                  isEditable={true}
                  className="me-4"
                />

                <UiButton className="me-2" title={"Update"} />
                <UiButton
                  type={GENERAL_UI_COLOR.DANGER}
                  onClick={handleOpenModal}
                  title={"Delete"}
                />
              </>
            )}
          />
        </div>
        <EditAccountForm
          data={accountInfo}
          control={control}
          errors={errors}
          register={register}
          dirtyFields={dirtyFields}
          watch={watch}
        />
      </UiForm>

      <QuickActionPopupConfirmation
        content={"Are you sure to delete this product ?"}
        isShowModal={isShowModal}
        handleCloseModal={handleCloseModal}
        onConfirm={() => {
          handleDeleteAccount(uuid as string);
        }}
      />
    </>
  );
};

export default Component;
