import * as yup from "yup";

export const schema = yup
  .object({
    password: yup.string().required(),
    range: yup.string().required(),
    checkbox: yup.bool().oneOf([true], 'You need to accept the terms and conditions'),
    date: yup.date(),
    // switch: yup.bool().oneOf([true], 'You need to accept the terms and conditions')
    switch: yup.bool()
  })
  .required();
