import { useState } from "react";
import { useNavigate } from "react-router-dom";

import * as R from "../../routings/path";
import {
  getDeletedLabelsListApi,
  getLabelsListApi,
  toggleLabelsApi,
} from "../../apis/paths/labels";
import { apiCaller } from "../../apis/config";
import { API_RESPONSE_STATUS } from "../../constants/api";
import UiButton from "../../components/ui-button";
import alert from "../../utils/alert";
import UiTab from "../../components/ui-tab";
import TablePanel from "../../components/panel-table";

import { schema } from "./schema";
import { deletedLabelsSchema } from "./deletedLabelsSchema";

const Component = () => {
  const navigate = useNavigate();
  const [pageReloadVersion, setPageReloadVersion] = useState(1);
  const [currentActiveTab, setCurrentActiveTab] = useState(0);

  const handleDeleteLabel = async (uuid: string) => {
    const waitDelete = await apiCaller({
      api: toggleLabelsApi,
      params: { uuid },
    });

    if (waitDelete.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Delete successful!");

      setPageReloadVersion((prev) => prev + 1);
      return;
    }

    alert.error("Delete was unsuccessful!. Please try again");
  };

  const handleRestoreLabel = async (uuid: string) => {
    const waitRestore = await apiCaller({
      api: toggleLabelsApi,
      params: { uuid },
    });

    if (waitRestore.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Restore successful!");

      setPageReloadVersion((prev) => prev + 1);
      return;
    }

    alert.error("Restore was unsuccessful!. Please try again");
  };

  const handleToggleUiTab = (newActiveTab: number) => {
    setCurrentActiveTab(newActiveTab);
    setPageReloadVersion((prev) => prev + 1);
  };

  return currentActiveTab === 0 ? (
    <TablePanel
      apiPath={getLabelsListApi}
      pageReloadVersion={pageReloadVersion}
      isSearchable={false}
      panel={{
        title: "Labels List",
        description: "List of labels in the warehouse",

        actionArea: () => (
          <>
            <UiButton
              title={"New Label"}
              onClick={() => navigate(R.LABELS_CREATE)}
            />
            <UiTab
              tabs={["In use", "Deleted"]}
              selected={currentActiveTab}
              onChange={handleToggleUiTab}
            />
          </>
        ),
      }}
      tableSchema={schema({ onDelete: handleDeleteLabel })}
    />
  ) : (
    <TablePanel
      apiPath={getDeletedLabelsListApi}
      pageReloadVersion={pageReloadVersion}
      isSearchable={false}
      panel={{
        title: "Deleted Labels",
        description: "List of deleted labels in the warehouse",

        actionArea: () => (
          <UiTab
            tabs={["In use", "Deleted"]}
            selected={currentActiveTab}
            onChange={handleToggleUiTab}
          />
        ),
      }}
      tableSchema={deletedLabelsSchema({
        onRestore: handleRestoreLabel,
      })}
    />
  );
};

export default Component;
