import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classNames from "classnames";

interface IGridLayoutProps {
  fluid?: boolean;
  leftCol: any;
  rightCol: any;
  widthDivision?: [number, number];
  className?: {
    container?: string;
    left?: string;
    right?: string;
  };
  gutter?: number;
  isDisplayPartition?: boolean;
}
const GridLayoutTwoColumn = ({
  fluid = false,
  leftCol,
  rightCol,
  widthDivision = [6, 6],
  className = {
    container: "",
    left: "",
    right: "",
  },
  gutter = 4,
  isDisplayPartition = false,
}: IGridLayoutProps) => {
  return (
    <Container fluid={fluid} className={`px-0 ${className.container}`}>
      <Row className={classNames(`g-${gutter}`)}>
        <Col
          md={widthDivision[0]}
          className={classNames(
            isDisplayPartition && "border-end",
            className.left
          )}
        >
          {leftCol}
        </Col>
        <Col md={widthDivision[1]} className={className.right}>
          {rightCol}
        </Col>
      </Row>
    </Container>
  );
};

export default GridLayoutTwoColumn;
