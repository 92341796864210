import { get, patch, put } from "../config";

export interface IUpdateWorkspace {
  name: string;
  email: string;
  phone: string;
  website: string;
  address: string;
}

export const getSettingsConstantApi = () =>
  get({
    path: `/wms/constants`,
  });

export const updateConstantsWorkspaceApi = ({
  name,
  email,
  phone,
  website,
  address,
}: IUpdateWorkspace) =>
  put({
    path: `/wms/constants/workspace`,
    data: {
      name,
      email,
      phone,
      website,
      address,
    },
  });

export const updateConstantsSlaApi = ({ data }: Record<string, any>) =>
  put({
    path: `/wms/constants/sla`,
    data,
  });

export const updateAdvancedSettingsApi = ({ data }: Record<string, any>) =>
  put({
    path: `/wms/constants/advanced`,
    data,
  });

export const updateConstantsStategyApi = ({ strategy }: { strategy: string }) =>
  put({
    path: `/wms/constants/strategy`,
    data: { strategy },
  });

export const uploadLogoApi = ({ logo }: { logo: any }) =>
  patch({
    path: `/wms/constants/logo`,
    data: logo,
    isFormData: true,
  });

export const getDynamicDocsApi = () =>
  get({
    path: `/wms/docs`,
  });

export const getDynamicMenuItemsApi = () =>
  get({
    path: `/wms/displays/menu_items`,
  });
