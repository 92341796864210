import * as yup from "yup";
import { validationMessage } from "../../../utils/validation";

export const fieldTitle = {
  description: "Discription",
  assignee_uuid: "Assignee",
  sla: "Duration",
  start_date: "In Progress at",
  due_date: "Due at",
};

export type FieldSchema = keyof typeof fieldTitle;

export const taskLoggingSchema = yup
  .object()
  .shape({
    description: yup.string(),
    linked_object_code: yup.string().nullable(),
    linked_object_type: yup.string().nullable(),
    assignee_uuid: yup
      .string()
      .required(({ path }) =>
        validationMessage.required(fieldTitle[path as FieldSchema])
      ),
    start_at: yup.mixed(),
    due_at: yup.mixed(),
  })
  .required();
