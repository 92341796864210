import { ProgressBar as ProgressBarComponent } from "react-bootstrap";

interface IProgressBarProps {
  value?: number;
  label?: string | React.ReactNode;
  min?: number;
  max?: number;
  visuallyHidden?: boolean;
  striped?: boolean;
  animated?: boolean;
  variant?: "success" | "danger" | "warning" | "info";
}

const ProgressBar = ({
  value = 30,
  label = `${value}%`,
  min = 0,
  max = 100,
  visuallyHidden = false,
  striped = false,
  animated = false,
  variant = "info",
}: IProgressBarProps) => {
  
  return (
    <ProgressBarComponent
      now={value}
      label={label || `${value}%`}
      min={min}
      max={max}
      visuallyHidden={visuallyHidden}
      striped={striped}
      animated={animated}
      variant={variant}
    />
  );
};

export default ProgressBar;
