import { Spinner as SpinnerComponent } from "react-bootstrap";

import { GENERAL_UI_COLOR } from "../../constants/color";

interface ISpinnerProps {
  animation?: "border" | "grow";
  size?: undefined | "sm";
  variant?: GENERAL_UI_COLOR;
}

const Spinner = ({
  animation = "border",
  variant = GENERAL_UI_COLOR.INFO,
  size = undefined,
}: ISpinnerProps) => (
  <SpinnerComponent
    animation={animation}
    variant={variant}
    size={size}
    role="status"
  >
    <span className="visually-hidden">Loading...</span>
  </SpinnerComponent>
);

export default Spinner;
