import { useState } from "react";
import { useParams } from "react-router-dom";

import { getWarehouseLocationsProducts } from "../../apis/paths/warehouse";
import TablePanel from "../../components/panel-table";

import { schema } from "./schema";
import CheckProductHistoriesPopup from "./view-popup-history";

const Component = () => {
  const params = useParams();
  const [isShownModal, setIsShownModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any | null>(null);

  const { uuid, code } = selectedProduct || {};
  const { warehouseId, locationCode } = params || {};

  const handleClickHistory = (product: any) => {
    setSelectedProduct(product);
    setIsShownModal(true);
  };

  return (
    <>
      <TablePanel
        apiPath={getWarehouseLocationsProducts}
        apiParams={{
          code: warehouseId,
          location_code: locationCode,
        }}
        isSearchable={true}
        panelClassName="card-profile-bottom "
        panel={{
          title: locationCode,
        }}
        tableSchema={schema({ onClickHistory: handleClickHistory })}
      />
      {warehouseId && locationCode && code && uuid && (
        <CheckProductHistoriesPopup
          key={uuid}
          params={{
            warehouseId,
            locationCode,
            productCode: code,
            productUuid: uuid,
          }}
          isShowModal={isShownModal}
          handleCloseModal={() => {
            setIsShownModal(false);
          }}
        />
      )}
    </>
  );
};

export default Component;
