import React, { useState } from "react";

import { GENERAL_UI_COLOR } from "../../constants/color";
import { apiCaller } from "../../apis/config";
import { inviteAccountApi } from "../../apis/paths/roles";
import { API_RESPONSE_STATUS } from "../../constants/api";
import alert from "../../utils/alert";
import UiButton from "../../components/ui-button";
import PanelGeneral from "../../components/panel-general/component";

import { IAccounts } from "./types";
import AddItemSearchBox from "./view-add-item-search-box";

const ViewAddNewMember = ({
  accounts,
  onCancel,
  uuid,
  setPageReloadVersion,
}: {
  accounts: IAccounts[];
  onCancel: () => void;
  uuid: string;
  setPageReloadVersion: any;
}) => {
  const [invitedAccount, setInvitedAccount] = useState<any>();

  const formatInvitedAccounts = (list: any[]) => {
    return list?.map((item) => item.value);
  };

  const handleConfirmAddNewMember = async () => {
    const waitAddInviteAccount = await apiCaller({
      api: inviteAccountApi,
      params: { uuid, uuids: formatInvitedAccounts(invitedAccount) },
    });

    if (waitAddInviteAccount.status === API_RESPONSE_STATUS.SUCCESS) {
      setTimeout(() => {
        alert.success("Invite successful!");
      }, 1000);
      setPageReloadVersion((prev: number) => prev + 1);
      return;
    }

    alert.error("Delete was unsuccessful!. Please try again");
  };
  
  return (
    <PanelGeneral
      styles={{ height: 340 }}
      panel={{
        title: "Members",
        actionArea: () => (
          <>
            <UiButton
              className="me-2"
              type={GENERAL_UI_COLOR.LIGHT}
              onClick={onCancel}
              title={"Cancel"}
            />
            <UiButton
              className="me-2"
              type={GENERAL_UI_COLOR.PRIMARY}
              onClick={() => {
                onCancel();
                handleConfirmAddNewMember();
              }}
              title={"Add"}
              disabled={!invitedAccount}
            />
          </>
        ),
      }}
    >
      <AddItemSearchBox
        data={accounts}
        setInvitedItem={setInvitedAccount}
        isAccountItem
      />
    </PanelGeneral>
  );
};

export default ViewAddNewMember;
