import * as R from "../../routings/path";
import { generateGoogleMapLink } from "../../utils/url";
import { IThTable } from "../../components/ui-table/component";
import { TABLE_ACTION } from "../../constants/table-action";
import { GENERAL_STATUS } from "../../constants/status";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { ACTIONABLE_TYPE } from "../../constants/actionable";
import DateTimeTableCell from "../../components/table-cell-date-time";
import StatusTableCell from "../../components/table-cell-status";
import ProductTableCell from "../../components/table-cell-product";
import ActionTableCell from "../../components/table-cell-action";
import UiInput from "../../components/ui-input/component";

import styles from "./styles.module.scss";

export const warehouseSchema = ({   
  selectedWarehouse,
  onSelectWarehouse,
}: {
  selectedWarehouse: any;
  onSelectWarehouse: any;
}): IThTable[] => [
  {
    name: "Warehouse",
    cell: (data: any) => (
      <ProductTableCell
        title={data.name}
        linkTo={generateGoogleMapLink({
          latitude: data.address.latitude,
          longitude: data.address.longitude,
        })}
        linkTarget={"_blank"}
        subIcon={"fa-location-dot"}
        description={data.address.full_address}
        descriptionLine={2}
        maxWidth={150}
      />
    ),
  },
  {
    name: "Storage Type",
    align: "center",
    cell: (data: any) => (
      <StatusTableCell
        status={data.storage_type}
        type={GENERAL_UI_COLOR.SECONDARY}
      />
    ),
  },

  {
    name: "Status",
    align: "center",
    cell: (data: any) => (
      <StatusTableCell
        status={
          data.deleted_at ? GENERAL_STATUS.DEACTIVE : GENERAL_STATUS.ACTIVATE
        }
      />
    ),
  },
  {
    name: "Action",
    align: "right",
    cell: (data: any) => {
      const isExist = selectedWarehouse?.uuid === data.uuid;
      if (isExist) return <div> Added</div>;

      return (
        <ActionTableCell
          customActionableType={ACTIONABLE_TYPE.FULL}
          types={[TABLE_ACTION.CUSTOM]}
          customTitle={"Select"}
          onCustomClick={() => onSelectWarehouse(data)}
        />
      );
    },
  },
];

export const useSaleOrdersSchema = ({
  selectedSaleOrders,
  onSelectSaleOrder,
  onSelectAll,
  currentPaging,
  pagesHaveBeenClickedSelectedAllSaleOrders,
}: {
  selectedSaleOrders: Array<any>;
  onSelectSaleOrder: Function;
  onSelectAll: Function;
  currentPaging: number;
  pagesHaveBeenClickedSelectedAllSaleOrders: number[];
}): IThTable[] => {
  return [
    {
      name: "Number",
      cell: (data: any) => (
        <ProductTableCell
          title={data.number}
          linkTo={`${R.SALE_ORDER_LIST}/${data.uuid}`}
          description={`#${data.channel}`.toUpperCase()}
          maxWidth={100}
        />
      ),
    },
    {
      name: "Contact",
      cell: (data: any) => (
        <ProductTableCell
          subIcon={"fa-phone"}
          linkTo={`tel:${data.receiver_phone}`}
          title={data.receiver_name}
          description={data.receiver_phone}
        />
      ),
    },
    {
      name: "Created",
      align: "center",
      sortTypes: {
        key: "created_at",
        value: ["asc", "desc"],
      },
      cell: (data: any) => <DateTimeTableCell timeStamp={data.created_at} />,
    },
    {
      name: "Status",
      align: "center",
      cell: (data: any) => <StatusTableCell status={data.status} />,
    },
    {
      name: "",
      headingAction: (
        <div className="d-flex justify-content-end gap-2 align-items-center">
          All
          <div>
            <UiInput
              type="checkbox"
              name={"checkbox"}
              containerClassName="mb-0 mt-3"
              className={styles.customInput}
              isDisplayLabel={false}
              checked={pagesHaveBeenClickedSelectedAllSaleOrders?.includes(currentPaging)}
              onClick={() => {
                onSelectAll(currentPaging);
              }}
            />
          </div>
        </div>
      ),
      align: "right",
      cell: (data: any) => {
        const isExist = selectedSaleOrders?.some(
          (saleOrder: any) => saleOrder?.uuid === data?.uuid
        );

        if (isExist) return <div> Added</div>;

        return (
          <ActionTableCell
            types={[TABLE_ACTION.CUSTOM]}
            customActionableType={ACTIONABLE_TYPE.FULL}
            customTitle={"Select"}
            onCustomClick={() => onSelectSaleOrder(data)}
          />
        );
      },
    },
  ];
};

export const selectedSaleOrdersSchema = ({
  onRemoveSaleOrder,
}: {
  onRemoveSaleOrder: Function;
}) => [
  {
    name: "Number",
    cell: (data: any) => (
      <ProductTableCell
        title={data.number}
        description={`#${data.channel}`.toUpperCase()}
        maxWidth={130}
      />
    ),
  },
  {
    name: "Created",
    align: "center",
    sortTypes: {
      key: "created_at",
      value: ["asc", "desc"],
    },
    cell: (data: any) => <DateTimeTableCell timeStamp={data.created_at} />,
  },
  {
    name: "Action",
    align: "right",
    textSize: "xs",
    cell: (data: any) => {
      return (
        <ActionTableCell
          types={[TABLE_ACTION.CUSTOM]}
          customActionableType={ACTIONABLE_TYPE.FULL}
          customTitle={"Remove"}
          onCustomClick={() => onRemoveSaleOrder(data)}
        />
      );
    },
  },
];
