import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  deleleRoleApi,
  getDeleteRoleApi,
  getRoleListApi,
  restoreRoleApi,
  toogleRoleStatusApi,
} from "../../apis/paths/roles";
import { apiCaller, useApiCaller } from "../../apis/config";
import { ROLES_CREATE } from "../../routings/path";
import alert from "../../utils/alert";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { ACTIONABLE_TYPE } from "../../constants/actionable";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { GENERAL_STATUS } from "../../constants/status";
import UiTab from "../../components/ui-tab";
import TablePanel from "../../components/panel-table";
import NotFoundPanel from "../../components/panel-not-found";

import { deletedRoleSchema } from "./deletedRoleSchema";
import { schema } from "./schema";

interface IStatusState {
  [key: string]: GENERAL_STATUS.ACTIVATE | GENERAL_STATUS.DEACTIVE;
}

const Component = () => {
  const navigate = useNavigate();
  const [pageReloadVersion, setPageReloadVersion] = useState(1);
  const [rolesListData, setRolesListData] = useState([]);
  const [currentActiveTab, setCurrentActiveTab] = useState(0);

  const { result: rolesList } = useApiCaller({
    api: getRoleListApi,
    params: DEFAULT_PAGINATION_PARAMS,
    pageReloadVersion,
  });

  useEffect(() => {
    setRolesListData(rolesList?.data?.list);
  }, [rolesList]);

  const resultObj: IStatusState = {};
  rolesListData?.forEach(
    ({
      uuid,
      status,
    }: {
      uuid: string;
      status: GENERAL_STATUS.ACTIVATE | GENERAL_STATUS.DEACTIVE;
    }) => {
      resultObj[uuid] = status;
    }
  );
  const [roleStatus, setRoleStatus] = useState<IStatusState>(resultObj);

  useEffect(() => {
    setRoleStatus(resultObj);
  }, [rolesListData]);

  const handleDeleteRole = async (uuid: string) => {
    const waitDelete = await apiCaller({
      api: deleleRoleApi,
      params: { uuid },
    });

    if (waitDelete.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Delete successful!");

      setPageReloadVersion((prev) => prev + 1);
      return;
    }

    alert.error("Delete was unsuccessful!. Please try again");
  };

  const handleToggleStatus = async (uuid: string) => {
    const waitToggle = await apiCaller({
      api: toogleRoleStatusApi,
      params: { uuid },
    });

    if (waitToggle.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update status successful!");
      setRoleStatus((prevState) => ({
        ...prevState,
        [uuid]:
          prevState[uuid] === GENERAL_STATUS.ACTIVATE
            ? GENERAL_STATUS.DEACTIVE
            : GENERAL_STATUS.ACTIVATE,
      }));
      return;
    }
    alert.error("Change status was not successful!. Please try again");
  };

  const handleToggleUiTab = (newActiveTab: number) => {
    setCurrentActiveTab(newActiveTab);
    setPageReloadVersion((prev) => prev + 1);
  };

  const handleRestore = async (uuid: string) => {
    const waitRestore = await apiCaller({
      api: restoreRoleApi,
      params: { uuid },
    });

    if (waitRestore.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Restore role successful!");

      setPageReloadVersion((prev) => prev + 1);
      return;
    }
    alert.error("Restore role was not successful!. Please try again");
  };

  if (rolesList?.status === "ERROR") return <NotFoundPanel />;

  return currentActiveTab === 0 ? (
    <TablePanel
      apiPath={getRoleListApi}
      pageReloadVersion={pageReloadVersion}
      panel={{
        title: "Roles",
        description: "Permission management system",
        actionableButtons: [
          {
            actionableType: ACTIONABLE_TYPE.NEW,
            title: "New Role",
            onClick: () => navigate(ROLES_CREATE),
          },
        ],

        actionArea: () => (
          <>
            <UiTab
              tabs={["In use", "Deleted"]}
              selected={currentActiveTab}
              onChange={handleToggleUiTab}
            />
          </>
        ),
      }}
      tableSchema={schema({
        onDelete: handleDeleteRole,
        onToggle: handleToggleStatus,
        roleStatus,
      })}
    />
  ) : (
    <TablePanel
      apiPath={getDeleteRoleApi}
      pageReloadVersion={pageReloadVersion}
      panel={{
        title: "Deleted roles",
        description: "Permission management system",
        actionArea: () => (
          <UiTab
            tabs={["In use", "Deleted"]}
            selected={currentActiveTab}
            onChange={handleToggleUiTab}
          />
        ),
      }}
      tableSchema={deletedRoleSchema({
        onRestore: handleRestore,
      })}
    />
  );
};

export default Component;
