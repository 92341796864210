import classNames from "classnames";
import { useState, useEffect } from "react";

import { useApiCaller } from "../../apis/config";
import { getFilterableSaleOrderForPickingListApi } from "../../apis/paths/picking";
import { IDynamicFilter } from "../../constants/dynamic-filter";
import UiInput from "../../components/ui-input";
import ComboBoxSearch from "../ui-combo-box-search/component";

import styles from "./styles.module.scss";

const formatOptionItems = (data: string[]) =>
  data.map((option) => ({
    value: option,
    content: option,
  }));

const Component = ({
  list,
  onApply,
}: {
  list?: IDynamicFilter[];
  onApply?: any;
}) => {
  const [filterValue, setFilterValue] = useState<any>({});

  const [params] = useState<{
    page: number;
    per: number;
  }>({
    page: 1,
    per: 50,
  });

  const { result: filterableFields } = useApiCaller({
    api: getFilterableSaleOrderForPickingListApi,
    params,
  });

  useEffect(() => {
    const initFilterValue: any = {};
    list?.forEach((filter: any) => {
      initFilterValue[filter.key] = {
        ...filter,
        value: "",
      };
    });

    setFilterValue(initFilterValue);
  }, []);

  const handleReset = () => {
    const initFilterValue: any = {};
    list?.forEach((filter: any) => {
      initFilterValue[filter.key] = {
        ...filter,
        value: "",
      };
    });

    setFilterValue(initFilterValue);
    onApply?.([]);
  };

  const handleApply = () => {
    const keys = Object.keys(filterValue);
    const results = keys
      .map((key: string) => {
        if (!filterValue[key]?.value || !filterValue[key]?.value?.length)
          return null;

        const mappingKeyValue = (key: string) => {
          let columnKey;
          switch (key) {
            case "channels":
              columnKey = "channel";
              break;
            case "shipping_services":
              columnKey = "shipping_service";
              break;
            case "shipping_service_names":
              columnKey = "shipping_service_name";
              break;
          }
          return columnKey;
        };

        const keyMatchWithDatabase = mappingKeyValue(key as string);

        return {
          field: FILTER_COMBO_TYPE?.includes(key)
            ? keyMatchWithDatabase
            : filterValue[key].key,
          operator: filterValue[key].operator,
          values: Array.isArray(filterValue[key].value)
            ? filterValue[key].value
            : [filterValue[key].value],
        };
      })
      .filter((result: any) => !!result);

    onApply?.(results);
  };

  const formatComboSearchValue = (selectedItems: any) =>
    selectedItems?.map((selectedItem: any) => selectedItem?.value);

  const FILTER_COMBO_TYPE: string[] =
    filterableFields?.data && Object.keys(filterableFields?.data);

  const renderFilterType = (filterItem: IDynamicFilter) => {
    if (FILTER_COMBO_TYPE?.includes(filterItem?.key as string)) {
      if (!filterItem.key || !filterableFields) return null;

      return (
        <ComboBoxSearch
          optionList={formatOptionItems(
            filterableFields?.data?.[filterItem?.key as string]
          )}
          title={filterItem?.title}
          className={{ label: styles.selectComboBoxLabel }}
          formItem={{ selectedIds: [] }}
          onChange={(selectedValue: any) => {
            setFilterValue({
              ...filterValue,
              [filterItem?.key as string]: {
                ...filterValue[filterItem?.key as string],
                value: formatComboSearchValue(selectedValue),
              },
            });
          }}
        />
      );
    }

    return (
      <UiInput
        size={"small"}
        isSmallLabel
        subLabel={<span>{filterItem.operatorAlias}</span>}
        title={filterItem.title}
        name={filterItem.key}
        value={filterValue[filterItem?.key]?.value}
        containerClassName={styles.input}
        onChange={(event) => {
          setFilterValue({
            ...filterValue,
            [filterItem.key]: {
              ...filterValue[filterItem.key],
              value: event?.target?.value,
            },
          });
        }}
      />
    );
  };

  if (!FILTER_COMBO_TYPE) return null;

  const groupComboSelectBox = list?.filter((field) =>
    FILTER_COMBO_TYPE?.includes(field?.key as string)
  );

  const groupNormalInput = list?.filter(
    (field) => !FILTER_COMBO_TYPE?.includes(field?.key as string)
  );

  return (
    <div className={styles.dynamicFilter}>
      <div className={styles.dropdownHeading}>
        <div className={styles.title}>Dynamic Filter</div>
        <div className={styles.action}>
          <div
            onClick={handleReset}
            className={`${styles.actionItem} ${styles.reset}`}
          >
            Reset
          </div>
          <div
            onClick={handleApply}
            className={`${styles.actionItem} ${styles.apply}`}
          >
            Apply
          </div>
        </div>
      </div>

      <hr className={`horizontal dark mt-2 ${styles.hr}`} />

      <div className={classNames(styles.list, "row")}>
        {groupNormalInput &&
          !!filterableFields &&
          groupNormalInput?.map((filterItem) => (
            <div className={classNames("col-6")} key={filterItem?.key}>
              {renderFilterType(filterItem)}
            </div>
          ))}
      </div>

      <div className={classNames(styles.list, "row")}>
        {groupComboSelectBox &&
          !!filterableFields &&
          groupComboSelectBox?.map((filterItem) => (
            <div className={styles.item} key={filterItem?.key}>
              {renderFilterType(filterItem)}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Component;
