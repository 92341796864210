import React from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { ISidebarContent } from "./constants";

const ContentSidebar = ({
  sidebarContent,
  paramIds,
}: {
  sidebarContent: any;
  paramIds: string[];
}) => {
  const location = useLocation();

  const params = useParams();

  const mappedParamIds = paramIds?.map((paramId: string) => {
    return params[paramId];
  });

  return (
    <div className="card card-profile-bottom">
      <div className="card position-sticky top-1">
        <ul className="nav flex-column bg-white border-radius-lg p-3">
          {sidebarContent(mappedParamIds).map(
            (item: ISidebarContent, index: number) => {
              return (
                <li className="nav-item mb-1" key={index}>
                  <NavLink
                    className={classNames(
                      "nav-link text-body d-flex align-items-center px-0",
                      (item.similarActiveHref?.includes(location.pathname) ||
                        location?.pathname === item?.href) &&
                        styles.active
                    )}
                    data-scroll=""
                    to={item.href}
                  >
                    <div className="p-1 w-20 d-flex justify-content-center align-items-center">
                      {item.icon}
                    </div>
                    <span className="text-sm w-100">{item.content}</span>
                  </NavLink>
                </li>
              );
            }
          )}
        </ul>
      </div>
    </div>
  );
};

export default ContentSidebar;
