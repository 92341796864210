import { useState } from "react";
import { useParams } from "react-router-dom";

import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import UiContentHeading from "../../components/ui-content-heading";
import TableCellStatus from "../../components/table-cell-status";
import StatusTableCell from "../../components/table-cell-status";
import HeaderInfoPanel from "../../components/panel-header-info";
import UiLoadingPanel from "../../components/ui-loading-panel";
import TablePanel from "../../components/panel-table";
import NotFoundPanel from "../../components/panel-not-found";
import UiContentRow from "../../components/ui-content-row";
import GeneralPanel from "../../components/panel-general";
import UiButton from "../../components/ui-button";

import { GENERAL_UI_SIZE } from "../../constants/size";
import { longHyphen } from "../../constants/character";

import {
  getDispatchDetailApi,
  printDispatchApi,
} from "../../apis/paths/dispatch";
import { apiCaller, useApiCaller } from "../../apis/config";
import { saleOredrSchema } from "./schema";

const Component = () => {
  const [pageReloadVersion] = useState(1);
  const { dipatchId: uuid } = useParams();
  const [params] = useState({ uuid });

  const { result, loading } = useApiCaller({
    api: getDispatchDetailApi,
    params: params,
  });

  const dispatchDetail = result?.data;

  const handlePrint = async () => {
    const waitPrint = await apiCaller({
      api: printDispatchApi,
      params: { uuid },
    });

    if (waitPrint?.status === "SUCCESS") {
      window.open(waitPrint?.data?.url, "_blank", "height=500,width=600");
    }
  };

  if (loading) return <UiLoadingPanel />;

  if (result?.status === "ERROR") return <NotFoundPanel />;

  return (
    <>
      <HeaderInfoPanel
        title={`#${dispatchDetail?.location?.code}`}
        className="mb-4"
        infoArea={() => (
          <TableCellStatus
            className="me-2"
            status={dispatchDetail?.status}
            size={GENERAL_UI_SIZE.SMALL}
          />
        )}
        actionArea={() => (
          <>
            {dispatchDetail?.instance_actionables?.print?.actionable && (
              <UiButton onClick={handlePrint} title={"Print"} />
            )}
          </>
        )}
      />
      <TwoColumnGridLayout
        className={{ container: "mt-4" }}
        leftCol={
          <GeneralPanel className="pt-4">
            <UiContentHeading>Location</UiContentHeading>
            <UiContentRow label="Location ID">
              {dispatchDetail?.location?.id}
            </UiContentRow>
            <UiContentRow label="Location code">
              {dispatchDetail?.location?.code}
            </UiContentRow>
            <UiContentRow label="Location type">
              <StatusTableCell
                status={dispatchDetail?.location?.location_type}
              />
            </UiContentRow>
            <UiContentRow label="Width x Height">
              {dispatchDetail?.location?.width || longHyphen} x{" "}
              {dispatchDetail?.location?.height || longHyphen}
            </UiContentRow>
            <UiContentRow label="Length">
              {dispatchDetail?.location?.length || longHyphen}
            </UiContentRow>
            <UiContentRow label="Zone">
              {dispatchDetail?.location?.zone || longHyphen}
            </UiContentRow>
          </GeneralPanel>
        }
        rightCol={
          <GeneralPanel className="pt-4">
            <UiContentHeading>Warehouse</UiContentHeading>
            <UiContentRow label="Code">
              {dispatchDetail?.warehouse?.code}
            </UiContentRow>
            <UiContentRow label="Name">
              {dispatchDetail?.warehouse?.name}
            </UiContentRow>
            <UiContentRow label="Storage type">
              <StatusTableCell
                status={dispatchDetail?.warehouse?.storage_type}
              />
            </UiContentRow>
            <UiContentRow label="Contact name">
              {" "}
              {dispatchDetail?.warehouse?.contact_name}
            </UiContentRow>
            <UiContentRow label="Contact phone">
              {dispatchDetail?.warehouse?.contact_phone}
            </UiContentRow>
            <UiContentRow label="Address">
              {dispatchDetail?.warehouse?.address?.full_address}
            </UiContentRow>
          </GeneralPanel>
        }
      />
      <TablePanel
        apiPath={getDispatchDetailApi}
        apiParams={{ uuid }}
        pageReloadVersion={pageReloadVersion}
        tableSchema={saleOredrSchema}
        dataTransformation={(result: any) =>
          result && {
            data: result?.data?.sale_orders,
          }
        }
        tableClassName={{ container1: "pt-3" }}
      />
    </>
  );
};

export default Component;
