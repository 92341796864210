import React, { useRef, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import classNames from "classnames";

import Typography from "../ui-typography/component";
import styles from "../ui-doughnut-chart/styles.module.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

interface IDataset {
  label: string;
  data: number[];
  backgroundColor: string[];
  borderColor: string[];
  borderWidth?: number;
  circumference?: number;
  cutout?: number;
  spacing?: number;
}

interface IHoverState {
  datasetIndex: number;
  element: any;
  index: number;
}
interface ILegends {
  align?: "start" | "center" | "end";
  position?: "bottom" | "center" | "top" | "chartArea" | "left" | "right";
  labels?: { borderRadius?: number; padding?: number };
}

interface IDoughnutProps {
  labels: string[];
  datasets: IDataset[];
}

const DoughnutChart = ({
  data,
  width = 300,
  height = 300,
  legend = {
    align: "center",
    position: "bottom",
    labels: { borderRadius: 50, padding: 10 },
  },
  pluggins,
  onHover = () => {},
  onClick = () => {},
  className = {
    centerText: "",
  },
}: {
  data: IDoughnutProps;
  legend?: ILegends;
  width?: number | '100%';
  height?: number;
  pluggins?: any[];
  onHover?: (element: any) => void;
  onClick?: (elements: any) => void;
  className?: {
    centerText?: string;
  };
  tooltip?: {
    enabled: boolean;
    yAlign?: "start" | "center" | "end";
  };
}) => {
  const previousHoverItemIndex = useRef<number | null>(null);
  const [hoverState, setHoverState] = useState<null | IHoverState>(null);
  const [hasHoveredToItem, setHasHoveredToItem] = useState(false);
  const [hoveredTextStyle, setHoveredTextStyle] = useState({});

  const currentDataSetIndex = hoverState?.datasetIndex || 0;
  const currentHoverSectionIndexInDataSet = hoverState?.index || 0;

  const datasets = data?.datasets?.[currentDataSetIndex];
  const hoveringLabel = data?.labels?.[currentHoverSectionIndexInDataSet];
  const hoveringValue = datasets?.data?.[currentHoverSectionIndexInDataSet] || 0;
  const hoveringTextColor =
    datasets?.borderColor?.[currentHoverSectionIndexInDataSet];

  const totalDataSetsValue = datasets?.data?.reduce(
    (acc, currentValue) => acc + currentValue,
    0
  ) || 0;

  const percentageOfHoveringItemPerTotal = Math.round(
    (hoveringValue / totalDataSetsValue) * 100
  );

  return (
    <div className="w-100 h-100 position-relative">
      <div
        className={classNames(
          "position-absolute text-center",
          styles.centerText,
          className.centerText
        )}
        style={hoveredTextStyle}
      >
        <Typography fontSize="fs-2" fontWeight="fw-bolder">
          {`${percentageOfHoveringItemPerTotal || 0}%`}
        </Typography>
        <Typography
          fontSize="fs-5"
          fontWeight="fw-bold"
          textColor={hoveringTextColor}
        >
          {hoveringLabel}
        </Typography>
        <Typography fontSize="fs-5" fontWeight="fw-bold">
          {`${hoveringValue}/${totalDataSetsValue} `}
        </Typography>
      </div>
      <Doughnut
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend,
            tooltip: { enabled: false, yAlign: "bottom" },
          },
          animation: {
            onComplete(options) {
              setHoveredTextStyle({
                width: 100,
                height: 100,
                left: options.chart.chartArea.width / 2 - 50,
              });
            },
          },
          onResize(chart, size) {
            if (chart.chartArea) {
              setHoveredTextStyle({
                width: 100,
                height: 100,
                left: chart.chartArea.width / 2 - 50,
              });
            }
          },
          onHover(event, elements, chart) {
            const currentHoverItemIndex = elements?.[0]?.index;
            if (currentHoverItemIndex !== previousHoverItemIndex.current) {
              if (elements.length === 0 && hasHoveredToItem) return;
              onHover && onHover(elements);
              setHoverState(elements?.[0]);
              previousHoverItemIndex.current = currentHoverItemIndex;
              setHasHoveredToItem(true);
              setHoveredTextStyle({
                width: 100,
                height: 100,
                left: chart.chartArea.width / 2 - 50,
              });
            }
          },
          onClick(event, elements, chart) {
            onClick && onClick(elements);
          }
        }}
        style={{
          width,
          height,
        }}
        plugins={pluggins}
      />
    </div>
  );
};

export default DoughnutChart;
