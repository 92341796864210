import UiInput from "../../components/ui-input";
import PanelGeneral from "../../components/panel-general/component";

import { formSchema } from "./formSchema";
import { detectRequiredlField } from "../../utils/validation";
import { IAccountUser } from "../../types/account";
import {
  calculateRemainingTime,
  convertYYYYMMDDToUnixTime,
} from "../../utils/date-time";
import DatePicker from "../../components/ui-date-picker/component";
import { useEffect, useState } from "react";
import Badge from "../../components/ui-badge/component";
import { GENERAL_UI_COLOR } from "../../constants/color";

const EditAccountForm = ({
  register,
  control,
  errors,
  data,
  dirtyFields,
  watch,
}: {
  register: any;
  control: any;
  errors: any;
  data: IAccountUser;
  dirtyFields?: any;
  watch?: any;
}) => {
  const { expired_at, name, username } = data || {};
  const [expiredDay, setExpiredDay] = useState(expired_at);

  const watchedDayValue = watch("expired_at");

  useEffect(() => {
    setExpiredDay(expired_at);
  }, [expired_at]);

  useEffect(() => {
    setExpiredDay(
      typeof watchedDayValue === "string"
        ? convertYYYYMMDDToUnixTime(watchedDayValue)
        : watchedDayValue
    );
  }, [watchedDayValue]);

  return (
    <PanelGeneral
      panel={{
        title: "Account Info",
      }}
    >
      <UiInput
        className="dataTable-input"
        name={"name"}
        title={"Name"}
        required={detectRequiredlField(formSchema, "name")}
        placeholder={name}
        isDisplayLabel={true}
        register={register}
        disabled
        errorMessage={errors?.name?.message}
      />
      <UiInput
        className="dataTable-input"
        name={"username"}
        required={detectRequiredlField(formSchema, "description")}
        title={"Username"}
        isDisplayLabel={true}
        register={register}
        placeholder={username}
        disabled
        errorMessage={errors?.description?.message}
      />
      <div className="d-flex gap-2">
        <div className="col-6">
          <DatePicker
            className="dataTable-input"
            name={"expired_at"}
            required={detectRequiredlField(formSchema, "expired_at")}
            title={"Expried At"}
            isDisplayLabel={true}
            control={control}
            disabled
            errorMessage={errors?.expired_at?.message}
            dirtyFields={dirtyFields}
          />
        </div>
        <div className="text-end col-6 pe-2">
          <label className="">Expect Remaining: </label>
          <div className="text-danger d-flex flex-start mt-3">
            <Badge
              content={calculateRemainingTime(expiredDay as number)}
              type={GENERAL_UI_COLOR.WARNING}
            />
          </div>
        </div>
      </div>
    </PanelGeneral>
  );
};

export default EditAccountForm;
