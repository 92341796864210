import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { GENERAL_STATUS } from "../../constants/status";
import { generateUrlParams } from "../../utils/url";
import { del, get, patch, post, put } from "../config";

interface IGetAccountListApi {
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
}
interface ICreateAccountApi {
  name: string;
  description?: string;
  username: string;
  status: GENERAL_STATUS.ACTIVATE | GENERAL_STATUS.DEACTIVE;
  password: string;
  expired_at: number;
}

export interface IUpdateAccountApi {
  uuid: string;
  name: string;
  username: string;
  status: GENERAL_STATUS.ACTIVATE | GENERAL_STATUS.DEACTIVE;
  expired_at: number;
}

export interface IResetPasswordApi {
  uuid: string;
  password: string;
  password_confirmation: string;
}

export const getAccountListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
}: IGetAccountListApi) =>
  get({
    path: `/wms/accounts${generateUrlParams({ page, per, keyword, sort })}`,
  });
  
export const getDeletedAcccountListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
}: IGetAccountListApi) =>
  get({
    path: `/wms/accounts/deleted${generateUrlParams({ page, per, keyword, sort })}`,
  });

export const deleleAccountApi = ({ uuid }: { uuid: string }) =>
  del({ path: `/wms/accounts/${uuid}` });

export const getAccountDetailApi = ({ uuid }: { uuid: string }) =>
  get({ path: `/wms/accounts/${uuid}` });

export const getAccountDevicesApi = ({ uuid }: { uuid: string }) =>
  get({ path: `/wms/accounts/${uuid}/devices` });

export const toogleAccountStatusApi = ({ uuid }: { uuid: string }) =>
  patch({ path: `/wms/accounts/${uuid}/status` });

  export const restoreAccountApi = ({ uuid }: {uuid:string}) =>
  patch({ path: `/wms/accounts/${uuid}/restore` });

export const toogleDeviceStatusApi = ({
  uuid,
  device_uuid,
}: {
  uuid: string;
  device_uuid: string;
}) => patch({ path: `/wms/accounts/${uuid}/devices/${device_uuid}/status` });

export const createAccountApi = ({
  name,
  username,
  status,
  password,
  expired_at,
}: ICreateAccountApi) =>
  post({
    path: `/wms/accounts`,
    data: {
      name,
      username,
      status,
      password,
      expired_at,
    },
  });

export const updateAccountApi = ({
  uuid,
  name,
  status,
  username,
  expired_at,
}: IUpdateAccountApi) =>
  put({
    path: `/wms/accounts/${uuid}`,
    data: {
      name,
      status,
      username,
      expired_at,
    },
  });

export const resetPasswordApi = ({
  uuid,
  password,
  password_confirmation,
}: IResetPasswordApi) => {
  return patch({
    path: `/wms/accounts/${uuid}/reset_password`,
    data: {
      password,
      password_confirmation,
    },
  });
};
