import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

import ImageUploading, { ImageListType } from "react-images-uploading";
import Typography from "../ui-typography/component";
import UiButton from "../ui-button/component";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { GENERAL_UI_COLOR } from "../../constants/color";

interface IImageUploader {
  isDisplayLabel?: boolean;
  required?: boolean;
  title?: string;
  isDisplayRemoveAll?: boolean;
  maxImageNumber?: number;
  setValue: any;
  name: string;
  errorMessage?: string;
  trigger?: any;
}
const ImageUploader = ({
  isDisplayLabel = true,
  required = false,
  title = "",
  isDisplayRemoveAll = true,
  maxImageNumber = 1,
  name,
  setValue,
  errorMessage,
  trigger,
}: IImageUploader) => {
  const [images, setImages] = React.useState([]);

  const onChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    setImages(imageList as never[]);
    setValue(name, imageList);
    trigger && trigger(name);
  };

  return (
    <div className="">
      {isDisplayLabel && <label data-required={required}>{title}</label>}
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxImageNumber}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => {
          return (
            <div className={classNames(styles.fileUploadContainer, "")}>
              <div
                onClick={onImageUpload}
                {...dragProps}
                className={classNames(
                  styles.dropZone,
                  isDragging && styles.isDragging,
                  "w-100 mb-2"
                )}
              >
                <div>
                  <i className="fa-solid fa-images fs-3"></i>
                </div>
                <div className="text-muted">Drag & Drop file or Click here</div>
                <div className={styles.uploadHint}>
                  <div className={styles.uploadHintItem}>
                    <strong className="me-1">Supports:</strong>
                    <span>JPG, GIF, PNG</span>
                  </div>
                  <div className={styles.uploadHintItem}>
                    <strong className="me-1">Max size:</strong>
                    <span>3Mb</span>
                  </div>
                  <div className={styles.uploadHintItem}>
                    <strong className="me-1">Image size:</strong>
                    <span>600x240px</span>
                  </div>
                </div>
              </div>
              {isDisplayRemoveAll && (
                <UiButton
                  onClick={onImageRemoveAll}
                  size={GENERAL_UI_SIZE.SMALL}
                  type={GENERAL_UI_COLOR.LIGHT}
                  title={"Remove all images"}
                  className="mb-2"
                />
              )}
              {imageList.map((image, index) => {
                return (
                  <div
                    key={index}
                    className={classNames(
                      styles.imageItem,
                      styles.actionGroup,
                      "d-flex w-100 mb-2"
                    )}
                  >
                    <img src={image.dataURL} alt="" width="100" />
                    <Typography
                      customFontsize={13}
                      className="ms-2 flex-grow-1"
                    >
                      {image?.file?.name}
                    </Typography>
                    <div
                      className={classNames(
                        "ms-2 d-flex flex-column justify-content-between"
                      )}
                    >
                      <div
                        onClick={() => onImageUpdate(index)}
                        className={classNames(
                          "cursor-pointer",
                          styles.actionItem
                        )}
                      >
                        <i className="fa-solid fa-pen opacity-6 fa-sm"></i>
                      </div>
                      <div
                        onClick={() => onImageRemove(index)}
                        className={classNames(
                          "cursor-pointer",
                          styles.actionItem
                        )}
                      >
                        <i className="fa-solid fa-trash opacity-6 fa-sm"></i>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }}
      </ImageUploading>

      {errorMessage && errorMessage.length && (
        <label className="mb-0" style={{ color: "#f5365c" }}>
          {errorMessage}
        </label>
      )}
    </div>
  );
};

export default ImageUploader;
