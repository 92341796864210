import React, { useEffect, useState } from "react";
import classNames from "classnames";

import SelectDropdown from "../../components/ui-select-dropdown/component";
import GridLayoutTwoColumn from "../../components/grid-layout-two-column/component";

import styles from "./style.module.scss";
import InputNote from "./input-note";
import DynamicTplSelectDropdown from "./view-dynamic-tpl-select-dropdown";
import { LINK_TYPES, LOCATIONS } from "../tpl-routes-edit/constants";
import { ILinkForm } from "../../types/tpl";

const EditTplFormItem = ({
  register,
  setValue,
  errors,
  keyIndex,
  data,
  formItem,
}: {
  register: any;
  errors: any;
  data: any;
  setValue?: any;
  keyIndex: number | string;
  formItem?: any;
}) => {
  const [selectedLinkType, setSelectedLinkType] = useState<string | null>(
    formItem?.linked_type || "Channel"
  );

  useEffect(() => {
    if (formItem?.linked_type) {
      setSelectedLinkType(formItem?.linked_type);
      return;
    } else if (formItem?.linked_object_type) {
      setSelectedLinkType(formItem?.linked_object_type);
    }
  }, [formItem]);

  const handleChange = (value: any) => {
    setSelectedLinkType(value);
  };

  useEffect(() => {
    if (formItem?.linked_type) {
      const formatLinkedValues = (formItem: ILinkForm) =>
        formItem?.linked_values?.map((form: any) => ({
          id: keyIndex,
          note: form,
        }));
      setValue(`links[${keyIndex}].linked_type`, formItem?.linked_type);
      setValue(
        `links[${keyIndex}].linked_values`,
        formatLinkedValues(formItem)
      );
    } else if (formItem?.linked_object_type) {
      setValue(`links[${keyIndex}].linked_type`, formItem?.linked_object_type);
      setValue(
        `links[${keyIndex}].linked_values`,
        formItem?.linked_object_uuids
      );
    }
  }, [formItem]);

  if (!data) return null;

  return (
    <div
      key={keyIndex}
      className={classNames(
        "d-flex justify-content-center align-items-center mb-4",
        styles.formRoutingItem
      )}
    >
      <GridLayoutTwoColumn
        leftCol={
          <>
            <SelectDropdown
              name={`links[${keyIndex}].linked_type`}
              title="Scope Type"
              isDisplayLabel
              disabled
              register={register}
              setValue={setValue}
              optionList={LOCATIONS}
              errorMessage={errors?.linked_type?.message}
              onChange={handleChange}
            />
          </>
        }
        rightCol={
          LINK_TYPES.includes(selectedLinkType || ("Channel" as string)) ? (
            <div>
              <label>Values</label>
              <InputNote
                formItem={formItem}
                setValue={setValue}
                keyIndex={keyIndex as string}
              />
            </div>
          ) : (
            <DynamicTplSelectDropdown
              name={`links[${keyIndex}].linked_values`}
              data={data}
              formItem={formItem}
              selectedLinkType={selectedLinkType as string}
              setValue={setValue}
            />
          )
        }
      />
    </div>
  );
};

export default EditTplFormItem;
