import {
  ACTIONABLE_TYPE,
  ACTIONABLE_ACTIONS,
} from "../../constants/actionable";
import UiButton from "../../components/ui-button";
import { UiButtonPropsType } from "../../components/ui-button/component";

interface IComponent extends UiButtonPropsType {
  className?: string;
  actionableType: ACTIONABLE_TYPE;
  actions: ACTIONABLE_ACTIONS;
  rest?: any;
}

const Component = ({
  className,
  actionableType,
  actions,
  ...rest
}: IComponent) => {
  const isEnabled = actions[actionableType]?.actionable;
  return <UiButton className={className} disabled={!isEnabled} {...rest} />;
};

export default Component;
