import { calculateRemainingTime, formatDateTime } from "../../utils/date-time";
import { longHyphen } from "../../constants/character";
import Typography from "../ui-typography/component";

import styles from "./styles.module.scss";
import classNames from "classnames";

interface IComponent {
  className?: {
    tooltip?: string;
  };
  timeStamp: number;
  align?: "start" | "center" | "end";
}

const TableCellOverdueDateTime = ({
  className = {
    tooltip: "",
  },
  timeStamp,
  align = "center",
}: IComponent) => {
  if (!timeStamp) return <div className={styles.empty}>{longHyphen}</div>;

  return (
    <div className={classNames(`d-flex justify-content-${align}`)}>
      <div className={classNames(styles.container)}>
        <div
          className="px-2 py-1 rounded"
          style={{ backgroundColor: "#ffebe6", width: "fit-content" }}
        >
          <Typography
            className="text-end"
            textColor="#BF2600"
            fontWeight="fw-normal"
          >
            {calculateRemainingTime(Date.now() / 1000, timeStamp, true)}
          </Typography>
        </div>

        <div
          className={classNames(styles.tooltip, className.tooltip)}
        >{`Due At: ${formatDateTime(timeStamp, "lll")}`}</div>
      </div>
    </div>
  );
};

export default TableCellOverdueDateTime;
