import { getWarehouseListApi } from "../../../apis/paths/warehouse";
import { fromWarehouseSchema } from "../schema/warehouseSchema";

import styles from "../styles.module.scss";
import TablePanel from "../../../components/panel-table";
import TwoColumnGridLayout from "../../../components/grid-layout-two-column";
import SelectedWarehouse from "../view-selected-warehouse";

interface IViewStep1 {
  onSelectWarehouse: (params: any) => void;
  selectedFromWarehouse: any;
}

const ViewStep1 = ({
  onSelectWarehouse,
  selectedFromWarehouse,
}: IViewStep1) => {
  return (
    <TwoColumnGridLayout
      widthDivision={[6, 6]}
      leftCol={
        <TablePanel
          apiPath={getWarehouseListApi}
          panelClassName={"mt-0"}
          type={"minimal"}
          tableSchema={fromWarehouseSchema({
            onSelectWarehouse,
            selectedWarehouse: selectedFromWarehouse,
          })}
        />
      }
      rightCol={
        <div>
          <div className={styles.selectWarehouse}>
            <SelectedWarehouse warehouse={selectedFromWarehouse} />
          </div>
        </div>
      }
    />
  );
};

export default ViewStep1;
