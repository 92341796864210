import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import * as R from "../../routings/path";
import { formatNumber } from "../../utils/format";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { formatDateTime } from "../../utils/date-time";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { extractDynamicAddressFields } from "../../utils/address";

import {
  changeSaleOrderPriorityToProcessApi,
  getSaleOrderDetailApi,
  getSaleOrderTrackingLink,
} from "../../apis/paths/sale-order";
import { apiCaller, useApiCaller } from "../../apis/config";
import PanelLabelController from "../../components/ui-panel-label-controller/component";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import HeaderInfoPanel from "../../components/panel-header-info";
import TableCellStatus from "../../components/table-cell-status";
import UiLoadingPanel from "../../components/ui-loading-panel";
import GeneralHeading from "../../components/ui-general-heading/component";
import TableDataPanel from "../../components/panel-table-data";
import NotFoundPanel from "../../components/panel-not-found";
import UiContentRow from "../../components/ui-content-row/component";
import GeneralPanel from "../../components/panel-general";
import UiCopyBadge from "../../components/ui-copy-badge/component";
import UiTimeline from "../../components/ui-timeline";
import UiButton from "../../components/ui-button";
import PanelNote from "../../components/panel-note/component";
import alert from "../../utils/alert";
import PanelAttachment from "../../components/panel-attachment/component";

import { productSchema } from "./schema";
import styles from "./styles.module.scss";
import AddressRow from "../../components/ui-address-row/component";
import RadioComboBox from "../../components/ui-radio-combo-box/component";
import UiContentHeading from "./view-section-heading";
import QuickActionPopupConfirmation from "../../components/popup-quick-aciton-confirmation/component";

const PRIORITY_STATUS = [
  {
    value: "yes",
    content: "Yes",
  },
  {
    value: "no",
    content: "No",
  },
];

const Component = () => {
  const navigate = useNavigate();

  const [pageReloadVersion, setPageReloadVersion] = useState(0);
  const [isShowModal, setIsShowModal] = useState(false);

  const { saleOrderId: uuid } = useParams();
  const [params, setParams] = useState({ uuid });

  const { control, watch } = useForm();

  const { result, loading } = useApiCaller({
    api: getSaleOrderDetailApi,
    params,
    pageReloadVersion,
  });

  const { result: trackingLink } = useApiCaller({
    api: getSaleOrderTrackingLink,
    params,
  });

  const saleOrderDetail = result?.data;

  useEffect(() => {
    setParams({ uuid });
  }, [uuid]);

  const handleChangePriorityToProcess = async () => {
    const fomatedBoolean = watch("priority") === "yes" ? true : false;
    const waitedChangePriority = await apiCaller({
      api: changeSaleOrderPriorityToProcessApi,
      params: { uuid, highest: fomatedBoolean },
    });

    if (waitedChangePriority.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Change priority successful!");

      setPageReloadVersion((prev: any) => prev + 1);
      return;
    }

    alert.error("Change priority was unsuccessful!. Please try again");
  };

  if (loading) return <UiLoadingPanel />;

  if (result?.status === "ERROR") return <NotFoundPanel />;

  const { detail, street, ward } = extractDynamicAddressFields(
    saleOrderDetail?.address?.full_address
  );

  return (
    <>
      <QuickActionPopupConfirmation
        content={"Do you want to prioritize this sale order to highest ?"}
        isShowModal={isShowModal}
        confirmationButton="Confirm"
        textColor="#344767"
        variant={GENERAL_UI_COLOR.DARK}
        handleCloseModal={() => {
          setIsShowModal(false);
        }}
        onConfirm={handleChangePriorityToProcess}
      >
        <div className="d-flex justify-content-center">
          <RadioComboBox
            control={control}
            options={PRIORITY_STATUS}
            name="priority"
          />
        </div>
      </QuickActionPopupConfirmation>

      <HeaderInfoPanel
        title={`#${saleOrderDetail?.number}`}
        infoArea={() => (
          <div className={styles.headerInfoArea}>
            <TableCellStatus
              className="me-2"
              status={saleOrderDetail?.status}
              size={GENERAL_UI_SIZE.SMALL}
            />
          </div>
        )}
        actionArea={() => (
          <>
            <UiCopyBadge
              title="Tracking Link"
              tooltipContent="Copied"
              className={{ container: "me-3" }}
              onTitleClick={() => {
                window.open(trackingLink?.data?.url, "_blank");
              }}
              urlForCopy={trackingLink?.data?.url}
            />
            {(saleOrderDetail?.status === "pending" ||
              saleOrderDetail?.status === "confirmed") && (
              <UiButton
                onClick={() => {
                  setIsShowModal(true);
                }}
                type={GENERAL_UI_COLOR.DARK}
                title={"Prioritize"}
                className="me-3"
              />
            )}

            <UiButton
              onClick={() =>
                navigate(`${R.SALE_ORDERS_LIST}/${uuid}/audit-change`)
              }
              type={GENERAL_UI_COLOR.INFO}
              title={"Histories"}
              className="me-3"
            />

            <UiButton
              onClick={() => navigate(`${R.SALE_ORDERS_LIST}/${uuid}/edit`)}
              title={"Edit"}
            />
          </>
        )}
      />
      <GeneralPanel className="pt-4" containerClassName="mt-4">
        <TwoColumnGridLayout
          widthDivision={[5, 7]}
          isDisplayPartition
          gutter={5}
          leftCol={
            <>
              <UiContentHeading>Timeline</UiContentHeading>
              <UiTimeline
                displayLimit={3}
                list={saleOrderDetail?.state_activities?.map(
                  (activity: any) => ({
                    icon: "gear",
                    title: activity?.state_value,
                    subTitle: activity?.account?.name
                      ? `by ${activity?.account?.name}`
                      : `by ${activity?.comment}`,
                    description: formatDateTime(
                      activity?.changed_at,
                      "HH:mm:ss DD-MM-YYYY"
                    ),
                  })
                )}
              />
              {uuid && (
                <PanelNote
                  isDisplayHeading
                  className={{
                    heading: "mb-0 pb-0 mt-4",
                    card: styles.customPanel,
                    cardBody: styles.customCardBody,
                    container1: styles.customContainer,
                    editArea: "mt-0",
                  }}
                  notable_type="SaleOrder"
                  notable_uuid={uuid as string}
                  setNoteQuantity={() => {}}
                />
              )}
              {uuid && (
                <PanelAttachment
                  isDisplayHeading
                  className={{
                    heading: "mb-0 pb-0 mt-4",
                    card: styles.customPanel,
                    cardBody: styles.customCardBody,
                    container1: styles.customContainer,
                    editArea: "mt-0",
                  }}
                  attachable_type="SaleOrder"
                  attachable_uuid={uuid as string}
                  setAttachmentQuantity={() => {}}
                />
              )}
            </>
          }
          rightCol={
            <>
              <UiContentHeading>Order Info</UiContentHeading>
              <div className="mb-4">
                <UiContentRow
                  label={"Order Number:"}
                  linkTo={`${R.SALE_ORDER_LIST}/${saleOrderDetail?.uuid}`}
                >
                  {saleOrderDetail?.number}
                </UiContentRow>

                <UiContentRow label={"Shipping Service:"}>
                  {saleOrderDetail?.shipping_service}
                </UiContentRow>

                <UiContentRow label={"Shiping Service Name:"}>
                  {saleOrderDetail?.shipping_service_name}
                </UiContentRow>

                {!!saleOrderDetail?.shipper_requested_at && (
                  <UiContentRow label={"Shipper Requested At:"}>
                    {formatDateTime(
                      saleOrderDetail?.shipper_requested_at,
                      "HH:mm YYYY-MM-DD"
                    )}
                  </UiContentRow>
                )}

                <UiContentRow label={"Tracking Code:"}>
                  {saleOrderDetail?.tracking_code}
                </UiContentRow>

                <UiContentRow label={"Reference Number:"}>
                  {saleOrderDetail?.reference_number}
                </UiContentRow>

                <UiContentRow label={"Created at:"}>
                  {formatDateTime(saleOrderDetail?.created_at)}
                </UiContentRow>

                <UiContentRow label={"Warehouse:"}>
                  {saleOrderDetail?.warehouse_name}
                </UiContentRow>

                <UiContentRow label={"Channel:"}>
                  {saleOrderDetail?.channel}
                </UiContentRow>

                <UiContentRow label={"COD:"}>
                  {formatNumber(saleOrderDetail?.cod)}
                </UiContentRow>

                <UiContentRow label={"Value:"}>
                  {formatNumber(saleOrderDetail?.value)}
                </UiContentRow>

                <UiContentRow label={"Note:"}>
                  {saleOrderDetail?.note}
                </UiContentRow>
              </div>
              <UiContentHeading>Receiver Info</UiContentHeading>
              <div className="mb-4">
                <UiContentRow label={"Receiver name:"}>
                  {saleOrderDetail?.receiver_name}
                </UiContentRow>
                <UiContentRow label={"Phone Number:"}>
                  {saleOrderDetail?.receiver_phone}
                </UiContentRow>
                <UiContentRow label={"Address:"}>
                  <AddressRow
                    mainAddress={ward && ` ${ward}`}
                    detailAddress={street && detail && `${detail}, ${street}`}
                  />
                </UiContentRow>
              </div>
              <div>
                <GeneralHeading className="mb-2 ">Labels</GeneralHeading>
                <PanelLabelController
                  isEditable={false}
                  linkedObjectType="SaleOrder"
                  uniqueUuid={uuid as string}
                />
              </div>
            </>
          }
        />
      </GeneralPanel>
      <div className="pt-4">
        <TableDataPanel
          data={saleOrderDetail?.products}
          schema={productSchema}
          displayLimit={5}
        />
      </div>
    </>
  );
};

export default Component;
