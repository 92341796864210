import { useState } from "react";
import { htmlStripTag } from "../../utils/format";
import { GENERAL_STATUS } from "../../constants/status";
import { GENERAL_UI_SIZE } from "../../constants/size";
import TableCellStatus from "../../components/table-cell-status";
import UiStatusDropdown from "../../components/ui-status-dropdown";

import styles from "./styles.module.scss";
import Typography from "../../components/ui-typography/component";

const Status = ({
  productItem,
  updateStatusApi,
}: {
  productItem: any;
  updateStatusApi: any;
}) => {
  const [product, setProduct] = useState(productItem);

  return (
    <UiStatusDropdown
      size={"small"}
      status={product?.status}
      triggerableEvents={product?.triggerable_events}
      actionApi={updateStatusApi}
      paramsTransformation={(newEvent: string) => ({
        uuid: product?.sale_order_uuid,
        productItemId: product?.id,
        event: newEvent,
      })}
      onChangeSuccess={(newData: any) => {
        setProduct({ ...product, ...newData });
      }}
    />
  );
};

const Component = ({
  label,
  products,
  onDeleteItem,
  onUpdateItem,
  customPanelHeight,
  isDisplayStatusLabel = true,
  isDisplayRequireMark = true,
  updateStatusApi,

  saleOrderStatus,
}: {
  label: string;
  products: any;
  onDeleteItem: any;
  onUpdateItem: any;
  customPanelHeight?: number;
  isDisplayStatusLabel?: boolean;
  isDisplayRequireMark?: boolean;
  updateStatusApi: any;
  saleOrderStatus?: string;
}) => {
  return (
    <>
      <div className={styles.selectedProductHeading}>
        <label data-required={isDisplayRequireMark}>{label}</label>
        {isDisplayStatusLabel && (
          <TableCellStatus
            status={
              products && products.length
                ? GENERAL_STATUS.SELECTED
                : GENERAL_STATUS.MISSING
            }
            size={GENERAL_UI_SIZE.SMALL}
          />
        )}
      </div>
      <div
        className={styles.selectedProducts}
        style={{ maxHeight: customPanelHeight }}
      >
        {!products || !products.length ? (
          <span className={styles.empty}>No product selected</span>
        ) : (
          products?.map((productItem: any) => (
            <div
              className={styles.productItem}
              key={`product-${productItem?.uuid}`}
            >
              <div className={styles.info}>
                <img src={productItem?.image_url} alt="product-item" />
                <div className={styles.content}>
                  <div className={styles.address}>
                    {htmlStripTag(productItem?.name)}
                  </div>
                  <input
                    title={"Quantity"}
                    name={"quantity"}
                    placeholder={" "}
                    min={1}
                    disabled
                    value={productItem?.quantity}
                    onChange={(e) =>
                      onUpdateItem(
                        Object.assign({}, productItem, {
                          quantity: parseInt(e.target.value),
                        })
                      )
                    }
                    className={styles.input}
                    type="text"
                    required
                  />
                  {saleOrderStatus === "pending" && (
                    <div className="d-flex align-items-center gap-2">
                      <Typography customFontsize={12} className="text-warning">
                        Unavailable Qty:
                      </Typography>
                      <strong className="text-warning">
                        {productItem?.quantity_details?.find(
                          (product: any) => product?.status === "unavailable"
                        )?.quantity || 0}
                      </strong>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.action}>
                <Status
                  productItem={productItem}
                  updateStatusApi={updateStatusApi}
                />
                <div
                  className={styles.actionItem}
                  title="Delete"
                  onClick={() => onDeleteItem(productItem)}
                >
                  <i
                    className="fas fa-trash text-secondary"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default Component;
