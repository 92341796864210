import { useState } from "react";
import { useParams } from "react-router-dom";

import { useApiCaller } from "../../apis/config";
import { GENERAL_STATUS } from "../../constants/status";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { getWarehouseDetailApi } from "../../apis/paths/warehouse";
import { extractAddressFields } from "../../utils/address";
import HeaderInfoPanel from "../../components/panel-header-info";
import TableCellStatus from "../../components/table-cell-status";
import UiLoadingPanel from "../../components/ui-loading-panel";
import NotFoundPanel from "../../components/panel-not-found";
import GeneralPanel from "../../components/panel-general";

import ViewChildWarehouse from "./view-child-warehouse";

const transformData = (data: any) => {
  if (!data) return null;

  const { detail, street } = extractAddressFields(
    data?.address?.full_address
  );
  
  return {
    contactName: data?.contact_name,
    contactPhone: data?.contact_phone,
    storageType: data?.storage_type,
    parentWarehouseUuid: data?.parent_warehouse?.name,
    wardId: data?.address?.ward_id,
    streetName: street,
    detail,
    longitude: data?.address?.longitude,
    latitude: data?.address?.latitude,
    ...data,
  };
};

const Component = () => {
  const [warehouseStatus] = useState<
    GENERAL_STATUS.ACTIVATE | GENERAL_STATUS.DEACTIVE
  >(GENERAL_STATUS.DEACTIVE);

  const { warehouseId: code } = useParams();

  const [params] = useState({ code });

  const { result, loading } = useApiCaller({
    api: getWarehouseDetailApi,
    params: params,
  });
  const warehouseInfo = transformData(result?.data);
  const isSubwarehouse = !!result?.data?.parent_warehouse;

  if (loading) return <UiLoadingPanel />;

  if (result?.status === "ERROR" || !warehouseInfo) return <NotFoundPanel />;

  return (
    <>
      <HeaderInfoPanel
        title={`${warehouseInfo?.name} #${warehouseInfo?.code}`}
        infoArea={() => (
          <TableCellStatus
            status={warehouseStatus}
            size={GENERAL_UI_SIZE.SMALL}
          />
        )}
      />

      <div className="mt-4">
        <GeneralPanel
          panel={{
            subTitle: isSubwarehouse ? "Master Warehouse" : "Sub Warehouses",
          }}
        >
          <ViewChildWarehouse
            warehouse={
              isSubwarehouse
                ? [result?.data?.parent_warehouse]
                : result?.data?.child_warehouses
            }
          />
        </GeneralPanel>
      </div>
    </>
  );
};

export default Component;
