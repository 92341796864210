import { useState } from "react";
import { getDispatchListApi } from "../../apis/paths/dispatch";
import TablePanel from "../../components/panel-table";
import UiTab from "../../components/ui-tab";

import { schema } from "./schema";

const Component = () => {
  const [currentActiveTab, setCurrentActiveTab] = useState(0);

  const handleToggleUiTab = (newActiveTab: number) => {
    setCurrentActiveTab(newActiveTab);
  };

  return currentActiveTab === 0 ? (
    <TablePanel
      apiPath={getDispatchListApi}
      apiParams={{
        per: 25,
        filter: [
          {
            field: "status",
            operator: "in",
            values: ["in_progress"],
          },
        ],
        sort: {
          field: "created_at",
          direction: "asc",
        },
      }}
      panel={{
        title: "Dispatch list",
        description: "List of dispatch in the system",
        actionArea: () => (
          <>
            <UiTab
              tabs={["In Progress", "Completed"]}
              selected={currentActiveTab}
              onChange={handleToggleUiTab}
            />
          </>
        ),
      }}
      tableSchema={schema}
    />
  ) : (
    <TablePanel
      apiPath={getDispatchListApi}
      apiParams={{
        per: 25,
        filter: [
          {
            field: "status",
            operator: "in",
            values: ["completed"],
          },
        ],
        sort: {
          field: "created_at",
          direction: "asc",
        },
      }}
      panel={{
        title: "Dispatch list",
        description: "List of dispatch in the system",
        actionArea: () => (
          <>
            <UiTab
              tabs={["Inprogress", "Completed"]}
              selected={currentActiveTab}
              onChange={handleToggleUiTab}
            />
          </>
        ),
      }}
      tableSchema={schema}
    />
  );
};

export default Component;
