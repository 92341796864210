import classNames from "classnames";

import { GENERAL_STATUS } from "../../../constants/status";
import { GENERAL_UI_SIZE } from "../../../constants/size";
import styles from "./styles.module.scss";
import TableCellStatus from "../../../components/table-cell-status";

interface ISelectedItemPanel {
  content?: string | React.ReactNode;
  label?: string;
  noItemContent?: string | React.ReactNode;
  itemName?: string | React.ReactNode;
  className?: {
    container?: string;
    label?: string;
  };
}
const SelectedItem = ({
  content,
  label,
  noItemContent,
  itemName,
  className,
}: ISelectedItemPanel) => {
  return (
    <>
      <div
        className={classNames(
          styles.selectedWarehouseHeading,
          className?.container
        )}
      >
        <label data-required>{label}</label>
        <TableCellStatus
          status={itemName ? GENERAL_STATUS.SELECTED : GENERAL_STATUS.MISSING}
          size={GENERAL_UI_SIZE.SMALL}
        />
      </div>
      <div className={styles.selectedWarehouse}>
        {!itemName ? (
          <span className={styles.empty}>{noItemContent}</span>
        ) : (
          <div className={styles.info}>
            <div className={styles.name}>{itemName}</div>
            <div className={styles.address}>{content}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default SelectedItem;
