import { SelectedOptions } from "./component";

export const handleCountTotalSelectedItems = (
  selectedItems: SelectedOptions
) => {
  let count = 0;
  Object.keys(selectedItems)?.forEach((selectedItemKey: string) => {
    const selectedFieldValues = selectedItems[selectedItemKey]?.values;
    if (Array.isArray(selectedFieldValues) && selectedFieldValues.length > 0) {
      count += selectedFieldValues.length;
    } else if (selectedItems[selectedItemKey]?.value) {
      count++;
    }
  });

  return count;
};

export const handleCountSingleSelectedField = (
  selectedItems: SelectedOptions,
  field: string
) => {
  let count = 0;
  const selectedFieldValues = selectedItems[field]?.values;
  if (Array.isArray(selectedFieldValues) && selectedFieldValues.length > 0)
    count = selectedFieldValues.length;
  return count;
};
