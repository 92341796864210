import classNames from "classnames";
import React  from "react";

import { GENERAL_UI_SIZE } from "../../constants/size";

interface ITypographyProps {
  children: string | React.ReactNode;
  textColor?: string;
  textSize?: GENERAL_UI_SIZE;
  handleClick?: () => void;
  customFontsize?: number;
  className?: string;
  fontSize?: "fs-1" | "fs-2" | "fs-3" | "fs-4" | "fs-5" | "fs-6";
  fontWeight?:
    | "fw-bold"
    | "fw-bolder"
    | "fw-normal"
    | "fw-light"
    | "fw-lighter";
  lineHeight?: "lh-1" | "lh-sm" | "lh-base" | "lh-lg";
  decoration?:
    | "text-decoration-underline"
    | "text-decoration-line-through"
    | "text-decoration-none";
}

const Typography = ({
  className,
  handleClick,
  children,
  fontSize,
  fontWeight = "fw-light",
  lineHeight = "lh-sm",
  decoration = "text-decoration-none",
  textColor,
  textSize,
  customFontsize
}: ITypographyProps) => {

  return (
    <div
      role="div"
      className={classNames(
        textSize && `text-${textSize}`,
        className,
        // handleClick ? "pe-auto" : "pe-none",
        fontSize,
        fontWeight,
        lineHeight,
        decoration
      )}
      style={{color: textColor && textColor, fontSize: customFontsize && customFontsize}}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};

export default Typography;
