import React from "react";

import { convertYYYYMMDDToUnixTime } from "../../utils/date-time";
import { ACCOUNT_LIST } from "../../routings/path";

import DateTimeTableCell from "../../components/table-cell-date-time";
import ProductTableCell from "../table-cell-product/component";
import PanelGeneral from "../panel-general/component";
import Typography from "../ui-typography/component";

import ViewActivityItem from "./view-activity-item";

const ViewActivityCard = ({ activity }: { activity: any }) => {
  return (
    <PanelGeneral>
      <div className="d-flex justify-content-between mt-3">
        <ProductTableCell
          linkTo={`${ACCOUNT_LIST}/${activity?.actor?.uuid}`}
          image={activity?.actor?.avatar}
          isForceDisplayAvatar
          title={activity?.actor?.info}
          userName={activity?.actor?.info}
          allowDynamicAvatarBackgroundColor
        />

        <div className="d-flex align-items-center gap-2">
          <Typography fontSize="fs-6" fontWeight="fw-bold" className="mt-1">
            Created At:
          </Typography>
          <DateTimeTableCell
            timeStamp={convertYYYYMMDDToUnixTime(activity?.created_at)}
            formatTimestamp={{
              time: "HH:mm YYYY-MM-DD",
              tooltip: "HH:mm YYYY-MM-DD",
            }}
          />
        </div>
        <Typography>{`#${activity?.id}`}</Typography>
      </div>
      <hr className="horizontal dark mt-3" />

      {Object.entries(
        activity?.object_changes as Record<string, string[]>
      )?.map(([key, change]) => (
        <ViewActivityItem index={key} change={change} />
      ))}
    </PanelGeneral>
  );
};

export default ViewActivityCard;
