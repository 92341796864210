import classnames from "classnames";
import styles from "./styles.module.scss";

const Component = ({
  className,
  children,
}: {
  className?: string;
  children: any;
}) => (
  <h5
    className={classnames(
      "text-sm font-bold mb-2",
      styles.container,
      className
    )}
  >
    {children}
  </h5>
);

export default Component;
