import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as R from "../../routings/path";
import { STRATEGY } from "../../constants/product";
import { apiCaller, useApiCaller } from "../../apis/config";
import { GENERAL_STATUS } from "../../constants/status";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import {
  getProductDetailApi,
  updateProductDetailApi,
  toggleProductStatusApi,
  deleteProductApi,
  getProductTypeListApi,
  IUpdateProductDetailApi,
} from "../../apis/paths/products";
import UiSelectDropdown, {
  IOptionItem,
} from "../../components/ui-select-dropdown/component";
import alert from "../../utils/alert";
import QuickActionPopupConfirmation from "../../components/popup-quick-aciton-confirmation/component";
import SmallCollumnCenterGridLayout from "../../components/grid-layout-small-collumn-center";
import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import HeaderInfoPanel from "../../components/panel-header-info";
import TableCellStatus from "../../components/table-cell-status";
import NotFoundPanel from "../../components/panel-not-found";
import UiLoadingPanel from "../../components/ui-loading-panel";
import SelectDropdown from "../../components/ui-select-dropdown/component";
import GeneralPanel from "../../components/panel-general";
import UiTypography from "../../components/ui-typography";
import ImageUploader from "../../components/ui-image-uploader/component";
import UiTextArea from "../../components/ui-text-area";
import UiSwitch from "../../components/ui-switch";
import UiButton from "../../components/ui-button";
import UiInput from "../../components/ui-input";


import { schema } from "./schema";
import { CAPACITY_UNIT } from "./constants";
import ProductTypesSearchBox from "./view-product-type-search-box";
import UiContentHeading from "../purchase-order-detail/view-section-heading";
import PanelLabelController from "../../components/ui-panel-label-controller/component";

const Component = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [productStatus, setProductStatus] = useState<
    GENERAL_STATUS.ACTIVATE | GENERAL_STATUS.DEACTIVE
  >(GENERAL_STATUS.DEACTIVE);
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [selectedItems, setSelectedItems] = useState<any>([]);

  useEffect(() => {
    setValue(
      "product_types",
      selectedItems?.map((item: any) => ({ id: item.value }))
    );
  }, [selectedItems]);

  const navigate = useNavigate();

  const handleOpenModal = () => {
    setIsShowModal(true);
  };

  const handleCloseModal = () => {
    setIsShowModal(false);
  };

  const { productCode: code } = useParams();
  const [params] = useState({ code });

  const { result, loading } = useApiCaller({
    api: getProductDetailApi,
    params: params,
  });

  const { result: productTypes } = useApiCaller({
    api: getProductTypeListApi,
    params: DEFAULT_PAGINATION_PARAMS,
  });

  const productUuid = result?.data?.uuid;
  const productInfo = result?.data;
  const typeIds = productInfo?.types?.map((typeId: any) => typeId?.id);
  const uploadImage: any = watch("upload_image");

  useEffect(() => {
    if (!result || result?.status === "ERROR") return;

    const productInfo = result?.data;

    const keys = Object.keys(schema?.fields);
    keys.forEach((key: any) => {
      if (key !== "product_types") setValue(key, productInfo[key]);
    });
    setProductStatus(productInfo?.status);
  }, [result]);

  const toggleProduct = async (nextValue: boolean) => {
    const nextStatus =
      nextValue === true ? GENERAL_STATUS.ACTIVATE : GENERAL_STATUS.DEACTIVE;

    const waitToggle = await apiCaller({
      api: toggleProductStatusApi,
      params: { code: productInfo?.code },
    });

    if (waitToggle.status === API_RESPONSE_STATUS.SUCCESS) {
      setProductStatus(nextStatus);
      return;
    }
    alert.error("Change status was not successful!. Please try again");
  };

  const onSubmit = async ({
    code,
    name,
    short_name,
    description,
    reference_code,
    capacity_value,
    capacity_unit,
    display_width,
    display_length,
    display_height,
    packaging_weight,
    packaging_width,
    packaging_height,
    packaging_length,
    product_types,
    strategy,
  }: any) => {
    const data: IUpdateProductDetailApi = {
      name,
      short_name,
      description,
      reference_code,
      capacity_value,
      capacity_unit,
      display_width,
      display_length,
      display_height,
      packaging_weight,
      packaging_width,
      packaging_height,
      packaging_length,
      image_url: productInfo?.image_url,
      upload_image: uploadImage?.[0]?.file,
      status: productStatus,
      product_types,
      strategy,
    };

    const formData = new FormData();

    for (const key in data) {
      if (key === "product_types" && Array.isArray(data[key])) {
        data[key].forEach((product: any) => {
          if (product.hasOwnProperty("id")) {
            formData.append(`product_types[][id]`, product.id);
          }
        });
      } else {
        if (!!data[key as keyof IUpdateProductDetailApi]) {
          formData.append(key, data[key as keyof IUpdateProductDetailApi]);
        }
      }
    }

    const waitUpdateProduct = await apiCaller({
      api: updateProductDetailApi,
      params: {
        code,
        data: formData,
      },
    });

    if (waitUpdateProduct.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update successful!");
      setTimeout(() => {
        navigate(`${R.PRODUCTS_LIST}/${code}`);
      }, 500);

      return;
    }

    alert.error("Update was not successful!. Please try again!");
  };

  const handleDeleteProduct = async () => {
    const waitDelete = await apiCaller({
      api: deleteProductApi,
      params: { code: productInfo?.code },
    });

    if (waitDelete.status === API_RESPONSE_STATUS.SUCCESS) {
      setTimeout(() => {
        alert.success("Delete successful!");
      }, 500);
      navigate(R.PRODUCTS_LIST);
    }
  };

  const formatOptionItems = (data: string[]) =>
    data.map((option) => ({
      value: option,
      content: option,
    }));

  const formatedAllOptionProductTypes = productTypes?.data?.list?.map(
    (productType: any) => ({
      value: productType.id,
      content: productType.name,
    })
  );

  if (loading) return <UiLoadingPanel />;

  if (result?.status === "ERROR") return <NotFoundPanel />;

  return (
    <>
      <HeaderInfoPanel
        title={productInfo?.name}
        image={productInfo?.image_url}
        infoArea={() => (
          <TableCellStatus
            status={productStatus}
            size={GENERAL_UI_SIZE.SMALL}
          />
        )}
        actionArea={() => (
          <>
            <UiTypography
              fontWeight="fw-bolder"
              className="text-xs text-bold me-2 pt-1"
            >
              STATUS
            </UiTypography>
            <UiSwitch
              checked={productStatus === GENERAL_STATUS.ACTIVATE}
              onChange={toggleProduct}
              isEditable={true}
              className="me-4"
            />
            <UiButton
              onClick={handleSubmit(onSubmit)}
              className="me-2"
              title={"Save Product Info"}
            />
            <UiButton
              type={GENERAL_UI_COLOR.DANGER}
              onClick={handleOpenModal}
              title={"Delete Product"}
            />
          </>
        )}
      />
      <SmallCollumnCenterGridLayout>
        <GeneralPanel panel={{ subTitle: "Product Information" }}>
          <TwoColumnGridLayout
            className={{ container: "px-0" }}
            leftCol={
              <>
                <UiInput
                  title={"Name"}
                  name={"name"}
                  required
                  register={register}
                  errorMessage={errors?.name?.message}
                />
                <UiInput
                  title={"Short name"}
                  name={"short_name"}
                  register={register}
                  errorMessage={errors?.short_name?.message}
                />
                <UiTextArea
                  title={"Description"}
                  name={"description"}
                  register={register}
                  errorMessage={errors?.description?.message}
                  rows={8}
                />
              </>
            }
            rightCol={
              <>
                <UiInput
                  title={"Code"}
                  name={"code"}
                  required
                  register={register}
                  errorMessage={errors?.code?.message}
                />
                <UiInput
                  title={"Reference Code"}
                  name={"reference_code"}
                  required
                  register={register}
                  errorMessage={errors?.reference_code?.message}
                />
                <SelectDropdown
                  name="strategy"
                  className="w-100"
                  title="Strategy"
                  isDisplayLabel
                  register={register}
                  setValue={setValue}
                  optionList={STRATEGY}
                />
                {formatedAllOptionProductTypes &&
                  typeIds &&
                  formatedAllOptionProductTypes.length > 0 && (
                    <ProductTypesSearchBox
                      optionList={formatedAllOptionProductTypes}
                      setSelectedItems={setSelectedItems}
                      formItem={{ typeIds: typeIds }}
                      errorMessage={errors?.product_types?.message}
                    />
                  )}
                <ImageUploader
                  isDisplayLabel
                  title="Image Upload File"
                  isDisplayRemoveAll={false}
                  name="upload_image"
                  setValue={setValue}
                  errorMessage={errors?.upload_image?.message}
                />
              </>
            }
          />
        </GeneralPanel>

        <GeneralPanel panel={{ subTitle: "Packaging Information" }}>
          <TwoColumnGridLayout
            className={{ container: "px-0" }}
            leftCol={
              <>
                <UiInput
                  title={"Capacity Value"}
                  name={"capacity_value"}
                  type="number"
                  register={register}
                  errorMessage={errors?.capacity_value?.message}
                />
                <UiSelectDropdown
                  name="capacity_unit"
                  title="Capacity Unit"
                  isDisplayLabel
                  register={register}
                  optionList={formatOptionItems(CAPACITY_UNIT) as IOptionItem[]}
                  errorMessage={errors?.capacity_unit?.message}
                />
                <UiInput
                  title={"Display Width"}
                  name={"display_width"}
                  type="number"
                  register={register}
                  errorMessage={errors?.display_width?.message}
                />
                <UiInput
                  title={"Display Length"}
                  name={"display_length"}
                  type="number"
                  register={register}
                  errorMessage={errors?.display_length?.message}
                />
                <UiInput
                  title={"Display Height"}
                  name={"display_height"}
                  type="number"
                  register={register}
                  errorMessage={errors?.display_height?.message}
                />
              </>
            }
            rightCol={
              <>
                <UiInput
                  title={"Packaging Weight"}
                  name={"packaging_weight"}
                  type="number"
                  register={register}
                  errorMessage={errors?.packaging_weight?.message}
                />
                <UiInput
                  title={"Packaging Width"}
                  name={"packaging_width"}
                  type="number"
                  register={register}
                  errorMessage={errors?.packaging_width?.message}
                />
                <UiInput
                  title={"Packaging Height"}
                  name={"packaging_height"}
                  type="number"
                  register={register}
                  errorMessage={errors?.packaging_height?.message}
                />
                <UiInput
                  title={"Packaging Length"}
                  name={"packaging_length"}
                  type="number"
                  register={register}
                  errorMessage={errors?.packaging_length?.message}
                />
              </>
            }
          />
        </GeneralPanel>
      </SmallCollumnCenterGridLayout>

      <SmallCollumnCenterGridLayout>
        <GeneralPanel className="pt-3">
          <UiContentHeading>Labels</UiContentHeading>
          {productUuid && (
            <div className="mt-3">
              <PanelLabelController
                linkedObjectType="Product"
                uniqueUuid={productUuid as string}
              />
            </div>
          )}
        </GeneralPanel>
      </SmallCollumnCenterGridLayout>

      <QuickActionPopupConfirmation
        content={"Are you sure to delete this product ?"}
        isShowModal={isShowModal}
        handleCloseModal={handleCloseModal}
        onConfirm={handleDeleteProduct}
      />
    </>
  );
};

export default Component;
