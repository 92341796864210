import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

import alert from "../../utils/alert";
import { apiCaller } from "../../apis/config";
import { createStickerApi } from "../../apis/paths/sticker";
import { API_RESPONSE_STATUS } from "../../constants/api";
import * as R from "../../routings/path";

import SmallCollumnCenterGridLayout from "../../components/grid-layout-small-collumn-center";
import HeaderInfoPanel from "../../components/panel-header-info";
import GeneralPanel from "../../components/panel-general";
import UiButton from "../../components/ui-button";
import UiInput from "../../components/ui-input";

import { schema } from "./schema";

const Component = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ quantity }: { quantity: number }) => {
    const waitCreateSticker = await apiCaller({
      api: createStickerApi,
      params: { quantity },
    });

    if (waitCreateSticker.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Create sticker successful!");
      setTimeout(() => {
        navigate(R.STICKER_LIST);
      }, 500);

      return;
    }

    alert.error("Update was not successful!. Please try again!");
  };

  return (
    <>
      <HeaderInfoPanel
        title={"Create new sticker"}
        description={"Input info below to create a new sticker"}
        actionArea={() => (
          <>
            <UiButton
              onClick={handleSubmit(onSubmit)}
              className="me-2"
              title={"Create"}
            />
          </>
        )}
      />
      <SmallCollumnCenterGridLayout>
        <GeneralPanel className="pt-4">
          <UiInput
            title={"Quantity"}
            name={"quantity"}
            type="number"
            register={register}
            errorMessage={errors?.quantity?.message}
          />
        </GeneralPanel>
      </SmallCollumnCenterGridLayout>
    </>
  );
};

export default Component;
