const Component = ({
  currentPage,
  perPage,
  totalItem,
}: {
  currentPage: number;
  perPage: number;
  totalItem: number;
}) => {
  const start = (currentPage - 1) * perPage + 1;
  const end = currentPage * perPage;

  if (Number.isNaN(start)) return null;

  return (
    <div className="dataTable-info">
      Showing {start} to {end} of {totalItem} entries
    </div>
  );
};

export default Component;
