import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { generateUrlParams } from "../../utils/url";
import { get, put, post } from "../config";

export const getStockCheckListApi = ({
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
}: {
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
}) =>
  get({
    path: `/wms/stock_checks${generateUrlParams({
      page,
      per,
      keyword,
      sort,
    })}`,
  });

export const getStockCheckDetailApi = ({ uuid }: { uuid: string }) =>
  get({
    path: `/wms/stock_checks/${uuid}`,
  });

export const getStockCheckProductCountApi = ({ uuid }: { uuid: string }) =>
  get({
    path: `/wms/stock_checks/${uuid}/products/count`,
  });

export const getStockCheckProductListApi = ({
    uuid,
    page = DEFAULT_PAGINATION_PARAMS.page,
    per = DEFAULT_PAGINATION_PARAMS.per,
    keyword = DEFAULT_PAGINATION_PARAMS.keyword,
    sort = DEFAULT_PAGINATION_PARAMS.sort,
    filter = DEFAULT_PAGINATION_PARAMS.filter,
  }: {
    uuid: string;
    page?: number;
    per?: number;
    keyword?: string;
    sort?: object | null;
    filter?: object | null;
    }) =>
    get({
      path: `/wms/stock_checks/${uuid}/products${generateUrlParams({
        page,
        per,
        keyword,
        sort,
        filter,
      })}`,
    });

export const processCheckingStockApi = ({
  uuid,
  type,
}: {
  uuid: string;
  type: "start" | "end" | "cancel";
}) =>
  put({
    path: `/wms/stock_checks/${uuid}/${type}`,
  });

export const createStockCheckApi = ({
  warehouseUuid,
  cycleChecks,
}: {
  warehouseUuid: String;
  cycleChecks: number;
}) =>
  post({
    path: `/wms/stock_checks`,
    data: {
      warehouse_code: warehouseUuid,
      cycle_checks: cycleChecks,
    },
  });

export const createStockCheckByProductUuidApi = ({
  warehouseUuid,
  productUuids,
}: {
  warehouseUuid: String;
  productUuids: Array<string>;
}) =>
  post({
    path: `/wms/stock_checks/create_by_product_uuids`,
    data: {
      warehouse_code: warehouseUuid,
      product_uuids: productUuids,
    },
  });

export const getAvailableProductsByWarehouseApi = ({
  warehouseUuid,
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
  keyword = DEFAULT_PAGINATION_PARAMS.keyword,
  sort = DEFAULT_PAGINATION_PARAMS.sort,
}: {
  warehouseUuid: string;
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
}) =>
  get({
    path: `/wms/stock_checks/available_products/${warehouseUuid}${generateUrlParams(
      {
        page,
        per,
        keyword,
        sort,
      }
    )}`,
  });

export const printStockCheckReportApi = ({
  stockCheckUuid,
}: {
  stockCheckUuid: String;
}) =>
  post({
    path: `/wms/stock_checks/${stockCheckUuid}/report/print`,
    data: {
      stock_check_uuid: stockCheckUuid,
    },
  });

export const getStockCheckUnMatchedProductListApi = ({
  uuid,
}: {
  uuid: string;
}) =>
  get({
    path: `/wms/stock_checks/${uuid}/products/unmatched_stock`,
  });

export const getStockCheckProductDetailApi = ({
  uuid,
  productUuid,
}: {
  uuid: string;
  productUuid: string;
}) =>
  get({
    path: `/wms/stock_checks/${uuid}/products/${productUuid}`,
  });

export const getStockCheckLocationListApi = ({ uuid }: { uuid: string }) =>
  get({
    path: `/wms/stock_checks/${uuid}/locations`,
  });

export const getStockCheckLocationDetailApi = ({
  uuid,
  locationCode,
}: {
  uuid: string;
  locationCode: string;
}) =>
  get({
    path: `/wms/stock_checks/${uuid}/locations/${locationCode}`,
  });
