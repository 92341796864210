import React from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { ISidebarContent } from "../../constants/layout";

const AccountSidebar = ({ sidebarContent }: { sidebarContent: any }) => {
  const location = useLocation();

  const { purchaseOrderId } = useParams();
  const uuid = purchaseOrderId || "";

  return (
    <div className="card card-profile-bottom">
      <div className="card position-sticky top-1">
        <ul className="nav flex-column bg-white border-radius-lg p-3">
          {sidebarContent(uuid).map((item: ISidebarContent, index: number) => {
            return (
              <li className="nav-item mb-1" key={index}>
                <NavLink
                  className={classNames(
                    "nav-link text-body d-flex align-items-center",
                    location?.pathname === item.href && styles.active
                  )}
                  data-scroll=""
                  to={item.href}
                >
                  <div className="p-1">{item.icon}</div>
                  <span className="text-sm">{item.content}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default AccountSidebar;
