import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  IUpdateRoleDetailApi,
  deleleRoleApi,
  getPermissionApi,
  getRoleDetailApi,
  getRoleListApi,
  updateRoleDetailApi,
} from "../../apis/paths/roles";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { IPermissionProps } from "../../types/roles";
import { ROLES_LIST } from "../../routings/path";
import { areObjectsEqual } from "../../utils/compare";
import { getAccountListApi } from "../../apis/paths/accounts";
import { apiCaller, useApiCaller } from "../../apis/config";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";

import alert from "../../utils/alert";
import TablePanel from "../../components/panel-table";
import UiButton from "../../components/ui-button";
import UiForm from "../../components/ui-form";
import HeaderInfoPanel from "../../components/panel-header-info";
import NotFoundPanel from "../../components/panel-not-found";
import QuickActionPopupConfirmation from "../../components/popup-quick-aciton-confirmation/component";
import GridLayoutDynamicCollumn from "../../components/grid-layout-dynamic-collum/component";
import usePreventDirtyFormExit from "../../hooks/usePreventDirtyFormExist";
import LeavePagePopupConfirmation from "../../components/popup-leave-page-confirmation/component";

import {
  handlePreSortingData,
  removeDuplicateArrayItems,
  removeEmptyObjects,
} from "./utils";
import { schema } from "./schema";
import { formSchema } from "./formSchema";

import EditRoleForm from "./view-edit-role-form";
import ViewInvitedMember from "./view-invited-member";
import ViewAddNewMember from "./view-add-new-member";
import ViewInvitedWarehouse from "./view-invited-warehouse";
import ViewAddNewWarehouse from "./view-add-new-warehouse";

interface IEditRoleSubmitAction {
  name: string;
  status?: "activate" | "deactivate";
  description?: string | null | undefined;
  permission_ids?: boolean[];
  upper_role_uuid?: string | null | undefined;
}

const Component = () => {
  const [pageReloadVersion, setPageReloadVersion] = useState(1);
  const [activeTabMember, setActiveTabMember] = useState(0);
  const [activeTabWarehouse, setActiveTabWarehouse] = useState(0);
  const [grantedPermissionsData, setGrantedPermissionsData] = useState([]);
  const [isShowDeleteConfirmationModal, setIsShowDeleteConfirmationModal] =
    useState(false);
  // TEMP PAUSING: Some deployment on staging issue with 'use blocker'
  const [isShowLeavePageConfimationModal, setIsShowLeavePageConfimationModal] =
    useState(false);
  const defaultFormValue = useRef({});

  const { roleId } = useParams();
  const uuid = roleId || "";
  const navigate = useNavigate();

  const [params] = useState<{
    page: number;
    per: number;
    uuid: string;
  }>({
    page: 1,
    per: 50,
    uuid,
  });
  const { result: roleDetail } = useApiCaller({
    api: getRoleDetailApi,
    params: params,
    pageReloadVersion,
  });

  useEffect(() => {
    if (roleDetail) setGrantedPermissionsData(roleDetail?.data?.permissions);
  }, [roleDetail]);

  const { result: rolesList } = useApiCaller({
    api: getRoleListApi,
    params: DEFAULT_PAGINATION_PARAMS,
  });

  const roles = useMemo(
    () => roleDetail?.data?.role || {},
    [roleDetail?.data?.role]
  );

  const { result: accountList } = useApiCaller({
    api: getAccountListApi,
    params: DEFAULT_PAGINATION_PARAMS,
    pageReloadVersion: pageReloadVersion,
  });

  const handleDeleteRole = async (uuid: string) => {
    const waitDelete = await apiCaller({
      api: deleleRoleApi,
      params: { uuid },
    });

    if (waitDelete.status === API_RESPONSE_STATUS.SUCCESS) {
      setTimeout(() => {
        alert.success("Delete successful!");
      }, 1000);

      navigate(ROLES_LIST);

      return;
    }

    alert.error("Delete was unsuccessful!. Please try again");
  };

  const handleOpenModal = () => {
    setIsShowDeleteConfirmationModal(true);
  };

  const handleCloseModal = () => {
    setIsShowDeleteConfirmationModal(false);
  };

  const {
    setValue,
    register,
    handleSubmit,
    control,
    formState: { errors, dirtyFields, isDirty },
    watch,
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const allGrantedPermissionIds = grantedPermissionsData?.map(
    (permission: IPermissionProps) => permission.id
  );

  const extractTruthyPermissionIds = () => {
    if (!dirtyFields?.permission_ids) return allGrantedPermissionIds; //To check if user havent't clicked on any switch inside table => Return default arr value

    const formatPermissionIds = (data: any) =>
      data?.map((item: any, index: number) => ({ id: index, value: item }));

    const modifiedAllGrantedPermissionIds = grantedPermissionsData?.map(
      (permission: IPermissionProps) => ({
        id: permission.id,
        value: true,
      })
    );

    const selectingIds = removeEmptyObjects(
      formatPermissionIds(watch("permission_ids"))
    );

    const mapArray = new Map(selectingIds?.map((obj: any) => [obj.id, obj]));

    const mergedArrayWithSelectingIds = modifiedAllGrantedPermissionIds.map(
      (obj) => {
        if (mapArray.has(obj.id)) {
          return { ...obj, value: mapArray.get(obj.id)!.value };
        }
        return obj;
      }
    );

    const cloneMergedArray = [...mergedArrayWithSelectingIds];

    mapArray.forEach((obj) => {
      if (!cloneMergedArray.some((item) => item.id === obj.id)) {
        cloneMergedArray.push({ ...obj });
      }
    });

    const formatAndExtractTruthyIds: any[] = cloneMergedArray
      .filter((item) => item.value)
      ?.map((item) => item.id);

    return formatAndExtractTruthyIds;
  };

  useEffect(() => {
    defaultFormValue.current = watch();
  }, [rolesList, roleDetail]);

  const checkIsFormDirty = () => {
    if (!isDirty) return false;

    const defaultObject = {
      ...defaultFormValue.current,
      permission_ids: allGrantedPermissionIds,
    };

    const updatedObject = {
      ...watch(),
      permission_ids: extractTruthyPermissionIds(),
    };

    return !areObjectsEqual(defaultObject, updatedObject);
  };
  const isFormDirty = checkIsFormDirty?.();

  const blocker = usePreventDirtyFormExit(isFormDirty);

  useEffect(() => {
    if (blocker.state === "blocked") setIsShowLeavePageConfimationModal(true);
  }, [blocker]);

  const onSubmit: SubmitHandler<IEditRoleSubmitAction> = async ({
    name,
    status,
    description,
    upper_role_uuid,
  }) => {
    const waitUpdateRole = await apiCaller({
      api: updateRoleDetailApi,
      params: {
        uuid,
        name,
        status,
        description,
        upper_role_uuid: upper_role_uuid,
        permission_ids: extractTruthyPermissionIds(),
      } as IUpdateRoleDetailApi,
    });

    if (waitUpdateRole.status === API_RESPONSE_STATUS.SUCCESS) {
      alert.success("Update successful!");
      setTimeout(() => {
        setPageReloadVersion((prev) => prev + 1);
      }, 1000);
      navigate(0);
      return;
    }

    alert.error("Update was not successful!. Please try again!");
  };

  if (!dirtyFields?.permission_ids)
    roles.permission_ids = allGrantedPermissionIds;
  // If user haven't click to any switch inside table,
  //  set role property "permission_ids" to roles so that the setValue in the child component will work

  if (
    roleDetail?.status === "ERROR" ||
    rolesList?.status === "ERROR" ||
    accountList?.status === "ERROR"
  )
    return <NotFoundPanel />;

  const removedDuplicateAccount = removeDuplicateArrayItems(
    roleDetail?.data?.accounts,
    accountList?.data.list
  );

  return (
    <>
      <UiForm onSubmit={handleSubmit(onSubmit)}>
        <div className="pb-4">
          <HeaderInfoPanel
            isDisplayInfoArea={true}
            title="Edit Role & Permission "
            description="Management system"
            actionArea={() => (
              <>
                <UiButton className="me-2" title={"Update"} />
                <UiButton
                  type={GENERAL_UI_COLOR.DANGER}
                  onClick={handleOpenModal}
                  title={"Delete Role"}
                />
              </>
            )}
          />
        </div>

        <GridLayoutDynamicCollumn widthDivision={[4, 4, 4]} className="px-0">
          <EditRoleForm
            rolesList={rolesList?.data?.list}
            roles={roles}
            setValue={setValue}
            control={control}
            errors={errors}
            register={register}
          />
          <>
            {activeTabWarehouse === 0 ? (
              <ViewInvitedWarehouse
                warehouses={roleDetail?.data?.warehouses}
                onClick={() => {
                  setActiveTabWarehouse(1);
                }}
                setPageReloadVersion={setPageReloadVersion}
              />
            ) : (
              <ViewAddNewWarehouse
                onCancel={() => {
                  setActiveTabWarehouse(0);
                }}
                roleWarehouses={roleDetail?.data?.warehouses}
                uuid={uuid as string}
                setPageReloadVersion={setPageReloadVersion}
              />
            )}
          </>
          <>
            {activeTabMember === 0 ? (
              <ViewInvitedMember
                accounts={roleDetail?.data?.accounts}
                onClick={() => {
                  setActiveTabMember(1);
                }}
                setPageReloadVersion={setPageReloadVersion}
              />
            ) : (
              <ViewAddNewMember
                accounts={removedDuplicateAccount}
                onCancel={() => {
                  setActiveTabMember(0);
                }}
                uuid={uuid as string}
                setPageReloadVersion={setPageReloadVersion}
              />
            )}
          </>
        </GridLayoutDynamicCollumn>
        <div className="pt-2">
          <TablePanel
            apiPath={getPermissionApi}
            panel={{
              title: "Permissions",
              description: roles?.is_system
                ? "The system role can't be modified"
                : "Actionable items of this role",
            }}
            isDisplayedDataTopTable={false}
            showPerPageSelection={false}
            dataTransformation={(result: any) =>
              result && {
                data: handlePreSortingData(result?.data?.list),
              }
            }
            tableSchema={schema({
              control,
              isSystem: roles?.is_system,
              grantedPermissions: grantedPermissionsData || [],
            })}
          />
        </div>
      </UiForm>
      <QuickActionPopupConfirmation
        content={"Are you sure to delete this product ?"}
        isShowModal={isShowDeleteConfirmationModal}
        handleCloseModal={handleCloseModal}
        onConfirm={() => {
          handleDeleteRole(uuid as string);
        }}
      />
      <LeavePagePopupConfirmation
        blocker={blocker}
        content={"You have unsaved changes in the form."}
        isShowModal={isShowLeavePageConfimationModal}
        handleCloseModal={() => {
          setIsShowLeavePageConfimationModal(false);
        }}
      />
    </>
  );
};

export default Component;
