export const formatIconBaseOnTaskName = (taskName: string) => {
  switch (taskName) {
    case "inbound_qc":
      return (
        <div className="bg-gradient-success p-1 rounded-2 d-flex align-items-center ">
          <span className="material-icons s-16 text-white">fact_check</span>
        </div>
      );
    case "inbound_putaway":
      return (
        <div className="bg-gradient-success p-1 rounded-2 d-flex align-items-center ">
          <span className="material-icons s-16 text-white">swipe_up</span>
        </div>
      );
    case "operation_relocation":
      return (
        <div className="bg-gradient-warning p-1 rounded-2 d-flex align-items-center ">
          <span className="material-icons s-16 text-white">move_up</span>
        </div>
      );
    case "operation_stock_check":
      return (
        <div className="bg-gradient-warning p-1 rounded-2 d-flex align-items-center ">
          <span className="material-icons s-16 text-white">troubleshoot</span>
        </div>
      );
    case "outbound_dispatch":
      return (
        <div className="bg-gradient-info p-1 rounded-2 d-flex align-items-center ">
          <span className="material-icons s-16 text-info">add_box</span>
        </div>
      );
    case "outbound_pack":
      return (
        <div className="bg-gradient-info p-1 rounded-2 d-flex align-items-center ">
          <span className="material-icons s-16 text-white">pallet</span>
        </div>
      );
    case "outbound_picking":
      return (
        <div className="bg-gradient-info p-1 rounded-2 d-flex align-items-center ">
          <span className="material-icons s-16 text-white">forklift</span>
        </div>
      );
    case "outbound_qc":
      return (
        <div className="bg-gradient-info p-1 rounded-2 d-flex align-items-center ">
          <span className="material-icons s-16 text-white">
            playlist_add_check_circle
          </span>
        </div>
      );

    default:
      <div className="bg-gradient-primary p-1 rounded-2 d-flex align-items-center ">
        <span className="material-icons s-16 text-white">
          playlist_add_check_circle
        </span>
      </div>;
      break;
  }
};

export const formatIconBaseOnChannel = (channel: string) => {
  switch (channel) {
    case "purchase":
      return (
        <div className="bg-gradient-primary p-1 rounded-2 d-flex align-items-center ">
          <span className="material-icons s-16 text-white">shop_two</span>
        </div>
      );
    case "return":
      return (
        <div className="bg-gradient-warning p-1 rounded-2 d-flex align-items-center ">
          <span className="material-icons s-16 text-white">
            keyboard_return
          </span>
        </div>
      );
    case "relocation":
      return (
        <div className="bg-gradient-success p-1 rounded-2 d-flex align-items-center ">
          <span className="material-icons s-16 text-white">move_up</span>
        </div>
      );
    case 'cancel':
      return (
        <div className="bg-gradient-danger p-1 rounded-2 d-flex align-items-center ">
          <span className="material-icons s-16 text-white">undo</span>
        </div>
      );
    default:
      return (
        <div className="bg-gradient-primary p-1 rounded-2 d-flex align-items-center ">
          <span className="material-icons s-16 text-white">question_mark</span>
        </div>
      );
  }
};

export const convertUnixTimeToRansackFormatTime = (
  daysToSubtract: number = 0
) => {
  const now = new Date(); // Get the current date and time
  now.setDate(now.getDate() + daysToSubtract); // Subtract the specified number of days

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDateTime;
};

export const SAMPLE_BORDER_COLORS = [
  "rgba(75, 192, 192, 1)",
  "rgba(153, 102, 255, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(255, 159, 64, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(0, 255, 0, 1)",
  "rgba(255, 0, 0, 1)",
  "rgba(0, 0, 255, 1)",
  "rgba(255, 255, 0, 1)",
  "rgba(128, 0, 128, 1)",
  "rgba(255, 140, 0, 1)",
  "rgba(0, 128, 128, 1)",
  "rgba(0, 0, 0, 1)",
  "rgba(128, 128, 128, 1)",
];

export const SAMPLE_BACKGROUND_COLORS = [
  "rgba(75, 192, 192, 0.2)",
  "rgba(153, 102, 255, 0.2)",
  "rgba(54, 162, 235, 0.2)",
  "rgba(255, 159, 64, 0.2)",
  "rgba(255, 206, 86, 0.2)",
  "rgba(0, 255, 0, 0.2)",
  "rgba(255, 0, 0, 0.2)",
  "rgba(0, 0, 255, 0.2)",
  "rgba(255, 255, 0, 0.2)",
  "rgba(128, 0, 128, 0.2)",
  "rgba(255, 140, 0, 0.2)",
  "rgba(0, 128, 128, 0.2)",
  "rgba(0, 0, 0, 0.2)",
  "rgba(128, 128, 128, 0.2)",
];
