import React, { Component, ErrorInfo, ReactNode } from "react";
import classNames from "classnames";

import { DASHBOARD } from "../../routings/path";
import UiButton from "../ui-button/component";
import errorImage from "../../styles/images/error.png";

import styles from "./styles.module.scss";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: any) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log({ error });
    console.log({ errorInfo });
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          className={classNames(
            "d-flex flex-column justify-content-center align-items-center",
            styles.errorBoundary
          )}
        >
          <div className="d-flex justify-content-center mb-3">
            <img src={errorImage} className="img-fluid w-60" alt="errors" />
          </div>
          <h2>Oops. Something went wrong!</h2>
          <h4> Please try again later</h4>
          <a href={DASHBOARD} className="mt-3">
            <UiButton title={"Go Back Home"} />
          </a>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
