import { lazy } from 'react';

export const ICON = {
  wallet: lazy(() => import('./svg/wallet')),
  notfound: lazy(() => import('./svg/notFound')),
  attenttion: lazy(() => import('./svg/attention')),
  moreVertical: lazy(() => import('./svg/moreVertical')),
  documentation: lazy(() => import('./svg/documentations')),

};
