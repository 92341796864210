import { useCallback, useEffect } from "react";
import {
  unstable_BlockerFunction as BlockerFunction,
  unstable_useBlocker as useBlocker,
} from "react-router-dom";

function usePreventDirtyFormExit(formDirty: boolean) {
  //TEMPARORILY PAUSING AS USE_BLOCKER ISSUE ON SERVER DEPLOYMENT
  let shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      formDirty && currentLocation.pathname !== nextLocation.pathname,
    [formDirty]
  );
  let blocker = useBlocker(shouldBlock);
  useEffect(() => {
    if (blocker.state === "blocked" && !formDirty) {
      blocker.reset();
    }
  }, [blocker, formDirty]);
  return blocker;
}

export default usePreventDirtyFormExit;
