import React from "react";
import Select, { StylesConfig } from "react-select";
import classNames from "classnames";

import Avatar from "../../components/ui-avatar/component";
import Typography from "../../components/ui-typography/component";

import {  ISearchBoxItemOption } from "./types";
import styles from "./style.module.scss";

const OptionItem = ({
  option,
  isAccountItem,
}: {
  option: ISearchBoxItemOption;
  isAccountItem: boolean;
}) => (
  <div
    className={classNames(
      "d-flex gap-2 align-items-center cursor-pointer p-2",
      styles.hoverEffect
    )}
    key={option?.uuid}
  >
    {isAccountItem && (
      <Avatar imageSrc={option?.avatar} size="sm" fixSize={24} />
    )}
    <div className="d-flex justify-content-between w-100">
      <Typography> {option?.name}</Typography>
      <Typography fontSize="fs-6" className="text-end"> {option?.code}</Typography>
    </div>
  </div>
);

const AddItemSearchBox = ({
  data,
  setInvitedItem,
  isAccountItem = false,
  onInputChange,
}: {
  data: any;
  setInvitedItem: any;
  isAccountItem?: boolean;
  onInputChange?: (value: string) => void;
}) => {
  const controlStyles: StylesConfig<any, true> = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: "",
      width: "100%",
      borderRadius: "0.5rem",
    }),
    menuList: (base: any) => ({
      ...base,
      maxHeight: "250px",
      borderRadius: "0.5rem",
    }),
  };

  const mappedOptions = data?.map((option: ISearchBoxItemOption) => ({
    value: option.uuid,
    label: option.name,
    customComponent: (
      <OptionItem option={option} isAccountItem={isAccountItem} />
    ),
  }));

  const CustomOption = ({ innerProps, data }: any) => (
    <div {...innerProps}>{data.customComponent && data.customComponent}</div>
  );

  return (
    <div className="App">
      <Select
        options={mappedOptions}
        components={{ Option: CustomOption }}
        isSearchable
        styles={controlStyles}
        isMulti
        onChange={(choice: any) => setInvitedItem(choice)}
        onInputChange={(inputValue: string) => onInputChange?.(inputValue)}
      />
    </div>
  );
};

export default AddItemSearchBox;
