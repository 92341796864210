import React, { useState } from "react";

import { stockByWarehouseSchema } from "./schema";

import { getStockProductByWarehouseApi } from "../../apis/paths/products";
import { useApiCaller } from "../../apis/config";
import Typography from "../../components/ui-typography/component";
import TableDataPanel from "../../components/panel-table-data";
import PopupConfirmation from "../../components/popup-confirmation/component";

interface IStockByWarehousePopup {
  isShowModal: boolean;
  onCloseModal?: () => void;
  title?: string | React.ReactNode;
  header?: string | React.ReactNode;
  productCode: string;
  product: any;
  warehouseUuid: string;
}

const StockByWarehousePopup = ({
  isShowModal,
  onCloseModal,
  product,
  productCode,
  warehouseUuid,
}: IStockByWarehousePopup) => {
  const [params] = useState<{
    page: number;
    per: number;
    code: string;
    warehouse_uuid: string;
  }>({
    page: 1,
    per: 50,
    code: productCode,
    warehouse_uuid: warehouseUuid,
  });

  const { result: stockOfProductByWarehouse } = useApiCaller({
    api: getStockProductByWarehouseApi,
    params,
  });

  return (
    <PopupConfirmation
      isShowModal={isShowModal}
      onCloseModal={onCloseModal}
      size="lg"
      header={
        <Typography fontSize="fs-5" fontWeight="fw-bold">
          All Locations by Warehouse of:{" "}
          <Typography fontSize="fs-5" fontWeight="fw-normal">
            {product?.name}
          </Typography>
        </Typography>
      }
    >
      <div className="modal-body">
        <TableDataPanel
          data={stockOfProductByWarehouse?.data?.list}
          schema={stockByWarehouseSchema()}
        />
      </div>
    </PopupConfirmation>
  );
};

export default StockByWarehousePopup;
