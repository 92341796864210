export interface IViewNodeItem {
  key: number;
  icon?: string;
  title?: string | React.ReactNode;
  onChange?: () => void;
  checked?: boolean;
  subSets?: IViewNodeItem[];
  parentTitle?: string | React.ReactNode;
}

const ViewNodeItem = ({ configuration }: { configuration: IViewNodeItem }) => {
  const { key, checked, onChange, icon, title, parentTitle, subSets } =
    configuration || {};

  const isDisplaysubSets = Array.isArray(subSets) && subSets.length > 0;
  return (
    <div className="px-4">
      <div className="d-flex my-3">
        {isDisplaysubSets ? (
          <h6 className="mb-0 w-100">{parentTitle}</h6>
        ) : (
          <>
            <h6 className="mb-0 w-100">
              <i className={`fa-solid ${icon} me-2 w-5`}></i> {title}
            </h6>
            <div className="form-check form-switch ps-0 ms-auto my-auto">
              <input
                className="form-check-input mt-1 ms-auto"
                type="checkbox"
                onChange={onChange}
                checked={checked}
              />
            </div>
          </>
        )}
      </div>

      {isDisplaysubSets &&
        subSets?.map((config) => (
          <ViewNodeItem key={config?.key} configuration={config} />
        ))}
      {!isDisplaysubSets && <hr className="horizontal dark my-sm-4 " />}
    </div>
  );
};

export default ViewNodeItem;
