import classNames from "classnames";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useScanDetection from "use-scan-detection";

import { PACKINGS_LIST } from "../../routings/path";
import { API_RESPONSE_STATUS } from "../../constants/api";
import { GENERAL_UI_COLOR } from "../../constants/color";
import { useKeyPress } from "../../hooks/useTypingLetterSelection";
import { apiCaller, useApiCaller } from "../../apis/config";
import {
  getPackingDetailApi,
  getPackingProductsApi,
  getPackingSaleOrderFromScannedProductApi,
} from "../../apis/paths/packing";

import alert from "../../utils/alert";
import UiButton from "../../components/ui-button/component";
import Typography from "../../components/ui-typography/component";
import TableDataPanel from "../../components/panel-table-data";
import HeaderInfoPanel from "../../components/panel-header-info";
import useHandleSidebarStatus from "../../hooks/useSidebarStatus";
import SmallCollumnCenterGridLayout from "../../components/grid-layout-small-collumn-center";

import { locationSchema } from "./schema";


const Component = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isKeyPressed0 = useKeyPress(["Meta", "v"]);
  const isGetClipboard = isKeyPressed0;

  const uslSearchParams = new URLSearchParams(location.search);
  const previousProcessingSaleOrder = uslSearchParams?.get(
    "previousSaleOrderCode"
  );

  const { packingId } = useParams();
  const uuid = packingId || "";

  const [qrCode, setQrCode] = useState<string>("");

  const [packingDetailParams] = useState({ uuid: packingId });

  const { result: packingProduct } = useApiCaller({
    api: getPackingProductsApi,
    params: packingDetailParams,
  });

  /* Watch short cut interaction for quick user handling */
  useEffect(() => {
    if (isGetClipboard && window.document.hasFocus()) {
      window.navigator.clipboard.readText().then((clipText) => {
        setQrCode(clipText as string);
      });
    }
  }, [isGetClipboard]);

  const allGtinCodes = packingProduct?.data?.list?.flatMap((item: any) =>
    item?.products?.map((productItem: any) => productItem?.product?.gtin_code)
  );

  useScanDetection({
    onComplete: (qrCode) => {
      const removeShiftQrCode = qrCode?.replace(/Shift/g, "");
      setQrCode(removeShiftQrCode as string);
    },
  });

  const { dispatchSidebarStatus } = useHandleSidebarStatus();

  useEffect(() => {
    dispatchSidebarStatus({ isOpened: false });
    return () => {
      dispatchSidebarStatus({ isOpened: true });
    };
  }, []);

  const [params] = useState({ uuid });

  const { result } = useApiCaller({
    api: getPackingDetailApi,
    params,
  });

  const handleGetSaleOrderDetail = async (qrCode: string) => {
    const saleOrderDetail = await apiCaller({
      api: getPackingSaleOrderFromScannedProductApi,
      params: {
        uuid: packingId,
        gtin_code: qrCode,
      },
    });

    if (saleOrderDetail.status === API_RESPONSE_STATUS.SUCCESS) {
      const saleOrderNumber = saleOrderDetail?.data?.number;
      navigate(
        `${PACKINGS_LIST}/${packingId}/${saleOrderNumber}?productCode=${qrCode}`
      );
      return;
    }

    alert.error("Delete was unsuccessful!. Please try again");
  };

  useEffect(() => {
    if (!!qrCode) {
      if (!allGtinCodes?.includes(qrCode)) {
        alert.error("This product doesn't exist in picking list");
        return;
      }

      handleGetSaleOrderDetail(qrCode);
    }
  }, [qrCode]);

  const pickingDetail = result?.data;

  return (
    <>
      <HeaderInfoPanel
        title={`Packing task of Picklist ${pickingDetail?.code}`}
      />

      <div className={classNames("mt-5")}>
        <Typography
          className="text-center"
          fontWeight="fw-normal"
          fontSize="fs-4"
        >
          {pickingDetail?.locations && pickingDetail?.locations?.length > 0
            ? "Please scan any Products in Locations"
            : "No items for packing. Please check if this list is completed!"}
        </Typography>
        <SmallCollumnCenterGridLayout>
          <TableDataPanel
            data={pickingDetail?.locations}
            schema={locationSchema}
          />
          {previousProcessingSaleOrder && (
            <div className="d-flex align-items-center gap-1 justify-content-center flex-column mt-4">
              Back to previous sale order
              <UiButton
                type={GENERAL_UI_COLOR.LIGHT}
                onClick={() => {
                  navigate(
                    `${PACKINGS_LIST}/${uuid}/${previousProcessingSaleOrder}`
                  );
                }}
                title={
                  <div className="d-flex align-items-center">
                    {previousProcessingSaleOrder}
                  </div>
                }
              />
            </div>
          )}
        </SmallCollumnCenterGridLayout>
      </div>
    </>
  );
};

export default Component;
