import React from "react";
import PopupConfirmation from "../../components/popup-confirmation/component";

interface IDeleteConfirmationPopupProps {
  content: string | React.ReactNode;
  isShowModal: boolean;
  handleCloseModal?: () => void;
  title?: string | React.ReactNode;
  header?: string | React.ReactNode;
}

const LabelDetailPopup = ({
  isShowModal,
  handleCloseModal,
  content,
  title = "",
  header = "Label Detail",
}: IDeleteConfirmationPopupProps) => {
    
  return (
    <PopupConfirmation
      isShowModal={isShowModal}
      onCloseModal={handleCloseModal}
      header={header}
      size="lg"
    >
      <div>
        <div className="modal-body">
          <div className=" text-center">
            <i className="ni ni-bell-55 ni-3x"></i>
            <h4 className="text-gradient text-danger mt-4">{title}</h4>
            <p className="mt-4">{content}</p>
          </div>
        </div>
      </div>
    </PopupConfirmation>
  );
};

export default LabelDetailPopup;
