import { DEFAULT_PAGINATION_PARAMS } from "../../constants/pagination";
import { generateUrlParams } from "../../utils/url";
import { del, get, patch, post } from "../config";

interface IGetAttachmentsApi {
  page?: number;
  per?: number;
  keyword?: string;
  sort?: object | null;
  attachable_uuid: string;
  attachable_type: string;
}

export const getAttachmentsApi = ({
  attachable_uuid,
  attachable_type,
  page = DEFAULT_PAGINATION_PARAMS.page,
  per = DEFAULT_PAGINATION_PARAMS.per,
}: IGetAttachmentsApi) =>
  get({
    path: `/wms/attachments${generateUrlParams({
      attachable_uuid,
      attachable_type,
      page,
      per,
    })}`,
  });
