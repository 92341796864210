import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import TwoColumnGridLayout from "../../components/grid-layout-two-column";
import HeaderInfoPanel from "../../components/panel-header-info";
import TableCellStatus from "../../components/table-cell-status";
import TableDataPanel from "../../components/panel-table-data";
import UiLoadingPanel from "../../components/ui-loading-panel";
import GeneralPanel from "../../components/panel-general";
import NotFoundPanel from "../../components/panel-not-found/component";
import UiContentRow from "../../components/ui-content-row";
import UiButton from "../../components/ui-button";

import { getPickingDetailApi } from "../../apis/paths/picking";
import { formatDateTime } from "../../utils/date-time";
import { GENERAL_UI_SIZE } from "../../constants/size";
import { useApiCaller } from "../../apis/config";
import { PICKINGS_LIST } from "../../routings/path";

import {
  checkingTaskSchema,
  productSchema,
  saleOrderSchema,
  taskSchema,
} from "./schema";
import styles from "./styles.module.scss";

const Component = () => {
  const { pickingId: uuid } = useParams();
  const navigate = useNavigate();

  const [params] = useState({ uuid });

  const { result, loading } = useApiCaller({
    api: getPickingDetailApi,
    params,
  });

  const pickingDetail = result?.data;

  if (loading) return <UiLoadingPanel />;

  if (result?.status === "ERROR") return <NotFoundPanel />;

  const groupAllProductFromLocation = Array.isArray(
    pickingDetail?.products_list
  )
    ? pickingDetail?.products_list?.reduce(
        (acc: any, currentValue: any) => acc.concat(currentValue?.products),
        []
      )
    : [];

  return (
    <>
      <HeaderInfoPanel
        title={`#${pickingDetail?.code}`}
        infoArea={() => (
          <div className={styles.headerInfoArea}>
            <TableCellStatus
              className="me-2"
              status={pickingDetail?.status}
              size={GENERAL_UI_SIZE.SMALL}
            />
          </div>
        )}
        actionArea={() => (
          <UiButton
            onClick={() => {
              navigate(`${PICKINGS_LIST}/${uuid}/edit`);
            }}
            title={"Edit"}
          />
        )}
      />

      <GeneralPanel className="pt-4" containerClassName="mt-4">
        <TwoColumnGridLayout
          leftCol={
            <div>
              <UiContentRow label="Created by">
                {`@${pickingDetail?.creator?.username}`}
              </UiContentRow>
            </div>
          }
          rightCol={
            <div>
              <UiContentRow label="Created at">
                {formatDateTime(pickingDetail?.created_at, "HH:mm YYYY-MM-DD")}
              </UiContentRow>
              <UiContentRow label="Updated at">
                {formatDateTime(pickingDetail?.updated_at, "HH:mm YYYY-MM-DD")}
              </UiContentRow>
            </div>
          }
        />
      </GeneralPanel>

      <TableDataPanel data={pickingDetail?.picking_tasks} schema={taskSchema} />

      {pickingDetail?.checking_task && (
        <div className="mt-4">
          <TableDataPanel
            data={[pickingDetail?.checking_task]}
            schema={checkingTaskSchema}
          />
        </div>
      )}

      <TwoColumnGridLayout
        className={{ container: "mt-4 px-0" }}
        leftCol={
          <div className={styles.productTable}>
            <TableDataPanel
              data={groupAllProductFromLocation}
              schema={productSchema}
            />
          </div>
        }
        rightCol={
          <TableDataPanel
            data={pickingDetail?.sale_orders}
            schema={saleOrderSchema}
          />
        }
      />
    </>
  );
};

export default Component;
