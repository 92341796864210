import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./user.flow";
import noteReducer from "./note.flow";
import sidebarReducer from "./sidebar.flow";
import assigneeFilterReducer from "./assigneeFilter.flow";
import mySessionReducer from "./mySession.flow";
import extractProductFromCsvFileReducer from "./extractProductFromCsvFile.flow";
import dynamicDocParamerterReducer from "./dynamicDocParameter.flow";
import expiredSessionDetectionReducer from "./expiredSessionDetection.flow";
import configurationReducer from "./configuration.flow";

const reducers = combineReducers({
  user: userReducer,
  notes: noteReducer,
  sidebar: sidebarReducer,
  assigneeFilter: assigneeFilterReducer,
  mySession: mySessionReducer,
  extractProductFromCsvFile: extractProductFromCsvFileReducer,
  docParamerter: dynamicDocParamerterReducer,
  expiredSessionDetection: expiredSessionDetectionReducer,
  confifuration: configurationReducer
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["notes", "extractProductFromCsvFile", "expiredSessionDetection"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
